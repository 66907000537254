import { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatchThunk, useAppSelector, RootState } from 'redux/store';
import {
  getMyCommentsForFree,
  getMyCommentsForGroup,
  deleteCommentForGroup,
  deleteCommentForFree,
  actionCommentForGroup,
  actionCommentForFree,
  resetFeed,
} from 'api/feed';
import { timeDisplay } from 'utils/common';
import { API_PAGE_SIZE, TOAST_MESSAGE_TYPE } from 'configs/configs';
import { FeedErrorType, FeedItemType } from '../../@types/feed';

import { openModal } from 'redux/slices/modal';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';
import ListLoader from 'components/common/Loader/ListLoader';
import { setRefreshFn } from 'redux/slices/refresh';

import styled from 'styled-components';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { onView } from 'utils';

const Target = styled.div`
  height: 10px;
`;

interface CommentListPropsType {
  boardType: 'free' | 'group'; // 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
  refreshFun?: () => void; // 새로 고침할 때 실행할 콜백함수
}

/**
 * @description 내피드 댓글 목록 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @param {string} props.boardType 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
 * @param {void} props.refreshFun 새로 고침할 때 실행할 콜백함수
 * @returns {JSX.Element}
 */
const CommentList = ({ boardType = 'free', refreshFun }: CommentListPropsType) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchThunk();
  const nowDate = new Date();
  // 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
  //const boardType = new URLSearchParams(window.location.search).get('type') ?? 'free';
  // 무한로딩 교차 기준 요소 ref
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);
  // api 요청 진행 상태, true: 진행 중, false : 진행완료
  const { loading } = useAppSelector((state: RootState) => state.feed);
  // 팝업 정보
  const { modalType, data, isOpen: isModalOpen } = useAppSelector((state: RootState) => state.modal);
  // 이전 isModalOpen 값(댓글 목록 팝업)
  const preIsModalOpenRef = useRef<boolean | undefined>(undefined);
  // 댓글 데이터
  const comments = useAppSelector((state: RootState) =>
    boardType == 'free' ? state.feed.comments : state.feed.group_comments,
  );

  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);
  const { setToastMessage } = useToast();

  /**
   * @description 댓글 데이터 가져오기
   * @param {number} page Page Number
   * @returns {void}
   */
  const loadPage = useCallback(
    (page: number) => {
      if (boardType == 'free') {
        // 전체 게시판 댓글 목록
        dispatch(getMyCommentsForFree({ page: page, page_size: API_PAGE_SIZE }));
      } else if (boardType == 'group') {
        // 모임 게시판 댓글 목록
        dispatch(getMyCommentsForGroup({ page: page, page_size: API_PAGE_SIZE }));
      }
    },
    [dispatch, boardType],
  );

  /**
   * @description 댓글 삭제
   * @param {number} id 게시글 id
   * @param {number} groupId 모임 id
   * @param {number} groupPostId 모임 게시글 id
   * @returns {void}
   */
  const deleteComment = async (id: number, groupId?: number, groupPostId?: number) => {
    try {
      if (boardType == 'free') {
        // 전체 게시판 댓글 삭제
        await dispatch(deleteCommentForFree({ id: id })).unwrap();
      } else if (boardType == 'group') {
        // 모임 게시판 댓글 삭제
        await dispatch(deleteCommentForGroup({ id: id, group_id: groupId, group_post_id: groupPostId })).unwrap();
      }

      setToastMessage({
        duration: 2000,
        content: TOAST_MESSAGE_TYPE.COMMENT_DELETE,
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        setToastMessage({
          duration: 2000,
          content: TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      } else {
        //console.log('delete error:', feedError.message);
      }
    }
  };

  /**
   * @description 댓글 공감처리(좋아요, 싫어요)
   * @param {number} id 게시글 id
   * @param {number} action 0 싫어요 1 좋아요
   * @param {number} groupId 모임 id
   * @param {number} groupPostId 모임 게시글 id
   * @returns {void}
   */
  const actionComment = async (id: number, action: number, groupId?: number, groupPostId?: number) => {
    try {
      if (boardType == 'free') {
        dispatch(actionCommentForFree({ id: id, action: action }));
      } else if (boardType == 'group') {
        dispatch(actionCommentForGroup({ id: id, group_id: groupId, group_post_id: groupPostId, action: action }));
      }
    } catch (error) {
      const feedError = error as FeedErrorType;
      //console.log('action error:', error);
    }
  };

  /**
   * @description 댓글 BottomSheet 팝업 열기
   * @param {FeedItemType} comment 댓글 정보
   * @returns {void}
   */
  const handleComment = (comment: FeedItemType) => {
    // dispatch(openModal({ modalType: 'show_comment_list', data: comment.post }));
    const link =
      boardType == 'free'
        ? `/postdetail/${comment.post_id}?show=true`
        : `/group-postdetail/${comment.group_id}/${comment.group_post_id}?show=true`;
    if (import.meta.env.MODE === 'localdev') {
      navigate(link);
    } else {
      onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
    }
  };

  // state 형식이 올바르지 않을 경우 reset 처리
  useEffect(() => {
    if (comments === undefined) {
      // state reset
      dispatch(resetFeed());
    }
  }, [comments]);

  useEffect(() => {
    // 댓글 1 page 로딩
    loadPage(1);

    // 새로 고침
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          if (refreshFun) {
            refreshFun();
          }

          loadPage(1);
        },
      }),
    );
  }, [loadPage]);

  // 무한로딩 구현
  useEffect(() => {
    if (loading || !comments || !comments?.items || comments.items.length == 0 || !scrollAnchorRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (comments.current_page && comments.total_page && comments.current_page < comments.total_page) {
          loadPage(comments.current_page + 1);
        }
      }
    });

    observer.observe(scrollAnchorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [comments?.items]);

  // 댓글 BottomSheet 팝업 닫았을 때
  useEffect(() => {
    const preIsModalOpen = preIsModalOpenRef.current;
    preIsModalOpenRef.current = isModalOpen;

    if (modalType == 'show_comment_list' && preIsModalOpen === true && isModalOpen === false) {
      loadPage(1);
    }
  }, [modalType, isModalOpen]);

  return (
    <>
      {(!comments || comments.items.length == 0) && (
        <div className={CommonStyles['no-data']}>작성된 댓글이 없습니다.</div>
      )}

      <div className={CommonStyles['comment-box']}>
        {comments && comments.loading === true && <ListLoader />}
        {comments &&
          comments.items?.map((comment, index) => {
            let brokerInfo = '';
            if (comment.user.verify?.is_ceo == 1) {
              brokerInfo = '대표공인중개사';
            } else if (comment.user.verify?.broker_code == 2) {
              brokerInfo = '소속공인중개사';
            } else if (comment.user.verify?.broker_code == 4) {
              brokerInfo = '중개보조원';
            }

            const action = comment.comment_action?.length > 0 ? comment.comment_action[0].kind : null;

            return (
              <div key={index} className={CommonStyles['comment-item']}>
                <div className={CommonStyles['member-information']}>
                  {/* 프로필 영역 */}
                  <button className={CommonStyles.clickable}>
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{
                          backgroundImage: comment.user?.profile_photo_path
                            ? `url(${comment.user.profile_photo_path})`
                            : undefined,
                        }}
                      ></div>
                      {comment?.user?.verify?.broker_code && (
                        <span className={CommonStyles.grade} aria-label={comment.user.grade}></span>
                      )}
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{comment.user.nickname}</p>
                      <div className={CommonStyles['divide-box']}>
                        {(comment.user.company?.name || brokerInfo) && (
                          <p className={CommonStyles['limit-line1']}>
                            {comment.user.company?.name} {brokerInfo}
                          </p>
                        )}
                        <p>{timeDisplay(nowDate, comment.created_at)}</p>
                      </div>
                    </div>
                  </button>
                  <button
                    className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles.line)}
                    onClick={() => {
                      const action = () => {
                        deleteComment(comment.id, comment.group_id, comment.group_post_id);
                      };

                      dispatch(openModal({ modalType: 'confirm_delete_comment', action: action }));
                    }}
                    disabled={loading ? true : false}
                  >
                    삭제
                  </button>
                </div>
                <div className={CommonStyles.content}>
                  {/* 댓글 내용 */}
                  <button
                    onClick={() => {
                      const link =
                        boardType == 'free'
                          ? `/postdetail/${comment.post_id}`
                          : `/group-postdetail/${comment.group_id}/${comment.group_post_id}`;
                      if (import.meta.env.MODE === 'localdev') {
                        navigate(link);
                      } else {
                        onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
                      }
                    }}
                    style={{ width: '100%' }}
                  >
                    <p className={CommonStyles.text} style={{ wordBreak: 'break-all' }}>
                      {comment.content}
                    </p>
                    {(comment?.photos?.length > 0 || comment?.emoticon) && (
                      <div className={CommonStyles['attached-div']}>
                        {comment?.photos?.length > 0 && (
                          <div className={CommonStyles['img-box']} style={{ width: '100%' }}>
                            <div className={CommonStyles['img-item']}>
                              <img src={comment?.photos[0]?.url} alt={comment?.photos[0]?.org_name} />
                            </div>
                          </div>
                        )}
                        {comment?.emoticon && (
                          <div className={CommonStyles['emoji-box']}>
                            <img src={`/images/${comment.emoticon}`} alt="이모지" />
                          </div>
                        )}
                      </div>
                    )}
                    <div className={CommonStyles.resource}>
                      [
                      <span className={CommonStyles['divide-box']}>
                        {boardType == 'free' ? (
                          <>
                            <p>전체게시판</p>
                            <p>{comment.post?.cate.label}</p>
                          </>
                        ) : (
                          <p>{comment.group?.name}</p>
                        )}
                      </span>
                      ]
                    </div>
                  </button>
                  {/* 댓글 공감정보 및 답글 달기 */}
                  <div className={CommonStyles['post-function']}>
                    <button
                      className={cn(CommonStyles.like, action === 1 && CommonStyles.on)}
                      onClick={() => actionComment(comment.id, 1, comment.group_id, comment.group_post_id)}
                    >
                      <i className={CommonStyles['icon-like']}></i>
                      {comment.like_count}
                    </button>
                    <button
                      className={cn(CommonStyles.dislike, action === 2 && CommonStyles.on)}
                      onClick={() => actionComment(comment.id, 2, comment.group_id, comment.group_post_id)}
                    >
                      <i className={CommonStyles['icon-dislike']}></i>
                      {comment.dislike_count}
                    </button>
                    <button className={CommonStyles.comment} onClick={() => handleComment(comment)}>
                      <i className={CommonStyles['icon-comment']}></i>답글 달기
                    </button>
                  </div>
                  {comment.children.length > 0 && (
                    <button className={CommonStyles['more-btn']} onClick={() => handleComment(comment)}>
                      댓글 {comment.children.length}개 더보기
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        <Target ref={scrollAnchorRef} />
        {/* Toast 팝업 */}
        {isOpen && <Toast />}
      </div>
    </>
  );
};

export default CommentList;
