import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/group';
import { IMixedKeyValue } from '<modal>';
import styled from 'styled-components';
import useScroll from 'hook/useScroll';
import PostContent from 'pages/allboard/PostContent';
import PostAttach from 'pages/allboard/PostAttach';
import { timeDisplay } from 'utils/common';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import ListLoader from 'components/common/Loader/ListLoader';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';

const PAGE_SIZE = 10;

/**
 * @description 모임 설정 - 신고 목록
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const ReportList = () => {
  const params = useParams();
  const { id = '' } = params;
  const dispatch = useAppDispatch();
  const lastPage = useRef<boolean>(false);
  const currentUser = useAppSelector((state: RootState) => state.user.currentUser);
  const { modalType, isConfirm = false } = useAppSelector((state: RootState) => state.modal);
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState({
    page: 1,
    key: new Date().getTime(),
  });

  // 새로고침 중복 방지
  const isRefreshRef = useRef<boolean>(false);

  useEffect(() => {
    if (id) {
      const f = async () => {
        fetchList();
      };

      if (isRefreshRef.current) return;
      f();
    }
  }, [page]);

  // refresh 기능 추가
  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );

    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  // 게시글 삭제시 신괴된 게시글 재호출
  useEffect(() => {
    if ((modalType === 'success_report_remove' || modalType === 'success_delete') && isConfirm) {
      fetchList(true);
    }
  }, [modalType]);

  /**
   * @description 모임 신고된 게시물 조회 api
   * @param {boolean} isRefresh 새로고침을 통해서 api를 조회했는지 여부
   * @returns {Promise<void>}
   */
  const fetchList = async (isRefresh = false) => {
    try {
      setLoading(true);
      const res = await APIS.getReportList(
        `/groups/${id}/report?page_size=${PAGE_SIZE}&page=${isRefresh ? 1 : page.page}`,
      );
      const { data = [], links, meta } = res;
      const { total: totalCnt = 0 } = meta;
      const { next = null } = links;

      setTotal(totalCnt);

      // 마지막 페이지 체크
      if (next === null) {
        lastPage.current = true;
      }
      if (data) {
        if (isRefresh) {
          setList([...data]);
        } else {
          setList((prev) => [...prev, ...data]);
        }
      }
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 새로고침
   * @returns {Promise<void>}
   */
  const refreshFn = () => {
    fetchList(true);
    isRefreshRef.current = true;
  };

  /**
   * @description 무한 스크롤 observe
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);
    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && list.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  /**
   * @description 신고된 게시글 sheet open
   * @param {any} post 게시글 정보
   * @returns {Promise<void>}
   */
  const handleMoreBotSheet = (post: IMixedKeyValue): void => {
    dispatch(openModal({ modalType: 'bottomSheet_modify_report', isOpen: true, data: post }));
  };

  /**
   * @description 신고된 게시글 댓글 sheet open
   * @param {any} post 게시글 정보
   * @returns {Promise<void>}
   */
  const handleComment = (post: IMixedKeyValue): void => {
    dispatch(openModal({ modalType: 'show_comment_list', data: post, isOpen: true }));
  };

  return (
    <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
      <div className={CommonStyles['sub-title']}>
        신고된 게시물<b className={CommonStyles['co-dorg']}>{total}</b>
      </div>
      {list.map((post, index: number) => {
        const nowDate = new Date();
        return (
          <>
            <div key={index} className={CommonStyles['post-card']}>
              <div className={CommonStyles['member-information']}>
                <button className={CommonStyles.clickable}>
                  <div className={CommonStyles.profile}>
                    <div
                      className={CommonStyles['img-div']}
                      style={{
                        width: 48,
                        height: 48,
                        backgroundImage: post?.user?.profile_photo_path
                          ? `url(${post?.user?.profile_photo_path}`
                          : `url(../images/icon_profile.svg)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                      }}
                    />
                    {post?.user?.verify?.broker_code && (
                      <span className={CommonStyles.grade} aria-label={post?.user?.grade}></span>
                    )}
                  </div>
                  <div className={CommonStyles['mem-info']}>
                    <p className={CommonStyles.name}>{post?.user?.nickname}</p>
                    <div className={CommonStyles['divide-box']}>
                      {post?.user?.company && (
                        <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {post?.user?.company?.name}
                        </p>
                      )}
                      <p>{timeDisplay(nowDate, post?.created_at)}</p>
                    </div>
                  </div>
                </button>
                <button className={CommonStyles.tool} onClick={() => handleMoreBotSheet(post)} />
              </div>
              <button className={CommonStyles.detail}>
                {post?.title && (
                  <p className={cn(CommonStyles.title, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                    {post?.title}
                  </p>
                )}
                {post && <PostContent id={post.id} text={post?.content} post={post} maxLines={3} />}
                <PostAttach post={post} />
              </button>
              <div className={CommonStyles['post-function']}>
                <button type="button" className={cn(CommonStyles.like)}>
                  <i className={CommonStyles['icon-like']}></i>
                  {post?.liked_count || 0}
                </button>
                <button type="button" className={cn(CommonStyles.dislike)}>
                  <i className={CommonStyles['icon-dislike']}></i>
                  {post?.hated_count || 0}
                </button>
                <button type="button" className={CommonStyles.comment} onClick={() => handleComment(post)}>
                  <i className={CommonStyles['icon-comment']}></i>
                  {post?.comments_list_count || 0}
                </button>
              </div>
              {index + 1 === list.length && <Target ref={ref} />}
            </div>
            {index + 1 === list.length && loading && <ListLoader />}
          </>
        );
      })}
    </div>
  );
};

export default ReportList;

const Target = styled.div`
  height: 1px;
`;
