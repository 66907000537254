import React, { useState, useEffect, useRef, useCallback, ChangeEvent, KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import * as APIS from 'api/group';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import GradeBadge from 'components/common/Badge/GradeBadge';
import Button from 'components/common/Button/Button';
import { IMixedKeyValue } from '<modal>';
import useScroll from 'hook/useScroll';
import styled from 'styled-components';
import moment from 'moment';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import Input from 'components/common/Input/Input';
import ListLoader from 'components/common/Loader/ListLoader';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';

const PAGE_SIZE = 10;

/**
 * @description 모임 설정 - 회원 패널티
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupMemberPenalty = () => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();
  const groupData = useAppSelector((state: RootState) => state.group);
  const { myInfo } = groupData;

  // 회원 리스트
  const lastPage = useRef(false);
  const isSearch = useRef<boolean>(false);
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  const [leaderList, setLeaderList] = useState<IMixedKeyValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState({
    page: 1,
    key: new Date().getTime(),
  });
  // 검색
  const [searchValue, setSearchValue] = useState<string>('');

  // 새로고침 중복 방지
  const isRefreshRef = useRef<boolean>(false);

  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchList();
      };
      if (isRefreshRef.current) return;
      f();
    }
  }, [page]);

  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          fetchInitList();
        },
      }),
    );
    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  /**
   * @description 회원 조회 api
   * @returns {Promise<void>}
   */
  const fetchList = async (isRefresh = false) => {
    try {
      setLoading(true);
      let queryString = `/groups/${id}/users/normal?page=${isRefresh ? 1 : page.page}&page_size=${PAGE_SIZE}`;
      if (searchValue) {
        queryString += `&q=${searchValue}`;
      } else {
        isSearch.current = false;
      }
      const res = await APIS.getMemberList(queryString);
      const { data = [], links, meta } = res;
      const { total: totalCnt = 0 } = meta;
      const { next = null } = links;

      setTotal(totalCnt);

      // 마지막 페이지 체크
      if (next === null) {
        lastPage.current = true;
      }

      if (data) {
        setList((prev) => [...prev, ...data]);
      }
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 무한 스크롤 observe
   * @returns {Promise<void>}
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);

    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && list.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  /**
   * @description 모임 회원 페널티 활성화 api
   * @param {number} userId 모임 userId
   * @param {string} nickname 모임 유저 닉네임
   * @returns {Promise<void>}
   */
  const handleClickEnablePanalty = useCallback(
    (userId?: number, nickname?: string) => {
      if (myInfo.value === 'OWNER' && userId) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'confirm_group_set_penalty',
            data: {
              groupId: id,
              userId: userId,
              nickname: nickname,
            },
            action: () => fetchInitList(),
          }),
        );
      }
      // 달력 작업
    },
    [list],
  );

  /**
   * @description 모임 회원 페널티 비활성화 api
   * @param {number} userId 모임 userId
   * @param {string} nickname 모임 유저 닉네임
   * @returns {Promise<void>}
   */
  const handleClickDisabledPenalty = useCallback(
    (userId?: number, nickname?: string) => {
      if (myInfo.value === 'OWNER' && userId) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'confirm_group_clear_penalty',
            data: {
              groupId: id,
              userId: userId,
              nickname: nickname,
            },
            action: () => fetchInitList(),
          }),
        );
      }
    },
    [list],
  );

  /**
   * @description 검색
   * @returns {Promise<void>}
   */
  const onKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      //setLeaderList([]);
      setList([]);
      setTotal(0);
      setPage({
        page: 1,
        key: new Date().getTime(),
      });

      lastPage.current = false;
      isSearch.current = true;
      //fetchLeaderList();
    }
  };

  /**
   * @description 모임 회원 목록 재조회
   * @returns {Promise<void>}
   */
  const fetchInitList = () => {
    //setLeaderList([]);
    setList([]);
    setTotal(0);
    setPage({
      page: 1,
      key: new Date().getTime(),
    });
    //fetchLeaderList();
    fetchList(true);

    lastPage.current = false;
    isSearch.current = true;
    isRefreshRef.current = true;
  };

  return (
    <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
      <div className={CommonStyles['inner']}>
        <Input
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          onKeyDown={(e) => onKeyDownSearch(e)}
          handleClickClear={() => setSearchValue('')}
          className={cn(CommonStyles['search-input'])}
          placeholder="회원이름 검색"
          clear={true}
          mergeClass={true}
        />
      </div>
      <div className={CommonStyles['sub-title']}>
        {isSearch.current ? '검색결과' : '회원'}
        <b className={CommonStyles['co-dorg']}>{isSearch.current ? list.length : total}</b>
      </div>
      <div className={CommonStyles['member-list']}>
        {/* {!isSearch.current &&
          leaderList?.map((item, index: number) => {
            return (
              <div key={index} className={CommonStyles.list}>
                <div className={CommonStyles['member-information']}>
                  <div className={CommonStyles.clickable}>
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{ backgroundImage: `url(${item?.profile_photo_path})` }}
                      />

                      <GradeBadge grade={item.grade} />
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{item.nickname}</p>
                      <div className={CommonStyles['divide-box']}>
                        {item?.company?.name && <p>{item.company?.name || ''}</p>}
                        {item.label && <p>{item.label}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={CommonStyles['gray-box']}>
                  {item?.histories?.map((el: IMixedKeyValue, elIndex: number) => {
                    // 패널티 내역인지 확인 값
                    const isPenaltyData = el.label === '패널티' ? true : false;
                    // 패널티가 진행중인지 체크
                    const isCheck = el.end_at
                      ? new Date(el.end_at).getTime() > new Date().getTime()
                        ? true
                        : false
                      : false;
                    return (
                      <p
                        key={`${elIndex}`}
                        className={
                          isCheck ? cn(CommonStyles['co-red']) : isPenaltyData && !isCheck ? CommonStyles['co-gry'] : ''
                        }
                      >
                        {moment(el.created_at).format('YYYY년 M월 D일')}
                        {el.label}
                      </p>
                    );
                  })}
                </div>
                {index + 1 === list.length && !lastPage.current && <Target ref={ref} />}
              </div>
            );
          })} */}
        {list.map((item, index: number) => {
          const { histories = [] } = item;
          const isPenalty = item?.is_penaly || false;
          return (
            <React.Fragment key={index}>
              <div className={CommonStyles.list}>
                <div className={CommonStyles['member-information']}>
                  <div className={CommonStyles.clickable}>
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{ backgroundImage: `url(${item?.profile_photo_path})` }}
                      />
                      {item?.verify?.['broker_code'] && <GradeBadge grade={item.grade} />}
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{item.nickname}</p>
                      <div className={CommonStyles['divide-box']}>
                        {item?.company?.name && (
                          <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {item.company?.name || ''}
                          </p>
                        )}
                        {item.label && <p>{item.label}</p>}
                      </div>
                    </div>
                  </div>
                  {item.value !== 'OWNER' && !isPenalty && (
                    <Button type="line" onClick={() => handleClickEnablePanalty(item.id, item.nickname)}>
                      페널티
                    </Button>
                  )}
                  {item.value !== 'OWNER' && isPenalty && (
                    <Button type="red" onClick={() => handleClickDisabledPenalty(item.id, item.nickname)}>
                      페널티 해제
                    </Button>
                  )}
                </div>
                <div className={CommonStyles['gray-box']} style={{ marginLeft: '3rem' }}>
                  {item?.histories?.map((el: IMixedKeyValue, elIndex: number) => {
                    // 패널티 내역인지 확인 값
                    const findIndex = histories.findLastIndex((item: { label: string }) =>
                      item.label.includes('패널티'),
                    );
                    const lastPenalty = elIndex === findIndex && isPenalty ? true : false;
                    const isPenaltyData = el.label?.includes('패널티') ? true : false;
                    // 패널티가 진행중인지 체크
                    const isCheck = el.end_at
                      ? new Date(el.end_at).getTime() > new Date().getTime()
                        ? true
                        : false
                      : false;
                    return (
                      <p
                        key={`${elIndex}`}
                        className={
                          lastPenalty ? cn(CommonStyles['co-red']) : isPenaltyData ? CommonStyles['co-gry'] : ''
                        }
                      >
                        {isPenaltyData && el.start_at && el.end_at
                          ? el.start_at == el.end_at
                            ? `${moment(el.end_at).format('YYYY년 M월 D일')} ${el.label}`
                            : `${moment(el.start_at).format('YYYY년 M월 D일')} ~ ${moment(el.end_at).format(
                                'YYYY년 M월 D일',
                              )} ${el.label} ${isCheck && lastPenalty ? '진행중' : ''}`
                          : `${moment(el.created_at).format('YYYY년 M월 D일')} ${el.label}`}
                        {/* <br /> */}
                      </p>
                    );
                  })}
                </div>
                {index + 1 === list.length && !lastPage.current && <Target ref={ref} />}
              </div>
              {index + 1 === list.length && loading && <ListLoader />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default GroupMemberPenalty;

const Target = styled.div`
  height: 1px;
`;
