import { Link, useLocation, useNavigate } from 'react-router-dom';

//import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
// styles
import Styles from 'styles/header.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { useEffect, useRef, useState } from 'react';

const BrokerEmpty = () => {
  const navigate = useNavigate();

  //넘어온값 확인
  const location = useLocation();
  const { state } = location;
  const [param, setParam] = useState(state);
  useEffect(() => {
    if (!param?.jurirno || !param?.registration_number) {
      navigate('/broker/company');
    }
  }, []);

  //backkey => navigate(-2)
  return (
    <>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={cn(CommonStyles['content-div'], CommonStyles['search'])}>
            <p className={CommonStyles['page-tit']}>개설등록번호 조회 결과에요.</p>
            <div className={ResetStyles['ta-c']}>
              <i className={CommonStyles['icon-empty-note']}></i>
              <p className={CommonStyles['page-txt']}>
                <b className={cn(ResetStyles['fw-b'], CommonStyles['co-org'])}>{param?.jurirno}</b>로 검색한 중개업소가
                없어요.
              </p>
              <p className={CommonStyles['info-txt']}>찾으시는 중개업소가 없으신가요?</p>
              <Link to="/broker/request" className={cn(CommonStyles['co-bl'], CommonStyles['btn-request'])}>
                개설등록번호 요청하기
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrokerEmpty;
