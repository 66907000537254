import { IMixedKeyValue } from '<modal>';
import cn from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/common';
import { useAppDispatch } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import { offLoad, onLoad } from 'redux/slices/loading';

/**
 * @description 공유매물 수정요청
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @returns {JSX.Element}
 */
const CoagencyModify = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id = '' } = params;

  const [message, setMessage] = useState('');
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  const [type, setType] = useState<string>('');

  // 수정 요청 항목 조회

  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchImproveList();
      };

      f();
    }
  }, [id]);

  // 수정 요청
  const handleClickRequestModify = async () => {
    try {
      dispatch(onLoad());
      const payload = {
        type: type + '',
        content: message,
      };
      const res = await APIS.postImprove(`/offers/improve/${id}`, payload);

      // 수정 요청
      if (res?.status === 200) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'alert_coagency_modify_success',
            action: () => {
              navigate(`/sharedetail/${id}?isOnClose=true`);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      dispatch(offLoad());
    }
  };

  const onChageType = (val: string) => {
    setType(val);
  };

  // 수정 사유 목록 조회
  const fetchImproveList = async () => {
    try {
      const res = await APIS.getImproveList('/offers/improve/reason');

      if (res?.data?.length > 0) {
        setList([...res.data]);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  return (
    <>
      <header>
        <div className={CommonStyles['left']}>
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['title']}>수정 요청 하기</div>
        <div className={CommonStyles['right']}>
          <button
            className={CommonStyles.close}
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className={CommonStyles['icon-x-bk']}></i>
          </button>
        </div>
      </header>
      <div className={cn(CommonStyles['container'], CommonStyles['hasBottom'])}>
        <div className={cn(CommonStyles['content'], CommonStyles['edit-request'])}>
          <div className={CommonStyles['gray-box']}>
            수정요청을 하게 되면, 매물리스트에 [수정 요청을 받은 매물입니다]로 표시되게 됩니다. 직접 확인하신 경우에만
            수정요청을 해주시기 바랍니다.{' '}
          </div>
          <section>
            <p className={CommonStyles['tit']}>수정요청 사유(선택 필수)</p>
            <div className={CommonStyles['radio-div']}>
              {list.map((item: any, index: number) => {
                return (
                  <div key={index} className={CommonStyles['radio-box']}>
                    <input
                      type="radio"
                      id={`radio1-${index}`}
                      name="radio"
                      value={item.value}
                      onChange={() => onChageType(item.key + '')}
                    />
                    <label htmlFor={`radio1-${index}`} className={CommonStyles['label-info']}>
                      {item.label}
                    </label>
                  </div>
                );
              })}
            </div>
          </section>
          <section>
            <p className={CommonStyles['tit']}>상세내용</p>
            <div className={CommonStyles['textarea-div']}>
              <textarea
                placeholder="구체적인 내용을 입력해주세요.
단, 입력하신 내용은 해당 중개사에게 전달되므로 본인의 개인정보 (이름, 연락처 등)는 기재하지 말아주세요."
                maxLength={1000}
                value={message}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setMessage(e.target.value);
                }}
              ></textarea>
              <p className={CommonStyles['byte']}>
                <b>{message.length}</b>/1000
              </p>
            </div>
          </section>
          <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
            <button className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}>취소</button>
            <button
              className={cn(
                CommonStyles['btn'],
                CommonStyles['lg'],
                (type === '' || message.length === 0) && CommonStyles['disabled'],
              )}
              disabled={type === '' || message.length === 0 ? true : false}
              onClick={handleClickRequestModify}
            >
              수정 요청
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoagencyModify;
