import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';

interface ToggleButtonInterface {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // toggle button onChange 액션
  checked?: boolean; // toggle button 체크 여부
  label?: string; // toggle button label
  id?: string; // toggle button id
  disabled?: boolean;
}

/**
 * @description Toggle button
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {void} props.onChange  toggle button onChange 액션
 * @param {boolean} props.checked toggle button 체크 여부
 * @param {string} props.label toggle button label
 * @param {string} props.id oggle button id
 * @param {boolean} props.disabled toggle enabled disabled
 * @returns {JSX.Element}
 */
const ToggleButton: React.FC<ToggleButtonInterface> = ({
  onChange = () => {},
  checked = false,
  label = '',
  id = '',
  disabled = false,
}) => {
  // props로 받은 값에 따라 props 셋팅 변수
  let defaultProps = {};
  let htmlForProps = {};

  // id값을 필수로 안받으면 console에 에러가 발생하기 떄문에 방지하기 위해 id가 있을경우 id와 관련된 props 를 설정
  if (id) {
    defaultProps = {
      ...defaultProps,
      id: id,
    };

    htmlForProps = {
      ...htmlForProps,
      htmlFor: id,
    };
  }

  return (
    <div className={cn(CommonStyles['round-toggle'])}>
      <input
        type="checkbox"
        style={{ width: '0px', height: '0px' }}
        {...defaultProps}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label {...htmlForProps} className={cn(CommonStyles['label-info'])}></label>
      {label && <label className={cn(CommonStyles['label-text'])}>{label}</label>}
    </div>
  );
};

export default ToggleButton;
