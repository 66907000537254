import { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Container as MapDiv } from 'react-naver-maps';
import NaverMapPreview from 'pages/coagency/NaverMapPreview';
// redux
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionUser from 'redux/slices/user';

// type and init data
import { IMixedKeyValue } from '<modal>';
// utils
import { squareToPyeong, getOfferGroup } from 'utils/common';
import network from 'utils/network';
import CoagencyModify from 'pages/coagency/CoagencyModify';
import { cloneDeep } from 'lodash';
import useToast from 'hook/useToast';
import * as APIS from 'api/common';
import Toast from 'components/common/Toast/Toast';
import { offLoad, onLoad } from 'redux/slices/loading';
import { moveMain, onClose } from 'utils';
import * as config from 'configs/configs';

/**
 * @description 공유매물 상세
 * @Class
 * @category Pages
 * @subcategory 공유매물 게시판
 * @component
 * @returns {JSX.Element}
 */
const ShareDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const { pathname } = location;

  const hasReturnNav = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.returnNav;
  const backUrl: string | number = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.backUrl || -1;
  const pathName = new URL(window.location.href).pathname;

  const queryParams = new URLSearchParams(location.search);
  const queryGroupId = queryParams?.get('group_id') || '';
  const queryPush = queryParams?.get('push') || 'false';
  const queryIsOnClose = queryParams?.get('isOnClose');

  const showComment = queryParams.get('show');
  const closeComment = queryParams.get('close');

  const [detail, setDetail] = useState<IMixedKeyValue>({});
  // 네이버맵
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);

  const [tab, setTab] = useState<string>('info');

  const clickRef = useRef<boolean>(false);

  const { setToastMessage } = useToast();
  const toastState = useAppSelector((state: RootState) => state.toast);

  /**
   * @description 상세정보 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (id) {
        await getPostDetail();
      }
    };
    getData();
    return () => abortController.abort();
  }, [id]);

  // 상단 탭 스크롤 인러택션
  useEffect(() => {
    const el = document.getElementById('detail');

    const handleScroll = () => {
      const elements = document.querySelectorAll('.tab-menu-section');
      if (el) {
        // 스크롤 위치
        const scrollPosition = el.scrollTop;

        const isNearBottom = el.scrollHeight - el.clientHeight - scrollPosition <= 30;

        // 가장 가까운 section 태그를 저장할 변수 초기화
        let closestSection = null;
        let closestDistance = Infinity;
        let prevId: any = '';

        if (!isNearBottom) {
          elements.forEach(function (element) {
            // 요소의 위치
            const elementPosition = element.getBoundingClientRect().top + scrollPosition;

            // 스크롤 위치와 요소의 위치의 거리 계산
            const distance = Math.abs(scrollPosition - elementPosition + 100);

            // 이전에 저장된 가장 가까운 section 태그와 거리를 비교하여 더 가까우면 업데이트
            if (distance < closestDistance && distance) {
              closestDistance = distance;
              closestSection = element.closest('section');
              if (closestSection?.id) {
                if (prevId !== closestSection?.id) {
                  prevId = closestSection.id;
                  setTab(closestSection.id);
                }
              }
            }
          });
        } else {
          elements.forEach(function (element: any) {
            const elementPosition = element.offsetTop + element.clientHeight;
            const distance = Math.abs(el.scrollHeight - elementPosition);
            if (distance < closestDistance && distance <= 30) {
              closestDistance = distance;
              closestSection = element.closest('section');

              if (closestSection?.id) {
                if (prevId !== closestSection?.id) {
                  prevId = closestSection.id;
                  setTab(closestSection.id);
                }
              }
            }
          });
        }
      }
    };

    el?.addEventListener('scroll', handleScroll);

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showComment === 'true' && closeComment !== 'true') {
      console.log(showComment, 'show in SharedDetail');
      handleComment(detail);
    }
  }, [showComment, closeComment, detail]);

  /**
   * @description 상세정보 가져오기
   * @returns {Promise<void>}
   */
  const getPostDetail = async (): Promise<void> => {
    try {
      const res = await network().coagency().getCommonList(`/offers/${id}`);
      const { data, status } = res;
      if (status === 200) {
        // dispatch(ActionDetail.setDetail(data));
        setDetail(data);
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  /**
   * @description 가격
   */
  const getPrice = useMemo(() => {
    const price =
      (detail?.offerings_gbn === 'AB' || detail?.offerings_gbn === 'JB' || detail?.offerings_gbn === 'OB') &&
      detail?.deal_type_name === '매매'
        ? `(분양가)${detail?.price_info[0]?.value?.toLocaleString()}/(프리미엄)${detail?.price_info[1]?.value?.toLocaleString()}/(옵션가)${detail?.price_info[2]?.value?.toLocaleString()}`
        : detail?.deal_type_name === '매매' || detail?.deal_type_name === '전세'
        ? `${detail?.price_info[0]?.value?.toLocaleString()}`
        : detail?.deal_type_name === '월세' || detail?.deal_type_name === '단기임대'
        ? `${detail?.price_info[0]?.value?.toLocaleString()}/${detail?.price_info[1]?.value?.toLocaleString()}`
        : '';
    return price;
  }, [detail]);

  /**
   * @description 공유매물 수정 페이지로 이동
   * @returns {void}
   */
  const handleClickModifyRequest = () => {
    navigate(`/coagency-request-modify/${detail.id}`);
  };

  /**
   * @description 공유매물 북마크 등록/해제
   * @param {number} id 게시글 id
   * @param {number} bookmark_id 게시글 북마크 id
   * @returns {void}
   */
  const handleClickAddBookmark = async (id?: number, bookmark_id?: number) => {
    if (id === null || id === undefined) return;
    if (clickRef.current) return;
    // 북마크 등록
    try {
      clickRef.current = true;
      if (bookmark_id === null) {
        const res = await APIS.postShareBookmark(`/offers/${id}/bookmark`);

        if (res.id) {
          const copyState = cloneDeep(detail);
          copyState['bookmark_id'] = res.id;
          setDetail(copyState);

          setToastMessage({
            duration: 2000,
            content: '북마크가 완료되었습니다.',
            type: 'message',
          });
        }
      } else {
        // 북마크 해제
        const payload = {
          ids: [bookmark_id],
        };
        const res = await APIS.postShareUnBookmark(`/offers/bookmark`, payload);
        if (res.length === 0) {
          const copyState = cloneDeep(detail);
          copyState['bookmark_id'] = null;
          setDetail(copyState);

          setToastMessage({
            duration: 2000,
            content: '북마크가 삭제되었습니다.',
            type: 'message',
          });
        }
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 공유매물 수정 확인 요청 페이지로 이동
   * @returns {void}
   */
  const handleCikcCheckModify = () => {
    if (id) {
      navigate(`/coagency-request-modify/process/${id}`);
    }
  };

  /**
   * @description 공유매물 거래 완료 처리
   * @returns {void}
   */
  const handleClickComplete = async () => {
    try {
      if (clickRef.current) return;
      clickRef.current = true;
      dispatch(onLoad());

      const res = await APIS.putCompleteCoagency(`/offers/${id}/complete`);

      if (res.status_deal === 'complete') {
        moveMain(4, '0');
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
      dispatch(offLoad());
    }
  };

  /**
   * @description 공유매물 코멘트 sheet
   * @param {any} post 게시글 정보
   * @returns {void}
   */
  const handleComment = async (post: IMixedKeyValue) => {
    dispatch(
      ActionModal.openModal({
        modalType: 'show_comment_list',
        data: {
          ...post,
          isType: 'coagency',
        },
        isOpen: true,
      }),
    );
  };

  /**
   * @description 공유매물 탭 이동
   * @param {string} val 선택된 탭
   * @returns {void}
   */
  const handleClickMoveTab = (val: string) => {
    if (val === tab) return;
    setTab(val);

    const el = document.getElementById(val);
    if (el) {
      el.scrollIntoView();

      setTimeout(() => {
        const dl = document.getElementById('detail');
        const value = val === 'modify-info' ? -50 : -100;
        dl?.scrollBy(0, value);
      }, 50);
    }
  };

  /**
   * @description 공유매물 뒤로가기
   * @returns {void}
   */
  const handleClickBack = () => {
    const isBrokerInfo = pathName === '/feed/brokerinfo' ? true : false;
    // group id가 있는 경우, 푸시를 통해서 들어온 경우
    // 모임 홈으로 이동
    if (queryGroupId !== '' && queryPush === 'true') {
      navigate(`/mygroup/group/${queryGroupId}`, {
        replace: true,
      });
    }

    // group id가 없지만 푸시를 통해서 들어왔을 경우
    // 게시판으로 이동
    if (queryGroupId === '' && queryPush === 'true') {
      moveMain(3, '');
    }

    // 타인의 공유매물 > 상세 > 수정요청하기 > 수정요청하기 완료 후 > 해당 공유매물 상세로 랜딩 > 상세에서 뒤로가기 버튼 탭 시 > 공유매물 목록 리스트로 이동 수정 요청
    if (queryIsOnClose) {
      moveMain(3, 'offer');
    }

    // group id도 없고 푸시를 통해서 들어온 경우가 아닌 경우 기존 처럼 동작
    if (queryGroupId === '' && queryPush === 'false') {
      if (import.meta.env.MODE === 'localdev') {
        if (hasReturnNav) {
          navigate(hasReturnNav);
        } else {
          navigate(backUrl as number);
        }
      } else {
        if (document.referrer.includes(import.meta.env.VITE_WEB_DOMAIN) && window.history.state.idx === 0) {
          navigate(backUrl as number);
        } else {
          if (!window.history.state || window.history.state.idx === 0 || isBrokerInfo) {
            onClose();
          } else {
            if (hasReturnNav) {
              navigate(hasReturnNav);
            } else {
              navigate(backUrl as number);
            }
          }
        }
      }
    }
    dispatch(ActionUser.resetHistoryPath());
  };

  return (
    <>
      <header>
        <div className={CommonStyles['left']}>
          <button onClick={handleClickBack}>
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['title']}>{detail?.my_offer ? '내 공유매물 상세' : '공유매물 상세'}</div>
        <div className={CommonStyles['right']}>
          {/* <button className={CommonStyles.close}>
            <i className={CommonStyles['icon-x-bk']}></i>
          </button> */}
        </div>
      </header>
      <div id="detail" className={cn(CommonStyles['container'], detail?.my_offer && CommonStyles['hasBottom'])}>
        <div className={cn(CommonStyles['content'], CommonStyles['share-dtl'])}>
          {detail?.my_offer &&
            detail.status_deal !== 'complete' &&
            detail.edit_req_date !== '' &&
            detail.edit_req_date !== null && (
              <div className={CommonStyles['gray-box']} style={{ marginBottom: '16px' }}>
                <p className={CommonStyles['warning-txt']}>
                  <i className={CommonStyles['icon-warning-red']}></i>타 중개사의 수정요청을 받았습니다.{' '}
                  <button
                    className={cn(
                      CommonStyles['btn'],
                      CommonStyles['red'],
                      CommonStyles['sm'],
                      CommonStyles['ml-auto'],
                    )}
                    onClick={handleCikcCheckModify}
                  >
                    처리하기
                  </button>
                </p>
              </div>
            )}
          {detail.id && (
            <div className={CommonStyles['sale-card']}>
              {detail?.my_offer && (
                <p className={cn(CommonStyles['co-dorg'], CommonStyles['has-icon'])}>
                  <i className={CommonStyles['icon-mine']}></i>내 매물
                </p>
              )}
              <div className={CommonStyles['type']}>{`[${detail?.offer_type_name}]`}</div>
              <div
                className={CommonStyles['address']}
                style={{ wordBreak: 'break-all' }}
              >{`${detail?.title_mobile}`}</div>
              <div className={CommonStyles['price']}>
                <b className={CommonStyles['co-bl']}>{`${detail?.deal_type_name}`}</b>
                {`${getPrice} 만원`}
              </div>
              {detail.edit_req_date !== '' && detail?.edit_req_date !== null && !detail?.my_offer && (
                <p className={CommonStyles['warning-txt']}>
                  <i className={CommonStyles['icon-warning-red']}></i>타 중개사의 수정요청을 받았습니다.
                </p>
              )}
              <div className={CommonStyles['function-div']}>
                <button onClick={() => handleComment(detail)}>
                  <i className={CommonStyles['icon-comment']} />
                  {detail.comments_count! >= 100 ? `+99` : detail?.comments_count}
                </button>
                <button
                  className={cn(CommonStyles['bookmark'], detail?.bookmark_id !== null && CommonStyles['on'])}
                  onClick={() => handleClickAddBookmark(detail.id, detail.bookmark_id)}
                >
                  <i className={CommonStyles['icon-bookmark']}></i>
                </button>
              </div>
            </div>
          )}
          <div className={cn(CommonStyles['tab-menu'], CommonStyles['round-tab'])}>
            <button
              className={cn(CommonStyles['tab'], tab === 'info' && CommonStyles['on'])}
              onClick={() => handleClickMoveTab('info')}
            >
              상세정보
            </button>
            {detail?.status_share_map === 'Y' && (
              <button
                className={cn(CommonStyles['tab'], tab === 'info-map' && CommonStyles['on'])}
                onClick={() => handleClickMoveTab('info-map')}
              >
                지도보기
              </button>
            )}
            {detail.my_offer === false && (
              <button
                className={cn(CommonStyles['tab'], tab === 'company-info' && CommonStyles['on'])}
                onClick={() => handleClickMoveTab('company-info')}
              >
                중개업소 정보
              </button>
            )}
            {detail.my_offer && (
              <button
                className={cn(CommonStyles['tab'], tab === 'share-info' && CommonStyles['on'])}
                onClick={() => handleClickMoveTab('share-info')}
              >
                공유정보
              </button>
            )}
            {detail.my_offer && (
              <button
                className={cn(CommonStyles['tab'], tab === 'secret-info' && CommonStyles['on'])}
                onClick={() => handleClickMoveTab('secret-info')}
              >
                비공개정보
              </button>
            )}
            {detail.my_offer && (
              <button
                className={cn(CommonStyles['tab'], tab === 'list-info' && CommonStyles['on'])}
                onClick={() => handleClickMoveTab('list-info')}
              >
                매물가 수정 내역
              </button>
            )}
            {detail.my_offer && (
              <button
                className={cn(CommonStyles['tab'], tab === 'modify-info' && CommonStyles['on'])}
                onClick={() => handleClickMoveTab('modify-info')}
              >
                수정 요청 정보
              </button>
            )}
          </div>
          <div className={CommonStyles['section-div']}>
            <section id="info" className="tab-menu-section">
              <p className={CommonStyles['tit']}>매물 상세정보</p>
              <table className={CommonStyles['gry-table']}>
                <colgroup>
                  <col width="110" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>소재지</th>
                    <td>{detail?.addr || '-'}</td>
                  </tr>
                  <tr>
                    <th>매물명</th>
                    <td>{detail?.offer_name || '-'}</td>
                  </tr>
                  <tr>
                    <th>거래/매물종류</th>
                    <td>
                      <div className={CommonStyles['divide-box']}>
                        <p>{detail?.deal_type_name}</p>
                        <p>{detail?.offer_type_name}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>매물가격</th>
                    <td>{detail?.id ? `${getPrice} 만원` : '-'}</td>
                  </tr>
                  <tr>
                    <th>면적</th>
                    <td>
                      {/* <p>[공] 36.31㎡ -11평</p>
                    <p>[전] 29.92㎡ - 9평</p> */}
                      {detail?.area_info?.length > 0 && detail.area_info?.some((item: IMixedKeyValue) => item.value)
                        ? detail?.area_info?.map((k: IMixedKeyValue) => (
                            <p key={k.name}>
                              {k.value === null
                                ? `[${k?.name}] -`
                                : `[${k?.name}] ${Number(k?.value).toFixed(2)}㎡  ${squareToPyeong(
                                    Number(k?.value),
                                    'search',
                                  )}평`}
                            </p>
                          ))
                        : '-'}
                    </td>
                  </tr>
                  {detail.offerings_gbn !== 'TJ' && (
                    <tr>
                      <th>층</th>
                      {/* <td>해당층 2 / 총층6</td> */}
                      <td>
                        {detail?.floor_info?.length > 0
                          ? detail?.floor_info?.map((k: IMixedKeyValue, i: number) => {
                              return detail?.floor_info.length === 1 ? (
                                <span key={k.name}>
                                  {k.name} {k.value ? `${k.value}` : '-'}
                                </span>
                              ) : i === 0 ? (
                                <span key={k.name}>
                                  {k.name} {k.value ? `${k.value}` : '-'} /
                                </span>
                              ) : (
                                <span key={k.name}>
                                  {k.name} {k.value ? `${k.value}` : '-'}
                                </span>
                              );
                            })
                          : '-'}
                      </td>
                    </tr>
                  )}
                  {detail.offerings_gbn === 'RB' ||
                    detail.offerings_gbn === 'SM' ||
                    detail.offerings_gbn === 'SP' ||
                    detail.offerings_gbn === 'GJ' ||
                    detail.offerings_gbn === 'JC' ||
                    detail.offerings_gbn === 'TJ' ||
                    detail.offerings_gbn === 'BD' ||
                    detail.offerings_gbn === 'SG' ||
                    detail.offerings_gbn === 'CO' ||
                    detail.offerings_gbn === 'GT' || (
                      <tr>
                        <th>방/욕실 수</th>
                        <td>
                          <p>{detail?.room_cnt || '-'}</p>
                          <p>{detail?.bath_cnt || '-'}</p>
                        </td>
                      </tr>
                    )}
                  <tr>
                    <th>매물특징</th>
                    <td>{detail?.memo || '-'}</td>
                  </tr>
                  <tr>
                    <th>매물설명</th>
                    <td>{detail?.detail_memo || '-'}</td>
                  </tr>
                  <tr>
                    <th>{detail?.updated_at ? '수정일' : '최초 등록일'}</th>
                    <td>{moment(detail?.updated_at ? detail?.updated_at : detail?.created_at).format('YYYY.MM.DD')}</td>
                  </tr>
                  <tr>
                    <th>상태</th>
                    <td>{detail?.status || '-'}</td>
                  </tr>
                </tbody>
              </table>
            </section>
            {detail?.status_share_map === 'Y' && (
              <section id="info-map" className="tab-menu-section">
                <p className={CommonStyles['tit']}>지도</p>
                <div className={CommonStyles['map-div']}>
                  <MapDiv
                    style={{
                      width: '100%',
                      height: '240px',
                    }}
                  >
                    {detail?.offerings_gbn && (
                      <NaverMapPreview
                        totalAddress={
                          getOfferGroup(detail?.offerings_gbn) !== 'apartment_group'
                            ? `${detail.addr} ${detail.house_no}`
                            : detail?.map_addr
                        }
                        lat={lat}
                        setLat={setLat}
                        lng={lng}
                        setLng={setLng}
                      />
                    )}
                  </MapDiv>
                </div>
              </section>
            )}
            {detail?.my_offer === false && (
              <section id="company-info" className="tab-menu-section">
                <p className={CommonStyles['tit']}>중개업소 정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="115" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>중개업소명</th>
                      <td>{detail?.company_name}</td>
                    </tr>
                    <tr>
                      <th>대표자명</th>
                      <td>{detail?.ceo}</td>
                    </tr>
                    <tr>
                      <th>담당자명</th>
                      <td>{detail?.editor_name}</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>{detail?.contact}</td>
                    </tr>
                    <tr>
                      <th>중개정보 소재지</th>
                      <td>{detail?.address_detail}</td>
                    </tr>
                    <tr>
                      <th>개설등록번호</th>
                      <td>{detail?.corp_number}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {detail.my_offer && (
              <section id="share-info" className="tab-menu-section">
                <p className={CommonStyles['tit']}>공유정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>공개여부</th>
                      <td>{detail.status_share === 'Y' ? '공유함' : '공유안함'}</td>
                    </tr>
                    <tr>
                      <th>공유범위</th>
                      <td>
                        내공유
                        <br />
                        {detail?.shares?.length > 0
                          ? detail?.shares.map((k: IMixedKeyValue, i: number) => {
                              return (
                                <div key={i}>
                                  <p>{`${k.share_type === 'P' ? '전체공유' : k.name} (${moment(k.created_at).format(
                                    'YYYY.MM.DD',
                                  )})`}</p>
                                  <br />
                                </div>
                              );
                            })
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th>연락처노출</th>
                      <td>
                        {detail?.tel_display === 'A' ? (
                          <>
                            <p>모두노출</p>
                            <p>(중개업소 전화번호+휴대폰번호)</p>
                          </>
                        ) : detail?.tel_display === 'R' ? (
                          <p>중개업소 전화번호만 노출</p>
                        ) : detail?.tel_display === 'C' ? (
                          <p>휴대폰번호만 노출</p>
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {detail.my_offer && (
              <section id="secret-info" className="tab-menu-section">
                <p className={CommonStyles['tit']}>비공개정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>고객명</th>
                      <td>{detail?.customer_name || '-'}</td>
                    </tr>
                    <tr>
                      <th>고객연락처</th>
                      <td>{detail?.phone_num || '-'}</td>
                    </tr>
                    <tr>
                      <th>중개사 메모</th>
                      <td>{detail?.hidden_memo || '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {detail.my_offer && (
              <section id="list-info" className="tab-menu-section">
                <p className={CommonStyles['tit']}>매물가 수정 내역</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    {detail?.price_history?.length > 0 ? (
                      detail?.price_history?.map((k: IMixedKeyValue, i: number) => {
                        return (
                          <tr key={i} className={CommonStyles['table-row']}>
                            <th className={cn(CommonStyles.column, CommonStyles['table-tit'])}>
                              {`${moment(k.created_at).format('YYYY.MM.DD')} (${moment(k.created_at).format('hh:mm')})`}
                            </th>
                            <td className={CommonStyles.column}>
                              <p>{k.name}</p>
                              <b>{`${k.old_price.toLocaleString()} 🡪 ${k.new_price.toLocaleString()}`}</b>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td style={{ textAlign: 'center' }}>매물가 수정내역이 없습니다.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </section>
            )}
            {detail?.my_offer && (
              <section id="modify-info" className="tab-menu-section">
                <div className={CommonStyles['tit']}>
                  <span>수정 요청 정보</span>
                  {detail?.edit_req_date !== null && detail.status_deal !== 'complete' && (
                    <button
                      className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['red'])}
                      onClick={handleCikcCheckModify}
                    >
                      수정요청 처리
                    </button>
                  )}
                </div>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    {detail?.improve?.map((k: IMixedKeyValue, i: number) => {
                      return (
                        <Fragment key={i}>
                          <tr className={CommonStyles['table-row']}>
                            <th className={cn(CommonStyles.column, CommonStyles['table-tit'])}>사유</th>
                            <td className={CommonStyles.column}>
                              {k.type === '2200'
                                ? '허위/과장 가격 매물'
                                : k.type === '2201'
                                ? '거래 완료/철회 매물'
                                : k.type === '2202'
                                ? '기타 정보 부정확 매물'
                                : ''}
                            </td>
                          </tr>
                          <tr className={CommonStyles['table-row']}>
                            <th className={cn(CommonStyles.column, CommonStyles['table-tit'])}>상세내용</th>
                            <td className={CommonStyles.column}>{k.content}</td>
                          </tr>
                          <tr className={CommonStyles['table-row']}>
                            <th className={cn(CommonStyles.column, CommonStyles['table-tit'])}>수정요청일</th>
                            <td className={CommonStyles.column}>
                              {moment(k.created_at).format('YYYY.MM.DD hh:mm:ss')}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </section>
            )}
          </div>
          <div style={{ paddingBottom: '100px' }}>
            {!detail?.my_offer && (
              <>
                <button
                  type="button"
                  className={cn(
                    CommonStyles['btn'],
                    CommonStyles['lg'],
                    detail?.['edit_req_date'] !== null && CommonStyles['disabled'],
                  )}
                  disabled={detail?.['edit_req_date'] === null ? false : true}
                  onClick={handleClickModifyRequest}
                  style={{ width: '100%' }}
                >
                  수정 요청하기
                </button>
                <p className={cn(CommonStyles['bottom-info-txt'], CommonStyles.w100p)}>
                  해당 중개업소 확인하신 정보가 정확하지 않다면
                  <br />
                  수정을 요청해주세요!{' '}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {detail?.my_offer && (
        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <>
            <button
              className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
              onClick={() => {
                dispatch(
                  ActionUser.setHistoryPath({
                    from: location.pathname,
                    to: `/coagency-edit/${id}`,
                  }),
                );
                navigate(`/coagency-edit/${id}`);
              }}
            >
              수정
            </button>
            <button
              className={cn(
                CommonStyles['btn'],
                CommonStyles['lg'],
                detail.status_deal === 'complete' && CommonStyles['disabled'],
              )}
              onClick={handleClickComplete}
              disabled={detail?.status_deal === 'complete' ? true : false}
            >
              거래완료
            </button>
          </>
        </div>
      )}
      {toastState.isOpen && <Toast />}
    </>
  );
};

export default ShareDetail;
