import { useEffect } from 'react';
import network from 'utils/network';
import { SSO_SERVICE_CODE } from 'configs/configs';
import { useNavigate } from 'react-router-dom';
import { locStore } from 'utils/common';
import { batch } from 'react-redux';
import { useAppDispatch } from 'redux/store';

import * as ActionLogin from 'redux/slices/login';
import * as ActionUser from 'redux/slices/user';

/**
 * @description SSO login bridge
 * @Class
 * @category Bridge
 * @subcategory sso login
 */
const LoginBridge = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resToken = queryParams.get('resToken') || '';
  const to = queryParams.get('to');
  const sServiceCode = SSO_SERVICE_CODE;

  const onLogin = async (resToken: string) => {
    try {
      const res = await network().sso().signinJwt({ token: resToken });
      const data = { ...res.data };

      const { token, refresh } = data;
      locStore('token', token);
      locStore('refresh_token', refresh);
      batch(() => {
        dispatch(ActionLogin.login(true));
        dispatch(ActionUser.setUser(res.data));
      });
      const resMe = await network().sso().me();
      const _verified = !!resMe?.data.verify;
      batch(() => {
        dispatch(ActionUser.setMe(resMe.data));
        dispatch(ActionUser.setVerified(_verified));
      });

      if (window.webkit) {
        window.webkit.messageHandlers.loginSuccess.postMessage(data);
      } else if (window.Android) {
        window.Android.loginSuccess(JSON.stringify(data));
      } else {
        window.webkit.messageHandlers.loginSuccess.postMessage(data);
      }
    } catch (e: any) {
      alert(e?.response?.data?.message || '로그인 오류입니다. 다시 로그인 해주세요.');
      navigate(-1);
      // alert(e?.message);
    }
  };

  useEffect(() => {
    if (resToken === '') return;
    onLogin(resToken);
  }, [resToken]);

  return <></>;
};

export default LoginBridge;
