import cn from 'classnames';
import Input from 'components/common/Input/Input';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/group';
import { IMixedKeyValue } from '<modal>';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import React from 'react';
import styled from 'styled-components';
import useScroll from 'hook/useScroll';
import ListLoader from 'components/common/Loader/ListLoader';
import moment from 'moment';
import { convertArea, convertNumberUnit, numberWithCommas } from 'utils';
import useToast from 'hook/useToast';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import ScrollLoader from 'components/common/Loader/ScrollLoader';

interface Props {
  groupId?: string;
  setScrollHeight: any;
  scrollHeight: number;
}

const PAGE_SIZE = 5;

/**
 * @description 모임 구합니다 목록
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupFindShareList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { groupId = '', setScrollHeight, scrollHeight = 0 } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  // 새로고침 상태에 따라서 리스트 재조회
  const { load = false } = useAppSelector((state: RootState) => state.refresh);
  // 마지막 페이지 체크
  const lastPage = useRef<boolean>(false);
  // api중복으로 날라가는걸 방지하기 위한 state
  const [loading, setLoading] = useState(false);

  const [filterInfo, setFilterInfo] = useState<IMixedKeyValue>({});
  // 공유 매물 리스트
  const [seekList, setSeekList] = useState<IMixedKeyValue[]>([]);
  // 페이징 정보
  const [page, setPage] = useState({
    page: 1,
    key: new Date().getTime(),
  });
  // 검색어로 검색했는지 여부
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState(false);
  // 매물 갯수
  const [cnt, setCnt] = useState<any>(null);
  // 처음 페이지 로드시 loading state
  const [firstLoader, setFirstLoader] = useState<boolean>(false);

  useEffect(() => {
    setFirstLoader(true);
  }, []);

  // 페이지 정보가 바뀔 때 공동중개 매물 재조회
  useEffect(() => {
    const f = async () => {
      fetchShareList();
    };

    f();
  }, [page]);

  // 새로고침 할 때 리스트 재조회
  useEffect(() => {
    if (load) {
      setSeekList([]);
      setPage({
        page: 1,
        key: new Date().getTime(),
      });
    }
  }, [load]);

  // setter된 필터가 바뀔 떄 공동중개 매물 검색
  useEffect(() => {
    if (Object.keys(filterInfo).length > 0) {
      setSeekList([]);
      // 설정된 필터의 정보가 바뀔 때 => 페이지 정보를 초기화 시켜서 공동중개 매물 재조회
      setPage({
        page: 1,
        key: new Date().getTime(),
      });
    }
  }, [filterInfo]);

  /**
   * @description 모임 구합니다 목록 조회 api
   * @returns {Promise<void>}
   */
  const fetchShareList = async () => {
    if (groupId === '') return;
    try {
      setLoading(true);
      const queryString = `/seeks/search/${groupId}`;
      //const queryString = `/seeks/search`;
      let payload: IMixedKeyValue = {
        page: page.page,
        page_size: PAGE_SIZE,
        sort: 2,
        type: 'all',
      };

      if (searchValue.length > 0) {
        payload = {
          ...payload,
          keyword: searchValue,
        };
      }
      const res = await APIS.postSeekList(queryString, payload);

      const {
        data = [],
        next_page_url,
        total = 0,
      }: { data: IMixedKeyValue[]; next_page_url: any; total: any } = res || {};

      // 마지막 페이지 체크
      if (next_page_url === null) {
        lastPage.current = true;
      }

      if (data.length === 0 && !isFirst) {
        setIsFirst(true);
        setSeekList([]);
      } else {
        if (data.length > 0) {
          if (isSearch) {
            setIsFirst(false);
          }

          if (!isFirst) {
            setIsFirst(true);
          }

          setSeekList((prev) => [...prev, ...data]);
        } else {
          if (!isSearch) {
            setIsSearch(true);
          } else {
            setIsSearch(false);
          }
          setSeekList([]);
        }
      }
      setCnt(total);
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      if (firstLoader) {
        setFirstLoader(false);
      }
      setLoading(false);
    }
  };

  // 공유 매물 검색
  const handleClickSearch = (e: any) => {
    if (e.key === 'Enter') {
      fetchShareList();
    }
  };

  // 필터 sheet 호출
  const handleClickFilter = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'share_filter_full',
        action: (payload: IMixedKeyValue) => setFilterInfo({ ...payload }),
      }),
    );
  };

  /**
   * @description 구합니다 무한 스크롤 observe
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);
    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && seekList.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  /**
   * @description 모임 구합니다 목록
   */
  const memoShareList = useMemo(() => {
    return seekList;
  }, [seekList]);

  const navigate = useNavigate();

  return (
    <div
      id="container_wrap"
      onScroll={() => {
        const element = document.getElementById('container_wrap');
        if (element) {
          setScrollHeight(element.scrollTop);
        }
      }}
      style={{ height: seekList.length >= 0 ? '100%' : '0%', overflowY: 'auto' }}
    >
      {/* {memoShareList.length === 0 && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          구합니다 매물이 없습니다.
        </div>
      )} */}
      {/* <div className={CommonStyles['region-filter']}>
        <div className={CommonStyles['selected-option']}>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['sticky'])}>초기화</span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            빌라/연립
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            매매
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            10억~20억
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            화장실
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            빌라/연립
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            매매
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            10억~20억
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            화장실
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
        </div>
        <button className={CommonStyles['filter-btn']} onClick={handleClickFilter}>
        <i className={CommonStyles['icon-filter']}></i>
        </button>
        </div>
      </div> */}
      <div className={CommonStyles['region-filter']}>
        <div className={CommonStyles['search-box']}>
          <Input
            className={cn(CommonStyles['search-input'])}
            style={{ width: '100%' }}
            mergeClass={true}
            clear={true}
            value={searchValue}
            handleClickClear={() => setSearchValue('')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            placeholder="단지명, 매물특징으로 검색해보세요"
            onKeyDown={(e: any) => handleClickSearch(e)}
          />
        </div>
      </div>
      {cnt !== null && memoShareList.length !== 0 && (
        <div className={CommonStyles['card-count-txt']}>
          총 <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>{cnt > 1000 ? '1000+' : cnt}</b>개의
          매물이 있습니다.
        </div>
      )}
      {firstLoader && memoShareList.length === 0 && (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ScrollLoader />
        </div>
      )}
      {memoShareList.length > 0 && (
        <div className={CommonStyles['sale-card-div']} style={{ marginTop: '0.75rem' }}>
          {memoShareList.map((item, index: number) => {
            return (
              <React.Fragment key={index}>
                <ShareCard shareData={item} setList={setSeekList} index={index} list={seekList} />
                {index - 1 === seekList.length - 2 && <Target ref={ref} />}
                {index - 1 === seekList.length - 2 && loading && (
                  <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <ListLoader />
                  </div>
                )}
              </React.Fragment>
            );
          })}
          {scrollHeight > 0 && seekList.length >= 2 && <div style={{ height: '100px', width: '100%' }} />}
        </div>
      )}
      {isFirst && memoShareList.length === 0 && !isSearch && (
        // <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className={CommonStyles['sale-card-div']} style={{ width: '100%' }}>
          <div className={CommonStyles['no-data']}>
            <p>
              구합니다를 등록해서
              <br />
              공동중개를 진행해보세요!
            </p>
            <button
              className={cn(CommonStyles['btn'], ['lg'])}
              onClick={() => {
                if (groupId !== '') {
                  navigate(`/coagency-lookingfor-register/${groupId}`);
                }
              }}
            >
              등록하러 가기
            </button>
          </div>
        </div>
        // </div>
      )}
      {isSearch && memoShareList.length === 0 && (
        <div className={CommonStyles['sale-card-div']}>
          <div className={CommonStyles['no-data']}>
            <p>
              제공 및 조건에 맞는 매물을 찾지 못했어요.
              <br />
              다시 검색해보세요!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const ShareCard = ({
  shareData = {},
  setList,
  index,
  list,
}: {
  list: IMixedKeyValue[];
  shareData?: IMixedKeyValue;
  index: number;
  setList: (list: IMixedKeyValue[]) => void;
}) => {
  const {
    my_seek = 0,
    created_at = '',
    types = '',
    price_info = {},
    bookmark_id = null,
    comments_count = 0,
    deal_type_name = '',
    areas = '',
    title = '',
    content = '',
    area_max = '',
    area_min = '',
    status = '',
    status_deal = '',
    id = null,
  } = shareData;

  const { setToastMessage } = useToast();
  const navigate = useNavigate();

  // 중복클릭 방지
  const clickRef = useRef<boolean>(false);

  const parsePrice = useMemo(() => {
    let price = '';

    if (price_info.length > 1) {
      if (price_info?.[0]?.min !== null && price_info?.[0]?.min !== 0) {
        price += numberWithCommas(price_info[0].min);
      }
      if (price_info?.[0]?.max !== null && price_info?.[0]?.max !== 0) {
        price += `${price_info?.[0]?.min !== 0 && price_info?.[0]?.min !== null ? '~' : ''}${numberWithCommas(
          price_info[0].max,
        )}`;
      }
      if (price_info?.[1]?.min !== null && price_info?.[1]?.min !== 0) {
        price += `/${numberWithCommas(price_info[1]?.min)}`;
        if (price_info?.[1]?.max === null) {
          price += '만원';
        }
      }
      if (price_info[1].max !== null && price_info[1].max !== 0) {
        price += `${price_info?.[1]?.min !== 0 && price_info?.[1]?.min !== null ? '~' : ''}${numberWithCommas(
          price_info[1].max,
        )}만원`;
      }
    } else {
      if (price_info?.[0]?.min !== null && price_info?.[0]?.min !== 0) {
        price += numberWithCommas(price_info[0]?.min);
        if (price_info?.[0]?.max === null) {
          price += '만원';
        }
      }
      if (price_info?.[0]?.max !== null && price_info?.[0]?.max !== 0) {
        price += `${price_info?.[0]?.min !== null && price_info?.[0]?.min !== 0 ? '~' : ''}${numberWithCommas(
          price_info[0].max,
        )}만원`;
      }
    }

    return price;
  }, [shareData]);

  const status_color =
    status_deal === 'normal'
      ? 'state-success'
      : status_deal === 'hold'
      ? 'state-hold'
      : status_deal === 'complete'
      ? 'state-complete'
      : 'state';

  // 관심매물 등록
  const handleClickAddBookmark = async (id: number, bookmark_id: number) => {
    if (id === null) return;
    if (clickRef.current) return;
    // 북마크 등록
    try {
      clickRef.current = true;
      if (bookmark_id === null) {
        const res = await APIS.postFindShareBookmark(`/seeks/${id}/bookmark`);

        if (res.id) {
          const parseList: IMixedKeyValue[] = cloneDeep(list);
          parseList[index]['bookmark_id'] = res.id;
          setList([...parseList]);

          setToastMessage({
            duration: 2000,
            content: '북마크가 완료되었습니다.',
            type: 'message',
          });
        }
      } else {
        // 북마크 해제
        const payload = {
          ids: [bookmark_id],
        };
        const res = await APIS.postFindShareUnBookmark(`/seeks/bookmark`, payload);
        if (res.length === 0) {
          const parseList: IMixedKeyValue[] = cloneDeep(list);
          parseList[index]['bookmark_id'] = null;
          setList([...parseList]);

          setToastMessage({
            duration: 2000,
            content: '북마크가 삭제되었습니다.',
            type: 'message',
          });
        }
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  // 구합니다 상세
  const movePageDetail = (e: any, id: number) => {
    if (id === null) return;
    if (e.target.id.includes('bookmark')) return;
    navigate(`/findsharedetail/${id}`);
  };

  //my_seek 1 내 매물 / 0 공유매물
  //deal_type_name 전세 / 매매 / 월세
  return (
    <div
      className={cn(CommonStyles['sale-card'], CommonStyles['bg-blue'])}
      style={{ cursor: 'pointer' }}
      onClick={(e) => movePageDetail(e, id)}
    >
      <div className={CommonStyles['state-summary']}>
        <div className={cn(CommonStyles['divide-box'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
          {(my_seek === 1 || my_seek === true) && (
            <p className={cn(CommonStyles['co-dorg'], CommonStyles['has-icon'])}>
              <i className={CommonStyles['icon-mine']}></i>내 구함
            </p>
          )}
          {created_at && (
            <p className={cn(CommonStyles['date'])}>최초 작성일 {moment(created_at).format('YYYY.MM.DD')}</p>
          )}
        </div>
        {/* {status && <p className={cn(CommonStyles['state'], CommonStyles[status_color])}>{status}</p>} */}
      </div>
      {title && <div className={cn(CommonStyles['blue-box'], CommonStyles['limit-line2'])}>{title}</div>}
      {content && <p className={cn(CommonStyles['title'], CommonStyles['limit-line2'])}>{content}</p>}
      {types && <div className={CommonStyles['type']}>[{types}]</div>}
      <div className={CommonStyles['price']} style={{ wordBreak: 'break-all' }}>
        <b className={CommonStyles['co-bl']}>{deal_type_name}</b>
        <b className={CommonStyles['co']}>{parsePrice !== '' && parsePrice}</b>
      </div>
      <div className={CommonStyles['information']}>
        {area_min && area_max && <p>{`${convertArea(+area_min, 'square')}/${convertArea(+area_max, 'square')}평`}</p>}
      </div>
      {areas && <div className={cn(CommonStyles['description'])}>{areas}</div>}
      <div className={cn(CommonStyles['function-div'])}>
        <button>
          <i className={CommonStyles['icon-comment']}></i>
          {comments_count! >= 100 ? `+99` : comments_count}
        </button>
        <button
          id="bookmark"
          className={cn(CommonStyles['bookmark'], bookmark_id && CommonStyles['on'])}
          onClick={() => {
            handleClickAddBookmark(id, bookmark_id);
          }}
        >
          <i id="bookmark-icon" className={CommonStyles['icon-bookmark']}></i>
        </button>
      </div>
    </div>
  );
};

export default GroupFindShareList;

const Target = styled.div`
  height: 1px;
`;
