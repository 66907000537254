import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { offLoad, onLoad } from 'redux/slices/loading';
import { useAppDispatch } from 'redux/store';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/common';
import { openModal } from 'redux/slices/modal';
import { IMixedKeyValue } from '<modal>';
import network from 'utils/network';

/**
 * @description 공유매물 수정요청 확인
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @returns {JSX.Element}
 */
const CoagencyModifyProcess = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id = '' } = params;
  const clickRef = useRef<boolean>(false);

  const [detail, setDetail] = useState<IMixedKeyValue>({});

  useEffect(() => {
    if (id) {
      const f = async () => {
        await getPostDetail();
      };
      f();
    }
  }, [id]);

  const getPostDetail = async (): Promise<void> => {
    try {
      const res = await network().coagency().getCommonList(`/offers/${id}`);
      const { data, status } = res;
      if (status === 200) {
        // dispatch(ActionDetail.setDetail(data));
        setDetail(data);
      }
      console.log('res==>', res);
    } catch (err) {
      console.error('err', err);
    }
  };

  // 확인 하기
  const handleClickConfirm = async () => {
    try {
      if (clickRef.current) return;
      clickRef.current = true;
      dispatch(onLoad());
      const res = await APIS.deletePostImpove(`/offers/improve/${id}`);
      if (res?.status === 200) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'alert_coagency_modify_process_success',
            action: () => {
              navigate(`/sharedetail/${id}`);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      dispatch(offLoad());
      clickRef.current = false;
    }
  };

  // 공유 중지
  const handleClickStopShare = async () => {
    try {
      if (clickRef.current) return;
      clickRef.current = true;
      dispatch(onLoad());
      const res = await APIS.postStopSharePost(`/offers/improve/${id}/share`);
      if (res?.status === 200) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'alert_coagency_modify_stop_share',
            action: () => {
              navigate(`/sharedetail/${id}`);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      dispatch(offLoad());
      clickRef.current = false;
    }
  };

  // 수정하기 => 수정 페이지로 이동
  const movePageModify = () => {
    navigate(`/coagency-edit/${id}`, { replace: true });
  };

  return (
    <>
      <header>
        <div className={CommonStyles['left']}>
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['title']}>수정 요청 확인하기</div>
        <div className={CommonStyles['right']}>
          <button
            className={CommonStyles.close}
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className={CommonStyles['icon-x-bk']}></i>
          </button>
        </div>
      </header>
      <div className={cn(CommonStyles['container'], CommonStyles['hasBottom'])}>
        <div className={cn(CommonStyles['content'], CommonStyles['edit-request'])}>
          <div className={CommonStyles['gray-box']}>
            수정 요청하면,
            <br />
            [수정 요청을 받은 매물입니다] 표시가 사라집니다.
            <br />
            원활한 공동중개를 위하여 반드시 매물 상태를 확인해주세요.
          </div>
          <section>
            <p className={CommonStyles['tit']}>수정 요청 정보</p>
            <table className={CommonStyles['gry-table']}>
              <colgroup>
                <col width="110" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr>
                  <th>사유</th>
                  <td>{detail?.improve_reason || ''}</td>
                </tr>
                <tr>
                  <th>상세내용</th>
                  <td>{detail?.improve_content || ''}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
            <button
              className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
              onClick={handleClickConfirm}
            >
              확인하기
            </button>
            <button className={cn(CommonStyles['btn'], CommonStyles['lg'])} onClick={movePageModify}>
              수정하기
            </button>
            <button
              className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['bk'])}
              onClick={handleClickStopShare}
            >
              공유중지
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoagencyModifyProcess;
