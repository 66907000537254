import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import loginSlice from './slices/login';
import userSlice from './slices/user';
import modalSlice from './slices/modal';
import toastSlice from './slices/toast';
import groupSlice from './slices/group';
import groupMemberSlice from './slices/groupmember';
import feedSlice from './slices/feed';
import loadingSlice from './slices/loading';
import commentsSlice from './slices/comment';
import boardSlice from './slices/board';
import refreshSlice from './slices/refresh';
import globalSlice from './slices/global';
import subViewSlice from './slices/subview';
import appInfoSlice from './slices/appInfo';
import coagecnySlice from './slices/coagency';
import coagecnydetailSlice from './slices/coagencydetail';

/**
 * @description reducers // combine reducers
 * @Class
 * @category redux
 * @subcategory store
 */
const reducers = combineReducers({
  modal: modalSlice.reducer,
  login: loginSlice.reducer,
  user: userSlice.reducer,
  toast: toastSlice.reducer,
  group: groupSlice.reducer,
  refresh: refreshSlice.reducer,
  groupmember: groupMemberSlice.reducer,
  feed: feedSlice.reducer,
  loading: loadingSlice.reducer,
  comment: commentsSlice.reducer,
  board: boardSlice.reducer,
  global: globalSlice.reducer,
  subview: subViewSlice.reducer,
  appInfo: appInfoSlice.reducer,
  coagency: coagecnySlice.reducer,
  coagencydetail: coagecnydetailSlice.reducer,
});

/**
 * @description persist config  // 이곳에 whitelist 또는 blacklist를 array로 설정
 * @Class
 * @category redux
 * @subcategory store
 */
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'login', 'group'],
};

/**
 * @description persisted Reducer
 * @Class
 * @category redux
 * @subcategory store
 */
const persistedReducer = persistReducer(persistConfig, reducers);

/**
 * @description Redux store 설정
 * @Class
 * @category redux
 * @subcategory store
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: !import.meta.env.PROD,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = useSelector;
export const useAppDispatchThunk: () => AppDispatch = useDispatch;
export default store;
