import * as ActionsBoard from 'redux/slices/board';
import { Dispatch, AnyAction } from 'redux';
import _ from 'lodash';
import * as ActionLoading from 'redux/slices/loading';
// utils
import network from 'utils/network';
import { IMixedKeyValue } from '<modal>';

/**
 * @description 전체게시판 목록가져오기
 * @Class
 * @category Api
 * @subcategory board
 * @param {object} params {kind:1500 , keyword: '',  page_size: 10, page: 1,}
 */
export const fetchAllBoardPost = (params: IMixedKeyValue) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const fetchData = async () => {
      const res = await network().post().getWithPostBoardPost(JSON.stringify(params));
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      return data;
    };
    try {
      const posts = await fetchData();
      if (!params.kind) {
        dispatch(
          ActionsBoard.getAllBoard({
            currentKindId: params.kind,
            posts: posts.data,
            total: posts.total,
            page: params.page,
          }),
        );
        dispatch(ActionLoading.offDataLoad());
      } else if (params.kind === 1500) {
        dispatch(
          ActionsBoard.getFreeBoard({
            currentKindId: params.kind,
            posts: posts.data,
            total: posts.total,
            page: params.page,
          }),
        );
        dispatch(ActionLoading.offDataLoad());
      } else if (params.kind === 1501) {
        dispatch(
          ActionsBoard.getIssue({
            currentKindId: params.kind,
            posts: posts.data,
            total: posts.total,
            page: params.page,
          }),
        );
        dispatch(ActionLoading.offDataLoad());
      }
      // console.log('posts===>', posts);
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판리스트 포스트 좋아요 싫어요
 * @Class
 * @category Api
 * @subcategory board
 * @param {number} kind {kind} 싫어요 좋아요 타입
 * @param {number} postId {post id} 게시글 id
 * @param {number} params {kind} 싫어요 좋아요 타입
 * @returns {Promise<void>}
 */
export const setBoardPostLike = (kind: number | null, postId: number, params: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const setLikeData = async () => {
      const res = await network().post().postPostAction(postId, params);
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res?.data;
      // console.log('data==>', data);
      return data;
    };
    try {
      const postLike = await setLikeData();
      // console.log('postLike==>', postLike);
      dispatch(ActionsBoard.setPostLike({ currentKindId: kind, postLike, likeTargetId: postId }));
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description post 상세정보 가져와서 좋아요/싫어요 처리
 * @Class
 * @category Api
 * @subcategory board
 * @param {number} postId 게시글 id
 * @param {number} kind 싫어요 좋아요 타입
 * @returns {Promise<void>}
 */
export const postBoardLikeAndDisLike = async (postId: number, kind: number) => {
  const res = await network().post().postPostAction(postId, kind);

  return res;
};

/**
 * @description post 상세정보 가져와서 user_block정보 update
 * @Class
 * @category Api
 * @subcategory board
 * @param {number} postId 게시글 id
 * @returns {Promise<void>}
 */
export const getBoardPostDetail = (postId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const getData = async () => {
      const res = await network().post().getPostDetail(`/posts/${postId}`);
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res?.data;
      // console.log('data==>', data);
      return data;
    };
    try {
      const postDetail = await getData();
      // console.log('postDetail==>', postDetail);
      dispatch(ActionsBoard.updatePost({ postId: postId, user_block: postDetail.user_block, user: postDetail.user }));
    } catch (err) {
      console.error(err);
    }
  };
};
