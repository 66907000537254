import cn from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openModal } from 'redux/slices/modal';
import CommonStyles from 'styles/common.module.css';
import { moveMain, onClose } from 'utils';

/**
 * @description 공동중개 FQA 현재 모바일에서 없는 페이지를 호출시 해당 페이지로 이동 후 팝업 알림
 * @Class
 * @category Pages
 * @subcategory 팝업
 * @component
 * @returns {JSX.Element}
 */
const Guide = (props: any) => {
  const { searchType = '' } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location?.search || '');
  const type = params?.get?.('type') || '';

  useEffect(() => {
    if (type === 'etc' || searchType === 'etc') {
      dispatch(
        openModal({
          modalType: 'alert_common',
          data: {
            txt: '도움말은 PC에서 확인해주세요! \n모바일에서 곧 만나요.',
          },
          isOpen: true,
          action: () => {
            moveMain(0, '');
            onClose();
          },
        }),
      );
    }

    if (type === 'coagency' || searchType === 'coagency') {
      dispatch(
        openModal({
          modalType: 'alert_common',
          data: {
            txt: '공동중개는 PC에서 확인해주세요!\n 모바일에서 곧 만나요.',
          },
          isOpen: true,
          action: () => {
            moveMain(0, '');
            onClose();
          },
        }),
      );
    }
  }, []);
  return <div className={cn(CommonStyles.content, CommonStyles['error-page'])}></div>;
};
export default Guide;
