/* eslint-disable no-useless-escape */
import React, { useState, useRef, useEffect } from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { replaceURLWithAtag } from 'utils/common';
import dompurify from 'dompurify';
import { isIOS, isSafari } from 'react-device-detect';

const ContentContainer = styled.div<{ maxLines: number; expanded: boolean }>`
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  line-height: 22px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  ${(props) => {
    return props.expanded
      ? {
          'max-height': 'none',
        }
      : {
          '-webkit-box-orient': 'vertical',
          'max-height': `${props.maxLines * 22}px`,
        };
  }}
`;
const ToggleButton = styled.button`
  color: #4d4c61;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 2px;
  padding: 6px 8px;
`;

interface ExpandableTextProps {
  id?: number;
  childKey?: number;
  comment: any;
  maxLines: number;
  show?: boolean;
  onExpended?: () => void;
}

/**
 * @description 댓글 contents F.C
 * @Class
 * @category Components
 * @subcategory 댓글
 * @param {CommentProps} props
 * @param {number} props.id
 * @param {number} props.childKey
 * @param {object} props.comment
 * @param {number} props.maxLines
 * @param {boolean} props.show
 * @param {function} props.onExpended
 * @returns {JSX.Element}
 */
const CommentContent = ({ id, childKey, comment, maxLines, onExpended }: ExpandableTextProps): JSX.Element => {
  const sanitizer = dompurify.sanitize;
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentLineCount, setCurrentLineCount] = useState(0);
  const { content }: { content: string } = comment;

  useEffect(() => {
    if (contentRef.current) {
      const computedStyle = window.getComputedStyle(contentRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const containerHeight = parseFloat(computedStyle.height);
      const newLineCount = Math.floor(containerHeight / lineHeight);
      setCurrentLineCount(newLineCount);
    }
  }, [content]);

  const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  const toggleExpansion = () => {
    // setExpanded(!expanded);
    setExpanded(true);
    if (onExpended) {
      onExpended();
    }
  };

  return (
    <div key={childKey} className="txt-box">
      {expanded && (isIOS || isSafari) ? (
        <>
          {content?.split('\n').map((line, index) => (
            <React.Fragment key={`${line}_${index}`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizer(line.replace(linkRegex, replaceURLWithAtag)).replace(/(?:\r\n|\r|\n)/g, '<br/>'),
                }}
                style={{
                  wordBreak: 'break-all',
                }}
              />
              {linkRegex.test(line) ? '' : <br />}
            </React.Fragment>
          ))}
        </>
      ) : (
        <StyleSheetManager shouldForwardProp={(prop) => !['expanded', 'maxLines'].includes(prop)}>
          <ContentContainer ref={contentRef} maxLines={maxLines} expanded={expanded}>
            {content?.split('\n').map((line, index) => (
              <React.Fragment key={`${line}_${index}`}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(line.replace(linkRegex, replaceURLWithAtag)).replace(/(?:\r\n|\r|\n)/g, '<br/>'),
                  }}
                  style={{
                    wordBreak: 'break-all',
                  }}
                />
                {linkRegex.test(line) ? '' : <br />}
              </React.Fragment>
            ))}
          </ContentContainer>
        </StyleSheetManager>
      )}
      {currentLineCount > 0 && currentLineCount >= maxLines && !expanded && (
        <ToggleButton onClick={toggleExpansion}>더보기</ToggleButton>
      )}
    </div>
  );
};

export default CommentContent;
