/**
 * @description api url
 * @Class
 * @category constant
 * @subcategory config
 */
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL ?? import.meta.env.VITE_API_BASE_URL;

/**
 * @description sso url
 * @Class
 * @category constant
 * @subcategory config
 */
export const SSO_URL = import.meta.env.PROD ? 'https://www.aipartner.com' : 'https://dwww.aipartner.com';

/**
 * @description app link
 * @Class
 * @category constant
 * @subcategory config
 */
export const APP_LINK = import.meta.env.PROD ? 'https://m.aipartner.com' : 'https://dm.aipartner.com';

/**
 * @description sso service code
 * @Class
 * @category constant
 * @subcategory config
 */
export const SSO_SERVICE_CODE = '2000';

/**
 * @description default path
 * @Class
 * @category constant
 * @subcategory config
 */
export const DEFAULT_PATHS = {
  APP: '/',
  NOT_FOUND: '/not-found',

  MYGROUP: '/mygroup',
  SEARCH: '/search',
  WRITING: '/newpostselect',
  ALLBOARD: '/allboard',
  FEED: '/feed',

  // SSO
  SSOLogin: `${SSO_URL}/integrated/login?requestPage=${
    import.meta.env.VITE_SSO_RETURN_URL
  }&serviceCode=${SSO_SERVICE_CODE}`,
  SSOLoginBroker: `${SSO_URL}/integrated/login?requestPage=${
    import.meta.env.VITE_SSO_RETURN_URL
  }/&serviceCode=${SSO_SERVICE_CODE}&brokerAuth=3000`,
  SSOSignup: `${SSO_URL}/integrated/join-confirm?requestPage=${
    import.meta.env.VITE_SSO_RETURN_URL
  }&serviceCode=${SSO_SERVICE_CODE}`,
  SSOPersonalInfo: `${SSO_URL}/member/myinfo`,
};

/**
 * @description path list
 * @Class
 * @category constant
 * @subcategory config
 */
export const PATHURLLIST = [
  { url: '/mygroup', title: '내모임', header: false, backKey: false, refresh: true },
  { url: '/individualGroup', title: '개별모임', header: false, refresh: true },
  { url: '/groupmembersearch', title: '회원', header: true, backKey: true, refresh: true },
  { url: '/groupmemberdetail', title: '회원', header: true, backKey: true, refresh: false },
  { url: '/invitegroupmember', title: '회원 초대하기 링크 만들기', header: true, backKey: true, refresh: false },
  { url: '/modifygroupimage', title: '모임 이름 및 사진 설정', header: true, backKey: true, refresh: false },
  { url: '/modifygroupintroduce', title: '모임 소개 및 지역', header: true, backKey: true, refresh: false },
  { url: '/groupapprovalmanagement', title: '가입 신청 관리', header: true, backKey: true, refresh: false },
  { url: '/groupmemberpanelty', title: '회원 페널티 설정', header: true, backKey: true, refresh: true },
  { url: '/groupleader', title: '모임장 위임', header: true, backKey: true, refresh: true },
  { url: '/groupcommonleader', title: '공동 모임장 추가 및 관리', header: true, backKey: true, refresh: true },
  { url: '/groupnoticelist', title: '공지사항', header: true, backKey: true, refresh: true },
  { url: '/mygroupdetail', title: '주제별 모임찾기' },
  { url: '/groupsetting', title: '모임설정', header: true, backKey: true, refresh: true },
  { url: '/groupapprovallist', title: '가입 승인 관리', header: true, backKey: true, refresh: true },
  { url: '/groupreportlist', title: '신고된 게시물 관리', header: true, backKey: true, refresh: true },
  { url: '/group-postdetail', title: '게시글 상세', header: true, backKey: true, refresh: true },
  { url: '/writing-findshare', title: '구합니다 등록', header: false, backKey: false, refresh: false },
  { url: '/guide', title: '안내', header: true, backKey: false, refresh: false },
  { url: '/newpost-type', title: '글쓰기', header: true, backKey: false, refresh: false, hasClose: true },
  {
    url: '/newpost-coagency-type',
    title: '글쓰기',
    header: false,
    backKey: true,
    refresh: false,
    hasClose: true,
    returnNav: '/newpost-type',
  },
  {
    url: '/coagency-register-select',
    title: '공유매물 등록',
    header: true,
    backKey: true,
    refresh: false,
    hasClose: true,
    returnNav: '/newpost-coagency-type',
  },
  {
    url: '/coagency-edit',
    title: '내 공유매물 수정',
    header: true,
    backKey: true,
    refresh: false,
  },
  { url: '/coagency-register', title: '공유매물 등록', header: false, backKey: false, refresh: false },
  {
    url: '/coagency-legacy-list',
    title: '이실장 불러오기',
    header: true,
    backKey: true,
    refresh: false,
    hasClose: true,
  },
  {
    url: '/coagency-lookingfor-register/modify',
    title: '구합니다 상세 수정',
    header: false,
    backKey: true,
    refresh: false,
  },
  { url: '/coagency-lookingfor-register', title: '구합니다 등록', header: false, backKey: true, refresh: false },
  {
    url: '/newpostselect',
    title: '글쓰기',
    header: true,
    backKey: false,
    refresh: false,
    brokerAuth: true,
    hasClose: true,
  },
  { url: '/writing', title: '글쓰기', header: false, backKey: true, refresh: false },
  { url: '/groupselect', title: '공유할 모임 선택', header: true, backKey: true, refresh: true },
  { url: '/allboard', title: '전체게시판', header: true, backKey: false, refresh: true },
  { url: '/postdetail', title: '게시글 상세', header: true, backKey: true, refresh: true },
  { url: '/sharedetail', title: '공유매물 상세', header: false, backKey: true, refresh: false },
  { url: '/findsharedetail', title: '구합니다 상세', header: false, backKey: true, refresh: false },
  { url: '/feed/bookmarks', title: '북마크', header: true, backKey: true, refresh: true },
  { url: '/feed/profile', title: '프로필 편집', header: true, backKey: true, refresh: false },
  { url: '/feed/brokerinfo', title: '중개사 정보', header: true, backKey: true, refresh: false },
  { url: '/feed', title: '내 피드', header: true, backKey: false, refresh: true },
  { url: '/broker/ceo', title: '중개사 인증', header: true, backKey: true, refresh: true, backUrl: '/' }, //대표가 카카오톡 초대받아서 넘어온 케이스
  { url: '/broker/temporary', header: false },
  { url: '/broker/invite', header: false },
  { url: '/broker/request', header: false },
  { url: '/broker', title: '중개사 인증', header: true, backKey: true, refresh: true },
];

/**
 * @description nav list
 * @Class
 * @category constant
 * @subcategory config
 */
export const NAV_LIST = [
  { url: '/mygroup', title: '홈' },
  { url: '/search', title: '검색' },
  { url: '/newpostselect', title: '글쓰기' },
  { url: '/allboard', title: '전체게시판' },
  { url: '/feed', title: '내 피드' },
];

/**
 * @description 사용하지 않음
 * @Class
 * @category constant
 * @subcategory config
 */
export const NAV_LIST_WITH_COAGENCY = [
  { url: '/mygroup', title: '홈' },
  { url: '/search', title: '검색' },
  { url: '/newpost-type', title: '글쓰기' },
  { url: '/allboard', title: '전체게시판' },
  { url: '/feed', title: '내 피드' },
];

/**
 * @description s3 url
 * @Class
 * @category constant
 * @subcategory config
 */
export const S3_URL = import.meta.env.VITE_S3_BUCKET_URL ?? '';

/**
 * @description cdn url
 * @Class
 * @category constant
 * @subcategory config
 */
export const CDN_URL = import.meta.env.VITE_CDN_URL ?? '';

/**
 * @description s3 bucket name
 * @Class
 * @category constant
 * @subcategory config
 */
export const BUCKET_NAME = import.meta.env.VITE_S3_BUCKET_NAME ?? '';

/**
 * @description crypto key
 * @Class
 * @category constant
 * @subcategory config
 */
export const CRYPTO_SECRET_KEY = import.meta.env.VITE_CRYPTO_SECRET_KEY ?? '';

/**
 * @description lang
 * @Class
 * @category constant
 * @subcategory config
 */
export const DEFAULT_SETTINGS = {
  LANGUAGE: 'ko-KR',
};

/**
 * @description 공지 팝업 url
 * @Class
 * @category constant
 * @subcategory config
 */
export const SIREN_POPUP_URL = 'https://pcc.siren24.com/pcc_V3/jsp/pcc_V3_j10_v2.jsp';

/**
 * @description service setting
 * @Class
 * @category constant
 * @subcategory config
 */
export const SERVICE_USE_SETTING = {
  hasGNG:
    import.meta.env.MODE === 'localdev' ||
    import.meta.env.MODE === 'coagency' ||
    !navigator.userAgent.toLowerCase().includes('aipartnernet')
      ? 'enabled'
      : 'disabled',
  WRITING_MAP: 'disabled',
};

/**
 * @description 모임만들기 default image
 * @Class
 * @category constant
 * @subcategory config
 */
const covers = Array.from({ length: 21 }, (_, i) => i + 1);
export const GROUP_DEFAULT_COVERS = covers.map((v) => {
  return {
    file_type: 'photo',
    mime_type: 'image/png',
    org_name: `cover_${v}.png`,
    url: `${S3_URL}/temp/cover/cover_${v}.png`,
    cdn_url: `${CDN_URL}/temp/cover/cover_${v}.png`,
  };
});

/**
 * @description 이모티콘
 * @Class
 * @category constant
 * @subcategory config
 */
export const EMOJIICON_LIST = ['recommend', 'like', 'soso', 'sad', 'angry'];

/**
 * @description sso api url
 * @Class
 * @category constant
 * @subcategory config
 */
export const API_URL = {
  POST_SSO_SIGNIN: '/sso/signin', // sso signin
  POST_SSO_SIGNIN_JWT: '/sso/signin-jwt', // sso signin JWT
  POST_SSO_SIGNOUT_JWT: '/sso/signout-jwt', // sso signout JWT (회원탈퇴용)
  POST_SSO_SIGNOUT: '/sso/signout', // sso signout
  POST_SSO_REFRESH: '/sso/token', // 토큰 갱신
  GET_SSO_ME: '/sso/me', // 내정보 가져오기
  PUT_SSO_PROFILE: '/sso/profile', // 프로파일 변경
  POST_ISNICKNAME: '/sso/isnickname', // 닉네임 중복여부
  POST_PHONE_PUBLIC: '/sso/phonepublic', // 휴대폰 공개여부 토글
  POST_BROKER_PUBLIC: '/sso/brokerpublic', // 중개사 공개여부 변경 (토글)
  PUT_SET_ALIM: '/sso/setalram', // 모임알림관리
  POST_SSO_ACCESS: '/sso/accessLogin', // 로그인 기록
  GET_JWT_MEMBER: '/sso/jwt-member', // JWT 생성(개인정보설정)
  GET_AIPARTNER_MEMBERSHIP: '/sso/membership', //이실장 멤버십 정보
  GET_AIPARTNER_SUBSCRIBES: '/sso/subscribes', //이실장 구독결제 정보
  GET_AIPARTNER_OFFERING: '/offers/import', //이실장 매물 목록 가져오기
  GET_AIPARTNER_OFFERING_CONVERT: '/offers/convert', //이실장 매물 단일 가져오기 (데이터 최적화)
  POST_AIPARTNER_OFFERING_CONVERT: '/offers/importmulti/convert', //이실장 매물 여러개 연동
  POST_AIPARTNER_OFFERING_All_LIST: '/offers/importall/list', //이실장 전체 매물 가져오기
  POST_AIPARTNER_OFFERING_All_CONVERT: '/offers/importall/convert', //이실장 전체 매물 연동 (반복)
  /** 모임 */
  // 통합api
  POST_GROUP_UNION_MAIN: '/groups/list/main',
  POST_GROUPDETAIL_UNION_MAIN: '/groups/list/main', // 상세로 parameter만 다름
  POST_GROUP_PAGEVIEW: '/groups/pageview',
  // 통합api end

  GET_GROUP_BASICINFO: '/groups/:groupId/basicinfo', // 내부사용
  GET_MY_GROUP: '/groups/list/me',
  GET_LIST_ACTIVE: '/groups/list/auth?o=new&r=4',
  GET_LIST_NEW: '/groups/list/auth?r=4&m=7',
  GET_LIST_SIX: '/groups/list/auth?ta[]=TOWN&r=6&o=new',
  GET_ACTIVE_TEN: '/groups/list/auth?k=1103&o=new&r=10',
  GET_LIST_NEW_TEN: '/groups/list/auth?k=1103&r=10&m=7',
  GET_GROUP_KIND_LIST: '/groups/config',
  GET_GROUP_INFO: '/groups/:groupId', // 내부사용
  DELETE_GROUP_COMMENT: '/groups/:groupId/posts/:groupPostId/comments/:id', // 모임 댓글 삭제

  // 전체 게시판
  DELETE_FREE_COMMENT: '/posts/comments/:id', // 전체 게시판 댓글 삭제

  // 전체게시판
  POST_BOARD_POST_LIST: '/posts/list', // 전체게시판 게시글 등록
  GET_POST_DETAIL: '/posts/:postId', // 상세가져오기
  PUT_BOARD_POST: '/posts/:postId', // 전체게시판 게시글 수정
  POST_BOARD_POST_SHARE: '/groups/:groupId/posts/share', // 전체게시판 게시글 공유
  DELETE_BOARD_POST: '/posts/:postId', // 전체게시판 게시글 삭제
  POST_BOARD_ACTION: '/posts/:postId/action', // 전체게시판 게시물 공감처리(좋아요, 싫어요)
  POST_COMMENT_ACTION: '/posts/comments/:commentId/action', // 전체게시판 댓글 공감처리(좋아요, 싫어요)

  // 모임 게시판
  GET_GROUP_POST_DETAIL: '/groups/:groupId/posts/:groupPostId', // 상세가져오기
  DELETE_GROUP_POST: '/groups/:groupId/posts/:groupPostId', // 모임 게시판 게시글 삭제
  POST_GROUP_POST_ACTION: '/groups/:groupId/posts/:groupPostId/:action', // 모임 게시판 게시글 공감처리(좋아요, 싫어요)
  POST_GROUP_COMMENT_ACTION: '/groups/:groupId/posts/:groupPostId/comments/:groupPostCommentId/action', // 모임 게시판 댓글 공감처리(좋아요, 싫어요)

  // Feed
  GET_MY_GROUP_POSTS: '/posts/my/group',
  GET_MY_GROUP_COMMENTS: '/posts/comments/my/group',
  GET_MY_FREE_POSTS: '/posts/my',
  GET_MY_FREE_COMMENTS: '/posts/comments/my',
  POST_GROUP_POST: '/groups/:groupId/posts',

  // 북마크
  GET_BOOKMARK_GROUP_POSTS: '/posts/bookmarks/group', // 모임 게시물 목록
  GET_BOOKMARK_FREE_POSTS: '/posts/bookmarks/list', // 전체 게시판 게시물 목록
  PUT_BOOKMARK_GROUP_POST: '/groups/:groupId/posts/:groupPostId/bookmark', // 모임 게시물 북마크 등록
  PUT_BOOKMARK_FREE_POST: '/posts/:postId/bookmark', // 전체 게시판 게시물 북마크 등록
  DELETE_BOOKMARK_GROUP_POST: '/groups/:groupId/posts/:groupPostId/bookmark/:groupPostBookmarkId', // 모임 게시물 북마크 해제
  DELETE_BOOKMARK_FREE_POST: '/posts/bookmarks/:bookId', // 전체 게시판 게시물 북마크 해제

  // 중개사
  DELETE_BROKER_LEAVE: '/broker/leave', // 중개사 퇴사
  GET_BROKER_LIST: '/broker/list', // 중개사 직원 목록
  POST_BROKER_FIRE: '/broker/fire', // 중개사 직원 해고
  GET_BROKER_VERIFY_CHECK: '/broker/verify/check', // 중개사 변경/삭제 여부
  GET_BROKER_FIRE_CHECK: '/broker/verify/fire_check/:userId', // 중개사 직원 해고 여부

  // 중개사인증
  GET_BROKER_ISCOMPANY: '/broker/iscompany',
  GET_BROKER_JURIDICALSEARCH: '/broker/juridical/company',
  GET_BROKER_JURIDICAL_MEMBER: '/broker/juridical/member',
  POST_BROKER_COMPANY_AREA: '/offers/area/search',
  POST_BROKER_COMPANY_REGISTER: '/broker/company',
  POST_BROKER_VERIFY: '/broker/verify',
  GET_BROKER_AIPARTNER: '/broker/aipartner', //이실장연동
  POST_BROKER_VERIFY_AIPARTNER: '/broker/verifyAipartner', // 중개사 인증 (이실장)

  // 공동중개
  GET_RE_TYPE_ALL: '/offers/type/list', // 매물구분 및 유형목록 함께 가져오기
  POST_ADDR_LIST: '/offers/area/search', // 해당주소검색
  GET_OFFERS_MYGROUP: '/offers/mygroups', // 나의 공동중개 모임목록 가져오기 (향후 중개사 인증모임결과로 변경)
  GET_OWNER_TYPE: '/offers/relation/list', // 비공개정보 관계

  // 공동주택 단지, 등록
  GET_OFFERS_COMPLEXES: '/offers/complexes', // 단지정보가져오기
  POST_APARTMENT: '/offers/apartment', // 공동주택 매물등록

  // 비공동주택 등록
  POST_NONAPARTMENT: '/offers/nonapartment', // 비공동주택 매물등록

  // 비공동주택 면적정보확인
  POST_OFFERS_BUILDINGINFO: '/offers/info/buildingInfo', // 빌딩정보
  POST_OFFERS_FLOORINFO: '/offers/info/floorInfo', // 층정보
  POST_OFFERS_AREAINFO: '/offers/info/areaInfo', // 면적정보

  // 공통싱세등록
  PUT_OFFERS_DETAIL: '/offers/optional/{id}', // id는 매물 id
  // 매물상세
  GET_OFFERS_DETAIL: '/offers/:offerId', // 매물상세

  // 앱 관련
  GET_APP_VERSION: '/app/version',
  GET_APP_INFO: '/app/info/1.0.0', // 시스템점검

  POST_SEARCH_OFFERINGS: '/offers/search', // 공유매물 검색 (북마크)
  POST_SEARCH_SEEKS: '/seeks/search', // 구합니다 검색 (북마크)
  DELETE_BOOKMARK_OFFERING: '/offers/bookmark', // 북마크 삭제 (공유매물)
  DELETE_BOOKMARK_SEEK: '/seeks/bookmark', // 북마크 삭제 (구합니다)
};

/**
 * @description 등급 목록
 * @Class
 * @category constant
 * @subcategory config
 */
export const GRADE_LIST = ['새싹', '열매', '초수', '중수', '고수', '영웅'];

/**
 * @description 전체 게시판 타입
 * @Class
 * @category constant
 * @subcategory config
 */
export const BOARD_TYPE: { [key: string]: string } = {
  '1500': 'free-board',
  '1501': 'issue',
};

/**
 * @description API page size
 * @Class
 * @category constant
 * @subcategory config
 */
export const API_PAGE_SIZE = 5;

/**
 * @description toast 메세지
 * @Class
 * @category constant
 * @subcategory config
 */
export const TOAST_MESSAGE_TYPE = {
  INACTIVE_MEMBER_RELEASE: '휴면 해제가 완료되었습니다.',
  BOOKMARK_COMPLETE: '북마크 완료되었습니다.',
  BOOKMARK_REMOVE: '북마크가 삭제되었습니다.',
  COMMENT_DELETE: '댓글이 삭제되었습니다.',
  URL_COPY_COMPLETE: '주소가 복사되었어요 원하는 곳에 붙여넣기 해주세요.',
  POST_DELETE: '게시글이 삭제되었습니다.',
  ORIGIN_POST_DELETE: '원문 게시글이 삭제되었습니다.',
  POST_REPORT_COMPLETE: '게시글이 신고되었습니다.',
  POST_REPORT_REMOVE: '게시글이 신고해제되었습니다.',
  COMMENT_REPORT_COMPLETE: '댓글이 신고되었습니다.',
  COMMENT_REPORT_REMOVE: '댓글이 신고해제되었습니다.',
  POST_REPORT_CONTENT: '신고내용을 선택해 주세요.',
  NOT_AUTHORITY: '권한이 없습니다.',
};

/**
 * @description 프로필 닉네임 관련 메세지
 * @Class
 * @category constant
 * @subcategory config
 */
export const PROFILE_NICKNAME_MESSAGE = {
  EMPTY_NICKNAME: '닉네임을 입력해 주세요.',
  NOT_VALID_NICKNAME: '한글, 문자, 숫자만 입력 가능하며 최대 10자까지 입력할 수 있습니다.',
  USABLE_NICKNAME: '사용할 수 있는 닉네임이에요.',
};

/**
 * @description 게시판 관련 메세지
 * @Class
 * @category constant
 * @subcategory config
 */
export const POST_MESSAGE = {
  POST_BLOCKED: '차단된 게시글입니다.',
  POST_DECLARED: '신고된 게시글입니다.',
  POST_EMPTY: '작성된 게시글이 없습니다.',
};

/**
 * @description 회원 가입 타입
 * @Class
 * @category constant
 * @subcategory config
 */
export const LOGIN_SNS_TYPE: { [key: string]: string } = {
  naver: '네이버 간편 통합회원',
  kakao: '카카오 간편 통합회원',
  apple: '애플 간편 통합회원',
};

/**
 * @description offers message
 * @Class
 * @category constant
 * @subcategory config
 */
export const OFFERS_MESSAGE = {
  KEYWORD_LABEL: '지역, 단지명, 매물특징으로 검색해보세요.',
  OFFER_EMPTY: '북마크된 공유매물이 없습니다.',
  OFFER_SEARCH_EMPTY: '조건에 맞는 매물을 찾지 못했어요.\n다시 검색해보세요!',
  OFFER_TOTAL: '총 :total개의 매물이 있습니다.',
  OFFER_EDIT_REQ: '타 중개사의 수정요청을 받았습니다.',
};

/**
 * @description seeks message
 * @Class
 * @category constant
 * @subcategory config
 */
export const SEEKS_MESSAGE = {
  KEYWORD_LABEL: '지역, 단지명, 매물특징으로 검색해보세요.',
  SEEK_EMPTY: '북마크된 구합니다가 없습니다.',
  SEEK_SEARCH_EMPTY: '조건에 맞는 구합니다를 찾지 못했어요.\n다시 검색해보세요!',
  SEEK_TOTAL: '총 :total개의 매물이 있습니다.',
};

/**
 * @description 매물 타입
 * @Class
 * @category constant
 * @subcategory config
 */
export const REType = [
  {
    main_nm: '아파트·오피스텔',
    main_cd: 'A',
    offer_types: [
      { main_cd: 'A', sub_nm: '아파트', sub_cd: 'AP' },
      { main_cd: 'A', sub_nm: '주상복합', sub_cd: 'JS' },
      { main_cd: 'A', sub_nm: '오피스텔', sub_cd: 'OP' },
      { main_cd: 'A', sub_nm: '재개발', sub_cd: 'RB' },
      { main_cd: 'A', sub_nm: '재건축', sub_cd: 'RC' },
    ],
  },
  {
    main_nm: '분양권',
    main_cd: 'B',
    offer_types: [
      { main_cd: 'B', sub_nm: '아파트 분양권', sub_cd: 'AB' },
      { main_cd: 'B', sub_nm: '주상복합 분양권', sub_cd: 'JB' },
      { main_cd: 'B', sub_nm: '오피스텔 분양권', sub_cd: 'OB' },
    ],
  },
  {
    main_nm: '빌라·주택',
    main_cd: 'C',
    offer_types: [
      { main_cd: 'C', sub_nm: '빌라·연립', sub_cd: 'VL' },
      { main_cd: 'C', sub_nm: '단독·다가구', sub_cd: 'DD' },
      { main_cd: 'C', sub_nm: '전원·농가', sub_cd: 'JW' },
      { main_cd: 'C', sub_nm: '한옥', sub_cd: 'HO' },
      { main_cd: 'C', sub_nm: '상가주택', sub_cd: 'SJ' },
    ],
  },
  {
    main_nm: '원룸',
    main_cd: 'D',
    offer_types: [{ main_cd: 'D', sub_nm: '원룸', sub_cd: 'OR' }],
  },
  {
    main_nm: '상가·업무\n공장·토지',
    main_cd: 'E',
    offer_types: [
      { main_cd: 'E', sub_nm: '사무실', sub_cd: 'SM' },
      { main_cd: 'E', sub_nm: '상가점포', sub_cd: 'SP' },
      { main_cd: 'E', sub_nm: '공장·창고', sub_cd: 'GJ' },
      { main_cd: 'E', sub_nm: '지식산업센터', sub_cd: 'JC' },
      { main_cd: 'E', sub_nm: '토지·임야', sub_cd: 'TJ' },
    ],
  },
  {
    main_nm: '건물',
    main_cd: 'F',
    offer_types: [
      { main_cd: 'F', sub_nm: '빌딩·건물', sub_cd: 'BD' },
      { main_cd: 'F', sub_nm: '상가건물', sub_cd: 'SG' },
      { main_cd: 'F', sub_nm: '숙박·콘도', sub_cd: 'CO' },
      { main_cd: 'F', sub_nm: '기타', sub_cd: 'GT' },
    ],
  },
];

/**
 * @description 매물 유형
 * @Class
 * @category constant
 * @subcategory config
 */
export const COAGENCY_REG_OFFER_GBN = [
  { id: 1, label: '매매', value: 'S' },
  { id: 2, label: '전세', value: 'L' },
  { id: 3, label: '월세', value: 'M' },
  { id: 4, label: '단기임대', value: 'T' },
];

/**
 * @description 매물 유형
 * @Class
 * @category constant
 * @subcategory config
 */
export const priceSelect = [
  { cd: 1, name: '매매가', code: 'sell_prc' },
  { cd: 2, name: '전세가', code: 'lease_prc' },
  { cd: 3, name: '보증금', code: 'deposit_prc' },
  { cd: 4, name: '월세', code: 'monthly_prc' },
];

/**
 * @description 매물 status
 * @Class
 * @category constant
 * @subcategory config
 */
export const adStatusGbn = [
  { cd: '00', name: '대기' },
  { cd: '01', name: '검증중' },
  { cd: '10', name: '정보확인' },
  { cd: '20', name: '등본확인' },
  { cd: '50', name: '노출' },
  { cd: '80', name: '전송실패' },
  { cd: '99', name: '종료' },
];

/**
 * @description 매물 기간 status
 * @Class
 * @category constant
 * @subcategory config
 */
export const monthTerm = [
  { cd: 'B', name: '초순' },
  { cd: 'C', name: '중순' },
  { cd: 'D', name: '하순' },
];
