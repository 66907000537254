import { useEffect, useCallback, useRef, useState, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useAppDispatchThunk, useAppSelector, RootState } from 'redux/store';
import { getBookmarksForSeeks, deleteBookmarkForSeek, resetFeed } from 'api/feed';
import { API_PAGE_SIZE, TOAST_MESSAGE_TYPE, SEEKS_MESSAGE } from 'configs/configs';
import { getDateDiffDays } from 'utils/date';
import { convertSquareMetersToPyung } from 'utils/common';
import { FeedErrorType, SeekPriceType } from '../../@types/feed';
import styled from 'styled-components';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { openModal } from 'redux/slices/modal';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';
import ListLoader from 'components/common/Loader/ListLoader';
import { setRefreshFn } from 'redux/slices/refresh';

const Target = styled.div`
  height: 10px;
`;

/**
 * @description 내피드 구합니다 목록 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @returns {JSX.Element}
 */
const SeekList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchThunk();

  // 검색어 input 요소 ref
  const inputRef = useRef<HTMLInputElement>(null);
  // 무한로딩 교차 기준 요소 ref
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);
  // api 요청 진행 상태, true: 진행 중, false : 진행완료
  const { loading } = useAppSelector((state: RootState) => state.feed);
  // 공유매물(북마크) 데이터
  const seeks = useAppSelector((state: RootState) => state.feed.seek_bookmarks);

  // 검색어
  const [keyword, setKeyword] = useState<string>('');
  // Toast 메세지창 Open 여부
  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);
  const { setToastMessage } = useToast();

  // 구합니다 목록 데이터 로딩
  const loadPage = useCallback(
    async (page: number, keyword: string) => {
      dispatch(getBookmarksForSeeks({ page_size: API_PAGE_SIZE, page: page, keyword: keyword }));
    },
    [dispatch],
  );

  /**
   * @description 검색어 input change 햄들러
   * @returns {void}
   */
  const changeKeyword = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  }, 300);

  /**
   * @description 상세페이지 이동
   * @returns {void}
   */
  const goToView = (id: number) => (event: MouseEvent<HTMLDivElement>) => {
    navigate(`/findsharedetail/${id}`);
  };

  /**
   * @description 북마크 삭제
   * @param {number} id 북마크 id
   * @returns {void}
   */
  const deleteBookmark = async (id: number) => {
    try {
      await dispatch(deleteBookmarkForSeek(id)).unwrap();

      setToastMessage({
        duration: 2000,
        content: TOAST_MESSAGE_TYPE.BOOKMARK_REMOVE,
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        setToastMessage({
          duration: 2000,
          content: TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      }
    }
  };

  // state 형식이 올바르지 않을 경우 reset 처리
  useEffect(() => {
    if (seeks === undefined) {
      dispatch(resetFeed());
    }
  }, [seeks]);

  useEffect(() => {
    // 구합니다 북마크 1 page 로딩
    loadPage(1, keyword);

    // 새로고침 등록
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          //console.log('refresh 구합니다 북마크 keyword', keyword);
          loadPage(1, keyword);
        },
      }),
    );
  }, [loadPage, keyword]);

  useEffect(() => {
    if (loading || !seeks || seeks?.items.length == 0 || !scrollAnchorRef.current) return;

    //console.log('무한로딩(구합니다 북마크) seeks', seeks);
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (seeks.current_page && seeks.total_page && seeks.current_page < seeks.total_page) {
          loadPage(seeks.current_page + 1, keyword);
        }
      }
    });

    observer.observe(scrollAnchorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [seeks?.items, loading]);

  return (
    <>
      {/* 검색어 입력창 */}
      <div className={CommonStyles['region-filter']}>
        <div className={CommonStyles['search-box']}>
          <div
            className={cn(
              CommonStyles['input-area'],
              CommonStyles['search-input'],
              keyword && CommonStyles['clear-has'],
            )}
          >
            <input type="text" placeholder={SEEKS_MESSAGE.KEYWORD_LABEL} ref={inputRef} onChange={changeKeyword} />
            <button
              className={keyword && CommonStyles.delete}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                  setKeyword('');
                }
              }}
            ></button>
          </div>
        </div>
      </div>
      {/* 총 건수 */}
      <div className={CommonStyles['card-count-txt']}>
        총 <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>{seeks?.total}</b>개의 매물이 있습니다.
      </div>
      {/* 구합니다 북마크 목록 */}
      <div className={cn(CommonStyles['sale-card-div'], seeks?.items.length > 0 && CommonStyles['mt16'])}>
        {(!seeks || seeks.items.length == 0) && (
          <div className={CommonStyles['no-data']}>
            <pre>{keyword ? SEEKS_MESSAGE.SEEK_SEARCH_EMPTY : SEEKS_MESSAGE.SEEK_EMPTY}</pre>
          </div>
        )}

        {seeks && seeks.loading === true && <ListLoader />}
        {seeks &&
          seeks.items?.map((seek, index) => {
            // 날짜
            let seekDateInfo =
              seek.created_at != seek.updated_at
                ? `수정일 ${seek.updated_at.replace(/-/g, '.').substring(2, 10)}`
                : `최초 작성일 ${seek.created_at.replace(/-/g, '.').substring(2, 10)}`;

            if (seek.my_seek === 1) {
              const diffShow = getDateDiffDays(new Date(), new Date(seek.expire_date));
              if (seek.expire_date && diffShow > 0 && diffShow <= 7) {
                seekDateInfo += ` (D-${diffShow})`;
              }
            }

            // 매물종류
            let types = null;
            if (seek.types) {
              types = seek.types.split(',');
            }

            // 소재지
            let areas = null;
            if (seek.areas) {
              areas = seek.areas.split(',');
            }

            // 가격
            let priceInfo = '';
            const emptyPrice = seek.price_info.filter((item: SeekPriceType) => item.min === null && item.max === null);
            if (seek.price_info.length > 0 && seek.price_info.length != emptyPrice.length) {
              const priceArr = seek.price_info.map((item: SeekPriceType) => {
                const result =
                  item.min === null && item.max === null
                    ? '-'
                    : `${item.min !== null ? item.min.toLocaleString() : ''}~${
                        item.max !== null ? item.max.toLocaleString() : ''
                      }`;
                return result;
              });

              priceInfo = priceArr.join('/');
            }

            return (
              <div
                key={index}
                className={cn(CommonStyles['sale-card'], CommonStyles['bg-blue'])}
                onClick={goToView(seek.id)}
              >
                <div className={CommonStyles['state-summary']}>
                  {/* 내구함 / 날짜정보 / 업소명 */}
                  <div className={CommonStyles['divide-box']}>
                    {seek.my_seek === 1 && (
                      <p className={cn(CommonStyles['co-dorg'], CommonStyles['has-icon'])}>
                        <i className={CommonStyles['icon-mine']}></i>내 구함
                      </p>
                    )}
                    <p className={CommonStyles['date']}>{seekDateInfo}</p>
                    {!seek.my_seek && <p className={CommonStyles['limit-line1']}>{seek.company_name}</p>}
                  </div>
                </div>
                {/* 상세조건 (제목) */}
                {seek.title && (
                  <div className={cn(CommonStyles['blue-box'], CommonStyles['limit-line2'])}>{seek.title}</div>
                )}
                {/* 상세조건 (내용) */}
                {seek.content && (
                  <p className={cn(CommonStyles['title'], CommonStyles['limit-line1'])}>{seek.content}</p>
                )}
                {/* 매물종류 */}
                {types && (
                  <div className={CommonStyles.type}>
                    {types.length > 0 && types.map((type: string, index: number) => `${index ? ' ' : ''}[${type}]`)}
                  </div>
                )}
                {/* 거래유형 / 가격 */}
                <div className={CommonStyles.price}>
                  <b className={CommonStyles['co-bl']}>{seek.deal_type_name}</b>
                  {priceInfo && <>{priceInfo}만원</>}
                </div>
                {/* 면적 */}
                {(seek.area_min !== null || seek.area_max !== null) && (
                  <div className={CommonStyles.information}>
                    <p>
                      {`${seek.area_min !== null ? convertSquareMetersToPyung(seek.area_min) : ''}`}~
                      {`${seek.area_max !== null ? convertSquareMetersToPyung(seek.area_max) : ''}`}평
                    </p>
                  </div>
                )}
                {/* 소재지 */}
                {areas && areas.length > 0 && (
                  <div className={CommonStyles.description}>
                    {areas.map((area: string, index: number) => `${index ? ' · ' : ''}${area}`)}
                  </div>
                )}
                <div className={CommonStyles['function-div']}>
                  {/* 댓글수 */}
                  <button>
                    <i className={CommonStyles['icon-comment']}></i>
                    {seek.comments_count}
                  </button>
                  {/* 북마크 해제 버튼 */}
                  <button
                    className={cn(CommonStyles.bookmark, CommonStyles.on)}
                    onClick={(event) => {
                      event.stopPropagation();

                      const action = () => deleteBookmark(seek.bookmark_id);
                      dispatch(openModal({ modalType: 'confirm_bookmark_comment', action }));
                    }}
                  >
                    <i className={CommonStyles['icon-bookmark']}></i>
                  </button>
                </div>
              </div>
            );
          })}

        <Target ref={scrollAnchorRef} />
        {/* Toast 팝업 */}
        {isOpen && <Toast />}
      </div>
    </>
  );
};

export default SeekList;
