/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { NaverMap, Marker, useNavermaps } from 'react-naver-maps';

interface Props {
  totalAddress?: string;
  lat?: number;
  setLat?: (lat: number) => void;
  lng?: number;
  setLng?: (lat: number) => void;
}

/**
 * @description 공유매물 Naver map component
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @param {Props} props
 * @param {string} props.totalAddress
 * @param {number} props.lat
 * @param {number} props.lng
 * @param {function} props.setLat
 * @param {function} props.setLng
 * @returns {JSX.Element}
 */
const NaverMapPreview = (props: Props): JSX.Element => {
  const { naver } = window;
  const navermaps = useNavermaps();
  const { totalAddress, lat, lng, setLat, setLng } = props;

  const [address, setAddress] = useState(totalAddress || '');
  const [roadAddress, setRoadAddress] = useState('');

  const [zoom, setZoom] = useState(12);

  console.log('addr in maps', totalAddress);
  console.log('lat in maps==>', lat);
  console.log('lng in maps==>', lng);

  useEffect(() => {
    const f = async () => {
      if (totalAddress) {
        await searchAddressToCoordinate(totalAddress!);
      }
    };
    f();
  }, [totalAddress]);

  const searchAddressToCoordinate = async (addr: string) => {
    naver.maps.Service.geocode(
      {
        query: addr,
      },
      (status: any, response: { v2: any }) => {
        if (status !== naver.maps.Service.Status.OK) {
          return console.error('Something wrong!');
        }

        const result = response.v2;
        console.log('result===>', result);
        const items = result.addresses;
        const x = parseFloat(items[0]?.x);
        const y = parseFloat(items[0]?.y);

        setLat!(y);
        setLng!(x);
        setZoom(15);
        setRoadAddress(items[0]?.roadAddress);
      },
    );
  };

  const center = new navermaps.LatLng(37.5666103, 126.9783882);

  return (
    <>
      <NaverMap center={new navermaps.LatLng(lat, lng)} defaultZoom={12}>
        <Marker position={{ lat, lng }} titie={roadAddress!} clickable />
      </NaverMap>
    </>
  );
};

export default NaverMapPreview;
