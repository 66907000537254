import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PostList from 'pages/feed/PostList';
import CommentList from 'pages/feed/CommentList';
import OfferingList from 'pages/feed/OfferingList';
import SeekList from 'pages/feed/SeekList';
import GradeInfo from 'pages/feed/GradeInfo';
import network from 'utils/network';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

import { useAppDispatch, useAppSelector, RootState } from 'redux/store';

import * as ActionUser from 'redux/slices/user';
import * as Config from 'configs/configs';
import { onView } from 'utils';

interface MyFeedPropsType {
  type: 'my' | 'bookmark'; // my : 내가 쓴 게시글, bookmark: 북마크 게시글
}

const MyFeed = ({ type = 'my' }: MyFeedPropsType) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // 내가 쓴글 종류(post: 게시판, comment: 댓글, offerings: 공유매물, seeks: 구합니다)
  const [feedType, setFeedType] = useState<string>('');
  // 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
  //const boardType = new URLSearchParams(window.location.search).get('type') ?? 'free';
  const [boardType, setBoardType] = useState<'free' | 'group'>('free');
  //const pathName = new URL(window.location.href).pathname;
  const me = useAppSelector((state: RootState) => state.user.me);
  // 중개사 정보
  const [borderInfo, setBrokerInfo] = useState<string>('');
  // 등급안내 팝업 노출여부
  const [isGradeInfo, setGradeInfo] = useState<boolean>(false);
  //const [me, setMe] = useState(infoMeRedux || {});

  const getMe = async () => {
    try {
      const response = await network().sso().me();
      dispatch(ActionUser.setMe(response.data));
    } catch (error) {
      //console.log('getMe(error):', error);
    }
  };

  // 내 정보 가져오기
  useEffect(() => {
    dispatch(ActionUser.resetHistoryPath()); // mainview 마운트시 history path 초기화 설정

    if (type != 'my') return;

    getMe();
  }, [type]);

  // 공인중개사 정보
  useEffect(() => {
    if (!me?.verify) {
      setBrokerInfo('');
      setFeedType('post');
      return;
    }

    let info = '';
    if (me.verify?.is_ceo == 1) {
      info = '대표공인중개사';
    } else if (me.verify?.broker_code == 2) {
      info = '소속공인중개사';
    } else if (me.verify?.broker_code == 4) {
      info = '중개보조원';
    }
    setBrokerInfo(info);
    setFeedType('post');
  }, [me?.verify]);

  return (
    <>
      <div
        className={cn(
          CommonStyles['content'],
          CommonStyles['my-feed'],
          Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
        )}
      >
        {type == 'bookmark' && me?.verify && (
          <div className={cn(CommonStyles['tab-menu'], CommonStyles['sub-tab'])}>
            <button
              className={cn(CommonStyles['tab'], feedType == 'offerings' && CommonStyles['on'])}
              onClick={() => setFeedType('offerings')}
            >
              공유매물
            </button>
            <button
              className={cn(CommonStyles['tab'], feedType == 'seeks' && CommonStyles['on'])}
              onClick={() => setFeedType('seeks')}
            >
              구합니다
            </button>
            <button
              className={cn(CommonStyles['tab'], feedType == 'post' && CommonStyles['on'])}
              onClick={() => {
                setFeedType('post');
                setBoardType('free');
              }}
            >
              게시글
            </button>
          </div>
        )}

        {type == 'my' && (
          <>
            <div className={CommonStyles['my-info-box']}>
              {/* 프로필 영역 */}
              <div className={cn(CommonStyles['member-profile'], CommonStyles['horizon'])}>
                <div className={CommonStyles['mem-info']}>
                  <p className={CommonStyles.name}>{me?.nickname}</p>
                  <p className={CommonStyles['mem-id']}>
                    {me?.loginType === 'aipartner'
                      ? me?.login_id
                      : me?.loginType && Config.LOGIN_SNS_TYPE[me?.loginType]
                      ? Config.LOGIN_SNS_TYPE[me?.loginType]
                      : ''}
                  </p>
                  {me?.verify && (
                    <div className={CommonStyles['divide-box']}>
                      {me?.company?.name && (
                        <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {me?.company?.name}
                        </p>
                      )}
                      <p>{borderInfo}</p>
                    </div>
                  )}

                  <p className={CommonStyles['grade-txt']}>
                    회원님의 현재 등급은 <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>{me?.grade}</b>{' '}
                    입니다.
                  </p>
                </div>
                <button
                  className={CommonStyles.profile}
                  onClick={() => {
                    if (import.meta.env.MODE === 'localdev') {
                      navigate(`/feed/profile`);
                    } else {
                      onView(`${import.meta.env.VITE_APP_DOMAIN}/feed/profile`);
                    }
                  }}
                >
                  <div
                    className={CommonStyles['img-div']}
                    style={{ backgroundImage: me?.profile_photo_path ? `url(${me.profile_photo_path})` : undefined }}
                  ></div>
                  <span className={CommonStyles['setting-btn']}>
                    <i className={CommonStyles['icon-modify']}></i>
                  </span>
                </button>
              </div>
              {/* 등급안내/북마크 버튼 */}
              <div className={CommonStyles['btn-wrap']}>
                <button
                  className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles.gry)}
                  onClick={() => setGradeInfo(true)}
                >
                  등급안내
                </button>
                <button
                  className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles.gry)}
                  onClick={() => {
                    if (import.meta.env.MODE === 'localdev') {
                      navigate(`/feed/bookmarks`);
                    } else {
                      onView(`${import.meta.env.VITE_APP_DOMAIN}/feed/bookmarks`);
                    }
                  }}
                >
                  북마크
                </button>
              </div>
            </div>
            {/* 게시글/댓글 탭 */}
            <div className={cn(CommonStyles['tab-menu'], CommonStyles['sub-tab'])}>
              <button
                className={cn(CommonStyles.tab, feedType == 'post' && CommonStyles.on)}
                onClick={() => {
                  //navigate('/feed');
                  setFeedType('post');
                  setBoardType('free');
                }}
              >
                게시글
              </button>
              <button
                className={cn(CommonStyles.tab, feedType == 'comment' && CommonStyles.on)}
                onClick={() => {
                  //navigate('/feed/comments');
                  setFeedType('comment');
                  setBoardType('free');
                }}
              >
                댓글
              </button>
            </div>
          </>
        )}

        {/* 
           전체 게시판/가입한 모임 게시판 탭 
           - 내피드, 북마크 게시글에만 노출
        */}
        {(type == 'my' || (type == 'bookmark' && feedType == 'post')) && (
          <div className={cn(CommonStyles['tab-menu'], CommonStyles['round-tab'])}>
            <button
              className={cn(CommonStyles.tab, boardType == 'free' && CommonStyles.on)}
              onClick={() => setBoardType('free')}
            >
              전체 게시판
            </button>
            <button
              className={cn(CommonStyles.tab, boardType == 'group' && CommonStyles.on)}
              onClick={() => setBoardType('group')}
            >
              가입한 모임 게시판
            </button>
          </div>
        )}

        {type == 'my' ? (
          feedType == 'post' ? (
            <PostList type={type} boardType={boardType} refreshFun={() => getMe()} />
          ) : (
            <CommentList boardType={boardType} refreshFun={() => getMe()} />
          )
        ) : feedType == 'post' ? (
          <PostList type={type} boardType={boardType} />
        ) : feedType == 'offerings' ? (
          <OfferingList />
        ) : feedType == 'seeks' ? (
          <SeekList />
        ) : null}
      </div>
      {/* 등급안내 팝업 */}
      {type == 'my' && <GradeInfo me={me} show={isGradeInfo} onShow={setGradeInfo} />}
    </>
  );
};
export default MyFeed;
