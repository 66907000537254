import { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Panel from 'components/common/Panel/Panel';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { modalIndex } from './ModalIndex';
import * as ActionModal from 'redux/slices/modal';
import Styles from 'styles/modal.module.css';

/**
 * @description modal or sheet 분기 처리 컴포넌트
 * @Class
 * @category Components
 * @subcategory Modal Manager
 * @component
 * @returns {JSX.Element}
 */
export default function ModalManager() {
  const dispatch = useAppDispatch();
  const { modalType, multiModalType, isOpen, isMultiple } = useAppSelector((state: RootState) => state.modal);
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [multiple, setMultiple] = useState<boolean>(isMultiple || false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  useEffect(() => {
    setMultiple(multiple);
  }, [multiple]);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const handleModalClose = (b: boolean) => {
    setOpen!(b);
    dispatch(ActionModal.closeModal({ modalType: modalType, data: {}, isOpen: b }));
  };

  const handleMultipleModalClose = (b: boolean) => {
    setMultiple!(b);
    dispatch(ActionModal.closeMultiModal({ multiModalType: multiModalType, multiData: {}, isMultiple: b }));
  };

  const findModal = modalIndex?.find((modal) => {
    return modal?.modalType === modalType;
  });

  const findMultiModal = modalIndex?.find((modal) => {
    return modal?.modalType === multiModalType;
  });

  const renderModal = () => {
    return findModal?.component || <></>;
  };

  const renderPopupModal = () => {
    return (
      (
        <div
          onClick={(e) => {
            if (modalType === 'confirm_group_delete_group' || modalType === 'confirm_group_quit_group') {
              e.stopPropagation();
            } else {
              e.stopPropagation();
              handleModalClose(!open);
            }
          }}
        >
          {findModal?.component}
        </div>
      ) || <></>
    );
    // return <div>{findModal?.component}</div> || <></>;
  };

  const multiclosableRenderModal = () => {
    return (
      (
        <div
          onClick={(e) => {
            e.stopPropagation();
            multiModalType && handleMultipleModalClose(!open);
          }}
        >
          {findMultiModal?.component}
        </div>
      ) || <></>
    );
  };

  const multiRenderModal = () => {
    return findMultiModal?.component || <></>;
  };

  return (
    <>
      {(multiModalType?.includes('bottomSheet') ||
        multiModalType?.includes('groupBotSheet') ||
        multiModalType?.includes('groupSelectBotSheet') ||
        multiModalType?.includes('show_comment') ||
        multiModalType?.includes('fullSheet') ||
        multiModalType?.includes('broker_bottom') ||
        multiModalType?.includes('bottomSheet_feed') ||
        multiModalType?.includes('default_sheet') ||
        multiModalType?.includes('share_filter_full') ||
        multiModalType?.includes('share_property') ||
        multiModalType?.includes('findshare_bot_sheet')) &&
        isMultiple && (
          <Panel open={isMultiple!} onDismiss={() => handleMultipleModalClose(!open)} modalType={multiModalType}>
            {multiRenderModal()}
          </Panel>
        )}
      {modalType?.includes('bottomSheet') ||
      modalType?.includes('groupBotSheet') ||
      modalType?.includes('groupSelectBotSheet') ||
      modalType?.includes('show_comment') ||
      modalType?.includes('fullSheet') ||
      modalType?.includes('broker_bottom') ||
      modalType?.includes('bottomSheet_feed') ||
      modalType?.includes('default_sheet') ||
      modalType?.includes('share_filter_full') ||
      modalType?.includes('share_property') ||
      modalType?.includes('coagency_bottom') ||
      modalType?.includes('findshare_bot_sheet') ||
      modalType?.includes('coagency_import_input') ||
      modalType?.includes('coagency_import_all_confirm') ? (
        <>
          <Panel open={isOpen!} onDismiss={() => handleModalClose(!open)} modalType={modalType}>
            {renderModal()}
          </Panel>
          {isMultiple && (
            <Panel open={isMultiple!} onDismiss={() => handleMultipleModalClose(!open)} modalType={multiModalType}>
              {multiModalType?.includes('coagency_select') ? multiclosableRenderModal() : multiRenderModal()}
            </Panel>
          )}
        </>
      ) : (
        <Modal
          isOpen={isOpen!}
          onRequestClose={() => handleModalClose(!open)}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          style={{
            overlay: {
              position: 'fixed',
              zIndex: 9999999999,
              inset: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
          className={Styles.modal}
          ariaHideApp={false}
        >
          {renderPopupModal()}
        </Modal>
      )}
    </>
  );
}
