import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isCommentLoading: false,
  isDataLoading: false,
};

/**
 * @description loading
 * @Class
 * @category redux
 * @subcategory slice
 */
const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    onLoad: (state) => {
      state.isLoading = true;
    },
    offLoad: (state) => {
      state.isLoading = false;
    },
    onCommentLoad: (state) => {
      state.isCommentLoading = true;
    },
    offCommentLoad: (state) => {
      state.isCommentLoading = false;
    },
    onDataLoad: (state) => {
      state.isDataLoading = true;
    },
    offDataLoad: (state) => {
      state.isDataLoading = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { onLoad, offLoad, onCommentLoad, offCommentLoad, onDataLoad, offDataLoad } = loadingSlice.actions;

export default loadingSlice;
