import CommonStyles from 'styles/common.module.css';

/**
 * @description scroll시 생기는 loader
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @returns {JSX.Element}
 */
const ScrollLoader = () => {
  return <div className={CommonStyles['loading-refresh']}></div>;
};

export default ScrollLoader;
