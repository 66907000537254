import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';
import ToggleButton from './ToggleButton';

interface ToggleListButtonInterface {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  id: string; // toggle list button id *required 리스트 클릭시 toggle button을 작동하기 위해서 필수 값
  title: string; // toggle list title
  text?: string; // toggle list text
  checked?: boolean;
  disabled?: boolean; // 버튼 비활성화
}

/**
 * @description Toggle List button
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {void} props.onChange  toggle button onChange 액션
 * @param {string} props.id toggle list button id *required 리스트 클릭시 toggle button을 작동하기 위해서 필수 값
 * @param {string} props.title toggle list title
 * @param {string} props.text toggle list text
 * @param {boolean} props.checked toggle checked
 * @param {boolean} props.disabled toggle enabled disabled
 * @returns {JSX.Element}
 */
const ToggleListButton: React.FC<ToggleListButtonInterface> = ({
  onChange = () => {},
  id = '',
  title = '',
  text = '',
  checked = false,
  disabled = false,
}) => {
  let defaultProps = {};

  if (id) {
    defaultProps = {
      ...defaultProps,
      id: id,
    };
  }
  return (
    <div className={cn(CommonStyles['toggle-list'])}>
      <label htmlFor={id}>
        <p className={cn(CommonStyles['tit'])}>{title}</p>
        {text && <p className={cn(CommonStyles['text'])}>{text}</p>}
      </label>
      <ToggleButton id={id} onChange={onChange} checked={checked} disabled={disabled} />
    </div>
  );
};

export default ToggleListButton;
