import { useCallback, useState, useEffect, ChangeEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionsComment from 'redux/slices/comment';
import * as ActionModal from 'redux/slices/modal';
import { sendPostComment, updatePostComment } from 'api/comment';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import AWS from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import _ from 'lodash';

import Button from '../Button/Button';
import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';

// configs
import * as Config from 'configs/configs';
import { ICommentType, TisEdit } from '<comments>';
import { IMixedKeyValue } from '<modal>';

type TUploadFile = {
  mime_type: string;
  org_name: string;
  url: string;
};
interface StyledProps {
  $height?: number;
  $bottomheight?: number;
}

const PreviewDiv = styled.div<StyledProps>`
  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : '88px')};
  position: absolute;
  bottom: ${({ $bottomheight }) => ($bottomheight ? `${$bottomheight}px` : '90px')};
`;

interface CommentFormInterface {
  id?: string; // textarea id
  value?: string; // textarea value
  maxLength?: number; // textarea maxLength
  placeholder?: string; // textarea placeholder
  onChange?: (value: string) => void; // textarea 이벤트
  submitHandler?: (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => void; // 게시 버튼 함수
  sheetOpen?: boolean;
  data?: IMixedKeyValue;
  commentType?: ICommentType;
  setCommentType?: (commentType: ICommentType) => void;
  isEdit?: TisEdit;
  setIsEdit?: (isEdit: TisEdit) => void;
}

/**
 * @description 댓글 Form F.C
 * @Class
 * @category Components
 * @subcategory 댓글
 * @param {CommentProps} props
 * @param {number | string} props.id
 * @param {number | string} props.value
 * @param {function} props.onChange
 * @param {string} props.placeholder
 * @param {number} props.maxLength
 * @param {function} props.sheetOpen
 * @param {object} props.data
 * @param {object} props.commentType
 * @param {function} props.setCommentType
 * @param {object} props.isEdit
 * @param {function} props.setIsEdit
 * @returns {JSX.Element}
 */
const CommentForm = ({
  id = '',
  value = '',
  onChange,
  placeholder = '',
  maxLength,
  sheetOpen,
  data,
  commentType,
  setCommentType,
  isEdit,
  setIsEdit,
}: CommentFormInterface): JSX.Element => {
  const location = useLocation();
  const path = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const feedType = searchParams.get('type');
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state: RootState) => state.comment);
  // const submitButtonRef = useRef<HTMLButtonElement>(null);
  const commentFormRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  // 이모지 선택창
  const [emoji, setEmoji] = useState<boolean>(false);
  // 선택된 파일
  const [file, setFile] = useState<string | null>(null);
  const [uploadedImages, setUploadedImages] = useState<TUploadFile[]>([]);
  // 선택된 이모지
  const [selectedEmoji, setSelectedEmoji] = useState<string>('');
  const [insertHeight, setInsertHeight] = useState<number>(0);
  const [isMention, setIsMention] = useState(false);
  const s3Regex = /[^a-zA-Z0-9가-힣ㄱ-ㅎㅏ-ㅣ._-]/g;

  const enterRef = useRef<boolean>(false);

  useEffect(() => {
    if (sheetOpen) {
      commentFormRef?.current?.focus();
    }
  }, [sheetOpen]);

  useEffect(() => {
    const _insertHeight = document.getElementById('insert')?.offsetHeight;
    if (_insertHeight) setInsertHeight(_insertHeight!);
  }, [value, emoji, file]);

  useEffect(() => {
    commentType?.focusDepth !== '1depthComment' && setIsMention(true);
  }, [commentType]);

  /**
   * @description edit mode에서 contents가져오기
   */
  useEffect(() => {
    if (isEdit?.isEditOn) {
      let _content;
      let _emoticon;
      let _photos;
      if (isEdit.editTarget === '1depth') {
        console.log('edit 1depth');
        _content = comments?.commentLists?.find((item) => item.id === isEdit.editCommentId)?.content;
        _emoticon = comments?.commentLists?.find((item) => item.id === isEdit.editCommentId)?.emoticon;
        _photos = comments?.commentLists?.find((item) => item.id === isEdit.editCommentId)?.photos;
      } else if (isEdit.editTarget === '2depth') {
        console.log('edit 2depth');
        const commentWithChildIndex = comments?.commentLists?.findIndex(
          (comment) => comment.id === isEdit?.parentCommentId,
        );
        const commentAllChildren = comments?.commentLists[commentWithChildIndex]?.children;
        _content = commentAllChildren?.find((item) => item.id === isEdit?.editCommentId)?.content;
        _emoticon = commentAllChildren?.find((item) => item.id === isEdit?.editCommentId)?.emoticon;
        _photos = commentAllChildren?.find((item) => item.id === isEdit?.editCommentId)?.photos;
      }
      onChange!(_content!);
      setSelectedEmoji(_emoticon!);
      setFile(_photos && _photos[0]?.url);
      setUploadedImages(_photos!);
      commentFormRef?.current?.focus();
    }
  }, [isEdit, comments]);

  // 이모지 창 열기/닫기
  const handleClickOpenEmoji = useCallback(
    (e: React.ChangeEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setEmoji(!emoji);
    },
    [emoji],
  );

  // 이모지 선택
  const handleClickEmoji = useCallback((selected: string) => {
    setSelectedEmoji(selected);
    setEmoji(false);
  }, []);

  // 파일선택 창 띄우기
  const handleClickAlbum = useCallback(
    (e: React.ChangeEvent<HTMLButtonElement>) => {
      e.preventDefault();
      fileInputRef?.current?.click();
    },
    [fileInputRef.current],
  );

  // AWS config and new s3
  AWS.config.update({
    region: import.meta.env.VITE_S3_BUCKET_REGION,
    accessKeyId: import.meta.env.VITE_S3_BUCKET_ACCESSKEY,
    secretAccessKey: import.meta.env.VITE_S3_BUCKET_SECRETKEY,
  });
  const s3 = new AWS.S3();

  /**
   * @description upload  photo image to S3
   * @param {File[]} files 파일
   * @param {string} bucketName
   * @param {string[]} keys
   * @returns {Promise<ManagedUpload.SendData[]>}
   */
  const uploadImagesToS3 = async (
    files: File[],
    bucketName: string,
    keys: string[],
  ): Promise<ManagedUpload.SendData[]> => {
    const uploadPromises: Promise<ManagedUpload.SendData>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = keys[i];

      const params: AWS.S3.PutObjectRequest = {
        Bucket: `${Config.BUCKET_NAME}/temp/photo`,
        Key: key,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
        ContentDisposition: `attachment; filename="${file?.name.replace(s3Regex, '').replace(/\s/g, '')}"`,
      };

      const upload = new AWS.S3.ManagedUpload({
        params,
        service: s3,
      });

      uploadPromises.push(upload.promise());
    }

    return Promise.all(uploadPromises);
  };

  /**
   * @description image change handler
   * @param {object} event React.syntheticEvent
   * @returns {Promise<void>}
   */
  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = event.target;
    const selectedFile = event.target.files?.[0];

    if (!files) return;
    if (files?.length > 1) {
      dispatch(ActionModal.openModal({ modalType: 'alert_image_limit', isOpen: true }));
      event.target.value = ''; // 파일 선택 취소
      return;
    }

    if (selectedFile) {
      // 용량 체크
      const maxSize = 20 * 1024 * 1024; // 20MB (20 * 1024 * 1024 bytes)
      if (selectedFile.size > maxSize) {
        // 파일 용량 제한
        dispatch(ActionModal.openModal({ modalType: 'alert_image_size_limit', isOpen: true }));
        event.target.value = ''; // 파일 선택 취소
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        setFile(e.target?.result as string); // Optional chaining을 사용하여 null 체크
      };
      reader.readAsDataURL(selectedFile);
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const saniTizedFileName = file.name.replace(s3Regex, '').replace(/\s/g, '');
      // 피일 확장자 제한,
      const fileExt = saniTizedFileName?.split('.').pop()?.toLowerCase();
      if (!fileExt || !['jpg', 'jpeg', 'png'].includes(fileExt)) {
        dispatch(ActionModal.openModal({ modalType: 'alert_image_extension_limit', isOpen: true }));
        event.target.value = '';
        return;
      }

      // const saniTizedFileName = file.name.replace(s3Regex, '').replace(/\s/g, '');
      const encodedName = encodeURIComponent(saniTizedFileName);
      Object.defineProperty(file, 'name', {
        writable: true,
        value: encodedName,
      });
    }

    const fileList = Array.from(files);
    // const keys = fileList?.map((file) => decodeURIComponent(file.name.replace(s3Regex, '').replace(/\s/g, '')));
    const keys = fileList?.map((file) => decodeURIComponent(file.name));
    const bucketName = 'photo_bucket_name';

    try {
      const uploadData = await uploadImagesToS3(fileList, bucketName, keys);
      uploadData?.forEach((upload, index) => {
        const fileType = 'photo';
        const orgName = decodeURIComponent(decodeURI(upload.Location.split('/').pop() || ''));
        const extensionRegex = /[^.]+$/;
        const extensionMatch = orgName.match(extensionRegex);
        let mimeType = '';

        if (extensionMatch) {
          const extension = extensionMatch[0].toLowerCase();
          if (extension === 'svg') {
            mimeType = 'image/svg+xml';
          } else if (extension === 'jpg') {
            mimeType = 'image/jpeg';
          } else {
            mimeType = `image/${extension}`;
          }
        }
        const imagePath = {
          file_type: fileType,
          mime_type: mimeType,
          org_name: orgName,
          url: upload.Location.replace(/%25/g, '%')
            .replace(/%3B/g, ';')
            .replace(/%3A/g, ':')
            .replace(/%2B/g, '+')
            .replace(/%2F/g, '/'),
        };
        setUploadedImages((prev: TUploadFile[]) => [...prev, imagePath]);
        event.target.value = ''; // 파일 선택 취소
      });
    } catch (error) {
      event.target.value = ''; // 파일 선택 취소
      console.error('Image upload failed:', error);
    }
  };

  /**
   * @description 미리보기 창 닫기
   * @returns {void}
   */
  const handleClosePreview = useCallback(() => {
    if (selectedEmoji) {
      setSelectedEmoji('');
      setEmoji(false);
    }

    if (file) {
      setFile(null);
      setUploadedImages([]);
    }
  }, [selectedEmoji, file, emoji]);

  /**
   * @description 멘션 preview 닫기
   * @returns {void}
   */
  const handleCloseMentionPreview = useCallback(() => {
    setIsMention(false);
    setCommentType!({
      ...commentType,
      focusDepth: '1depthComment',
      parentId: '',
      mentionId: '',
      mentionNickname: '',
      isOn: false,
    } as ICommentType);
    onChange!('');
  }, [isMention]);

  let defaultProps = {};

  if (maxLength) {
    defaultProps = {
      ...defaultProps,
      maxLength: +maxLength,
    };
  }

  if (id) {
    defaultProps = { ...defaultProps, id: id };
  }

  /**
   * @description 댓글 submit
   * @param {object} e React.SyntheticEvent
   * @returns {void}
   */
  const submitHandler = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.currentTarget.disabled = true;

    const params = {
      parent_id:
        isEdit?.isEditOn && isEdit?.editTarget === '2depth'
          ? Number(isEdit?.parentCommentId)
          : Number(commentType?.parentId) || null,
      // mention_id: Number(commentType?.mentionId) || null,
      content: value, // 댓글 내용
      emoticon: selectedEmoji, // 이모티콘
      photos:
        uploadedImages?.length > 0
          ? uploadedImages?.map((k) =>
              _.assign({
                file_type: 'photo',
                mime_type: k.mime_type,
                org_name: k.org_name,
                url: k.url,
              }),
            )
          : [], // 사진
    };
    if (isEdit?.isEditOn) {
      if (path.includes('/allboard') || (path.includes('/feed') && feedType !== 'group')) {
        dispatch(updatePostComment(isEdit.editCommentId!, params, isEdit.editTarget));
      } else {
        dispatch(
          updatePostComment(isEdit.editCommentId!, params, isEdit.editTarget, data?.id, data?.group_id, data?.isType),
        );
      }
    } else {
      dispatch(sendPostComment(data?.id, params, data?.group_id, data?.isType));
    }
    onChange!(''); // 초기화
    handleClosePreview();
    enterRef.current = false;
    handleCloseMentionPreview();
    setIsEdit!({
      editTarget: '1depth',
      parentCommentId: null,
      editCommentId: null,
      isEditOn: false,
    });
    e.currentTarget.disabled = false;
  };

  /**
   * @description keypress 동작
   * @param {object} e React.SyntheticEvent
   * @returns {void}
   */
  const handleKeypress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // if (e.key === 'Enter' && e.shiftKey === false) {
    //   if (enterRef.current) return;
    //   e.preventDefault();
    //   if (!enterRef.current) {
    //     enterRef.current = true;
    //     submitHandler!(e);
    //   }
    // }
  };

  // console.log('offsetHeight', document.getElementById('insert')?.offsetHeight);
  // console.log('insertHeight', insertHeight);
  // console.log('uploadedImages', uploadedImages);
  // console.log('value==>', value);
  // console.log('file==>', file);
  // console.log('selectedEmoji==>', selectedEmoji);
  // console.log('data in CommentForm', data);

  return (
    <>
      <div id="insert" className={CommonStyles['comment-insert']} style={{ position: 'relative' }}>
        {(selectedEmoji || file) && (
          <PreviewDiv $bottomheight={insertHeight} $height={88}>
            <div className={CommonStyles['preview-div']} style={{ position: 'relative' }}>
              {selectedEmoji && (
                <div className={CommonStyles['emoji-box']}>
                  <img src={`/images/${selectedEmoji}`} alt="미리보기 이미지" />
                </div>
              )}
              {file && (
                <div className={CommonStyles['img-box']}>
                  <img src={file} alt="미리보기 사진" />
                </div>
              )}
              <Button className={CommonStyles['close-btn']} onClick={handleClosePreview}>
                <i className={CommonStyles['icon-x-wh']}></i>
              </Button>
            </div>
          </PreviewDiv>
        )}

        {!selectedEmoji && !file && isMention && value && (
          <PreviewDiv $bottomheight={insertHeight} $height={40}>
            <div className={cn(CommonStyles['preview-div'], CommonStyles['mention-div'])}>
              {commentType?.mentionNickname && <div>{`${commentType?.mentionNickname}님에게 답글 남기는 중`}</div>}
              <Button className={CommonStyles['close-btn']} onClick={handleCloseMentionPreview}>
                <i className={CommonStyles['icon-x-bk']}></i>
              </Button>
            </div>
          </PreviewDiv>
        )}
        <form onSubmit={submitHandler}>
          <div className={CommonStyles['insert-form']}>
            <div className={CommonStyles['textarea-div']}>
              <TextareaAutosize
                cacheMeasurements
                maxRows={4}
                minRows={1}
                ref={commentFormRef}
                spellCheck={false}
                name="content"
                placeholder={placeholder}
                style={{ height: 20, overflowY: 'scroll' }}
                maxLength={1000}
                onChange={(e) => onChange!(e.target.value)}
                onKeyDown={handleKeypress}
                value={value}
                {...defaultProps}
              />
              <div className={CommonStyles['attached-tool']}>
                <Button className={`none`} onClick={handleClickAlbum}>
                  <i className={CommonStyles['icon-album']}></i>
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  id="comment_file"
                  key={Date.now()} // Change the key when the component re-renders
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
                <Button
                  className={`none`}
                  onClick={(e) => {
                    handleClickOpenEmoji(e);
                  }}
                >
                  <i className={CommonStyles['icon-emoji']}></i>
                </Button>
              </div>
            </div>
            <Button
              className={cn(
                CommonStyles.btn,
                CommonStyles['post-btn'],
                !value && !selectedEmoji && !file && CommonStyles.disabled,
              )}
              size={'middle'}
              mergeClass={true}
              onClick={submitHandler}
              disabled={!value && !selectedEmoji && !file}
            >
              게시
            </Button>
          </div>
        </form>

        {emoji && (
          <div className={CommonStyles['emoji-select']}>
            {Config.EMOJIICON_LIST?.map((item: string, index: number) => {
              return (
                <Button
                  key={`${item}-${index}`}
                  className={cn(CommonStyles['emoji'], CommonStyles[item])}
                  onClick={() => handleClickEmoji(`emoji_${item}.svg`)}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CommentForm;
