import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Button from 'components/common/Button/Button';
import ToggleListButton from 'components/common/Button/ToggleListButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from 'redux/slices/modal';
import * as APIS from 'api/group';
import { initGroupInfo, setGroupConfig, setGroupInfo } from 'redux/slices/group';
import { RootState } from 'redux/store';
import { offLoad, onLoad } from 'redux/slices/loading';
import { join } from 'lodash';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';
import { moveMain, onClose } from 'utils';

/**
 * @description 모임 설정
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupSetting = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groupData = useSelector((state: RootState) => state.group);
  const { id = '' } = params;
  const [alarm, setAlarm] = useState<string>('N');
  const [total, setTotal] = useState<number>(0);
  const { join_condition, kind, open, group, myInfo } = groupData;
  const { open_info, join_condition: join_type = {} } = group;
  const { broker_type = '' } = join_type || {};

  useEffect(() => {
    if (id) {
      // 모임설정 config 조회
      const f = async () => {
        await fetchGroupConfig();
        await fetchConfig();
      };
      f();
    }
  }, []);

  useEffect(() => {
    if (group?.join_alarm) {
      setAlarm(group.join_alarm);
    }
  }, [group]);

  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );

    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  /**
   * @description 새로고침
   * @returns {Promise<void>}
   */
  const refreshFn = () => {
    fetchConfig();
    fetchGroupConfig();
    fetchList();
  };

  /**
   * @description 모임 설정 config 항목 조회 api
   * @returns {Promise<void>}
   */
  const fetchConfig = async () => {
    try {
      const res = await APIS.getGroupConfig('/groups/config');

      const { data = {} } = res;

      if (data) {
        const { join_condition = [], kind = [], open = [], type = [] } = data;
        dispatch(
          setGroupConfig({
            kind,
            join_condition,
            open,
            type,
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  /**
   * @description 모임 설정 config 항목 조회 api
   * @returns {Promise<void>}
   */
  const currentUrl = window.location.href;
  let res = '';
  const fetchGroupConfig = async () => {
    if (id === '') return;
    try {
      const urlSuffix = currentUrl.includes('/mygroup/group/') ? '/info' : '/basicinfo';
      res = await APIS.getGroupBasicInfo(`/groups/${id}${urlSuffix}`);
      const { data }: any = res;

      if (data) {
        const { myinfo = [], group = {}, other = {}, permissoin = {} } = data;
        const myData = myinfo.length > 0 ? myinfo[0] : {};
        dispatch(
          setGroupInfo({
            myInfo: { ...myData },
            group: { ...group },
            other: { ...other },
            permissoin: { ...permissoin },
          }),
        );
      }
    } catch (err: any) {
      if (err?.response?.data?.error === 'Data not found') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '존재하지 않는 모임입니다.',
            },
            isOpen: true,
            action: () => {
              moveMain(0, '');
              onClose();
            },
          }),
        );
      }
      console.log(err, 'err');
    }
  };

  // 회원가입시 알람 설정
  // const fetchAlarm = async () => {
  //   try {
  //     // dispatch(onLoad());
  //     const res = await APIS.putGroupAlarm(`/groups/${id}/join_alarm`);
  //     const { data } = res;
  //     const { join_alarm } = data;

  //     if (join_alarm) {
  //       dispatch(
  //         setGroupInfo({
  //           ...groupData,
  //           group: {
  //             ...group,
  //             join_alarm: join_alarm,
  //           },
  //         }),
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err, 'err');
  //   }
  // };

  /**
   * @description 모임 이름 및 사진 페이지로 이동
   * @returns {Promise<void>}
   */
  const movePageModifyGroupImage = useCallback(() => {
    navigate(`/modifygroupimage/${id}`);
  }, []);

  /**
   * @description 모임 소개 및 지역 페이지로 이동
   * @returns {Promise<void>}
   */
  const movePageModifyGroupIntroduce = useCallback(() => {
    navigate(`/modifygroupintroduce/${id}`);
  }, []);

  /**
   * @description 모임 가입 신청 관리 페이지로 이동
   * @returns {Promise<void>}
   */
  const movePageModifyGroupApprovalManagement = useCallback(() => {
    navigate(`/groupapprovalmanagement/${id}`);
  }, []);

  /**
   * @description 모임 멤버 페널티 설정 페이지 이동
   * @returns {Promise<void>}
   */
  const movePageMemberPanelty = useCallback(() => {
    navigate(`/groupmemberpanelty/${id}`);
  }, []);

  /**
   * @description 모임 모임장 설정 페이지 이동
   * @returns {Promise<void>}
   */
  const movePageGroupLeader = useCallback(() => {
    navigate(`/groupleader/${id}`);
  }, []);

  // 모임 공동모임장 설정
  // const movePageGroupCommonLeader = useCallback(() => {
  //   navigate(`/groupcommonleader/${id}`);
  // }, []);

  /**
   * @description 모임 신고된 게시물 목록 페이지 이동
   * @returns {Promise<void>}
   */
  const moveReportList = useCallback(() => {
    navigate(`/groupreportlist/${id}`);
  }, []);

  /**
   * @description 모임 공개 / 비공개 설정 팝업 sheet open
   * @returns {Promise<void>}
   */
  const handleClickIsGroup = useCallback(() => {
    dispatch(
      openModal({ isOpen: true, modalType: 'groupBotSheet_group_public_setting', action: modifyGroupCondition }),
    );
  }, []);

  /**
   * @description 모임 공개 / 비공개 설정 api
   * @param {number} key 모임 권한 key
   * @param {boolean} apply 모입 가입 승인 기능 사용 여부
   * @returns {Promise<void>}
   */
  const modifyGroupCondition = async (key: number, apply: boolean = false) => {
    if (key) {
      try {
        dispatch(onLoad());
        const payload = {
          open: key,
          join_apply_use: apply,
        };
        await APIS.putIsGroup(`/groups/${id}/open`, payload);
      } catch (err) {
        console.log(err, 'err');
      } finally {
        dispatch(closeModal({ isOpen: false, modalType: 'groupBotSheet_group_public_setting' }));
        fetchGroupConfig();
        setTimeout(() => {
          dispatch(offLoad());
        }, 300);
      }
    }
  };

  /**
   * @description 모임 중개사 가입 조건 sheet open
   * @returns {Promise<void>}
   */
  const handleClickIsBroker = useCallback(() => {
    dispatch(openModal({ isOpen: true, modalType: 'groupBotSheet_group_join_condition' }));
  }, []);

  /**
   * @description 모임 탈퇴
   * @returns {Promise<void>}
   */
  const handleClickLeaveGroup = () => {
    // 모임장인 경우 경고 모달
    if (myInfo.value === 'OWNER') {
      dispatch(openModal({ isOpen: true, modalType: 'alert_limit_leave_group' }));
      return;
    } else {
      //모임장이 아닌경우 탈퇴 가능
      if (myInfo.id) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'confirm_group_quit_group',
            data: {
              groupId: id,
              userId: myInfo.id,
            },
          }),
        );
      }
    }
  };

  /**
   * @description 모임 삭제
   * @returns {Promise<void>}
   */
  const handleClickDeleteGroup = () => {
    if (myInfo.value === 'OWNER') {
      if (group.users_count > 1) {
        // 모임에 회원수가 1명 이상일 경우 경고 메세지
        dispatch(openModal({ isOpen: true, modalType: 'alert_delete_group' }));
        return;
      } else {
        // 회원수가 자신 하나일경우 삭제 모달 호출
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'confirm_group_delete_group',
            data: {
              groupId: id,
            },
          }),
        );
      }
    }
  };

  // 신고된 게시물 총 갯수 가져오기
  useEffect(() => {
    if (id) {
      const f = async () => {
        fetchList();
      };
      if (myInfo.value === 'OWNER') {
        f();
      }
    }
  }, [myInfo]);

  /**
   * @description 신고된 게시물 조회 api
   * @returns {Promise<void>}
   */
  const fetchList = async () => {
    try {
      const res = await APIS.getReportList(`/groups/${id}/report`);
      const { meta } = res;
      const { total: totalCnt = 0 } = meta;

      setTotal(totalCnt);
    } catch (err) {
      console.log(err, 'err');
    }
  };

  return (
    <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
      {myInfo?.value === 'OWNER' && (
        <>
          <div className={CommonStyles.section}>
            <p className={CommonStyles['sub-title']}>모임 정보 관리</p>
            <div className={CommonStyles['arrow-button-list']}>
              <Button className={CommonStyles.list} onClick={movePageModifyGroupImage}>
                <p className={CommonStyles.tit}>모임 이름 및 사진</p>
                <p className={CommonStyles.txt}>모임이름 및 사진을 변경할 수 있습니다.</p>
              </Button>
              {open?.length > 0 && (
                <Button className={CommonStyles.list} onClick={handleClickIsGroup}>
                  <p className={CommonStyles.tit}>모임 공개</p>
                  <p className={CommonStyles.txt}>{open_info?.['label'] || ''}</p>
                </Button>
              )}
              {kind?.length > 0 && (
                <Button className={CommonStyles.list} onClick={movePageModifyGroupIntroduce}>
                  <p className={CommonStyles.tit}>모임 소개 및 지역</p>
                  <p className={CommonStyles.txt}>모임 소개글, 지역, 주제를 변경할 수 있습니다.</p>
                </Button>
              )}
            </div>
          </div>
          <div className={CommonStyles['contour-line']}></div>
        </>
      )}
      {myInfo?.value === 'OWNER' && (
        <>
          <div className={CommonStyles.section}>
            <p className={CommonStyles['sub-title']}>모임 가입 관리</p>
            <div className={CommonStyles['arrow-button-list']}>
              <Button className={CommonStyles.list} onClick={movePageModifyGroupApprovalManagement}>
                <p className={CommonStyles.tit}>가입 신청 관리</p>
              </Button>
              {join_condition?.length > 0 && broker_type && (
                <Button className={CommonStyles.list} onClick={handleClickIsBroker}>
                  <p className={CommonStyles.tit}>가입 조건 설정</p>
                  <p className={CommonStyles.txt}>{broker_type === 'BROKER' ? '중개사만' : '제한없음' || ''}</p>
                </Button>
              )}
              {/* <ToggleListButton
                id="list_02"
                title={'회원 가입 알림'}
                text={'회원이 가입할 때마다 가입 알림을 제공합니다.'}
                checked={alarm === 'Y' ? true : false}
                onChange={() => {
                  fetchAlarm();
                }}
              /> */}
              {/* <div className={CommonStyles['toggle-list']}>
              <label htmlFor="alert">
                <p className={CommonStyles.tit}>회원 가입 알림</p>
                <p className={CommonStyles.txt}>회원이 가입할 때마다 가입 알림을 제공합니다.</p>
              </label>
              <div className={CommonStyles['toggle-box round-toggle']}>
                <input type="checkbox" id="alert" />
                <label htmlFor="alert" className={CommonStyles['label-info']}></label>
              </div>
            </div> */}
            </div>
          </div>
          <div className={CommonStyles['contour-line']}></div>
        </>
      )}
      {/* <div className={CommonStyles['contour-line']}></div> */}
      {/* <div className={CommonStyles['section']}>
        <p className={CommonStyles['sub-title']}>회원 권한 설정</p>
        <div className={CommonStyles['arrow-button-list']}>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>모임 이름 및 사진</p>
            <p className={CommonStyles.txt}>모임장과 공동 모임장</p>
          </Button>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>공지글 등록</p>
            <p className={CommonStyles.txt}>모임장과 공동 모임장</p>
          </Button>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>게시글 관리 설정</p>
            <p className={CommonStyles.txt}>모임장만</p>
          </Button>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>회원초대</p>
            <p className={CommonStyles.txt}>모임장만</p>
          </Button>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>회원 가입 신청 수락</p>
            <p className={CommonStyles.txt}>모임장과 공동 모임장</p>
          </Button>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>가입방법 및 가입조건</p>
            <p className={CommonStyles.txt}>모임장과 공동 모임장</p>
          </Button>
          <Button className={CommonStyles.list}>
            <p className={CommonStyles.tit}>회원 페널티 설정</p>
            <p className={CommonStyles.txt}>모임장과 공동 모임장</p>
          </Button>
        </div>
      </div> */}
      {myInfo?.value === 'OWNER' && (
        <>
          <div className={CommonStyles['section']}>
            <p className={CommonStyles['sub-title']}></p>
            <div className={CommonStyles['arrow-button-list']}>
              <Button className={CommonStyles.list} onClick={movePageMemberPanelty}>
                <p className={CommonStyles.tit}>회원 페널티 설정</p>
                <p className={CommonStyles.txt}>부적절한 언행 등을 한 회원을 관리할 수 있습니다.</p>
              </Button>
              {/* <Button className={CommonStyles.list} onClick={movePageGroupCommonLeader}>
                <p className={CommonStyles.tit}>공동 모임장 추가 및 관리</p>
                <p className={CommonStyles.txt}>공동 모임장 추가 및 변경할 수 있습니다.</p>
              </Button> */}
              <Button className={CommonStyles.list} onClick={movePageGroupLeader}>
                <p className={CommonStyles.tit}>모임장 위임</p>
                <p className={CommonStyles.txt}>모임장 권한을 다른 사람에게 위임할 수 있습니다.</p>
              </Button>
              <Button className={CommonStyles.list} onClick={moveReportList}>
                <p className={CommonStyles.tit}>
                  신고된 게시물 관리<b className={CommonStyles['co-dorg']}>{total}</b>
                </p>
              </Button>
            </div>
          </div>
          <div className={CommonStyles['contour-line']}></div>
        </>
      )}
      <div className={CommonStyles['section']}>
        <div className={CommonStyles['arrow-button-list']}>
          {myInfo.value !== undefined && (
            <Button className={CommonStyles.list} onClick={handleClickLeaveGroup}>
              <p className={CommonStyles.tit}>모임 탈퇴</p>
            </Button>
          )}
          {myInfo?.value === 'OWNER' && (
            <Button className={CommonStyles.list} onClick={handleClickDeleteGroup}>
              <p className={CommonStyles.tit}>모임삭제</p>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GroupSetting;
