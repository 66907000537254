import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';
import { IMixedKeyValue } from '<modal>';
// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionDetail from 'redux/slices/coagencydetail';

export type TClickDate = {
  date?: number | null;
  fullDate: Date | any;
  isNextMonth: boolean;
  isPrevMonth: boolean;
};

interface DatePickerInterface {
  clType?: string;
  selectedStartDate?: IMixedKeyValue | null | undefined;
  selectedEndDate?: IMixedKeyValue | null | undefined;
  selectedMoveDate: IMixedKeyValue | null | undefined;
  setSelectedMoveDate?: (selectedMoveDate: TClickDate) => void;
  monthlyTerm?: string;
  setMonthlyTerm: (monthlyTerm: string) => void;
  handleModalClose?: () => void;
}

/**
 * @description 공동 중개 DatePicker
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {string} clType 분기처리 타입 default "" or modify 수정
 * @param {any} selectedStartDate 선택된 start date
 * @param {any} selectedEndDate 선택된 end date
 * @param {any} selectedMoveDate 이동할 날짜
 * @param {void} setSelectedMoveDate 날짜 변경 함수
 * @param {void} handleModalClose modal close 함수
 * @param {string} monthlyTerm move_ymd와 move_gbn 값
 * @param {void} setMonthlyTerm move_ymd와 move_gbn의 값을 가져오기
 * @returns {JSX.Element}
 */
const MoveInDatePicker = ({
  clType,
  selectedStartDate,
  selectedEndDate,
  selectedMoveDate,
  setSelectedMoveDate,
  monthlyTerm,
  setMonthlyTerm,
  handleModalClose,
}: DatePickerInterface) => {
  const dispatch = useAppDispatch();
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const detailGlobal = useAppSelector((state: RootState) => state.coagencydetail);
  const currentDateRef = useRef(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());

  const initSelectedMoveDate = {
    date: null,
    fullDate: new Date(),
    isNextMonth: false,
    isPrevMonth: false,
  };

  /**
   * @description monut시 move_ymd와 move_gbn의 값을 가져오기
   */
  useEffect(() => {
    if (shareRegStep.coagencyRegData.move_ymd) {
      const _selectedMoveDate = {
        date: Number(moment(shareRegStep.coagencyRegData.move_ymd).format('MM')),
        fullDate: shareRegStep.coagencyRegData.move_ymd ? moment(shareRegStep.coagencyRegData?.move_ymd) : new Date(),
        isNextMonth: false,
        isPrevMonth: false,
      };
      setSelectedMoveDate!(_selectedMoveDate);
      setMonthlyTerm(shareRegStep.coagencyRegData.move_gbn);
    }
  }, [shareRegStep]);

  /**
   * @description 이전달 prev click
   * @returns {void}
   */
  const handleClickPrev = () => {
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    if (
      (currentDateRef.current.getFullYear() > previousMonth.getFullYear() &&
        previousMonth.getMonth() > currentDateRef.current.getMonth()) ||
      (currentDateRef.current.getFullYear() === previousMonth.getFullYear() &&
        previousMonth.getMonth() < currentDateRef.current.getMonth())
    ) {
      return;
    }
    setCurrentDate(previousMonth);
    const _selectedMoveDate: TClickDate = {
      date: 1,
      fullDate: previousMonth,
      isNextMonth: false,
      isPrevMonth: false,
    };
    setSelectedMoveDate!(_selectedMoveDate);
  };

  /**
   * @description 다음달 next click
   * @returns {void}
   */
  const handleClickNext = () => {
    const nextMonthDate = new Date(currentDate?.getFullYear(), currentDate?.getMonth() + 1, 1);
    // setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
    setCurrentDate(nextMonthDate);
    const _selectedMoveDate: TClickDate = {
      date: 1,
      fullDate: nextMonthDate,
      isNextMonth: false,
      isPrevMonth: false,
    };
    setSelectedMoveDate!(_selectedMoveDate);
  };

  /**
   * @description 이전 달의 마지막 날짜 구하기
   * @param {IMixedKeyValue} date 날짜 데이터
   * @returns {void}
   */
  const getDaysInMonth = (date: IMixedKeyValue) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    //const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = [];

    // 이전 달의 마지막 날짜 구하기
    const prevMonthLastDay = new Date(year, month, 0);
    const prevMonthLastDate = prevMonthLastDay.getDate();

    // 이전 달의 마지막 요일
    const prevMonthLastDayOfWeek = prevMonthLastDay.getDay();

    // 이전 달의 마지막 요일부터 현재 달의 마지막 날짜까지의 날짜 추가
    for (let i = prevMonthLastDate - prevMonthLastDayOfWeek; i <= prevMonthLastDate; i++) {
      daysInMonth.push({ date: i, isPrevMonth: true, isNextMonth: false, fullDate: new Date(year, month - 1, i) });
    }

    // 현재 달의 날짜 추가
    for (let i = 1; i <= lastDay.getDate(); i++) {
      daysInMonth.push({ date: i, isPrevMonth: false, isNextMonth: false, fullDate: new Date(year, month, i) });
    }

    return daysInMonth;
  };

  /**
   * @description 달력에 표시될 날짜 sort
   */
  const daysInMonth = getDaysInMonth(currentDate);

  /**
   * @description date format
   * @param {Date} date format 변경할 날짜 data
   * @returns {void}
   */
  const convertDateFormat = (date: Date) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  };

  /**
   * @description  달력에 주말 빨간날 또는 다음달에 표시된 저번달의 일짜 css 처리
   * @returns {void}
   */
  const RenderDatePicker = () => {
    return daysInMonth
      .reduce((rows: any, day, index) => {
        if (index % 7 === 0) {
          rows.push([]);
        }
        rows[rows.length - 1].push(day);
        return rows;
      }, [])
      .map((week: any[]) =>
        week.map((day, dayIndex) => {
          let defaultProps = {};
          // console.log(day, 'day');
          if (
            day.isPrevMonth ||
            moment?.(week[dayIndex].fullDate)?.format('YYYY-MM-DD') !== moment?.(day.fullDate)?.format('YYYY-MM-DD') ||
            (day?.fullDate.getMonth() === new Date().getMonth() && day?.fullDate.getDate() < new Date().getDate())
          ) {
            defaultProps = {
              style: {
                color: '#AAAAAA',
              },
            };
          } else {
            if (
              day.fullDate.getDay() === 0 &&
              convertDateFormat(selectedStartDate?.fullDate) !== convertDateFormat(day.fullDate) &&
              convertDateFormat(selectedEndDate?.fullDate) !== convertDateFormat(day?.fullDate)
            ) {
              defaultProps = {
                style: {
                  color: '#ff5959',
                },
              };
            } else if (
              convertDateFormat(selectedStartDate?.fullDate) === convertDateFormat(day?.fullDate) ||
              convertDateFormat(selectedEndDate?.fullDate) === convertDateFormat(day?.fullDate)
            ) {
              defaultProps = {
                style: {
                  color: '#fff',
                },
              };
            }
          }

          return (
            <button
              className={
                moment?.(selectedMoveDate?.fullDate)?.format('YYYY-MM-DD') ===
                moment?.(day.fullDate)?.format('YYYY-MM-DD')
                  ? CommonStyles.active
                  : ''
              }
              key={dayIndex}
              onClick={(e) => handleClickDate(e, day)}
            >
              <span {...defaultProps}>{day.date}</span>
            </button>
          );
        }),
      );
  };

  /**
   * @description 날짜선택
   * @param {object} e React.SyntheticEvent
   * @param {TClickDate} clickedDate
   */
  const handleClickDate = (e: React.MouseEvent<HTMLButtonElement>, clickedDate: TClickDate) => {
    e.stopPropagation();
    console.log('clickedDate', clickedDate);
    setSelectedMoveDate!(clickedDate);
    if (clType === 'modify') {
      dispatch(
        ActionDetail.setDate({
          moveInDate: {
            move_gbn: 'S',
            move_ymd: moment(clickedDate.fullDate).format('YYYY-MM-DD'),
            is_move_chg: 'N',
          },
        }),
      );
    } else {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              move_gbn: 'S',
              move_ymd: moment(clickedDate.fullDate).format('YYYY-MM-DD'),
              is_move_chg: 'N',
            },
          },
        }),
      );
    }
  };

  /**
   * @description 초순, 중순, 하순 선택
   * @param {string} type
   * @returns {void}
   */
  const handleSetMovGbn = (type: string): void => {
    setMonthlyTerm(type);
    // const currentMonth = new Date(currentDate?.getFullYear(), currentDate?.getMonth(), 1);
    // const _selectedMoveDate: TClickDate = {
    //   date: 1,
    //   fullDate: currentMonth,
    //   isNextMonth: false,
    //   isPrevMonth: false,
    // };
    // setSelectedMoveDate!(_selectedMoveDate);
  };

  /**
   * @description 새로고침
   * @returns {void}
   */
  const handleRefresh = (): void => {
    setMonthlyTerm('');
    setSelectedMoveDate!(initSelectedMoveDate);
  };

  /**
   * @description 선택완료
   * @returns {void}
   */
  const handleComplete = (): void => {
    if (clType === 'modify') {
      dispatch(
        ActionDetail.setDate({
          moveInDate: {
            move_gbn: monthlyTerm ? monthlyTerm : 'S',
            move_ymd: moment(selectedMoveDate?.fullDate).format('YYYY-MM-DD'),
            is_move_chg: 'N',
          },
        }),
      );
    } else {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              move_gbn: monthlyTerm ? monthlyTerm : 'S',
              move_ymd: moment(selectedMoveDate?.fullDate).format('YYYY-MM-DD'),
              is_move_chg: 'N',
            },
          },
        }),
      );
    }
    handleModalClose!();
  };

  console.log('monthlyTerm in MoveInDatePicker==>', monthlyTerm);

  return (
    <>
      <div className={cn(CommonStyles['calendar-box'])}>
        {/* <p className={Styles.txt}>아래 캘린더에서 날짜를 선택해주세요.</p> */}
        <div className={CommonStyles['calendar-header']}>
          <button
            className={CommonStyles['prev-btn']}
            onClick={(e) => {
              e.stopPropagation();
              handleClickPrev();
            }}
          >
            <i className={CommonStyles['icon-back']}></i>
          </button>
          <p className={CommonStyles['year-month']}>{moment(currentDate).format('YYYY년 M월')}</p>
          <button
            className={CommonStyles['next-btn']}
            onClick={(e) => {
              e.stopPropagation();
              handleClickNext();
            }}
          >
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['calendar-body']}>
          <div className={CommonStyles['day-list']}>
            <span>일</span>
            <span>월</span>
            <span>화</span>
            <span>수</span>
            <span>목</span>
            <span>금</span>
            <span>토</span>
          </div>
          <div className={CommonStyles['date-list']}>{<RenderDatePicker />}</div>
        </div>
        <div className={CommonStyles['calendar-footer']}>
          <div className={CommonStyles['period-set-wrap']}>
            <button
              className={monthlyTerm === 'B' ? CommonStyles['selected'] : ''}
              onClick={(e) => {
                e.stopPropagation();
                handleSetMovGbn('B');
              }}
            >
              초순
            </button>
            <button
              className={monthlyTerm === 'C' ? CommonStyles['selected'] : ''}
              onClick={(e) => {
                e.stopPropagation();
                handleSetMovGbn('C');
              }}
            >
              중순
            </button>
            <button
              className={monthlyTerm === 'D' ? CommonStyles['selected'] : ''}
              onClick={(e) => {
                e.stopPropagation();
                handleSetMovGbn('D');
              }}
            >
              하순
            </button>
          </div>
        </div>
      </div>
      <div
        className={cn(CommonStyles['popup-button-wrap'], CommonStyles.ratio)}
        style={{ marginTop: '1rem', padding: '0', display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}
      >
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
          onClick={(e) => {
            e.stopPropagation();
            handleRefresh();
          }}
        >
          새로고침
        </button>
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg)}
          style={{ flexGrow: 1, opacity: 1 }}
          onClick={handleComplete}
        >
          선택 완료
        </button>
      </div>
    </>
  );
};

export default MoveInDatePicker;
