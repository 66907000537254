import { IMixedKeyValue } from '<modal>';
import cn from 'classnames';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/common';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import { convertArea, formatNumber, moveMain } from 'utils';
import network from 'utils/network';
// configs
import * as Config from 'configs/configs';

/**
 * @description 구합니다 추가 작성 화면 F.C
 * @Class
 * @category Pages
 * @subcategory 구합니다 게시판
 * @component
 * @param {any} props
 * @param {number} props.step 현재 구합니다 작성 step
 * @param {temp} props.temp 임시저장된 데이터 또는 실제로 저장된 데이터
 * @param {boolean} props.isTemp 임시 저장을 했는지 여부
 * @param {void} props.setLoading 로딩 설정 함수
 * @returns {JSX.Element}
 */
const WritingFindShareDetail = ({
  step,
  temp,
  isTemp = false,
  setLoading,
}: {
  step: number;
  temp?: IMixedKeyValue;
  isTemp: boolean;
  setLoading: (val: boolean) => void;
}) => {
  /*
    residential: 주거용
    commercial: 상업용
  */
  const dispatch = useAppDispatch();
  const inputRefs = useRef<any>(null);
  // 매물 유형
  const [type, setType] = useState('');
  // 선택된 매물 유형 목록
  const [selected, setSelected] = useState<string[]>([]);
  // 주거용 매물 유형
  const [residentialList, setResidentialList] = useState<IMixedKeyValue[]>([]);
  // 상업용 매물 유형
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  // 약관 체크
  const [checked, setChecked] = useState<boolean>(false);
  // 비필수값 정보
  const [info, setInfo] = useState<IMixedKeyValue>({
    customer_name: '',
    phone_num: '',
    min: null,
    max: null,
    m_min: null,
    m_max: null,
    area_min: null,
    area_max: null,
    hidden_memo: '',
    relation_type: '',
    typeName: '',
  });
  // 평 or 제곱미터
  const [areaType, setAreaType] = useState('meter');

  // 고객관계리스트
  const [relationList, setRelationList] = useState([]);

  // force
  const [force, setForce] = useState<any>(new Date().getTime());

  useEffect(() => {
    const f = async () => {
      fetchTypeList();
      getRelationList();
    };

    f();
  }, []);

  /**
   * @description 앞에서 이미 임시 저장을 한경우 입력된 값 초기화
   */
  useEffect(() => {
    if (isTemp === true) {
      setInfo({
        customer_name: '',
        phone_num: '',
        min: null,
        max: null,
        m_min: null,
        m_max: null,
        area_min: null,
        area_max: null,
        hidden_memo: '',
        relation_type: '',
        typeName: '',
      });
    }
  }, [isTemp]);

  /**
   * @description 상세 작성 페이지 이전 단계로 갔을 경우 기존에 입력했던 값 다시 셋팅
   */
  useEffect(() => {
    if (info.m_min) {
      if (inputRefs.current['m_min']) {
        inputRefs.current['m_min'].value = info.m_min;
      }
    }

    if (info.m_max) {
      if (inputRefs.current['m_max']) {
        inputRefs.current['m_max'].value = info.m_max;
      }
    }

    if (info.min) {
      if (inputRefs.current['min']) {
        inputRefs.current['min'].value = info.min;
      }
    }

    if (info.max) {
      if (inputRefs.current['max']) {
        inputRefs.current['max'].value = info.max;
      }
    }

    if (info.area_max) {
      if (inputRefs.current['area_max']) {
        inputRefs.current['area_max'].value = info.area_max;
      }
    }

    if (info.area_min) {
      if (inputRefs.current['area_min']) {
        inputRefs.current['area_min'].value = info.area_min;
      }
    }
  }, [step]);

  /**
   * @description 소유자 관계목록
   * @returns {Promise<void>}
   */
  const getRelationList = async (): Promise<void> => {
    try {
      const resAddr = await network().coagency().getCommonList(Config.API_URL.GET_OWNER_TYPE);
      const { data, status } = resAddr;
      if (status === 200) {
        let _data = data.map((item: IMixedKeyValue) => ({
          name: item.name,
          code: item.code,
        }));
        _data = [{ code: '', name: '선택' }, ..._data];
        setRelationList(_data);
      }
    } catch (err) {
      console.error('err', err);
      if (err) {
        setRelationList([]);
      }
    }
  };

  /**
   * @description 매물 유형 조회
   */
  const fetchTypeList = async () => {
    try {
      const res = await APIS.getSeekTypeList('/seeks/type/list');
      if (res.length > 0) {
        const resList = res.filter((item: IMixedKeyValue) => item.kind === 'A');
        const list = res.filter((item: IMixedKeyValue) => item.kind === 'B');

        if (resList.length > 0) {
          setResidentialList([...resList]);
        }

        if (list.length > 0) {
          setList([...list]);
        }
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  /**
   * @description 매물 유형 타입 선택
   * @param {string} val 유형 타입
   */
  const handleClickType = (val: string) => {
    if (val === type) {
      setType('');
    } else {
      setType(val);
    }
    setSelected([]);
  };

  /**
   * @description 매물 종류 2개이상 선택시 alert 모달
   */
  const handleClickModal = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'alert_custom',
        data: {
          txt: '매물종류는 최대 2개까지 선택하실 수 있습니다.',
        },
      }),
    );
  };

  /**
   * @description 매물 유형 선택
   * @param {string} val 선택한 유형
   */
  const handleClickSeekType = (val: string) => {
    // 주거용
    let copyList = cloneDeep(selected);
    if (type === 'residential') {
      if (copyList.find((item) => item === val)) {
        copyList = copyList.filter((item: any) => item !== val);
      } else {
        if (selected.length >= 2) {
          handleClickModal();
          return;
        }
        copyList.push(val);
      }

      setSelected(copyList);
    }

    // 상업용
    if (type === 'commercial') {
      if (copyList.find((item) => item === val)) {
        copyList = copyList.filter((item: any) => item !== val);
      } else {
        if (selected.length >= 2) {
          handleClickModal();
          return;
        }
        copyList.push(val);
      }

      setSelected(copyList);
    }
  };

  /**
   * @description 매물 구합니다 저장
   */
  const handleClickSave = async () => {
    try {
      setLoading(true);
      let payload = { ...info };
      if (!isTemp) {
        payload = {};
      }

      if (selected.length > 0) {
        payload = {
          ...payload,
          seek_type: [...selected],
        };
      }

      // 면적이 평형으로 돼 있을 경우 제곱미터로 변환해서 저장
      if (payload.area_min !== null || payload.area_min !== '') {
        if (areaType === 'square') {
          payload = {
            ...payload,
            area_min: convertArea(+info.area_min, 'meter'),
          };
        } else {
          payload = {
            ...payload,
            area_min: +info.area_min,
          };
        }
      }

      // 면적이 평형으로 돼 있을 경우 제곱미터로 변환해서 저장
      if (payload.area_max !== null || payload.area_max !== '') {
        if (areaType === 'square') {
          payload = {
            ...payload,
            area_max: convertArea(+info.area_max, 'meter'),
          };
        } else {
          payload = {
            ...payload,
            area_max: +info.area_max,
          };
        }
      }

      // 매매
      if (temp?.deal_type === 'S' || temp?.['3']?.trade_type === 'S') {
        payload = {
          ...payload,
          sell_prc_min: +info?.m_min?.replace(/,/g, '') || null,
          sell_prc_max: +info?.m_max?.replace(/,/g, '') || null,
        };
      }

      // 전세
      if (temp?.deal_type === 'L' || temp?.['3']?.trade_type === 'L') {
        payload = {
          ...payload,
          lease_prc_min: +info?.m_min?.replace(/,/g, '') || null,
          lease_prc_max: +info?.m_max?.replace(/,/g, '') || null,
        };
      }

      // 월세가,단기임대
      if (
        temp?.deal_type === 'M' ||
        temp?.deal_type === 'T' ||
        temp?.['3']?.trade_type === 'M' ||
        temp?.['3']?.trade_type === 'T'
      ) {
        payload = {
          ...payload,
          deposit_prc_min: +info?.min?.replace(/,/g, '') || null,
          deposit_prc_max: +info?.max?.replace(/,/g, '') || null,
          monthly_prc_min: +info?.m_min?.replace(/,/g, '') || null,
          monthly_prc_max: +info?.m_max?.replace(/,/g, '') || null,
        };
      }

      // api 분기처리
      if (isTemp) {
        if (temp?.id) {
          const res = await APIS.putPostSeek(`/seeks/optional/${temp.id}`, payload);

          if (Object.keys(res).length > 0) {
            //구합니다 등록이 완료되었습니다
            dispatch(
              openModal({
                isOpen: true,
                modalType: 'alert_looking_for_save_success',
                action: () => {
                  moveMain(4, '1');
                },
              }),
            );
          }
        }
      } else {
        const copyState = cloneDeep(temp) || {};
        if (copyState?.['1']?.title !== '') payload = { ...payload, title: copyState?.['1']?.title };
        if (copyState?.['1']?.content !== '') payload = { ...payload, content: copyState?.['1']?.content };
        if (copyState?.['3']?.trade_type !== '') payload = { ...payload, deal_type: copyState?.['3']?.trade_type };

        payload = {
          ...payload,
          status_share: copyState['4'].status_share,
          show_days: copyState['4'].expireDate,
          tel_display: copyState['4'].tel_display,
        };

        const filterAreas = copyState?.['2']?.areaList.filter((item: any) => item.step1.code !== '');
        // 중복된 지역코드 필터링
        const parseAreas = [...new Set(extractAreaCode(filterAreas))];

        if (parseAreas.length > 0) {
          payload = {
            ...payload,
            areas: [...parseAreas],
          };
        }

        if (copyState?.['4']?.status_share === 'Y') {
          payload = {
            ...payload,
            shares: [...copyState['4'].shareIds],
          };
        }

        const res = await APIS.postSaveSeek(`/seeks/`, payload);

        if (Object.keys(res).length > 0) {
          dispatch(
            openModal({
              isOpen: true,
              modalType: 'alert_looking_for_save_success',
              action: () => {
                moveMain(4, '1');
              },
            }),
          );
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description 매물 소새지의 마지막 코드 가져오기 => 소재지를 문자열로 변환하기 위한 함수
   * @param {array} areaList 소재지 목록
   */
  const extractAreaCode = (areaList: any[]) => {
    const lastStepCodes: any[] = [];
    areaList.forEach((area) => {
      let lastStep = null;
      for (let i = 4; i >= 1; i--) {
        if (area[`step${i}`].code !== '') {
          lastStep = area[`step${i}`];
          break;
        }
      }

      if (lastStep !== null) {
        lastStepCodes.push(lastStep.code);
      }
    });
    return lastStepCodes;
  };

  /**
   * @description 매물 평 or 제곱미터 선택 함수
   * @param {string} type 면적 type
   */
  const handleClickAreaType = (type: string) => {
    const copyInfo = cloneDeep(info);

    if (areaType === 'meter') {
      setAreaType('square');
    } else {
      setAreaType('meter');
    }

    if (copyInfo.area_min && copyInfo.area_max) {
      copyInfo.area_min = convertArea(+copyInfo['area_min'], type);
      copyInfo.area_max = convertArea(+copyInfo['area_max'], type);
      setInfo(copyInfo);
    } else if (copyInfo.area_min) {
      copyInfo.area_min = convertArea(+copyInfo['area_min'], type);
      setInfo(copyInfo);
    } else if (copyInfo.area_max) {
      copyInfo.area_max = convertArea(+copyInfo['area_max'], type);
      setInfo(copyInfo);
    }
  };

  /**
   * @description 매물 고객 관련 value change 함수
   */
  const handleChangeValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { value, name } = e.target;

    setInfo({
      ...info,
      [name]: value,
    });
  };

  /**
   * @description 매물 작성 취소
   */
  const handleClickCancel = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'findshare_cancel_write',
        data: {
          moveIndex: isTemp ? 4 : 3,
        },
      }),
    );
  };

  /**
   * @description 매물 고객 관계 선택
   */
  const handleClickOpenRelationModal = () => {
    dispatch(
      openModal({
        modalType: 'findshare_modal_relation_type',
        isOpen: true,
        action: (val: IMixedKeyValue) => {
          if (Object.keys(val).length > 0) {
            setInfo({
              ...info,
              relation_type: val.code,
              typeName: val.name,
            });
          }
        },
      }),
    );
  };

  /**
   * @description 매물 저장 버튼 disabled enabled 값
   */
  const isBtn = useMemo(() => {
    let check = false;

    if (info?.customer_name !== '' && !checked) {
      check = true;
    }

    if (info?.hidden_memo !== '' && !checked) {
      check = true;
    }

    if (info?.phone_num !== '' && !checked) {
      check = true;
    }

    return check;
  }, [info, checked]);

  /**
   * @description 매물 소재지 문자열 만들어 주는 함수
   */
  const parseAddr: any = useMemo(() => {
    let addrList: any = [];
    const { areaList = [] }: any = temp?.['2'] || {};
    if (areaList.length > 0) {
      areaList.forEach((item: any) => {
        let addr = '';
        const parseItem = cloneDeep(item);
        delete parseItem['isLast'];
        delete parseItem['step'];

        Object.keys(parseItem).forEach((el: any) => {
          addr += parseItem[el]?.name ? `${parseItem[el].name} ` : '';
        });

        addr = addr.trimEnd();

        addrList.push(addr);
      });
    }

    addrList = addrList.join(' · ');

    return addrList;
  }, [temp]);

  /**
   * @description 매물 min max 관련 input ref를 추가하는 함수
   * @param {string} key obj데이터 키값
   * @param {any} ref element 데이터
   */
  const addInputRef = (key: string, ref: any) => {
    if (key) {
      inputRefs.current = {
        ...inputRefs.current,
        [key]: ref,
      };
    }
  };

  const isTerms = useMemo(() => {
    let check = true;
    if (
      info.customer_name?.trim() !== '' ||
      info.phone_num?.trim() !== '' ||
      info.hidden_memo?.trim() !== '' ||
      info.relation_type?.trim() !== '' ||
      info.typeName?.trim() !== ''
    ) {
      check = false;
    }
    return check;
  }, [info]);

  useEffect(() => {
    if (isTerms === true && checked === true) {
      setChecked(false);
    }
  }, [isTerms, checked]);

  /**
   * @description  구합니다 약관 선택
   * @returns {void}
   */
  const handleClickTerms = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'coagency_bottom_privacy_info',
        data: {
          isModify: true,
        },
        action: () => {
          setChecked(true);
        },
      }),
    );
  };

  const handleChangeAreaValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const len = `${value}`.length;
    const maxLen = `${value}`.includes('.') ? 7 : 6;

    if (len > maxLen) return;

    // if(len === 0)

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleInputClick = (event: any) => {
    const targetElement: HTMLElement = event.target;
    if (targetElement && !/Android/i.test(navigator.userAgent)) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <>
      <div className={cn(CommonStyles.container, CommonStyles['hasBottom'])} style={{ paddingBottom: '200px' }}>
        <div
          className={cn(CommonStyles['content'], CommonStyles['share-write'], step <= 3 && CommonStyles['area-select'])}
        >
          <div className={CommonStyles['section-div']}>
            <section className={CommonStyles['pt0']}>
              <div className={cn(CommonStyles['sale-card'], CommonStyles['bg-blue'])}>
                {(temp?.['1']?.title || temp?.title) && (
                  <div className={cn(CommonStyles['blue-box'], CommonStyles['limit-line1'])}>
                    {temp?.['1']?.title || temp?.title}
                  </div>
                )}
                {(temp?.['1']?.content || temp?.content) && (
                  <p className={cn(CommonStyles['title'], CommonStyles['limit-line2'])}>
                    {temp?.['1']?.content || temp?.content}
                  </p>
                )}
                {parseAddr && (
                  <div className={cn(CommonStyles['title'], CommonStyles['fw-r'], CommonStyles['mt8'])}>
                    {parseAddr}
                  </div>
                )}
              </div>
              {step === 5 && (
                <div className={CommonStyles['insert-item']}>
                  <p className={CommonStyles['sub-title']}>매물 종류를 선택해주세요.</p>
                  <div className={CommonStyles['btn-wrap']}>
                    <button
                      className={cn(
                        CommonStyles['btn'],
                        CommonStyles['lg'],
                        type !== 'residential' && CommonStyles['line'],
                      )}
                      onClick={() => {
                        handleClickType('residential');
                      }}
                    >
                      주거용
                    </button>
                    <button
                      className={cn(
                        CommonStyles['btn'],
                        CommonStyles['lg'],
                        type !== 'commercial' && CommonStyles['line'],
                      )}
                      onClick={() => {
                        handleClickType('commercial');
                      }}
                    >
                      상업용
                    </button>
                  </div>
                  <div
                    className={cn(CommonStyles['d-flex-wrap'], CommonStyles['mt12'])}
                    style={{
                      display: 'flex',
                      flex: 'flex-wrap',
                      gap: '0.5rem',
                    }}
                  >
                    {type === 'residential' && (
                      <>
                        {residentialList.map((item) => {
                          return (
                            <button
                              key={item.name}
                              className={cn(
                                CommonStyles['btn'],
                                !selected?.includes?.(item.code) && CommonStyles['line'],
                              )}
                              onClick={() => handleClickSeekType(item.code)}
                            >
                              {item.name}
                            </button>
                          );
                        })}
                      </>
                    )}
                    {type === 'commercial' && (
                      <>
                        {list.map((item) => {
                          return (
                            <button
                              key={item.name}
                              className={cn(
                                CommonStyles['btn'],
                                !selected?.includes?.(item.code) && CommonStyles['line'],
                              )}
                              onClick={() => handleClickSeekType(item.code)}
                            >
                              {item.name}
                            </button>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
              )}
              {step === 6 && (
                <>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['title']}>매물 상세 정보(선택입력)</p>
                    <p className={CommonStyles['sub-txt']}>
                      *상세 정보 미작성시에도 공유하기가 가능하며, 수정하기에서 추가 작성이 가능합니다.
                    </p>
                  </div>
                  {/* 매매 S */}
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['main-title']}>
                      {(temp?.['3']?.trade_type === 'S' || temp?.deal_type === 'S') && '매매가'}
                      {(temp?.['3']?.trade_type === 'L' || temp?.deal_type === 'L') && '전세가'}
                      {(temp?.['3']?.trade_type === 'M' || temp?.deal_type === 'M') && '월세가'}
                      {(temp?.['3']?.trade_type === 'T' || temp?.deal_type === 'T') && '단기임대'}를 작성해주세요.
                    </p>
                    {/* <p className={CommonStyles['sub-title']}>
                {temp?.['3']?.trade_type === 'S' && '매매가'}
                {temp?.['3']?.trade_type === 'L' && '전세가'}
                {temp?.['3']?.trade_type === 'M' && '월세가'}
                {temp?.['3']?.trade_type === 'T' && '단기임대'}
              </p> */}
                    {/* 단기임대나 월세일경우 보여짐 */}
                    <div className={CommonStyles['insert-form']}>
                      <p className={CommonStyles['sub-title']} style={{ marginLeft: '0rem' }}>
                        {(temp?.['3']?.trade_type === 'S' || temp?.deal_type === 'S') && '매매가'}
                        {(temp?.['3']?.trade_type === 'L' || temp?.deal_type === 'L') && '전세가'}
                        {(temp?.['3']?.trade_type === 'M' || temp?.deal_type === 'M') && '월세가'}
                        {(temp?.['3']?.trade_type === 'T' || temp?.deal_type === 'T') && '단기임대가'}
                      </p>
                      {(temp?.['3']?.trade_type === 'M' ||
                        temp?.['3']?.trade_type === 'T' ||
                        temp?.deal_type === 'M' ||
                        temp?.deal_type === 'T') && (
                        <div className={CommonStyles['insert-item']}>
                          <p className={CommonStyles['sub-title']} style={{ marginLeft: '0.5rem' }}>
                            {(temp?.['3']?.trade_type === 'M' || temp?.deal_type === 'T' || temp?.deal_type === 'M') &&
                              '보증금'}
                          </p>
                          <div className={CommonStyles['input-div']}>
                            <div className={CommonStyles['input-area']}>
                              <input
                                onClick={handleInputClick}
                                type="tel"
                                name="min"
                                maxLength={8}
                                onFocus={(e) => {
                                  const { value } = e.target;
                                  const inputValue = value;
                                  const isNumberValue = inputValue.replace(/\D/g, '');

                                  if (inputRefs?.current?.['min']?.value?.includes(',')) {
                                    inputRefs.current['min'].value = isNumberValue;
                                  }
                                }}
                                onBlur={() => {
                                  const inputValue = inputRefs.current['min'].value;
                                  const isNumberValue = inputValue.replace(/\D/g, '');
                                  const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                  if (inputRefs?.current?.['min'].value) {
                                    if (
                                      parseInt(inputRefs?.current['max']?.value?.replace(/,/g, '')) > 0 &&
                                      parseInt(inputRefs?.current['min']?.value?.replace(/,/g, '')) >
                                        parseInt(inputRefs?.current['max']?.value?.replace(/,/g, ''))
                                    ) {
                                      inputRefs.current['max'].value = null;
                                      inputRefs.current['min'].value = commaValue;
                                      setInfo({ ...info, ['min']: commaValue, ['max']: null });
                                    } else {
                                      inputRefs.current['min'].value = commaValue;
                                      setInfo({ ...info, ['min']: commaValue });
                                    }
                                  } else {
                                    setForce(new Date().getTime());
                                  }
                                }}
                                ref={(ref) => addInputRef('min', ref)}
                              />
                              <span className={CommonStyles['unit']}>만원</span>
                            </div>
                            <span>~</span>
                            <div className={CommonStyles['input-area']}>
                              <input
                                onClick={handleInputClick}
                                type="tel"
                                name="max"
                                maxLength={8}
                                onFocus={(e) => {
                                  const { value } = e.target;
                                  const inputValue = value;
                                  const isNumberValue = inputValue.replace(/\D/g, '');

                                  if (inputRefs?.current?.['max']?.value?.includes(',')) {
                                    inputRefs.current['max'].value = isNumberValue;
                                  }
                                }}
                                onBlur={() => {
                                  const inputValue = inputRefs.current['max'].value;
                                  const isNumberValue = inputValue.replace(/\D/g, '');
                                  const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                  if (inputRefs?.current?.['max'].value) {
                                    if (
                                      parseInt(inputRefs?.current['max']?.value?.replace(/,/g, '')) > 0 &&
                                      parseInt(inputRefs?.current['min']?.value?.replace(/,/g, '')) >
                                        parseInt(inputRefs?.current['max']?.value?.replace(/,/g, ''))
                                    ) {
                                      inputRefs.current['max'].value = null;
                                      setInfo({ ...info, ['max']: null });
                                    } else {
                                      inputRefs.current['max'].value = commaValue;
                                      setInfo({ ...info, ['max']: commaValue });
                                    }
                                  } else {
                                    setForce(new Date().getTime());
                                  }
                                }}
                                ref={(ref) => addInputRef('max', ref)}
                              />
                              <span className={CommonStyles['unit']}>만원</span>
                            </div>
                          </div>
                          <div className={cn(CommonStyles['w100p'], CommonStyles['info-txt'])}>
                            [금액:
                            <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                              {inputRefs?.current?.['min']?.value
                                ? formatNumber(+inputRefs?.current?.['min']?.value?.replace(/,/g, ''))
                                : 0}
                            </b>
                            원 ~{' '}
                            <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                              {inputRefs?.current?.['max']?.value
                                ? formatNumber(+inputRefs?.current?.['max']?.value?.replace(/,/g, ''))
                                : 0}
                            </b>
                            원]
                          </div>
                        </div>
                      )}
                      {/* 공통 min ~ max 입력란 */}
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']} style={{ marginLeft: '0.5rem' }}>
                          {(temp?.['3']?.trade_type === 'M' ||
                            temp?.['3']?.trade_type === 'T' ||
                            temp?.deal_type === 'M' ||
                            temp?.deal_type === 'T') &&
                            '월세'}
                        </p>
                        <div className={CommonStyles['input-div']}>
                          <div className={CommonStyles['input-area']}>
                            <input
                              onClick={handleInputClick}
                              type="tel"
                              name="m_min"
                              maxLength={8}
                              onFocus={(e) => {
                                const { value } = e.target;
                                const inputValue = value;
                                const isNumberValue = inputValue.replace(/\D/g, '');

                                if (inputRefs?.current?.['m_min']?.value?.includes(',')) {
                                  inputRefs.current['m_min'].value = isNumberValue;
                                }
                              }}
                              onBlur={() => {
                                const inputValue = inputRefs.current['m_min'].value;
                                const isNumberValue = inputValue.replace(/\D/g, '');
                                const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                if (inputRefs?.current?.['m_min'].value) {
                                  if (
                                    parseInt(inputRefs?.current['m_max']?.value?.replace(/,/g, '')) > 0 &&
                                    parseInt(inputRefs?.current['m_min']?.value?.replace(/,/g, '')) >
                                      parseInt(inputRefs?.current['m_max']?.value?.replace(/,/g, ''))
                                  ) {
                                    inputRefs.current['m_max'].value = null;
                                    inputRefs.current['m_min'].value = commaValue;

                                    setInfo({ ...info, ['m_min']: commaValue, ['m_max']: null });
                                  } else {
                                    inputRefs.current['m_min'].value = commaValue;
                                    setInfo({ ...info, ['m_min']: commaValue });
                                  }
                                } else {
                                  setForce(new Date().getTime());
                                }
                              }}
                              ref={(ref) => addInputRef('m_min', ref)}
                            />
                            <span className={CommonStyles['unit']}>만원</span>
                          </div>
                          <span>~</span>
                          <div className={CommonStyles['input-area']}>
                            <input
                              onClick={handleInputClick}
                              type="tel"
                              name="m_max"
                              maxLength={8}
                              onFocus={(e) => {
                                const { value } = e.target;
                                const inputValue = value;
                                const isNumberValue = inputValue.replace(/\D/g, '');

                                if (inputRefs?.current?.['m_max']?.value?.includes(',')) {
                                  inputRefs.current['m_max'].value = isNumberValue;
                                }
                              }}
                              onBlur={() => {
                                const inputValue = inputRefs.current['m_max'].value + '';
                                const isNumberValue = inputValue.replace(/\D/g, '');
                                const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                if (inputRefs?.current?.['m_max'].value) {
                                  if (
                                    parseInt(inputRefs?.current['m_min']?.value?.replace(/,/g, '')) > 0 &&
                                    parseInt(inputRefs?.current['m_min']?.value?.replace(/,/g, '')) >
                                      parseInt(inputRefs?.current['m_max']?.value?.replace(/,/g, ''))
                                  ) {
                                    inputRefs.current['m_max'].value = null;
                                    setInfo({ ...info, ['m_max']: null });
                                  } else {
                                    inputRefs.current['m_max'].value = commaValue;
                                    setInfo({ ...info, ['m_max']: commaValue });
                                  }
                                } else {
                                  setForce(new Date().getTime());
                                }
                              }}
                              ref={(ref) => addInputRef('m_max', ref)}
                            />
                            <span className={CommonStyles['unit']}>만원</span>
                          </div>
                        </div>
                        <div className={cn(CommonStyles['w100p'], CommonStyles['info-txt'])}>
                          [금액:
                          <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                            {inputRefs?.current?.['m_min']?.value
                              ? formatNumber(inputRefs?.current?.['m_min']?.value?.replace(/,/g, ''))
                              : 0}
                          </b>
                          원 ~{' '}
                          <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                            {inputRefs?.current?.['m_max']?.value
                              ? formatNumber(inputRefs?.current?.['m_max']?.value?.replace(/,/g, ''))
                              : 0}
                          </b>
                          원]
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={CommonStyles['insert-item']}>
                    <div className={CommonStyles['sub-title']}>면적을 작성해주세요.</div>
                    <div className={CommonStyles['input-div']}>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          name="area_min"
                          type="number"
                          value={info.area_min}
                          onChange={handleChangeAreaValue}
                          onBlur={() => {
                            const inputValue = info['area_min'] + '';
                            const isNumberValue = inputValue.replace(/\D/g, '');
                            if (info['area_min']) {
                              if (+info['area_max'] > 0 && +info['area_min'] > +info['area_max']) {
                                setInfo({ ...info, ['area_min']: isNumberValue, ['area_max']: null });
                              } else {
                                setInfo({ ...info, ['area_min']: isNumberValue });
                              }
                            }
                          }}
                        />
                        <span className={CommonStyles['unit']}>{areaType === 'meter' ? '㎡' : '평'}</span>
                      </div>
                      <span>~</span>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          name="area_max"
                          type="number"
                          value={info?.area_max === null ? '' : info?.area_max}
                          onChange={handleChangeAreaValue}
                          onBlur={() => {
                            const inputValue = info['area_max'] + '';
                            const isNumberValue = inputValue.replace(/\D/g, '');
                            if (info['area_max']) {
                              if (+info['area_max'] > 0 && +info['area_min'] > +info['area_max']) {
                                setForce(new Date().getTime());
                                setInfo({ ...info, ['area_max']: null });
                              } else {
                                setForce(new Date().getTime());
                                setInfo({ ...info, ['area_max']: isNumberValue });
                              }
                            }
                          }}
                        />
                        <span className={CommonStyles['unit']}>{areaType === 'meter' ? '㎡' : '평'}</span>
                      </div>
                    </div>
                    <div className={cn(CommonStyles['ml-auto'], CommonStyles['btn-div'])}>
                      <button
                        className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['gry'])}
                        onClick={() => handleClickAreaType(areaType === 'meter' ? 'square' : 'meter')}
                      >
                        <i className={CommonStyles['icon-change']}></i>
                        {areaType === 'meter' ? '평형으로 입력' : '제곱미터로 입력'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </section>
            {step === 6 && (
              <>
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['title']}>비공개 정보 입력</p>
                    <p className={CommonStyles['sub-txt']}>
                      *나만 보는 매물 관리 정보가 필요하신 경우 등록하세요. 매물 공유시에 타인에게 노출되지 않습니다.
                    </p>
                  </div>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>고객명</p>
                    <div
                      className={cn(CommonStyles['d-flex'], CommonStyles['half'])}
                      style={{
                        display: 'flex',
                        flex: 'flex-wrap',
                        gap: '0.5rem',
                      }}
                    >
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['line'])}
                          name="customer_name"
                          type="text"
                          placeholder="고객명(비공개)"
                          value={info.customer_name || ''}
                          onChange={handleChangeValue}
                        />
                      </div>
                      <button
                        className={CommonStyles['dropdown']}
                        onClick={() =>
                          dispatch(
                            openModal({
                              modalType: 'coagency_select_customer_relation_fn',
                              multiModalType: '', // 멀티모달은 초기화
                              data: relationList,
                              action: (val: IMixedKeyValue) => {
                                if (Object.keys(val).length > 0) {
                                  if (val?.name === '선택') {
                                    setInfo({
                                      ...info,
                                      relation_type: '',
                                      typeName: '',
                                    });
                                  } else {
                                    setInfo({
                                      ...info,
                                      relation_type: val.code,
                                      typeName: val.name,
                                    });
                                  }
                                }
                              },
                              isOpen: true,
                            }),
                          )
                        }
                      >
                        <span style={{ color: info?.typeName ? '#000' : '#aaa' }}>
                          {info?.typeName ? info.typeName : '선택'}
                        </span>
                        <i className={CommonStyles['icon-arrow-down-gr']}></i>
                      </button>
                    </div>
                  </div>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>고객 연락처</p>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['line'])}
                        name="phone_num"
                        type="tel"
                        placeholder="고객연락처(비공개)"
                        value={info?.phone_num || ''}
                        onChange={handleChangeValue}
                      />
                    </div>
                  </div>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>중개사 메모</p>
                    <div className={cn(CommonStyles['textarea-div'], CommonStyles['type2'])}>
                      <textarea
                        onClick={handleInputClick}
                        name="hidden_memo"
                        placeholder="중개사 메모(비공개)"
                        value={info?.hidden_memo || ''}
                        maxLength={1000}
                        onChange={handleChangeValue}
                      ></textarea>
                      <p className={CommonStyles['byte']}>
                        <b>{info.hidden_memo.length}</b>/1000
                      </p>
                    </div>
                  </div>
                  {!isTerms && (
                    <div className={cn(CommonStyles['check-box'], CommonStyles['agree-chk'])}>
                      <input
                        type="checkbox"
                        id="agree"
                        checked={checked}
                        onChange={() => {
                          if (checked) {
                            setChecked(false);
                          } else {
                            handleClickTerms();
                          }
                        }}
                      />
                      <label htmlFor="agree" className={CommonStyles['label-info']}>
                        (선택) 상기 개인정보의 수집, 이용에 대해 고객으로부터 동의를 받았습니다.
                      </label>
                    </div>
                  )}
                </section>
              </>
            )}
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])} style={{ position: 'absolute' }}>
        {step === 6 && (
          <>
            <button
              className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
              onClick={() => {
                handleClickCancel();
              }}
            >
              취소
            </button>
            <button
              className={cn(CommonStyles['btn'], CommonStyles['lg'], isBtn && CommonStyles['disabled'])}
              disabled={!isBtn ? false : true}
              onClick={handleClickSave}
            >
              등록하기
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default WritingFindShareDetail;
