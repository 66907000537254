/* eslint-disable @typescript-eslint/no-explicit-any */
import Input from 'components/common/Input/Input';
import React, { useEffect, useState, KeyboardEvent, useRef } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Button from 'components/common/Button/Button';
import GradeBadge from 'components/common/Badge/GradeBadge';
import { useNavigate, useParams } from 'react-router-dom';
import * as APIS from 'api/group';
import { useDispatch } from 'react-redux';
import { GroupMemberInterface, setInfo } from 'redux/slices/groupmember';
import { IMixedKeyValue } from '<modal>';
import { RootState, useAppSelector } from 'redux/store';
import styled from 'styled-components';
import useScroll from 'hook/useScroll';
import ListLoader from 'components/common/Loader/ListLoader';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';
import { openModal } from 'redux/slices/modal';
import { moveMain, onClose } from 'utils';

const PAGE_SIZE = 10;

/**
 * @description 모임 - 회원 조회
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupMemberSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id = '' } = useParams();
  const groupData = useAppSelector((state: RootState) => state.group);
  const { myInfo } = groupData;
  const [page, setPage] = useState({
    page: 1,
    key: new Date().getTime(),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const lastPage = useRef<boolean>(false);
  const isSearch = useRef<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [memberList, setMemberList] = useState<IMixedKeyValue[]>([]);
  const [leaderList, setLeaderList] = useState<IMixedKeyValue[]>([]);

  // 새로고침 중복 방지
  const isRefreshRef = useRef<boolean>(false);

  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchData();
      };
      if (isRefreshRef.current) return;
      f();
    }
  }, [page]);

  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchLeaderList();
      };
      f();
    }
  }, []);

  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => refreshFn(),
      }),
    );
    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  /**
   * @description input 입력된 값 초기화
   * @returns {Promise<void>}
   */
  const handleClickClear = () => {
    setSearch('');
  };

  /**
   * @description 모임 가입 승인관리 목록 페이지 이동
   * @returns {Promise<void>}
   */
  const movePageApprovalMember = () => {
    navigate(`/groupapprovallist/${id}`);
  };

  /**
   * @description 모임장 조회 api
   * @returns {Promise<void>}
   */
  const fetchLeaderList = async () => {
    try {
      const res = await APIS.getLeaderList(
        search ? `/groups/${id}/users/admin?q=${search}` : `/groups/${id}/users/admin`,
      );
      const { data = [] } = res;

      if (data) {
        setLeaderList([...data]);
      }
    } catch (err: any) {
      if (err?.response?.data?.error === 'Data not found') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '존재하지 않는 모임입니다.',
            },
            isOpen: true,
            action: () => {
              moveMain(0, '');
              onClose();
            },
          }),
        );
      }
    }
  };

  /**
   * @description 모임 멤버 조회 api
   * @param {boolean} isRefresh 새로고침으로 조회됐는지 판단 여부
   * @returns {Promise<void>}
   */
  const fetchData = async (isRefresh = false) => {
    if (id === '') return;
    try {
      setLoading(true);
      let queryString = `/groups/${id}/users/normal?page=${isRefresh ? 1 : page.page}&page_size=${PAGE_SIZE}`;
      if (search) {
        queryString += `&q=${search}`;
      } else {
        isSearch.current = false;
      }
      const res = await APIS.getGroupMemberList(queryString);
      const { data = [], links, meta } = res;
      const { total: totalCnt = 0 } = meta;
      const { next = null } = links;

      setTotal(totalCnt);

      // 마지막 페이지 체크
      if (next === null) {
        lastPage.current = true;
      }
      if (data) {
        if (isRefresh) {
          setMemberList([...data]);
        } else {
          setMemberList((prev) => [...prev, ...data]);
        }
      }
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 새로고침 관련 함수
   * @returns {Promise<void>}
   */
  const refreshFn = () => {
    fetchLeaderList();
    setPage({
      page: 1,
      key: new Date().getTime(),
    });
    fetchData(true);
    lastPage.current = false;
    isSearch.current = true;
    isRefreshRef.current = true;
  };

  /**
   * @description 모임 멤버 상세조회 페이지 이동
   * @returns {Promise<void>}
   */
  const movePageDetail = (id: number) => {
    if (id) {
      const findMember = memberList.find((item) => item.id === id);
      if (findMember) {
        const payload: GroupMemberInterface = {
          name: findMember.name,
          nickname: findMember.nickname,
          grade: findMember.grade,
          phone: findMember.phone,
          total_comment_count: findMember.total_comment_count,
          total_like_count: findMember.total_like_count,
          total_post_count: findMember.total_post_count,
          offering_complete_count: findMember.offering_complete_count,
          offering_count: findMember.offering_count,
          profile_photo_path: findMember.profile_photo_path,
          point_level: findMember.point_level,
          company: findMember.company,
          verify: findMember.verify,
        };
        dispatch(setInfo(payload));
        navigate(`/groupmemberdetail/${id}`);
      } else {
        const findLeader = leaderList.find((item) => item.id === id);
        if (findLeader) {
          const payload: GroupMemberInterface = {
            name: findLeader.name,
            nickname: findLeader.nickname,
            grade: findLeader.grade,
            phone: findLeader.phone,
            total_comment_count: findLeader.total_comment_count,
            total_like_count: findLeader.total_like_count,
            total_post_count: findLeader.total_post_count,
            offering_complete_count: findLeader.offering_complete_count,
            offering_count: findLeader.offering_count,
            profile_photo_path: findLeader.profile_photo_path,
            point_level: findLeader.point_level,
            company: findLeader.company,
            verify: findLeader.verify,
          };
          dispatch(setInfo(payload));
          navigate(`/groupmemberdetail/${id}`);
        }
      }
    }
  };

  /**
   * @description 검색
   * @returns {Promise<void>}
   */
  const onKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setLeaderList([]);
      setMemberList([]);
      setTotal(0);
      setPage({
        page: 1,
        key: new Date().getTime(),
      });

      lastPage.current = false;
      isSearch.current = true;
      fetchLeaderList();
    }
  };

  /**
   * @description 무한 스크롤 observe
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);
    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && memberList.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  return (
    <div className={CommonStyles.container}>
      <div className={cn(CommonStyles.content, CommonStyles['group-member'])}>
        <Input
          className={cn(CommonStyles['search-input'])}
          placeholder="닉네임 검색"
          handleClickClear={() => handleClickClear()}
          onChange={onChangeValue}
          onKeyDown={(e) => onKeyDownSearch(e)}
          value={search}
          mergeClass={true}
          clear={true}
        />
        <div className={CommonStyles['sub-title']}>
          {isSearch.current ? '검색결과' : '회원'}
          <span className={CommonStyles['co-dorg']}>
            {isSearch.current ? memberList.length + leaderList.length : total + leaderList.length}
          </span>
          {myInfo.value === 'OWNER' && (
            <Button type="line" className={CommonStyles['ml-auto']} mergeClass={true} onClick={movePageApprovalMember}>
              가입 승인관리
            </Button>
          )}
        </div>
        <div className={CommonStyles['member-list']}>
          {leaderList.map((item, index: number) => {
            return (
              <div key={index} className={CommonStyles.list}>
                <div className={CommonStyles['member-information']}>
                  <Button
                    className={CommonStyles.clickable}
                    mergeClass={false}
                    onClick={() => {
                      if (item?.verify?.['broker_code']) {
                        movePageDetail(item.id);
                      }
                    }}
                  >
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{ backgroundImage: `url(${item['profile_photo_path']})` }}
                      ></div>
                      {item?.verify?.['broker_code'] && <GradeBadge grade={item.grade} />}
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{item?.nickname || '-'}</p>
                      <div className={CommonStyles['divide-box']}>
                        {item?.company?.name && (
                          <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {item.company.name}
                          </p>
                        )}
                        <p>{item.label}</p>
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            );
          })}
          {memberList.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div className={CommonStyles.list}>
                  <div className={CommonStyles['member-information']}>
                    <Button
                      className={CommonStyles.clickable}
                      mergeClass={false}
                      onClick={() => {
                        if (item?.verify?.['broker_code']) {
                          movePageDetail(item.id);
                        }
                      }}
                    >
                      <div className={CommonStyles.profile}>
                        <div
                          className={CommonStyles['img-div']}
                          style={{ backgroundImage: `url(${item['profile_photo_path']})` }}
                        ></div>
                        {item?.verify?.['broker_code'] && <GradeBadge grade={item.grade} />}
                      </div>
                      <div className={CommonStyles['mem-info']}>
                        <p className={CommonStyles.name}>{item?.nickname || '-'}</p>
                        <div className={CommonStyles['divide-box']}>
                          {item?.company?.name && (
                            <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                              {item.company.name}
                            </p>
                          )}
                          <p>{item.label}</p>
                        </div>
                      </div>
                    </Button>
                  </div>
                  {index + 1 === memberList.length && <Target ref={ref} />}
                </div>
                {index + 1 === memberList.length && loading && <ListLoader />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GroupMemberSearch;

const Target = styled.div`
  height: 1px;
`;
