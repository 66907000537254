import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';
import cn from 'classnames';
import { history } from 'router/index';
import * as ActionModal from 'redux/slices/modal';
// redux
import { useAppDispatch } from 'redux/store';
import * as ActionCoagency from 'redux/slices/coagency';

// configs
import * as Config from 'configs/configs';
// styles
import CommonStyles from 'styles/common.module.css';

/**
 * @description 공유매물등록 선택 component
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @returns {JSX.Element}
 */
const CoagencyRegisterSelect = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');

  /**
   * @description hardware backey history 동작
   */
  useEffect(() => {
    const listenBackEvent = () => {
      return navigate(`/newpost-coagency-type`);
    };

    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlisten;
  }, [history]);

  useEffect(() => {
    dispatch(ActionCoagency.initStep('share'));
  }, []);

  const handleImportCheck = async () => {
    dispatch(onLoad());
    try {
      const { data } = await network().sso().membership();
      dispatch(offLoad());
      if (data.isSubscribe) {
        navigate({
          pathname: '/coagency-legacy-list',
          search: groupId ? `?groupId=${groupId}` : '',
        });
      } else {
        dispatch(
          ActionModal.openModal({
            modalType: 'alert_common',
            isOpen: true,
            data: { txt: '이실장 멤버십 회원만\n사용할 수 있는 기능입니다.' },
          }),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      className={cn(
        CommonStyles.content,
        CommonStyles['share-write'],
        Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
      )}
    >
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles.full)}>
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg)}
          onClick={() => {
            navigate({
              pathname: '/coagency-register',
              search: groupId ? `?groupId=${groupId}&currStep=1` : 'currStep=1',
            });
          }}
        >
          새로운 매물 등록
        </button>
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
          onClick={handleImportCheck}
        >
          이실장 매물 불러오기
        </button>
      </div>
    </div>
  );
};

export default CoagencyRegisterSelect;
