import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { history } from 'router/index';
// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionUser from 'redux/slices/user';

import { moveMain, onClose } from 'utils';
import { isCheckWebview } from 'utils/common';
// configs
import * as Config from 'configs/configs';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

// utils
import { onView } from 'utils';

/**
 * @description 글쓰기 선택 공동중개 or 게시글
 * @Class
 * @category Pages
 * @subcategory 글쓰기
 * @component
 * @returns {JSX.Element}
 */
const NewPostType = (): JSX.Element => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get('groupName');
  const groupId = queryParams.get('groupId');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const historyPath = useAppSelector((state: RootState) => state.user.historyPath);

  /**
   * @description hardware backey history 동작  // subview닫고 홈으로 이동시킬것
   */
  useEffect(() => {
    const listenBackEvent = () => {
      moveMain(0, '0');
      onClose();
    };

    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlisten;
  }, [history]);

  console.log('histor path includes', historyPath.from.includes('/mygroup/group'));
  console.log('isCheckWebview==>', !isCheckWebview());

  return (
    <div
      className={cn(
        CommonStyles.content,
        CommonStyles['select-board'],
        Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
      )}
    >
      <p className={cn(CommonStyles.txt, ResetStyles['fw-b'])}>작성할 게시글을 선택해주세요.</p>
      <div className={CommonStyles['type-wrap']}>
        <button
          id="coagency"
          className={CommonStyles['select-type']}
          aria-label="공동중개"
          onClick={() => {
            if (
              import.meta.env.MODE === 'localdev' ||
              import.meta.env.MODE === 'coagency' ||
              !isCheckWebview() ||
              historyPath.from.includes('/mygroup/group')
            ) {
              dispatch(
                ActionUser.setHistoryPath({
                  from: location.pathname,
                  to: `/newpostselect`,
                }),
              );
              navigate({
                pathname: '/newpost-coagency-type',
                search: groupId ? `?groupId=${groupId}` : '',
              });
            } else {
              onView(`${import.meta.env.VITE_APP_DOMAIN}/newpost-coagency-type`);
            }
          }}
        >
          <i></i>공동중개
        </button>
        <button
          id="post"
          className={CommonStyles['select-type']}
          aria-label="게시글"
          onClick={() => {
            if (
              import.meta.env.MODE === 'localdev' ||
              import.meta.env.MODE === 'coagency' ||
              !isCheckWebview() ||
              historyPath.from.includes('/mygroup/group')
            ) {
              if (groupName) {
                dispatch(
                  ActionUser.setHistoryPath({
                    from: location.pathname,
                    to: `/writing`,
                  }),
                );
                navigate({
                  pathname: '/writing',
                  search: `?mode=write&type=group&groupId=${groupId}&name=${groupName}`,
                });
              } else {
                dispatch(
                  ActionUser.setHistoryPath({
                    from: location.pathname,
                    to: `/newpostselect`,
                  }),
                );
                navigate({
                  pathname: '/newpostselect',
                });
              }
            } else {
              onView(`${import.meta.env.VITE_APP_DOMAIN}/newpostselect`);
            }
          }}
        >
          <i></i>게시글
        </button>
      </div>
    </div>
  );
};

export default NewPostType;
