import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import useQueryState from 'hook/useQueryState';
import useInfiniteScroll from 'hook/useInfiniteScroll';
import ListLoader from 'components/common/Loader/ListLoader';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';

// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionLoading from 'redux/slices/loading';
import * as ActionUser from 'redux/slices/user';
import Toast from 'components/common/Toast/Toast';
import { fetchAllBoardPost, setBoardPostLike } from 'api/board';

// components
import PostContent from './PostContent';
import PostAttach from './PostAttach';
// styles
import CommonStyles from 'styles/common.module.css';
// configs
import * as Config from 'configs/configs';
// utils
import network from 'utils/network';
import { timeDisplay } from 'utils/common';
import { IMixedKeyValue } from '<modal>';
import { onView } from 'utils';

/**
 * @description 전체게시판 F.C
 * @Class
 * @category Pages
 * @subcategory 전체게시판
 * @component
 * @returns {JSX.Element}
 */
const AllBoard = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { modalType, isConfirm } = useAppSelector((state: RootState) => state.modal);
  const toastState = useAppSelector((state: RootState) => state.toast);
  const currentUser = useAppSelector((state: RootState) => state.user.currentUser);
  const infoMeRedux = useAppSelector((state: RootState) => state.user.me);
  const allBoardPost = useAppSelector((state: RootState) => state.board.allBoard);
  const freeBoardPost = useAppSelector((state: RootState) => state.board.freeBoard);
  const issueBoardPost = useAppSelector((state: RootState) => state.board.issue);
  const isDataLoading = useAppSelector((state: RootState) => state.loading.isDataLoading);

  const searchParams = new URLSearchParams(location.search);
  const [boardType, setBoardType] = useQueryState<string | null>(searchParams.get('type'), 'type');

  const [activeTab, setActiveTab] = useState<string | null>('all');
  const [allPosts, setAllPosts] = useState<IMixedKeyValue[]>([]);
  const [infoMe, setInfoMe] = useState(infoMeRedux || {});

  useEffect(() => {
    if (infoMeRedux?.id) return;
    const abortController = new AbortController();
    const getData = async () => {
      await getInfoMe();
    };
    getData();
    return () => abortController.abort();
  }, [infoMeRedux]);

  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          const cnt = 0;
          setCount(cnt);
        },
      }),
    );
    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  // pagination for infinite
  const targetRef = useRef(null);
  const { count, setCount } = useInfiniteScroll({
    root: null,
    rootMargin: '0px',
    target: targetRef,
    targetArray: allPosts,
    threshold: 0.5,
    endPoint: 4,
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(Config.API_PAGE_SIZE || 5);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  /**
   * @description boardType별 active select
   * // mount 될때 historyPath 초기화설정
   */
  useEffect(() => {
    dispatch(ActionUser.resetHistoryPath());
    boardType ? setActiveTab(boardType) : setActiveTab('all');
  }, [boardType]);

  useEffect(() => {
    setPage((prev) => count + 1);
  }, [count]);

  /**
   * @description 리스트 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const f = async () => {
      dispatch(ActionLoading.onDataLoad());
      const kind: number | null = activeTab === 'free-board' ? 1500 : activeTab === 'issue' ? 1501 : null;
      if (page >= 1 || totalPage >= page) {
        await dispatch(fetchAllBoardPost({ kind, keyword: '', page_size: pageSize, page }));
      }
    };
    f();
    return () => abortController.abort();
  }, [activeTab, page]);

  useEffect(() => {
    if (boardType === 'all') {
      setAllPosts(allBoardPost.posts);
      setTotal(allBoardPost.total);
      setTotalPage(Math.ceil(allBoardPost.total / Config.API_PAGE_SIZE));
    } else if (boardType === 'free-board') {
      setAllPosts(freeBoardPost.posts);
      setTotal(freeBoardPost.total);
      setTotalPage(Math.ceil(freeBoardPost.total / Config.API_PAGE_SIZE));
    } else if (boardType === 'issue') {
      setAllPosts(issueBoardPost.posts);
      setTotal(issueBoardPost.total);
      setTotalPage(Math.ceil(issueBoardPost.total / Config.API_PAGE_SIZE));
    } else {
      setAllPosts(allBoardPost.posts);
      setTotal(allBoardPost.total);
      setTotalPage(Math.ceil(allBoardPost.total / Config.API_PAGE_SIZE));
    }
  }, [allBoardPost, freeBoardPost, issueBoardPost]);

  /**
   * @description active tab
   * @param {string} tab 선택된 탭
   * @returns {void}
   */
  const handleTabClick = useCallback(
    (tab: string) => {
      setActiveTab(tab);
      setBoardType(tab);
      setCount(0);
      setPage(1);
      setAllPosts([]);
    },
    [activeTab],
  );

  /**
   * @description 내정보 api call
   * @returns {Promise<void>}
   */
  const getInfoMe = useCallback(async () => {
    try {
      const resMe = await network().sso().me();
      dispatch(ActionUser.setMe(resMe.data));
      console.log(resMe);
    } catch (e) {
      console.error(e);
    }
  }, []);

  /**
   * @description 툴팁형 bottomSheet
   * @returns {void}
   */
  const handleMoreBotSheet = (post: IMixedKeyValue): void => {
    if (infoMe.id ? post?.user?.id === infoMe?.id : post?.user?.id === currentUser?.id) {
      dispatch(
        ActionModal.openModal({ modalType: 'bottomSheet_menuMyPost', data: post, isOpen: true, infoMe: infoMe.id }),
      );
    } else {
      dispatch(ActionModal.openModal({ modalType: 'bottomSheet_menu', data: post, isOpen: true, infoMe: infoMe.id }));
    }
  };

  /**
   * @description 좋아요 싫어요 handler
   * @returns {void}
   */
  const handleLikeOrUnlike = (post: IMixedKeyValue, type: string) => {
    const kindId: number | null = activeTab === 'free-board' ? 1500 : activeTab === 'issue' ? 1501 : null;
    const params = {
      kind: type === 'like' ? 1 : 2,
    };
    dispatch(setBoardPostLike(kindId, post?.id, params.kind));
  };

  /**
   * @description comment bottom sheet
   * @returns {void}
   */
  const handleComment = (post: IMixedKeyValue): void => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate(`/postdetail/${post?.id}?show=true`);
    } else {
      //onView(`${import.meta.env.VITE_APP_DOMAIN}/postdetail/${post?.id}?show=true`);
    }
  };

  /**
   * @description 게시글 상세
   * @param id {post id}
   * @returns {void}
   */
  const movePageBoardDetail = (id: number): void => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate(`/postdetail/${id}`);
    } else {
      //onView(`${import.meta.env.VITE_APP_DOMAIN}/postdetail/${id}`);
    }
  };

  /**
   * @description 전체게시판에 각 게시판별 글이 없을 경우
   * @returns {JSX.Element}
   */
  const noItem = (
    <div className={CommonStyles['no-data']} style={{ height: 'calc(100vh - 98px - 49px)', textAlign: 'center' }}>
      <p>
        아직 게시글이 없어요!
        <br />‘{infoMe?.nickname || currentUser?.nickname}’님께서
        <br />
        가장 먼저 나누고 싶은 이야기를 써보세요.
      </p>
    </div>
  );

  const BoardPost =
    allPosts.length > 0 &&
    allPosts?.map((post: IMixedKeyValue, index: number) => {
      const nowDate = new Date();
      return (
        <div key={index} className={CommonStyles['post-card']} id={`post-card-${post?.id}`}>
          <div className={CommonStyles['member-information']}>
            <button className={CommonStyles.clickable}>
              <div className={CommonStyles.profile}>
                <div
                  className={CommonStyles['img-div']}
                  style={{
                    width: 48,
                    height: 48,
                    backgroundImage: post?.user?.profile_photo_path
                      ? `url(${post?.user?.profile_photo_path}`
                      : `url(../images/icon_profile.svg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                  }}
                />
                {post?.user?.verify?.broker_code && (
                  <span className={CommonStyles.grade} aria-label={post?.user?.grade}></span>
                )}
              </div>
              <div className={CommonStyles['mem-info']}>
                <p className={CommonStyles.name}>{post?.user?.nickname}</p>
                <div className={CommonStyles['divide-box']}>
                  {post?.user?.company && (
                    <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                      {post?.user?.company?.name}
                    </p>
                  )}
                  <p>{timeDisplay(nowDate, post?.created_at)}</p>
                </div>
              </div>
            </button>
            <button className={CommonStyles.tool} onClick={() => handleMoreBotSheet(post)} />
          </div>
          {post?.post_report?.length > 0 || post?.user_block ? (
            <div className={CommonStyles.detail}>
              {post?.user_block && <div className={CommonStyles['blind-box']}>{Config.POST_MESSAGE.POST_BLOCKED}</div>}
              {post?.post_report?.length > 0 && (
                <div className={CommonStyles['blind-box']}>{Config.POST_MESSAGE.POST_DECLARED}</div>
              )}
            </div>
          ) : (
            <>
              <button
                type="button"
                className={CommonStyles.detail}
                onClick={(e) => {
                  movePageBoardDetail(post?.id);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {post?.title && (
                  <div className={cn(CommonStyles.title, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                    {post?.title}
                  </div>
                )}
              </button>
              {post && (
                <div className={CommonStyles.detail} style={{ width: '100%', height: 'auto' }}>
                  <PostContent id={post.id} text={post?.content} post={post} maxLines={3} main={true} />
                </div>
              )}
              <PostAttach post={post} main={true} />
            </>
          )}
          {!post?.post_report?.length && !post?.user_block && (
            <div className={CommonStyles['post-function']}>
              <button
                type="button"
                className={cn(
                  CommonStyles.like,
                  post?.post_action && post?.post_action?.[0]?.kind === 1 && CommonStyles.on,
                )}
                onClick={() => handleLikeOrUnlike(post, 'like')}
              >
                <i className={CommonStyles['icon-like']}></i>
                {post?.liked_count}
              </button>
              <button
                type="button"
                className={cn(
                  CommonStyles.dislike,
                  post?.post_action && post?.post_action?.[0]?.kind === 2 && CommonStyles.on,
                )}
                onClick={() => handleLikeOrUnlike(post, 'disLike')}
              >
                <i className={CommonStyles['icon-dislike']}></i>
                {post?.hated_count}
              </button>
              <button type="button" className={CommonStyles.comment} onClick={() => handleComment(post)}>
                <i className={CommonStyles['icon-comment']}></i>
                {post?.comments_list_count}
              </button>
            </div>
          )}
        </div>
      );
    });

  // console.log('activeTab', activeTab);
  // console.log('allPosts', allPosts);
  // console.log('isConfirm in AllBoard==>', isConfirm, page);
  // console.log('page===>', page);
  // console.log('count===>', count);
  // console.log('total===>', total);
  // console.log('totalPage===>', totalPage);
  // console.log('boardType==>', boardType);

  return (
    <>
      {isDataLoading && <ListLoader />}
      <div
        className={cn(
          CommonStyles.content,
          CommonStyles.board,
          Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
        )}
        id="board"
      >
        <div className={cn(CommonStyles['tab-menu'], CommonStyles['round-tab'])}>
          <button
            className={cn(CommonStyles.tab, activeTab === 'all' && CommonStyles.on)}
            onClick={() => handleTabClick('all')}
          >
            전체
          </button>
          <button
            className={cn(CommonStyles.tab, activeTab === 'free-board' && CommonStyles.on)}
            onClick={() => handleTabClick('free-board')}
          >
            자유게시판
          </button>
          <button
            className={cn(CommonStyles.tab, activeTab === 'issue' && CommonStyles.on)}
            onClick={() => handleTabClick('issue')}
          >
            부동산이슈
          </button>
        </div>
        <section ref={targetRef}>{allPosts?.length > 0 ? BoardPost : noItem}</section>
        {toastState.isOpen && <Toast />}
      </div>
    </>
  );
};
export default AllBoard;
