import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { fetchPostCommentsData } from 'api/comment';
import * as ActionLoader from 'redux/slices/loading';

import ScrollLoader from 'components/common/Loader/ScrollLoader';
import Comment from './Comment';
import Toast from 'components/common/Toast/Toast';
import useToast from 'hook/useToast';

import CommonStyles from 'styles/common.module.css';
import { IMixedKeyValue } from '<modal>';
import { ICommentItem, ICommentType, TisEdit } from '<comments>';

import * as Config from 'configs/configs';

interface Props {
  data: IMixedKeyValue;
  commentType?: ICommentType;
  setCommentType?: (commentType: ICommentType) => void;
  isEdit?: TisEdit;
  setIsEdit?: (isEdit: TisEdit) => void;
}

/**
 * @description 댓글 list F.C
 * @Class
 * @category Components
 * @subcategory 댓글
 * @param {CommentProps} props
 * @param {object} props.data
 * @param {object} props.commentType
 * @param {function} props.setCommentType
 * @param {object} props.isEdit
 * @param {function} props.setIsEdit
 * @returns {JSX.Element}
 */
const CommentList = ({ data, commentType, setCommentType, isEdit, setIsEdit }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const comments = useAppSelector((state: RootState) => state.comment);
  const toastState = useAppSelector((state: RootState) => state.toast);
  const { isCommentLoading } = useAppSelector((state: RootState) => state.loading);
  const { setToastMessage } = useToast();

  /**
   * @description 데이터 fetching
   */
  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchPostCommentsData(data?.cate?.key, data?.id, data?.group_id, data?.isType));
    };
    getData();
  }, [dispatch, data]);

  /**
   * @description isFetch일 경우
   */
  useEffect(() => {
    if (comments.isRemoveComment && !comments.commentLists?.length) {
      dispatch(ActionLoader.offCommentLoad());
      return;
    }
    if (!comments.isFetch) {
      dispatch(ActionLoader.onCommentLoad());
      if (comments.deletedId || comments.deletedChildId) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.COMMENT_DELETE,
          type: 'message',
        });
      }
    } else {
      dispatch(ActionLoader.offCommentLoad());
    }
  }, [comments]);

  // if (comments.deletedId)
  // console.log('data in reduxSlice ===>', data);
  // console.log('comments in reduxSlice ===>', comments);
  // console.log('isCommentLoading ==>', isCommentLoading);

  return (
    <>
      {comments.commentLists!.length > 0 ? (
        comments.commentLists?.map((item: ICommentItem) => {
          return (
            <Comment
              key={item.id}
              id={`${item.id}`}
              comment={item}
              commentType={commentType}
              setCommentType={setCommentType}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          );
        })
      ) : isCommentLoading ? (
        <div style={{ minHeight: 40 }}>
          <ScrollLoader />
        </div>
      ) : (
        <div className={CommonStyles['no-data']} style={{ height: 'calc(168px)', textAlign: 'center' }}>
          <p>
            <span style={{ fontSize: 14, fontWeight: 700, whiteSpace: 'pre-wrap' }}>
              {data?.isType === 'coagency' || data?.isType === 'looking-for'
                ? `공동중개 댓글은\n 나와 작성자만 볼 수 있는 비밀댓글입니다.`
                : '아직 댓글이 없습니다.'}
            </span>
            <br />
            <span style={{ fontSize: 12 }}>
              {data?.isType === 'coagency' || data?.isType === 'looking-for'
                ? `댓글을 남겨서 공동중개를 진행해보세요.`
                : '댓글을 남겨보세요.'}
            </span>
          </p>
        </div>
      )}
      {toastState.isOpen && <Toast />}
    </>
  );
};

export default CommentList;
