import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { closeModal, closeMultiModal, openModal } from 'redux/slices/modal';
import { IMixedKeyValue } from '<modal>';

/**
 * @description 구합니다 bottom sheet
 * @Class
 * @category Components
 * @subcategory 구합니다 sheet | modal
 * @component
 * @param {string} props.title sheet title
 * @returns {JSX.Element}
 */
const FindShareBotSheet = ({ title = '' }: { title?: string }) => {
  const dispatch = useAppDispatch();
  const { modalType, multiAction, multiData, multiModalType } = useAppSelector((state: RootState) => state.modal);

  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [type, setType] = useState<IMixedKeyValue>({
    code: '',
    name: '',
  });
  const [memo, setMemo] = useState('');

  useEffect(() => {
    if (multiData?.name) {
      setName(multiData?.name);
    }

    if (multiData?.tel) {
      setTel(multiData?.tel);
    }

    if (multiData?.typeName) {
      setType({
        code: multiData.code,
        name: multiData.typeName,
      });
    }
  }, [multiData]);

  // 수정하기 버튼
  const handleClickModify = () => {
    let payload = {};

    if (multiData?.name !== name) {
      payload = {
        ...payload,
        name: name,
      };
    }

    if (multiData?.tel !== tel) {
      payload = {
        ...payload,
        tel: tel,
      };
    }

    if (type?.code) {
      payload = {
        ...payload,
        code: type.code,
      };
    }

    if (type?.name) {
      payload = {
        ...payload,
        typeName: type.name,
      };
    }

    multiAction?.(payload);
    setTimeout(() => {
      dispatch(closeMultiModal({ multiModalType: modalType, isMultiple: false, isConfirm: true }));
    }, 200);
  };

  const handleModalClose = () => {
    dispatch(closeMultiModal({ modalType: modalType, isMultiple: false, isConfirm: true }));
  };

  // 관계 선택
  const handleClickOpenRelationModal = () => {
    dispatch(
      openModal({
        modalType: 'findshare_modal_relation_type',
        isOpen: true,
        action: (val: IMixedKeyValue) => {
          if (Object.keys(val).length > 0) {
            setType({
              ...val,
            });
          }
        },
      }),
    );
  };

  return (
    <div className={cn(CommonStyles['popup-container'], CommonStyles['bottom-sheet-popup'])}>
      <div className={CommonStyles['popup-header']}>
        <p className={CommonStyles['pop-tit']}>{title}</p>
        <button className={CommonStyles['close']} onClick={handleModalClose}></button>
      </div>
      <div className={cn(CommonStyles['popup-content'], CommonStyles['share-write'])}>
        <section>
          {(modalType === 'findshare_bot_sheet_info' || multiModalType === 'findshare_bot_sheet_info') && (
            <>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>고객명</p>
                <div className={cn(CommonStyles['d-flex'], CommonStyles['half'])} style={{ display: 'flex' }}>
                  <div className={CommonStyles['input-area']}>
                    <input
                      type="text"
                      placeholder="고객명(비공개)"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <button className={CommonStyles['dropdown']} onClick={handleClickOpenRelationModal}>
                    <span>{type?.name ? type.name : '선택'}</span>
                    <i className={CommonStyles['icon-arrow-down-gr']}></i>
                  </button>
                </div>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>고객 연락처</p>
                <div className={CommonStyles['input-area']}>
                  <input
                    type="text"
                    placeholder="고객연락처(비공개)"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}
          {(modalType === 'findshare_bot_sheet_info' || multiModalType === 'findshare_bot_sheet_info') && (
            <>
              <div className={CommonStyles['insert-item']}>
                <div className={CommonStyles['textarea-div']}>
                  <textarea placeholder="중개사 메모(비공개)"></textarea>
                  <p className={CommonStyles['byte']}>
                    <b>0</b>/1000
                  </p>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <div className={CommonStyles['popup-button-wrap']}>
        {(modalType === 'findshare_bot_sheet_info' || multiModalType === 'findshare_bot_sheet_info') && (
          <button className={cn(CommonStyles['btn'], CommonStyles['lg'])} onClick={handleClickModify}>
            수정하기
          </button>
        )}
        {(modalType === 'findshare_bot_sheet_memo' || multiModalType === 'findshare_bot_sheet_memo') && (
          <button className={cn(CommonStyles['btn'], CommonStyles['lg'])}>완료</button>
        )}
      </div>
    </div>
  );
};

export default FindShareBotSheet;
