import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';

type GradeType = '새싹' | '열매' | '초수' | '중수' | '고수' | '영웅';

interface GradeBadgeInterface {
  grade?: GradeType | string;
}

/**
 * @description 등급 뱃지
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {GradeType} props.grade 유저 등급
 * @returns {JSX.Element}
 */
const GradeBadge = ({ grade = '새싹' }: GradeBadgeInterface) => {
  return <div className={cn(CommonStyles.grade)} aria-label={grade} />;
};

export default GradeBadge;
