import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';
import { IMixedKeyValue } from '<modal>';

interface DatePickerInterface {
  setSelectedStartDate?: (...args: any[]) => void;
  setSelectedEndDate?: (...args: any[]) => void;
  selectedStartDate?: IMixedKeyValue | null | undefined;
  selectedEndDate?: IMixedKeyValue | null | undefined;
  refresh?: string;
}

/**
 * @description DatePicker
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {void} setSelectedStartDate start date
 * @param {void} setSelectedEndDate end date
 * @param {any} selectedStartDate 선택된 start date
 * @param {any} selectedEndDate 선택된 end date
 * @param {string} refresh 새로고침 여부
 * @returns {JSX.Element}
 */
const DatePicker = ({
  setSelectedStartDate,
  setSelectedEndDate,
  selectedStartDate,
  selectedEndDate,
  refresh,
}: DatePickerInterface) => {
  const currentDateRef = useRef(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState<number>(0);

  useEffect(() => {
    if (refresh !== '') {
      // 새로고침 할 때 하단 날짜 옵션 버튼 선택 해제
      setSelectedDate(0);
    }
  }, [refresh]);

  /**
   * @description 선택된 날짜 클릭
   * @param {IMixedKeyValue} clickedDate 선택한 날짜
   * @returns {void}
   */
  const handleDateClick = (clickedDate: IMixedKeyValue) => {
    if (selectedStartDate !== null && selectedEndDate !== null) {
      setSelectedStartDate?.(clickedDate);
      setSelectedEndDate?.(null);
      return;
    }
    // 선택된 시작 날짜가 없으면 시작 날짜로 설정
    if (!selectedStartDate) {
      setSelectedStartDate?.(clickedDate);
    } else {
      if (selectedStartDate !== null) {
        // 만약에 endDate가 startDate보다 이전 날짜를 클릭할 경우 retrun false
        if (selectedStartDate?.fullDate?.getTime() > clickedDate?.fullDate?.getTime()) {
          return;
        } else {
          setSelectedEndDate?.(clickedDate);
        }
      }
    }
  };

  /**
   * @description 이전달 prev click
   * @returns {void}
   */
  const handleClickPrev = () => {
    const previousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

    if (
      (currentDateRef.current.getFullYear() > previousMonth.getFullYear() &&
        previousMonth.getMonth() > currentDateRef.current.getMonth()) ||
      (currentDateRef.current.getFullYear() === previousMonth.getFullYear() &&
        previousMonth.getMonth() < currentDateRef.current.getMonth())
    ) {
      return;
    }
    setCurrentDate(previousMonth);
  };

  /**
   * @description 다음달 next click
   * @returns {void}
   */
  const handleClickNext = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
  };

  /**
   * @description 이전 달의 마지막 날짜 구하기
   * @param {IMixedKeyValue} date 날짜 데이터
   * @returns {void}
   */
  const getDaysInMonth = (date: IMixedKeyValue) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    //const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const daysInMonth = [];

    // 이전 달의 마지막 날짜 구하기
    const prevMonthLastDay = new Date(year, month, 0);
    const prevMonthLastDate = prevMonthLastDay.getDate();

    // 이전 달의 마지막 요일
    const prevMonthLastDayOfWeek = prevMonthLastDay.getDay();

    // 이전 달의 마지막 요일부터 현재 달의 마지막 날짜까지의 날짜 추가
    for (let i = prevMonthLastDate - prevMonthLastDayOfWeek; i <= prevMonthLastDate; i++) {
      daysInMonth.push({ date: i, isPrevMonth: true, isNextMonth: false, fullDate: new Date(year, month - 1, i) });
    }

    // 현재 달의 날짜 추가
    for (let i = 1; i <= lastDay.getDate(); i++) {
      daysInMonth.push({ date: i, isPrevMonth: false, isNextMonth: false, fullDate: new Date(year, month, i) });
    }

    return daysInMonth;
  };

  /**
   * @description 기간 설정 단축 버튼
   * @param {number} day 기간 설정 버튼 날짜
   * @returns {void}
   */
  const handleClickDateOption = (day: number) => {
    const copyCurrentDate = new Date();
    copyCurrentDate.setDate(copyCurrentDate.getDate() + day - 1);

    setSelectedStartDate?.({
      date: new Date().getDate(),
      isPrevMonth: false,
      isNextMonth: false,
      fullDate: new Date(),
    });

    setSelectedEndDate?.({
      date: copyCurrentDate.getDate(),
      isPrevMonth: false,
      isNextMonth: false,
      fullDate: copyCurrentDate,
    });

    setCurrentDate(copyCurrentDate);
    setSelectedDate(day);
  };

  /**
   * @description 달력에 표시될 날짜 sort
   */
  const daysInMonth = getDaysInMonth(currentDate);

  /**
   * @description date format
   * @param {Date} date format 변경할 날짜 data
   * @returns {void}
   */
  const convertDateFormat = (date: Date) => {
    if (!date) return '';
    return moment(date).format('YYYY-MM-DD');
  };

  /**
   * @description  달력에 주말 빨간날 또는 다음달에 표시된 저번달의 일짜 css 처리
   * @returns {void}
   */
  const RenderDatePicker = () => {
    return daysInMonth
      .reduce((rows: any, day, index) => {
        if (index % 7 === 0) {
          rows.push([]);
        }
        rows[rows.length - 1].push(day);
        return rows;
      }, [])
      .map((week: any[]) =>
        week.map((day, dayIndex) => {
          let defaultProps = {};
          console.log(day, 'day');
          if (
            day.isPrevMonth ||
            moment?.(week[dayIndex].fullDate)?.format('YYYY-MM-DD') !== moment?.(day.fullDate)?.format('YYYY-MM-DD') ||
            (day?.fullDate.getMonth() === new Date().getMonth() && day?.fullDate.getDate() < new Date().getDate())
          ) {
            defaultProps = {
              style: {
                color: '#AAAAAA',
              },
            };
          } else {
            if (
              day.fullDate.getDay() === 0 &&
              convertDateFormat(selectedStartDate?.fullDate) !== convertDateFormat(day.fullDate) &&
              convertDateFormat(selectedEndDate?.fullDate) !== convertDateFormat(day?.fullDate)
            ) {
              defaultProps = {
                style: {
                  color: '#ff5959',
                },
              };
            } else if (
              convertDateFormat(selectedStartDate?.fullDate) === convertDateFormat(day?.fullDate) ||
              convertDateFormat(selectedEndDate?.fullDate) === convertDateFormat(day?.fullDate)
            ) {
              defaultProps = {
                style: {
                  color: '#fff',
                },
              };
            }
          }

          return (
            <button
              className={
                moment?.(selectedStartDate?.fullDate)?.format('YYYY-MM-DD') ===
                  moment?.(day.fullDate)?.format('YYYY-MM-DD') &&
                (!day.isPrevMonth ||
                  moment?.(week[dayIndex].fullDate)?.format('YYYY-MM-DD') ===
                    moment?.(day.fullDate)?.format('YYYY-MM-DD')) &&
                selectedStartDate !== null
                  ? moment?.(selectedStartDate?.fullDate)?.format('YYYY-MM-DD') ===
                      moment?.(selectedEndDate?.fullDate)?.format('YYYY-MM-DD') &&
                    selectedStartDate !== null &&
                    selectedEndDate !== null
                    ? CommonStyles.active
                    : `${cn(CommonStyles.active, CommonStyles['start-date'])}`
                  : moment?.(selectedEndDate?.fullDate)?.format('YYYY-MM-DD') ===
                      moment?.(day.fullDate)?.format('YYYY-MM-DD') &&
                    (!day.isPrevMonth ||
                      moment?.(week[dayIndex].fullDate)?.format('YYYY-MM-DD') ===
                        moment?.(day.fullDate)?.format('YYYY-MM-DD')) &&
                    selectedEndDate !== null
                  ? `${cn(CommonStyles.active, CommonStyles['end-date'])}`
                  : selectedStartDate?.fullDate?.getTime() <= day.fullDate?.getTime() &&
                    selectedEndDate?.fullDate?.getTime() >= day.fullDate?.getTime()
                  ? CommonStyles.selected
                  : moment?.(new Date())?.format('YYYY-MM-DD') === moment?.(day.fullDate)?.format('YYYY-MM-DD')
                  ? CommonStyles.today
                  : ''
              }
              key={dayIndex}
              disabled={
                moment(week[dayIndex]?.fullDate).format('YYYY-MM-DD') === moment(day.fullDate).format('YYYY-MM-DD')
                  ? false
                  : day.isPrevMonth ||
                    (day.fullDate.getTime() < new Date().getTime() &&
                      moment(day.fullDate).format('YYYY-MM-DD') !== moment(new Date()).format('YYYY-MM-DD'))
                  ? true
                  : false
              }
              onClick={() => handleDateClick(day)}
            >
              <span {...defaultProps}>{day.date}</span>
            </button>
          );
        }),
      );
  };

  return (
    <div className={cn(CommonStyles['calendar-box'])}>
      {/* <p className={Styles.txt}>아래 캘린더에서 날짜를 선택해주세요.</p> */}
      <div className={CommonStyles['calendar-header']}>
        <button className={CommonStyles['prev-btn']} onClick={handleClickPrev}>
          <i className={CommonStyles['icon-back']}></i>
        </button>
        <p className={CommonStyles['year-month']}>{moment(currentDate).format('YYYY년 M월')}</p>
        <button className={CommonStyles['next-btn']} onClick={handleClickNext}>
          <i className={CommonStyles['icon-back']}></i>
        </button>
      </div>
      <div className={CommonStyles['calendar-body']}>
        <div className={CommonStyles['day-list']}>
          <span>일</span>
          <span>월</span>
          <span>화</span>
          <span>수</span>
          <span>목</span>
          <span>금</span>
          <span>토</span>
        </div>
        <div className={CommonStyles['date-list']}>{<RenderDatePicker />}</div>
      </div>
      <div className={CommonStyles['calendar-footer']}>
        <div className={CommonStyles['period-set-wrap']}>
          <button
            className={selectedDate === 7 ? CommonStyles['selected'] : ''}
            onClick={() => handleClickDateOption(7)}
          >
            7일
          </button>
          <button
            className={selectedDate === 14 ? CommonStyles['selected'] : ''}
            onClick={() => handleClickDateOption(14)}
          >
            14일
          </button>
          <button
            className={selectedDate === 30 ? CommonStyles['selected'] : ''}
            onClick={() => handleClickDateOption(30)}
          >
            30일
          </button>
          <button
            className={selectedDate === 60 ? CommonStyles['selected'] : ''}
            onClick={() => handleClickDateOption(60)}
          >
            60일
          </button>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
