import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatchThunk, useAppSelector, RootState } from 'redux/store';
import {
  getBookmarksForFree,
  getBookmarksForGroup,
  getMyPostsForFree,
  getMyPostsForGroup,
  deleteBookmarkForGroup,
  deleteBookmarkForFree,
  actionPostForGroup,
  actionPostForFree,
  getPostInfoForGroup,
  getPostInfoForFree,
  resetFeed,
} from 'api/feed';
import { timeDisplay } from 'utils/common';
import { API_PAGE_SIZE, TOAST_MESSAGE_TYPE, POST_MESSAGE } from 'configs/configs';
import { FeedErrorType, FeedItemType } from '../../@types/feed';
import styled from 'styled-components';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import PostContent from 'pages/allboard/PostContent';
import PostAttach from 'pages/allboard/PostAttach';

import { openModal } from 'redux/slices/modal';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';
import ListLoader from 'components/common/Loader/ListLoader';
import { setRefreshFn } from 'redux/slices/refresh';
import { onView } from 'utils';

const Target = styled.div`
  height: 10px;
`;

interface PostListPropsType {
  type: 'my' | 'bookmark'; // my : 내가 쓴 게시글, bookmark: 북마크 게시글
  boardType: 'free' | 'group'; // 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
  refreshFun?: () => void; // 새로 고침할 때 실행할 콜백함수
}

/**
 * @description 내피드 게시글 목록 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @param {string} props.type my : 내가 쓴 게시글, bookmark: 북마크 게시글
 * @param {string} props.type 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
 * @param {void} props.refreshFun 새로 고침할 때 실행할 콜백함수
 * @returns {JSX.Element}
 */
const PostList = ({ type = 'my', boardType = 'free', refreshFun }: PostListPropsType) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchThunk();
  const nowDate = new Date();
  // 게시판 종류, free: 전체 게시판, group: 가입한 모임 게시판
  //const boardType = new URLSearchParams(window.location.search).get('type') ?? 'free';
  // 무한로딩 교차 기준 요소 ref
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);
  // api 요청 진행 상태, true: 진행 중, false : 진행완료
  const { loading } = useAppSelector((state: RootState) => state.feed);
  // 팝업 정보
  const { modalType, isOpen: isModalOpen } = useAppSelector((state: RootState) => state.modal);
  // 이전 isModalOpen 값(댓글 목록 팝업)
  const preIsModalOpenRef = useRef<boolean | undefined>(undefined);
  // 댓글의 post_id, group_id 값
  const postInfoRef = useRef<{ post_id: number; group_id: number } | null>(null);
  // 게시글 데이터
  const posts = useAppSelector((state: RootState) => {
    if (type == 'my') {
      return boardType == 'free' ? state.feed.posts : state.feed.group_posts;
    } else {
      return boardType == 'free' ? state.feed.bookmarks : state.feed.group_bookmarks;
    }
  });

  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);
  const { setToastMessage } = useToast();

  /**
   * @description 게시글 데이터 로딩
   * @param {number} page Page Number
   * @returns {void}
   */
  const loadPage = useCallback(
    async (page: number) => {
      if (boardType == 'free') {
        // 전체 게시판
        if (type == 'my') {
          // 내가 쓴 게시글
          await dispatch(getMyPostsForFree({ page: page, page_size: API_PAGE_SIZE }));
        } else {
          // 북마크 게시글
          await dispatch(getBookmarksForFree({ page: page, page_size: API_PAGE_SIZE }));
        }
      } else if (boardType == 'group') {
        // 모임 게시판
        if (type == 'my') {
          // 내가 쓴 게시글
          await dispatch(getMyPostsForGroup({ page: page, page_size: API_PAGE_SIZE }));
        } else {
          // 북마크 게시글
          await dispatch(getBookmarksForGroup({ page: page, page_size: API_PAGE_SIZE }));
        }
      }
    },
    [dispatch, type, boardType],
  );

  /**
   * @description 북마크 삭제
   * @param {number} id 북마크 id
   * @returns {void}
   */
  const deleteBookmark = async (id: number, groupId?: number, groupPostId?: number) => {
    try {
      if (boardType == 'free') {
        await dispatch(deleteBookmarkForFree({ id: id })).unwrap();
      } else if (boardType == 'group') {
        await dispatch(deleteBookmarkForGroup({ id: id, group_id: groupId, group_post_id: groupPostId })).unwrap();
      }

      setToastMessage({
        duration: 2000,
        content: TOAST_MESSAGE_TYPE.BOOKMARK_REMOVE,
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        setToastMessage({
          duration: 2000,
          content: TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      } else {
        //console.log('delete error:', feedError.message);
      }
    }
  };

  /**
   * @description 게시글 공감처리(좋아요, 싫어요)
   * @param {number} id 게시글 id
   * @param {number} action 0 싫어요 1 좋아요
   * @param {number} id 모임 id
   * @returns {void}
   */
  const actionPost = async (id: number, action: number | string, groupId?: number) => {
    try {
      if (boardType == 'free') {
        dispatch(actionPostForFree({ id: id, action: Number(action) }));
      } else if (boardType == 'group') {
        dispatch(actionPostForGroup({ id: id, group_id: groupId, action: String(action) }));
      }
    } catch (error) {
      //console.log('action error:', error);
    }
  };

  /**
   * @description 상세 페이지 이동
   * @returns {void}
   */
  const goToPost = (post: FeedItemType) => {
    const link = post.group_id ? `/group-postdetail/${post.group_id}/${post.id}` : `/postdetail/${post.id}`;

    if (type == 'my') {
      if (import.meta.env.MODE === 'localdev') {
        navigate(link);
      } else {
        onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
      }
    } else {
      navigate(link);
      /*
      if (import.meta.env.MODE === 'localdev') {
        navigate(link);
      } else {
        onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
      }
      */
    }
  };

  /**
   * @description 댓글 BottomSheet 팝업 열기
   * @param {post} 게시글 정보
   * @returns {void}
   */
  const handleComment = (post: FeedItemType) => {
    postInfoRef.current = { post_id: post.id, group_id: post.group_id };
    const link = post.group_id
      ? `/group-postdetail/${post.group_id}/${post.id}?show=true`
      : `/postdetail/${post.id}?show=true`;
    // dispatch(openModal({ modalType: 'show_comment_list', data: post }));
    if (import.meta.env.MODE === 'localdev') {
      navigate(link);
    } else {
      onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
    }
  };

  // state 형식이 올바르지 않을 경우 reset 처리
  useEffect(() => {
    if (posts === undefined) {
      dispatch(resetFeed());
    }
  }, [posts]);

  useEffect(() => {
    // 게시글 1 page 로딩
    loadPage(1);

    // 새로 고침
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          if (refreshFun) {
            refreshFun();
          }

          loadPage(1);
        },
      }),
    );
  }, [loadPage]);

  // 무한로딩 구현
  useEffect(() => {
    if (loading || !posts || !posts?.items || posts.items.length == 0 || !scrollAnchorRef.current) return;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (posts.current_page && posts.total_page && posts.current_page < posts.total_page) {
          loadPage(posts.current_page + 1);
        }
      }
    });

    observer.observe(scrollAnchorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [posts?.items, loading]);

  // 댓글 BottomSheet 팝업 닫았을 때
  useEffect(() => {
    const preIsModalOpen = preIsModalOpenRef.current;
    preIsModalOpenRef.current = isModalOpen;

    if (modalType == 'show_comment_list' && preIsModalOpen === true && isModalOpen === false) {
      if (boardType == 'free') {
        if (postInfoRef.current?.post_id) dispatch(getPostInfoForFree({ id: postInfoRef.current.post_id }));
      } else if (boardType == 'group') {
        if (postInfoRef.current?.post_id && postInfoRef.current?.group_id) {
          dispatch(getPostInfoForGroup({ id: postInfoRef.current?.post_id, group_id: postInfoRef.current?.group_id }));
        }
      }
    }
  }, [modalType, isModalOpen]);

  return (
    <>
      {(!posts || posts.items.length == 0) && <div className={CommonStyles['no-data']}>{POST_MESSAGE.POST_EMPTY}</div>}

      <div className={CommonStyles['post-box']}>
        {posts && posts.loading === true && <ListLoader />}
        {posts &&
          posts.items?.map((post, index) => {
            let brokerInfo = '';
            if (post.user.verify?.is_ceo == 1) {
              brokerInfo = '대표공인중개사';
            } else if (post.user.verify?.broker_code == 2) {
              brokerInfo = '소속공인중개사';
            } else if (post.user.verify?.broker_code == 4) {
              brokerInfo = '중개보조원';
            }

            // 공유 출처, 이미지, 첨부파일, 지도정보, 이모티콘 존재 여부
            const isAttached =
              post.post_share?.length > 0 ||
              post.photos.length !== 0 ||
              post.files.length !== 0 ||
              (post.latitude !== null && post.longitude !== null) ||
              post.emoticon !== null;

            // 게시글에 대한 사용자의 공감정보(1: 좋아요, 2: 싫어요)
            const action = post.post_action?.length > 0 ? post.post_action[0].kind : null;

            return (
              <div key={index} className={CommonStyles['post-card']}>
                {/* 프로필 영역 */}
                <div className={CommonStyles['member-information']}>
                  <button className={CommonStyles.clickable}>
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{
                          backgroundImage: post.user?.profile_photo_path
                            ? `url(${post.user.profile_photo_path})`
                            : undefined,
                        }}
                      ></div>
                      {post?.user?.verify?.broker_code && (
                        <span className={CommonStyles.grade} aria-label={post.user.grade}></span>
                      )}
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{post.user.nickname}</p>
                      <div className={CommonStyles['divide-box']}>
                        {(post.user.company?.name || brokerInfo) && (
                          <p className={CommonStyles['limit-line1']}>
                            {post.user.company?.name} {brokerInfo}
                          </p>
                        )}
                        <p>{timeDisplay(nowDate, post.created_at)}</p>
                      </div>
                    </div>
                  </button>
                  {/* 더보기 또는 삭제 버튼 */}
                  <button
                    className={
                      type == 'my' ? CommonStyles.tool : cn(CommonStyles.btn, CommonStyles.sm, CommonStyles.line)
                    }
                    onClick={() => {
                      if (type == 'my') {
                        // 더보기(내가 쓴 게시글)
                        dispatch(
                          openModal({
                            modalType: 'bottomSheet_feed_more',
                            data: {
                              post: post,
                            },
                          }),
                        );
                      } else {
                        // 삭제(북마크 게시글)
                        const action = () => {
                          deleteBookmark(post.post_bookmark[0].id, post.group_id, post.post_bookmark[0].group_post_id);
                        };

                        dispatch(openModal({ modalType: 'confirm_bookmark_comment', action: action }));
                      }
                    }}
                  >
                    {type == 'bookmark' ? '삭제' : ''}
                  </button>
                </div>
                {post.user_block || post.post_report?.length > 0 ? (
                  // 차단된 게시물, 신고된 게시물 일 경우
                  <div className={CommonStyles.detail}>
                    <div className={CommonStyles['blind-box']}>
                      {post.user_block ? POST_MESSAGE.POST_BLOCKED : POST_MESSAGE.POST_DECLARED}
                    </div>
                  </div>
                ) : (
                  // 게시물 내용
                  <button className={CommonStyles.detail}>
                    {post.title && (
                      <p
                        className={cn(CommonStyles['title'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}
                        onClick={() => goToPost(post)}
                      >
                        {post.title}
                      </p>
                    )}
                    <PostContent
                      id={post.id}
                      text={post?.content}
                      post={post}
                      maxLines={3}
                      main={type == 'my' ? true : false}
                    />
                    <div className={CommonStyles.resource}>
                      [
                      <span className={CommonStyles['divide-box']}>
                        {boardType == 'free' ? (
                          <>
                            <p>전체게시판</p>
                            <p>{post.cate?.label}</p>
                          </>
                        ) : (
                          <p>{post.group?.name}</p>
                        )}
                      </span>
                      ]
                    </div>
                    {isAttached && (
                      <PostAttach
                        post={post}
                        attachType={type == 'my' ? 'feed' : 'bookmark'}
                        main={type == 'my' ? true : false}
                      />
                    )}
                  </button>
                )}

                {/* 공감 정보 */}
                <div className={CommonStyles['post-function']}>
                  <button
                    className={cn(CommonStyles.like, action === 1 && CommonStyles.on)}
                    onClick={() => actionPost(post.id, boardType == 'free' ? 1 : 'like', post.group_id)}
                  >
                    <i className={CommonStyles['icon-like']}></i>
                    {post.liked_count}
                  </button>
                  <button
                    className={cn(CommonStyles.dislike, action === 2 && CommonStyles.on)}
                    onClick={() => actionPost(post.id, boardType == 'free' ? 2 : 'hate', post.group_id)}
                  >
                    <i className={CommonStyles['icon-dislike']}></i>
                    {post.hated_count}
                  </button>
                  <button className={CommonStyles.comment} onClick={() => handleComment(post)}>
                    <i className={CommonStyles['icon-comment']}></i>
                    {post.comments_list_count}
                  </button>
                </div>
              </div>
            );
          })}
        <Target ref={scrollAnchorRef} />
        {/* Toast 팝업 */}
        {isOpen && <Toast />}
      </div>
    </>
  );
};

export default PostList;
