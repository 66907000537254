import React, { useCallback, useEffect, useRef, useState, ChangeEvent, KeyboardEvent } from 'react';
import { useParams } from 'react-router-dom';
import * as APIS from 'api/group';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import GradeBadge from 'components/common/Badge/GradeBadge';
import Button from 'components/common/Button/Button';
import { IMixedKeyValue } from '<modal>';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import useScroll from 'hook/useScroll';
import styled from 'styled-components';
import { openModal } from 'redux/slices/modal';
import Input from 'components/common/Input/Input';
import ListLoader from 'components/common/Loader/ListLoader';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';

const PAGE_SIZE = 10;

/**
 * @description 모임 설정 - 모임장 설정
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupLeader = () => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();
  const groupData = useAppSelector((state: RootState) => state.group);
  const { myInfo } = groupData;

  // 회원 리스트
  const lastPage = useRef(false);
  const isSearch = useRef<boolean>(false);
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  const [leaderList, setLeaderList] = useState<IMixedKeyValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState({
    page: 1,
    key: new Date().getTime(),
  });

  // 검색
  const [searchValue, setSearchValue] = useState<string>('');

  // 새로고침 중복 방지
  const isRefreshRef = useRef<boolean>(false);

  // 회원 조회
  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchList();
      };
      if (isRefreshRef.current) return;
      f();
    }
  }, [page]);

  // 모임장 조회
  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchLeaderList();
      };
      f();
    }
  }, []);

  // 새로고침 함수 설정 및 초기화
  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );
    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  /**
   * @description 모임장 조회 api
   * @returns {Promise<void>}
   */
  const fetchLeaderList = async () => {
    try {
      const res = await APIS.getLeaderList(
        searchValue ? `/groups/${id}/users/admin?q=${searchValue}` : `/groups/${id}/users/admin`,
      );
      const { data = [] } = res;

      if (data) {
        setLeaderList([...data]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * @description 모임 회원 조회 api
   * @param {boolean} isRefresh 새로고침 여부(새로고침을 통해서 api를 조회한건지 분기 처리)
   * @returns {Promise<void>}
   */
  const fetchList = async (isRefresh = false) => {
    try {
      setLoading(true);
      let queryString = `/groups/${id}/users/normal?page=${isRefresh ? 1 : page.page}&page_size=${PAGE_SIZE}`;
      if (searchValue) {
        queryString += `&q=${searchValue}`;
      } else {
        isSearch.current = false;
      }
      const res = await APIS.getMemberList(queryString);
      const { data = [], links, meta } = res;
      const { total: totalCnt = 0 } = meta;
      const { next = null } = links;

      setTotal(totalCnt);

      // 마지막 페이지 체크
      if (next === null) {
        lastPage.current = true;
      }
      if (data) {
        setList((prev) => [...prev, ...data]);
      }
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 무한 스크롤 observe
   * @returns {Promise<void>}
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);

    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && list.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  /**
   * @description 모임장 위임 modal open
   * @param {string} nickname 위임 받을 유저 닉네임
   * @param {number} nickname 위임 받을 유저 userId
   * @returns {Promise<void>}
   */
  const handleClickMandate = useCallback(
    (nickname: string, userId: number) => {
      dispatch(
        openModal({
          isOpen: true,
          modalType: 'confirm_group_transfer_leader',
          data: {
            groupId: id,
            nickname: nickname,
            userId: userId,
          },
        }),
      );
    },
    [list],
  );

  /**
   * @description 검색
   * @returns {Promise<void>}
   */
  const onKeyDownSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setLeaderList([]);
      setList([]);
      setTotal(0);
      setPage({
        page: 1,
        key: new Date().getTime(),
      });

      lastPage.current = false;
      isSearch.current = true;
      fetchLeaderList();
    }
  };

  /**
   * @description 새로고침에 실행시킬 함수
   * @returns {Promise<void>}
   */
  const refreshFn = () => {
    setLeaderList([]);
    setList([]);
    setTotal(0);
    setPage({
      page: 1,
      key: new Date().getTime(),
    });
    fetchLeaderList();

    fetchList(true);

    lastPage.current = false;
    isSearch.current = true;
    isRefreshRef.current = true;
  };

  return (
    <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
      <div className={CommonStyles['inner']}>
        <Input
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
          onKeyDown={(e) => onKeyDownSearch(e)}
          handleClickClear={() => setSearchValue('')}
          className={cn(CommonStyles['search-input'])}
          placeholder="닉네임 검색"
          clear={true}
          mergeClass={true}
        />
      </div>
      <div className={CommonStyles['sub-title']}>
        {isSearch.current ? '검색결과' : '회원'}
        <b className={CommonStyles['co-dorg']}>
          {isSearch.current ? list.length + leaderList.length : total + leaderList.length}
        </b>
      </div>
      <div className={CommonStyles['member-list']}>
        {leaderList.map((item, index: number) => {
          const isGroupLeader = item.value === 'OWNER' ? true : false;
          return (
            <div key={index} className={CommonStyles.list}>
              <div className={CommonStyles['member-information']}>
                <div className={CommonStyles.clickable}>
                  <div className={CommonStyles.profile}>
                    <div
                      className={CommonStyles['img-div']}
                      style={{ backgroundImage: `url(${item?.profile_photo_path})` }}
                    />
                    {item?.verify?.broker_code && <GradeBadge grade={item.grade} />}
                  </div>
                  <div className={CommonStyles['mem-info']}>
                    <p className={CommonStyles.name}>{item.nickname}</p>
                    <div className={CommonStyles['divide-box']}>
                      {item?.company?.name && (
                        <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {item.company?.name || ''}
                        </p>
                      )}
                      {item.label && <p>{item.label}</p>}
                    </div>
                  </div>
                </div>
                {!isGroupLeader && (
                  <Button
                    type="line"
                    onClick={() => handleClickMandate(item.nickname, item.id)}
                    disabled={myInfo.value !== 'OWNER' ? true : false}
                  >
                    위임
                  </Button>
                )}
              </div>
            </div>
          );
        })}
        {list.map((item, index: number) => {
          const isGroupLeader = item.value === 'OWNER' ? true : false;
          return (
            <React.Fragment key={index}>
              <div key={index} className={CommonStyles.list}>
                <div className={CommonStyles['member-information']}>
                  <div className={CommonStyles.clickable}>
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{ backgroundImage: `url(${item?.profile_photo_path})` }}
                      />
                      {item?.verify?.broker_code && <GradeBadge grade={item.grade} />}
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{item.nickname}</p>
                      <div className={CommonStyles['divide-box']}>
                        {item?.company?.name && (
                          <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {item.company?.name || ''}
                          </p>
                        )}
                        {item.label && <p>{item.label}</p>}
                      </div>
                    </div>
                  </div>
                  {!isGroupLeader && (
                    <Button
                      type="line"
                      onClick={() => handleClickMandate(item.nickname, item.id)}
                      disabled={myInfo.value !== 'OWNER' ? true : false}
                    >
                      위임
                    </Button>
                  )}
                </div>
                {index + 1 === list.length && !lastPage.current && <Target ref={ref} />}
              </div>
              {index + 1 === list.length && loading && <ListLoader />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default GroupLeader;

const Target = styled.div`
  height: 1px;
`;
