import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import './App.css';

/**
 * @description 리액트 프로젝트의 라우터를 return하는 App F.C
 * @Class
 * @category App Root
 * @subcategory router
 * @component
 * * @example
 * return (
 *   <RouterProvider router={router} />
 * )
 * @returns {JSX.Element}
 */
const App = (): JSX.Element => <RouterProvider router={router} />;

export default App;
