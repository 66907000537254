import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import { TOffers } from '@types';

/**
 * @description 공동중개 modal
 * @Class
 * @category Components
 * @subcategory coagency modal
 * @component
 * @returns {JSX.Element}
 */
const LegacyItem = () => {
  const dispatch = useAppDispatch();
  const { modalType, action, data, isOpen } = useAppSelector((state: RootState) => state.modal);

  const handleConfirm = (item: TOffers.TImportOffersScList) => {
    action?.(item);
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };
  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show)}>
        <div className={cn(Styles['popup-container'], Styles['modal-popup'], CommonStyles['address-select-pop'])}>
          <div className={cn(Styles['popup-content'], CommonStyles['button-list'])}>
            {data?.importScList?.map((item: TOffers.TImportOffersScList, index: number) => (
              <button key={index} onClick={() => handleConfirm(item)}>
                <p className={CommonStyles.type}>{item?.offeringsGbnStr}</p>
                <p className={CommonStyles.address}>{item?.label}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default LegacyItem;
