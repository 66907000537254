import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { ImodalRedux } from '<modal>';

const initialState: ImodalRedux = {
  modalType: '',
  multiModalType: '',
  data: {},
  multiData: {},
  isOpen: false,
  isMultiple: false,
  isConfirm: false,
  isCancel: false,
  action: () => {},
  multiAction: () => {},
  infoMe: '',
};

/**
 * @description modal
 * @Class
 * @category redux
 * @subcategory slice
 */
export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, actions) => {
      const { modalType, isOpen, data, action, infoMe } = actions.payload;
      state.modalType = modalType;
      state.data = data;
      // if (modalType === 'bottomSheet_feed_setting') {
      state.isOpen = isOpen !== undefined ? isOpen : true;
      // } else {
      //   state.isOpen = isOpen; // true
      // }
      // state.isOpen = isOpen; // true
      state.infoMe = infoMe;
      state.action = action;
    },
    openMultiModal: (state, actions) => {
      console.log(state.isMultiple);
      console.log(actions, 'call action');
      const { multiModalType, multiData, action, multiAction } = actions.payload;
      state.multiModalType = multiModalType;
      state.multiData = multiData;
      state.isMultiple = true;
      state.action = action;
      state.multiAction = multiAction;
    },
    closeMultiModal: (state, actions) => {
      const { multiModalType } = actions.payload;
      state.multiModalType = multiModalType;
      state.multiData = {};
      state.isMultiple = false;
    },
    closeModal: (state, actions) => {
      const { modalType } = actions.payload;
      state.modalType = modalType;
      state.data = {};
      state.isOpen = false;
      state.isConfirm = false;
      state.isCancel = false;
    },
    confirmModal: (state, actions) => {
      const { modalType, data, action } = actions.payload;
      state.modalType = modalType;
      state.data = {};
      state.action = action;
      state.isOpen = false;
      state.isConfirm = true;
      state.isCancel = false;
    },
    cancelModal: (state, actions) => {
      const { modalType, action } = actions.payload;
      state.modalType = modalType;
      state.data = {};
      state.action = action;
      state.isOpen = false;
      state.isConfirm = false;
      state.isCancel = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { openModal, closeModal, confirmModal, openMultiModal, closeMultiModal } = modalSlice.actions;

export default modalSlice;
