import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import cn from 'classnames';
import styled, { keyframes, css } from 'styled-components';

/**
 * @description Toast - redux에서 받아온 값으로 toast 셋팅
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @returns {JSX.Element}
 */
const Toast = () => {
  const toastMessage = useSelector((state: RootState) => state.toast);
  const { content = '토스트 메세지 입니다.' || <></>, type = 'message', duration = 2000 } = toastMessage;

  // 메세지 타입에 따른 css 개별 적용
  const parseClassName =
    type === 'close' || type === 'action'
      ? cn(CommonStyles['toast-popup'], ResetStyles['d-flex'], CommonStyles['ja-b'])
      : type === 'message_left'
      ? cn(CommonStyles['toast-popup'], ResetStyles['ta-l'])
      : type === 'message_right'
      ? cn(CommonStyles['toast-popup'], ResetStyles['ta-r'])
      : cn(CommonStyles['toast-popup'], ResetStyles['ta-c']);

  return (
    <StyledToast
      id={toastMessage.toastId}
      $messageType={toastMessage.type}
      $isOpen={toastMessage.isOpen}
      $duration={duration}
      className={parseClassName}
      style={{ zIndex: 99999999, wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-line' }}
    >
      {content}
    </StyledToast>
  );
};

export default Toast;

const fadeInOut = keyframes`
0% {
  transform: translateX(-50%) scale(1);
  opacity: 0;
  backdrop-filter: blur(0px);
}
30% {
  transform: translateX(-50%) scale(1);
  opacity: 1;
  backdrop-filter: blur(2px);
}
70% {
  transform: translateX(-50%) scale(1);
  opacity: 1;
  backdrop-filter: blur(2px);
}
100% {
  transform: translateX(-50%) scale(1);
  opacity: 0;
  backdrop-filter: blur(0px);
}
`;

const fadeIn = keyframes`
0% {
  transform: translateX(-50%) scale(1);
  opacity: 0;
  backdrop-filter: blur(0px);
}
30% {
  transform: translateX(-50%) scale(1);
  opacity: 1;
  backdrop-filter: blur(2px);
}
`;

const StyledToast = styled.div<{
  $duration: number;
  $isOpen: boolean;
  $messageType: string;
}>`
  ${({ $isOpen, $duration, $messageType }) =>
    $isOpen && ($messageType === 'message' || $messageType === 'message_left' || $messageType === 'message_right')
      ? css`
          animation: ${fadeInOut} ${$duration + 1000}ms ease-in-out both;
        `
      : $isOpen && $messageType !== 'message'
      ? css`
          animation: ${fadeIn} ${$duration}ms ease-in-out both;
        `
      : css`
          animation: none;
          display: none;
        `}
`;
