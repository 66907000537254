import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { IMixedKeyValue } from '<modal>';

export interface GroupMemberInterface {
  name?: string;
  nickname?: string;
  grade?: string;
  phone?: string;
  total_comment_count: number | string;
  total_like_count: number | string;
  total_post_count: number | string;
  offering_complete_count: number | string;
  offering_count: number | string;
  profile_photo_path: string;
  point_level: number | string;
  company: IMixedKeyValue | null | undefined;
  verify: IMixedKeyValue | null | undefined;
}

const initialState: GroupMemberInterface = {
  name: '',
  nickname: '',
  grade: '',
  phone: '',
  total_comment_count: 0, // 댓글
  total_like_count: 0, // 공감
  total_post_count: 0, // 글쓰기
  offering_complete_count: 0, // 거래완료
  offering_count: 0, // 공유
  profile_photo_path: '',
  point_level: '',
  company: null,
  verify: null,
};

/**
 * @description group member
 * @Class
 * @category redux
 * @subcategory slice
 */
const groupMemberSlice = createSlice({
  name: 'groupmember',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.name = action.payload.name;
      state.nickname = action.payload.nickname;
      state.grade = action.payload.grade;
      state.phone = action.payload.phone;
      state.total_comment_count = action.payload.total_comment_count;
      state.total_like_count = action.payload.total_like_count;
      state.total_post_count = action.payload.total_post_count;
      state.offering_complete_count = action.payload.offering_complete_count;
      state.offering_count = action.payload.offering_count;
      state.profile_photo_path = action.payload.profile_photo_path;
      state.point_level = action.payload.point_level;
      state.company = action.payload.company;
      state.verify = action.payload.verify;
    },
    initInfo: (state, action) => {
      state = { ...action.payload };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setInfo, initInfo } = groupMemberSlice.actions;

export default groupMemberSlice;
