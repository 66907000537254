import moment from 'moment';
import _ from 'lodash';
import { formatDate } from './date';
import { REType, adStatusGbn } from 'configs/configs';
/**
 * @description 로컬스토리지에 저장 & 불러오기
 * @todo locStore(key): 해당 키의 값이 리턴됨
 * @todo locStore(key, value): 해당 키에 값이 저장됨
 * @Class
 * @category utils
 * @param {string} key
 * @param {object} value
 */
export const locStore = (key: string, value?: object | string) => {
  if (value) {
    localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
    return true;
  }
  const val = localStorage.getItem(key);
  if (val !== null) {
    try {
      return JSON.parse(val);
    } catch (e) {
      if (val.indexOf('object Object') > -1) {
        localStorage.removeItem(key);
        return null;
      }
      return val;
    }
  }
  return null;
};

/**
 * @description 로컬스토리지에 저장된 값 삭제
 * @Class
 * @category utils
 * @param {string} key
 */
export const rmLocStore = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    /* empty */
  }
};

/**
 * @description 0을 pad
 * @Class
 * @category utils
 * @param {string} string 문자열
 * @param {number} pow length
 * @param {string} pad 채우는 pad 문자열
 * @returns {string} 반환된 pad 문자열
 */
export function zeroPad(string: number | string = '', pow: number = 0, pad: string = '0'): string {
  let result = String(string);
  const padString = String(pad);

  for (let i = pow - result.length; i > 0; i--) {
    result = padString + result;
  }

  return result;
}

/**
 * @description 파일이름 가져오기
 * @Class
 * @category utils
 * @param {string} orginName 업로드할 파일명
 * @returns {string} 파일 확장자
 */
export const getFileName = (orginName: string): string => {
  const _lastDot = orginName.lastIndexOf('.');
  return orginName.substring(0, _lastDot).toLowerCase();
};

/**
 * @description 파일확장자 가져오기
 * @Class
 * @category utils
 * @param {string} orginName 업로드할 파일명
 * @returns {string} 파일 확장자
 */
export const getFileExtentions = (orginName: string): string => {
  const lastIndex = orginName.lastIndexOf('.');
  if (lastIndex < 0) {
    return '';
  }
  return orginName.substring(lastIndex + 1).toLowerCase();
};

/**
 * @description 파일 확장자체크
 * @Class
 * @category utils
 * @param {string} name 파일 full name
 * @returns {boolean}
 */
export const fileExtensionValidFunc = (name: string, type?: string): boolean => {
  const ALLOW_EXTENSION =
    type === 'profile' ? 'jpg,jpeg,png,gif,svg' : 'jpg,jpeg,png,gif,svg,pdf,dox,docx,hwp,xlsx,xls';
  const extension = getFileExtentions(name);
  console.log('extension===>', extension);
  if (!(ALLOW_EXTENSION.indexOf(extension) > -1) || extension === '') {
    return false;
  }
  return true;
};

export function getTimeDifference(timeString: string, diffTime?: Date) {
  const currentTime = diffTime || new Date();
  const inputTime = new Date(timeString);
  const diffInSeconds = Math.floor((currentTime.getTime() - inputTime.getTime()) / 1000);

  const rtf = new Intl.RelativeTimeFormat('ko-KR', { numeric: 'auto' });

  if (diffInSeconds < 60) {
    // return rtf.format(-diffInSeconds, 'second');
    return '지금 막';
  }
  if (diffInSeconds < 3600) {
    return rtf.format(-Math.floor(diffInSeconds / 60), 'minute');
  }
  if (diffInSeconds < 86400) {
    return rtf.format(-Math.floor(diffInSeconds / 3600), 'hour');
  }
  if (diffInSeconds < 604800) {
    return rtf.format(-Math.floor(diffInSeconds / 86400), 'day');
  }
  return inputTime.toLocaleString('ko-KR', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

export const checkBizNumber = (number: string) => {
  const numberMap = number
    .replace(/-/gi, '')
    .split('')
    .map((d) => {
      return parseInt(d, 10);
    });

  if (numberMap.length === 10) {
    const keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
    let chk = 0;

    keyArr.forEach((d, i) => {
      chk += d * numberMap[i];
    });

    chk += parseInt(String((keyArr[8] * numberMap[8]) / 10), 10);
    // console.log(chk);
    return Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10;
  }

  return false;
};

/**
 * @description 숫자 단위 변경
 * @Class
 * @category utils
 * @param {number} num 변경할 단위 숫자
 * @returns {boolean}
 */
export const numTohan = (num: number | string) => {
  if (_.isNaN(num)) return '-';
  num = `${parseInt(`${num}`.replace(/[^0-9]/g, ''), 10)}`;
  if (num === '0') return '영';
  const number = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const unit = ['', '만', '억', '조'];
  const smallUnit = ['천', '백', '십', ''];
  const result = [];
  const unitCnt = Math.ceil(num.length / 4);
  num = num.padStart(unitCnt * 4, '0');
  const regexp = /[\w\W]{4}/g;
  const array = num.match(regexp);
  for (let i = array!.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++) {
    const hanValue = _makeHan(array![i]);
    if (hanValue === '') continue;
    result.unshift(hanValue + unit[unitCnt]);
  }

  function _makeHan(text: string) {
    let str = '';
    for (let i = 0; i < text.length; i++) {
      const num2: any = text[i];
      if (num2 === '0') continue;
      str += number[num2] + smallUnit[i];
    }
    return str;
  }
  return result.join('');
};

/**
 * @description ~분전 텍스트 표기
 * @Class
 * @category utils
 * @param {Date} currTime 현재 시간
 * @param {Date} diffTime 차이나는 시간
 * @returns {boolean}
 */
export const timeDisplay = (currTime?: Date, diffTime?: Date | string) => {
  let dispTime;
  if (moment.duration(moment(currTime).diff(moment(diffTime))).asDays() < 1) {
    if (moment.duration(moment(currTime).diff(moment(diffTime))).asHours() < 1) {
      if (moment.duration(moment(currTime).diff(moment(diffTime))).asSeconds() < 60) {
        dispTime = `지금`;
      } else {
        dispTime = `${moment
          .duration(moment(currTime).diff(moment(diffTime)))
          .asMinutes()
          .toFixed()}분 전`;
      }
    } else {
      dispTime = `${moment
        .duration(moment(currTime).diff(moment(diffTime)))
        .asHours()
        .toFixed()}시간 전`;
    }
  } else {
    if (moment.duration(moment(currTime).diff(moment(diffTime))).asDays() <= 15) {
      dispTime = `${moment
        .duration(moment(currTime).diff(moment(diffTime)))
        .asDays()
        .toFixed()}일 전`;
    } else {
      dispTime = `${formatDate(diffTime)}`;
    }
  }
  return dispTime;
};

export function rmComma(s: string | number | null): string {
  const rtn = String(s).replace(/,/gi, '');
  if (_.isNaN(Number(rtn))) {
    if (rtn === '-' || rtn === '0') {
      return rtn;
    }
    return '0';
  }
  return rtn;
}

/**
 * @description 면적을 평으로 변경
 * @Class
 * @category utils
 * @param {number} num 면적
 * @param {string} type
 * @returns {string} 평
 */
export const squareToPyeong = (num: number, type?: string): number => {
  const value: number = num * 0.3025;
  return Number(value.toFixed(2));
};

/**
 * @description 면적을 평으로, 평을 면적으로 변경
 * @Class
 * @category utils
 * @param {number} num 면적
 * @param {string} type 'toPyeong', 'toSquare'
 * @returns {number} 면적, 평
 */
export const convertArea = (num: number, type?: string): number => {
  let value: number;
  if (type === 'toPyeong') {
    value = num * 0.3025;
  } else {
    value = num * 3.3058;
  }
  return Number(value.toFixed(2));
};

/**
 * @description 매물구분에 따른 main_cd가져오기
 * @Class
 * @category utils
 * @param {string} offegGbn 매물구분
 * @returns {string} main_cd
 */
export const getOfferGroup = (offegGbn: string): string => {
  const _mainCD = REType.map((item) => item.offer_types)
    .flat()
    .find((item) => item.sub_cd === offegGbn)?.main_cd;
  const _group = _mainCD === 'A' || _mainCD === 'B' ? 'apartment_group' : 'nonapartment_group';
  return _group;
};

/**
 * @description 매물코드에 따른 매물종류명 가져오기
 * @Class
 * @category utils
 * @param {string} offegGbn
 */
export const getOfferGbnStr = (offegGbn: string): string => {
  return (
    REType.map((item) => item.offer_types)
      .flat()
      .find((item) => item.sub_cd === offegGbn)?.sub_nm || ''
  );
};

/**
 * @description 매물코드에 따른 매물종류명 가져오기
 * @Class
 * @category utils
 * @param {string} offegGbn
 */
export const getOfferGbnCode = (offegGbn: string): string => {
  return (
    REType.map((item) => item.offer_types)
      .flat()
      .find((item) => item.sub_cd === offegGbn)?.main_cd || ''
  );
};

export const replaceURLWithAtag = (matched: string) => {
  let withProtocol = matched;
  let newStr: string;
  if ((matched && withProtocol.startsWith('www')) || withProtocol.startsWith('WWW')) {
    withProtocol = `http://${matched}`;
    newStr = `<a href="${withProtocol}" target="_blank" style="text-decoration: underline">
      ${matched}
    </a>`;
    return newStr;
  }
  if (matched && (withProtocol.startsWith('http') || withProtocol.startsWith('https'))) {
    newStr = `<a href="${matched}" target="_blank" style="text-decoration: underline">
      ${matched}
    </a>`;
    return newStr;
  }
  return matched;
};

/**
 * @description 배열에 추가할 요소들 중에서 기존에 존재하지 않는 요소들을 반환
 * @Class
 * @category utils
 * @param {any} currentItems currentItems 기존 배열
 * @param {string} key 중복 체크 key
 * @param {any} items 추가하려는 배열
 */
export const getUniqueArray = (
  currentItems: { [key: string]: any }[],
  key: string,
  items: { [key: string]: any }[],
) => {
  const currentIds = new Set(currentItems.map((item) => item[key]));
  const uniqueItems = items.filter((item) => !currentIds.has(item[key]));

  return uniqueItems;
};

/**
 * @description custom webview인지 판단
 * @Class
 * @category utils
 * @returns {boolean}
 */
export const isCheckWebview = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  let isWebView = false;
  if (userAgent.includes('aipartnernet')) {
    return (isWebView = true);
  }
  return isWebView;
};

// 제곱미터를 평수로 변환
export const convertSquareMetersToPyung = (squareMeters: number): string => {
  const pyung = squareMeters * 0.3025;
  const valueForRounding = Math.floor(pyung * 1000);
  const roundedValue = Math.round(valueForRounding / 10);
  const result = (roundedValue / 100).toFixed(2);
  return result === '0.00' ? '0' : result;
};

export const convertPyungToSquareMeters = (pyungValue: number): string => {
  const pyung = pyungValue * 3.3058;
  const valueForRounding = Math.floor(pyung * 1000);
  const roundedValue = Math.round(valueForRounding / 10);
  const result = (roundedValue / 100).toFixed(2);
  return result === '0.00' ? '0' : result;
};

/**
 * @description 매물상태에 따른 상태명 가져오기
 * @Class
 * @category utils
 * @param {string} statusGbn
 */
export const getStatusGbnStr = (statusGbn: string | undefined): string => {
  return adStatusGbn.find((item) => item.cd === statusGbn)?.name || '';
};

export const checkNumber = (e: { key: string | number }) => {
  if (Number(e.key) >= 0 && Number(e.key) <= 9) {
    return true;
  }

  return false;
};
