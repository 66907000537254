import { useEffect, useCallback, useRef, useState, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { useAppDispatchThunk, useAppSelector, RootState } from 'redux/store';
import { getBookmarksForOfferings, deleteBookmarkForOffering, resetFeed } from 'api/feed';
import { API_PAGE_SIZE, TOAST_MESSAGE_TYPE, OFFERS_MESSAGE } from 'configs/configs';
import { getDateDiffDays } from 'utils/date';
import { convertSquareMetersToPyung } from 'utils/common';
import { FeedErrorType, AreaType } from '../../@types/feed';
import styled from 'styled-components';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { openModal } from 'redux/slices/modal';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';
import ListLoader from 'components/common/Loader/ListLoader';
import { setRefreshFn } from 'redux/slices/refresh';

const Target = styled.div`
  height: 10px;
`;

/**
 * @description 내피드 공유매물 목록 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @returns {JSX.Element}
 */
const OfferingList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatchThunk();

  // 검색어 input 요소 ref
  const inputRef = useRef<HTMLInputElement>(null);
  // 무한로딩 교차 기준 요소 ref
  const scrollAnchorRef = useRef<HTMLDivElement | null>(null);
  // api 요청 진행 상태, true: 진행 중, false : 진행완료
  const { loading } = useAppSelector((state: RootState) => state.feed);
  // 공유매물(북마크) 데이터
  const offers = useAppSelector((state: RootState) => state.feed.offer_bookmarks);

  // 검색어
  const [keyword, setKeyword] = useState<string>('');
  // Toast 메세지창 Open 여부
  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);
  const { setToastMessage } = useToast();

  // 공유매물 목록 데이터 로딩
  const loadPage = useCallback(
    async (page: number, keyword: string) => {
      dispatch(getBookmarksForOfferings({ page_size: API_PAGE_SIZE, page: page, keyword: keyword }));
    },
    [dispatch],
  );

  /**
   * @description 검색어 input change 햄들러
   * @returns {void}
   */
  const changeKeyword = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  }, 300);

  /**
   * @description 상세페이지 이동
   * @returns {void}
   */
  const goToView = (id: number) => (event: MouseEvent<HTMLDivElement>) => {
    navigate(`/sharedetail/${id}`);
  };

  /**
   * @description 북마크 삭제
   * @param {number} id 북마크 id
   * @returns {void}
   */
  const deleteBookmark = async (id: number) => {
    try {
      await dispatch(deleteBookmarkForOffering(id)).unwrap();

      setToastMessage({
        duration: 2000,
        content: TOAST_MESSAGE_TYPE.BOOKMARK_REMOVE,
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        setToastMessage({
          duration: 2000,
          content: TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      } else {
        //console.log('delete error:', feedError.message);
      }
    }
  };

  // state 형식이 올바르지 않을 경우 reset 처리
  useEffect(() => {
    if (offers === undefined) {
      dispatch(resetFeed());
    }
  }, [offers]);

  useEffect(() => {
    // 공유매물 북마크 1 page 로딩
    loadPage(1, keyword);

    // 새로 고침 등록
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          //console.log('refresh 공동매물 북마크 keyword', keyword);
          loadPage(1, keyword);
        },
      }),
    );
  }, [loadPage, keyword]);

  // 무한로딩 구현
  useEffect(() => {
    if (loading || !offers || !offers?.items || offers.items.length == 0 || !scrollAnchorRef.current) return;

    //console.log('무한로딩(공동매물 북마크) offers', offers);
    //console.log('무한로딩(공동매물 북마크) keyword', keyword);
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (offers.current_page && offers.total_page && offers.current_page < offers.total_page) {
          loadPage(offers.current_page + 1, keyword);
        }
      }
    });

    observer.observe(scrollAnchorRef.current);

    return () => {
      observer.disconnect();
    };
  }, [offers?.items, loading]);

  return (
    <>
      {/* 검색어 입력창 */}
      <div className={CommonStyles['region-filter']}>
        <div className={CommonStyles['search-box']}>
          <div
            className={cn(
              CommonStyles['input-area'],
              CommonStyles['search-input'],
              keyword && CommonStyles['clear-has'],
            )}
          >
            <input type="text" placeholder={OFFERS_MESSAGE.KEYWORD_LABEL} ref={inputRef} onChange={changeKeyword} />
            <button
              className={keyword && CommonStyles.delete}
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                  setKeyword('');
                }
              }}
            ></button>
          </div>
        </div>
      </div>
      {/* 총 건수 */}
      <div className={CommonStyles['card-count-txt']}>
        총 <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>{offers?.total}</b>개의 매물이 있습니다.
      </div>
      {/* 공유매물 북마크 목록 */}
      <div className={cn(CommonStyles['sale-card-div'], offers?.items.length > 0 && CommonStyles['mt16'])}>
        {(!offers || offers.items.length == 0) && (
          <div className={CommonStyles['no-data']}>
            <pre>{keyword ? OFFERS_MESSAGE.OFFER_SEARCH_EMPTY : OFFERS_MESSAGE.OFFER_EMPTY}</pre>
          </div>
        )}

        {offers && offers.loading === true && <ListLoader />}
        {offers &&
          offers.items?.map((offer, index) => {
            // 날짜
            let offerDateInfo =
              offer.created_at != offer.updated_at
                ? `수정일 ${offer.updated_at.replace(/-/g, '.').substring(2, 10)}`
                : `최초 작성일 ${offer.created_at.replace(/-/g, '.').substring(2, 10)}`;

            if (offer.my_offer === 1) {
              const diffShow = getDateDiffDays(new Date(), new Date(offer.expire_date));
              if (offer.expire_date && diffShow > 0 && diffShow <= 7) {
                offerDateInfo += ` (D-${diffShow})`;
              }
            }

            // 면적
            let areaInfo = '';
            const emptyArea = offer.area_info.filter((item: AreaType) => !item.value);
            if (offer.area_info.length > 0 && emptyArea.length != offer.area_info.length) {
              offer.area_info.forEach((item: AreaType, index: number) => {
                areaInfo +=
                  (index != 0 ? ' ' : '') +
                  `[${item.name}]${item.value ? convertSquareMetersToPyung(item.value) : '-'}${item.value ? '평' : ''}`;
              });
            }

            // 방/욕실
            let roomBath = '';
            const roomBathArr = offer.room_bath.split('/');
            if (roomBathArr.length == 2) {
              roomBath = `방${roomBathArr[0]}욕실${roomBathArr[1]}`;
            }

            // 가격 ('/' 로 구분)
            let priceInfo = '';
            if (offer.price_info.price) {
              const priceArr = Object.keys(offer.price_info.price).map((item) =>
                offer.price_info.price[item] !== null ? offer.price_info.price[item].toLocaleString() : 0,
              );
              priceInfo = priceArr.join('/');
            }

            return (
              <div key={index} className={CommonStyles['sale-card']} onClick={goToView(offer.id)}>
                {/* 내 매물 / 날짜정보 / 업소명 */}
                <div className={CommonStyles['state-summary']}>
                  <div className={CommonStyles['divide-box']}>
                    {offer.my_offer === 1 && (
                      <p className={cn(CommonStyles['co-dorg'], CommonStyles['has-icon'])}>
                        <i className={CommonStyles['icon-mine']}></i>내 매물
                      </p>
                    )}
                    <p className={CommonStyles['date']}>{offerDateInfo}</p>
                    {!offer.my_offer && <p className={CommonStyles['limit-line1']}>{offer.company_name}</p>}
                  </div>
                </div>
                {/* 매물종류 */}
                <div className={CommonStyles.type}>[{offer.offer_type_name}]</div>
                {/* 매물명 */}
                <div className={CommonStyles.address}>{offer.title_mobile}</div>
                {/* 면적 / 방.욕실 / 층 */}
                <div className={CommonStyles.information}>
                  {areaInfo && <p>{areaInfo}</p>}
                  {roomBath && <p>{roomBath}</p>}
                  {offer.floor2 != '-' && <p>{offer.floor2}층</p>}
                </div>
                {/* 가격 */}
                <div className={CommonStyles.price}>
                  <b className={CommonStyles['co-bl']}>{offer.deal_type_name}</b>
                  {priceInfo && <>{priceInfo}만원</>}
                </div>
                {/* 매물특징 */}
                {offer.memo && (
                  <div className={cn(CommonStyles['description'], CommonStyles['limit-line1'])}>{offer.memo}</div>
                )}
                {/* 수정요청 */}
                {offer.edit_req === 1 && (
                  <p className={CommonStyles['warning-txt']}>
                    <i className={CommonStyles['icon-warning-red']}></i>
                    {OFFERS_MESSAGE.OFFER_EDIT_REQ}
                  </p>
                )}
                <div className={CommonStyles['function-div']}>
                  {/* 댓글수 */}
                  <button>
                    <i className={CommonStyles['icon-comment']}></i>
                    {offer.comments_count}
                  </button>
                  {/* 북마크 해제 버튼 */}
                  <button
                    className={cn(CommonStyles.bookmark, CommonStyles.on)}
                    onClick={(event) => {
                      event.stopPropagation();

                      const action = () => deleteBookmark(offer.bookmark_id);
                      dispatch(openModal({ modalType: 'confirm_bookmark_comment', action }));
                    }}
                  >
                    <i className={CommonStyles['icon-bookmark']}></i>
                  </button>
                </div>
              </div>
            );
          })}

        <Target ref={scrollAnchorRef} />
        {/* Toast 팝업 */}
        {isOpen && <Toast />}
      </div>
    </>
  );
};

export default OfferingList;
