import { IJuridical, IJuridicalMember } from '<broker>';
import BrokerListMember from './BrokerListMember';

import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';

const BrokerListCompany = (props: {
  juridical: IJuridical;
  companySelect: string;
  handleSelectJuridical: (_regNo: string) => void;
  handleSelectMember: (_brkrNm: string) => void;
}) => {
  const { lowDongCode, regNo, companyName, address, ceo, regDate, status, startDate, endDate, tell, member } =
    props.juridical;
  const { companySelect, handleSelectJuridical, handleSelectMember } = props;

  return (
    <>
      <li
        className={cn(CommonStyles['line-box'], companySelect === regNo ? CommonStyles.selected : '')}
        onClick={() => handleSelectJuridical(regNo)}
      >
        <p className={CommonStyles.name}>{companyName}</p>
        <p className={CommonStyles.address}>{address}</p>
        <p className={CommonStyles.number}>개설번호 : {regNo}</p>

        {member && (
          <>
            <div className={CommonStyles['mem-list']} style={{ display: 'block' }}>
              <div className={CommonStyles['sel-table']}>
                <div className={CommonStyles.row}>
                  <div className={CommonStyles['col-th']}>구분(직위)</div>
                  <div className={CommonStyles['col-th']}>이름</div>
                  <div className={CommonStyles['col-th']}>선택</div>
                </div>
                <div className={cn(CommonStyles['list-wrap'], CommonStyles['custom-scroll'])}>
                  {member?.map((juridicalMember: IJuridicalMember, index) => (
                    <BrokerListMember
                      key={index}
                      index={index}
                      member={juridicalMember}
                      handleSelectMember={handleSelectMember}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </li>
    </>
  );
};

export default BrokerListCompany;
