import { IMixedKeyValue } from '<modal>';
// common
export const initAddr: IMixedKeyValue = {
  sidoAddr: {},
  guGunAddr: {},
  eupMyeonDongAddr: {},
  liAddr: {},
  complexAddr: {},
  dongAddr: {},
  square: {},
};

export const initAddressList = {
  sido: [],
  guGun: [],
  eupMyeonDong: [],
  li: [],
  redevelop: [],
  complex: [],
  dong: [],
  squareList: [],
};

export const initRelation = {
  name: '',
  code: '',
};

export const initSquareSelect = {
  complex_cd: '',
  sqr_cd: '',
  square_type: '',
  sup_sqr: '',
  exc_sqr: '',
  con_sqr: '',
  dis_sqr: '',
  disSqr: '',
};

// 공유매물 상세
export const initCoAgencyDetail = {
  id: null,
  offerings_gbn: '',
  editor_name: '',
  offer_type_name: '',
  deal_type_name: '',
  edit_req_date: '',
  complex_nm: '',
  dong_name: '',
  ho_name: '',
  ho_nm: '',
  addr: '',
  addr_gbn: '',
  house_no: '',
  detail_addr: '',
  move_data: '',
  room_cnt: null,
  bath_cnt: null,
  memo: '',
  detail_memo: '',
  created_at: '',
  updated_at: '',
  status: '',
  customer_name: '',
  owner_type: '',
  owner_type_name: '',
  phone_num: '',
  hidden_memo: '',
  company_name: '',
  status_share: '',
  tel_display: '',
  status_share_map: '',
  bookmark_count: null,
  comments_count: null,
  my_offer: false,
  my_seek: false,
  price_info: [],
  area_info: [],
  floor_info: [],
  shares: [],
  isFav: false,
  bookmark_id: null,
  price_history: [],
  improve: [],
  ceo: '',
  contact: '',
  corp_number: '',
  address: '',
  address_detail: '',
};

// CoAgencyRegister 공유매물등록.
export const initCoAgencyRegisterState = {
  id: '',
  areaCd: '',
  houseNo: '',
  offerings_main_gbn: '',
  offerings_gbn: '',
  addr: '',
  sidoAddr: initAddr,
  guGunAddr: initAddr,
  eupMyeonDongAddr: initAddr,
  liAddr: initAddr,
  redevelopAddr: initAddr,
  complexAddr: initAddr,
  dongAddr: initAddr,
  hoAddr: null,
  dong_hide: 'N',
  addrGbn: 'N',
  forSaleRight: '', // 분양권 (A: 일반분양권 , B: 조합원분양권)
  streetStart: '',
  streetEnd: '',
  detailUse: 'Y', // 상세주소 사용 'Y',  없음 'N'
  detailAddr: '',
  offerGbn: 'S',
  price_info: [],
  sellPrc: null, // 매매가
  leasePrc: null, // 전세가
  depositPrc: null, // 보증금
  monthlyPrc: null, // 월세
  salePrc: null, // 분양권 - 분양가
  premiumPrc: null, // 분양권 - 프리미엄가
  optionPrc: null, // 분양권 - 분양옵션가
  squareSelect: initSquareSelect, // 면적선택 (공동주택)
  supArea: null, // 공급면적
  conArea: null, // 계약면적
  excArea: null, // 전용면적
  lotArea: null, // 대지면적
  totArea: null, // 연면적
  bulArea: null, // 건축면적
  applyFloor: null, // 해당층
  underFloor: null, // 지하층
  groundFloor: null, // 지상층
  totFloor: null, // 총층
  roomCnt: null,
  bathCnt: null,
  moveGbn: 'A', // 입주가능일 타입 - A:즉시입주,  B:초순,  C:중순,  D:하순, S: 날짜 지정
  isMoveChg: 'N', // 입주가능일 협의여부
  moveYmd: new Date(),
  memo: '', // 매물특징
  detailMemo: '', // 매물설명
  statusDeal: 'normal', // 상태, normal : 정상, hold : 보류, complete : 거래완료
  customerName: '', // 비공개정보 고객명
  ownerType: initRelation, // 비공개정보 관계  01 ~ 99
  phoneNum: '',
  hiddenMemo: '',
  status_share: 'Y', // 공유여부, Y: 공유, N: 공유안함  // 공유함 default
  shares: [], // 모임 id, type이 'G' 경우 설정
  telDisplay: '', // 연락처 노출, A : 모두, R : 전화번호, C : 휴대폰번호
  statusShareMap: 'Y', // 지도노출여부, Y: 지도노출, N: 지도노출안함
  show_days: null,
  isPriceChange: false,
};
export const initImportState = {
  targetSourceName: '',
  areaCd: '',
  acMetroNm: '',
  acGuNm: '',
  acDongNm: '',
  acLiNm: '',
  hoNm: '',
  adOfferingsSeq: 0,
  offeringsSeq: 0,
  offeringsGbn: '',
  offerGbn: 'S',
  offerGbnStr: '',
  imgList: [],
  addrStr: '',
  memo: '',
  detailMemo: '',
  hiddenMemo: '',
  supSqrStr: '',
  price: '',
  complexCd: 0,
  sqrCd: 0,
  supSqr: 0,
  excSqr: 0,
  conSqr: 0,
  buildSqr: 0,
  etcSqr: 0,
  cusOwnerGbn: '',
  sellPrc: null,
  leasePrc: null,
  depositPrc: null,
  monthlyPrc: null,
  parcelPrc: null,
  premiumPrc: null,
  parcelOptionPrc: null,
  parcelGbn: null,
  floor: null,
  totFloor: null,
  underFloor: null,
  groundFloor: null,
  roomCnt: null,
  bathCnt: null,
  isMoveChg: 'N',
  moveGbn: '',
  moveYmd: '',
  ntDisplayDate: null,
  ntExpireDate: null,
  detailAddr: null,
  houseNo: null,
  addrGbn: 'S',
  isRebuild: 'N',
};
