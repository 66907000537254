/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-useless-escape */
import React, { useState, useRef, useEffect } from 'react';
import styled, { StyleSheetManager } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import dompurify from 'dompurify';

// utils
import { replaceURLWithAtag } from 'utils/common';
import { IMixedKeyValue } from '<modal>';

// styles
import CommonStyles from 'styles/common.module.css';

// webviews
import { onView } from 'utils';

const ContentContainer = styled.div<{ maxLines: number; expanded: boolean }>`
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  line-height: 22px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  ${(props) => {
    return props.expanded
      ? {
          'max-height': 'none',
        }
      : {
          '-webkit-box-orient': 'vertical',
          'max-height': `${props.maxLines * 22}px`,
        };
  }}
`;

interface ExpandableTextProps {
  id?: string;
  text?: string;
  post?: IMixedKeyValue;
  maxLines: number;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  main?: boolean | undefined; // 메인 view 여부, true: 메인, false: 서브
}

/**
 * @description 포스트의 content rendering F.C
 * @Class
 * @category Pages
 * @subcategory 전체게시판
 * @component
 * @param {ExpandableTextProps} props
 * @param {string} props.id (optinal)
 * @param {string} props.text (optinal)
 * @param {object} props.post (optinal)
 * @param {number} props.maxLines
 * @param {function} props.onClick (optinal)
 * @param {boolean | undefined} props.main (optinal)
 * @returns {JSX.Element}
 */
const PostContent = (props: ExpandableTextProps): JSX.Element => {
  const { id, text, post, maxLines, onClick, main = false } = props;
  const sanitizer = dompurify.sanitize;
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [currentLineCount, setCurrentLineCount] = useState(0);
  const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  const navigate = useNavigate();

  useEffect(() => {
    if (contentRef.current) {
      const computedStyle = window.getComputedStyle(contentRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const containerHeight = parseFloat(computedStyle.height);
      const newLineCount = Math.floor(containerHeight / lineHeight);
      setCurrentLineCount(newLineCount);
    }
  }, [text]);

  const movePage = (post: IMixedKeyValue) => {
    const link = post?.group_id ? `/group-postdetail/${post?.group_id}/${post?.id}` : `/postdetail/${post?.id}`;
    if (!post?.id) return;
    if (main) {
      if (import.meta.env.MODE === 'localdev') {
        navigate(link);
      } else {
        onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
      }
    } else {
      navigate(link);
    }
  };

  // console.log('props==>', props);

  return (
    <div key={id} id={id} className={CommonStyles['txt-section']} onClick={onClick ? onClick : undefined}>
      <StyleSheetManager shouldForwardProp={(prop) => !['expanded', 'maxLines'].includes(prop)}>
        <ContentContainer ref={contentRef} maxLines={maxLines} expanded={expanded}>
          {text?.split('\n').map((line, i) => {
            return (
              <React.Fragment key={i}>
                <span
                  onClick={(e: any) => {
                    const targetLink = e.target.closest('a');
                    if (targetLink) {
                      e.preventDefault();
                      e.stopPropagation();
                      movePage(post!);
                    } else {
                      movePage(post!);
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(line.replace(linkRegex, replaceURLWithAtag)).replace(/(?:\r\n|\r|\n)/g, '<br/>'),
                  }}
                  style={{
                    wordBreak: 'break-all',
                  }}
                />
                {linkRegex.test(line) ? '' : <br />}
              </React.Fragment>
            );
          })}
        </ContentContainer>
      </StyleSheetManager>
      {currentLineCount >= maxLines && !expanded && (
        <span className={CommonStyles['more-btn']} onClick={() => movePage(post!)}>
          더보기
        </span>
      )}
    </div>
  );
};

export default PostContent;
