import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { closeModal, closeMultiModal, openModal } from 'redux/slices/modal';
import { useEffect, useRef, useState } from 'react';
import { IMixedKeyValue } from '<modal>';
import { IAddressParams } from '<coagency>';
import * as Config from 'configs/configs';
import * as APIS from 'api/group';
import * as CAPIS from 'api/common';
import { cloneDeep } from 'lodash';
import network from 'utils/network';
import ScrollLoader from 'components/common/Loader/ScrollLoader';

const initArea = {
  addr: null,
  addr_dong: null,
  addr_dong_code: null,
  addr_dong_cd: null,
  addr_gu: null,
  addr_gu_code: null,
  addr_gu_cd: null,
  addr_li: null,
  addr_li_code: null,
  addr_li_cd: null,
  addr_si: null,
  addr_si_code: null,
  addr_si_cd: null,
  area_cd: null,
  area_name: null,
};

const initAreaSi = {
  addr: null,
  addr_dong: null,
  addr_dong_code: null,
  addr_dong_cd: null,
  addr_gu: null,
  addr_gu_code: null,
  addr_gu_cd: null,
  addr_li: null,
  addr_li_code: null,
  addr_li_cd: null,
  area_cd: null,
  area_name: null,
};

const initAreaGu = {
  addr: null,
  addr_dong: null,
  addr_dong_code: null,
  addr_dong_cd: null,
  addr_li: null,
  addr_li_code: null,
  addr_li_cd: null,
  area_cd: null,
  area_name: null,
};

const initAreaDong = {
  addr: null,
  addr_li: null,
  addr_li_code: null,
  addr_li_cd: null,
  area_cd: null,
  area_name: null,
};

/**
 * @description 구합니다 bottom sheet
 * @Class
 * @category Components
 * @subcategory 구합니다 sheet | modal
 * @component
 * @param {string} props.title sheet title
 * @returns {JSX.Element}
 */
const SharePropertyAreaSheet = ({ viewType = '' }: { viewType?: string }) => {
  const dispatch = useAppDispatch();
  const { multiAction, multiData, data, action } = useAppSelector((state: RootState) => state.modal);

  // 매물 유형
  const [type, setType] = useState('residential');
  const [typeList, setTypeList] = useState<IMixedKeyValue[]>([]);
  // 선택된 매물 유형
  const [selected, setSelected] = useState<IMixedKeyValue[]>([]);
  // 주거용 매물 유형
  const [residentialList, setResidentialList] = useState<IMixedKeyValue[]>([]);
  // 상업용 매물 유형
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  // 소재지 데이터
  const [areaData, setAreaData] = useState<IMixedKeyValue>({});
  // 소재지 목록
  const [addrList, setAddrList] = useState<IMixedKeyValue>([]);

  const [selectedType, change] = useState('');

  // 소재 현재 스텝 총 4개가 존재 1부터 시작F
  const [step, setStep] = useState<number>(1);

  const clickRef = useRef<boolean>(false);

  useEffect(() => {
    if (viewType === 'property_type') {
      const f = async () => {
        fetchTypeList();
      };

      f();
    }
    // 구합니다 매물 종류
    if (viewType === 'looking_for_type') {
      fetchLookingforList();
    }
  }, [viewType]);

  useEffect(() => {
    if (viewType === 'looking_for_type') {
      if (multiData?.type_info?.length > 0) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        setSelected([...multiData?.type_info]);
      }

      if (multiData?.type) {
        setType(multiData.type);
      }
    }
  }, [multiData]);

  useEffect(() => {
    if (data?.isArea) {
      setAreaData({
        addr: data.addr || null,
        addr_dong: data.addr_dong || null,
        addr_dong_code: data.addr_dong_code || null,
        addr_gu: data?.addr_gu || null,
        addr_gu_code: data?.addr_gu_code || null,
        addr_li: data?.addr_li || null,
        addr_li_code: data?.addr_li_code || null,
        addr_si: data?.addr_si || null,
        addr_si_code: data?.addr_si_code || null,
        area_cd: data?.area_cd || null,
        area_name: data?.area_name || null,
      });

      if (data.addr_li_code !== null) {
        // 읍/면/리로 조회
        getAddress('li', { dong_code: data.addr_dong_code });
        setStep(4);
      }

      if (data.addr_dong_code !== null && data.addr_li_code === null) {
        // 동으로 조회
        getAddress('guGun', { gu_code: data.addr_gu_code });
        setStep(3);
      }

      if (data.addr_gu_code !== null && data.addr_dong_code === null) {
        // 시군구로 조회
        getAddress('guGun', { si_code: data.addr_si_code });
        setStep(2);
      }

      if (data.addr_si_code !== null && data.addr_gu_code === null) {
        // 시군구로 조회
        getAddress('sido', { si_code: null });
        setStep(1);
      } else {
        getAddress('sido', { si_code: null });
      }
    }
  }, [data]);

  /**
   * @description 주소 api 호출
   * @param {string} type  호출 type sido, guGun, eupMyeonDong, li, dong
   * @param {object} params {si_code: string, gu_code: string, dong_code: string }
   * @returns {Promise<void>}
   */
  const getAddress = async (type: string, params: IAddressParams, index?: any): Promise<void> => {
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const resAddr = await network().coagency().postAddress(Config.API_URL.POST_ADDR_LIST, JSON.stringify(params));
      const { data, status } = resAddr;
      if (status === 200) {
        if (type === 'li' && data.length > 0) {
          if (index !== null || index !== undefined) {
            setAddrList([...data]);
          }
        } else {
          setAddrList([...data]);
        }
      }
    } catch (err) {
      console.error('err', err);
    } finally {
      clickRef.current = false;
    }
  };

  // 소재지 변경
  const handleClickCurrentStep = (step: number) => {
    if (step === 2 && areaData.addr_si_code === null) return;
    if (step === 3 && areaData.addr_gu_code === null) return;
    if (step === 4 && areaData.addr_dong_code === null) return;

    let copyData = cloneDeep(areaData);

    setStep(step);

    if (step === 1) {
      if (copyData.addr_si_code !== '') {
        copyData = { ...areaData, ...initArea };
      }
      // 시군구
      setAddrList([]);
      getAddress('sido', { si_code: null });
    }

    if (step === 2) {
      if (copyData.addr_gu_code !== '') {
        copyData = { ...areaData, ...initAreaSi };
      }
      // 시군구
      setAddrList([]);
      getAddress('guGun', { si_code: areaData.addr_si_code });
    }

    if (step === 3) {
      // 구군
      if (copyData.addr_dong_code !== '') {
        copyData = { ...areaData, ...initAreaGu };
      }
      setAddrList([]);
      getAddress('guGun', { gu_code: areaData.addr_gu_code });
    }

    if (step === 4) {
      if (copyData.addr_li_code !== '') {
        copyData = { ...areaData, ...initAreaDong };
      }
      // 읍면동
      setAddrList([]);
      getAddress('li', { dong_code: areaData.addr_dong_code });
    }

    setAreaData(copyData);
  };

  // 매물 유형 목록 조회
  const fetchTypeList = async () => {
    const res = await APIS.getOffersTypeList('/offers/type/list');
    if (res?.length > 0) {
      setTypeList([...res]);
    }
  };

  const fetchLookingforList = async () => {
    try {
      const res = await CAPIS.getSeekTypeList('/seeks/type/list');
      if (res.length > 0) {
        const resList = res.filter((item: IMixedKeyValue) => item.kind === 'A');
        const list = res.filter((item: IMixedKeyValue) => item.kind === 'B');

        if (resList.length > 0) {
          setResidentialList([...resList]);
        }

        if (list.length > 0) {
          setList([...list]);
        }
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const handleClickClose = () => {
    dispatch(closeModal({ isOpen: false }));
    dispatch(closeMultiModal({ isMultiple: false }));
  };

  const handleClickSeekType = (val: any) => {
    // 주거용
    let copyList: any = cloneDeep(selected);
    if (type === 'residential') {
      if (copyList.find((item: any) => item.code === val.code)) {
        copyList = copyList.filter((item: any) => item.code !== val.code);
      } else {
        if (selected.length >= 2) {
          handleClickModal();
          return;
        }
        copyList.push(val);
      }

      setSelected(copyList);
    }

    // 상업용
    if (type === 'commercial') {
      console.log(val, 'val');
      if (copyList.find((item: any) => item.code === val.code)) {
        copyList = copyList.filter((item: any) => item.code !== val.code);
      } else {
        if (selected.length >= 2) {
          handleClickModal();
          return;
        }
        copyList.push(val);
      }

      setSelected(copyList);
    }
  };

  // 매물 종류 2개이상 선택시 alert 모달
  const handleClickModal = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'alert_custom',
        data: {
          txt: '매물종류는 최대 2개까지 선택하실 수 있습니다.',
        },
      }),
    );
  };

  const handleClickType = (val: string) => {
    if (val === type) {
      setType('');
    } else {
      setType(val);
    }
    setSelected([]);
  };

  const handleClickSeletedArea = (data: IMixedKeyValue) => {
    if (clickRef.current) return;

    if (step === 1) {
      setAreaData({
        ...initArea,
        addr_si: data.name,
        addr_si_code: data.cd,
        addr_si_cd: data.code,
      });
      setStep(2);
      getAddress('sido', { si_code: data.cd });
    }

    if (step === 2) {
      setAreaData({
        ...initArea,
        addr_si: areaData.addr_si,
        addr_si_code: areaData.addr_si_code,
        addr_si_cd: areaData.addr_si_cd,
        addr_gu: data.name,
        addr_gu_code: data.cd,
        addr_gu_cd: data.code,
      });
      setStep(3);
      getAddress('guGun', { gu_code: data.cd });
    }

    if (step === 3) {
      setAreaData({
        ...initArea,
        addr_si: areaData.addr_si,
        addr_si_code: areaData.addr_si_code,
        addr_si_cd: areaData.addr_si_cd,
        addr_gu: areaData.addr_gu,
        addr_gu_code: areaData.addr_gu_code,
        addr_gu_cd: areaData.addr_gu_cd,
        addr_dong: data.name,
        addr_dong_code: data.cd,
        addr_dong_cd: data.code,
      });
      if (checkLastCharacter(data.name, '동') === false) {
        setStep(4);
        getAddress('eupMyeonDong', { dong_code: data.cd });
      }
    }

    if (step === 4) {
      setAreaData({
        ...initArea,
        addr_si: areaData.addr_si,
        addr_si_code: areaData.addr_si_code,
        addr_si_cd: areaData.addr_si_cd,
        addr_gu: areaData.addr_gu,
        addr_gu_code: areaData.addr_gu_code,
        addr_gu_cd: areaData.addr_gu_cd,
        addr_dong: areaData.addr_dong,
        addr_dong_code: areaData.addr_dong_code,
        addr_dong_cd: areaData.addr_dong_code,
        addr_li: data.name,
        addr_li_code: data.cd,
        addr_li_cd: data.code,
      });
    }
  };

  // 문자열 체크
  const checkLastCharacter = (str: string, character: string) => {
    const parseStr = str ? str : '';
    return parseStr[parseStr.length - 1] === character;
  };

  const checkProperty = (obj: any, searchString: string) => {
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].includes(searchString)) {
        return true;
      }
    }
    return false;
  };

  // 수정하기
  const handleClickModify = () => {
    if (viewType === 'looking_for_area') {
      action?.(areaData);
    }
    if (viewType === 'looking_for_type') {
      multiAction?.(selected);
    }
    handleClickClose();
  };

  return (
    <div
      className={cn(
        Styles['popup-container'],
        Styles['bottom-sheet-popup'],
        viewType === 'property_type' && Styles['select-type-pop'],
        viewType === 'looking_for_area' && CommonStyles['area-select-pop'],
      )}
    >
      <div className={Styles['popup-header']}>
        {viewType === 'property_area' && <p className={Styles['pop-tit']}>면적 종류를 선택해주세요</p>}
        {viewType === 'property_type' ||
          (viewType === 'looking_for_type' && <p className={Styles['pop-tit']}>매물 종류를 선택해주세요</p>)}
        {viewType === 'looking_for_area' && <p className={Styles['pop-tit']}>매물 소재지를 선택해주세요</p>}
        <button className={Styles['close']} onClick={handleClickClose}></button>
      </div>
      <div
        className={cn(Styles['popup-content'], viewType === 'looking_for_type' && Styles['share-write'])}
        style={{
          padding: viewType === 'looking_for_area' ? '0rem' : '0 1rem',
        }}
      >
        {viewType === 'property_area' && (
          <div className={Styles['button-list']}>
            <button>공급면적</button>
            <button>계약면적</button>
            <button>대지면적</button>
            <button>연면적</button>
          </div>
        )}
        {viewType === 'property_type' && (
          <>
            <div className={Styles['division']}>
              <div className={Styles['d-flex']}>
                <button className={CommonStyles['btn']}>전체</button>
              </div>
            </div>
            {typeList.map((item: any, index: number) => {
              return (
                <div key={index} className={Styles['division']}>
                  <p className={Styles['tit']}>{item?.main_nm || '-'}</p>
                  <div className={Styles['d-flex']} style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
                    {item?.['offer_types']?.map((el: any, elIndex: number) => {
                      return (
                        <button key={elIndex} className={cn(Styles['btn'], Styles['line'])}>
                          {el.sub_nm}
                        </button>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
        {viewType === 'looking_for_type' && (
          <section
            style={{ gap: '1.5rem', display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}
          >
            <div className={CommonStyles['insert-item']}>
              <div className={CommonStyles['btn-wrap']}>
                <button
                  className={cn(
                    CommonStyles['btn'],
                    CommonStyles['lg'],
                    type !== 'residential' && CommonStyles['line'],
                  )}
                  onClick={() => {
                    handleClickType('residential');
                  }}
                >
                  주거용
                </button>
                <button
                  className={cn(CommonStyles['btn'], CommonStyles['lg'], type !== 'commercial' && CommonStyles['line'])}
                  onClick={() => {
                    handleClickType('commercial');
                  }}
                >
                  상업용
                </button>
              </div>
            </div>
            <div className={CommonStyles['insert-item']}>
              <div
                className={cn(CommonStyles['d-flex-wrap'])}
                style={{
                  display: 'flex',
                  flex: 'flex-wrap',
                  gap: '0.5rem',
                }}
              >
                {type === 'residential' && (
                  <>
                    {residentialList.map((item) => {
                      const findItem = selected.find((el: any) => item.code === el.code);
                      return (
                        <button
                          key={item.name}
                          className={cn(CommonStyles['btn'], findItem?.code !== item.code && CommonStyles['line'])}
                          onClick={() => handleClickSeekType(item)}
                        >
                          {item.name}
                        </button>
                      );
                    })}
                  </>
                )}
                {type === 'commercial' && (
                  <>
                    {list.map((item) => {
                      const findItem = selected.find((el: any) => item.code === el.code);
                      return (
                        <button
                          key={item.name}
                          className={cn(CommonStyles['btn'], findItem?.code !== item.code && CommonStyles['line'])}
                          onClick={() => handleClickSeekType(item)}
                        >
                          {item.name}
                        </button>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </section>
        )}
        {viewType === 'looking_for_area' && (
          <>
            <div className={cn(CommonStyles['select-step1'])}>
              <button
                type="button"
                className={cn(areaData?.addr_si && CommonStyles['selected'])}
                onClick={() => handleClickCurrentStep(1)}
              >
                {areaData?.addr_si ? areaData?.addr_si : '시/도'}
              </button>
              <i className={CommonStyles['icon-arrow-down-gr']}></i>
              <button
                type="button"
                className={cn(areaData?.addr_gu && CommonStyles['selected'])}
                onClick={() => handleClickCurrentStep(2)}
              >
                {areaData?.addr_gu ? areaData?.addr_gu : '시/군/구'}
              </button>
              <i className={CommonStyles['icon-arrow-down-gr']}></i>
              <button
                type="button"
                className={cn(areaData?.addr_dong && CommonStyles['selected'])}
                onClick={() => handleClickCurrentStep(3)}
              >
                {areaData?.addr_dong ? areaData.addr_dong : '읍/면/동'}
              </button>
              {checkLastCharacter(areaData?.addr_dong, '동') === false &&
                areaData?.addr_dong !== '' &&
                areaData?.addr_dong !== null && (
                  <>
                    <i className={CommonStyles['icon-arrow-down-gr']}></i>
                    <button
                      type="button"
                      className={cn(areaData?.addr_li && CommonStyles['selected'])}
                      onClick={() => handleClickCurrentStep(4)}
                    >
                      {areaData?.addr_li ? areaData?.addr_li : '  선택(리)'}
                    </button>
                  </>
                )}
            </div>
            <div className={CommonStyles['scroll-area']} style={{ minHeight: 'calc(100vh - 16.563rem)' }}>
              <div className={CommonStyles['select-step2']}>
                {addrList.map((item: IMixedKeyValue, index: number) => {
                  const { cd, name } = item;
                  const isCheck = checkProperty(areaData, cd);
                  return (
                    <button
                      type="button"
                      key={index}
                      className={cn(CommonStyles['btn'], !isCheck && CommonStyles['line'])}
                      onClick={() => handleClickSeletedArea(item)}
                    >
                      {item.name}
                    </button>
                  );
                })}
                {addrList.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'fixed',
                      left: 0,
                      top: 'calc(100vh - 15.5rem - 20px)',
                    }}
                  >
                    <ScrollLoader />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={cn(Styles['popup-button-wrap'], Styles['mt0'])}>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'])}
          style={{ width: '100%' }}
          onClick={handleClickModify}
        >
          수정하기
        </button>
      </div>
    </div>
  );
};

export default SharePropertyAreaSheet;
