import { useEffect, useState } from 'react';
import network from 'utils/network';
import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';

type TAppInfo = {
  api_message: string;
  api_period: string;
  api_status: boolean;
};

/**
 * @description Parking 화면
 * @Class
 * @category Pages
 * @subcategory 점검
 * @component
 * @returns {JSX.Element}
 */
const Parking = () => {
  const [appInfo, setAppInfo] = useState<TAppInfo>({
    api_message: '',
    api_period: '',
    api_status: true,
  });

  const getAppInfo = async () => {
    try {
      const { data } = await network().app().getAppInfo();

      if (data) {
        setAppInfo({
          api_message: data.api_message,
          api_period: data.api_period,
          api_status: data.api_status,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAppInfo();
  }, []);

  return (
    <div id="wrap">
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles['error-page'])}>
          <i className={CommonStyles['icon-parking']}></i>
          <div className={CommonStyles['txt-box']}>
            <p className={CommonStyles.tit}>시스템 정기점검 중이에요.</p>
            <p className={CommonStyles.txt}>
              신속하게 처리하여 빠른 시일내에
              <br />
              정상적인 서비스가 되도록 할게요.
              <br />
              감사합니다.
            </p>
          </div>
          <div className={CommonStyles['gray-box']}>
            <p>점검시간: {appInfo.api_period} </p>
            <p>점검내용: {appInfo.api_message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parking;
