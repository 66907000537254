import React, { useState, useEffect, ChangeEvent, KeyboardEvent, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as APIS from 'api/group';
import cn from 'classnames';
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import { offLoad, onLoad } from 'redux/slices/loading';
import { setGroupInfo } from 'redux/slices/group';
import { IMixedKeyValue } from '<modal>';
import { cloneDeep } from 'lodash';

// configs
import * as Config from 'configs/configs';
import DatePicker from 'components/common/DatePicker/DatePicker';
import moment from 'moment';

interface Props {
  type?: string;
  title?: string;
}

/**
 * @description 모임 sheet
 * @Class
 * @category Components
 * @subcategory group sheet
 * @component
 * @returns {JSX.Element}
 */
const GroupBotSheet = (props: Props) => {
  const { title } = props;
  const dispatch = useAppDispatch();
  const { modalType, isOpen, data, action } = useAppSelector((state: RootState) => state.modal);
  const groupData = useAppSelector((state: RootState) => state.group);

  const { join_condition: join_cd } = groupData;
  const { open_info, join_apply_use, join_condition, id = '', owner_user } = groupData.group;

  // 모임 공개 설정 관련 state
  const [selected, setSelected] = useState<{
    label?: string;
    value?: string;
    key?: number | null;
    checked?: boolean; // 모임명 공개모임 설정값
  }>({
    label: '',
    value: '',
    key: null,
    checked: false,
  });
  // 임시 모임 공개 설정 관련 state
  const [tempCondition, setTempCondition] = useState<{
    label?: string;
    value?: string;
    key?: number | null;
    checked?: boolean; // 모임명 공개모임 설정값
  }>({
    label: '',
    value: '',
    key: null,
    checked: false,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  // 페널티 기간
  const [startDate, setStartDate] = useState<IMixedKeyValue | null>(null);
  const [endDate, setEndDate] = useState<IMixedKeyValue | null>(null);
  const [refresh, setRefresh] = useState<string>('');

  // 가입 조건
  const [type, setType] = useState<string>('');
  // 가입 조건 임시 temp
  const [temp, setTemp] = useState<string>('');

  useEffect(() => {
    if (groupData.group) {
      if (open_info) {
        const { label, value, key } = open_info;
        setSelected({
          label: label,
          value: value,
          key: key,
          checked: join_apply_use,
        });
        if (tempCondition.label === '') {
          setTempCondition({
            label: label,
            value: value,
            key: key,
            checked: join_apply_use,
          });
        }
      }

      if (join_condition) {
        const { broker_type } = join_condition;
        setType(broker_type);
        setTemp(broker_type);
      }
    }
  }, [groupData.group]);

  useEffect(() => {
    return () => {
      console.log('unmount');
    };
  }, []);

  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [category, setCategory] = useState<{
    category?: string | null | undefined;
    category_value?: string | null | undefined;
    category_key?: number | null | undefined;
  }>({
    category: '',
    category_value: '',
    category_key: null,
  });

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  useEffect(() => {
    if (groupData?.group) {
      const { kind_info } = groupData.group;
      setCategory({
        category: kind_info?.label,
        category_value: kind_info?.value,
        category_key: kind_info?.key,
      });
    }
  }, [groupData]);

  const handleModalClose = () => {
    if (groupData?.group) {
      const { kind_info } = groupData.group;
      setCategory({
        category: kind_info?.label,
        category_value: kind_info?.value,
        category_key: kind_info?.key,
      });
    }
    setOpen!(false);
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  const handleConfirm = () => {
    if (modalType === 'groupBotSheet_group_public_setting') {
      // 모임 공개 설정
      if (
        (groupData.group['open_info'].value === 'PRIVATE' || groupData.group['open_info'].value === 'NAMEONLY') &&
        (selected?.value === 'PUBLIC' || selected?.value === 'NAMEONLY')
      ) {
        dispatch(ActionModal.closeModal({ isOpen: false, modalType: 'groupBotSheet_group_public_setting' }));
        setTimeout(() => {
          dispatch(
            ActionModal.openModal({
              isOpen: true,
              modalType: 'confirm_group_change_type',
              action: () => action?.(selected.key, selected.checked),
            }),
          );
        }, 100);
      } else {
        action?.(selected.key, selected.checked);
      }
    }

    if (modalType === 'groupBotSheet_group_penalty_period') {
      setTimeout(() => {
        dispatch(
          ActionModal.openModal({
            isOpen: true,
            modalType: 'confirm_group_set_penalty',
            data: {
              ...data,
              startDate: startDate,
              endDate: endDate,
            },
            action: action,
          }),
        );
      }, 100);
    }

    if (modalType === 'groupBotSheet_group_join_condition') {
      // 모임 가입 조건 설정
      modifyJoinCondition();
    }
    if (modalType !== 'groupBotSheet_group_penalty_period') {
      handleModalClose();
    }
  };

  const modifyJoinCondition = async () => {
    if (id === '') return;
    try {
      dispatch(onLoad());
      const payload = {
        join_condition: {
          gender: 'ALL',
          broker_type: type,
        },
      };
      const res = await APIS.putIsGroupCondition(`/groups/${id}/join`, payload);
      const { message } = res.data;
      if (message === 'OK') {
        dispatch(
          setGroupInfo({
            ...groupData,
            group: {
              ...groupData.group,
              join_condition: payload.join_condition,
            },
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 검색 여부 체크
  const [isSearch, setIsSearch] = useState<boolean>(false);

  // 이전 지역 검색 리스트
  const [tempAreaList, setTempAreaList] = useState<string[]>([]);

  // 지역 리스트
  const [areaList, setAreaList] = useState<IMixedKeyValue[]>([]);

  // 지역 search value
  const [areaValue, setAreaValue] = useState<string>('');
  // 지역 검색어 저장
  const [tempValue, setTempValue] = useState<string>('');

  // 이전 검색결과 목록 가져오기 localStorage
  useEffect(() => {
    if (id && modalType === 'groupBotSheet_group_area') {
      // 검색창 focus
      if (inputRef.current) {
        inputRef.current?.focus();
      }
      const historyData: any = JSON.parse(localStorage?.getItem('searchAreaList') || '{}');

      if (Object.keys(historyData).length > 0) {
        const listData = historyData?.[id] || [];

        if (listData.length > 0) {
          setTempAreaList(listData);
        }
      } else {
        localStorage.setItem(
          'searchAreaList',
          JSON.stringify({
            [id]: [],
          }),
        );
      }
    }
  }, [groupData]);

  // 지역 검색 value change
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setAreaValue(e.target.value);
  };

  //엔터 입력시 검색
  const onChangeEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && areaValue.length > 0) {
      setTempValue(areaValue);
      fetchSearch();
    }
  };

  //검색 내역 추가
  const addHistoryData = () => {
    let historyData = JSON.parse(localStorage?.getItem('searchAreaList') || '{}');
    const copyData = cloneDeep(historyData?.[id] || []);

    if (id) {
      if (copyData.length >= 10) {
        copyData.shift();
      }
      copyData.push(areaValue);

      if (historyData?.[id]) {
        if (historyData[id].includes(areaValue)) {
          return;
        }
        historyData[id] = copyData;
      } else {
        historyData = {
          ...historyData,
          [id]: copyData,
        };
      }

      localStorage.setItem('searchAreaList', JSON.stringify(historyData));
    }
  };

  // 지역 검색
  const fetchSearch = async (areaName?: string) => {
    if (id === '') return;
    try {
      const res = await APIS.getAreaList(`/area?q=${areaName ? areaName : areaValue}`);
      const { data = [] } = res;
      setIsSearch(true);

      if (data.length > 0) {
        if (areaName === undefined) {
          addHistoryData();
        }
        if (areaName) {
          setAreaValue(areaName);
        }
        setAreaList([...data]);
      } else {
        setAreaList([]);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  // 이전 검색 리스트 클릭시 조회 함수
  const handleClickTempItem = (areaName: string) => {
    fetchSearch(areaName);
  };

  // 검색결과 주소 내에서 결과 삭제
  const handleClickDeleteItem = (index: number) => {
    let copyList = cloneDeep(areaList);
    copyList = copyList.filter((_, itemIndex) => itemIndex !== index);
    setAreaList(copyList);
  };

  // 이전 검색결과 내에서 결과 삭제
  const handleClickDeleteTempItem = (index: number) => {
    let copyList = cloneDeep(tempAreaList);
    copyList = copyList.filter((_, itemIndex) => itemIndex !== index);

    const historyData: any = JSON.parse(localStorage?.getItem('searchAreaList') || '{}');
    historyData[id] = copyList;

    localStorage.setItem('searchAreaList', JSON.stringify(historyData));
    setTempAreaList(copyList);
  };

  // groupBotSheet_image_select ==> 모임에서 이미지 선택 bottom sheet
  // groupBotSheet_group_public_setting ==> 모임 공개설정 bottom sheet
  // groupBotSheet_group_subject_select ==> 모임 주제 선택
  // groupBotSheet_group_join_condition ==> 가입 조건 설정

  const isBtn =
    modalType === 'groupBotSheet_group_join_condition' && temp === type
      ? true
      : modalType === 'groupBotSheet_group_public_setting' && selected.label === tempCondition.label
      ? selected.checked !== tempCondition.checked
        ? false
        : true
      : false;

  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div
          id="render-modal"
          className={cn(
            Styles['popup-container'],
            Styles['bottom-sheet-popup'],
            modalType === 'groupBotSheet_group_area' && Styles['region-search-pop'],
            modalType === 'groupBotSheet_group_public_setting' &&
              (CommonStyles['group-type-pop'], Styles['group-type-pop']),
            modalType === 'groupBotSheet_group_subject_select' && Styles['subject-setting-pop'],
            modalType === 'groupBotSheet_group_join_condition' && Styles['join-condition-pop'],
            modalType === 'groupBotSheet_group_penalty_period' && Styles['penalty-period-pop'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
        >
          <div className={Styles['popup-header']}>
            {modalType === 'groupBotSheet_image_select' || <p className={Styles['pop-tit']}>{title}</p>}
            <button className={Styles.close} onClick={handleModalClose} />
          </div>
          <div className={Styles['popup-content']}>
            <div className={cn(CommonStyles['button-list'], Styles['button-list'])}>
              {modalType === 'groupBotSheet_image_select' && (
                <>
                  <Button
                    className="none"
                    onClick={() => {
                      if (action) {
                        handleModalClose();
                        setTimeout(() => {
                          action();
                        }, 100);
                      }
                    }}
                  >
                    기본 사진 선택
                  </Button>
                  <Button
                    className="none"
                    onClick={() => {
                      const el = document.getElementById('group_image_select');
                      if (el) {
                        el?.click();
                        handleModalClose();
                      }
                    }}
                  >
                    앨범에서 사진 선택
                  </Button>
                </>
              )}
              {modalType === 'groupBotSheet_group_public_setting' && (
                <div className={Styles['radio-div']}>
                  {groupData?.open[0]?.children?.map(
                    (item: { type: string; label: string; value: string; key: number | null }, index: number) => {
                      const returnMessage = (type: string) => {
                        let message = '';
                        switch (type) {
                          case 'PRIVATE':
                            message = '모임 게시글이 공개되지 않습니다. 모임장의 초대를 통해서만 가입할 수 있습니다.';
                            break;
                          case 'NAMEONLY':
                            message =
                              '누구나 모임을 검색으로 찾아 모임 소개를 볼 수 있지만,\n게시글은 가입 회원만 볼 수 있습니다.';
                            break;
                          case 'PUBLIC':
                            message = '누구나 모임을 검색으로 찾아 모임 소개와 게시글을 볼 수 있습니다.';
                            break;
                          default:
                            message = '';
                            break;
                        }

                        return message;
                      };
                      return (
                        <div key={index} className={cn(CommonStyles['radio-box'], Styles.list)}>
                          <label htmlFor={item.value}>
                            <p className={Styles.tit}>{item.label}</p>
                            <p className={Styles.txt} style={{ whiteSpace: 'pre-line' }}>
                              {returnMessage(item.value)}
                            </p>
                          </label>
                          <input
                            type="radio"
                            name="type"
                            id={item.value}
                            onClick={() => {
                              if (item.key) {
                                setSelected({
                                  label: item.label,
                                  value: item.value,
                                  key: item.key,
                                  checked: item.value === 'NAMEONLY' ? join_apply_use : false,
                                });
                              }
                            }}
                            checked={item.value === selected.value}
                          />
                          <label
                            htmlFor={item.value}
                            className={cn(CommonStyles['label-info'], Styles['label-info'])}
                          />
                          {item.value === 'NAMEONLY' && selected.value === 'NAMEONLY' && (
                            <div className={cn(Styles['hidden-item'], CommonStyles['gray-box'])} onClick={() => {}}>
                              <label htmlFor="application">
                                <p className={Styles.tit}>가입 신청 받기</p>
                                <p className={Styles.txt}>회원 가입 시 모임장의 승인이 필요합니다.</p>
                              </label>
                              <div className={cn(Styles['toggle-box'], CommonStyles['round-toggle'])}>
                                <input
                                  type="checkbox"
                                  style={{ width: '0px', height: '0px' }}
                                  id="application"
                                  checked={selected.checked}
                                  onChange={(e) => setSelected({ ...selected, checked: e.target.checked })}
                                />
                                <label
                                  htmlFor="application"
                                  className={cn(CommonStyles['label-info'], Styles['label-info'])}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </div>

            {modalType === 'groupBotSheet_group_subject_select' && (
              <>
                {groupData.kind[0] &&
                  groupData.kind[0]?.children?.map(
                    (
                      item: {
                        children: [];
                        label: string;
                      },
                      index: number,
                    ) => {
                      return (
                        <div key={index} className={Styles.division}>
                          <p className={Styles.tit}>{item.label}</p>
                          <div className={cn(Styles['d-flex'], ResetStyles['d-flex'])}>
                            {item.children.map(
                              (
                                el: {
                                  label: string;
                                  value: string;
                                  key: number;
                                },
                                elIndex: number,
                              ) => {
                                return (
                                  <Button
                                    key={elIndex}
                                    type={el.label === category.category ? 'default' : 'line'}
                                    onClick={() => {
                                      if (el.label === category.category) return;
                                      action?.(el.label, el.value, el.key);
                                      dispatch(
                                        setGroupInfo({
                                          ...groupData,
                                          group: {
                                            ...groupData.group,
                                            kind_info: {
                                              ...groupData.group.kind_info,
                                              label: el.label,
                                              value: el.value,
                                              key: el.key,
                                            },
                                          },
                                        }),
                                      );
                                      setCategory({
                                        category: el.label,
                                        category_value: el.value,
                                        category_key: el.key,
                                      });
                                    }}
                                  >
                                    {el.label}
                                  </Button>
                                );
                              },
                            )}
                          </div>
                        </div>
                      );
                    },
                  )}
              </>
            )}

            {modalType === 'groupBotSheet_group_join_condition' && (
              <>
                <p className={Styles.tit}>중개사 인증</p>
                <div className={Styles['radio-div']}>
                  {join_cd.length > 0 &&
                    join_cd?.[0]?.children
                      ?.filter((item: IMixedKeyValue) => item.value === 'BROKER_TYPE')
                      .map((el: IMixedKeyValue) => {
                        return el.children.map((dl: IMixedKeyValue, dlIndex: number) => {
                          return dl.value === 'BROKER' && owner_user?.verify === null ? null : (
                            <div
                              key={dlIndex}
                              className={cn(CommonStyles['radio-box'], Styles.list, CommonStyles['gray-box'])}
                            >
                              <label htmlFor="noLimit">
                                <p className={Styles.tit}>{dl.label}</p>
                                {dl.value === 'ALL' && (
                                  <p className={Styles.txt}>일반회원 중개사회원 모두 가입 가능합니다.</p>
                                )}
                                {dl.value === 'BROKER' && (
                                  <p className={Styles.txt}>중개사인증을 완료한 회원만 가입 가능합니다.</p>
                                )}
                              </label>
                              <input
                                type="radio"
                                name="type"
                                id={`${dl.value}-noLimit`}
                                checked={dl.value === type}
                                onChange={() => setType(dl.value)}
                              />
                              <label
                                htmlFor={`${dl.value}-noLimit`}
                                className={cn(CommonStyles['label-info'], Styles['label-info'])}
                              />
                            </div>
                          );
                        });
                      })}
                </div>
                {/* <p className={Styles.tit}>중개사 인증</p>
                <div className={Styles['radio-div']}>
                  <div className={cn(CommonStyles['radio-box'], Styles.list, CommonStyles['gray-box'])}>
                    <label htmlFor="noLimit">
                      <p className={Styles.tit}>제한 없음</p>
                      <p className={Styles.txt}>일반회원 중개사회원 모두 가입 가능합니다.</p>
                    </label>
                    <input type="radio" name="type" id="noLimit" />
                    <label htmlFor="noLimit" className={cn(CommonStyles['label-info'], Styles['label-info'])} />
                  </div>
                  <div className={cn(CommonStyles['radio-box'], Styles.list, CommonStyles['gray-box'])}>
                    <label htmlFor="onlyAgency">
                      <p className={Styles.tit}>중개사만</p>
                      <p className={Styles.txt}>중개사인증을 완료한 회원만 가입 가능합니다.</p>
                    </label>
                    <input type="radio" name="type" id="onlyAgency" checked={b} />
                    <label htmlFor="onlyAgency" className={cn(CommonStyles['label-info'], Styles['label-info'])} />
                  </div>
                </div> */}
              </>
            )}
            {modalType === 'groupBotSheet_group_penalty_period' && (
              <>
                {startDate && endDate ? (
                  <p className={Styles.tit}>
                    {moment(startDate.fullDate).format('YYYY년 M월 D일')} ~{' '}
                    {moment(endDate.fullDate).format('YYYY년 M월 D일')}
                  </p>
                ) : (
                  <p className={Styles.txt}>아래 캘린더에서 날짜를 선택해주세요.</p>
                )}
                <DatePicker
                  refresh={refresh}
                  setSelectedStartDate={setStartDate}
                  setSelectedEndDate={setEndDate}
                  selectedStartDate={startDate}
                  selectedEndDate={endDate}
                />
                {/* <div className={CommonStyles['calendar-footer']}>
                  <div className={CommonStyles['period-set-wrap']}>
                    <button
                      className={selectedDate === 7 ? CommonStyles['selected'] : ''}
                      onClick={() => setSelectedDate(7)}
                    >
                      7일
                    </button>
                    <button
                      className={selectedDate === 14 ? CommonStyles['selected'] : ''}
                      onClick={() => setSelectedDate(14)}
                    >
                      14일
                    </button>
                    <button
                      className={selectedDate === 30 ? CommonStyles['selected'] : ''}
                      onClick={() => setSelectedDate(30)}
                    >
                      30일
                    </button>
                    <button
                      className={selectedDate === 60 ? CommonStyles['selected'] : ''}
                      onClick={() => setSelectedDate(60)}
                    >
                      60일
                    </button>
                  </div>
                </div> */}
              </>
            )}
            {modalType === 'groupBotSheet_group_area' && (
              <>
                <Input
                  ref={inputRef}
                  clear={true}
                  mergeClass={true}
                  className={cn(CommonStyles['search-input'])}
                  value={areaValue}
                  onChange={onChangeValue}
                  handleClickClear={() => setAreaValue('')}
                  onKeyDown={(e) => onChangeEnterPress(e)}
                  placeholder="시/군/구, 읍/면/동 단위로 입력해주세요."
                />
                {/* <div className={Styles["input-area search-input clear-has"]}>
                    <input type="text" placeholder="장소, 주소 단위로 입력해주세요." />
                    <button className={CommonStyles.delete}></button>
                  </div> */}
                {/* <!-- 검색 전 --> */}
                <div className={cn(Styles['scroll-area'])}>
                  {!isSearch && <p className={cn(Styles['sub-title'], CommonStyles['sub-title'])}>이전검색</p>}
                  {isSearch && areaList.length > 0 && (
                    <p className={cn(Styles['sub-title'], CommonStyles['sub-title'])}>검색결과</p>
                  )}
                  {tempAreaList.length > 0 && areaList.length === 0 && !isSearch && (
                    <div className={CommonStyles['search-keyword-list']}>
                      {tempAreaList.map((item: string, index: number) => {
                        return (
                          <div key={index} className={CommonStyles.list}>
                            <button
                              onClick={() => {
                                setTempValue(item);
                                setAreaValue(item);
                                handleClickTempItem(item);
                              }}
                            >
                              {item}
                            </button>
                            <button className={CommonStyles.delete} onClick={() => handleClickDeleteTempItem(index)} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {isSearch && areaList.length > 0 && (
                    <div className={CommonStyles['search-keyword-list']}>
                      {areaList.map((item, index: number) => {
                        const parseAddr = item.addr.split(' ');
                        return (
                          <div key={index} className={CommonStyles.list}>
                            <button
                              onClick={() => {
                                action?.(item.addr, item.code);
                                handleModalClose();
                              }}
                            >
                              <HighlightKeyword text={item.addr} keyword={tempValue} />
                            </button>
                            <button className={CommonStyles.delete} onClick={() => handleClickDeleteItem(index)} />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {areaList.length === 0 && isSearch && (
                    <div className={CommonStyles['search-keyword-list']} style={{ height: '100%', marginTop: '0' }}>
                      <div className={CommonStyles['no-data']} style={{ height: '100%' }}>
                        <p>검색결과가 없어요.</p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          {modalType !== 'groupBotSheet_group_area' && (
            <div
              className={cn(
                Styles['popup-button-wrap'],
                CommonStyles['popup-button-wrap'],
                modalType === 'groupBotSheet_image_select' && CommonStyles['mt0'],
                modalType === 'groupBotSheet_group_penalty_period' && Styles.ratio,
              )}
            >
              {modalType === 'groupBotSheet_image_select' && (
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
                  취소
                </button>
              )}
              {(modalType === 'groupBotSheet_group_public_setting' ||
                modalType === 'groupBotSheet_group_join_condition' ||
                modalType === 'groupBotSheet_group_subject_select') && (
                <Button className={Styles.btn} mergeClass={true} size="large" onClick={handleConfirm} disabled={isBtn}>
                  확인
                </Button>
              )}
              {modalType === 'groupBotSheet_group_penalty_period' && (
                <>
                  <Button
                    size="large"
                    type="gry"
                    className={cn(Styles.btn)}
                    mergeClass={true}
                    disabled={startDate || endDate ? false : true}
                    onClick={() => {
                      setStartDate(null);
                      setEndDate(null);
                      setRefresh(`${new Date().getTime()}`);
                    }}
                  >
                    새로고침
                  </Button>
                  <Button
                    size="large"
                    className={cn(Styles.btn)}
                    mergeClass={true}
                    onClick={handleConfirm}
                    disabled={startDate && endDate ? false : true}
                  >
                    확인
                  </Button>
                </>
              )}
            </div>
          )}
          {modalType === 'groupBotSheet_group_area' && (
            <div
              className={cn(
                Styles['popup-button-wrap'],
                CommonStyles['popup-button-wrap'],
                modalType === 'groupBotSheet_image_select' && CommonStyles['mt0'],
                modalType === 'groupBotSheet_group_penalty_period' && Styles.ratio,
              )}
            >
              <Button className={Styles.btn} mergeClass={true} size="large" onClick={handleConfirm} disabled={isBtn}>
                확인
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const HighlightKeyword = ({ text, keyword }: { text: string; keyword: string }) => {
  const parts = text.split(new RegExp(`(${keyword})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === keyword.toLowerCase() ? (
          <span key={index}>
            <b className={CommonStyles['co-dorg']}>{part}</b>
          </span>
        ) : (
          part
        ),
      )}
    </span>
  );
};

export default GroupBotSheet;
