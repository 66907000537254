import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import * as config from 'configs/configs';
import network from 'utils/network';
import axios from 'axios';

import { useAppDispatch, useAppSelector, RootState } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import { setMe } from 'redux/slices/user';
import * as ActionUser from 'redux/slices/user';
import * as ActionModal from 'redux/slices/modal';

// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Styles from 'styles/header.module.css';
import { moveMain, onClose } from 'utils';

/**
 * @description header
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @returns {JSX.Element}
 */
const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pathname } = location;
  const historyPath = useAppSelector((state: RootState) => state.user.historyPath);
  const user = useAppSelector((state: RootState) => state.user);
  const isVerified = useAppSelector((state: RootState) => state.user.brokerVerified);
  const headerText = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.title;
  const isBackKey = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.backKey;
  const isHeader = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.header;
  const hasClose = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.hasClose;
  const hasReturnNav = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.returnNav;
  const brokerAuth = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.brokerAuth;
  const backUrl: string | number = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.backUrl || -1;
  const pathName = new URL(window.location.href).pathname;
  const useBackey = isVerified && brokerAuth;

  // IOS 모임 뒤로가기 이슈
  if (window.location.href.includes('mygroup/group/') === true) {
    //
  } else {
    document.cookie = `prevUrl=${window.location.pathname}; path=/`;
    localStorage.setItem('prevUrl', window.location.pathname);
    sessionStorage.setItem('prevUrl', window.location.pathname);
  }

  // 내 프로필 편집 저장
  const handleProfileSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      if (!user?.profile_edit?.save) return;

      e.currentTarget.disabled = true;

      await network()
        .sso()
        .putProfile({ nickname: user?.profile_edit?.nickname, profile: user?.profile_edit?.photo });

      const response = await network().sso().me();
      dispatch(setMe(response.data));

      if (import.meta.env.MODE === 'localdev') {
        navigate(-1);
      } else {
        moveMain(4, '0'); // 내 피드 새로고침
        onClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          dispatch(
            openModal({
              modalType: 'alert_custom',
              data: {
                txt: error.response?.data?.message,
              },
            }),
          );
        }
      } else {
        console.log('error:', error);
      }

      e.currentTarget.disabled = false;
    }
  };

  const handleCloseConfirm = () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_writing_close',
        action: () => handleClose(),
        isOpen: true,
      }),
    );
  };

  const handleClose = () => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/mygroup');
    } else {
      moveMain(0, '0');
      // onClose(); // 메인에서 바로 이동할때는 불필요
    }
  };

  return (
    isHeader && (
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          {(isBackKey || useBackey) && (
            <button
              type="button"
              onClick={() => {
                const isBrokerInfo = pathName === '/feed/brokerinfo' ? true : false;
                if (import.meta.env.MODE === 'localdev') {
                  if (isBrokerInfo) {
                    navigate('/');
                  } else {
                    if (hasReturnNav) {
                      navigate(hasReturnNav);
                    } else {
                      navigate(backUrl as number);
                    }
                  }
                } else {
                  if (document.referrer.includes(import.meta.env.VITE_WEB_DOMAIN) && window.history.state.idx === 0) {
                    navigate(backUrl as number);
                  } else {
                    if (!window.history.state || window.history.state.idx === 0 || isBrokerInfo) {
                      onClose();
                    } else {
                      if (hasReturnNav) {
                        navigate(hasReturnNav);
                      } else {
                        navigate(backUrl as number);
                      }
                    }
                  }
                }
                dispatch(ActionUser.resetHistoryPath());
              }}
            >
              <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
            </button>
          )}
        </div>

        <div className={Styles.title}>{headerText}</div>
        <div className={cn(Styles.right, CommonStyles.right)}>
          {(pathname == '/feed' || pathname == '/feed/comments') && (
            <button
              onClick={() => {
                dispatch(
                  openModal({
                    modalType: 'bottomSheet_feed_setting',
                  }),
                );
              }}
            >
              <i className={CommonStyles['icon-setting']}></i>
            </button>
          )}
          {pathname == '/feed/profile' && (
            <button
              className={cn(CommonStyles['text-btn'], !user?.profile_edit?.save && CommonStyles['disabled'])}
              onClick={handleProfileSave}
            >
              완료
            </button>
          )}
          {hasClose && (
            <button onClick={() => (pathname === '/newpost-type' ? handleClose() : handleCloseConfirm())}>
              <i className={CommonStyles['icon-x-bk']} />
            </button>
          )}
        </div>
      </header>
    )
  );
};

export default Header;
