import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/slices/user';

import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

/**
 * @description 중개사 modal
 * @Class
 * @category Components
 * @subcategory broker modal
 * @component
 * @returns {JSX.Element}
 */
const BrokerAlert = () => {
  const dispatch = useAppDispatch();

  const { me } = useSelector(userSelector);

  const { modalType, isOpen, data } = useAppSelector((state: RootState) => state.modal);
  const [open, setOpen] = useState<boolean>(isOpen || false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    if (data?.rejectNavi) {
      data?.rejectNavi();
    }
  };

  const handleConfirm = () => {
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    data?.actionNavi();
  };
  return (
    <>
      {modalType === 'broker_alert' && (
        <div className={cn(Styles['popup-dimd'], Styles.show, !isOpen && ResetStyles['d-none'])}>
          <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
            <div className={Styles['popup-content']}>
              <p className={Styles.txt} style={{ whiteSpace: 'pre-line' }}>
                {data?.txt}
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}

      {modalType === 'broker_confirm' && (
        <div className={cn(Styles['popup-dimd'], Styles.show, !isOpen && ResetStyles['d-none'])}>
          <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
            <div className={Styles['popup-content']}>
              <p className={Styles.txt} style={{ whiteSpace: 'pre-line' }}>
                {data?.txt}
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                onClick={handleModalClose}
              >
                아니오
              </button>
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                네
              </button>
            </div>
          </div>
        </div>
      )}

      {modalType === 'broker_alert_join' && (
        <div className={cn(Styles['popup-dimd'], Styles.show, !isOpen && ResetStyles['d-none'])}>
          <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
            <div className={Styles['popup-content']}>
              <p className={Styles['tit']}>
                반갑습니다. {me.name}님!
                <br />
                혹시, 중개사무소에 일하고 계신가요?
              </p>
              <p className={Styles.txt}>
                중개업에 종사하고 계시다면,
                <br />
                중개사 인증을 통해 중개사 분들을 위한
                <br />
                다양한 기능을 사용해 보세요!
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg, Styles.w100p)}
                onClick={handleConfirm}
              >
                중개사인증 하러가기
              </button>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg, CommonStyles.gry, Styles.w100p)}
                onClick={handleModalClose}
              >
                나중에 하기
              </button>
            </div>
          </div>
        </div>
      )}

      {modalType === 'broker_alert_sync' && (
        <div className={cn(Styles['popup-dimd'], Styles.show, !isOpen && ResetStyles['d-none'])}>
          <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
            <div className={Styles['popup-content']}>
              <p className={Styles.txt}>
                이실장에서 완료한
                <br />
                중개사인증 정보가 있습니다.
                <br />
                간편하게 연동하시겠습니까?
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
                onClick={handleModalClose}
              >
                아니오
              </button>
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                연동하기
              </button>
            </div>
          </div>
        </div>
      )}

      {modalType === 'broker_alert_sync_cancel' && (
        <div className={cn(Styles['popup-dimd'], Styles.show, !isOpen && ResetStyles['d-none'])}>
          <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
            <div className={Styles['popup-content']}>
              <p className={Styles.tit}>중개사 인증 연동 취소</p>
              <p className={Styles.txt}>
                지금 취소하시면 다시 연동하셔야해요.
                <br />
                취소하시겠습니까?
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
                onClick={handleModalClose}
              >
                나중에 하기
              </button>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.bk, CommonStyles.lg)}
                onClick={handleConfirm}
              >
                취소하기
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BrokerAlert;
