import { ImodalIndex } from '<modal>';
import InduceApp from './popup/InduceApp';
// 그룹
import GroupBotSheet from './popup/GroupBotSheet';
import GroupConfirm from './popup/GroupConfirm';
import GroupSelectBotSheet from './popup/GroupSelectBotSheet';
// 공통
import BottomSheet from './popup/BottomSheet';
import CommonConfirm from './popup/CommonConfirm';
import CommonAlert from './popup/CommonAlert';
// 이미지 full popup
import ImageFull from './popup/ImageFull';
// comment bottom sheet
import CommentBotSheet from './popup/CommentBotSheet';
import CommentToolBotSheet from './popup/CommentToolBotSheet';

import FullSheet from './popup/FullSheet';
// 중개사 인증 팝업
import BrokerAlert from './broker/BrokerAlert';
import BrokerBottomSheet from './broker/BrokerBottomSheet';
// 모임 공지글 설정 팝업F
import EtcConfirm from './popup/EtcConfirm';
// 내 피드
import FeedSheet from './popup/FeedSheet';
// 공동중개
import CoagencyBotSheet from './coagency/CoagencyBotSheet';
import CoagencyBotDatePicker from './coagency/CoagencyBotDatePicker';
import CoagencyCommonSelect from './coagency/CoagencyCommonSelect';
// 모임 공동중개
import ShareBotSheet from './share/ShareBotSheet';
import ShareTypeSheet from './share/SharePropertyAreaSheet';
import SharePropertyAreaSheet from './share/SharePropertyAreaSheet';
import FindShareModal from './share/FindShareModal';
import FindShareBotSheet from './share/FindShareBotSheet';
//이실장 매물연동
import LegacyItem from './coagency/LegacyItem';
import LegacyInput from './coagency/LegacyInput';
import LegacyAllModal from './coagency/LegacyAllModal';

/**
 * @description modal or sheet 분기 처리
 * @Class
 * @category Components
 * @subcategory Modal Manager
 */
export const modalIndex: ImodalIndex[] = [
  {
    modalType: 'induce_app',
    component: <InduceApp />,
  },
  {
    modalType: 'bottomSheet_menu', //미트볼 메뉴 클릭시 노출
    component: <BottomSheet />,
  },
  {
    modalType: 'bottomSheet_menuMyPost', //미트볼 메뉴 클릭시 노출 팝업_내 글
    component: <BottomSheet />,
  },
  {
    modalType: 'bottomSheet_share', // 공유하기
    component: <BottomSheet title="공유하기" />,
  },
  {
    modalType: 'bottomSheet_report', // 신고 게시물
    component: <BottomSheet title="신고 게시물" />,
  },
  {
    modalType: 'bottomSheet_addImage', // 첨부이미지
    component: <BottomSheet />,
  },
  {
    modalType: 'bottomSheet_addFile', // 첨부파일
    component: <BottomSheet />,
  },
  {
    modalType: 'bottomSheet_group_notice', // 모임 공지글 관련
    component: <BottomSheet />,
  },
  {
    modalType: 'bottomSheet_feed_setting', // 내 피드 설정
    component: <FeedSheet />,
  },
  {
    modalType: 'bottomSheet_feed_more', // 내 피드 게시글 더보기
    component: <FeedSheet />,
  },
  {
    modalType: 'bottomSheet_modify_report', // 신고된 게시물 관리
    component: <BottomSheet />,
  },
  {
    modalType: 'bottomSheet_save_file', // 파일 저장
    component: <BottomSheet />,
  },
  {
    modalType: 'confirm_delete_post', // 글 삭제 confirm
    component: <CommonConfirm txt="게시글을 삭제하시겠습니까?" />,
  },
  {
    modalType: 'confirm_delete_comment', // 댓글 삭제 confirm
    component: <CommonConfirm txt="댓글을 삭제하시겠습니까?" />,
  },
  {
    modalType: 'confirm_bookmark_comment', // 댓글 삭제 confirm
    component: <CommonConfirm txt="북마크에서 삭제하시겠습니까?" />,
  },
  {
    modalType: 'find_share_register_temp', // 구합니다 매물 임시 등록 confirm
    component: (
      <CommonConfirm
        txt={`구합니다 간편등록이 완료되었습니다!\n매물특징과 설명을 추가로 작성하실 수 있어요.\n추가 작성을 하시겠습니까? `}
      />
    ),
  },
  {
    modalType: 'confirm_cancel_writing_post', // 작성글 취소 confirm
    component: <CommonConfirm txt="작성하시던 글을 취소하시겠습니까?" />,
  },
  {
    modalType: 'confirm_join_group', // 모임 가입 confirm
    component: <CommonConfirm txt={`모임에 가입해야 사용할 수 있습니다. \n모임에 가입 하시겠습니까?`} />,
  },
  {
    modalType: 'confirm_block_user', // 모임 가입 confirm
    component: (
      <CommonConfirm
        title="차단하시겠어요?"
        align="left"
        txt={`이 회원이 쓴 글과 댓글에 대해 알림이 오지 않고,\n작성한 내용도 보이지 않게 됩니다.  `}
      />
    ),
  },
  {
    modalType: 'confirm_custom', // confirm custom
    component: <CommonConfirm />,
  },
  {
    modalType: 'alert_findshare', // 공유정보 선택 alert
    component: <CommonAlert txt="공유정보를 선택해 주세요." />,
  },

  {
    modalType: 'alert_image_limit', // 이미지 limit alert
    component: <CommonAlert txt="사진은 10장까지 첨부할 수 있습니다." />,
  },
  {
    modalType: 'alert_reject_join_group', // 조건에 맞지 않아 가입 불가 alert
    component: <CommonAlert txt={`모임장이 지정한 조건에 맞지 않아\n가입할 수 없습니다.`} />,
  },
  {
    modalType: 'alert_join_group', // 가입 신청 완료 alert
    component: <CommonAlert txt={`가입 신청이 완료되었습니다.\n모임장이 승인하면 모임에 가입됩니다.`} />,
  },
  {
    modalType: 'alert_join_re_group', // 가입 신청 완료 alert
    component: <CommonAlert txt={`모임장의 가입 승인 대기중입니다.\n모임장이 승인하면 모임에 가입됩니다.`} />,
  },
  {
    modalType: 'alert_image_size_limit', // 이미지 limit alert
    component: <CommonAlert txt={`파일 용량을 초과하여 업로드가 불가능합니다.\n개당 20MB 이내의 파일만 가능합니다.`} />,
  },
  {
    modalType: 'alert_image_extension_limit', // 이미지 limit alert
    component: <CommonAlert txt={`첨부하신 파일은 업로드가 불가능해요. \njpg, jpeg, png 파일만 가능합니다.`} />,
  },
  {
    modalType: 'alert_title_limit', // 이미지 limit alert
    component: <CommonAlert txt="제목은 최대 50자 이내로 작성해 주세요." />,
  },
  {
    modalType: 'alert_content_limit', // 이미지 limit alert
    component: <CommonAlert txt="내용은 최대 3000자 이내로 작성해 주세요." />,
  },
  {
    modalType: 'alert_file_each_limit', // 파일 limit alert
    component: <CommonAlert txt="파일은 최대 5개까지 가능합니다." />,
  },
  {
    modalType: 'alert_file_size_limit', // 파일 limit alert
    component: (
      <CommonAlert txt={`파일 용량을 초과하여 업로드가 불가능합니다.\n개당 200MB 이내의 파일만 가능합니다.`} />
    ),
  },
  {
    modalType: 'alert_common', // 모임 위임 에러
    component: <CommonAlert />,
  },
  {
    modalType: 'alert_owner_error', // 모임 위임 에러
    component: <CommonAlert />,
  },
  {
    modalType: 'alert_penalty_error', // 패널티 회원 접근
    component: <CommonAlert />,
  },
  {
    modalType: 'alert_file_limit', // file limit alert
    component: (
      <CommonAlert
        type="file_limit"
        title="첨부하신 파일은 업로드가 불가능합니다."
        txt="pdf, ppt, dox, docx, hwp, xlsx, xls 파일만 업로드가 가능합니다."
      />
    ),
  },
  {
    modalType: 'alert_limit_leave_group', // file limit alert
    component: <CommonAlert txt={`모임장의 경우 모임 탈퇴가 불가능합니다.\n 모임장 위임 후 탈퇴가 가능합니다.`} />,
  },
  {
    modalType: 'alert_delete_group', // file limit alert
    component: (
      <CommonAlert txt={`회원이 있는 경우 모임 삭제가 불가능합니다.\n회원이 모두 탈퇴 후 삭제할 수 있습니다. `} />
    ),
  },
  {
    modalType: 'alert_dont_content', // 파일 limit alert
    component: <CommonAlert txt="나누고 싶은 이야기를 입력해 주세요." />,
  },
  {
    modalType: 'alert_possible_report', // 파일 limit alert
    component: (
      <CommonAlert
        txt={`게시글을 10건 이상 신고하면\n이실장넷 운영정책에 따라\n 익일 자정까지 신고기능이 제한됩니다.`}
      />
    ),
  },
  {
    modalType: 'alert_custom', // custom message alert
    component: <CommonAlert />,
  },
  {
    modalType: 'alert_coagency_modify_success', // 공동 매물 수정 요청 성공
    component: <CommonAlert txt="수정 요청이 완료되었습니다." />,
  },
  {
    modalType: 'alert_looking_for_save_success', // 구합니다 등록 성공
    component: <CommonAlert txt="구합니다 등록이 완료되었습니다 ." />,
  },
  {
    modalType: 'alert_looking_for_modify_success', // 구합니다 수정 성공
    component: <CommonAlert txt="수정이 완료되었습니다." />,
  },
  {
    modalType: 'confirm_looking_for_delete_check', // 구합니다 삭제 체크
    component: <CommonConfirm align="center" txt={`내 구합니다를 삭제하시겠습니까?`} />,
  },
  {
    modalType: 'alert_looking_for_delete_success', // 구합니다 삭제 성공
    component: <CommonAlert txt="삭제되었습니다." />,
  },
  {
    modalType: 'alert_coagency_modify_process_success', // 공동 매물 수정 확인
    component: <CommonAlert txt="매물 수정요청 확인이 처리되었습니다." />,
  },
  {
    modalType: 'alert_coagency_modify_stop_share', // 공동 매물 공유 중지
    component: <CommonAlert txt="공유가 중지되었습니다." />,
  },
  {
    modalType: 'fullSheet_default_image_select', // 모임 이미지 선택
    component: <FullSheet />,
  },
  {
    modalType: 'groupBotSheet_image_select', // 모임 이미지 선택
    component: <GroupBotSheet />,
  },
  {
    modalType: 'groupBotSheet_group_public_setting', // 모임 공개 설정
    component: <GroupBotSheet title="모임 공개 설정" />,
  },
  {
    modalType: 'groupBotSheet_group_subject_select', // 모임 주제 선택
    component: <GroupBotSheet title="주제 선택" />,
  },
  {
    modalType: 'groupBotSheet_group_join_condition', // 가입 조건 설정
    component: <GroupBotSheet title="가입 조건 설정" />,
  },
  {
    modalType: 'groupBotSheet_group_penalty_period', // 패널티 기간 설정
    component: <GroupBotSheet title="페널티 적용 기간" />,
  },
  {
    modalType: 'groupBotSheet_group_area', // 지역선택
    component: <GroupBotSheet title="지역 선택" />,
  },
  {
    modalType: 'groupBotSheet_group_penalty_period', // 페널티 기간 설정
    component: <GroupBotSheet title="페널티 적용 기간" />,
  },
  {
    modalType: 'groupBotSheet_group_area', // 페널티 기간 설정
    component: <GroupBotSheet title="지역 선택" />,
  },
  {
    modalType: 'groupSelectBotSheet_select_group', // 공유할 모임 선택
    component: <GroupSelectBotSheet title="공유할 모임 선택" />,
  },
  {
    modalType: 'confirm_group_change_type', // 모임 공개 변경 안내
    component: <GroupConfirm title="모임 공개 변경 안내" />,
  },
  {
    modalType: 'confirm_group_join_question', // 가입 질문 설정
    component: (
      <GroupConfirm
        title="가입 질문을 작성해주세요."
        txt={'새로운 회원이 모임 가입을 신청할 때,\n물어볼 질문을 작성해주세요.'}
      />
    ),
  },
  {
    modalType: 'confirm_join_question', // 가입 질문 작성
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_group_set_penalty', // 페널티 부과
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_group_clear_penalty', // 페널티 해제
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_group_clear_leader', // 모임장 해임
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_group_transfer_leader', // 모임장 위임
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_group_quit_group', // 모임 탈퇴
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_group_delete_group', // 모임 삭제
    component: <GroupConfirm />,
  },
  {
    modalType: 'confirm_board_change_type', // 중요 공지 등록 모달
    component: <EtcConfirm />,
  },
  {
    modalType: 'show_image_full', // 이미지 full popup
    component: <ImageFull />,
  },
  {
    modalType: 'show_comment_list', // comment bot sheet
    component: <CommentBotSheet />,
  },
  {
    modalType: 'comment_tool_me', // comment tool bot sheet
    component: <CommentToolBotSheet />,
  },
  {
    modalType: 'comment_tool_other', // comment tool bot sheet
    component: <CommentToolBotSheet />,
  },

  //중개사 모달
  {
    modalType: 'broker_alert_join', // 중개사 인증 안내
    component: <BrokerAlert />,
  },
  {
    modalType: 'broker_bottom_agree', // 중개사 인증 :: 개인정보 수집동의
    component: <BrokerBottomSheet />,
  },
  {
    modalType: 'broker_alert', // 중개사 인증 alert 공통
    component: <BrokerAlert />,
  },
  {
    modalType: 'broker_confirm', // 중개사 인증 confirm 공통
    component: <BrokerAlert />,
  },
  {
    modalType: 'broker_alert_sync', // 중개사 인증 연동 안내
    component: <BrokerAlert />,
  },
  {
    modalType: 'broker_alert_sync_cancel', // 중개사 인증 연동 취소 안내
    component: <BrokerAlert />,
  },
  {
    modalType: 'broker_bottom_area', // 중개사 인증 연동 안내
    component: <BrokerBottomSheet />,
  },
  {
    modalType: 'broker_bottom_invite', // 중개사 인증 대표 초대
    component: <BrokerBottomSheet />,
  },
  {
    modalType: 'coagency_bottom_privacy_info', // 공동중개 개인정보 수집 이용동의 bottom sheet
    component: <CoagencyBotSheet title={`개인정보 수집 · 이용 동의`} />,
  },
  {
    modalType: 'coagency_confirm_easy_registration', // 간편등록완료
    component: (
      <CommonConfirm
        align="center"
        txt={`매물공유 간편등록이 완료되었습니다!\n매물특징, 면적 등 설명을 추가로 작성하실 수 있어요. 추가 작성을 하시겠습니까?`}
      />
    ),
  },
  {
    modalType: 'coagency_alert_success_registration', // 등록완료
    component: <CommonAlert type="coagency_registration" txt={`매물공유 상세등록이 완료되었습니다!`} />,
  },
  {
    modalType: 'coagency_alert_success_edit', // 수정완료
    component: <CommonAlert type="coagency_edit" txt={`내매물 수정이 완료되었습니다!`} />,
  },
  {
    modalType: 'coagency_confirm_change_coagency_type', // 매물종류 변경시 컨펌 팝업
    component: (
      <CommonConfirm
        align="center"
        txt={`매물 종류 변경시 이전 작성내용이 초기화 됩니다.\n새로 매물등록을 하시겠습니까?`}
      />
    ),
  },
  {
    modalType: 'coagency_confirm_modify_looking_for', // 구합니다 거래완료
    component: <CommonConfirm align="center" txt={`해당 매물을 거래완료 처리하시겠습니까?`} />,
  },
  {
    modalType: 'coagency_confirm_change_location', // 소재지 변경시 컨펌 팝업
    component: (
      <CommonConfirm align="center" txt={`소재지 변경 시, 입력하신 상세주소가 초기화 됩니다.\n수정하시겠습니까?`} />
    ),
  },
  {
    modalType: 'coagency_confirm_delete_when_edit', // 내 공유매물 수정 ==> 삭제 confirm modal
    component: <CommonConfirm align="center" txt={`내 공유매물을 삭제하시겠습니까?`} />,
  },
  {
    modalType: 'coagency_select_detail_addr_select', // 건축물대장 상세주소 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_select_building_select', // 건축물대장 건물 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_bottom_building_info', // 건축물대장 정보확인 (1. 건축물 대장 조회) bottom sheet
    component: <CoagencyBotSheet title="건축물대장 정보 확인" />,
  },
  {
    modalType: 'coagency_bottom_building_area', // 건축물대장 정보확인 (2. 면적확인) bottom sheet
    component: <CoagencyBotSheet title="건축물대장 정보 확인" />,
  },
  {
    modalType: 'coagency_select_customer_relation', // 매물등록시 고객 관계 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_select_customer_relation_fn', // 매물등록시 고객 관계 선택 팝업(구합니다)
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_select_customer_relation_when_edit', // 매물수정시 고객 관계 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_bottom_customer_info_input', // 고객 정보 입력 bottom sheet
    component: <CoagencyBotSheet title="고객정보를 입력해주세요." />,
  },
  {
    modalType: 'coagency_bottom_custom_textarea', // 매물특징 / 매물설명 / 중개사 메모 입력 bottom sheet
    component: <CoagencyBotSheet title="매물특징을 작성해주세요" />,
  },
  {
    modalType: 'coagency_bottom_share_input', // 공유 정보 입력 bottom sheet
    component: <CoagencyBotSheet title="공유정보를 입력해주세요" />,
  },
  {
    modalType: 'coagency_bottom_room_bath_input', // 방수/욕실수 입력 bottom sheet
    component: <CoagencyBotSheet title="방수와 욕실수를 작성해주세요" />,
  },
  {
    modalType: 'coagency_bottom_floor_input', // 층 입력 bottom sheet
    component: <CoagencyBotSheet title="층을 입력해주세요" />,
  },
  {
    modalType: 'coagency_bottom_tel_display', // 연락처 노출 선택 bottom sheet
    component: <CoagencyBotSheet title="연락처 노출을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_movein_calendar', // 입주 가능일 캘린더 bottom sheet
    component: <CoagencyBotSheet title="입주가능일을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_movein_select', // 입주가능일 선텍 bottom sheet
    component: <CoagencyBotSheet title="입주가능일을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_movein_select_register', // 입주가능일 선텍 bottom sheet (공유매물등록)
    component: <CoagencyBotSheet title="입주가능일을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_movein_calendar_twoFloor', // 입주 가능일 캘린더 2층 bottom sheet
    component: <CoagencyBotDatePicker title="입주가능일을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_price_input', // 매매가 / 전세 / 월세 / 단기임대 / 분양가 bottom sheet
    component: <CoagencyBotSheet />,
  },
  {
    modalType: 'coagency_bottom_price_input_register', // 매매가 / 전세 / 월세 / 단기임대 / 분양가 bottom sheet (공유매물등록)
    component: <CoagencyBotSheet />,
  },
  {
    modalType: 'coagency_bottom_sale_type_select', // 거래유형 선택 bottom sheet
    component: <CoagencyBotSheet title="거래유형을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_sale_type_select_register', // 거래유형 선택 bottom sheet (공유매물등록시)
    component: <CoagencyBotSheet title="거래유형을 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_parcel_type_select_register', // 거래유형 선택 bottom sheet(분양권 구분)==>신규생성
    component: <CoagencyBotSheet title="분양권 구분을 선택해주세요." />,
  },
  {
    modalType: 'coagency_select_area_select', // 공유매물 등록시 면적 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_select_area_select_when_edit', // 공유매물 수정시 면적 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_bottom_area_select', // 면적 수정 bottom sheet
    component: <CoagencyBotSheet title="면적을 선택해주세요" />,
  },
  {
    modalType: 'coagency_select_dong_select', // 동 선택 팝업
    component: <CoagencyCommonSelect />,
  },
  {
    modalType: 'coagency_bottom_dong_ho_select', // 동 호 선택 bottom sheet
    component: <CoagencyBotSheet title="동/호를 선택해주세요" />,
  },
  {
    modalType: 'coagency_bottom_complex_select', // 단지선택 bottom sheet
    component: <CoagencyBotSheet title="단지를 선택해주세요" />,
  },
  {
    // 모임 공유매물 검색 필터
    modalType: 'share_filter_full',
    component: <ShareBotSheet />,
  },
  {
    // 모임 매물 면적
    modalType: 'share_property_area',
    component: <SharePropertyAreaSheet viewType={'property_area'} />,
  },
  {
    // 모임 매물 종류
    modalType: 'share_property_type',
    component: <SharePropertyAreaSheet viewType={'property_type'} />,
  },
  {
    // 구합니다 매물 종류
    modalType: 'share_property_type_looking_for',
    component: <SharePropertyAreaSheet viewType={'looking_for_type'} />,
  },
  {
    // 구합니다 매물 소재지
    modalType: 'share_property_type_looking_for_area',
    component: <SharePropertyAreaSheet viewType={'looking_for_area'} />,
  },
  {
    // 구합니다 거래 유형
    modalType: 'share_property_type_looking_for',
    component: <SharePropertyAreaSheet viewType={'looking_for_type_trade'} />,
  },
  {
    modalType: 'coagency_looking_edit_cancel', // 내 구합니다 수정 취소
    component: (
      <CommonConfirm
        align="center"
        txt={`취소시 작성 내용이 사라집니다.\n작성을 취소하고 이전으로 돌아가시겠습니까?`}
      />
    ),
  },
  {
    modalType: 'coagency_common_alert', // 공통 오류 처리
    component: <CommonAlert />,
  },
  {
    modalType: 'coagency_registration_cancel', // 거의다왔어요에서 취소처리
    component: <CommonConfirm align="center" txt={`작성내용이 사라집니다. 취소하시겠습니까?`} />,
  },
  {
    modalType: 'coagency_detail_registration_cancel', // 상세작성에서 취소처리
    component: <CommonConfirm align="center" txt={`작성내용이 사라집니다. 취소하시겠습니까?`} />,
  },
  {
    modalType: 'coagency_edit_cancel', // 내 공유매물 작성취소
    component: <CommonConfirm align="center" txt={`작성내용이 사라집니다. 취소하시겠습니까?`} />,
  },
  {
    modalType: 'coagency_writing_close', // 글쓰기 공통 close 버튼 취소처리
    component: (
      <CommonConfirm
        align="center"
        txt={`취소 시 작성내용이 사라집니다.\n작성을 취소하시고 홈으로 이동하시겠습니까?`}
      />
    ),
  },
  {
    modalType: 'coagency_offer_edit_confirm', // 내 공유매물 작성시 거래유형변경 confirm
    component: (
      <CommonConfirm align="center" txt={`거래유형을 변경하면 입력하신 가격이 초기화 됩니다.\n진행하시겠습니까?`} />
    ),
  },
  {
    modalType: 'findshare_modal_relation_type', // 관계 타입
    component: <FindShareModal />,
  },
  {
    modalType: 'findshare_bot_sheet_info', // 고객 정보 입력
    component: <FindShareBotSheet title={'고객정보를 입력해주세요.'} />,
  },
  {
    modalType: 'findshare_bot_sheet_memo', // 고객 정보 입력
    component: <FindShareBotSheet title={'중개사 메모를 작성해주세요'} />,
  },
  {
    modalType: 'findshare_cancel_write', // 작성 취소
    component: <CommonConfirm align="center" txt={`작성내용이 사라집니다. 취소하시겠습니까?`} />,
  },
  {
    modalType: 'coagency_import_sc_list', //이실장 매물 연동 단지 목록 모달
    component: <LegacyItem />,
  },
  {
    modalType: 'coagency_import_input', //이실장 매물 연동 추가정보 입력 bottom sheet
    component: <LegacyInput />,
  },
  {
    modalType: 'coagency_import_all_confirm', //이실장 매물 연동 추가정보 입력 bottom sheet
    component: <LegacyAllModal />,
  },
  {
    modalType: 'coagency_import_all_error', //이실장 매물 연동 오류 모달
    component: <LegacyAllModal />,
  },
  {
    modalType: 'coagency_import_all_lading', //이실장 매물 연동 완료 모달
    component: <LegacyAllModal />,
  },
];
