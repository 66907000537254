import Button from 'components/common/Button/Button';
import { Link, useNavigate } from 'react-router-dom';
import CommonStyles from 'styles/common.module.css';
import { useRef, useState, useCallback, ChangeEvent } from 'react';

const MyGroup = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<string | null>(null);

  // 파일선택 창 띄우기
  const handleClickAlbum = useCallback(() => {
    const el = document.getElementById('comment_file');
    if (el) {
      el.click();
    }
  }, []);

  // 이미지 선택
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      // 용량 체크
      const maxSize = 10 * 1024 * 1024; // 10MB (10 * 1024 * 1024 bytes)

      if (selectedFile.size > maxSize) {
        alert('파일 크기는 10MB 이하여야 합니다.');
        e.target.value = ''; // 파일 선택 취소
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        setFile(e.target?.result as string); // Optional chaining을 사용하여 null 체크
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <>
      {file && (
        <div className={CommonStyles['img-box']}>
          <img src={file} alt="미리보기 사진" />
        </div>
      )}
      <Button className={CommonStyles.none} onClick={handleClickAlbum}>
        <i className={CommonStyles['icon-album']}></i>
      </Button>
      <input
        type="file"
        id="comment_file"
        ref={fileInputRef}
        key={Date.now()} // Change the key when the component re-renders
        style={{ display: 'none' }}
        accept="image/*"
        onChange={handleImageChange}
      />
    </>
  );
};

export default MyGroup;
