import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSubView: false,
  subViewUrl: '',
};

/**
 * @description global
 * @Class
 * @category redux
 * @subcategory slice
 */
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    openSubView: (state, action) => {
      state.isSubView = true;
      state.subViewUrl = action.payload;
    },
    closeSubView: (state) => {
      state.isSubView = false;
      state.subViewUrl = initialState.subViewUrl;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { openSubView, closeSubView } = globalSlice.actions;

export default globalSlice;
