/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';

/**
 * @description component 특정영역 클릭 관련 hook
 * @Class
 * @category hooks
 * @subcategory common
 */
const useOutsideClick = (callback: () => void, exceptRef?: any, exceptRefOtherRef?: any) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !exceptRef.current.contains(event.target) &&
        !exceptRefOtherRef?.current?.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
};

export default useOutsideClick;
