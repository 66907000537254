import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { IAddressParams } from '<coagency>';
import network from 'utils/network';
// configs
import * as Config from 'configs/configs';
import { IMixedKeyValue } from '<modal>';
import { cloneDeep } from 'lodash';
// api
import * as APIS from 'api/group';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import WritingFindShareDetail from './WritingFindShareDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { moveMain, onClose } from 'utils';
import ScrollLoader from 'components/common/Loader/ScrollLoader';

const initState = {
  code: '',
  cd: '',
  name: '',
  sido: '',
  gugun: '',
  dong: '',
};

const TradeTypeList = [
  {
    name: '매매',
    value: 'S',
  },
  {
    name: '전세',
    value: 'L',
  },
  {
    name: '월세',
    value: 'M',
  },
  {
    name: '단기임대',
    value: 'T',
  },
];

/**
 * @description 구합니다 작성 화면 F.C
 * @Class
 * @category Pages
 * @subcategory 구합니다 게시판
 * @component
 * @returns {JSX.Element}
 */
const WritingFindShare = () => {
  const historyPath = useAppSelector((state: RootState) => state.user.historyPath);

  const param = useParams();
  const { id = '' } = param;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');
  const { pathname } = location;
  const hasReturnNav = Config.PATHURLLIST.find((k) => pathname.includes(k.url))?.returnNav;
  const backUrl: string | number = Config.PATHURLLIST.find((k) => pathname.includes(k.url))?.backUrl || -1;
  const pathName = new URL(window.location.href).pathname;
  // 구합니다 단계별 데이터
  const [stepInfo, setStepInfo] = useState<any>({
    1: {
      title: '',
      content: '',
    },
    2: {
      areaList: [
        {
          step: 1,
          isLast: true,
          step1: {
            code: '',
            cd: '',
            name: '',
            sido: '',
            gugun: '',
            dong: '',
          },
          step2: {
            code: '',
            cd: '',
            name: '',
            sido: '',
            gugun: '',
            dong: '',
          },
          step3: {
            code: '',
            cd: '',
            name: '',
            sido: '',
            gugun: '',
            dong: '',
          },
          step4: {
            code: '',
            cd: '',
            name: '',
            sido: '',
            gugun: '',
            dong: '',
          },
        },
      ],
    },
    3: {
      trade_type: '',
    },
    4: {
      expireDate: 30,
      tel_display: 'A',
      status_share: 'Y',
      shareIds: [
        {
          type: 'P', // 공유타입 (필수) - P: 전체, G: 모임
          group_id: null, // 모임 id (필수) type이 'P' 경우 null
        },
      ],
    },
    5: {
      type: '',
    },
    6: {},
  });

  // loading
  const [isLoading, setLoading] = useState(false);
  // 필수 정보 등록 후 state로 관리 => 추가 정보 입력할 때 필요
  const [temp, setTemp] = useState<IMixedKeyValue>({});
  // 현재 소재지 index
  const [selected, setSelected] = useState(0);
  // 소재지 데이터
  const [addrList, setAddrList] = useState<IMixedKeyValue[]>([]);
  // 공유할 모임 리스트
  const [groupList, setGroupList] = useState<IMixedKeyValue[]>([]);
  // 단계
  const [step, setStep] = useState<number>(1);
  // textarea focus
  const textAreaRef = useRef<any>(null);
  // 중복 클릭 방지
  const clickRef = useRef<boolean>(false);
  // 임시저장 여부
  const [isTemp, setIsTemp] = useState<boolean>(false);
  // 첫번째 step bottom button css 컨트롤
  const [bottom, setBottom] = useState<boolean>(false);

  useEffect(() => {
    const handleTouchMove = (e: any) => {
      e.preventDefault();
    };

    const inputs = document.querySelectorAll('input, textarea');

    const disableTouchScroll = () => {
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
    };

    const enableTouchScroll = () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };

    if (step === 1) {
      inputs.forEach((input) => {
        input.addEventListener('focus', disableTouchScroll);
        input.addEventListener('blur', enableTouchScroll);
      });
    }

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', disableTouchScroll);
        input.removeEventListener('blur', enableTouchScroll);
      });
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [step]);

  useEffect(() => {
    if (step === 2) {
      // 소재지 단계로 오면 주소(시) 데이터 조회
      if (addrList.length === 0) {
        getAddress('sido', { si_code: null });
      }
    }

    if (step === 3) {
      if (groupList.length === 0) {
        getMyGroupList();
      }
    }
  }, [step]);

  useEffect(() => {
    if (groupId) {
      const copyState = cloneDeep(stepInfo);

      copyState['4']['shareIds'] = [
        {
          type: 'G',
          group_id: +groupId,
        },
      ];

      setStepInfo(copyState);
    }
  }, [groupId]);

  /**
   * @description 공유할 모임 목록 가져오기(내가 가입한 공동중개 모임)
   */
  const getMyGroupList = async () => {
    const res = await APIS.getMyGroupList('/offers/mygroups');

    if (res.length > 0) {
      setGroupList([...res]);
    }
  };

  /**
   * @description 주소 api 호출
   * @param {string} type  호출 type sido, guGun, eupMyeonDong, li, dong
   * @param {object} params {si_code: string, gu_code: string, dong_code: string }
   * @returns {Promise<void>}
   */
  const getAddress = async (type: string, params: IAddressParams, index?: any): Promise<void> => {
    if (clickRef.current) return;
    clickRef.current = true;
    setAddrList([]);
    try {
      const resAddr = await network().coagency().postAddress(Config.API_URL.POST_ADDR_LIST, JSON.stringify(params));
      const { data, status } = resAddr;
      if (status === 200) {
        if (type === 'li' && data.length > 0) {
          if (index !== null || index !== undefined) {
            setAddrList([...data]);
          }
        } else {
          setAddrList([...data]);
        }
      }
    } catch (err) {
      console.error('err', err);
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 다음 스텝으로 이동
   * @returns {void}
   */
  const handleClickNext = () => {
    if (!isBtn) {
      setStep((prev) => prev + 1);
    }
  };

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, max = 1000) => {
    const { name, value } = e.target;
    const copyState = cloneDeep(stepInfo);

    if (value.length >= max) return;

    copyState[`${step}`][name] = value;

    setStepInfo(copyState);
  };

  /**
   * @description 소재지 추가 버튼
   * @returns {void}
   */
  const handleClickAddAddr = () => {
    const copyState = cloneDeep(stepInfo);
    if (stepInfo[2].areaList.length >= 5) {
      dispatch(
        openModal({
          isOpen: true,
          modalType: 'alert_custom',
          data: {
            txt: '소재지는 최대 5개까지 추가 가능합니다.',
          },
        }),
      );
      return;
    }

    const idx = stepInfo[2].areaList.length - 1;

    if (stepInfo[2].areaList[idx].step1.code === '') return;
    copyState['2'].areaList.push({
      step: 1,
      isLast: true,
      step1: {
        code: '',
        cd: '',
        name: '',
        sido: '',
        gugun: '',
        dong: '',
      },
      step2: {
        code: '',
        cd: '',
        name: '',
        sido: '',
        gugun: '',
        dong: '',
      },
      step3: {
        code: '',
        cd: '',
        name: '',
        sido: '',
        gugun: '',
        dong: '',
      },
      step4: {
        code: '',
        cd: '',
        name: '',
        sido: '',
        gugun: '',
        dong: '',
      },
    });

    getAddress('sido', { si_code: null });
    setSelected(stepInfo[2].areaList.length);
    setAddrList([]);
    setStepInfo(copyState);
  };

  /**
   * @description 이전 페이지로 이동
   * @returns {void}
   */
  const handleClickPrev = () => {
    const isBrokerInfo = pathName === '/feed/brokerinfo' ? true : false;
    if (isTemp && step !== 6) return;
    if (step === 1) {
      if (document.referrer.includes(import.meta.env.VITE_WEB_DOMAIN) && window.history.state.idx === 0) {
        navigate(backUrl as number);
      } else {
        if (!window.history.state || window.history.state.idx === 0 || isBrokerInfo) {
          onClose();
        } else {
          if (hasReturnNav) {
            navigate(hasReturnNav);
          } else {
            navigate(backUrl as number);
          }
        }
      }
    }
    setStep((prev) => prev - 1);
  };

  /**
   * @description 소재지 선택
   * @param {any} data 소재지 데이터
   * @param {number} index 소재지 index
   * @returns {void}
   */
  const handleClickSeletedArea = (data: IMixedKeyValue, index: number) => {
    if (clickRef.current) return;
    const copyData = cloneDeep(stepInfo);
    const currentStep = copyData['2'].areaList[selected].step;
    const currentCd = copyData['2'].areaList[selected][`step${currentStep}`].cd;
    if (currentStep && data) {
      const { name, code, cd } = data;
      if (currentCd !== cd) {
        if (currentStep === 1) {
          copyData['2'].areaList[selected][`step2`] = { ...initState };
          copyData['2'].areaList[selected][`step3`] = { ...initState };
          copyData['2'].areaList[selected][`step4`] = { ...initState };
        }
        if (currentStep === 2) {
          copyData['2'].areaList[selected][`step3`] = { ...initState };
          copyData['2'].areaList[selected][`step4`] = { ...initState };
        }
        if (currentStep === 3) {
          copyData['2'].areaList[selected][`step4`] = { ...initState };
        }
      }

      // 앞에 소재지가 바뀔경우 뒤에 소재지 정보 초기화
      copyData['2'].areaList[selected][`step${currentStep}`].code = code;
      copyData['2'].areaList[selected][`step${currentStep}`].name = name;
      copyData['2'].areaList[selected][`step${currentStep}`].cd = cd;

      if (currentStep === 1) {
        copyData['2'].areaList[selected][`step${currentStep}`].sido = data.cd;
      }

      if (currentStep === 2) {
        copyData['2'].areaList[selected][`step${currentStep}`].gugun = data.cd;
      }
      if (currentStep === 3) {
        copyData['2'].areaList[selected][`step${currentStep}`].dong = data.cd;
      }
      if (currentStep <= 2) {
        copyData['2'].areaList[selected].step = currentStep + 1;
      }

      if (currentStep === 1) {
        getAddress('guGun', { si_code: data?.cd });
      }

      if (currentStep === 2) {
        getAddress('eupMyeonDong', { gu_code: data?.cd });
      }

      if (currentStep === 3) {
        if (
          !checkLastCharacter(copyData['2'].areaList[index].step3.name, '동') &&
          copyData['2'].areaList[index].step3.name !== ''
        ) {
          getAddress('li', { dong_code: data?.cd }, index);
          copyData['2'].areaList[index].step = copyData['2'].areaList[index].step + 1;
        }
      }

      setStepInfo(copyData);
    }
  };

  /**
   * @description 마지막 문자열 체크
   * @param {string} str  문자열
   * @param {string} character  비교할 문자 값
   * @returns {void}
   */
  const checkLastCharacter = (str: string, character: string) => {
    return str[str.length - 1] === character;
  };

  /**
   * @description 마지막 스텝의 지역 코드값 뽑아오기
   * @param {array} areaList  소재지 목록
   * @returns {void}
   */
  const extractAreaCode = (areaList: any[]) => {
    const lastStepCodes: any[] = [];
    areaList.forEach((area) => {
      let lastStep = null;
      for (let i = 4; i >= 1; i--) {
        if (area[`step${i}`].code !== '') {
          lastStep = area[`step${i}`];
          break;
        }
      }

      if (lastStep !== null) {
        lastStepCodes.push(lastStep.code);
      }
    });
    return lastStepCodes;
  };

  /**
   * @description 소재지 변경
   * @param {string} idx  소재지의 index
   * @param {number} step  선택된 소재지의 step
   * @param {number} deleteIndex  삭제할 소재지의 index
   * @returns {void}
   */
  const handleClickCurrentStep = (idx: number, step: number, deleteIndex?: number) => {
    const copyState = cloneDeep(stepInfo);
    const data = copyState['2'].areaList[idx];

    // if (idx !== selected) return;

    if (step === 1) {
      if (copyState['2'].areaList[idx][`step${step}`].code !== '' && deleteIndex === undefined) {
        copyState['2'].areaList[idx][`step1`] = { ...initState };
        copyState['2'].areaList[idx][`step2`] = { ...initState };
        copyState['2'].areaList[idx][`step3`] = { ...initState };
        copyState['2'].areaList[idx][`step4`] = { ...initState };
      }
      copyState['2'].areaList[idx]['step'] = step;
      setSelected(idx);
      setAddrList([]);
      getAddress('sido', { si_code: null });
    }

    if (step === 2 && data['step2'].cd) {
      if (copyState['2'].areaList[idx][`step${step}`].code !== '' && deleteIndex === undefined) {
        copyState['2'].areaList[idx][`step2`] = { ...initState };
        copyState['2'].areaList[idx][`step3`] = { ...initState };
        copyState['2'].areaList[idx][`step4`] = { ...initState };
      }
      const { step1 } = data;
      copyState['2'].areaList[idx]['step'] = step;
      setSelected(idx);
      setAddrList([]);
      getAddress('guGun', { si_code: step1.sido });
    }

    // if (step === 3 && data['step3'].cd === '') {
    //   console.log('call-1');
    //   if (copyState['2'].areaList[idx][`step${step}`].code !== '') {
    //     copyState['2'].areaList[idx][`step3`] = { ...initState };
    //     copyState['2'].areaList[idx][`step4`] = { ...initState };
    //   }
    //   const { step2 } = data;
    //   setAddrList([]);
    //   getAddress('guGun', { gu_code: step2.gugun });
    // }

    if (step === 3 && data['step3'].cd) {
      if (copyState['2'].areaList[idx][`step${step}`].code !== '' && deleteIndex === undefined) {
        copyState['2'].areaList[idx][`step3`] = { ...initState };
        copyState['2'].areaList[idx][`step4`] = { ...initState };
      }
      const { step2 } = data;
      copyState['2'].areaList[idx]['step'] = step;
      setSelected(idx);
      setAddrList([]);
      getAddress('eupMyeonDong', { gu_code: step2.gugun });
    }

    if (step === 4 && data['step4'].cd === '') {
      if (copyState['2'].areaList[idx][`step${step}`].code !== '' && deleteIndex === undefined) {
        copyState['2'].areaList[idx][`step4`] = { ...initState };
      }
      const { step3, step2 } = data;
      if (checkLastCharacter(step3.name, '동')) {
        copyState['2'].areaList[idx]['step'] = step;
        setSelected(idx);
        setAddrList([]);
        getAddress('eupMyeonDong', { gu_code: step2.gugun });
      } else {
        copyState['2'].areaList[idx]['step'] = step;
        setSelected(idx);
        setAddrList([]);
        getAddress('li', { dong_code: step3.dong }, idx);
      }
    }

    if (step === 4 && data['step4'].cd) {
      if (copyState['2'].areaList[idx][`step${step}`].code !== '' && deleteIndex === undefined) {
        copyState['2'].areaList[idx][`step4`] = { ...initState };
      }
      const { step3, step2 } = data;
      if (checkLastCharacter(step3.name, '동')) {
        copyState['2'].areaList[idx]['step'] = step;
        setSelected(idx);
        setAddrList([]);
        getAddress('eupMyeonDong', { gu_code: step2.gugun });
      } else {
        copyState['2'].areaList[idx]['step'] = step;
        setSelected(idx);
        setAddrList([]);
        getAddress('li', { dong_code: step3.dong }, idx);
      }
    }

    if (deleteIndex) {
      copyState['2'].areaList = copyState['2'].areaList.filter(
        (_: any, itemIndex: number) => itemIndex !== deleteIndex,
      );
    }

    setStepInfo(copyState);
  };

  /**
   * @description 거래유형 변경
   * @param {string} type  유형 타입
   * @returns {void}
   */
  const handleClickTradeType = (type: string) => {
    const copyState = cloneDeep(stepInfo);

    copyState['3']['trade_type'] = type;

    setStep(4);
    setStepInfo(copyState);
  };

  /**
   * @description 매물 노출 기간
   * @param {number} val  기간
   * @returns {void}
   */
  const handleChangeExpireDate = (val: number) => {
    const copyState = cloneDeep(stepInfo);

    copyState['4']['expireDate'] = val;
    setStepInfo(copyState);
  };

  /**
   * @description 매물 공유 옵션
   * @param {string} type  옵션 값
   * @returns {void}
   */
  const handleClickShareType = (type: string) => {
    if (status_share === type) return;
    const copyState = cloneDeep(stepInfo);

    copyState['4']['status_share'] = type;
    setStepInfo(copyState);
  };

  /**
   * @description  매물 공유 게시판 선택
   * @param {any} data 공유 게시판 데이터
   * @returns {void}
   */
  const handleClickShareOption = (data: IMixedKeyValue) => {
    const copyState = cloneDeep(stepInfo);

    let checkList = copyState['4']['shareIds'] || [];

    if (data.type === 'P') {
      if (checkList.filter((item: any) => item.group_id === null).length > 0) {
        checkList = checkList.filter((item: any) => item.type !== 'P');
      } else {
        checkList.push({
          ...data,
        });
      }
    } else {
      if (checkList.filter((item: any) => item.group_id === data.group_id).length > 0) {
        checkList = checkList.filter((item: any) => item.group_id !== data.group_id);
      } else {
        checkList.push({
          ...data,
        });
      }
    }

    copyState['4']['shareIds'] = checkList;

    setStepInfo(copyState);
  };

  /**
   * @description  소재지 삭제
   * @param {number} index 소재지 삭제
   * @returns {void}
   */
  const handleClickDelete = (index: number) => {
    const copyState = cloneDeep(stepInfo);

    copyState['2'].areaList = copyState['2'].areaList.filter((_: any, itemIndex: number) => itemIndex !== index);

    const lastIndex = copyState['2'].areaList.length - 1;
    const currStep = copyState['2'].areaList[lastIndex].step;
    const calcStep = copyState['2'].areaList[lastIndex][`step${currStep}`]?.cd === '' ? 1 : 0;
    const currentStep = copyState['2'].areaList[lastIndex].step - calcStep;

    setSelected(lastIndex);
    setAddrList([]);
    handleClickCurrentStep(lastIndex, currentStep, index);
  };

  /**
   * @description  소재지 선택
   * @param {number} index 소재지 선택
   * @returns {void}
   */
  const handleClickSelected = (index: number) => {
    const copyState = cloneDeep(stepInfo);

    const currStep = copyState['2'].areaList[index].step;
    const calcStep = copyState['2'].areaList[index][`step${currStep}`]?.cd === '' ? 1 : 0;
    const currentStep = copyState['2'].areaList[index].step - calcStep;

    setSelected(index);
    setAddrList([]);
    handleClickCurrentStep(index, currentStep, 1111111111);
  };

  /**
   * @description  매물 정보 노출 여부
   * @param {string} type 매물 정보 노출 여부 타입
   * @returns {void}
   */
  const handleClickCallInfoOption = (type: string) => {
    const copyState = cloneDeep(stepInfo);

    copyState['4']['tel_display'] = type;

    setStepInfo(copyState);
  };

  /**
   * @description  구합니다 등록하기
   * @returns {void}
   */
  const handleClickRegister = async () => {
    const copyState = cloneDeep(stepInfo);
    const checkList = copyState['4']['shareIds'] || [];
    const share = copyState['4'].status_share;

    // 공유정보는 공유함인데 => 공유할 게시판을 선택하지 않을 경우 alert
    if (checkList.length === 0 && share === 'Y') {
      dispatch(
        openModal({
          isOpen: true,
          modalType: 'alert_findshare',
        }),
      );
      return;
    }
    try {
      setLoading(true);

      let payload = {};
      if (copyState['1'].title !== '') payload = { ...payload, title: copyState['1'].title };
      if (copyState['1'].content !== '') payload = { ...payload, content: copyState['1'].content };
      if (copyState['3'].trade_type !== '') payload = { ...payload, deal_type: copyState['3'].trade_type };
      payload = {
        ...payload,
        show_days: copyState['4'].expireDate,
        tel_display: copyState['4'].tel_display,
        status_share: copyState['4'].status_share,
      };
      if (copyState['4'].status_share === 'N') {
        payload = { ...payload, status_share: copyState['4'].status_share, show_days: copyState['4'].expireDate };
      }
      const filterAreas = copyState['2'].areaList.filter((item: any) => item.step1.code !== '');
      // 중복된 지역코드 필터링
      const parseAreas = [...new Set(extractAreaCode(filterAreas))];

      if (parseAreas.length > 0) {
        payload = {
          ...payload,
          areas: [...parseAreas],
        };
      }

      payload = {
        ...payload,
        shares: [...copyState['4'].shareIds],
      };

      const res = await APIS.postFindShareBoard('/seeks', payload);

      if (res) {
        setIsTemp(true);
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'find_share_register_temp',
            action: () => {
              // 상세 정보에서 결과값을 활용
              setTemp({ ...res, '2': stepInfo['2'] });
              setStep(5);
            },
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description 구합니다 글쓰기 취소
   * @returns {void}
   */
  const handleClickCancel = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'findshare_cancel_write',
        data: {
          moveIndex: 3,
        },
      }),
    );
  };

  /**
   * @description 구합니다 글쓰기 취소 confirm modal
   * @returns {void}
   */
  const handleCloseConfirm = () => {
    dispatch(
      openModal({
        modalType: 'coagency_writing_close',
        action: () => handleClose(),
        isOpen: true,
      }),
    );
  };

  /**
   * @description 구합니다 글쓰기 취소 확인 버튼
   * @returns {void}
   */
  const handleClose = () => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/home');
    } else {
      if (id !== '') {
        navigate(`/mygroup/${id}`, {
          replace: true,
        });
      } else {
        moveMain(0, '');
        onClose();
      }
    }
  };

  /**
   * step01 제목 및 상세조건 * 필수
   * step02 매물 소재지 * 필수
   * step03 거래 유형 * 필수
   * step04 매물 공유 설정 * 필수
   * step05 매물 종류 선택
   * step06 매물 정보 등록
   */

  /**
   * @description 단계별 progress bar value
   */
  const stepWidth = useMemo(() => {
    return 33.3 * step;
  }, [step]);

  /**
   * @description 다음 버튼 상태
   */
  const isBtn = useMemo(() => {
    let result = false;
    if (stepInfo['1'].content.length === 0 && stepInfo['1'].title.length === 0) result = true;
    if (step === 3 && stepInfo['3']['trade_type'] === '') result = true;
    if (step === 2 && stepInfo['2']['areaList'][0]['step1'].cd === '') result = true;
    return result;
  }, [step, stepInfo]);

  /**
   * @description trade_type 값
   */
  const tradeType = useMemo(() => {
    const type = stepInfo['3']['trade_type'] || '';
    return type;
  }, [stepInfo]);

  /**
   * @description 4번째 step 값
   */
  const stepData = useMemo(() => {
    return stepInfo['4'];
  }, [step, stepInfo]);

  const { expireDate, status_share = 'Y', shareIds = [], tel_display = 'A' } = stepData;

  const handleInputClick = (event: any) => {
    const targetElement: HTMLElement = event.target;
    if (targetElement && !/Android/i.test(navigator.userAgent)) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <>
      <header>
        <div className={CommonStyles['left']}>
          <button onClick={handleClickPrev}>
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['title']}>구합니다 등록</div>
        <div className={CommonStyles['right']}>
          <button
            className={CommonStyles.close}
            onClick={() => {
              handleCloseConfirm();
            }}
          >
            <i className={CommonStyles['icon-x-bk']}></i>
          </button>
        </div>
      </header>
      {step !== 5 && (
        <div
          className={cn(CommonStyles.container, CommonStyles['hasBottom'])}
          style={{ display: step === 6 ? 'none' : 'block', height: bottom ? '100%' : 'calc(100vh - 8.825rem)' }}
        >
          <div
            className={cn(
              CommonStyles['content'],
              CommonStyles['share-write'],
              step <= 3 && CommonStyles['area-select'],
            )}
          >
            {step <= 3 && (
              <div className={CommonStyles['progress-wrap']}>
                <div className={CommonStyles['progress-bar']}>
                  <p className={CommonStyles['present-state']} style={{ width: `${stepWidth}%` }}></p>
                </div>
              </div>
            )}
            {step === 1 && (
              <p className={CommonStyles['main-title']}>
                구하는 매물 제목과 상세조건을
                <br />
                입력해주세요.
              </p>
            )}
            {step === 2 && <p className={CommonStyles['main-title']}>매물의 소재지를 작성해주세요.</p>}
            {step === 3 && <p className={CommonStyles['main-title']}>거래 유형을 선택해주세요.</p>}
            {step === 4 && (
              <p className={cn(CommonStyles['main-title'], CommonStyles['pt0'])}>
                거의 다왔어요!
                <br />
                매물을 공유할 곳을 선택해주세요.
              </p>
            )}
            {step === 1 && (
              <div className={CommonStyles['insert-item']}>
                <div className={cn(CommonStyles['input-area'], CommonStyles['has-byte'])}>
                  <input
                    onClick={handleInputClick}
                    name="title"
                    type="text"
                    placeholder="구하는 매물 제목을 입력해주세요."
                    maxLength={40}
                    value={stepInfo['1'].title}
                    onChange={(e: any) => handleChangeValue(e, 40)}
                    onFocus={() => {
                      // setBottom(true);
                    }}
                    onBlur={() => {
                      // setBottom(false);
                    }}
                  />
                  <span className={CommonStyles['byte']}>{stepInfo['1'].title.length}/40</span>
                </div>
                <div
                  ref={textAreaRef}
                  className={cn(CommonStyles['textarea-div'], CommonStyles['type2'], CommonStyles['mt4'])}
                  style={{ height: '45vh' }}
                >
                  <textarea
                    style={{ height: '45vh' }}
                    onClick={handleInputClick}
                    id="scroll-action"
                    name="content"
                    placeholder="구하는 매물의 상세조건을 입력해주세요."
                    maxLength={1000}
                    onFocus={() => {
                      if (textAreaRef.current) {
                        textAreaRef.current.classList.add(CommonStyles['on']);
                      }
                      // setBottom(true);
                    }}
                    onBlur={() => {
                      if (textAreaRef.current) {
                        textAreaRef.current.classList.remove(CommonStyles['on']);
                      }
                      //window.scrollTo(0, 0);
                      // setBottom(false);
                    }}
                    value={stepInfo['1'].content}
                    onChange={(e: any) => handleChangeValue(e, 1000)}
                  />
                  <p className={CommonStyles['byte']}>
                    <b>{stepInfo['1'].content.length}</b>/1000
                  </p>
                  <button
                    style={{ position: 'absolute', width: '90%', marginLeft: '1%', marginTop: '20px' }}
                    className={cn(CommonStyles['btn'], CommonStyles['lg'], isBtn && CommonStyles['disabled'])}
                    disabled={isBtn}
                    onClick={handleClickNext}
                  >
                    확인
                  </button>
                </div>
              </div>
            )}
            {step === 2 && (
              <div className={CommonStyles['area-select-form']}>
                <div className={CommonStyles['division']}>
                  {stepInfo['2'].areaList.map((item: IMixedKeyValue, index: number) => {
                    const { step1, step2, step3, step4 } = item;
                    return (
                      <div key={index} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        <div
                          className={cn(
                            CommonStyles['select-step1'],
                            index === selected && stepInfo['2'].areaList.length > 1 && CommonStyles['addr-selected'],
                          )}
                          style={{ userSelect: 'none' }}
                          onClick={() => {
                            if (selected !== index) {
                              handleClickSelected(index);
                            }
                          }}
                        >
                          <button
                            type="button"
                            className={cn(step1.name && CommonStyles['selected'])}
                            onClick={() => {
                              handleClickCurrentStep(index, 1);
                            }}
                          >
                            {step1.name ? step1.name : '시/도'}
                          </button>
                          <i className={CommonStyles['icon-arrow-down-gr']}></i>
                          <button
                            type="button"
                            className={cn(step2.name && CommonStyles['selected'])}
                            onClick={() => handleClickCurrentStep(index, 2)}
                          >
                            {step2.name ? step2.name : '시/군/구'}
                          </button>
                          <i className={CommonStyles['icon-arrow-down-gr']}></i>
                          <button
                            type="button"
                            className={cn(step3.name && CommonStyles['selected'])}
                            onClick={() => handleClickCurrentStep(index, 3)}
                          >
                            {step3.name ? step3.name : '읍/면/동'}
                          </button>
                          {checkLastCharacter(step3.name, '동') === false && step3.name !== '' && (
                            <>
                              <i className={CommonStyles['icon-arrow-down-gr']}></i>
                              <button
                                type="button"
                                className={cn(step4.name && CommonStyles['selected'])}
                                onClick={() => handleClickCurrentStep(index, 4)}
                              >
                                {step4.name ? step4.name : '  선택(리)'}
                              </button>
                            </>
                          )}
                        </div>
                        {stepInfo['2'].areaList.length >= 2 && (
                          <div
                            style={{
                              height: '100%',
                              position: 'absolute',
                              right: -5,
                              top: -15,
                              opacity: 0.7,
                            }}
                          >
                            <button
                              className={cn(CommonStyles['clear-has'], CommonStyles.delete)}
                              style={{ width: '16px', height: '16px', borderRadius: '50%' }}
                              onClick={() => handleClickDelete(index)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className={CommonStyles['division']}>
                  <div
                    className={CommonStyles['select-step2']}
                    style={{ overflow: 'auto !important', height: 'calc( 100% - 20px ) !important' }}
                  >
                    {addrList.map((item: IMixedKeyValue, index: number) => {
                      const { cd } = item;
                      const step = stepInfo['2'].areaList?.[selected]?.step;
                      const currentCd = stepInfo['2'].areaList?.[selected][`step${step}`]?.cd;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={cn(CommonStyles['btn'], cd !== currentCd && CommonStyles['line'])}
                          onClick={() => handleClickSeletedArea(item, selected)}
                        >
                          {item.name}
                        </button>
                      );
                    })}
                    {addrList.length === 0 && (
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'fixed',
                          left: 0,
                          top: 'calc(100vh - 15.5rem - 80px)',
                        }}
                      >
                        <ScrollLoader />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className={cn(CommonStyles['btn-wrap'], CommonStyles['full'])}>
                {TradeTypeList.map((item: { name: string; value: string }, index: number) => {
                  return (
                    <button
                      key={index}
                      className={cn(
                        CommonStyles['btn'],
                        CommonStyles['lg'],
                        tradeType !== item.value && CommonStyles['line'],
                      )}
                      onClick={() => handleClickTradeType(item.value)}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            )}
            {step === 4 && (
              <div className={CommonStyles['section-div']}>
                <section className={CommonStyles['pt0']}>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>매물 노출 기간을 선택해주세요.</p>
                    <div className={cn(CommonStyles['button-list'], CommonStyles['p0'])}>
                      <button className={CommonStyles['radio-box']}>
                        <input
                          type="radio"
                          id="radio15"
                          name="radio15"
                          value="15"
                          checked={expireDate === 15}
                          onChange={() => handleChangeExpireDate(15)}
                        />
                        <label htmlFor="radio15" className={CommonStyles['label-info']}>
                          15일
                        </label>
                      </button>
                      <button className={CommonStyles['radio-box']}>
                        <input
                          type="radio"
                          id="radio30"
                          name="radio30"
                          value="30"
                          checked={expireDate === 30}
                          onChange={() => handleChangeExpireDate(30)}
                        />
                        <label htmlFor="radio30" className={CommonStyles['label-info']}>
                          30일
                        </label>
                      </button>
                    </div>
                  </div>
                </section>
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>연락처 노출을 선택해주세요.</p>
                    <div className={cn(CommonStyles['button-list'], CommonStyles['p0'])}>
                      <button className={cn(CommonStyles['radio-box'])}>
                        <input
                          type="radio"
                          id="radioA"
                          name="radioA"
                          value="radioA"
                          checked={tel_display === 'A'}
                          onChange={() => handleClickCallInfoOption('A')}
                        />
                        <label htmlFor="radioA" className={CommonStyles['label-info']}>
                          모두노출(중개업소 전화번호+휴대폰번호)
                        </label>
                      </button>
                      <button className={cn(CommonStyles['radio-box'])}>
                        <input
                          type="radio"
                          id="radioR"
                          name="radioR"
                          value="radioR"
                          checked={tel_display === 'R'}
                          onChange={() => handleClickCallInfoOption('R')}
                        />
                        <label htmlFor="radioR" className={CommonStyles['label-info']}>
                          중개업소 전화번호만 노출
                        </label>
                      </button>
                      <button className={cn(CommonStyles['radio-box'])}>
                        <input
                          type="radio"
                          id="radioC"
                          name="radioC"
                          value="radioC"
                          checked={tel_display === 'C'}
                          onChange={() => handleClickCallInfoOption('C')}
                        />
                        <label htmlFor="radioC" className={CommonStyles['label-info']}>
                          휴대폰번호만 노출
                        </label>
                      </button>
                    </div>
                  </div>
                </section>
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>공유정보</p>
                    <div className={CommonStyles['btn-wrap']}>
                      <button
                        className={cn(CommonStyles['btn'], status_share === 'Y' && CommonStyles['line'])}
                        onClick={() => handleClickShareType('N')}
                      >
                        공유안함
                      </button>
                      <button
                        className={cn(CommonStyles['btn'], status_share === 'N' && CommonStyles['line'])}
                        onClick={() => handleClickShareType('Y')}
                      >
                        공유함
                      </button>
                    </div>
                    <p className={cn(CommonStyles['sub-txt'], CommonStyles['share-info'])}>
                      *공동중개를 위한 매물공유를 원하시면 공유함을 선택해주세요. 아래 게시판은 복수선택이 가능합니다.{' '}
                    </p>
                    {stepInfo['4']['status_share'] === 'N' && <div style={{ height: '10px' }} />}
                    {stepInfo['4']['status_share'] === 'Y' && (
                      <div className={cn(CommonStyles['button-list'], CommonStyles['p0'])}>
                        <button className={CommonStyles['check-box']}>
                          <input
                            type="checkbox"
                            id="chk11"
                            defaultChecked={shareIds.find((item: any) => item.group_id === null && item.type === 'P')}
                            onChange={() =>
                              handleClickShareOption({
                                type: 'P',
                                group_id: null,
                              })
                            }
                          />
                          <label htmlFor="chk11" className={CommonStyles['label-info']}>
                            전체 게시판 (공동중개)
                          </label>
                        </button>
                        {groupList.map((item, index: number) => {
                          return (
                            <button key={index} className={CommonStyles['check-box']}>
                              <input
                                type="checkbox"
                                id={item.id}
                                defaultChecked={shareIds.filter((el: any) => item.id === el.group_id).length > 0}
                                onChange={() =>
                                  handleClickShareOption({
                                    type: 'G',
                                    group_id: +item.id,
                                  })
                                }
                              />
                              <label htmlFor={item.id} className={CommonStyles['label-info']}>
                                {item.name}
                              </label>
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </section>
              </div>
            )}
          </div>
        </div>
      )}
      <div style={{ display: step !== 5 && step !== 6 ? 'none' : 'block' }}>
        <WritingFindShareDetail
          temp={Object.keys(temp).length > 0 ? temp : stepInfo}
          step={step}
          isTemp={isTemp}
          setLoading={setLoading}
        />
      </div>
      {isLoading && (
        <div className={CommonStyles['loading-container']}>
          <div className={CommonStyles['loading-spinner']} />
        </div>
      )}
      {step !== 6 && (
        <div
          id="bottom-fixed"
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          style={{ background: '#fff', bottom: bottom ? '-28px' : 0 }}
        >
          {step === 2 && (stepInfo['2'].areaList.length > 1 || stepInfo['2'].areaList[0].step2.cd !== '') && (
            <>
              <button
                className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
                onClick={handleClickAddAddr}
              >
                소재지 추가하기
              </button>
              <button
                className={cn(CommonStyles['btn'], CommonStyles['lg'], isBtn && CommonStyles['disabled'])}
                onClick={handleClickNext}
                disabled={isBtn}
              >
                다음
              </button>
            </>
          )}
          {step === 4 && (
            <>
              {/* <button
                  className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
                  onClick={() => {
                    handleClickCancel();
                  }}
                >
                  취소
                </button> */}
              <button
                className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
                onClick={() => {
                  setStep(5);
                }}
              >
                상세 작성
              </button>
              <button className={cn(CommonStyles['btn'], CommonStyles['lg'])} onClick={handleClickRegister}>
                간편등록
              </button>
            </>
          )}
          {step === 5 && (
            <button
              className={cn(CommonStyles['btn'], CommonStyles['lg'])}
              onClick={() => {
                handleClickNext();
              }}
            >
              다음
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default WritingFindShare;
