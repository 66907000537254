import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionCoagency from 'redux/slices/coagency';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

import { moveMain, onClose } from 'utils';
import { isCheckWebview } from 'utils/common';

interface Props {
  type?: string;
  title?: string;
  txt?: string;
  setIsAlertOpen?: (isAlertOpen: boolean) => void;
}

/**
 * @description common alert
 * @Class
 * @category Components
 * @subcategory common modal
 * @component
 * @returns {JSX.Element}
 */
const CommonAlert = (props: Props) => {
  const navigate = useNavigate();
  const { type, title, txt, setIsAlertOpen } = props;

  const dispatch = useAppDispatch();
  const { modalType, data, isOpen, action } = useAppSelector((state: RootState) => state.modal);

  const [open, setOpen] = useState<boolean>(isOpen || false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    if (modalType === 'alert_join_group') {
      action?.();
    }
    if (modalType === 'alert_penalty_error') {
      action?.();
    }
    if (modalType === 'alert_common') {
      action?.();
    }
    if (modalType === 'alert_looking_for_modify_success') {
      action?.();
    }
    if (modalType === 'alert_looking_for_delete_success') {
      action?.();
    }
    if (modalType === 'alert_coagency_modify_success') {
      action?.();
    }
    if (modalType === 'alert_coagency_modify_stop_share') {
      action?.();
    }
    if (modalType === 'alert_coagency_modify_process_success') {
      action?.();
    }
    if (modalType === 'alert_looking_for_save_success') {
      action?.();
    }

    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  const handlePropModalClose = () => {
    setIsAlertOpen!(false);
  };

  /**
   * @description 상세등록 이후 내피드 공유매물로 이동
   */
  const moveToFeed = () => {
    if (isCheckWebview()) {
      moveMain(4, '0');
      onClose();
    } else {
      navigate('/feed');
    }
    dispatch(ActionCoagency.initStep('share'));
    handleModalClose();
    console.log('상세등록이후에 내피드로 이동');
  };

  /**
   * @description 내 공유매물 수정 후 이전페이지로 이동
   */
  const handleConfirm = () => {
    action?.();
    handleModalClose();
  };

  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show, !open && ResetStyles['d-none'])}>
        <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
          <div className={Styles['popup-content']}>
            {modalType === 'alert_file_limit' && <p className={Styles.tit}>{title}</p>}
            <p className={Styles.txt} style={{ whiteSpace: 'pre-line' }}>
              {txt || data?.txt}
            </p>
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            <button
              className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
              onClick={() =>
                type === 'alert_possible_report'
                  ? handlePropModalClose()
                  : type === 'coagency_registration'
                  ? moveToFeed()
                  : type === 'coagency_edit'
                  ? handleConfirm()
                  : handleModalClose()
              }
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonAlert;
