import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { IMixedKeyValue } from '<modal>';

const initialState: IMixedKeyValue = {
  mygroup: [],
  join_condition: [],
  kind: [],
  open: [],
  type: [],
  group: {},
  myInfo: {},
  other: {},
  permissoin: {},
};

/**
 * @description group
 * @Class
 * @category redux
 * @subcategory slice
 */
const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setMyGroup: (state, action) => {
      state.mygroup = action.payload;
    },
    setGroupInfo: (state, action) => {
      state.other = action.payload.other;
      state.group = action.payload.group;
      state.myInfo = action.payload.myInfo;
      state.permissoin = action.payload.permissoin;
    },
    setGroupConfig: (state, action) => {
      state.join_condition = action.payload.join_condition;
      state.kind = action.payload.kind;
      state.open = action.payload.open;
      state.type = action.payload.type;
    },
    initGroupInfo: (state) => {
      state.join_condition = initialState.join_condition;
      state.kind = initialState.kind;
      state.open = initialState.open;
      state.type = initialState.type;
      state.other = initialState.other;
      state.group = initialState.group;
      state.myInfo = initialState.myInfo;
      state.permissoin = initialState.permissoin;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setMyGroup, setGroupConfig, setGroupInfo, initGroupInfo } = groupSlice.actions;

export default groupSlice;
