import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { ICoAgencyDetail } from '<coagency>';
import { initAddr, initSquareSelect, initRelation } from 'pages/coagency/initState';

const initialState = {
  selectedAddr: initAddr,
  regState: {
    id: '',
    areaCd: '',
    houseNo: '',
    offerings_main_gbn: '',
    offerings_gbn: '',
    addr: '',
    sidoAddr: initAddr,
    guGunAddr: initAddr,
    eupMyeonDongAddr: initAddr,
    liAddr: initAddr,
    redevelopAddr: initAddr,
    complexAddr: initAddr,
    dongAddr: initAddr,
    hoAddr: null,
    dong_hide: 'N',
    addrGbn: 'N',
    forSaleRight: '', // 분양권 (A: 일반분양권 , B: 조합원분양권)
    streetStart: '',
    streetEnd: '',
    detailUse: 'Y', // 상세주소 사용 'Y',  없음 'N'
    detailAddr: '',
    offerGbn: 'S',
    price_info: [],
    sellPrc: null, // 매매가
    leasePrc: null, // 전세가
    depositPrc: null, // 보증금
    monthlyPrc: null, // 월세
    salePrc: null, // 분양권 - 분양가
    premiumPrc: null, // 분양권 - 프리미엄가
    optionPrc: null, // 분양권 - 분양옵션가
    squareSelect: initSquareSelect, // 면적선택 (공동주택)
    supArea: null, // 공급면적
    conArea: null, // 계약면적
    excArea: null, // 전용면적
    lotArea: null, // 대지면적
    totArea: null, // 연면적
    bulArea: null, // 건축면적
    applyFloor: null, // 해당층
    underFloor: null, // 지하층
    groundFloor: null, // 지상층
    totFloor: null, // 총층
    roomCnt: null,
    bathCnt: null,
    moveGbn: 'A', // 입주가능일 타입 - A:즉시입주,  B:초순,  C:중순,  D:하순, S: 날짜 지정
    isMoveChg: 'N', // 입주가능일 협의여부
    moveYmd: new Date(),
    memo: '', // 매물특징
    detailMemo: '', // 매물설명
    statusDeal: 'normal', // 상태, normal : 정상, hold : 보류, complete : 거래완료
    customerName: '', // 비공개정보 고객명
    ownerType: initRelation, // 비공개정보 관계  01 ~ 99
    phoneNum: '',
    hiddenMemo: '',
    status_share: 'Y', // 공유여부, Y: 공유, N: 공유안함  // 공유함 default
    shares: [], // 모임 id, type이 'G' 경우 설정
    telDisplay: '', // 연락처 노출, A : 모두, R : 전화번호, C : 휴대폰번호
    statusShareMap: 'Y', // 지도노출여부, Y: 지도노출, N: 지도노출안함
    show_days: null,
    isPriceChange: false,
  },
  moveInDate: {
    move_gbn: '',
    move_ymd: '',
    is_move_chg: '',
  },
};

/**
 * @description 공동중개 공유매물상세 coagecnydetail
 * @Class
 * @category redux
 * @subcategory slice
 */
export const coagencydatail = createSlice({
  name: 'coagencydetail',
  initialState,
  reducers: {
    setRegState: (state, actions) => {
      state.regState = Object.assign(state.regState, actions.payload);
    },
    setComplex: (state, actions) => {
      state.selectedAddr = {
        ...state.selectedAddr,
        complexAddr: actions.payload.complexAddr,
      };
    },
    setDong: (state, actions) => {
      state.selectedAddr = {
        ...state.selectedAddr,
        dongAddr: actions.payload.dongAddr,
      };
    },
    setSquare: (state, actions) => {
      state.selectedAddr = {
        ...state.selectedAddr,
        square: actions.payload.square,
      };
    },
    setDate: (state, actions) => {
      state.moveInDate = actions.payload.moveInDate;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setRegState, setComplex, setDong, setSquare, setDate } = coagencydatail.actions;

export default coagencydatail;
