import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { userSelector } from 'redux/slices/user';
import { useSelector } from 'react-redux';

import network from 'utils/network';
import { IJuridical, IJuridicalMember, IJuridicalSearch } from '<broker>';
import BrokerListCompany from './BrokerListCompany';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionUser from 'redux/slices/user';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

const BrokerList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //넘어온값 확인
  const location = useLocation();
  const { state } = location;
  const [param, setParam] = useState(state);
  useEffect(() => {
    if (!param?.jurirno || !param?.registration_number || !param?.broker_agree) {
      navigate('/broker/company');
    }
  }, []);

  const [juridicalList, setJuridicalList] = useState<IJuridical[]>(state?.juridicalList);
  useEffect(() => {
    if (juridicalList.length < 1) {
      navigate('/broker/empty', {
        state: {
          registration_number: param?.registration_number,
          jurirno: param?.jurirno,
        },
      });
    }
  }, [juridicalList]);

  //중개업소 선택시 직원정보 조회
  const [companySelect, setCompanySelect] = useState<string>('');
  const handleSelectJuridical = async (_regNo: string) => {
    if (companySelect !== _regNo) {
      dispatch(onLoad());
      setCompanySelect(_regNo);
      setMemberSelect('');
      juridicalList.map((juridical: IJuridical) => {
        juridical.member = null;
      });

      const req: IJuridicalSearch = {
        jurirno: _regNo,
      };
      await network()
        .broker()
        .getJuridicalMember(req)
        .then((res) => {
          dispatch(offLoad());
          if (res.data.length > 0) {
            const juridicalMember: IJuridicalMember[] = res.data;
            juridicalList.map((juridical: IJuridical) => {
              if (juridical.regNo === _regNo) {
                juridical.member = juridicalMember;
              }
            });
            setJuridicalList([...juridicalList]);
          } else {
            dispatch(
              ActionModal.openModal({
                modalType: 'broker_alert',
                isOpen: true,
                data: { txt: '해당 공인중개사의 직원이 존재하지 않습니다.' },
              }),
            );
          }
        })
        //조회 에러
        .catch((err) => {
          dispatch(offLoad());
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: err.response.data.message },
            }),
          );
        });
    }
  };

  //중개업소의 직원선택시
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [memberSelect, setMemberSelect] = useState<string>('');
  const handleSelectMember = (_brkrNm: string) => {
    setMemberSelect(_brkrNm);
  };

  useEffect(() => {
    if (companySelect && memberSelect) {
      setIsRegister(true);
    } else {
      setIsRegister(false);
    }
  }, [companySelect, memberSelect]);

  const { currentUser, me, brokerVerified } = useSelector(userSelector);
  const handleAuthConfirm = async () => {
    if (isRegister && companySelect && memberSelect) {
      if (me.name != memberSelect) {
        dispatch(
          ActionModal.openModal({
            modalType: 'broker_alert',
            isOpen: true,
            data: { txt: "선택하신 이름이\n'" + me.name + "'님과 일치하지 않습니다.\n다시 선택해 주세요." },
          }),
        );
        return;
      }

      dispatch(onLoad());
      await network()
        .broker()
        .postVerify({
          registration_number: param?.registration_number.replaceAll('-', ''),
          jurirno: companySelect,
          brkrNm: memberSelect,
        })
        .then(async (res) => {
          dispatch(offLoad());
          //내정보 갱신
          const resMe = await network().sso().me();
          dispatch(ActionUser.setMe(resMe.data));

          if (resMe.data.verify_temp) {
            navigate('/broker/temporary');
          } else {
            navigate('/broker/complete');
          }
        })
        .catch((err) => {
          dispatch(offLoad());
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: err.response.data.message },
            }),
          );
        });
    }
  };
  return (
    <>
      <div className={cn(CommonStyles.container, companySelect.length > 0 && CommonStyles.hasBottom)}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={CommonStyles['content-div']}>
            <p className={cn(CommonStyles['page-txt'], ResetStyles['d-flex'], CommonStyles['ja-b'])}>
              찾으시는 개설등록번호가 없으신가요?
              <Link to="/broker/request" className={CommonStyles['co-bl']}>
                개설번호 요청하기
              </Link>
            </p>
            <ul className={CommonStyles['office-list']}>
              {juridicalList.map((juridical: IJuridical) => (
                <BrokerListCompany
                  key={juridical.regNo}
                  juridical={juridical}
                  companySelect={companySelect}
                  handleSelectJuridical={handleSelectJuridical}
                  handleSelectMember={handleSelectMember}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
      {companySelect.length > 0 && (
        <div className={cn(CommonStyles['btn-wrap'], CommonStyles.relative)}>
          <button
            className={cn(CommonStyles.btn, CommonStyles.lg, !isRegister ? CommonStyles.disabled : '')}
            onClick={handleAuthConfirm}
          >
            확인
          </button>
        </div>
      )}
    </>
  );
};

export default BrokerList;
