import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionModal from 'redux/slices/modal';
import * as Config from 'configs/configs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import network from 'utils/network';
import { IMixedKeyValue } from '<modal>';
import * as Index from 'utils/index';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { offLoad, onLoad } from 'redux/slices/loading';
import { TOffers } from '@types';
import { useLocation } from 'react-router-dom';

/**
 * @description 공동중개 modal
 * @Class
 * @category Components
 * @subcategory coagency modal
 * @component
 * @returns {JSX.Element}
 */
const LegacyInput = () => {
  const dispatch = useAppDispatch();
  const { modalType, data, action } = useAppSelector((state: RootState) => state.modal);

  const selectedOffers = data?.selectedOffers;

  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const shares = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.shares);
  // 내그룹목록
  const [mygroupList, setMyGroupList] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');

  /**
   * @description 내 모임목록 가져오기
   * @returns {Promise<void>}
   */
  const getMyGroupList = async (): Promise<void> => {
    try {
      const resAddr = await network().coagency().getCommonList(Config.API_URL.GET_OFFERS_MYGROUP);
      const { data, status } = resAddr;
      if (status === 200) {
        let _data = data;
        const defaultShare = {
          id: 99999,
          group_id: 99999,
          name: '전체 공유 (공동중개 게시판)',
          code: 'P',
        };
        _data = [defaultShare, ..._data];

        const _shares = !groupId
          ? _.uniqBy([defaultShare], 'id')
          : [
              {
                code: '',
                group_id: undefined,
                ...data.find((item: IMixedKeyValue) => item.id === Number(groupId)),
              },
            ];

        setMyGroupList(_data);
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              coagencyRegData: {
                ...shareRegStep.coagencyRegData,
                shares: _shares,
              },
            },
          }),
        );
      }
    } catch (err) {
      console.error('err', err);
      if (err) {
        setMyGroupList([]);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getMyGroupList();
    };
    getData();
  }, []);

  const handleModalClose = () => {
    dispatch(ActionCoagency.initStep('share'));
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  const handleRegister = async () => {
    dispatch(onLoad());
    const params = {
      offers: selectedOffers,
      status_share: shareRegStep?.coagencyRegData?.status_share, // 공유여부, Y: 공유, N: 공유안함
      shares: shares?.map((k: IMixedKeyValue) => {
        return {
          type: k.group_id === 99999 ? 'P' : 'G',
          group_id: k.group_id === 99999 ? null : k.group_id || k.id,
        };
      }),
      tel_display: shareRegStep?.coagencyRegData?.tel_display, // 연락처 노출, A : 모두, R : 전화번호, C : 휴대폰번호
      show_days: 30, // shareRegStep?.coagencyRegData?.show_days, // {/* TODO 노출기간 수정 - 8. mo : 공유매물 > 작성 > 이실장매물 불러오기 > 바로등록 버텀 시트 팝업  */}
    };
    const body = JSON.stringify(params);
    const res = await network().coagency().importMultiConvert(body);
    const { data, status } = res;

    dispatch(ActionCoagency.initStep('share'));
    dispatch(offLoad());

    handleModalClose();
    //완료되면 내피드로 이동
    Index.moveMain(4, '0');
    Index.onClose();
  };

  const isIphone = /iPhone/i.test(navigator.userAgent);
  const paddingBottom = isIphone ? '70px' : undefined;

  return (
    <>
      <div className={Styles.show}>
        <div className={cn(Styles['popup-container'], Styles['bottom-sheet-popup'])}>
          <div className={Styles['popup-header']}>
            <p className={Styles['pop-tit']}>공유정보를 입력해주세요</p>
            <button className={Styles.close} onClick={handleModalClose} />
          </div>
          <div className={Styles['popup-content']}>
            <div className={cn(CommonStyles['share-write'], CommonStyles.p0)}>
              <div className={CommonStyles['section-div']}>
                {/* TODO 노출기간 수정 - 8. mo : 공유매물 > 작성 > 이실장매물 불러오기 > 바로등록 버텀 시트 팝업  */}
                {/* <section>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>매물 노출 기간을 선택해주세요.</p>
                    <div className={Styles['button-list']}>
                      <button className={CommonStyles['radio-box']}>
                        <input
                          type="radio"
                          id="radio1"
                          name="radio_show_days"
                          value={15}
                          checked={shareRegStep.coagencyRegData.show_days === 15}
                          onChange={(e) =>
                            dispatch(
                              ActionCoagency.setStep({
                                shareRegStep: {
                                  ...shareRegStep,
                                  coagencyRegData: {
                                    ...shareRegStep.coagencyRegData,
                                    show_days: Number(e.target.value),
                                  },
                                },
                              }),
                            )
                          }
                        />
                        <label htmlFor="radio1" className={CommonStyles['label-info']}>
                          15일
                        </label>
                      </button>
                      <button
                        className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                        aria-expanded="false"
                      >
                        <input
                          type="radio"
                          id="radio2"
                          name="radio_show_days"
                          value={30}
                          checked={shareRegStep.coagencyRegData.show_days === 30}
                          onChange={(e) =>
                            dispatch(
                              ActionCoagency.setStep({
                                shareRegStep: {
                                  ...shareRegStep,
                                  coagencyRegData: {
                                    ...shareRegStep.coagencyRegData,
                                    show_days: Number(e.target.value),
                                  },
                                },
                              }),
                            )
                          }
                        />
                        <label htmlFor="radio2" className={CommonStyles['label-info']}>
                          30일
                        </label>
                      </button>
                    </div>
                  </div>
                </section> */}
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>연락처 노출을 선택해주세요.</p>
                    <div className={Styles['button-list']}>
                      <button className={CommonStyles['radio-box']}>
                        <input
                          type="radio"
                          id="radio11"
                          name="radio_tel_display"
                          value="A"
                          checked={shareRegStep.coagencyRegData.tel_display === 'A'}
                          onChange={(e) =>
                            dispatch(
                              ActionCoagency.setStep({
                                shareRegStep: {
                                  ...shareRegStep,
                                  coagencyRegData: {
                                    ...shareRegStep.coagencyRegData,
                                    tel_display: e.target.value,
                                  },
                                },
                              }),
                            )
                          }
                        />
                        <label htmlFor="radio11" className={CommonStyles['label-info']}>
                          모두노출(중개업소 전화번호+휴대폰번호)
                        </label>
                      </button>
                      <button
                        className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                        aria-expanded="false"
                      >
                        <input
                          type="radio"
                          id="radio12"
                          name="radio_tel_display"
                          value="R"
                          checked={shareRegStep.coagencyRegData.tel_display === 'R'}
                          onChange={(e) =>
                            dispatch(
                              ActionCoagency.setStep({
                                shareRegStep: {
                                  ...shareRegStep,
                                  coagencyRegData: {
                                    ...shareRegStep.coagencyRegData,
                                    tel_display: e.target.value,
                                  },
                                },
                              }),
                            )
                          }
                        />
                        <label htmlFor="radio12" className={CommonStyles['label-info']}>
                          중개업소 전화번호만 노출
                        </label>
                      </button>
                      <button className={CommonStyles['radio-box']}>
                        <input
                          type="radio"
                          id="radio13"
                          name="radio_tel_display"
                          value="C"
                          checked={shareRegStep.coagencyRegData.tel_display === 'C'}
                          onChange={(e) =>
                            dispatch(
                              ActionCoagency.setStep({
                                shareRegStep: {
                                  ...shareRegStep,
                                  coagencyRegData: {
                                    ...shareRegStep.coagencyRegData,
                                    tel_display: e.target.value,
                                  },
                                },
                              }),
                            )
                          }
                        />
                        <label htmlFor="radio13" className={CommonStyles['label-info']}>
                          휴대폰번호만 노출
                        </label>
                      </button>
                    </div>
                  </div>
                </section>
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <div className={CommonStyles['sub-title']}>공유정보</div>
                    <div className={CommonStyles['btn-wrap']}>
                      <button
                        type="button"
                        className={cn(
                          CommonStyles.btn,
                          shareRegStep.coagencyRegData.status_share !== 'N' && CommonStyles.line,
                        )}
                        onClick={() =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyRegData: {
                                  ...shareRegStep.coagencyRegData,
                                  status_share: 'N',
                                },
                              },
                            }),
                          )
                        }
                      >
                        공유안함
                      </button>
                      <button
                        type="button"
                        className={cn(
                          CommonStyles.btn,
                          shareRegStep.coagencyRegData.status_share !== 'Y' && CommonStyles.line,
                        )}
                        onClick={() => {
                          const el = document.getElementById('groupList');
                          el?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyRegData: {
                                  ...shareRegStep.coagencyRegData,
                                  status_share: 'Y',
                                },
                              },
                            }),
                          );
                        }}
                      >
                        공유함
                      </button>
                    </div>
                    <p className={cn(Styles['sub-txt'], Styles['text-left'])}>
                      *공동중개를 위한 매물공유를 원하시면 공유함을 선택해주세요. 아래 게시판은 복수선택이 가능합니다.
                    </p>
                    <div className={Styles['button-list']}>
                      {mygroupList.length > 0 &&
                        shareRegStep.coagencyRegData.status_share === 'Y' &&
                        mygroupList.map((item: IMixedKeyValue) => {
                          return (
                            <button key={item.id} className={CommonStyles['check-box']}>
                              <input
                                type="checkbox"
                                id={`share_${item.id}`}
                                checked={shares.map((k: IMixedKeyValue) => k.id).includes(item.id)}
                                value={item.id}
                                onChange={() => {
                                  dispatch(
                                    ActionCoagency.setStep({
                                      shareRegStep: {
                                        ...shareRegStep,
                                        coagencyRegData: {
                                          ...shareRegStep.coagencyRegData,
                                          shares: [...shares].some((j: IMixedKeyValue) => j.id === item.id)
                                            ? [...shares].filter((t: IMixedKeyValue) => t.id !== item.id)
                                            : [
                                                ...shares,
                                                {
                                                  id: item.id,
                                                  name: item.name,
                                                  group_id: item.group_id,
                                                  code: item.code || '',
                                                },
                                              ],
                                        },
                                      },
                                    }),
                                  );
                                }}
                              />
                              <label htmlFor={`share_${item.id}`} className={CommonStyles['label-info']}>
                                {item.name}
                              </label>
                            </button>
                          );
                        })}
                    </div>
                  </div>
                </section>
              </div>
              <div
                className={cn(CommonStyles['btn-wrap'], CommonStyles['margin-top'])}
                style={{ paddingBottom }}
              >
                <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleModalClose}>
                  취소
                </button>
                <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleRegister}>
                  불러오기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegacyInput;
