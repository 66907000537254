import { useAppDispatch } from 'redux/store';
import { userSelector } from 'redux/slices/user';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

const BrokerComplete = () => {
  const { me } = useSelector(userSelector);

  const goHome = () => {
    if (window.webkit) {
      window.webkit.messageHandlers.moveTab.postMessage(JSON.stringify({ tabIndex: 0, parameter: '' }));
      window.webkit.messageHandlers.close.postMessage();
    } else if (window.Android) {
      window.Android.moveTab(JSON.stringify({ tabIndex: 0, parameter: '' }));
      window.Android.close();
    }
  };

  return (
    <>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={cn(CommonStyles['content-div'], CommonStyles['complete'])}>
            <div className={ResetStyles['ta-c']}>
              <i className={CommonStyles['img-aipartner']}></i>
              <strong className={CommonStyles['big-txt']}>{me.name}님,</strong>
              <p className={CommonStyles['page-txt']}>중개사 인증이 완료되었습니다.</p>
            </div>
            <div className={CommonStyles['round-table']}>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>상호명</p>
                <p className={CommonStyles.col}>{me.company?.name}</p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>소재지</p>
                <p className={CommonStyles.col}>{me.company?.address_detail}</p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>전화번호</p>
                <p className={CommonStyles.col}>
                  {me.company?.contact?.split(',')[1]
                    ? `${me.company?.contact.split(',')[0]}, ${me.company?.contact.split(',')[1]}`
                    : me.company?.contact || '-'}
                </p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>등록번호</p>
                <p className={CommonStyles.col}>{me.company?.corp_number}</p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>
                  {me.verify?.is_ceo == 1
                    ? '대표자명'
                    : me.verify?.broker_code == 2
                    ? '소속공인중개사명'
                    : '중개보조원명'}
                </p>
                <p className={CommonStyles.col}>{me.name}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles['fixed'])}>
        <button className={cn(CommonStyles['btn'], CommonStyles['lg'])} onClick={goHome}>
          홈으로 가기
        </button>
      </div>
    </>
  );
};

export default BrokerComplete;
