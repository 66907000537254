import { useLocation, useOutlet } from 'react-router-dom';
import ModalManager from 'components/common/modal/ModalManager';
import Header from './header/Header';
import FooterNav from './FooterNav';
import useScrollToTop from 'hook/useScrollToTop';
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
// styels
import CommonStyles from 'styles/common.module.css';
// configs
import RefreshLayout from 'components/common/RefreshLayout/RefreshLayout';
import * as config from 'configs/configs';
import useDisablePinchZoomEffect from 'hook/useDisablePinchZoomEffect';
import { useEffect } from 'react';
import network from 'utils/network';
import { useDispatch } from 'react-redux';
import { initAppInfo, setAppInfo } from 'redux/slices/appInfo';

// configs
import * as Config from 'configs/configs';

/**
 * @description MainLayout
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @returns {JSX.Element}
 */
const RootLayout = () => {
  useScrollToTop();
  useDisablePinchZoomEffect();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();

  const currStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.currStep);
  const refresh = config.PATHURLLIST?.find?.((k) => pathname.includes(k.url))?.refresh;
  const isCheck = pathname.includes('mygroup') || pathname.includes('broker');
  const currentOutlet = useOutlet() || <></>;
  const agent = navigator.userAgent.toLowerCase();

  useEffect(() => {
    if (!agent) return;

    const f = async () => {
      getAppInfo(agent);
    };

    f();

    return () => {
      dispatch(initAppInfo());
    };
  }, [agent]);

  const getAppInfo = async (agent: string) => {
    try {
      const platform = agent.indexOf('android') !== -1 ? 'A' : 'I';

      const response = await network().app().getAppVersion();
      if (response?.platform === platform) {
        dispatch(
          setAppInfo({
            appInfo: response?.version || '0.0.0',
          }),
        );
      } else {
        dispatch(
          setAppInfo({
            appInfo: response?.version || '',
          }),
        );
      }
    } catch (error) {
      //console.log('error:', error);
    }
  };

  return (
    <>
      <div id="wrap">
        <Header />
        {isCheck ? (
          refresh ? (
            <RefreshLayout>{currentOutlet}</RefreshLayout>
          ) : (
            currentOutlet
          )
        ) : pathname.includes('/coagency-register') && 9 <= currStep ? (
          <>{refresh ? <RefreshLayout>{currentOutlet}</RefreshLayout> : currentOutlet}</>
        ) : pathname.includes('/coagency-lookingfor-register') || pathname.includes('/findsharedetail') ? (
          <>{currentOutlet}</>
        ) : (
          <div
            id="container"
            className={CommonStyles.container}
            style={
              currStep === 2 || currStep === 3
                ? { overflow: 'hidden', position: 'sticky', height: '100vh' }
                : pathname === '/writing'
                ? { height: '100vh' }
                : pathname === '/coagency-register'
                ? { height: '100vh' }
                : { height: '' }
            }
          >
            {refresh ? <RefreshLayout>{currentOutlet}</RefreshLayout> : currentOutlet}
          </div>
        )}
        {/* {Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && <FooterNav />} */}
      </div>
      <ModalManager />
    </>
  );
};

export default RootLayout;
