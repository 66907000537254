import { useEffect } from 'react';

/**
 * @description 줌 확대 축소 관련 hook
 * @Class
 * @category hooks
 * @subcategory common
 * @returns {void}
 */
const useDisablePinchZoomEffect = (): void => {
  useEffect(() => {
    const disablePinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener('touchmove', disablePinchZoom, { passive: false });
    return () => {
      document.removeEventListener('touchmove', disablePinchZoom);
    };
  }, []);
};

export default useDisablePinchZoomEffect;
