import React, { InputHTMLAttributes } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';

interface InputInterface extends InputHTMLAttributes<HTMLInputElement> {
  id?: string; // input id
  name?: string; // input name
  value?: string; // input value
  className?: string; // className
  mergeClass?: boolean; // 기존 input className이랑 새로 넘어오는 className이랑 merge 여부
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // input onChange 함수
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void; // input onKeydown
  clear?: boolean; // clear 사용 여부
  handleClickClear?: () => void; // input clear 함수
  maxLength?: number; // input maxLength
  disabled?: boolean; // input 활성화 여부
  placeholder?: string; // palceholder 값
  checkRequire?: boolean; // 유효성 검사 활성화 여부
  error?: false; // 유효성 검사 에러 여부
  errorMsg?: string; // 유효성 검사 에러 문구
  successMsg?: string; // 유효성 검사 성공 문구
  readOnly?: boolean; // readonly option
  style?: any; // style
}

// 추후에 리팩토링 필요
/**
 * @description input * 추후에 리팩토링 필요
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {string} props.id input id
 * @param {string} props.name input name
 * @param {string} props.value input value
 * @param {string} props.className input className
 * @param {boolean} props.mergeClass input merge class
 * @param {void} props.onChange input onchange event
 * @param {void} props.onKeyDown input onkeydown event
 * @param {boolean} props.clear input clear 함수 사용 여부
 * @param {void} props.handleClickClear input clear 함수
 * @param {number} props.maxLength input maxLength
 * @param {boolean} props.disabled input disable enable 여부
 * @param {string} props.placeholder input placeholder
 * @param {boolean} props.checkRequire input 유효성 검사 사용 여부 * 미구현
 * @param {boolean} props.error input 유효성 검사 사용 여부 * 미구현
 * @param {string} props.errorMsg input 유효성 검사 사용 여부 * 미구현
 * @param {string} props.successMsg input 유효성 검사 사용 여부 * 미구현
 * @param {boolean} props.readOnly input readOnly
 * @param {objecy} props.style input style
 * @returns {JSX.Element}
 */
const Input = React.forwardRef<HTMLInputElement, InputInterface>(
  (
    {
      id = '',
      name = '',
      value = '',
      onChange = () => {},
      onKeyDown = () => {},
      className = '',
      mergeClass = false,
      clear = false,
      handleClickClear = () => {},
      maxLength = '',
      disabled = false,
      placeholder = '',
      checkRequire = false,
      error = false,
      errorMsg = '',
      successMsg = '',
      readOnly = false,
      style = {},
    },
    ref,
  ) => {
    let defaultProps = {};

    if (maxLength) {
      defaultProps = {
        ...defaultProps,
        maxLength: +maxLength,
      };
    }

    if (id) {
      defaultProps = {
        ...defaultProps,
        id: id,
      };
    }

    if (name) {
      defaultProps = {
        ...defaultProps,
        name: name,
      };
    }

    const getStyle = () => {
      let payload = `${CommonStyles['input-area']}`;

      // if (clear) {
      //   payload += ` ${CommonStyles['clear-has']}`;
      // }

      if (maxLength) {
        payload += ` ${CommonStyles['has-byte']}`;
      }

      // if (checkRequire) {
      // }

      return payload;
    };

    return (
      <div
        className={
          className
            ? mergeClass
              ? cn(getStyle(), className, clear && value.length > 0 && CommonStyles['clear-has'])
              : className
            : cn(getStyle(), clear && value.length > 0 && CommonStyles['clear-has'])
        }
        style={style}
      >
        <input
          type="text"
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={(e) => onKeyDown(e)}
          disabled={disabled}
          {...defaultProps}
          readOnly={readOnly}
        />
        {maxLength && (
          <span className={CommonStyles.byte}>
            {value.length}/{maxLength}
          </span>
        )}
        {clear && value.length > 0 && <button className={CommonStyles.delete} onClick={handleClickClear} />}
        {checkRequire && !error ? (
          <p className={CommonStyles['guide-txt']}>{successMsg}</p>
        ) : (
          <p className={CommonStyles['guide-txt']}>{errorMsg}</p>
        )}
      </div>
    );

    //return <input placeholder={placeholder} disabled={disabled} />;
  },
);

export default Input;

{
  /* <div class="input-area has-byte clear-has">
<input type="text" placeholder="예시입니다.">
<span class="byte">35/50</span>
<button class="delete"></button>
<p class="guide-txt">사용할 수 있는 닉네임이에요.</p>
</div>
<div class="input-area error">
<input type="text" placeholder="예시입니다.">
<p class="guide-txt">닉네임은 한글, 영문, 숫자만 사용할 수 있어요.</p>
</div> */
}
