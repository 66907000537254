import { createBrowserRouter } from 'react-router-dom';
import RootLayout from 'layout/RootLayout';
import Parking from 'pages/Parking';

// SSO
import SignIn from 'pages/SignIn';
import LoginBridge from 'pages/sso/LoginBridge';
import LogoutBridge from 'pages/sso/LogoutBridge';

// 홈
import MyGroup from 'pages/mygroup/MyGroup';
//import MyGroupDetail from 'pages/mygroup/MyGroupDetail';
import Alim from 'pages/alim/Alim';
// 검색
import Search from 'pages/Search';

// 글쓰기
import NewPostType from 'pages/writing/NewPostType';
import NewPostCoagencyType from 'pages/writing/NewPostCoagencyType';

import NewPostSelect from 'pages/allposts/NewPostSelect';
import Writing from 'pages/allposts/Writing';
import GroupSelect from 'pages/allposts/GroupSelect';

// 공동중개
import CoagencyRegisterSelect from 'pages/coagency/CoagencyRegisterSelect';
import CoagencyRegister from 'pages/coagency/CoagencyRegister';
import CoagencyEdit from 'pages/coagency/CoagencyEdit';
import CoagencyRegisterLegacyList from 'pages/coagency/CoagencyRegisterLegacyList';

// 전체게시판
import AllBoard from 'pages/allboard/AllBoard';

// 내피드
import MyFeed from 'pages/MyFeed';
// import PostList from 'pages/feed/PostList';
// import CommentList from 'pages/feed/CommentList';
import BrokerInfo from 'pages/feed/BrokerInfo';
import Profile from 'pages/feed/Profile';

// 중개사 인증
import BrokerAuth from 'pages/brokerauth/BrokerAuth';
import BrokerCompany from 'pages/brokerauth/BrokerCompany';
import BrokerJurirno from 'pages/brokerauth/BrokerJurirno';
import BrokerList from 'pages/brokerauth/BrokerList';
import BrokerEmpty from 'pages/brokerauth/BrokerEmpty';
import BrokerRequest from 'pages/brokerauth/BrokerRequest';
import BrokerComplete from 'pages/brokerauth/BrokerComplete';
import BrokerTemporary from 'pages/brokerauth/BrokerTemporary';
import BrokerInvite from 'pages/brokerauth/BrokerInvite';
import BrokerSync from 'pages/brokerauth/BrokerSync';
import SampleList from 'pages/SampleList';
import TestPage from 'pages/test';
import NotFound from 'pages/NotFound';

// 개별모임
import Group from 'pages/group/Group';
import GroupMemberSearch from 'pages/group/GroupMemberSearch';
import GroupMemberDetail from 'pages/group/GroupMemberDetail';
import InviteMember from 'pages/group/InviteMember';
import GroupSetting from 'pages/group/GroupSetting';
import ModifyGroupImage from 'pages/group/ModifyGroupImage';
import GroupIntroduce from 'pages/group/GroupIntroduce';
import GroupApprovalManagement from 'pages/group/GroupApprovalManagement';
import GroupMemberPenalty from 'pages/group/GroupMemberPenalty';
import GroupLeader from 'pages/group/GroupLeader';
import GroupCommonLeader from 'pages/group/GroupCommonLeader';
import PostDetail from 'pages/allboard/PostDetail';
import GroupNoticeList from 'pages/group/GroupNoticeList';
import MemberApprovalList from 'pages/group/MemberApprovalList';
import ReportList from 'pages/group/ReportList';
import GroupInvite from 'pages/group/GroupInvite';

import AppCheck from 'pages/appcheck/AppCheck';
import Guide from 'pages/Guide';
import { createBrowserHistory } from 'history';
import ShareDetail from 'pages/allboard/SharedDetail';
import FindShareDetail from 'pages/allboard/FindShareDetail';
import WritingFindShare from 'pages/allposts/WritingFindShare';
import EditFindShare from 'pages/allposts/EditFindShare';
import CoagencyModify from 'pages/coagency/CoagencyModify';
import CoagencyModifyProcess from 'pages/coagency/CoagencyModifyProcess';
import AppDownload from 'pages/AppDownload';

export const history = createBrowserHistory();

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: '/mygroup',
        element: <MyGroup />,
      },
      {
        path: '/newpost-type', // 글쓰기진입 ==> 공동중개/게시글
        element: <NewPostType />,
      },
      {
        path: '/newpost-coagency-type', // 공동중개 진입 ==> 공유매물/구합니다.
        element: <NewPostCoagencyType />,
      },
      {
        path: '/coagency-register-select', // 공유매물 등록 선택
        element: <CoagencyRegisterSelect />,
      },
      {
        path: '/coagency-register', // 공유매물 등록 진입
        element: <CoagencyRegister />,
      },
      {
        path: '/coagency-edit/:offerId', // 공유매물 수정
        element: <CoagencyEdit />,
      },
      {
        path: '/coagency-legacy-list', // 공유매물 등록 선택
        element: <CoagencyRegisterLegacyList />,
      },
      {
        path: '/coagency-lookingfor-register', // 구합니다 작성
        element: <WritingFindShare />,
      },
      {
        path: '/coagency-lookingfor-register/:id', // 구합니다 작성(모임에서 넘어온 경우)
        element: <WritingFindShare />,
      },
      {
        path: '/coagency-request-modify/:id', // 공유매물 수정 요청
        element: <CoagencyModify />,
      },
      {
        path: '/coagency-request-modify/process/:id', // 공유매물 수정 확인
        element: <CoagencyModifyProcess />,
      },
      {
        path: '/newpostselect',
        element: <NewPostSelect />,
      },
      {
        path: '/writing',
        element: <Writing />,
      },
      {
        path: '/groupselect',
        element: <GroupSelect />,
      },
      {
        path: '/groupselect/:sharedId',
        element: <GroupSelect />,
      },
      {
        path: '/allboard',
        element: <AllBoard />,
      },
      {
        path: '/postdetail/:id', // 게시글 상세
        element: <PostDetail />,
      },
      {
        path: '/sharedetail/:id', // 공유매물 상세
        element: <ShareDetail />,
      },
      {
        path: '/sharedetail/:id/:groupId',
        element: <PostDetail />,
      },
      {
        path: '/findsharedetail/:id', // 구합니다 상세
        element: <FindShareDetail />,
      },
      {
        path: '/findsharedetail/:id/:groupId',
        element: <FindShareDetail />,
      },
      {
        path: '/coagency-lookingfor-register/modify/:id', // 구합니다 수정
        element: <EditFindShare />,
      },
      {
        path: '/feed',
        element: <MyFeed type="my" />,
      },
      {
        path: '/feed/bookmarks',
        element: <MyFeed type="bookmark" />,
      },
      {
        path: '/feed/brokerinfo',
        element: <BrokerInfo />,
      },
      {
        path: '/feed/profile',
        element: <Profile />,
      },
      {
        path: '/broker', //중개사인증 메인화면
        element: <BrokerAuth />,
      },
      {
        path: '/broker/ceo', //중개사인증 메인화면 (대표가 초대되어 왔을때)
        element: <BrokerAuth />,
      },
      {
        path: '/broker/company', //중개사인증 사업자등록번호 조회
        element: <BrokerCompany />,
      },
      {
        path: '/broker/jurirno', //중개사인증 개설등록번호 조회
        element: <BrokerJurirno />,
      },
      {
        path: '/broker/list', //중개사인증 조회된 중개업소 및 직원 목록
        element: <BrokerList />,
      },
      {
        path: '/broker/empty', //중개사인증 조회된 중개업소가 없을때
        element: <BrokerEmpty />,
      },
      {
        path: '/broker/request', //중개사인증 개설등록번호 요청
        element: <BrokerRequest />,
      },
      {
        path: '/broker/temporary', //중개사인증 인증완료
        element: <BrokerTemporary />,
      },
      {
        path: '/broker/complete', //중개사인증 인증 (대표거나 대표가 인증되어 있거나)
        element: <BrokerComplete />,
      },
      {
        path: '/broker/invite', //중개사인증 임시인증 (대표 미인증시)
        element: <BrokerInvite />,
      },
      {
        path: '/broker/sync', //중개사인증 연동
        element: <BrokerSync />,
      },
      {
        path: '/*',
        element: <NotFound />,
      },
      {
        path: '/notFound',
        element: <NotFound />,
      },
      {
        path: '/guide',
        element: <Guide />,
      },
      {
        path: '/test',
        element: !import.meta.env.PROD ? <TestPage /> : <NotFound />,
      },
      {
        //개별 모임 상세
        path: '/mygroup/group/:id',
        element: <Group />,
      },
      {
        //개별 모임 상세
        path: '/mygroup/group',
        element: <Group />,
      },
      {
        //개별 모임 상세
        path: '/mygroup/group/:id/:code',
        element: <Group />,
      },
      {
        path: '/group-postdetail/:groupId/:id',
        element: <PostDetail />,
      },
      {
        //개별 모임 멤버 조회
        path: '/groupmembersearch/:id',
        element: <GroupMemberSearch />,
      },
      {
        //개별 모임 상세
        path: '/groupmemberdetail/:id',
        element: <GroupMemberDetail />,
      },
      {
        //개별 모임 상세
        path: '/groupmemberdetail',
        element: <GroupMemberDetail />,
      },
      {
        //개별 모임 회원 초대하기
        path: '/invitegroupmember/:id',
        element: <InviteMember />,
      },
      {
        //개별 모임 환경설정
        path: '/groupsetting/:id',
        element: <GroupSetting />,
      },
      {
        //개별 모임 모임 이름 및 사진 설정
        path: '/modifygroupimage/:id',
        element: <ModifyGroupImage />,
      },
      {
        //개별 모임 소개 및 지역설정
        path: '/modifygroupintroduce/:id',
        element: <GroupIntroduce />,
      },
      {
        //개별 모임 가입 신청 관리
        path: '/groupapprovalmanagement/:id',
        element: <GroupApprovalManagement />,
      },
      {
        //개별 모임 페널티 멤버 관리
        path: '/groupmemberpanelty/:id',
        element: <GroupMemberPenalty />,
      },
      {
        //개별 모임 모임장 설정
        path: '/groupleader/:id',
        element: <GroupLeader />,
      },
      {
        //개별 모임 공동 모임장 설정
        path: '/groupcommonleader/:id',
        element: <GroupCommonLeader />,
      },
      {
        //개별 모임 공지사항 목록
        path: '/groupnoticelist/:id',
        element: <GroupNoticeList />,
      },
      {
        path: '/coagency-coagency',
        element: <Guide searchType={'coagency'} />,
      },
      {
        path: '/coagency-lookingfor',
        element: <Guide searchType={'etc'} />,
      },
      {
        //개별 모임 가입 승인
        path: '/groupapprovallist/:id',
        element: <MemberApprovalList />,
      },
      {
        //개별 모임 신고된 게시물 관리
        path: '/groupreportlist/:id',
        element: <ReportList />,
      },
      {
        //개별 모임 초대 코드 검증 화면
        path: '/groupcode/:code',
        element: <GroupInvite />,
      },
      {
        path: '/sample',
        element: !import.meta.env.PROD ? <SampleList /> : <NotFound />,
      },
      {
        path: '/appcheck',
        element: <AppCheck />,
      },
    ],
  },
  {
    path: '/signin',
    element: !import.meta.env.PROD ? <SignIn /> : <NotFound />,
  },
  {
    path: '/sso-login',
    element: <LoginBridge />,
  },
  {
    path: '/sso-logout',
    element: <LogoutBridge />,
  },
  {
    path: '/parking',
    element: <Parking />,
  },
  {
    path: '/app-download',
    element: <AppDownload />,
  },
]);
