import { IMixedKeyValue } from '<modal>';
import network from 'utils/network';

/**
 * @description 모임 정보 조회
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getGroupInfo = async (path: string) => {
  const res = await network().group().getGroupInfo(path);
  return res;
};

/**
 * @description 모임 회원 목록
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getGroupMemberList = async (path: string) => {
  const res = await network().group().getGroupMemberList(path);
  return res;
};

/**
 * @description 모임 게시글 목록 조회
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getGroupBoardList = async (path: string) => {
  const res = await network().group().getGroupBoardList(path);
  return res;
};

/**
 * @description 모임 모임 초대코드 생성
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getInviteCode = async (path: string) => {
  const res = await network().group().getInviteCode(path);
  return res;
};

/**
 * @description 모임 정보 수정(이름 및 사진)
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putModifyImageAndName = async (path: string, payload: IMixedKeyValue | string) => {
  const res = await network().group().putModifyImageAndName(path, payload);
  return res;
};

/**
 * @description 모임 정보 수정(소개 및 지역)
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putModifyIntroduceAndArea = async (path: string, payload: IMixedKeyValue | string) => {
  const res = await network().group().putModifyIntroduceAndArea(path, payload);
  return res;
};

/**
 * @description 모임 정보 수정(가입 신청 관리)
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const puthModifyApprovalManagement = async (path: string, payload: IMixedKeyValue | string) => {
  const res = await network().group().puthModifyApprovalManagement(path, payload);
  return res;
};

/**
 * @description 모임 설정 confing 항목 조회
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getGroupConfig = async (path: string) => {
  const res = await network().group().getGroupConfig(path);
  return res;
};

/**
 * @description 모임 설정 config 설정된 값
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getGroupBasicInfo = async (path: string) => {
  const res = await network().group().getGroupBasicInfo(path);
  return res;
};

/**
 * @description 모임 설정 회원가입시 알람 설정
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putGroupAlarm = async (path: string) => {
  const res = await network().group().putGroupAlarm(path);
  return res;
};

/**
 * @description 모임 설정 공개 여부
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putIsGroup = async (path: string, payload: IMixedKeyValue | string) => {
  const res = await network().group().putIsGroup(path, payload);
  return res;
};

/**
 * @description 모임 가입 조건 변경
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putIsGroupCondition = async (path: string, payload: IMixedKeyValue | string) => {
  const res = await network().group().putIsGroup(path, payload);
  return res;
};

/**
 * @description 지역검색
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getAreaList = async (path: string) => {
  const res = await network().group().getAreaList(path);
  return res;
};

/**
 * @description 모임 유저 페널티 해제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putDisabledPenalty = async (path: string, payload?: IMixedKeyValue | string) => {
  const res = await network().group().putDisabledPenalty(path, payload);
  return res;
};

/**
 * @description 모임 유저 페널티 적용
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postEnabledPenalty = async (path: string, payload?: IMixedKeyValue | string) => {
  const res = await network().group().postEnabledPenalty(path, payload);
  return res;
};

/**
 * @description 모임 모임장 위임
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putTransferGroupLeader = async (path: string) => {
  const res = await network().group().putTransferGroupLeader(path);
  return res;
};

/**
 * @description 모임 모임장 조회
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getLeaderList = async (path: string) => {
  const res = await network().group().getLeaderList(path);
  return res;
};

/**
 * @description 모임 회원 조회(모임장 제외)
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getMemberList = async (path: string) => {
  const res = await network().group().getMemberList(path);
  return res;
};

/**
 * @description 모임 탈퇴
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putLeaveGroup = async (path: string) => {
  const res = await network().group().putLeaveGroup(path);
  return res;
};

/**
 * @description 모임 삭제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deleteGroup = async (path: string) => {
  const res = await network().group().deleteGroup(path);
  return res;
};

/**
 * @description 모임 가입 신청
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postJoinGroup = async (path: string, payload?: IMixedKeyValue | string) => {
  const res = await network().group().postJoinGroup(path, payload);
  return res;
};

/**
 * @description 모임 승인 목록
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getApprovalList = async (path: string) => {
  const res = await network().group().getApprovalList(path);
  return res;
};

/**
 * @description 모임 가입 반려
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putRejectGroup = async (path: string) => {
  const res = await network().group().putRejectGroup(path);
  return res;
};

/**
 * @description 모임 가입 승인
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putApprovalJoinGroup = async (path: string) => {
  const res = await network().group().putApprovalJoinGroup(path);
  return res;
};

/**
 * @description 모임 신고된 게시물 관리
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getReportList = async (path: string) => {
  const res = await network().group().getReportList(path);
  return res;
};

/**
 * @description 모임 게시글 삭제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deleteGroupPost = async (path: string) => {
  const res = await network().group().deleteGroupPost(path);
  return res;
};

/**
 * @description 모임 게시글 삭제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putGroupBookmark = async (path: string) => {
  const res = await network().group().putGroupBookmark(path);
  return res;
};

/**
 * @description 모임 게시글 북마크 해제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deleteGroupUnBookmark = async (path: string) => {
  const res = await network().group().deleteGroupUnBookmark(path);
  return res;
};

/**
 * @description 모임 게시글 수정
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putModifyGroupPost = async (path: string) => {
  const res = await network().group().putModifyGroupPost(path);
  return res;
};

/**
 * @description 모임 게시글 상세
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getGroupPostDetail = async (path: string) => {
  const res = await network().group().getGroupPostDetail(path);
  return res;
};

/**
 * @description 모임 게시글 신고
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putReportGroupPost = async (path: string, payload: IMixedKeyValue | string) => {
  const res = await network().group().putReportGroupPost(path, payload);
  return res;
};

/**
 * @description 모임 게시글 신고 해제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deleteUnReportGroupPost = async (path: string) => {
  const res = await network().group().deleteUnReportGroupPost(path);
  return res;
};

/**
 * @description 모임 게시글 좋아요 or 해제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postLikeGroupPost = async (path: string) => {
  const res = await network().group().postLikeGroupPost(path);
  return res;
};

/**
 * @description 모임 게시글 싫어요 or 해제
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postdisLikeGroupPost = async (path: string) => {
  const res = await network().group().postdisLikeGroupPost(path);
  return res;
};

/**
 * @description 모임 게시글 공지로 설정
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putRegisterNotice = async (path: string) => {
  const res = await network().group().putRegisterNotice(path);
  return res;
};

/**
 * @description 모임 게시글 공지 내리기
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putUnRegisterNotice = async (path: string) => {
  const res = await network().group().putUnRegisterNotice(path);
  return res;
};

/**
 * @description 모임 게시글 중요 공지 설정
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putRegisterImNotice = async (path: string) => {
  const res = await network().group().putRegisterImNotice(path);
  return res;
};

/**
 * @description 모임 게시글 중요 공지 내리기
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putUnRegisterImNotice = async (path: string) => {
  const res = await network().group().putUnRegisterImNotice(path);
  return res;
};

/**
 * @description 모임 초대 코드 검증
 * @Class
 * @category Api
 * @subcategory 모임
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getConfirmInviteCode = async (path: string) => {
  const res = await network().group().getConfirmInviteCode(path);
  return res;
};

/**
 * @description 공유매물 조회
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postShareList = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().group().postShareList(path, payload);
  return res;
};

/**
 * @description 매물 유형 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getOffersTypeList = async (path: string) => {
  const res = await network().group().getOffersTypeList(path);
  return res;
};

/**
 * @description 구합니다 목록 조회
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postSeekList = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().group().postSeekList(path, payload);
  return res;
};

/**
 * @description 공유매물 북마크
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postShareBookmark = async (path: string) => {
  const res = await network().group().postShareBookmark(path);

  return res;
};

/**
 * @description 공유매물 북마크해제
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postShareUnBookmark = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().group().postShareUnBookmark(path, payload);

  return res;
};

/**
 * @description 구합니다 북마크
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postFindShareBookmark = async (path: string) => {
  const res = await network().group().postShareBookmark(path);

  return res;
};

/**
 * @description 구합니다 북마크 해제
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postFindShareUnBookmark = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().group().postShareUnBookmark(path, payload);

  return res;
};

/**
 * @description 구합니다 상세
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getFindShareDetail = async (path: string) => {
  const res = await network().group().getFindShareDetail(path);

  return res;
};

/**
 * @description 구합니다 모임 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getMyGroupList = async (path: string) => {
  const res = await network().group().getMyGroupList(path);

  return res;
};

/**
 * @description 구합니다 등록
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postFindShareBoard = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().group().postFindShareBoard(path, payload);

  return res;
};
