import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appInfo: '',
};

/**
 * @description 앱정보 appInfo
 * @Class
 * @category redux
 * @subcategory slice
 */
const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState,
  reducers: {
    setAppInfo: (state, action) => {
      state.appInfo = action.payload.appInfo;
    },
    initAppInfo: (state) => {
      state.appInfo = '';
    },
  },
});

export const { setAppInfo, initAppInfo } = appInfoSlice.actions;

export default appInfoSlice;
