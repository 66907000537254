import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { initAddr } from 'pages/coagency/initState';

const initialState = {
  coagencyType: '', // share, lookfor
  shareRegStep: {
    totalStep: 11,
    currStep: 1,
    currAddrStep: 1,
    selectedAddr: initAddr,
    dir: 'right',
    mode: 'write',
    editStep: '',
    coagencyRegData: {
      regId: null,
      offerings_main_gbn: '',
      offerings_gbn: '',
      area_cd: null,
      complex_cd: null,
      dong_cd: null,
      dong_hide: 'N',
      ho_nm: '',
      addr_gbn: 'N',
      addr: {
        street_1: null,
        street_2: null,
        detail_use: 'Y',
        detail: '',
      },
      offer_gbn: '', // S, L, M, T
      sell_prc: null,
      lease_prc: null,
      deposit_prc: null,
      monthly_prc: null,

      parcel_gbn: 'A', // A일반, B 조합원분,
      parcel_prc: null,
      premium_prc: null,
      parcel_option_prc: null,

      move_gbn: 'A',
      is_move_chg: 'N',
      move_ymd: new Date(),
      status_deal: 'normal',
      status_share: 'Y',
      shares: [],
      tel_display: 'A',
      status_share_map: 'N',
      show_days: 15,
      ad_offerings_seq: null,
      offerings_seq: null,
      offering_sync: null,
      isPriceChange: false,
      price_info: [],
    },
    coagencyOptRegData: {
      area: {
        supArea: null,
        conArea: null,
        excArea: null,
        lotArea: null,
        totArea: null,
        bulArea: null,
      },
      floor: {
        apply: null,
        under: null,
        ground: null,
        total: null,
      },
      room_cnt: null,
      bath_cnt: null,
      memo: '',
      detail_memo: '',
      customer_name: '',
      owner_type: {
        code: '',
        name: '',
      },
      phone_num: '',
      hidden_memo: '',
      isTermsAgree: false,
    },
    targetBuilding: {
      building: {
        dongNm: '',
        gbnCodeNm: '',
        mngPk: '',
        sitePos: '',
      },
      floor: {
        buildingPk: '',
        floorNm: '',
        hoNm: '',
        useNm: '',
        etcNm: '',
        sqr: '',
      },
      area: {
        buildSqr: '',
        buildingNm: '',
        buildingPk: '',
        code: '',
        dongNm: '',
        excSqr: '',
        gbnCodeNm: '',
        mngPk: '',
        sitePos: '',
        siteSqr: '',
        supSqr: '',
        useSqr: '',
      },
    },
    price: {
      sell_prc: null,
      lease_prc: null,
      deposit_prc: null,
      monthly_prc: null,
      parcel_prc: null,
      premium_prc: null,
      parcel_option_prc: null,
    },
  },
};

export const initImportState = {
  targetSourceName: '',
  areaCd: '',
  acMetroNm: '',
  acGuNm: '',
  acDongNm: '',
  acLiNm: '',
  hoNm: '',
  adOfferingsSeq: 0,
  offeringsSeq: 0,
  offeringsGbn: '',
  offerGbn: 'S',
  offerGbnStr: '',
  imgList: [],
  addrStr: '',
  memo: '',
  detailMemo: '',
  hiddenMemo: '',
  supSqrStr: '',
  price: '',
  complexCd: 0,
  sqrCd: 0,
  supSqr: 0,
  excSqr: 0,
  conSqr: 0,
  buildSqr: 0,
  etcSqr: 0,
  cusOwnerGbn: '',
  sellPrc: null,
  leasePrc: null,
  depositPrc: null,
  monthlyPrc: null,
  parcelPrc: null,
  premiumPrc: null,
  parcelOptionPrc: null,
  parcelGbn: null,
  floor: null,
  totFloor: null,
  underFloor: null,
  groundFloor: null,
  roomCnt: null,
  bathCnt: null,
  isMoveChg: 'N',
  moveGbn: '',
  moveYmd: '',
  ntDisplayDate: null,
  ntExpireDate: null,
  detailAddr: null,
  houseNo: null,
  addrGbn: 'S',
  isRebuild: 'N',
};

/**
 * @description 공동중개 공유매물 coagecny
 * @Class
 * @category redux
 * @subcategory slice
 */
export const coagecnySlice = createSlice({
  name: 'coagency',
  initialState,
  reducers: {
    setCoagencyType: (state, actions) => {
      const { coagencyType } = actions.payload;
      state.coagencyType = coagencyType;
    },
    initStep: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = { ...initialState.shareRegStep };
    },
    initLocation: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 2,
        currAddrStep: 1,
        mode: 'edit',
        editStep: 'location',
        selectedAddr: {
          ...state.shareRegStep.selectedAddr,
        },
      };
    },
    initComplex: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 3,
        mode: 'edit',
        editStep: 'complex',
        selectedAddr: {
          ...state.shareRegStep.selectedAddr,
        },
      };
    },
    initDongHo: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 4,
        mode: 'edit',
        editStep: 'dongHo',
        selectedAddr: {
          ...state.shareRegStep.selectedAddr,
        },
        coagencyRegData: {
          ...state.shareRegStep.coagencyRegData,
          offerings_main_gbn: state.shareRegStep.coagencyRegData.offerings_main_gbn,
          offerings_gbn: state.shareRegStep.coagencyRegData.offerings_gbn,
        },
      };
    },
    initDetailAddr: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 5,
        mode: 'edit',
        editStep: 'detailAddr',
        selectedAddr: {
          ...state.shareRegStep.selectedAddr,
        },
      };
    },
    initOffer: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 6,
        mode: 'edit',
        editStep: 'offer',
      };
    },
    initPrice: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 7,
        mode: 'edit',
        editStep: 'offer',
      };
    },
    initParcel: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 5,
        mode: 'edit',
        editStep: 'parcel',
      };
    },
    initMoveDay: (state, action) => {
      state.coagencyType = action.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        currStep: 8,
        mode: 'edit',
        editStep: 'moveDay',
      };
    },
    setStep: (state, actions) => {
      const { shareRegStep } = actions.payload;
      state.shareRegStep = Object.assign({}, state.shareRegStep, shareRegStep);
    },
    setArea: (state, actions) => {
      const { a } = actions.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        coagencyOptRegData: {
          ...state.shareRegStep.coagencyOptRegData,
          area: a,
        },
      };
    },
    setTargetBuilding: (state, actions) => {
      const { b } = actions.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        targetBuilding: {
          ...state.shareRegStep.targetBuilding,
          building: b,
        },
      };
    },
    setTargetFloor: (state, actions) => {
      const { f } = actions.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        targetBuilding: {
          ...state.shareRegStep.targetBuilding,
          floor: f,
        },
      };
    },
    setTargetArea: (state, actions) => {
      const { a } = actions.payload;
      state.shareRegStep = {
        ...state.shareRegStep,
        targetBuilding: {
          ...state.shareRegStep.targetBuilding,
          area: a,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  setCoagencyType,
  initStep,
  initLocation,
  initComplex,
  initDongHo,
  initDetailAddr,
  initOffer,
  initPrice,
  initParcel,
  initMoveDay,
  setStep,
  setArea,
  setTargetBuilding,
  setTargetFloor,
  setTargetArea,
} = coagecnySlice.actions;

export default coagecnySlice;
