import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import Button from 'components/common/Button/Button';
import useToast from 'hook/useToast';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/group';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Toast from 'components/common/Toast/Toast';

/**
 * @description 모임 - 회원 초대 화면
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const InviteMember = () => {
  const { isOpen = false } = useSelector((state: RootState) => state.toast);
  const groupData = useSelector((state: RootState) => state.group);
  const params = useParams();
  const { id = '' } = params;
  const { group } = groupData;
  const { name = '모임', photos = [], desc = '' } = group;
  const { setToastMessage } = useToast();
  const [inviteCode, setInviteCode] = useState('');
  const [code, setCode] = useState<string>(`${import.meta.env.VITE_APP_DOMAIN}/groupcode/`);
  const [isCode, setIsCode] = useState<boolean>(false);
  // 모임 초대코드 조회
  useEffect(() => {
    if (id) {
      const f = async () => {
        await fetchCode();
      };
      f();
    }
  }, []);

  /**
   * @description 모임 초대 코드 생성 api
   * @returns {Promise<void>}
   */
  const fetchCode = useCallback(async () => {
    try {
      const queryString = `/groups/${id}/invite/url`;
      const res = await APIS.getInviteCode(queryString);
      const { shortUrl = '' } = res;
      if (shortUrl) {
        setInviteCode(shortUrl);
        setCode(code + shortUrl);
        setIsCode(true);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  }, [code]);

  //  주소복사
  const handleClickCopy = () => {
    if (code) {
      window.navigator.clipboard.writeText(code).then(() => {
        setToastMessage({
          duration: 2000,
          content: '주소가 복사되었어요. 원하는 곳에 붙여넣기 하세요.',
          type: 'message',
        });
      });
    }
  };

  /**
   * @description 모임 공유
   * @returns {Promise<void>}
   */
  const handleInviteSend = () => {
    const kakaoBody = {
      title: `[${name}] 에 초대합니다!`,
      description: `[${name}] ${desc ? desc : ''}`,
      imageUrl: photos.length > 0 ? photos[0].url : `${location.origin}/images/share_group.png`,
      link: {
        mobileWebUrl: `${location.origin}/groupcode/${inviteCode}`,
        webUrl: `${location.origin}/groupcode/${inviteCode}`,
      },
    };

    if (window.webkit) {
      window.webkit.messageHandlers.shared.postMessage(kakaoBody);
      setToastMessage({
        duration: 2000,
        content: '초대장 보내기를 완료하였습니다.',
        type: 'message',
      });
    } else if (window.Android) {
      window.Android.shared(JSON.stringify(kakaoBody));
      setToastMessage({
        duration: 2000,
        content: '초대장 보내기를 완료하였습니다.',
        type: 'message',
      });
    }
  };

  return (
    <div className={cn(CommonStyles.hasBottom)}>
      <div className={cn(CommonStyles.content, CommonStyles['group-invite'])}>
        <i className={CommonStyles['icon-invite']}></i>
        <p className={CommonStyles.txt} style={{ textAlign: 'center' }}>
          초대할 회원이 있다면
          <br />
          초대링크를 공유해주세요.
        </p>
        {/* <div className={CommonStyles['copy-link']}>
          <input id="copy_group_code" value={code} readOnly={true} />
          <Button size="small" type="line" onClick={handleClickCopy} disabled={isCode ? false : true}>
            URL 복사
          </Button>
        </div> */}
        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <Button size="large" disabled={isCode ? false : true} onClick={handleInviteSend}>
            카카오톡으로 공유하기
          </Button>
        </div>
        {isOpen && <Toast />}
        {/* <div className={CommonStyles["toast-popup"]}>주소가 복사되었어요. 원하는 곳에 붙여넣기 하세요.</div> */}
      </div>
    </div>
  );
};

export default InviteMember;
