import { closeModal, openMultiModal } from 'redux/slices/modal';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import cn from 'classnames';
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { useEffect, useState } from 'react';
import { IMixedKeyValue } from '<modal>';

interface TradeType {
  category: string;
  value: string;
}

const TRADE_TYPE: TradeType[] = [
  {
    category: '전체',
    value: '',
  },
  {
    category: '매매',
    value: 'S',
  },
  {
    category: '전세',
    value: 'L',
  },
  {
    category: '월세',
    value: 'M',
  },
  {
    category: '단기 임대',
    value: 'T',
  },
];

// 초깃값
const initState = {};

/**
 * @description 구합니다 bottom sheet
 * @Class
 * @category Components
 * @subcategory 구합니다 sheet | modal
 * @component
 * @param {string} props.title sheet title
 * @returns {JSX.Element}
 */
const ShareBotSheet = () => {
  const dispatch = useAppDispatch();
  const { modalType, action } = useAppSelector((state: RootState) => state.modal);
  // 평 square , meter = m2
  const [inputType, setInputType] = useState('meter');
  // 검색 조건
  const [info, setInfo] = useState<IMixedKeyValue>({ ...initState });

  const handleClickBack = () => {
    dispatch(
      closeModal({
        isOpen: false,
        modalType: modalType,
      }),
    );
  };

  // 매물면적
  const handleClicPropertyArea = () => {
    dispatch(
      openMultiModal({
        isMultiple: true,
        multiModalType: 'share_property_area',
      }),
    );
  };

  // 매물종류
  const handleClicPropertyType = () => {
    dispatch(
      openMultiModal({
        isMultiple: true,
        multiModalType: 'share_property_type',
      }),
    );
  };

  // 매물 검색
  const handleClickSearch = () => {
    if (modalType === 'share_filter_full') {
      // 설정된 검색 조건을 공동중개 리스트에 필터로 setter해주는 함수
      action?.();
    }
  };

  return (
    <div id="wrap" style={{ overflow: 'hidden' }}>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={() => handleClickBack()}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>

        <div className={Styles.title} style={{ fontWeight: 700, color: '#111' }}>
          필터 옵션
        </div>
        <div className={cn(Styles.right, CommonStyles.right)}>
          <button className={cn(Styles['text-btn'], Styles.disabled, CommonStyles.disabled)}></button>
        </div>
      </header>
      <div className={CommonStyles.container} style={{ background: '#fff' }}>
        <div className={cn(CommonStyles['content'], CommonStyles['insert-content'])}>
          <div className={CommonStyles['insert-form']}>
            <div className={CommonStyles['sub-title']}>매물 종류</div>
            <div className={CommonStyles['insert-item']} onClick={handleClicPropertyType}>
              <button className={CommonStyles['dropdown']}>
                <span>선택</span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
            </div>
          </div>
          <div className={CommonStyles['insert-form']}>
            <div className={CommonStyles['sub-title']}>거래유형</div>
            <div className={CommonStyles['insert-item']}>
              {TRADE_TYPE.map((item: TradeType, index: number) => {
                return (
                  <button key={index + item.value} className={cn(CommonStyles['btn'], CommonStyles['line'])}>
                    {item.category}
                  </button>
                );
              })}
            </div>
          </div>
          <div className={CommonStyles['insert-form']}>
            <div className={CommonStyles['sub-title']}>단기임대가</div>
            <div className={CommonStyles['insert-item']}>
              <div className={CommonStyles['sub-title']}>보증금</div>
              <div className={CommonStyles['input-div']}>
                <div className={CommonStyles['input-area']}>
                  <input type="text" placeholder="10,000" />
                  <span className={CommonStyles['unit']}>만원</span>
                </div>
                <span>~</span>
                <div className={CommonStyles['input-area']}>
                  <input type="text" placeholder="10,000" />
                  <span className={CommonStyles['unit']}>만원</span>
                </div>
              </div>
              <div className={cn(CommonStyles['w100p'], CommonStyles['info-txt'])}>
                [금액:<b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>0</b>원 ~{' '}
                <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>0</b>원]
              </div>
            </div>
            <div className={CommonStyles['insert-item']}>
              <div className={CommonStyles['sub-title']}>월세</div>
              <div className={CommonStyles['input-div']}>
                <div className={CommonStyles['input-area']}>
                  <input type="text" placeholder="10,000" />
                  <span className={CommonStyles['unit']}>만원</span>
                </div>
                <span>~</span>
                <div className={CommonStyles['input-area']}>
                  <input type="text" placeholder="10,000" />
                  <span className={CommonStyles['unit']}>만원</span>
                </div>
              </div>
              <div className={cn(CommonStyles['w100p'], CommonStyles['info-txt'])}>
                [금액:<b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>0</b>원 ~{' '}
                <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>0</b>원]
              </div>
            </div>
          </div>
          <div className={CommonStyles['insert-form']}>
            <div className={CommonStyles['sub-title']}>면적</div>
            <div className={CommonStyles['insert-item']}>
              <button className={CommonStyles['dropdown']} onClick={handleClicPropertyArea}>
                <span>선택</span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
              <div className={CommonStyles['input-div']}>
                <div className={CommonStyles['input-area']}>
                  <input className="number" placeholder="85" />
                  <span className={CommonStyles['unit']}>{inputType === 'meter' ? '㎡' : '평'}</span>
                </div>
                <span>~</span>
                <div className={CommonStyles['input-area']}>
                  <input type="number" placeholder="85" />
                  <span className={CommonStyles['unit']}>{inputType === 'meter' ? '㎡' : '평'}</span>
                </div>
              </div>
              <div className={cn(CommonStyles['ml-auto'], CommonStyles['btn-div'])}>
                <button
                  className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['gry'])}
                  onClick={() => {
                    if (inputType === 'meter') {
                      setInputType('square');
                    } else {
                      setInputType('meter');
                    }
                  }}
                >
                  <i className={CommonStyles['icon-change']}></i>
                  {inputType === 'meter' ? '평형으로 입력' : '제곱미터로 입력'}
                </button>
              </div>
            </div>
          </div>
          <div className={CommonStyles['insert-form']}>
            <div className={CommonStyles['sub-title']}>방/욕실개수</div>
            <div className={CommonStyles['insert-item']}>
              <div className={CommonStyles['input-div']}>
                <div className={CommonStyles['input-area']}>
                  <input type="number" placeholder="방수" />
                  <span className={CommonStyles['unit']}>개</span>
                </div>
                <span>~</span>
                <div className={CommonStyles['input-area']}>
                  <input type="number" placeholder="욕실수" />
                  <span className={CommonStyles['unit']}>개</span>
                </div>
              </div>
            </div>
          </div>
          <div className={CommonStyles['btn-wrap']}>
            <button className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}>초기화</button>
            <button className={cn(CommonStyles['btn'], CommonStyles['lg'])}>매물 검색</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareBotSheet;
