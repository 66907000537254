import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { API_BASE_URL, API_URL } from 'configs/configs';
import { locStore, rmLocStore } from './common';
import { SignInFormData, SsoSignInJwtRequestBody, SsoSignInRequestBody } from '<network>';
import {
  FeedReqType,
  FeedNicknameReqType,
  FeedProfileReqType,
  FeedOfferSearchReqType,
  FeedSeekSearchReqType,
} from '../@types/feed';
import { IWriting } from '<writing>';
import { IMixedKeyValue } from '<modal>';
import { IJuridicalSearch, IisCompany } from '<broker>';
import { IAccessLogRequestBody } from '../@types/user';
import { persistor } from '../main';
import { closeSubView } from 'redux/slices/global';
import { onClose, onView } from 'utils';
import { TOfferImportMode, TOfferImportRequest } from '../@types/form';

// import mem from 'mem';

const Axios = (token?: string, multipart?: boolean, timeout?: number) =>
  axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    timeout: timeout !== undefined && timeout !== null ? timeout : 10000,
    headers: {
      Accept: 'application/json',
      ...(multipart ? { 'Content-Type': 'multipart/form-data' } : { 'Content-Type': 'application/json' }),
      ...(token && { Authorization: token && `Bearer ${token}` }),
    },
  });

const getPromise = async <T>(fnc: () => Promise<AxiosResponse<T>>): Promise<T> => {
  try {
    const res = await fnc();
    return res.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios 오류 처리
      const axiosError = error as AxiosError<T>;
      // 필요한 경우 오류 내용을 반환하거나 처리
      throw axiosError;
    } else {
      // 기타 오류 처리
      throw error;
    }
  }
};
const getReturnPromise = async <T>(fnc: () => Promise<AxiosResponse<T> | AxiosError>): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    fnc()
      .then((res) => {
        const { data } = res as AxiosResponse<T>;
        resolve(data as T);
      })
      .catch((err) => {
        const { data } = err && (err as AxiosError).response;
        reject(data);
      });
  });
};

const getResPromise = async <T>(func: () => Promise<AxiosResponse<T> | AxiosError>): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    func()
      .then((res) => {
        const response = res as AxiosResponse<T>;
        resolve(response as T);
      })
      .catch((err) => {
        const errorRes = err && (err as AxiosError).response;
        reject(errorRes);
      });
  });
};

const getRefreshToken = async (): Promise<string> => {
  const accessToken = locStore('token');
  const refreshToken = locStore('refresh_token');

  try {
    if (!accessToken) throw new Error('empty acess token');

    const res = await Axios(accessToken).post(API_URL.POST_SSO_REFRESH, {
      refresh_token: refreshToken,
      device: 'Mobile',
    });

    const { token, refresh } = res.data.data;
    locStore('token', token);
    locStore('refresh_token', refresh);
    return token;
  } catch (err) {
    console.log('token refresh failed:', err);
    return '';
  }
};

const logout = async () => {
  try {
    await persistor.purge();
    rmLocStore('token');
    rmLocStore('refresh_token');

    if (window.webkit) {
      window.webkit.messageHandlers.logout.postMessage('');
    } else if (window.Android) {
      window.Android.logout();
    } else {
      window.webkit.messageHandlers.logout.postMessage('');
    }
  } catch (error) {
    console.log('logout error:', error);
  }
};

/**
 * @description api 관련 설정
 * @todo api 카데고리 참고
 * @Class
 * @category utils
 */
const network = () => {
  const accessToken = locStore('token');
  const noAuthRequest = Axios();
  const authRequest = Axios(accessToken);
  const authRequestUnlimited = Axios(accessToken, false, 0);
  //const authMultipartRequest = Axios(accessToken, true); => 추후 multipart 사용시 사용

  const interceptorActions = (response: AxiosResponse) => {
    return response;
  };

  const authInterceptorErrorActions = async (error: AxiosError) => {
    const { config, response } = error;
    if (!config) return Promise.reject(error);

    const token = locStore('token');
    if (response?.status == 401 && !token) {
      logout();
      return;
    }

    if (config?.url === API_URL.POST_SSO_REFRESH || response?.status !== 401) {
      return Promise.reject(error);
    }

    const accessToken = await getRefreshToken();

    if (!accessToken) {
      logout();
      return;
    }

    console.log('getRefreshToken:', accessToken);
    console.log('error:', error);

    if (error.config) {
      error.config.headers['Authorization'] = `Bearer ${accessToken}`;
      return Axios(accessToken).request(error.config as AxiosRequestConfig);
    }
  };

  const noAuthInterceptorErrorActions = (error: AxiosError) => {
    try {
      if (error.response?.status === 307) {
        window.location.href = '/';
        return false;
      }
      return Promise.reject(error);
    } catch (err) {
      return Promise.reject(err);
    }
  };

  authRequest.interceptors.response.use(interceptorActions, authInterceptorErrorActions);
  noAuthRequest.interceptors.response.use(interceptorActions, noAuthInterceptorErrorActions);
  authRequestUnlimited.interceptors.response.use(interceptorActions, authInterceptorErrorActions);

  return {
    auth() {
      return {
        login: (data: SignInFormData) => getPromise(() => noAuthRequest.post(`/signin`, data)),
      };
    },
    sso() {
      return {
        // 로그인
        signin: (data: SsoSignInRequestBody) =>
          getPromise(() => noAuthRequest.post(API_URL.POST_SSO_SIGNIN, { ...data, device: 'Mobile' })),
        // JWT 로그인
        signinJwt: (data: SsoSignInJwtRequestBody) =>
          getPromise(() => noAuthRequest.post(API_URL.POST_SSO_SIGNIN_JWT, data)),
        // JWT 로그아웃(회원탈퇴)
        signoutJwt: (data: SsoSignInJwtRequestBody) =>
          getPromise(() => noAuthRequest.post(API_URL.POST_SSO_SIGNOUT_JWT, data)),
        // 로그아웃
        signout: () => getPromise(() => authRequest.post(API_URL.POST_SSO_SIGNOUT)),
        // 내정보
        me: () => getPromise(() => authRequest.get(API_URL.GET_SSO_ME)),
        // 중개사 정보
        brokerpublic: () => getPromise(() => authRequest.post(API_URL.POST_BROKER_PUBLIC)),
        // 닉네임 유효성 체크
        isNickname: (data: FeedNicknameReqType) => getPromise(() => authRequest.post(API_URL.POST_ISNICKNAME, data)),
        putProfile: (data: FeedProfileReqType) => getPromise(() => authRequest.put(API_URL.PUT_SSO_PROFILE, data)),
        access: (data: IAccessLogRequestBody) => getPromise(() => authRequest.post(API_URL.POST_SSO_ACCESS, data)),
        getJWTForMember: () => getPromise(() => authRequest.get(API_URL.GET_JWT_MEMBER)),
        //이실장 멤버십 여부
        membership: () => getPromise(() => authRequest.get(API_URL.GET_AIPARTNER_MEMBERSHIP)),
        //이실장 구독 여부
        subscribes: () => getPromise(() => authRequest.get(API_URL.GET_AIPARTNER_SUBSCRIBES)),
      };
    },
    group() {
      return {
        getMyGroup: () => getPromise(() => authRequest.get(API_URL.GET_MY_GROUP)),
        // 내가 쓴 글(모임 게시판)
        getMyPosts: (data: FeedReqType) =>
          getPromise(() =>
            authRequest.get(`${API_URL.GET_MY_GROUP_POSTS}?page=${data.page}&page_size=${data.page_size}`),
          ),
        // 내가 쓴 글(모임 댓글)
        getMyComments: (data: FeedReqType) =>
          getPromise(() =>
            authRequest.get(`${API_URL.GET_MY_GROUP_COMMENTS}?page=${data.page}&page_size=${data.page_size}`),
          ),
        // 개별모임 정보 조회
        getGroupInfo: (path: string) => getPromise(() => authRequest.get(path)),
        // 개별 모임 회원 목록
        getGroupMemberList: (path: string) => getPromise(() => authRequest.get(path)),
        // 게시글 조회
        getGroupBoardList: (path: string) => getPromise(() => authRequest.get(path)),
        // 개별 모임 초대코드
        getInviteCode: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 정보 수정(이름 및 사진)
        putModifyImageAndName: (path: string, data: IMixedKeyValue | string) =>
          getPromise(() => authRequest.put(path, data)),
        // 모임 정보 수정(소개 및 지역)
        putModifyIntroduceAndArea: (path: string, data: IMixedKeyValue | string) =>
          getPromise(() => authRequest.put(path, data)),
        // 모임 정보 수정(가입 신청 관리)
        puthModifyApprovalManagement: (path: string, data: IMixedKeyValue | string) =>
          getPromise(() => authRequest.put(path, data)),
        // 모임 설정 confing 항목 조회
        getGroupConfig: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 설정 config 설정된 값
        getGroupBasicInfo: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 설정 회원가입 알람 여부
        putGroupAlarm: (path: string, data?: IMixedKeyValue | string) => getPromise(() => authRequest.put(path, data)),
        // 모임 설정 공개 여부
        putIsGroup: (path: string, data?: IMixedKeyValue | string) => getPromise(() => authRequest.put(path, data)),
        // 모임 설정 가입 조건
        putIsGroupCondition: (path: string, data?: IMixedKeyValue | string) =>
          getPromise(() => authRequest.put(path, data)),
        // 지역 검색
        getAreaList: (path: string) => getPromise(() => authRequest.get(path)),
        // 페널티 해제
        putDisabledPenalty: (path: string, data?: IMixedKeyValue | string) =>
          getPromise(() => authRequest.put(path, data)),
        // 페널티 적용
        postEnabledPenalty: (path: string, data?: IMixedKeyValue | string) =>
          getPromise(() => authRequest.post(path, data)),
        // 모임장 위임
        putTransferGroupLeader: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임장 리스트 조회
        getLeaderList: (path: string) => getPromise(() => authRequest.get(path)),
        // 회원 조회 (모임장 제외)
        getMemberList: (path: string) => getPromise(() => authRequest.get(path)),
        // 초대 코드 확인
        getConfirmInviteCode: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 탈퇴
        putLeaveGroup: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 삭제
        deleteGroup: (path: string) => getPromise(() => authRequest.delete(path)),
        // 모임 가입 신청
        postJoinGroup: (path: string, data?: IMixedKeyValue | string) => getPromise(() => authRequest.post(path, data)),
        // 모임 승인 목록 조회
        getApprovalList: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 가입 반려
        putRejectGroup: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 가입 승인
        putApprovalJoinGroup: (path: string) => getPromise(() => authRequest.put(path)),
        // 신고된 게시물 조회
        getReportList: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 게시글 삭제
        deleteGroupPost: (path: string) => getPromise(() => authRequest.delete(path)),
        // 모임 게시글 북마크
        putGroupBookmark: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 게시글 북마크 해제
        deleteGroupUnBookmark: (path: string) => getPromise(() => authRequest.delete(path)),
        // 모임 게시글 수정
        putModifyGroupPost: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 게시글 상세
        getGroupPostDetail: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 게시글 신고
        putRportGroupPost: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 게시글 신고 해제
        putUnReportGroupPost: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 게시글 좋아요 / 좋아요 해제
        postLikeGroupPost: (path: string) => getPromise(() => authRequest.post(path)),
        // 모임 게시글 싫어요 / 싫어요 해제
        postdisLikeGroupPost: (path: string) => getPromise(() => authRequest.post(path)),
        // 모임 게시글 신고
        putReportGroupPost: (path: string, data: IMixedKeyValue | string) =>
          getPromise(() => authRequest.put(path, data)),
        // 게시글 공지로 설정
        putRegisterNotice: (path: string) => getPromise(() => authRequest.put(path)),
        // 게시글 공지로 내리기
        putUnRegisterNotice: (path: string) => getPromise(() => authRequest.put(path)),
        // 중요 공지로 설정
        putRegisterImNotice: (path: string) => getPromise(() => authRequest.put(path)),
        // 중요 공지 내리기
        putUnRegisterImNotice: (path: string) => getPromise(() => authRequest.put(path)),
        // 모임 게시글 신고 해제
        deleteUnReportGroupPost: (path: string) => getPromise(() => authRequest.delete(path)),
        // 모임 댓글 삭제
        deleteGroupComment: (groupId: number, groupPostId: number, id: number) => {
          const replacements: { [key: string]: string } = {
            ':groupId': String(groupId),
            ':groupPostId': String(groupPostId),
            ':id': String(id),
          };

          const path = API_URL.DELETE_GROUP_COMMENT.replace(/:groupId|:groupPostId|:id/g, (matched) => {
            return replacements[matched];
          });
          return getPromise(() => authRequest.delete(path));
        },
        // 모임 공유매물 조회
        postShareList: (path: string, payload: IMixedKeyValue) => getPromise(() => authRequest.post(path, payload)),
        getOffersTypeList: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 구합니다 조회
        postSeekList: (path: string, payload: IMixedKeyValue) => getPromise(() => authRequest.post(path, payload)),
        // 공유매물 북마크
        postShareBookmark: (path: string) => getPromise(() => authRequest.post(path)),
        // 공유매물 북마크 해제
        postShareUnBookmark: (path: string, payload: IMixedKeyValue) =>
          getPromise(() => authRequest.post(path, payload)),
        // 구합니다 북마크
        postFindShareBookmark: (path: string) => getPromise(() => authRequest.post(path)),
        // 구합니다 북마크 해제
        postFindShareUnBookmark: (path: string, payload: IMixedKeyValue) =>
          getPromise(() => authRequest.post(path, payload)),
        // 구합니다 상세
        getFindShareDetail: (path: string) => getPromise(() => authRequest.get(path)),
        // 내가 가입한 공동중개 모임
        getMyGroupList: (path: string) => getPromise(() => authRequest.get(path)),
        // 구합니다 게시글 등록
        postFindShareBoard: (path: string, payload: IMixedKeyValue) =>
          getPromise(() => authRequest.post(path, payload)),
        // 구합니다 매물 유형 조회
        getSeekTypeList: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 페이지뷰
        groupPageView: (data: { group_id: number; url: string; platform: string }) =>
          getPromise(() => authRequest.post(API_URL.POST_GROUP_PAGEVIEW, data)),
      };
    },
    post() {
      return {
        // 내가 쓴 전체 게시판 목록
        getMyPosts: (data: FeedReqType) => getPromise(() => authRequest.post(API_URL.GET_MY_FREE_POSTS, data)),
        // 내가 쓴 전체 게시판 댓글 목록
        getMyComments: (data: FeedReqType) => getPromise(() => authRequest.post(API_URL.GET_MY_FREE_COMMENTS, data)),
        // 전체게시판 게시판별(kind) 게시글 가져오기
        getWithPostBoardPost: (data: IMixedKeyValue | string) =>
          getPromise(() => authRequest.post(API_URL.POST_BOARD_POST_LIST, data)),
        // 게시글 상세가져오기
        getPostDetail: (path: string) => getPromise(() => authRequest.get(path)),
        // 게시글 등록
        postBoardPost: (path: string, data: IWriting | string) => getPromise(() => authRequest.post(path, data)),
        // 게시글 수정
        putBoardPost: (path: string, data: IWriting | string) => getPromise(() => authRequest.put(path, data)),
        // 게시글 삭제
        deleteBoardPost: (path: string) => getPromise(() => authRequest.delete(path)),
        // 전체 게시판 댓글 삭제
        deleteComment: (id: number) => {
          const path = API_URL.DELETE_FREE_COMMENT.replace(/:id/, String(id));
          return getPromise(() => authRequest.delete(path));
        },
        // 전체게시판 게시글 공감(좋아요)처리
        postPostAction: (id: number, action: number) => {
          const path = API_URL.POST_BOARD_ACTION.replace(/:postId/, String(id));
          return getPromise(() => authRequest.post(path, { kind: action }));
        },
        // 전체게시판 북마크 등록
        postBookmarkBoard: (path: string) => getPromise(() => authRequest.post(path)),
        // 전체게시판 북마크 삭제
        deleteBookmarkBoard: (path: string) => getPromise(() => authRequest.delete(path)),
        // 전체게시판 게시판 글 신고
        postReportBoardPost: (path: string, data: string) => getPromise(() => authRequest.post(path, data)),
        // 전체게시판 게시판 글 신고해제
        deleteReportBoardPost: (path: string) => getPromise(() => authRequest.delete(path)),

        // 전체게기판 댓글 목록 가져오기
        getPostCommentsList: (path: string) => getPromise(() => authRequest.get(path)),
        // 전체게시판 개별댓글 수정하기
        putBoardPostComment: (path: string, data: string) => getPromise(() => authRequest.put(path, data)),
        // 전체게시판 개별댓글 삭제하기
        deleteBoardPostComment: (path: string) => getPromise(() => authRequest.delete(path)),
        // 전체게시판 댓글 작성하기
        postBoardPostComment: (path: string, data: string) => getPromise(() => authRequest.post(path, data)),
        // 전체게시판 댓글 좋아요 / 싫어요
        postBoardPostCommentLike: (path: string, data: string) => getPromise(() => authRequest.post(path, data)),
        // 전체게시판 댓글 신고하기
        postBoardPostCommentReport: (path: string, data: string) => getPromise(() => authRequest.post(path, data)),
        // 전체게시판 개별댓글 신고해제하기
        deleteBoardPostCommentReport: (path: string) => getPromise(() => authRequest.delete(path)),
      };
    },
    findShare() {
      return {
        putPostSeek: (path: string, data: IMixedKeyValue) => getPromise(() => authRequest.put(path, data)),
        postSaveSeek: (path: string, data: IMixedKeyValue) => getPromise(() => authRequest.post(path, data)),
        getSeekRelationList: (path: string) => getPromise(() => authRequest.get(path)),
        putModifySeek: (path: string, data: IMixedKeyValue) => getPromise(() => authRequest.put(path, data)),
        putCompleteSeek: (path: string) => getPromise(() => authRequest.put(path)),
        deleteSeek: (path: string) => getPromise(() => authRequest.delete(path)),
      };
    },
    groupPost() {
      return {
        // 모임 게시글 등록
        postGroupPost: (path: string, data: IWriting | string) => getPromise(() => authRequest.post(path, data)),
        // 모임 게시글 상세가져오기
        getGroupPostDetail: (path: string) => getPromise(() => authRequest.get(path)),
        // 모임 게시글 수정
        putGroupPost: (path: string, data: IWriting | string) => getPromise(() => authRequest.put(path, data)),
        // 모임 게시글 삭제
        deleteGroupPost: (groupId: number, id: number) => {
          const replacements: { [key: string]: string } = {
            ':groupId': String(groupId),
            ':groupPostId': String(id),
          };

          const path = API_URL.DELETE_GROUP_POST.replace(/:groupId|:groupPostId/g, (matched) => {
            return replacements[matched];
          });
          return getPromise(() => authRequest.delete(path));
        },
        // 모임 게시글 공유
        postGroupPostShare: (path: string, data: IWriting | string) => getPromise(() => authRequest.post(path, data)),
        // 모임 게시글 댓글 목록 가져오기
        getGroupPostCommentsList: (path: string) => getPromise(() => authRequest.get(path)),
        putGroupPostBookmark: (groupId: number, groupPostId: number) => {
          const replacements: { [key: string]: string } = {
            ':groupId': String(groupId),
            ':groupPostId': String(groupPostId),
          };

          const path = API_URL.PUT_BOOKMARK_GROUP_POST.replace(/:groupId|:groupPostId/g, (matched) => {
            return replacements[matched];
          });
          return getPromise(() => authRequest.put(path));
        },
        // 모임 게시글 공감처리(좋아요, 싫어요)
        postGroupPostAction: (groupId: number, groupPostId: number, action: string) => {
          const replacements: { [key: string]: string } = {
            ':groupId': String(groupId),
            ':groupPostId': String(groupPostId),
            ':action': action,
          };

          const path = API_URL.POST_GROUP_POST_ACTION.replace(
            /:groupId|:groupPostId|:action/g,
            (matched) => replacements[matched],
          );
          return getPromise(() => authRequest.post(path));
        },
        // 모임 댓글 공감처리(좋아요, 싫어요)
        postGroupCommentAction: (groupId: number, groupPostId: number, id: number, action: number) => {
          const replacements: { [key: string]: string } = {
            ':groupId': String(groupId),
            ':groupPostId': String(groupPostId),
            ':groupPostCommentId': String(id),
          };

          const path = API_URL.POST_GROUP_COMMENT_ACTION.replace(
            /:groupId|:groupPostId|:groupPostCommentId/g,
            (matched) => replacements[matched],
          );
          return getPromise(() => authRequest.post(path, { kind: action }));
        },
      };
    },
    bookmark() {
      return {
        // 모임 게시물 북마크 목록
        getBookmarksForGroup: (data: FeedReqType) =>
          getPromise(() =>
            authRequest.get(`${API_URL.GET_BOOKMARK_GROUP_POSTS}?page=${data.page}&page_size=${data.page_size}`),
          ),
        // 자유게시판 게시물 북마크 목록
        getBookmarksForFree: (data: FeedReqType) =>
          getPromise(() => authRequest.post(API_URL.GET_BOOKMARK_FREE_POSTS, data)),
        // 모임 게시물 북마크 해제
        deleteBookmarkForGroup: (groupId: number, groupPostId: number, id: number) => {
          const replacements: { [key: string]: string } = {
            ':groupId': String(groupId),
            ':groupPostId': String(groupPostId),
            ':groupPostBookmarkId': String(id),
          };

          const path = API_URL.DELETE_BOOKMARK_GROUP_POST.replace(
            /:groupId|:groupPostId|:groupPostBookmarkId/g,
            (matched) => {
              return replacements[matched];
            },
          );
          return getPromise(() => authRequest.delete(path));
        },
        // 자유게사판 게시물 북마크 해제
        deleteBookmarkForFree: (id: number) => {
          const path = API_URL.DELETE_BOOKMARK_FREE_POST.replace(/:bookId/, String(id));
          return getPromise(() => authRequest.delete(path));
        },
        // 공유매물 검색 (북마크)
        getBookmarksForOfferings: (data: FeedOfferSearchReqType) => {
          data.bookmark = 1;
          data.sort = 1;
          return getPromise(() => authRequest.post(API_URL.POST_SEARCH_OFFERINGS, data));
        },
        // 구합니다 검색 (북마크)
        getBookmarksForSeeks: (data: FeedSeekSearchReqType) => {
          data.bookmark = 1;
          data.sort = 1;
          return getPromise(() => authRequest.post(API_URL.POST_SEARCH_SEEKS, data));
        },
        // 공유매물 북마크 삭제
        deleteBookmarkForOffering: (id: number) =>
          getPromise(() => authRequest.post(API_URL.DELETE_BOOKMARK_OFFERING, { ids: [id] })),
        // 구합니다 북마크 삭제
        deleteBookmarkForSeek: (id: number) =>
          getPromise(() => authRequest.post(API_URL.DELETE_BOOKMARK_SEEK, { ids: [id] })),
      };
    },
    broker() {
      return {
        deleteBrokerLeave: () => getPromise(() => authRequest.delete(API_URL.DELETE_BROKER_LEAVE)),
        deleteBrokerFire: (id: number) => getPromise(() => authRequest.post(API_URL.POST_BROKER_FIRE, { user_id: id })),
        getBrokerList: () => getPromise(() => authRequest.get(API_URL.GET_BROKER_LIST)),
        getBrokerVerifyCheck: () => getPromise(() => authRequest.get(API_URL.GET_BROKER_VERIFY_CHECK)),
        getBrokerFireCheck: (id: number) => {
          const path = API_URL.GET_BROKER_FIRE_CHECK.replace(/:userId/, String(id));
          return getPromise(() => authRequest.get(path));
        },
        //휴폐업 조회
        getIsCompany: (data: IisCompany) =>
          getPromise(() => {
            return authRequest.get(API_URL.GET_BROKER_ISCOMPANY + '?registration_number=' + data.registration_number);
          }),
        //중개업소 목록 조회
        getJuridicalSearch: (data: IJuridicalSearch) =>
          getPromise(() => {
            return authRequest.get(API_URL.GET_BROKER_JURIDICALSEARCH + '?jurirno=' + data.jurirno);
          }),
        //중개업소 직원 조회
        getJuridicalMember: (data: IJuridicalSearch) =>
          getPromise(() => {
            return authRequest.get(API_URL.GET_BROKER_JURIDICAL_MEMBER + '?jurirno=' + data.jurirno);
          }),
        //주소 검색
        postCompanyArea: (data?: { si_code?: string; gu_code?: string; dong_code?: string }) =>
          getPromise(() => {
            return authRequest.post(API_URL.POST_BROKER_COMPANY_AREA, data);
          }),
        //중개업소 등록 요청
        postCompanyRegister: (data: { jurirno: string; addr: string }) =>
          getPromise(() => {
            return authRequest.post(API_URL.POST_BROKER_COMPANY_REGISTER, data);
          }),
        postVerify: (data: { registration_number: string; jurirno: string; brkrNm: string }) =>
          getPromise(() => {
            return authRequest.post(API_URL.POST_BROKER_VERIFY, data);
          }),
        //이실장 연동 정보 가져오기
        getAipartner: () =>
          getReturnPromise(() => {
            return authRequest.get(API_URL.GET_BROKER_AIPARTNER);
          }),
        //이실장 연동 정보 가져오기
        PostAipartnerVerify: (data: string) =>
          getPromise(() => {
            return authRequest.post(API_URL.POST_BROKER_VERIFY_AIPARTNER, data);
          }),
      };
    },
    coagency() {
      return {
        // 부동산 매물 종류
        getRETypeList: (path: string) => getResPromise(() => authRequest.get(path)),
        // 주소
        postAddress: (path: string, param: string) => getResPromise(() => authRequest.post(path, param)),
        // common GET ('단지목록', 그룹리스트, 관계리스트)
        getCommonList: (path: string) => getResPromise(() => authRequest.get(path)),
        // 매물 간편등록
        postSubmit: (path: string, param: string) => getResPromise(() => authRequest.post(path, param)),
        // 매물 상세 등록, 매물 수정,
        putSubmit: (path: string, data: string) => getResPromise(() => authRequest.put(path, data)),
        // 건축물대장 building info, 층정보, 면적정보,
        postBuilding: (path: string, param: string) => getResPromise(() => authRequest.post(path, param)),
        // 공유매물 삭제
        deleteCoagency: (path: string) => getResPromise(() => authRequest.delete(path)),

        //이실장 매물 가져오기 (목록)
        import: (mode: TOfferImportMode, data?: TOfferImportRequest) =>
          getPromise(() => authRequest.post(`${API_URL.GET_AIPARTNER_OFFERING}/${mode}`, data)),
        //이실장 매물 가져오기 (한개)
        convert: (mode: string, seq: number) =>
          getResPromise(() => authRequest.get(`${API_URL.GET_AIPARTNER_OFFERING_CONVERT}/${mode}/${seq}`)),
        //이실장 매물 여러개 연동
        importMultiConvert: (data: string) =>
          getPromise(() => authRequest.post(`${API_URL.POST_AIPARTNER_OFFERING_CONVERT}`, data)),
        //이실장 전체 매물 가져오기
        getImportAllList: () =>
          getPromise(() => authRequestUnlimited.post(`${API_URL.POST_AIPARTNER_OFFERING_All_LIST}`)),
        //이실장 전체 매물 연동 (반복)
        importAllConvert: () =>
          getPromise(() => authRequestUnlimited.post(`${API_URL.POST_AIPARTNER_OFFERING_All_CONVERT}`)),
        // 매물 수정 사유 목록
        getImproveList: (path: string) => getPromise(() => authRequest.get(path)),
        // 매물 수정 요청
        postImprove: (path: string, data: IMixedKeyValue) => getResPromise(() => authRequest.post(path, data)),
        // 매물 수정 요청 확인
        deletePostImprove: (path: string) => getResPromise(() => authRequest.delete(path)),
        // 매물 공유 중지
        postStopSharePost: (path: string) => getResPromise(() => authRequest.post(path)),
        // 매물 거래 완료 처리
        putCompleteCoagency: (path: string) => getPromise(() => authRequestUnlimited.put(path)),
      };
    },
    user() {
      return {
        // 유저 차단하기
        postBlockUser: (
          path: string,
          data: {
            block_user_id: number;
          },
        ) => getPromise(() => authRequest.post(path, data)),
        // 유저 차단 해제
        deleteBlockUser: (path: string) => getPromise(() => authRequest.delete(path)),
      };
    },
    app() {
      return {
        // 앱 버전정보
        getAppVersion: () =>
          getPromise(() => {
            return authRequest.get(API_URL.GET_APP_VERSION);
          }),
        // 시스템점검
        getAppInfo: () => getPromise(() => authRequest.get(API_URL.GET_APP_INFO)),
      };
    },
  };
};

export default network;
