import { IJuridicalMember } from '<broker>';

import cn from 'classnames';
import { useEffect, useState } from 'react';
import CommonStyles from 'styles/common.module.css';

const BrokerListMember = (props: {
  index: number;
  member: IJuridicalMember;
  handleSelectMember: (_brkrNm: string) => void;
}) => {
  const {
    ldCode,
    ldCodeNm,
    jurirno,
    bsnmCmpnm,
    brkrNm,
    brkrAsortCode,
    brkrAsortCodeNm,
    crqfcNo,
    crqfcAcqdt,
    ofcpsSeCode,
    ofcpsSeCodeNm,
    lastUpdtDt,
    type,
  } = props.member;
  const { index, handleSelectMember } = props;

  const handleMemberSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSelectMember(e.target.value);
  };

  return (
    <>
      <div className={CommonStyles.row}>
        <div className={CommonStyles['col-td']}>{ofcpsSeCode == '01' ? ofcpsSeCodeNm : brkrAsortCodeNm}</div>
        <div className={CommonStyles['col-td']}>{brkrNm}</div>
        <div className={cn(CommonStyles['col-td'], CommonStyles['radio-div'])}>
          <div className={CommonStyles['radio-box']}>
            <input
              type="radio"
              id={'inputMem_' + jurirno + '_' + index}
              name="radio"
              value={brkrNm}
              onChange={(e) => {
                if (e.target.checked) {
                  handleMemberSelected(e);
                }
              }}
            />
            <label htmlFor={'inputMem_' + jurirno + '_' + index} className={CommonStyles['label-info']}></label>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrokerListMember;
