import cn from 'classnames';
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import { IMixedKeyValue } from '<modal>';
import { useEffect, useState } from 'react';
import * as APIS from 'api/common';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { closeModal } from 'redux/slices/modal';

/**
 * @description 구합니다 modal
 * @Class
 * @category Components
 * @subcategory 구합니다 sheet | modal
 * @component
 * @param {string} props.title sheet title
 * @returns {JSX.Element}
 */
const FindShareModal = () => {
  const dispatch = useAppDispatch();
  const { modalType, action } = useAppSelector((state: RootState) => state.modal);
  const [list, setList] = useState<IMixedKeyValue[]>([]);

  useEffect(() => {
    const f = async () => {
      fetchRelationList();
    };

    f();
  }, []);

  // 관계 목록 조회
  const fetchRelationList = async () => {
    try {
      const res = await APIS.getSeekRelationList('/seeks/relation/list');
      if (res.length > 0) {
        setList([...res]);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  // 관계 클릭
  const handleClickType = (val: IMixedKeyValue) => {
    action?.(val);
    //if(action)
  };

  const handleModalClose = () => {
    dispatch(closeModal({ modalType: modalType, isOpen: false, isConfirm: true }));
  };

  return (
    <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
      <div className={cn(Styles['popup-content'], CommonStyles['button-list'])}>
        {list.map((item, index) => {
          return (
            <button key={index} onClick={() => handleClickType(item)}>
              {item.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default FindShareModal;
