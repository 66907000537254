import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { IMixedKeyValue } from '<modal>';
import _ from 'lodash';
import { loadComments, addComment, deleteComment, addChildren, deleteChildren } from './comment';

const initialState: IMixedKeyValue = {
  allBoard: {
    posts: [],
    total: null,
    page: null,
  },
  freeBoard: {
    posts: [],
    total: null,
    page: null,
  },
  issue: {
    posts: [],
    total: null,
    page: null,
  },
  boardKind: null,
  currentKindId: null,
  postLike: {
    hated_count: null,
    liked_count: null,
    post_action: [],
    shared_count: null,
  },
  likeTargetId: null,
  postId: null,
  targetPost_comment_count: null,
  shareBoardData: {},
};

/**
 * @description 전체게시판 board
 * @Class
 * @category redux
 * @subcategory slice
 */
const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    getAllBoard: (state, action) => {
      state.currentKindId = action.payload.currentKindId;
      if (action.payload.page === 1) {
        state.allBoard.posts = _.uniqBy(action.payload.posts, 'id');
      } else {
        state.allBoard.posts = _.uniqBy(state.allBoard.posts.concat(action.payload.posts), 'id');
      }
      state.allBoard.total = action.payload.total;
      state.allBoard.page = action.payload.page;
    },
    getFreeBoard: (state, action) => {
      state.currentKindId = action.payload.currentKindId;
      if (action.payload.page === 1) {
        state.freeBoard.posts = _.uniqBy(action.payload.posts, 'id');
      } else {
        state.freeBoard.posts = _.uniqBy(state.freeBoard.posts.concat(action.payload.posts), 'id');
      }
      state.freeBoard.total = action.payload.total;
      state.freeBoard.page = action.payload.page;
    },
    getIssue: (state, action) => {
      state.currentKindId = action.payload.currentKindId;
      if (action.payload.page === 1) {
        state.issue.posts = _.uniqBy(action.payload.posts, 'id');
      } else {
        state.issue.posts = _.uniqBy(state.issue.posts.concat(action.payload.posts), 'id');
      }
      state.issue.total = action.payload.total;
      state.issue.page = action.payload.page;
    },
    setPostLike: (state, action) => {
      const _currentKindId = action.payload.currentKindId;
      const _postLike = action.payload.postLike;
      const _likeTargetId = action.payload.likeTargetId;
      state.currentKindId = action.payload.currentKindId;
      state.postLike = action.payload.postLike;
      state.likeTargetId = action.payload.likeTargetId;
      let _newLikeTarget;
      if (_currentKindId === null) {
        _newLikeTarget = state.allBoard.posts.find((item: IMixedKeyValue) => item.id === _likeTargetId);
      } else if (_currentKindId === 1500) {
        _newLikeTarget = state.freeBoard.posts.find((item: IMixedKeyValue) => item.id === _likeTargetId);
      } else if (_currentKindId === 1501) {
        _newLikeTarget = state.issue.posts.find((item: IMixedKeyValue) => item.id === _likeTargetId);
      } else {
        _newLikeTarget = state.allBoard.posts.find((item: IMixedKeyValue) => item.id === _likeTargetId);
      }
      _newLikeTarget.liked_count = _postLike.liked_count;
      _newLikeTarget.hated_count = _postLike.hated_count;
      _newLikeTarget.shared_count = _postLike.shared_count;
      _newLikeTarget.post_action = _postLike.post_action;
    },
    getCommentCount: (state, action) => {
      const _newCount = action.payload.comment_count;
      let _allBoardIndex;
      let _freeBoardIndex;
      let _issueBoardIndex;
      console.log('slice in _newCount', _newCount, state.postId);
      if (state.currentKindId === null && state.postId && state.allBoard.posts.length) {
        _allBoardIndex = state.allBoard.posts?.findIndex((item: IMixedKeyValue) => item.id === state.postId);
        state.allBoard.posts[_allBoardIndex].comments_list_count = _newCount;
      }
      if (state.currentKindId === 1500 && state.postId && state.freeBoard.posts.length) {
        _freeBoardIndex = state.freeBoard.posts?.findIndex((item: IMixedKeyValue) => item.id === state.postId);
        state.freeBoard.posts[_freeBoardIndex].comments_list_count = _newCount;
      } else if (state.currentKindId === 1501 && state.postId && state.issue.posts.length) {
        _issueBoardIndex = state.issue.posts?.findIndex((item: IMixedKeyValue) => item.id === state.postId);
        state.issue.posts[_issueBoardIndex].comments_list_count = _newCount;
      }
    },
    updatePost: (state, action) => {
      const _post_report = action.payload.post_report;
      const _is_possible_report = action.payload.is_possible_report;
      const _user_block = action.payload.user_block;
      const _user = action.payload.user;
      const _post_bookmark = action.payload.post_bookmark;
      const _targetPostid = action.payload.postId;
      const _type = action.payload.type;
      let _allBoardIndex;
      let _freeBoardIndex;
      let _issueBoardIndex;
      if (state.currentKindId === null && state.allBoard.posts.length) {
        if (_type === 'report') {
          _allBoardIndex = state.allBoard.posts?.findIndex((item: IMixedKeyValue) => item.id === _targetPostid);
          state.allBoard.posts[_allBoardIndex].post_report = _post_report;
          state.allBoard.posts[_allBoardIndex].is_possible_report = _is_possible_report;
        } else if (_type === 'bookmark') {
          _allBoardIndex = state.allBoard.posts?.findIndex((item: IMixedKeyValue) => item.id === _targetPostid);
          state.allBoard.posts[_allBoardIndex].post_bookmark = _post_bookmark;
        } else {
          [...state.allBoard.posts].map((item: IMixedKeyValue) =>
            item.user_id === _user?.id ? _.assign(item, { user_block: _user_block }) : item,
          );
        }
      }
      if (state.currentKindId === 1500 && state.freeBoard.posts.length) {
        _freeBoardIndex = state.freeBoard.posts?.findIndex((item: IMixedKeyValue) => item.id === _targetPostid);
        if (_type === 'report') {
          state.freeBoard.posts[_freeBoardIndex].post_report = _post_report;
          state.freeBoard.posts[_freeBoardIndex].is_possible_report = _is_possible_report;
        } else if (_type === 'bookmark') {
          _freeBoardIndex = state.freeBoard.posts?.findIndex((item: IMixedKeyValue) => item.id === _targetPostid);
          state.freeBoard.posts[_allBoardIndex].post_bookmark = _post_bookmark;
        } else {
          [...state.freeBoard.posts].map((item: IMixedKeyValue) =>
            item.user_id === _user?.id ? _.assign(item, { user_block: _user_block }) : item,
          );
        }
      } else if (state.currentKindId === 1501 && state.issue.posts.length) {
        _issueBoardIndex = state.issue.posts?.findIndex((item: IMixedKeyValue) => item.id === _targetPostid);
        if (_type === 'report') {
          state.issue.posts[_issueBoardIndex].post_report = _post_report;
          state.issue.posts[_issueBoardIndex].is_possible_report = _is_possible_report;
        } else if (_type === 'bookmark') {
          state.issue.posts[_allBoardIndex].post_bookmark = _post_bookmark;
        } else {
          [...state.issue.posts].map((item: IMixedKeyValue) =>
            item.user_id === _user?.id ? _.assign(item, { user_block: _user_block }) : item,
          );
        }
      }
    },
    updateList: (state, action) => {
      const _targetPostid = action.payload.postId;
      state.postId = action.payload.postId;
      if (state.currentKindId === null && state.allBoard.posts.length) {
        state.allBoard.posts = state.allBoard.posts?.filter((item: IMixedKeyValue) => item.id !== _targetPostid);
      }
      if (state.currentKindId === 1500 && state.freeBoard.posts.length) {
        state.freeBoard.posts = state.freeBoard.posts?.filter((item: IMixedKeyValue) => item.id !== _targetPostid);
      }
      if (state.currentKindId === 1501 && state.issue.posts.length) {
        state.issue.posts = state.issue.posts?.filter((item: IMixedKeyValue) => item.id !== _targetPostid);
      }
    },
    getShareData: (state, action) => {
      state.shareBoardData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadComments, (state, action) => {
      state.boardKind = action.payload.boardKind;
      state.postId = action.payload.postId;
    });
    builder.addCase(addComment, (state, action) => {
      state.targetPost_comment_count = action.payload.comments.comment_count;
    });
    builder.addCase(deleteComment, (state, action) => {
      state.targetPost_comment_count = action.payload.comments.comment_count;
    });
    builder.addCase(addChildren, (state, action) => {
      state.targetPost_comment_count = action.payload.comments.comment_count;
    });
    builder.addCase(deleteChildren, (state, action) => {
      state.targetPost_comment_count = action.payload.comments.comment_count;
    });
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  getAllBoard,
  getFreeBoard,
  getIssue,
  setPostLike,
  getCommentCount,
  updatePost,
  updateList,
  getShareData,
} = boardSlice.actions;

export default boardSlice;
