import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import GradeBadge from 'components/common/Badge/GradeBadge';
import Button from 'components/common/Button/Button';

// 공동 모임장 설정
const GroupCommonLeader = () => {
  return (
    <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
      <div className={CommonStyles['sub-title']}>
        회원<b className={CommonStyles['co-dorg']}>4</b>
      </div>
      <div className={CommonStyles['member-list']}>
        {Array.from({ length: 5 }).map((item, index: number) => {
          return (
            <div key={index} className={CommonStyles.list}>
              <div className={CommonStyles['member-information']}>
                <Button className={CommonStyles.clickable}>
                  <div className={CommonStyles.profile}>
                    <div
                      className={CommonStyles['img-div']}
                      style={{ backgroundImage: `url('../image/test/test5.jpg')` }}
                    ></div>
                    <GradeBadge grade="새싹" />
                  </div>
                  <div className={CommonStyles['mem-info']}>
                    <p className={CommonStyles.name}>더비즈</p>
                    <div className={CommonStyles['divide-box']}>
                      <p>방배동</p>
                      <p>대표공인중개사</p>
                      <p>공동모임장</p>
                    </div>
                  </div>
                </Button>
                <Button type="line">변경</Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupCommonLeader;
