import CommonStyles from 'styles/common.module.css';

interface IconInterface {
  type: string; // 아이콘 타입 ex) dislike, like, search 등등 *required !!아이콘이 2개이상일경우 콤마로 구분해준다. 단 공백없이
}

/**
 * @description Icon
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {string} props.type  아이콘 타입 ex) dislike, like, search 등등 *required !!아이콘이 2개이상일경우 콤마로 구분해준다. 단 공백없이
 * @returns {JSX.Element}
 */
const Icon: React.FC<IconInterface> = ({ type = '' }) => {
  const parseMultipleType = (type: string): string => {
    let parseType: string = '';
    if (type.includes(',')) {
      const arr = type.split(',');
      arr.forEach((item: string) => {
        parseType += ` ${CommonStyles[item]}`;
      });
    } else {
      parseType += `${CommonStyles[type]}`;
    }
    return parseType;
  };

  return <i className={parseMultipleType(type)} />;
};

export default Icon;
