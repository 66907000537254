import { useState, useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { batch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionsBoard from 'redux/slices/board';
import * as ActionsUser from 'redux/slices/user';
import * as APIS from 'api/group';
import * as APISUSER from 'api/users';
import useToast from 'hook/useToast';
import moment from 'moment';
import { reportPostComment } from 'api/comment';
import { getBoardPostDetail } from 'api/board';

import cn from 'classnames';
import { onView } from 'utils';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

// configs
import * as Config from 'configs/configs';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';
import { IMixedKeyValue } from '<modal>';

interface Props {
  type?: string;
  title?: string;
  targetData?: IMixedKeyValue;
  targetDepth?: string;
  propContentHandler?: (c: string) => void;
  setIsSheetOpen?: (isSheetOpen: boolean) => void;
  infoMe?: string;
}

/**
 * @description common bottom sheet
 * @Class
 * @category Components
 * @subcategory common sheet
 * @component
 * @returns {JSX.Element}
 */
const BottomSheet = (props: Props) => {
  const { type, title, targetData, targetDepth, setIsSheetOpen }: Props = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clickRef = useRef<boolean>(false);
  const { setToastMessage } = useToast();
  const groupData = useAppSelector((state: RootState) => state.group);
  const { modalType, data, isOpen, infoMe } = useAppSelector((state: RootState) => state.modal);
  const possibleReport = useAppSelector((state: RootState) => state.user.is_possible_report);
  const { myInfo, permissoin, group } = groupData;
  const historyLocation = useLocation();
  const { pathname } = historyLocation;
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const boardType = data?.cate?.key === 1500 ? 'free-board' : data?.cate?.key === 1501 ? 'issue' : 'group';
  // 신고하기
  const [content, setContent] = useState('');
  const contentData = [
    { id: 1, value: '스팸입니다.' },
    { id: 2, value: '나체 이미지이거나 음란한 내용을 담고 있습니다.' },
    { id: 3, value: '부적절한 상품을 팔거나 홍보하고 있습니다.' },
    { id: 4, value: '자해나 자살과 관련된 내용입니다.' },
    { id: 5, value: '저작권, 명예훼손 등 기타 권리를 침해하는 내용입니다.' },
    { id: 6, value: '특정인의 개인정보가 포함되어 있습니다.' },
    { id: 7, value: '혐오를 조장하는 내용입니다.' },
  ];

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    }, 200);
  };
  const handleConfirm = () => {
    console.log('confirm !!! confirm action');
    handleModalClose();
  };

  /**
   * @description 전체게시판, 모임 post 수정하기
   * @returns {void}
   */
  const handleModifyAllPosts = (groupId = ''): void => {
    if (groupId) {
      navigate(`/writing?mode=edit&type=${boardType}&postId=${data?.id}&groupId=${groupId}&name=${data?.group?.name}`);
      console.log('수정하기001');
    } else {
      if (import.meta.env.MODE === 'localdev') {
        navigate(`/writing?mode=edit&type=${boardType}&postId=${data?.id}`);
        console.log('수정하기002');
      } else {
        console.log('수정하기003');
        onView(`${import.meta.env.VITE_APP_DOMAIN}/writing?mode=edit&type=${boardType}&postId=${data?.id}`);
      }
    }
    handleModalClose();
  };

  /**
   * @description 전체게시판 게시글 공유하기
   * @returns {void}
   */
  const handleShareAllPosts = (): void => {
    dispatch(ActionModal.openModal({ modalType: 'bottomSheet_share', data: data!, isOpen: true }));
  };

  /**
   * @description 공유할 공유모임선택 ==> bottomsheet에서 page로 수정변경
   * @returns {void}
   */
  const handleSelectGroup = (): void => {
    console.log('공유하기 모임 select data==>', data);
    // dispatch(ActionModal.openModal({ modalType: 'groupSelectBotSheet_select_group', data: data!, isOpen: true }));
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' || data?.group_id) {
      if (data?.group_id) {
        navigate(`/groupselect`);
      } else {
        navigate(`/groupselect/${data?.id}`);
      }
    } else {
      onView(`${import.meta.env.VITE_APP_DOMAIN}/groupselect/${data?.id}`);
    }
    // dispatch(ActionsBoard.getShareData(data));
    // 모임에서만 공유시도시 web과 webview에서 상태를 공유하기 위해서 (이때 bottom sheet는 웹것을 사용함)
    if (data?.group_id) {
      localStorage.setItem('shareData', JSON.stringify(data));
    }
  };

  /**
   * @description 전체게시판 게시글 카카오톡 공유하기
   * @returns {void}
   */
  const handleSharePostKakao = (d: IMixedKeyValue): void => {
    if (!d.id) return;
    const kakaoBody = {
      title: `이실장넷 글 공유드립니다.`,
      description: `[${d?.cate?.label}] ${d?.content}`,
      imageUrl: d?.photos?.length > 0 ? d?.photos[0]?.url : `${location.origin}/images/text_share_image.png`,
      link: {
        mobileWebUrl: `${location.origin}/postdetail/${d?.id}`,
        webUrl: `${location.origin}/allpost?id=${d?.id}`,
      },
    };

    if (window.webkit) {
      window.webkit.messageHandlers.shared.postMessage(kakaoBody);
    } else if (window.Android) {
      window.Android.shared(JSON.stringify(kakaoBody));
    }
  };

  /**
   * @description 전체게시판 북마크 등록
   * @returns {Promise<void>}
   */
  const handleBookMarkAllPosts = async (): Promise<void> => {
    console.log('전체게시판 포스트 북마크 연결');
    await network()
      .post()
      .postBookmarkBoard(`/posts/${data?.id}/bookmark`)
      .then(async (res) => {
        console.log('success==>', res);
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.BOOKMARK_COMPLETE,
          type: 'message',
        });
        handleModalClose();
        dispatch(ActionModal.confirmModal({ modalType: 'success_bookmark_complete', isConfirm: true, isOpen: false }));
        dispatch(
          ActionsBoard.updatePost({
            type: 'bookmark',
            postId: data?.id,
            post_bookmark: res?.post_bookmark,
          }),
        );
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setToastMessage({
            duration: 2000,
            content: err.response.data.message,
            type: 'message',
          });
        } else {
          console.error('error', err);
        }
      });
  };

  /**
   * @description 전체게시판 포스트 북마크 해제
   * @param {number} bookId
   * @returns {promise<void>}
   */
  const handleUnBookMarkAllPosts = async (bookId: number) => {
    console.log('전체게시판 포스트 북마크 해제');
    await network()
      .post()
      .deleteBookmarkBoard(`/posts/bookmarks/${bookId}`)
      .then(async (res) => {
        console.log('success==>', res);
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.BOOKMARK_REMOVE,
          type: 'message',
        });
        handleModalClose();
        dispatch(ActionModal.confirmModal({ modalType: 'success_bookmark_remove', isConfirm: true, isOpen: false }));
        dispatch(
          ActionsBoard.updatePost({
            type: 'bookmark',
            postId: data?.id,
            post_bookmark: res?.post_bookmark,
          }),
        );
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setToastMessage({
            duration: 2000,
            content: err.response.data.message,
            type: 'message',
          });
        } else {
          console.error('error', err);
        }
      });
  };

  // 모임 게시글 북마크
  const handleGroupBookMarkPost = async () => {
    if (!data?.group_id) return;
    try {
      const res = await APIS.putGroupBookmark(`/groups/${data.group_id}/posts/${data.id}/bookmark`);
      if (res?.data?.id) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.BOOKMARK_COMPLETE,
          type: 'message',
        });
        handleModalClose();
        dispatch(
          ActionModal.confirmModal({
            modalType: 'success_bookmark_complete',
            isConfirm: true,
            isOpen: false,
          }),
        );
      }
    } catch (err) {
      console.log(err, 'res');
    }
  };

  // 모임 게시글 북마크 해제
  const handleGroupUnBookMarkPost = async (bookId: number) => {
    if (!data?.group_id) return;
    if (!bookId) return;
    try {
      const res = await APIS.deleteGroupUnBookmark(`/groups/${data.group_id}/posts/${data.id}/bookmark/${bookId}`);
      if (res?.data?.id) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.BOOKMARK_REMOVE,
          type: 'message',
        });
        handleModalClose();
        dispatch(ActionModal.confirmModal({ modalType: 'success_bookmark_remove', isConfirm: true, isOpen: false }));
      }
    } catch (err) {
      console.log(err, 'res');
    }
  };

  /**
   * @description 전체게시판 post 삭제
   * @returns {void}
   */
  const handleDeleteAllPosts = (): void => {
    // groupId가 있을 경우 모임 게시글 삭제 아닐 경우 게시판 게시글 삭제
    const fn = data?.group_id ? () => deleteGroupPost() : () => deletePost();
    dispatch(ActionModal.openModal({ modalType: 'confirm_delete_post', action: fn, data: data!, isOpen: true }));
  };

  // 모임 게시글 삭제
  const deleteGroupPost = async () => {
    if (!data?.group_id) return;
    try {
      dispatch(onLoad());
      const res = await APIS.deleteGroupPost(`/groups/${data.group_id}/posts/${data.id}`);
      if (res.data.message === 'OK') {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.POST_DELETE,
          type: 'message',
        });
        dispatch(ActionModal.confirmModal({ modalType: 'success_delete', isConfirm: true, isOpen: false }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  /**
   * @description 게시글 삭제 action
   * @returns {Promise<void>}
   */
  const deletePost = async (): Promise<void> => {
    try {
      const res = await network()
        .post()
        .deleteBoardPost(`/posts/${data?.id}`);
      console.log('res 포스트 삭제', res);
      const _data = res.data;
      if (_data?.message === '') {
        console.log('success==>', _data);
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.POST_DELETE,
          type: 'message',
        });
        dispatch(ActionModal.confirmModal({ modalType: 'success_delete', isConfirm: true, isOpen: false }));
        dispatch(ActionsBoard.updateList({ postId: data?.id }));
      }
    } catch (err) {
      console.error('error', err);
    }
  };

  /**
   * @description 전체게시판 포스트 신고
   * @returns {void}
   */
  const handleOnReport = (): void => {
    console.log('모임 및 전체게시판 게시글포스트 신고 possibleReport alert');
    // possibleReport : 10건까지는 true, 11건 이상일 경우 false
    if (possibleReport === false) {
      dispatch(ActionModal.openModal({ modalType: 'alert_possible_report', isOpen: true }));
      return;
    }
    const fn = data?.group_id ? () => handleClickGroupPostReport() : () => handleReportApi();
    dispatch(ActionModal.openModal({ modalType: 'bottomSheet_report', action: fn, data: data!, isOpen: true }));
  };

  /**
   * @description 전체게시판 게시글 신고 action api
   * @returns {Promise<void>}
   */
  const handleReportApi = async (): Promise<void> => {
    if (!content) {
      return setToastMessage({
        duration: 2000,
        content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_CONTENT,
        type: 'message',
      });
    }
    const params = {
      content,
    };
    const body = JSON.stringify(params);
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await network()
        .post()
        .postReportBoardPost(`/posts/${data?.id}/report`, body);
      console.log('res 포스트 신고완료', res);
      if (res?.id && res.content) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_COMPLETE,
          type: 'message',
        });
        handleModalClose();
        dispatch(ActionModal.confirmModal({ modalType: 'success_report', isConfirm: true, isOpen: false }));
        dispatch(
          ActionsBoard.updatePost({
            type: 'report',
            postId: data?.id,
            post_report: res?.post_report,
            is_possible_report: res?.is_possible_report,
          }),
        );
      }
    } catch (err) {
      console.error('error', err);
      clickRef.current = false;
    } finally {
      clickRef.current = false;
    }
  };

  // 모임 게시글 신고
  const handleClickGroupPostReport = async () => {
    if (!content) {
      return setToastMessage({
        duration: 2000,
        content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_CONTENT,
        type: 'message',
      });
    }
    const params = {
      content,
    };
    const body = JSON.stringify(params);

    if (!data?.group_id && !data?.id) return;
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await APIS.putReportGroupPost(`/groups/${data.group_id}/posts/${data.id}/report`, body);

      if (res?.data?.id) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_COMPLETE,
          type: 'message',
        });
        handleModalClose();
        batch(() => {
          dispatch(ActionModal.confirmModal({ modalType: 'success_report', isConfirm: true, isOpen: false }));
          dispatch(ActionsUser.setPossibleReport({ is_possible_report: res?.data.is_possible_report }));
        });
      }
    } catch (err) {
      console.log(err, 'err');
      clickRef.current = false;
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description comment 게시글 신고
   * @returns {void}
   */
  const handleCommentReport = (t: IMixedKeyValue, depth: string): void => {
    if (!content) {
      return setToastMessage({
        duration: 2000,
        content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_CONTENT,
        type: 'message',
      });
    }

    const params = {
      content,
    };
    if (data?.isType === 'coagency' || data?.isType === 'looking-for') {
      dispatch(reportPostComment!(t?.parent_id, t?.id, params, depth, data.id, undefined, data?.isType));
    } else {
      dispatch(reportPostComment!(t?.parent_id, t?.id, params, depth));
    }

    setIsSheetOpen!(false);
  };

  // 모임 게시글 신고 해제
  const handleClickGroupPostUnReport = async (reportId: number) => {
    if (!reportId && !data?.group_id && !data?.id) return;
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await APIS.deleteUnReportGroupPost(`/groups/${data?.group_id}/posts/${data?.id}/report/${reportId}`);
      console.log(res, 'res');
      if (res?.data?.is_possible_report) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_REMOVE,
          type: 'message',
        });
        handleModalClose();
        dispatch(ActionModal.confirmModal({ modalType: 'success_report_remove', isConfirm: true, isOpen: false }));
      }
    } catch (err) {
      console.error('error', err);
      if (err instanceof AxiosError) {
        if (err?.response?.status === 422) {
          setToastMessage({
            duration: 2000,
            content: err.response.data.message,
            type: 'message',
          });
        } else {
          console.error('error', err);
        }
      }
      clickRef.current = false;
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 게시글 신고 해제 action api
   * @returns {Promise<void>}
   */
  const handleRemoveReport = async (reportId: number): Promise<void> => {
    await network()
      .post()
      .deleteReportBoardPost(`/posts/reports/${reportId}`)
      .then(async (res) => {
        if (res?.is_possible_report) {
          setToastMessage({
            duration: 2000,
            content: Config.TOAST_MESSAGE_TYPE.POST_REPORT_REMOVE,
            type: 'message',
          });
          handleModalClose();
          dispatch(ActionModal.confirmModal({ modalType: 'success_report_remove', isConfirm: true, isOpen: false }));
          dispatch(
            ActionsBoard.updatePost({
              type: 'report',
              postId: data?.id,
              post_report: res?.post_report,
              is_possible_report: res?.is_possible_report,
            }),
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setToastMessage({
            duration: 2000,
            content: err.response.data.message,
            type: 'message',
          });
        } else {
          console.error('error', err);
        }
      });
  };

  /**
   * @description 신고내용
   */
  const listData = contentData?.map((item, index) => {
    return (
      <div key={item.id} className={cn(Styles['check-box'], CommonStyles['check-box'])} style={{ textAlign: 'left' }}>
        <input
          type="checkbox"
          style={{ width: '0px', height: '0px' }}
          id={`chk${index}`}
          value={item.value}
          checked={content === item.value}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
        <label htmlFor={`chk${index}`} className={CommonStyles['label-info']}>
          {item.value}
        </label>
      </div>
    );
  });

  // 게시글 공지로 등록
  const handleClickRegisterNotice = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.putRegisterNotice(`/groups/${data?.group_id}/posts/${data?.id}/notice`);
      if (res?.data?.message) {
        dispatch(ActionModal.confirmModal({ modalType: 'success_notice_complete', isConfirm: true, isOpen: false }));
        setToastMessage({
          duration: 2000,
          content: res?.data?.message,
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 중요 게시글로 등록
  const handleClickRegisterImNotice = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.putRegisterImNotice(`/groups/${data?.group_id}/posts/${data?.id}/important_notice`);
      if (res?.data?.message) {
        dispatch(ActionModal.confirmModal({ modalType: 'success_notice_complete', isConfirm: true, isOpen: false }));
        setToastMessage({
          duration: 2000,
          content: res?.data?.message,
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 게시글 공지로 내리기
  const handleClickUnRegisterNotice = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.putUnRegisterNotice(`/groups/${data?.group_id}/posts/${data?.id}/notice/remove`);
      if (res?.data?.message) {
        dispatch(ActionModal.confirmModal({ modalType: 'success_notice_un_complete', isConfirm: true, isOpen: false }));
        setToastMessage({
          duration: 2000,
          content: res?.data?.message,
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 중요 공지 내리기
  const handleClickUnImRegisterNotice = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.putUnRegisterNotice(`/groups/${data?.group_id}/posts/${data?.id}/important_notice/remove`);
      if (res?.data?.message) {
        dispatch(ActionModal.confirmModal({ modalType: 'success_notice_un_complete', isConfirm: true, isOpen: false }));
        setToastMessage({
          duration: 2000,
          content: res?.data?.message,
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 게시글 공지로 등록 확인 팝업
  const handleClickConfirmModal = () => {
    if (!data?.notice) return;
    dispatch(
      ActionModal.openModal({
        isOpen: true,
        modalType: 'confirm_board_change_type',
        data: {
          type: data?.notice === 2 ? 'fix_top' : 'normal',
        },
        action: data?.notice === 2 ? () => handleClickRegisterImNotice() : () => handleClickRegisterNotice(),
      }),
    );
  };

  // 유저 차단하기 confirm modal
  const handleClickBlockUser = () => {
    console.log('test===>');
    if (!data?.id) return;
    dispatch(
      ActionModal.openModal({
        isOpen: true,
        modalType: 'confirm_block_user',
        action: () => fetchBlockUser(),
      }),
    );
  };

  // 유저 차단하기
  const fetchBlockUser = async () => {
    try {
      const payload = {
        block_user_id: data?.user_id,
      };
      const res = await APISUSER.postBlockUser(`/my/block/user`, payload);
      if (res?.data?.message === 'OK') {
        dispatch(
          ActionModal.confirmModal({
            isOpen: false,
            modalType: 'success_block_user',
            isConfirm: true,
          }),
        );
        // postId에 해당하는 정보호출
        await dispatch(getBoardPostDetail(data?.id)); // 이 부분 다시 검토할 것
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  // 유저 차단하기 해제
  const fetchUnBlockUser = async () => {
    if (!data?.id) return;
    try {
      const res = await APISUSER.deleteBlockUser(`/my/block/user?block_user_id=${data?.user_id}`);
      if (res.data.message === 'OK') {
        dispatch(
          ActionModal.confirmModal({
            isOpen: false,
            modalType: 'success_unblock_user',
            isConfirm: true,
          }),
        );
        // postId에 해당하는 정보호출
        await dispatch(getBoardPostDetail(data?.id));
      }
      console.log(res, 'res');
    } catch (err) {
      console.log(err, 'err');
    }
  };

  console.log('data===>', data);
  console.log('open BottomSheet===>', open);
  console.log('content==>', content);

  // 카카오톡 공유하기
  const handleClickShared = (d: IMixedKeyValue) => {
    // console.log('d===>', d);
    if (!d?.id) return;
    const { name = '', photos = [], desc = '', id: groupId }: any = d.group;
    const kakaoBody = {
      title: `[${name}]`,
      description: `${d.content}`,
      imageUrl: photos?.length > 0 ? photos[0]?.url : `${location.origin}/images/text_share_image.png`,
      link: {
        mobileWebUrl: `${location.origin}/mygroup/group/${groupId}`,
        webUrl: `${location.origin}/mygroup/group?id=${groupId}`,
      },
    };

    console.log('kakaoBody', kakaoBody);
    if (window.webkit) {
      window.webkit.messageHandlers.shared.postMessage(kakaoBody);
    } else if (window.Android) {
      window.Android.shared(JSON.stringify(kakaoBody));
    }
  };

  /**
   * @description 첨부파일 바로 열기 (type: 0)
   * @returns {void}
   */
  const handleFileOpen = (): void => {
    const params = {
      url: data?.url,
      type: 0,
    };
    console.log('params0==>', params);
    if (window.Android) {
      window.Android.file(`${data?.url}`, 0);
    } else if (window.webkit) {
      window.webkit.messageHandlers.file.postMessage(JSON.stringify(params));
    }
  };

  /**
   * @description 첨부파일 앱에 저장 ( type: 1)
   * @returns {void}
   */
  const handleFileSave = (): void => {
    const params = {
      url: data?.url,
      type: 1,
    };
    console.log('params1==>', params);
    if (window.Android) {
      window.Android.file(`${data?.url}`, 1);
    } else if (window.webkit) {
      window.webkit.messageHandlers.file.postMessage(JSON.stringify(params));
    }
  };
  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div
          id="render-modal"
          className={cn(
            Styles['popup-container'],
            Styles['bottom-sheet-popup'],
            (type === 'bottomSheet_report' || modalType === 'bottomSheet_report') && Styles['report-pop'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
        >
          <div className={Styles['popup-header']}>
            {(modalType === 'bottomSheet_share' ||
              modalType === 'bottomSheet_report' ||
              type === 'bottomSheet_report') && <p className={Styles['pop-tit']}>{title}</p>}
            {modalType !== 'bottomSheet_addImage' && <button className={Styles.close} onClick={handleModalClose} />}
          </div>
          <div className={Styles['popup-content']}>
            <div className={cn(CommonStyles['button-list'], Styles['button-list'])}>
              {modalType === 'bottomSheet_menu' && (
                <>
                  <button type="button" onClick={handleShareAllPosts}>
                    공유하기
                  </button>
                  <button
                    type="button"
                    onClick={() =>
                      data?.post_bookmark?.length > 0
                        ? data?.group_id
                          ? handleGroupUnBookMarkPost(data?.post_bookmark[0]?.id)
                          : handleUnBookMarkAllPosts(data?.post_bookmark[0]?.id)
                        : data?.group_id
                        ? handleGroupBookMarkPost()
                        : handleBookMarkAllPosts()
                    }
                  >
                    {data?.post_bookmark?.length > 0 ? '북마크해제' : '북마크'}
                  </button>
                  {myInfo.value === 'OWNER' && data?.notice === 1 && data.group_id && (
                    <button onClick={() => handleClickConfirmModal()}>공지로 등록</button>
                  )}
                  {myInfo?.value === 'OWNER' && data?.notice === 2 && data.group_id && (
                    <button onClick={() => handleClickConfirmModal()}>중요 공지로 등록</button>
                  )}
                  {myInfo?.value === 'OWNER' && data?.notice === 2 && data.group_id && (
                    <button onClick={() => handleClickUnRegisterNotice()}>공지에서 내리기</button>
                  )}
                  {myInfo?.value === 'OWNER' && data?.notice === 3 && data.group_id && (
                    <button onClick={() => handleClickUnImRegisterNotice()}>중요 공지에서 내리기</button>
                  )}
                  {data &&
                    infoMe &&
                    (data?.post_report?.length === 0 ||
                      (data?.post_report?.[0] && data?.post_report[0]?.user_id === infoMe)) && (
                      <button
                        type="button"
                        onClick={() =>
                          data?.post_report?.length > 0
                            ? data?.group_id
                              ? handleClickGroupPostUnReport(data?.post_report[0]?.id)
                              : handleRemoveReport(data?.post_report[0]?.id)
                            : handleOnReport()
                        }
                      >
                        {data?.post_report?.length > 0 && data?.post_report[0]?.user_id === infoMe
                          ? '신고해제'
                          : '신고하기'}
                      </button>
                    )}
                  <button onClick={data?.user_block ? () => fetchUnBlockUser() : () => handleClickBlockUser()}>
                    {data?.user_block ? '이 회원의 글 차단 해제' : '이 회원의 게시글 차단하기'}
                  </button>
                </>
              )}
              {modalType === 'bottomSheet_save_file' && (
                <>
                  <button type="button" onClick={handleFileOpen}>
                    파일열기
                  </button>
                  <button type="button" onClick={handleFileSave}>
                    파일앱에 저장
                  </button>
                </>
              )}
              {modalType === 'bottomSheet_menuMyPost' && (
                <>
                  <button type="button" onClick={() => handleModifyAllPosts(data?.group_id)}>
                    수정하기
                  </button>
                  {myInfo?.value === 'OWNER' && data?.notice === 1 && data.group_id && (
                    <button onClick={() => handleClickConfirmModal()}>공지로 등록</button>
                  )}
                  {myInfo?.value === 'OWNER' && data?.notice === 2 && data.group_id && (
                    <button onClick={() => handleClickConfirmModal()}>중요 공지로 등록</button>
                  )}
                  {myInfo?.value === 'OWNER' && data?.notice === 2 && data.group_id && (
                    <button onClick={() => handleClickUnRegisterNotice()}>공지에서 내리기</button>
                  )}
                  {myInfo?.value === 'OWNER' && data?.notice === 3 && data.group_id && (
                    <button onClick={() => handleClickUnImRegisterNotice()}>중요 공지에서 내리기</button>
                  )}
                  {data?.notice !== 2 && data?.notice !== 3 && (
                    <button type="button" onClick={handleShareAllPosts}>
                      공유하기
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={() =>
                      data?.post_bookmark?.length > 0
                        ? data?.group_id
                          ? handleGroupUnBookMarkPost(data?.post_bookmark[0]?.id)
                          : handleUnBookMarkAllPosts(data?.post_bookmark[0]?.id)
                        : data?.group_id
                        ? handleGroupBookMarkPost()
                        : handleBookMarkAllPosts()
                    }
                  >
                    {data?.post_bookmark?.length > 0 ? '북마크해제' : '북마크'}
                  </button>
                  <button type="button" onClick={handleDeleteAllPosts}>
                    삭제하기
                  </button>
                </>
              )}
              {modalType === 'bottomSheet_share' && (
                <>
                  <button type="button" onClick={handleSelectGroup}>
                    공유할 모임 선택
                  </button>
                  <button onClick={() => (data?.group_id ? handleClickShared(data!) : handleSharePostKakao(data!))}>
                    카카오톡으로 공유하기
                  </button>
                </>
              )}
              {modalType === 'bottomSheet_addFile' && (
                <>
                  <button type="button" onClick={handleFileOpen}>
                    첨부 파일 열기
                  </button>
                  <button type="button" onClick={handleFileSave}>
                    파일 저장
                  </button>
                </>
              )}
              {modalType === 'bottomSheet_modify_report' && (
                <>
                  <button type="button" onClick={handleDeleteAllPosts}>
                    삭제하기
                  </button>
                  <button type="button" onClick={() => handleClickGroupPostUnReport(data?.post_report[0]?.id)}>
                    신고해제
                  </button>
                </>
              )}
            </div>
            {(modalType === 'bottomSheet_report' || type === 'bottomSheet_report') && (
              <>
                <div className={cn(CommonStyles['gray-box'], Styles['gray-box'])}>
                  <div className={cn(Styles.line, CommonStyles.line)}>
                    <div className={Styles.tit} style={{ textAlign: 'left' }}>
                      작성자
                    </div>
                    <div className={Styles.txt}>
                      {type === 'bottomSheet_report' ? targetData?.user?.nickname : data?.user?.nickname}
                    </div>
                  </div>
                  <div className={cn(Styles.line, CommonStyles.line)}>
                    <div className={Styles.tit} style={{ textAlign: 'left' }}>
                      신고내용
                    </div>
                    <div
                      className={cn(Styles.txt, CommonStyles['limit-line'], CommonStyles['limit-line6'])}
                      style={{ textAlign: 'left' }}
                    >
                      {type === 'bottomSheet_report' ? targetData?.content : data?.content}
                    </div>
                  </div>
                  <div className={cn(Styles.line, CommonStyles.line)}>
                    <div className={Styles.tit} style={{ textAlign: 'left' }}>
                      신고일자
                    </div>
                    <div className={Styles.txt}>{moment(new Date()).format('YYYY/MM/DD')}</div>
                  </div>
                </div>
                <div className={Styles['checkbox-div']}>{listData}</div>
              </>
            )}
            {modalType === 'bottomSheet_addImage' && (
              <button className={cn(Styles.txt, CommonStyles.w100p, ResetStyles['ta-l'])}>파일앱에서 첨부</button>
            )}
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            {modalType !== 'bottomSheet_addImage' &&
              modalType !== 'bottomSheet_report' &&
              type !== 'bottomSheet_report' && (
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
                  취소
                </button>
              )}
            {modalType === 'bottomSheet_addImage' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                확인
              </button>
            )}
          </div>
          {(modalType === 'bottomSheet_report' || type === 'bottomSheet_report') && (
            <div
              className={cn(
                Styles['popup-button-wrap'],
                CommonStyles['popup-button-wrap'],
                modalType !== 'bottomSheet_report' && CommonStyles['mt0'],
              )}
            >
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={
                  type === 'bottomSheet_report'
                    ? () => handleCommentReport(targetData!, targetDepth!)
                    : data?.group_id
                    ? () => handleClickGroupPostReport()
                    : () => handleReportApi()
                }
              >
                신고하기
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BottomSheet;
