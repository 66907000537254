import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as APIS from 'api/group';
import useToast from 'hook/useToast';
import moment from 'moment';
import cn from 'classnames';

import Modal from 'react-modal';
import CommonConfirm from 'components/common/modal/popup/CommonConfirm';
import CommonAlert from 'components/common/modal/popup/CommonAlert';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

// configs
import * as Config from 'configs/configs';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';
interface Props {
  type?: string;
  title?: string;
}

/**
 * @description comment tool bottom sheet
 * @Class
 * @category Components
 * @subcategory common sheet
 * @component
 * @returns {JSX.Element}
 */
const CommentToolBotSheet = (props: Props) => {
  const { title } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clickRef = useRef<boolean>(false);
  const { setToastMessage } = useToast();
  const { multiModalType, multiData, action, data, multiAction, isOpen } = useAppSelector(
    (state: RootState) => state.modal,
  );
  const possibleReport = useAppSelector((state: RootState) => state.user.is_possible_report);
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeMultiModal({ multiModalType: multiModalType, isOpen: false }));
    }, 200);
  };

  const handleConfirm = () => {
    handleModalClose();
  };

  const commentActionHandler = (type: string) => {
    if (type === 'edit' || type === 'report') {
      action!(multiData?.id);
      handleModalClose();
    }
    if (type === 'block') {
      multiAction!(multiData?.id);
      handleModalClose();
    }
    if (type === 'delete') {
      console.log('delete start==>');
      setIsConfirmOpen(true);
    }
  };

  /**
   * @description possible report수정
   * @returns {void}
   */
  const handlePossibleReport = () => {
    return setIsAlertOpen(true);
  };

  /**
   * @description 댓글 삭제 action function
   */
  const deleteComment = () => {
    multiAction!(multiData?.id);
    handleModalClose();
  };

  console.log('data in CommentToolBotSheet===>', data);
  console.log('multiData in CommentToolBotSheet===>', multiData);

  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div
          className={cn(
            Styles['popup-container'],
            Styles['bottom-sheet-popup'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
        >
          <div className={Styles['popup-header']}>
            <button className={Styles.close} onClick={handleModalClose} />
          </div>
          <div className={Styles['popup-content']}>
            <div className={cn(CommonStyles['button-list'], Styles['button-list'])}>
              {multiModalType === 'comment_tool_me' && (
                <>
                  <button type="button" onClick={() => commentActionHandler('edit')}>
                    수정하기
                  </button>
                  <button type="button" onClick={() => commentActionHandler('delete')}>
                    삭제하기
                  </button>
                </>
              )}
              {multiModalType === 'comment_tool_other' &&
                (multiData?.comment_report?.length > 0 ? (
                  <button type="button" onClick={() => commentActionHandler('report')}>
                    {'신고해제'}
                  </button>
                ) : multiData?.user_block?.block_user_id ? (
                  <button type="button" onClick={() => commentActionHandler('block')}>
                    {'차단해제'}
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() =>
                        multiData?.comment_report?.length === 0 && !possibleReport
                          ? handlePossibleReport()
                          : commentActionHandler('report')
                      }
                    >
                      {multiData?.comment_report?.length > 0 ? '신고해제' : '신고하기'}
                    </button>
                    <button type="button" onClick={() => commentActionHandler('block')}>
                      {multiData?.user_block?.block_user_id ? '차단해제' : '차단하기'}
                    </button>
                  </>
                ))}
            </div>
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
              취소
            </button>
          </div>
        </div>
        {(isConfirmOpen || isAlertOpen) && (
          <Modal
            isOpen={isConfirmOpen! || isAlertOpen!}
            onRequestClose={() => (isAlertOpen ? setIsAlertOpen(!open) : setIsConfirmOpen(!open))}
            style={{
              overlay: {
                position: 'fixed',
                zIndex: 999999999,
                inset: 0,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
            className={Styles.modal}
            ariaHideApp={false}
          >
            {isConfirmOpen && (
              <CommonConfirm
                type="confirm_delete_comment"
                title="댓글을 삭제하시겠습니까?"
                align="center"
                deleteComment={deleteComment}
                setIsConfirmOpen={setIsConfirmOpen}
              />
            )}
            {isAlertOpen && (
              <CommonAlert
                type="alert_possible_report"
                txt={`게시글을 10건 이상 신고하면\n이실장넷 운영정책에 따라\n 익일 자정까지 신고기능이 제한됩니다.`}
                setIsAlertOpen={setIsAlertOpen}
              />
            )}
          </Modal>
        )}
      </div>
    </>
  );
};

export default CommentToolBotSheet;
