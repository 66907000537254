import { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import useQueryState from 'hook/useQueryState';
import useIsMounted from 'hook/useIsMounted';
import ScrollLoader from 'components/common/Loader/ScrollLoader';
import useScrollToTop from 'hook/useScrollToTop';
import qs from 'qs';

// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionLoader from 'redux/slices/loading';
import * as ActionCoagency from 'redux/slices/coagency';
import { initAddr, initAddressList } from './initState';

// configs
import * as Config from 'configs/configs';
// styles
import CommonStyles from 'styles/common.module.css';
import { IMixedKeyValue } from '<modal>';
import { IAddressParams } from '<coagency>';
import network from 'utils/network';

interface Props {
  title?: string;
  stateAddr?: IMixedKeyValue;
  setStateAddr?: (stateAddr: IMixedKeyValue) => void;
}

/**
 * @description 공유매물등록 주소선택 component
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @param {object} props
 * @param {string} props.title
 * @returns {JSX.Element}
 */
const CoagencyRegAddress = (props: Props): JSX.Element => {
  useScrollToTop();
  const { title, stateAddr, setStateAddr } = props;
  const isMounted = useIsMounted();
  const dispatch = useAppDispatch();
  const { isCommentLoading } = useAppSelector((state: RootState) => state.loading);
  const currAddrStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.currAddrStep);
  const currStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.currStep);
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const dir = useAppSelector((state: RootState) => state.coagency.shareRegStep.dir);
  const mode = useAppSelector((state: RootState) => state.coagency.shareRegStep.mode);
  const REMainType = useAppSelector(
    (state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_main_gbn,
  );
  const RESubType = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_gbn);

  const queryParams = new URLSearchParams(location.search);
  const [addrStep, setAddrStep] = useQueryState<string | null>(queryParams.get('currAddrStep'), 'currAddrStep');
  const [editCompleteAddr, setEditCompleteAddr] = useState(false);
  const [isEndingComplex, setIsEndingComplex] = useState(false);

  const [addressList, setAddresList] = useState(initAddressList);
  const [complexList, setComplexList] = useState([]);
  const { sido, guGun, eupMyeonDong, li } = addressList;

  const [aptGroup, setAptGroup] = useState(false);

  useEffect(() => {
    if (REMainType) {
      const _aptgroup = REMainType === 'A' || REMainType === 'B';
      setAptGroup(_aptgroup);
    }
  }, [REMainType]);

  useEffect(() => {
    // backkey동작시는 기존 redux state를 동기화.
    if (shareRegStep.dir === 'left') {
      setStateAddr!(shareRegStep?.selectedAddr);
    }
    // 소재지 edit 모드일경우  state는 초기화시킴 (소재지 초기화)
    if (
      shareRegStep.editStep === 'location' &&
      shareRegStep.mode === 'edit' &&
      shareRegStep.currStep === 2 &&
      shareRegStep.currAddrStep === 1
    ) {
      setStateAddr!({
        sidoAddr: initAddr.sidoAddr, // 주소 초기화,
        guGunAddr: initAddr.guGunAddr, // 주소 초기화,
        eupMyeonDongAddr: initAddr.eupMyeonDongAddr, // 주소 초기화,
        liAddr: initAddr.liAddr, // 주소 초기화,
        complexAddr: shareRegStep?.selectedAddr.complexAddr,
        dongAddr: shareRegStep?.selectedAddr.dongAddr,
      });
    }
    // 단지 edit 모드일경우  redux state를 동기화.
    if (
      shareRegStep.editStep === 'complex' &&
      shareRegStep.mode === 'edit' &&
      shareRegStep.currStep === 3 &&
      shareRegStep.currAddrStep === 4
    ) {
      setStateAddr!(shareRegStep?.selectedAddr);
    }
  }, [shareRegStep]);

  /**
   * @description 최초 mount시 currAddStep, editCompleteAddr 초기화 side effect
   */
  useEffect(() => {
    if (isMounted()) {
      if (currStep === 3) return;
      setEditCompleteAddr(false);
      setIsEndingComplex(false);
    }
  }, [currStep]);

  /**
   * @description 현재 주소 step을 query string으로 변환 side effect
   */
  useEffect(() => {
    if (currAddrStep) setAddrStep(String(currAddrStep));
  }, [currAddrStep]);

  /**
   * @description 리(선택) 정보가 없을경우 다음 step으로 이동 및 area_cd정보 side effect
   */
  useEffect(() => {
    if (shareRegStep.dir === 'right' && currStep === 2 && editCompleteAddr && !li.length) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: REMainType === 'A' || REMainType === 'B' ? currStep! + 1 : currStep! + 3,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              area_cd: stateAddr?.liAddr.code
                ? Number(stateAddr?.liAddr.code)
                : stateAddr?.eupMyeonDongAddr?.code
                ? Number(stateAddr?.eupMyeonDongAddr?.code)
                : null,
            },
          },
        }),
      );
    }
  }, [editCompleteAddr, li]);

  /**
   * @description 주소 리스트 가져오기 side effect
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      dispatch(ActionLoader.onCommentLoad());
      if (currStep === 2 && currAddrStep === 1) {
        await getAddress('sido', { si_code: null });
      } else if (currStep === 2 && currAddrStep === 2 && stateAddr?.sidoAddr?.cd) {
        await getAddress('guGun', { si_code: stateAddr?.sidoAddr?.cd });
      } else if (currStep === 2 && currAddrStep === 3 && stateAddr?.sidoAddr?.cd && stateAddr?.guGunAddr?.cd) {
        await getAddress('eupMyeonDong', { si_code: stateAddr?.sidoAddr?.cd, gu_code: stateAddr?.guGunAddr?.cd });
      } else if (
        currAddrStep === 4 &&
        stateAddr?.sidoAddr?.cd &&
        stateAddr?.guGunAddr?.cd &&
        stateAddr?.eupMyeonDongAddr?.cd &&
        !stateAddr?.liAddr?.cd
      ) {
        if (!(REMainType === 'A' || REMainType === 'B')) {
          await getAddress('li', {
            si_code: stateAddr?.sidoAddr?.cd,
            gu_code: stateAddr?.guGunAddr?.cd,
            dong_code: stateAddr?.eupMyeonDongAddr?.cd,
          });
        }

        if (REMainType === 'A' || REMainType === 'B') {
          setIsEndingComplex(true);
          const complexParams = complexParamFunc(stateAddr?.eupMyeonDongAddr?.code);
          await getComplexList(complexParams);
        }
      } else if (
        currAddrStep === 4 &&
        stateAddr?.sidoAddr?.cd &&
        stateAddr?.guGunAddr?.cd &&
        stateAddr?.eupMyeonDongAddr?.cd &&
        stateAddr?.liAddr?.cd
      ) {
        if (REMainType === 'A' || REMainType === 'B') {
          setIsEndingComplex(true);
          const complexParams = complexParamFunc(stateAddr?.liAddr?.code);
          await getComplexList(complexParams);
        }
        // back key 동작시 비공동주택의 경우에는 li를 가져와서 선택된 상태로 표시
        if (!(REMainType === 'A' || REMainType === 'B') && dir === 'left') {
          await getAddress('li', {
            si_code: stateAddr?.sidoAddr?.cd,
            gu_code: stateAddr?.guGunAddr?.cd,
            dong_code: stateAddr?.eupMyeonDongAddr?.cd,
          });
        }
      }
    };
    getData();
    return () => abortController.abort();
  }, [currStep, currAddrStep, stateAddr, REMainType]);

  /**
   * @description 주소 api 호출
   * @param {string} type  호출 type sido, guGun, eupMyeonDong, li
   * @param {object} params {si_code: string, gu_code: string, dong_code: string }
   * @returns {Promise<void>}
   */
  const getAddress = async (type: string, params: IAddressParams): Promise<void> => {
    try {
      const resAddr = await network().coagency().postAddress(Config.API_URL.POST_ADDR_LIST, JSON.stringify(params));
      const { data, status } = resAddr;
      if (status === 200) {
        // backey 동작 및 공동주택일 경우에 sido, guGun을/ 비공동주택일경우 sido, guGun, li 초기화시킴
        if (dir === 'left' && currStep === 2 && currAddrStep === 3) {
          setAddresList({
            ...addressList,
            sido: [],
            guGun: [],
            eupMyeonDong: data,
            li: [],
          });
        } else if (dir === 'left' && currStep === 2 && currAddrStep === 2) {
          setAddresList({
            ...addressList,
            sido: [],
            guGun: data,
            eupMyeonDong: [],
            li: [],
          });
        } else {
          setAddresList({
            ...addressList,
            [type]: data,
          });
        }
        if (type === 'li') setEditCompleteAddr(true);
        dispatch(ActionLoader.offCommentLoad());
      }
    } catch (err) {
      console.error('err', err);
      if (err) dispatch(ActionLoader.offCommentLoad());
    }
  };

  /**
   * @description 단지정보 호출시 params
   * @param {any} p
   * @returns {object} params
   */
  const complexParamFunc = useCallback(
    (p: string): object => {
      const params = {
        area_cd: p,
        complex_gbn: RESubType === 'AB' ? 'AP' : RESubType === 'JB' ? 'JS' : RESubType === 'OB' ? 'OP' : RESubType,
        is_rebuild: RESubType === 'RC' ? 'Y' : '',
        is_parcel: REMainType === 'B' ? 'Y' : '',
      };
      return params;
    },
    [RESubType, REMainType],
  );

  /**
   * @description 공동주택 단지정보 api call
   * @returns {Promise<void>}
   */
  const getComplexList = async (params: IMixedKeyValue): Promise<void> => {
    const joinPath = `${Config.API_URL.GET_OFFERS_COMPLEXES}?${qs.stringify(params)}`;
    try {
      const resComplex = await network().coagency().getCommonList(joinPath);
      const { data, status } = resComplex;
      if (status === 200) {
        setComplexList(data.data);
        setIsEndingComplex(false);
        dispatch(ActionLoader.offCommentLoad());
      }
      console.log('resComplex==>', resComplex);
    } catch (err) {
      console.error('err', err);
      if (err) {
        setIsEndingComplex(false);
        dispatch(ActionLoader.offCommentLoad());
      }
    }
  };

  /**
   * @description 주소 및 단지 선택 navigation
   * @returns {JSX.Element}
   */
  const AddrTopNav = (): JSX.Element => {
    return (
      <div className={CommonStyles.division}>
        <div className={CommonStyles['select-step1']}>
          <button
            type="button"
            className={stateAddr?.sidoAddr?.cd ? CommonStyles.selected : ''}
            onClick={() => {
              if (shareRegStep.editStep === 'complex') return;
              setComplexList([]);
              setStateAddr!(initAddr);
              dispatch(
                ActionCoagency.setStep({
                  shareRegStep: {
                    ...shareRegStep,
                    currStep: 2,
                    currAddrStep: 1,
                    dir: 'right',
                    selectedAddr: initAddr,
                  },
                }),
              );
            }}
          >
            {stateAddr?.sidoAddr?.cd ? stateAddr?.sidoAddr?.name : '시/도'}
          </button>
          <i className={CommonStyles['icon-arrow-down-gr']}></i>
          <button
            type="button"
            className={stateAddr?.guGunAddr?.cd ? CommonStyles.selected : ''}
            onClick={() => {
              if (shareRegStep.editStep === 'complex') return;
              if (!stateAddr?.sidoAddr?.cd) return;
              setComplexList([]);
              setStateAddr!({
                ...stateAddr,
                guGunAddr: {},
                eupMyeonDongAddr: {},
                liAddr: {},
                complexAddr: {},
                dongAddr: {},
              });
              dispatch(
                ActionCoagency.setStep({
                  shareRegStep: {
                    ...shareRegStep,
                    currStep: 2,
                    currAddrStep: 2,
                    dir: 'right',
                    selectedAddr: {
                      ...shareRegStep.selectedAddr,
                      guGunAddr: {},
                      eupMyeonDongAddr: {},
                      liAddr: {},
                      complexAddr: {},
                      dongAddr: {},
                    },
                  },
                }),
              );
            }}
          >
            {stateAddr?.guGunAddr?.cd ? stateAddr?.guGunAddr?.name : '시/군/구'}
          </button>
          <i className={CommonStyles['icon-arrow-down-gr']}></i>
          <button
            type="button"
            className={stateAddr?.eupMyeonDongAddr?.cd ? CommonStyles.selected : ''}
            onClick={() => {
              if (shareRegStep.editStep === 'complex') return;
              if (!stateAddr?.guGunAddr?.cd) return;
              setComplexList([]);
              setStateAddr!({
                ...stateAddr,
                eupMyeonDongAddr: {},
                liAddr: {},
                complexAddr: {},
                dongAddr: {},
              });
              dispatch(
                ActionCoagency.setStep({
                  shareRegStep: {
                    ...shareRegStep,
                    currStep: 2,
                    currAddrStep: 3,
                    dir: 'right',
                    selectedAddr: {
                      ...shareRegStep.selectedAddr,
                      eupMyeonDongAddr: {},
                      liAddr: {},
                      complexAddr: {},
                      dongAddr: {},
                    },
                  },
                }),
              );
            }}
          >
            {stateAddr?.eupMyeonDongAddr?.cd ? stateAddr?.eupMyeonDongAddr?.name : '읍/면/동'}
          </button>
          {/** 공동주택의 경우에는 리(선택) disable*/}
          {!(REMainType === 'A' || REMainType === 'B') && !(stateAddr?.eupMyeonDongAddr?.cd && !li.length) && (
            <>
              <i className={CommonStyles['icon-arrow-down-gr']}></i>
              <button
                type="button"
                className={stateAddr?.liAddr?.cd ? CommonStyles.selected : ''}
                onClick={() => {
                  if (shareRegStep.editStep === 'complex') return;
                  if (!stateAddr?.eupMyeonDongAddr?.cd) return;
                  setComplexList([]);
                  setStateAddr!({
                    ...stateAddr,
                    liAddr: {},
                    complexAddr: {},
                    dongAddr: {},
                  });
                  dispatch(
                    ActionCoagency.setStep({
                      shareRegStep: {
                        ...shareRegStep,
                        currStep: 2,
                        currAddrStep: 4,
                        dir: 'right',
                        selectedAddr: {
                          ...shareRegStep.selectedAddr,
                          liAddr: {},
                          complexAddr: {},
                          dongAddr: {},
                        },
                      },
                    }),
                  );
                }}
              >
                {stateAddr?.liAddr?.cd
                  ? stateAddr?.liAddr?.name
                  : stateAddr?.eupMyeonDongAddr?.cd && li?.length === 0
                  ? '-'
                  : '선택(리)'}
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  /**
   * @description 주소 선택
   * @returns {JSX.Element}
   */
  const renderAddress = (
    <div className={cn(CommonStyles.division, CommonStyles['scroll-area'])}>
      <div className={CommonStyles['select-step2']}>
        {/** 시도선택 */}
        {sido?.length > 0 &&
          (dir === 'right' || mode === 'edit' ? !stateAddr?.sidoAddr?.cd : stateAddr?.sidoAddr?.cd) && (
            <>
              {sido?.map((item: IMixedKeyValue) => (
                <button
                  key={item.cd}
                  type="button"
                  className={cn(CommonStyles.btn, item.cd !== stateAddr?.sidoAddr?.cd && CommonStyles.line)}
                  onClick={() => {
                    setStateAddr!({
                      ...stateAddr,
                      sidoAddr: item,
                      guGunAddr: {},
                      eupMyeonDongAddr: {},
                      liAddr: {},
                    });
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          currAddrStep: currAddrStep! + 1,
                          dir: 'right',
                          selectedAddr: {
                            ...shareRegStep.selectedAddr,
                            sidoAddr: mode === 'write' ? item : shareRegStep.selectedAddr.sidoAddr,
                          },
                        },
                      }),
                    );
                  }}
                >
                  {item.name}
                </button>
              ))}
            </>
          )}
        {/** 구군선택 */}
        {guGun?.length > 0 &&
          stateAddr?.sidoAddr?.cd &&
          (dir === 'right' ? !stateAddr?.guGunAddr?.cd : stateAddr?.guGunAddr?.cd) && (
            <>
              {guGun?.map((item: IMixedKeyValue) => (
                <button
                  key={item.cd}
                  type="button"
                  className={cn(CommonStyles.btn, item.cd !== stateAddr?.guGunAddr?.cd && CommonStyles.line)}
                  onClick={() => {
                    setStateAddr!({
                      ...stateAddr,
                      guGunAddr: item,
                      eupMyeonDongAddr: {},
                      liAddr: {},
                    });
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          currAddrStep: currAddrStep! + 1,
                          dir: 'right',
                          selectedAddr: {
                            ...shareRegStep.selectedAddr,
                            guGunAddr: mode === 'write' ? item : shareRegStep.selectedAddr.guGunAddr,
                          },
                        },
                      }),
                    );
                  }}
                >
                  {item.name}
                </button>
              ))}
            </>
          )}
        {/** 읍/면/동 선택 */}
        {eupMyeonDong?.length > 0 &&
          stateAddr?.sidoAddr?.cd &&
          stateAddr?.guGunAddr?.cd &&
          (dir === 'right' ? !stateAddr?.eupMyeonDongAddr?.cd : stateAddr?.eupMyeonDongAddr?.cd) && (
            <>
              {eupMyeonDong?.map((item: IMixedKeyValue) => (
                <button
                  key={item.cd}
                  type="button"
                  className={cn(CommonStyles.btn, item.cd !== stateAddr?.eupMyeonDongAddr?.cd && CommonStyles.line)}
                  onClick={() => {
                    setStateAddr!({
                      ...stateAddr,
                      eupMyeonDongAddr: item,
                      liAddr: {},
                    });
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          currAddrStep: currAddrStep! + 1,
                          currStep: REMainType === 'A' || REMainType === 'B' ? currStep! + 1 : currStep!, // 공동주택의 경우 단지정보를 보여주고, 비공동주택의 경우에는 리선택가능
                          dir: 'right',
                          selectedAddr: {
                            ...shareRegStep?.selectedAddr,
                            eupMyeonDongAddr: mode === 'write' ? item : shareRegStep?.selectedAddr?.eupMyeonDongAddr,
                            liAddr: {},
                          },
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            area_cd:
                              mode === 'write' ? Number(item.code) || null : shareRegStep?.coagencyRegData?.area_cd,
                          },
                        },
                      }),
                    );
                  }}
                >
                  {item.name}
                </button>
              ))}
            </>
          )}
        {/** 선택(리) */}
        {!(REMainType === 'A' || REMainType === 'B') &&
          li?.length > 0 &&
          stateAddr?.sidoAddr?.cd &&
          stateAddr?.guGunAddr?.cd &&
          stateAddr?.eupMyeonDongAddr?.cd && (
            <>
              {li?.map((item: IMixedKeyValue) => (
                <button
                  key={item.cd}
                  type="button"
                  className={cn(CommonStyles.btn, item.cd !== stateAddr?.liAddr?.cd && CommonStyles.line)}
                  onClick={() => {
                    setStateAddr!({
                      ...stateAddr,
                      liAddr: item,
                    });
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          currStep: currStep! + 3, // 비공동주택의 경우 상세주소 입력단계로 바로 이동함.
                          currAddrStep: currAddrStep! + 1,
                          dir: 'right',
                          selectedAddr: {
                            ...shareRegStep?.selectedAddr,
                            liAddr: mode === 'write' ? item : shareRegStep?.selectedAddr?.liAddr,
                          },
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            area_cd:
                              mode === 'write' ? Number(item.code) || null : shareRegStep?.coagencyRegData?.area_cd,
                          },
                        },
                      }),
                    );
                  }}
                >
                  {item.name}
                </button>
              ))}
            </>
          )}
      </div>
    </div>
  );

  /**
   * @description 단지 선택
   * @returns {JSX.Element}
   */
  const renderComplex = (
    <>
      {/** 공동주택 단지정보 */}
      <div className={cn(CommonStyles.division, CommonStyles['scroll-area'])}>
        {complexList?.length > 0 ? (
          <div className={cn(CommonStyles['select-step2'], CommonStyles.full)}>
            {complexList?.map((item: IMixedKeyValue) => {
              return (
                <button
                  key={item.complex_cd}
                  type="button"
                  className={cn(
                    CommonStyles.btn,
                    item.complex_cd !== stateAddr?.complexAddr?.complex_cd && CommonStyles.line,
                  )}
                  onClick={() => {
                    setStateAddr!({
                      ...stateAddr,
                      complexAddr: item,
                    });
                    if (shareRegStep.mode !== 'edit') {
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            currStep: RESubType === 'RB' ? currStep! + 2 : currStep! + 1,
                            dir: 'right',
                            selectedAddr: {
                              ...shareRegStep.selectedAddr,
                              complexAddr: item,
                              dongAddr: {}, // dongAddr은 초기화시킴
                            },
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              complex_cd: item.complex_cd, // 단지 complex_cd
                              dong_cd: null, //  write mode 에서 단지 선택시 dong_cd는 초기화
                              dong_hide: 'N',
                              ho_nm: '',
                            },
                          },
                        }),
                      );
                    } else {
                      // edit mode일 경우
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            currStep: RESubType === 'RB' ? currStep! + 2 : currStep! + 1,
                            dir: 'right',
                            selectedAddr: {
                              ...shareRegStep.selectedAddr,
                              complexAddr: item,
                              dongAddr: {}, // dongAddr은 초기화시킴
                            },
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              complex_cd: item.complex_cd, // 단지 complex_cd
                              // dong_cd: stateAddr?.dongAddr.dong_cd, // 단지 선택시 edit mode에서는 dong_cd는 그대로 유지
                              dong_cd: null,
                              dong_hide: 'N',
                              ho_nm: '',
                            },
                          },
                        }),
                      );
                    }
                  }}
                >
                  {item.complex_nm}
                </button>
              );
            })}
          </div>
        ) : (
          !isCommentLoading &&
          !isEndingComplex && (
            <div style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>
              {`선택하신 지역에 ${
                RESubType === 'RB' ? '재개발 단지' : RESubType === 'RC' ? '재건축 단지' : '단지'
              }가 존재하지 않습니다.\n매물의 소재지를 다시 설정해주세요.`}
            </div>
          )
        )}
      </div>
    </>
  );

  /**
   * @description edit mode에서 aptgroup 단지선택 이후 next step시 데이터 저장
   * @returns {void}
   */
  const handleComplexNextStep = (): void => {
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: currStep! + 1,
          dir: 'right',
          selectedAddr: {
            ...shareRegStep.selectedAddr,
            complexAddr: stateAddr?.complexAddr,
          },
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            complex_cd: stateAddr?.complexAddr.complex_cd, // 단지 complex_cd
            dong_cd: stateAddr?.dongAddr.dong_cd, // 단지 선택시 edit mode에서는 dong_cd는 그대로 유지
          },
        },
      }),
    );
  };

  /**
   * @description 주소수정 취소버튼 클릭
   * @returns {void}
   */
  const handleAddrEditCancel = (): void => {
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          currAddrStep: 4,
          editStep: '',
          mode: 'write',
          selectedAddr: {
            ...shareRegStep.selectedAddr,
          },
        },
      }),
    );
  };

  /**
   * @description 주소 수정완료 // 공동주택은 단지선택 이후 step up and 동/호선택으로 이동,
   * @returns {void}
   */
  const handleAddrEditComplete = (): void => {
    if (aptGroup) {
      handleComplexNextStep();
    } else {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: 9,
            editStep: '',
            mode: 'write',
          },
        }),
      );
    }
  };

  console.group();
  console.log('addressList===>', addressList);
  console.log('sido===>', sido);
  console.log('guGun===>', guGun);
  console.log('eupMyeonDong===>', eupMyeonDong);
  console.log('li===>', li);
  console.log('complexList', complexList);
  console.log('editCompleteAddr==>', editCompleteAddr);
  console.log('stateAddr===>', stateAddr);
  console.groupEnd();

  return (
    <>
      <p className={CommonStyles['main-title']}>{title}</p>
      <div className={CommonStyles['area-select-form']}>
        <AddrTopNav />
        {isCommentLoading ? (
          <div
            style={{
              display: 'flex',
              height: 'calc(100vh - 15.5rem - 49px)',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ScrollLoader />
          </div>
        ) : (
          <>
            {currStep === 2 && renderAddress}
            {currStep === 3 && renderComplex}
          </>
        )}
      </div>
      {/** 등록시 소재지 초기화 수정 */}
      {shareRegStep.mode === 'edit' && shareRegStep.editStep === 'location' && (
        <div
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          style={
            import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
          }
        >
          <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleAddrEditCancel}>
            수정 취소
          </button>
          {/**단지정보와 연결됨 **/}
        </div>
      )}
      {/** 공동주택 등록시 단지 수정 */}
      {shareRegStep.mode === 'edit' && shareRegStep.editStep === 'complex' && (
        <div
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          style={
            import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
          }
        >
          <button
            className={cn(
              CommonStyles.btn,
              CommonStyles.lg,
              CommonStyles.gry,
              shareRegStep.editStep === 'complex' &&
                !shareRegStep.selectedAddr.complexAddr.complex_cd &&
                CommonStyles.disabled,
            )}
            disabled={shareRegStep.editStep === 'complex' && !shareRegStep.selectedAddr.complexAddr.complex_cd}
            onClick={handleAddrEditCancel}
          >
            수정 취소
          </button>
          <button
            className={cn(
              CommonStyles.btn,
              CommonStyles.lg,
              (aptGroup && !stateAddr?.complexAddr?.complex_cd) ||
                (!aptGroup &&
                  ((!li.length && !stateAddr?.eupMyeonDongAddr?.cd) || (li.length > 0 && !stateAddr?.liAddr?.cd)))
                ? CommonStyles.disabled
                : '',
            )}
            onClick={handleAddrEditComplete}
            disabled={
              (aptGroup && !stateAddr?.complexAddr?.complex_cd) ||
              (!aptGroup &&
                ((!li.length && !stateAddr?.eupMyeonDongAddr?.cd) || (li.length > 0 && !stateAddr?.liAddr?.cd)))
            }
          >
            수정 완료
          </button>
        </div>
      )}
    </>
  );
};

export default CoagencyRegAddress;
