import { useState, useEffect, useCallback } from 'react';
import * as APIS from 'api/group';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import ToggleListButton from 'components/common/Button/ToggleListButton';
import Button from 'components/common/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modal';
import { RootState, useAppSelector } from 'redux/store';
import { offLoad, onLoad } from 'redux/slices/loading';

/**
 * @description 모임 승인
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupApprovalManagement = () => {
  const params = useParams();
  const { id = '' } = params;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const groupData = useAppSelector((state: RootState) => state.group);
  const { group } = groupData;
  const { join_apply_use, open_info, join_question_use, join_question } = group || {};
  const { value = '' } = open_info || {};
  //const { id = ''}= params;
  // 가입신청 받기 상태
  const [requestChecked, setRequestChecked] = useState<boolean>(false);
  // 가입 질문하기 상태
  const [quesChecked, setQuesChecked] = useState<boolean>(false);
  // 임시 가입신청 받기 상태값
  const [tempRequestChecked, setTempRequestChecked] = useState<boolean>(false);
  // 임시 가입 질문하기 상태값
  const [tempQuesChecked, setTempQuesChecked] = useState<boolean>(false);
  // 질문 내용
  const [text, setText] = useState<string>('');

  // 기존에 저장된 모임 설정 관련 state 셋팅
  useEffect(() => {
    if (join_apply_use !== undefined || join_apply_use !== null) {
      setRequestChecked(join_apply_use);
      setTempRequestChecked(join_apply_use);
    }

    if (join_question_use !== undefined || join_apply_use !== null) {
      setQuesChecked(join_question_use);
      setTempQuesChecked(join_question_use);
    }

    if (join_question) {
      setText(join_question);
    }
  }, [group]);

  /**
   * @description 모임 가입조건 변경
   * @param {any} e changeEvent
   * @param {string} type 모임 가입 조건 type
   */
  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>, type: string = '') => {
    const checked = e.target.checked;
    if (type === 'requestChecked') {
      setRequestChecked(checked);
    }

    if (type === 'quesChecked') {
      setQuesChecked(checked);
    }
  };

  /**
   * @description 질문 사용 여부
   */
  const handleClickModifyJoinQuestion = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'confirm_group_join_question',
        data: {
          join_apply_use: requestChecked, //신청 사용 여부
          join_question_use: quesChecked, //질문 사용여부
        },
      }),
    );
  };

  /**
   * @description 뒤로가기
   */
  const handleClickBack = useCallback(() => {
    navigate(-1);
  }, []);

  /**
   * @description 모임 가입 조건 변경 api
   */
  const modifyGroupApproval = async () => {
    if (id === '') return;
    try {
      dispatch(onLoad());
      const payload = {
        join_apply_use: requestChecked, //신청 사용 여부
        join_question_use: quesChecked, //질문 사용여부
        join_question: text, //질문 내용
      };
      await APIS.puthModifyApprovalManagement(`/groups/${id}/apply`, payload);
    } catch (err) {
      console.log(err, 'err');
    } finally {
      handleClickBack();
      dispatch(offLoad());
    }
  };

  /**
   * @description 모임 가입조건 변경 버튼 status enable disable
   */
  const isVaildation = () => {
    let isBtn = false;

    if (tempQuesChecked === quesChecked && tempRequestChecked === requestChecked) {
      isBtn = true;
    }

    if (tempRequestChecked === requestChecked) {
      isBtn = true;
    }

    if (tempQuesChecked !== quesChecked) {
      isBtn = false;
    }

    if (quesChecked && text.length === 0) {
      isBtn = true;
    }
    return isBtn;
  };

  const isBtn = isVaildation();

  return (
    <div className={cn(CommonStyles.hasBottom)}>
      <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
        <ToggleListButton
          id="group_approval_management_request"
          checked={requestChecked}
          title="가입 신청 받기"
          text={
            requestChecked
              ? '회원 가입 시, 모임장의 승인이 필요합니다.'
              : '회원가입시 모임장이 승인하지 않습니다. 초대받은 회원이 초대장 링크로 바로 가입할 수 있습니다.'
          }
          disabled={value === 'NAMEONLY' ? false : true}
          onChange={(e) => onChangeStatus(e, 'requestChecked')}
        />
        <ToggleListButton
          id="group_approval_management_ques"
          checked={quesChecked}
          title="가입 질문 하기"
          disabled={requestChecked === false ? true : false}
          onChange={(e) => onChangeStatus(e, 'quesChecked')}
        />

        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <Button size="large" type="gry" onClick={handleClickBack}>
            취소
          </Button>
          <Button size="large" disabled={isBtn} onClick={modifyGroupApproval}>
            확인
          </Button>
        </div>
        <div className={cn(CommonStyles['group-setting'])}>
          <Button size="large" type="line" onClick={handleClickModifyJoinQuestion} disabled={!quesChecked}>
            가입질문 수정하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GroupApprovalManagement;
