import * as ActionsComment from 'redux/slices/comment';
import * as ActionsBoard from 'redux/slices/board';
import { Dispatch, AnyAction } from 'redux';
import * as APISUSER from 'api/users';
// utils
import network from 'utils/network';
import { IMixedKeyValue } from '<modal>';

/**
 * @description 전체게시판 / 모임게시판 해당게시글 댓글 목록 가져오기
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} kind {kind }
 * @param {number} postId {post id}
 * @param {number} groupId {group id} optional
 * @param {string} isType 구합니다 / 공동 매물 게시글 타입
 */
export const fetchPostCommentsData = (
  kind: number | null,
  postId: number | null,
  groupId?: number | null,
  isType?: string | null,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const fetchData = async () => {
      let path = groupId ? `/groups/${groupId}/posts/${postId}/comments/list` : `/posts/${postId}/comments/list`;
      // 구합니다 댓글
      if (isType === 'looking-for') {
        path = `/seeks/${postId}/comments/list`;
      }

      // 공동 매물 댓글
      if (isType === 'coagency') {
        path = `/offers/${postId}/comments/list`;
      }
      const res = await network().post().getPostCommentsList(path);
      if (!res) {
        throw new Error('Error occured');
      }

      // console.log('getData3==>', res);
      let data: IMixedKeyValue = {};
      if (groupId) {
        data = await res;
        data = data.data;
      } else {
        data = await res;
      }
      if (!data?.list?.length && !data.top?.length && data.message) {
        data = [];
      } else if (data.top?.length) {
        data = [...data.top, ...data.list];
      } else {
        data = data?.list;
      }

      if (isType === 'looking-for') {
        data = res?.data?.map((item: any) => {
          return { ...item, user: { ...item.users } };
        });
      }

      if (isType === 'coagency') {
        data = res?.list?.map((item: any) => {
          return {
            ...item,
            user: { ...item.users },
            children:
              item?.children?.map((el: any) => {
                return { ...el, user: { ...el.users } };
              }) || [],
          };
        });
      }

      // console.log('getData4==>', data);
      return data;
    };
    try {
      const comments = await fetchData();
      if (comments) {
        dispatch(
          ActionsComment.loadComments({
            comments,
            boardKind: kind,
            postId: postId,
            groupId: groupId,
            parentId: null,
            isFetch: true,
            isAddComment: false,
            isAddChildren: false,
            isType: isType || '',
          }),
        );
      } else {
        dispatch(
          ActionsComment.loadComments({
            comments: [],
            boardKind: kind,
            postId: postId,
            groupId: groupId,
            parentId: null,
            isFetch: true,
            isAddComment: false,
            isAddChildren: false,
            isType: isType || '',
          }),
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판 / 모임게시판 해당댓글 업데이트
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} commentId {post id}
 * @param {object} params {IComment}
 * @param {string} depth depth
 * @param {number} postId {post id}
 * @param {number} groupId {group id}
 * @returns {Promise<void>}
 */
export const updatePostComment = (
  commentId: number,
  params: IMixedKeyValue,
  depth?: string,
  postId?: number,
  groupId?: number,
  isType?: string,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const updateData = async () => {
      let path = groupId ? `/groups/${groupId}/posts/${postId}/comments/${commentId}` : `/posts/comments/${commentId}`;

      if (isType === 'looking-for') {
        path = `/seeks/${postId}/comments/${commentId}`;
      }

      if (isType === 'coagency') {
        path = `/offers/${postId}/comments/${commentId}`;
      }
      const res = await network().post().putBoardPostComment(path, JSON.stringify(params));
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      console.log('data==>', data);
      return data.data;
    };
    try {
      const comments = await updateData();
      console.log('comments in updateData', comments);
      if (depth === '1depth') {
        dispatch(ActionsComment.updateComment({ comments, updatedId: commentId }));
      } else {
        dispatch(ActionsComment.updateChildren({ comments, parentId: comments.parent_id, updatedId: commentId }));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판 / 모임게시판 해당댓글 삭제하기
 * @Class
 * @category Api
 * @subcategory comment
 * @param {string} depth {depth}
 * @param {number} commentId {comment id}
 * @param {number} pid {parent id}
 * @param {number} groupId {groupId}
 * @param {number} groupPostId {groupPostId}
 * @returns {Promise<void>}
 */
export const deletePostComment = (
  depth: string,
  commentId: number,
  pid?: number | null,
  groupId?: number | null,
  groupPostId?: number | null,
  isType?: string | null,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const deleteData = async () => {
      let path = groupId
        ? `/groups/${groupId}/posts/${groupPostId}/comments/${commentId}`
        : `/posts/comments/${commentId}`;

      if (isType === 'looking-for') {
        path = `/seeks/${groupPostId}/comments/${commentId}`;
      }

      if (isType === 'coagency') {
        path = `/offers/${groupPostId}/comments/${commentId}`;
      }
      const res = await network().post().deleteBoardPostComment(path);
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res.data;
      console.log('data==>', data);
      return data;
    };
    try {
      const comments = await deleteData();
      console.log('comments==>', comments);
      if (depth === '1depth') {
        dispatch(ActionsComment.deleteComment({ comments, deletedId: commentId, isRemoveComment: true }));
      } else if (depth === '2depth') {
        dispatch(
          ActionsComment.deleteChildren({
            comments,
            parentId: pid,
            deletedChildId: commentId,
            isRemoveComment: true,
          }),
        );
      }
      dispatch(ActionsBoard.getCommentCount({ comment_count: comments.comment_count }));
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판 / 해당댓글 댓글 달기
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} postId / commentId {postId or comment id}
 * @param {object} params {IComment}
 * @returns {Promise<void>}
 */
export const sendPostComment = (postId: number, params: IMixedKeyValue, groupId?: number, isType?: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const sendData = async () => {
      let path = groupId ? `/groups/${groupId}/posts/${postId}/comments` : `/posts/${postId}/comments`;
      // 구합니다.
      if (isType === 'looking-for') {
        path = `/seeks/${postId}/comments`;
      }
      // 공동 매물
      if (isType === 'coagency') {
        path = `/offers/${postId}/comments`;
      }
      const res = await network().post().postBoardPostComment(path, JSON.stringify(params));
      if (!res) {
        throw new Error('Error occured');
      }

      const data = await res;
      let _data;
      if (data?.data?.post_id === postId) {
        _data = data.data;
      }

      if (groupId && data?.data?.group_post_id === postId) {
        _data = data.data;
      }

      if (isType === 'looking-for') {
        _data = {
          ...data.data,
          comment_report: data.data?.comment_report || [],
          like_count: data.data?.like_count || 0,
          dislike_count: data.data?.dislike_count || 0,
        };
      }

      if (isType === 'coagency') {
        _data = {
          ...data.data,
          comment_report: data.data?.comment_report || [],
          like_count: data.data?.like_count || 0,
          dislike_count: data.data?.dislike_count || 0,
        };
      }

      return _data;
    };
    try {
      const comments = await sendData();
      console.log('add comments', comments);
      if (comments?.parent_id) {
        dispatch(ActionsComment.addChildren({ comments, parentId: comments.parent_id, isAddChildren: true }));
      } else {
        dispatch(ActionsComment.addComment({ comments, isAddComment: true }));
      }
      if (!groupId) {
        dispatch(ActionsBoard.getCommentCount({ comment_count: comments.comment_count }));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판/ 모임게시판 해당댓글 좋아요 싫어요
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} commentId {comment id}
 * @param {object} params {IComment}
 * @returns {Promise<void>}
 */
export const setPostCommentLike = (
  commentId: number,
  params: IMixedKeyValue,
  postId?: number,
  groupId?: number,
  isType?: string,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const setLikeData = async () => {
      let path =
        postId && groupId
          ? `/groups/${groupId}/posts/${postId}/comments/${commentId}/action`
          : `/posts/comments/${commentId}/action`;

      if (isType === 'looking-for') {
        path = `/seeks/${postId}/comments/${commentId}/action`;
      }

      if (isType === 'coagency') {
        path = `/offers/${postId}/comments/${commentId}/action`;
      }
      const res = await network().post().postBoardPostCommentLike(path, JSON.stringify(params));
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      console.log('data==>', data);
      let _data;
      if (data?.data) {
        _data = data.data;
      }
      return _data;
    };
    try {
      const commentsLike = await setLikeData();
      dispatch(ActionsComment.setCommentLike({ commentsLike, isLikeTarget: commentId, isCommentLike: true }));
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판 / 모임게시판 해당댓글 신고하기
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} commentId {post id}
 * @param {object} params {IComment}
 * @param {string} depth depth
 * @param {number} postId {post id}
 * @param {number} groupId {group id}
 * @returns {Promise<void>}
 */
export const reportPostComment = (
  parentId: number,
  commentId: number,
  params: IMixedKeyValue,
  depth?: string,
  postId?: number,
  groupId?: number,
  isType?: string,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const reportedData = async () => {
      console.log(isType, 'isType');
      let path = groupId
        ? `/groups/${groupId}/posts/${postId}/comments/${commentId}/report`
        : `/posts/comments/${commentId}/report`;
      if (isType === 'looking-for') {
        path = `/seeks/${postId}/comments/${commentId}/report`;
      }

      if (isType === 'coagency') {
        path = `/offers/${postId}/comments/${commentId}/report`;
      }
      const res = await network().post().postBoardPostCommentReport(path, JSON.stringify(params));
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      console.log('data==>', data);
      return data;
    };
    try {
      const comments = await reportedData();
      console.log('comments in reportedData', comments, depth);
      if (depth === '1depth') {
        dispatch(ActionsComment.updateComment({ comments, updatedId: commentId }));
      } else {
        dispatch(ActionsComment.updateChildren({ comments, parentId: parentId!, updatedId: commentId! }));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description 전체게시판 / 모임게시판 해당댓글 신고해제하기
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} commentId {commentId}
 * @param {number} reportId {reportId} // reportId or groupPostReportId
 * @param {string} depth depth
 * @param {number} postId {post id}
 * @param {number} groupId {group id}
 * @returns {Promise<void>}
 */
export const deleteReportPostComment = (
  parentId: string,
  commentId: number,
  reportId: number,
  depth?: string,
  postId?: number,
  groupId?: number,
  isType?: string,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const removeReportedData = async () => {
      let path = groupId
        ? `/groups/${groupId}/posts/${postId}/report/${reportId}`
        : `/posts/comments/reports/${reportId}`;

      if (isType === 'looking-for') {
        path = `/seeks/${postId}/comments/${commentId}/report/${reportId}`;
      }

      if (isType === 'coagency') {
        path = `/offers/${postId}/comments/${commentId}/report/${reportId}`;
      }
      const res = await network().post().deleteBoardPostCommentReport(path);
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      console.log('data==>', data);
      return data;
    };
    try {
      const comments = await removeReportedData();
      console.log('comments in removeReportedData', comments, depth, parentId);
      if (depth === '1depth') {
        dispatch(ActionsComment.updateComment({ comments, updatedId: commentId }));
      } else {
        dispatch(ActionsComment.updateChildren({ comments, parentId: parentId!, updatedId: commentId! }));
      }
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description user 컨텐츠 차단하기
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} parentId
 * @param {number} commentId
 * @param {object} params {block_user_id: number}
 * @param {string} depth
 * @returns {Promise<void>}
 */
export const setBlockUser = (
  parentId: number | null,
  commentId: number,
  params: { block_user_id: number },
  depth: string,
  grouId?: number,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const blockedData = async () => {
      const res = await APISUSER.postBlockUser(`/my/block/user`, params);
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      console.log('data==>', data);
      if (data?.data?.message === 'OK') {
        return data?.data?.message;
      }
    };
    try {
      const comments = await blockedData();
      console.log('comments in blockedData', comments);
      if (comments === 'OK') {
        if (depth === '1depth') {
          dispatch(ActionsComment.updateComment({ comments, updatedId: commentId }));
        } else {
          dispatch(
            ActionsComment.updateChildren({
              comments,
              parentId: parentId!,
              updatedId: commentId!,
            }),
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
};

/**
 * @description user 차단 해제
 * @Class
 * @category Api
 * @subcategory comment
 * @param {number} parentId 댓글 parentId
 * @param {number} commentId commentId
 * @param {number} userId user id
 * @param {string} depth 댓글 depth
 * @returns {Promise<void>}
 */
export const unBlockUser = (
  parentId: number | null,
  commentId: number,
  userId: number,
  depth: string,
  grouId?: number,
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const unBlockedData = async () => {
      const res = await APISUSER.deleteBlockUser(`/my/block/user?block_user_id=${userId}`);
      if (!res) {
        throw new Error('Error occured');
      }
      const data = await res;
      console.log('data==>', data);
      if (data?.data?.message === 'OK') {
        return data?.data?.message;
      }
    };
    try {
      const comments = await unBlockedData();
      console.log('comments in unBlockedData', comments);
      if (comments === 'OK') {
        if (depth === '1depth') {
          dispatch(ActionsComment.updateComment({ comments, updatedId: commentId }));
        } else {
          dispatch(
            ActionsComment.updateChildren({
              comments,
              parentId: parentId!,
              updatedId: commentId!,
            }),
          );
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
};
