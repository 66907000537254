import { convertPyungToSquareMeters, convertSquareMetersToPyung } from './common';

/**
 * @description 숫자에 콤마 표시
 * @Class
 * @category utils
 * @param {number} x
 * @returns {string}
 */
export function numberWithCommas(x: string | number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * @description 웹뷰 닫기
 * @Class
 * @category utils
 * @returns {void}
 */
export const onClose = () => {
  if (window.webkit) {
    window.webkit.messageHandlers.close.postMessage('');
  } else if (window.Android) {
    window.Android.close('');
  }
};

/**
 * @description 서브 웹뷰 호출
 * @Class
 * @category utils
 * @returns {void}
 */
export const onView = (url: string) => {
  if (!url) return;
  const payload = JSON.stringify({ url: url });
  if (window.webkit) {
    window.webkit.messageHandlers.view.postMessage(payload);
  } else if (window.Android) {
    window.Android.view(payload);
  }
};

/**
 * @description 아래로 대체
 * @param {string} type
 * @Class
 * @category utils
 * @returns {void}
 */
export const movePostList = (type: string) => {
  if (!type) return;
  const payload = JSON.stringify({ type: type });
  if (window.webkit) {
    window.webkit.messageHandlers.postList.postMessage(payload);
  } else if (window.Android) {
    window.Android.postList(payload);
  }
};

/**
 * @description 글쓰기 이후 탭이동
 * @Class
 * @category utils
 * @param {string} type boardType
 * @returns {void}
 */
export const moveWritingTab = (type: string): void => {
  const params = {
    tabIndex: 3,
    parameter: type,
  };
  const payload = JSON.stringify(params);
  if (window.webkit) {
    window.webkit.messageHandlers.moveTab.postMessage(payload);
  } else if (window.Android) {
    window.Android.moveTab(payload);
  }
};

/**
 * @description 메인 뷰 이동
 * @Class
 * @category utils
 * @param {number} tabIndex 탭 index
 * @param {string} parameter 메인 뷰 param
 * @returns {string}
 */
export const moveMain = (tabIndex: number, parameter: string) => {
  const payload = JSON.stringify({ tabIndex: tabIndex, parameter: parameter });

  if (window.Android) {
    window.Android.moveTab(JSON.stringify({ tabIndex: tabIndex, parameter: parameter }));
  } else {
    window.webkit.messageHandlers.moveTab.postMessage(payload);
  }
};

/**
 * @description 평형 제곱미터 convert
 * @Class
 * @category utils
 * @param {number} num value
 * @param {string} type 평형 타입 suqare or pyung
 * @returns {string}
 */
export const convertArea = (num: number, type?: string): number => {
  let value: number;
  if (type === 'square') {
    // 제곱미터를 평으로 변경
    value = +convertSquareMetersToPyung(num);
  } else {
    // 평을 제곱미터로 변경
    value = +convertPyungToSquareMeters(num);
  }
  return value;
};

/**
 * @description 숫자 단위 변경
 * @Class
 * @category utils
 * @param {string} num value
 * @returns {string}
 */
export const convertNumberUnit = (value: string) => {
  const arr = ['원', '만원', '억'];
  let targetIndex = 0;
  if (value.length > 3) {
    targetIndex = 2;
  } else if (value.length === 3) {
    targetIndex = 1;
  } else {
    targetIndex = 0;
  }

  const divide = targetIndex === 2 ? 10000 : targetIndex === 1 ? 1000 : 100;

  return `${+value / divide}${arr[targetIndex]}`;
};

/**
 * @description url 치환
 * @Class
 * @category utils
 * @param {string} url
 * @returns {void}
 */
export const convertPcToMobileUrl = (url?: string) => {
  const domain = import.meta.env.VITE_WEB_DOMAIN;
  const appDomain = import.meta.env.VITE_APP_DOMAIN;
  const categroy = [
    '/localgroup',
    '/allposts/free-board',
    '/allposts/issue',
    '/coagency-coagency',
    '/mygroup/group',
    '/mygroup/member',
    '/mygroup/settingmy',
    '/mygroup/boardnotice',
    '/search',
    '/myboard-coagency',
    '/myboard-comment',
    '/bookmark-board',
    '/myboard-lookingfor',
    '/myboard-board',
    '/myinfo',
    '/help',
    '/qna',
    '/bookmark-coagency',
    '/bookmark-lookingfor',
  ];
  const parsePath: any = {
    '/localgroup': '/localgroup',
    '/allposts/free-board': '/allposts/free-board',
    '/allposts/issue': '/allposts/issue',
    '/mygroup/group': '/mygroup/group/',
    '/mygroup/member': '/groupmembersearch/',
    '/mygroup/settingmy': '/groupsetting/',
    '/mygroup/boardnotice': '/groupnoticelist/',
    '/search': '/search',
    '/myboard-coagency': '/myboard-coagency',
    '/myboard-comment': '/myboard-comment',
    '/bookmark-board': '/bookmark-board',
    '/myboard-lookingfor': '/myboard-lookingfor',
    '/myboard-board': '/myboard-board',
    '/myinfo': '/myinfo',
    '/help': '/help',
    '/qna': '/qna',
    '/bookmark-coagency': '/bookmark-coagency',
    '/bookmark-lookingfor': '/bookmark-lookingfor',
  };

  if (url && url.includes(domain)) {
    let copyUrl = url;
    copyUrl = copyUrl.slice(copyUrl.indexOf('.net/') + 4);
    copyUrl = appDomain + copyUrl;

    categroy.forEach((text: string) => {
      if (copyUrl.includes(text)) {
        if (parsePath?.[text]) {
          const newPath = parsePath[text];
          copyUrl = copyUrl.replace(text, newPath);
          if (copyUrl?.includes('?id')) {
            copyUrl = copyUrl.replace(/\?id=/, '');
          }
        }
      }
    });

    return copyUrl;
  } else {
    return '';
  }
};

/**
 * @description 숫자 단위 변경
 * @Class
 * @category utils
 * @param {number} value value
 * @returns {string}
 */
export function formatNumber(value: number = 0) {
  if (value >= 100000000) {
    return value || 0 / 100000000 + '억';
  } else if (value >= 10000) {
    const man: number = Math.floor(value / 10000) || 0;
    const remainder: number = value % 10000 || 0;
    if (remainder === 0) {
      return man + '억';
    } else {
      return man + '억' + remainder + '만';
    }
  } else {
    if (value === 0) {
      return value || 0;
    }

    return `${value || 0}만`;
  }
}
