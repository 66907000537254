import { useAppDispatch } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

const BrokerAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //중개사 자체 인증하기
  const brokerAuth = async (): Promise<void> => {
    dispatch(onLoad());
    try {
      //중개사 정보가 연동가능한지 체크
      const { message, data } = await network().broker().getAipartner();
      dispatch(offLoad());
      dispatch(
        ActionModal.openModal({
          modalType: 'broker_alert_sync',
          isOpen: true,
          data: {
            actionNavi: () => navigate('/broker/sync', { state: data }),
            rejectNavi: () => navigate('/broker/company', { state: { broker_agree: 'Y' } }),
          },
        }),
      );
    } catch (e: any) {
      //중개사정보가 없을때
      dispatch(offLoad());
      navigate('/broker/company', { state: { broker_agree: 'Y' } });
    }
  };

  //중개사 이실장과 연동하기
  const brokerSync = async (): Promise<void> => {
    dispatch(onLoad());
    try {
      //중개사 정보를 연동하고..
      const { message, data } = await network().broker().getAipartner();
      dispatch(offLoad());
      navigate('/broker/sync', { state: data });
    } catch (e: any) {
      //중개사정보가 없을때
      dispatch(offLoad());
      dispatch(
        ActionModal.openModal({
          modalType: 'broker_alert',
          isOpen: true,
          data: { txt: e.message || '오류가 발생했습니다.' },
        }),
      );
    }

    //최초 로그인후 중개사 인증 안내화면
    // dispatch(ActionModal.openModal({ modalType: 'broker_alert_join', isOpen: true, data: {actionNavi: () => navigate('/broker')} }));
  };
  return (
    <>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={CommonStyles['content-div']}>
            <div className={ResetStyles['ta-c']}>
              <p className={CommonStyles['page-txt']}>
                <b>중개사를 위한 커뮤니티 서비스</b>
              </p>
              <i className={CommonStyles['logo-ainet']}></i>
              <p className={CommonStyles['page-txt']}>
                중개사 인증을 통해 중개사를
                <br />
                위한 특별한 서비스를 이용해보세요!
              </p>
            </div>
            <button
              type="button"
              className={cn(CommonStyles['gray-box'], ResetStyles['d-flex'], CommonStyles['ja-c'])}
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'broker_bottom_agree',
                    isOpen: true,
                    data: { actionNavi: () => brokerAuth() },
                  }),
                )
              }
            >
              <i className={CommonStyles['ch-ms']}></i>
              <div>
                <p>대표/소속</p>
                <p className={CommonStyles['bld-txt']}>공인중개사 인증하기</p>
              </div>
            </button>
            <button
              type="button"
              className={cn(CommonStyles['gray-box'], ResetStyles['d-flex'], CommonStyles['ja-c'])}
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'broker_bottom_agree',
                    isOpen: true,
                    data: { actionNavi: () => brokerSync() },
                  }),
                )
              }
            >
              <i className={CommonStyles['ch-mr']}></i>
              <div>
                <p>사용중인 이실장</p>
                <p className={CommonStyles['bld-txt']}>중개사 정보 연동하기</p>
              </div>
              <span className={cn(CommonStyles.tooltip, CommonStyles.wh)} style={{ display: 'block' }}>
                이실장을 이용 중이시라면 간편하게 연동하세요.
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrokerAuth;
