import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { IJuridical, IJuridicalSearch } from '<broker>';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';

const BrokerJurirno = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //넘어온값 확인
  const location = useLocation();
  const { state } = location;
  const [param, setParam] = useState(state);
  useEffect(() => {
    if (!param?.broker_agree || !param?.registration_number) {
      navigate('/broker');
    }
  }, []);

  //조회버튼 클릭 가능여부
  const [isSearch, setIsSearch] = useState<boolean>(false);

  //auto focus
  const inputJurirNumberRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (inputJurirNumberRef.current) {
        inputJurirNumberRef.current.focus();
      }
    }, 200);
  }, []);

  //개설등록번호
  const [jurirno, setJurirno] = useState<string>('');
  //개설등록번호 onchange
  const handleJurirNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setJurirno(e.target.value);
  };
  //개설등록번호 번호형식
  useEffect(() => {
    if (jurirno == '') {
      inputJurirNumberRef.current?.focus();
    }

    if (jurirno && jurirno.length >= 1) {
      setIsSearch(true);
    }
  }, [jurirno]);

  //조회시작
  const handleSearchConfirm = () => {
    if (isSearch === true) {
      dispatch(onLoad());
      setIsSearch(false);
      const req: IJuridicalSearch = {
        jurirno: jurirno,
      };
      network()
        .broker()
        .getJuridicalSearch(req)
        .then((res) => {
          dispatch(offLoad());
          if (res.data.total > 0) {
            const juridicalList: IJuridical[] = res.data.list;
            navigate('/broker/list', {
              state: {
                broker_agree: param?.broker_agree,
                registration_number: param?.registration_number,
                jurirno: jurirno,
                juridicalList: juridicalList,
              },
            });
          } else {
            navigate('/broker/empty', {
              state: {
                registration_number: param?.registration_number,
                jurirno: jurirno,
              },
            });
          }
        })
        //조회 에러
        .catch((err) => {
          dispatch(offLoad());
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: err.response.data.message },
            }),
          );
          setIsSearch(true);
        });
    }
  };
  return (
    <>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={CommonStyles['content-div']}>
            <p className={CommonStyles['page-tit']}>개설등록번호를 조회해주세요.</p>
            <div className={cn(CommonStyles['input-area'], CommonStyles['clear-has'])}>
              <input
                type="text"
                ref={inputJurirNumberRef}
                name="registration_number"
                id="registration_number"
                value={jurirno || ''}
                placeholder="개설등록번호"
                onChange={(e) => handleJurirNumber(e)}
              />
              {jurirno.length > 0 && <button className={CommonStyles.delete} onClick={() => setJurirno('')}></button>}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles['fixed'])}>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], !isSearch ? CommonStyles.disabled : '')}
          onClick={handleSearchConfirm}
        >
          확인
        </button>
      </div>
    </>
  );
};

export default BrokerJurirno;
