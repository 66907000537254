/* eslint-disable no-useless-escape */
import { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import dompurify from 'dompurify';
// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionLoading from 'redux/slices/loading';

// components
import PostAttach from './PostAttach';
// styles
import CommonStyles from 'styles/common.module.css';
// configs
import * as Config from 'configs/configs';
// utils
import network from 'utils/network';
import { timeDisplay } from 'utils/common';
import { IMixedKeyValue } from '<modal>';
import { replaceURLWithAtag } from 'utils/common';
import { openModal } from 'redux/slices/modal';
import Toast from 'components/common/Toast/Toast';

import * as APIS from 'api/group';
import * as BAPIS from 'api/board';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';
import { moveMain, onClose } from 'utils';

/**
 * @description 게시글 상세 F.C
 * @Class
 * @category Pages
 * @subcategory 전체게시판
 * @component
 * @returns {JSX.Element}
 */
const PostDetail = () => {
  const sanitizer = dompurify.sanitize;
  const locationHistory = useLocation();
  const { pathname } = locationHistory;
  const { id, groupId } = useParams();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state: RootState) => state.user);
  const modal = useAppSelector((state: RootState) => state.modal);
  const toastState = useAppSelector((state: RootState) => state.toast);
  const { currentUser } = userData;

  const searchParams = new URLSearchParams(locationHistory.search);
  const showComment = searchParams.get('show');
  const closeComment = searchParams.get('close');
  const [detail, setDetail] = useState<IMixedKeyValue>({});
  const [isDelete, setIsDelete] = useState(false);
  const [refresh, setRefresh] = useState({
    key: new Date().getTime(),
  });

  const clickRef = useRef<boolean>(false);
  const nowDate = new Date();
  const linkRegex = /(https?\:\/\/)?(www\.)?[^\s]+\.[^\s]+/g;
  const location = useLocation();
  const params = new URLSearchParams(location?.search || '');
  const type = params?.get?.('type') || '';
  const navigate = useNavigate();

  // 게시글 상세 가져오기
  useEffect(() => {
    const abortController = new AbortController();
    dispatch(ActionLoading.onLoad());
    const getData = async () => {
      await getDetail();
    };
    getData();
    return () => abortController.abort();
  }, [pathname, id, groupId, refresh]);

  // 게시글 상세 가져오기
  useEffect(() => {
    const abortController = new AbortController();
    if (
      (!modal?.isOpen && modal.modalType === 'show_comment_list') ||
      (modal.isConfirm &&
        (modal.modalType === 'success_bookmark_complete' || modal.modalType === 'success_bookmark_remove'))
    ) {
      const getData = async () => {
        if (pathname.includes('group-postdetail')) {
          await getGroupPostDetail(Number(groupId), Number(id));
        } else {
          await getPostDetail(Number(id));
        }
      };
      getData();
    }
    return () => abortController.abort();
  }, [modal]);

  // 새로고침 함수
  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );
    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  // 코멘트 창 open 여부
  useEffect(() => {
    if (showComment === 'true' && closeComment !== 'true') {
      console.log(showComment, 'show');
      handleComment(detail);
    }
  }, [showComment, closeComment, detail]);

  // console.log('detail001', detail);

  useEffect(() => {
    if ((modal?.modalType === 'success_block_user' || modal.modalType === 'success_unblock_user') && modal?.isConfirm) {
      setRefresh({
        key: new Date().getTime(),
      });
    }
    if (modal?.modalType === 'success_delete' && modal?.isConfirm) {
      setIsDelete(true);
    } else {
      setIsDelete(false);
    }
  }, [modal]);

  /**
   * @description 게시글 상세 조회 api
   * @returns {void}
   */
  const getDetail = useCallback(async () => {
    if (pathname.includes('group-postdetail')) {
      await getGroupPostDetail(Number(groupId), Number(id));
    } else {
      await getPostDetail(Number(id));
    }
  }, [groupId, id]);

  /**
   * @description 새로고침 함수
   * @returns {void}
   */
  const refreshFn = () => {
    if (pathname.includes('group-postdetail')) {
      getGroupPostDetail(Number(groupId), Number(id));
    } else {
      getPostDetail(Number(id));
    }
  };

  /**
   * @description 게시글(모임) 상세 조회 api
   * @returns {void}
   */
  const getGroupPostDetail = useCallback(
    async (groupId: number, id: number) => {
      try {
        const res = await network().groupPost().getGroupPostDetail(`/groups/${groupId}/posts/${id}`);
        setDetail(res.data);
        dispatch(ActionLoading.offLoad());
      } catch (err: any) {
        if (err?.response?.data?.message === '권한이 없습니다.' && type === 'web') {
          dispatch(
            openModal({
              modalType: 'alert_common',
              data: {
                txt: '권한이 없어 해당 게시글 조회가 불가능합니다.\n모임에 가입 후 이용해주세요! ',
              },
              isOpen: true,
              action: () => {
                navigate(`/mygroup/group?id=${groupId}`);
              },
            }),
          );
        }
        if (err?.response?.data?.error === 'Data not found') {
          dispatch(
            openModal({
              modalType: 'alert_common',
              data: {
                txt: '게시글이 존재하지 않습니다.',
              },
              isOpen: true,
              action: () => {
                navigate(`/mygroup/group?id=${groupId}`);
              },
            }),
          );
        }
        dispatch(ActionLoading.offLoad());
      }
    },
    [groupId, id],
  );

  /**
   * @description 전체게시판 상세정보 가져오기
   * @param {number} postId {post id}
   */
  const getPostDetail = async (postId: number) => {
    try {
      const res = await network().post().getPostDetail(`/posts/${postId}`);
      setDetail(res.data);
      dispatch(ActionLoading.offLoad());
    } catch (err: any) {
      if (err?.response?.data?.message === '권한이 없습니다.' && type === 'web') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '권한이 없어 해당 게시글 조회가 불가능합니다.',
            },
            isOpen: true,
            action: () => {
              moveMain(3, '');
              onClose();
            },
          }),
        );
      }
      if (err?.response?.data?.message === 'Data not found') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '게시글이 존재하지 않습니다.',
            },
            isOpen: true,
            action: () => {
              moveMain(3, '');
              onClose();
            },
          }),
        );
      }
      dispatch(ActionLoading.offLoad());
    }
  };

  /**
   * @description 게시글 메뉴 sheet open
   * @param {IMixedKeyValue} post 게시글 데이터
   * @returns {void}
   */
  const handleMoreBotSheet = async (post: IMixedKeyValue) => {
    if (isDelete) return;
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    if (post?.user?.id === currentUser?.id) {
      dispatch(openModal({ modalType: 'bottomSheet_menuMyPost', data: post, isOpen: true, infoMe: currentUser.id }));
    } else {
      dispatch(openModal({ modalType: 'bottomSheet_menu', data: post, isOpen: true, infoMe: currentUser.id }));
    }
  };

  /**
   * @description 게시글 댓글 sheet open
   * @param {IMixedKeyValue} post 게시글 데이터
   * @returns {void}
   */
  const handleComment = async (post: IMixedKeyValue) => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    if (isDelete) return;
    dispatch(openModal({ modalType: 'show_comment_list', data: post, isOpen: true }));
  };

  /**
   * @description 좋아요 or 해제
   * @returns {void}
   */
  const handleClickLikePost = async () => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크

    if (clickRef.current) return;
    clickRef.current = true;
    try {
      let res;
      if (groupId) {
        res = await APIS.postLikeGroupPost(`/groups/${groupId}/posts/${detail.id}/like`);
      } else {
        res = await BAPIS.postBoardLikeAndDisLike(detail.id, 1);
      }
      if (res?.data) {
        setDetail({
          ...detail,
          ...res.data,
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 싫어요 or 해제
   * @returns {void}
   */
  const handleClickdisLikePost = async () => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크

    if (clickRef.current) return;
    clickRef.current = true;
    try {
      let res;
      if (groupId) {
        res = await APIS.postdisLikeGroupPost(`/groups/${groupId}/posts/${detail.id}/hate`);
      } else {
        res = await BAPIS.postBoardLikeAndDisLike(detail.id, 2);
      }
      if (res?.data) {
        setDetail({
          ...detail,
          ...res.data,
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  return (
    <>
      {Object.keys(detail).length > 0 && (
        <div
          className={cn(CommonStyles.content, Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB)}
          style={{ paddingBottom: '100px' }}
        >
          <div className={cn(CommonStyles['post-card'], CommonStyles['post-detail'])}>
            <div className={CommonStyles['member-information']}>
              <div className={CommonStyles.clickable}>
                <div className={CommonStyles.profile}>
                  <div
                    className={CommonStyles['img-div']}
                    style={{
                      width: 48,
                      height: 48,
                      backgroundImage: detail?.user?.profile_photo_path
                        ? `url(${detail?.user?.profile_photo_path}`
                        : `url(../images/icon_profile.svg)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                    }}
                  />
                  {detail?.user?.verify?.broker_code && (
                    <span className={CommonStyles.grade} aria-label={detail?.user?.grade}></span>
                  )}
                </div>
                <div className={CommonStyles['mem-info']}>
                  <p className={CommonStyles.name}>{detail?.user?.nickname}</p>
                  <div className={CommonStyles['divide-box']}>
                    {detail?.user?.company && (
                      <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                        {detail?.user?.company?.name}
                      </p>
                    )}
                    <p>{timeDisplay(nowDate, detail?.created_at)}</p>
                  </div>
                </div>
              </div>
              <button className={CommonStyles.tool} onClick={() => handleMoreBotSheet(detail)}></button>
            </div>
            {isDelete && (
              <div className={CommonStyles.detail}>
                <div className={CommonStyles['blind-box']}>삭제된 게시글입니다.</div>
              </div>
            )}
            {(detail?.user_block || detail?.post_report?.length > 0) && !isDelete ? (
              <div className={CommonStyles.detail}>
                <div className={CommonStyles['blind-box']}>
                  {detail?.user_block ? '차단한 회원의 글입니다.' : '신고된 게시글입니다.'}
                </div>
              </div>
            ) : !isDelete ? (
              <div className={CommonStyles.detail}>
                {detail?.title && (
                  <p className={cn(CommonStyles.title, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                    {detail?.title}
                  </p>
                )}
                {detail && (
                  <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-line' }}>
                    <div
                      id="postDetailContent"
                      dangerouslySetInnerHTML={{
                        __html: sanitizer(detail?.content.replace(linkRegex, replaceURLWithAtag)).replace(
                          /(?:\r\n|\r|\n)/g,
                          '<br/>',
                        ),
                      }}
                    />
                  </div>
                )}
                {detail && <PostAttach attachType="detail" post={detail} isDetail={true} />}
              </div>
            ) : (
              <></>
            )}
            {!detail?.user_block && !detail?.post_report?.length && (
              <div className={CommonStyles['post-function']} style={{ position: 'absolute' }}>
                <button
                  className={cn(CommonStyles.like, detail?.post_action?.[0]?.kind === 1 && CommonStyles.on)}
                  onClick={() => handleClickLikePost()}
                >
                  <i className={CommonStyles['icon-like']}></i>
                  {detail?.liked_count}
                </button>
                <button
                  className={cn(CommonStyles.dislike, detail?.post_action?.[0]?.kind === 2 && CommonStyles.on)}
                  onClick={() => handleClickdisLikePost()}
                >
                  <i className={CommonStyles['icon-dislike']}></i>
                  {detail?.hated_count}
                </button>
                <button className={CommonStyles.comment} onClick={() => handleComment(detail)}>
                  <i className={CommonStyles['icon-comment']}></i>
                  {detail?.comments_list_count}
                </button>
              </div>
            )}
          </div>
          {toastState.isOpen && <Toast />}
        </div>
      )}
    </>
  );
};

export default PostDetail;
