import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState } from 'redux/store';
import { persistor } from '../../../../main';
import { rmLocStore } from 'utils/common';
import network from 'utils/network';
import * as ActionModal from 'redux/slices/modal';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';

import {
  createBookmarkForGroup,
  deleteBookmarkForGroup,
  createBookmarkForFree,
  deleteBookmarkForFree,
  deleteMyPostForGroup,
  deleteMyPostForFree,
} from 'api/feed';
import * as Config from 'configs/configs';
import { FeedItemType, FeedErrorType } from '../../../../@types/feed';

import cn from 'classnames';
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { onClose, onView } from 'utils';

/**
 * @description feed sheet
 * @Class
 * @category Components
 * @subcategory feed sheet
 * @component
 * @returns {JSX.Element}
 */
const FeedSheet = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { modalType, data, isOpen } = useAppSelector((state: RootState) => state.modal);
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const { appInfo } = useAppSelector((state: RootState) => state.appInfo);

  const { isOpen: isToastOpen = false } = useAppSelector((state: RootState) => state.toast);
  const { setToastMessage } = useToast();
  const agent = navigator.userAgent.toLowerCase();

  const handleModalClose = () => {
    //setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    }, 200);
  };

  // 북마크 등록
  const createBookmark = async (post: FeedItemType) => {
    try {
      if (post.post_bookmark?.length > 0) return;

      // 북마크 등록
      let url = '';
      if (post.group_id) {
        await dispatch(createBookmarkForGroup({ group_id: post.group_id, post_id: post.id })).unwrap();
        url = '/feed/bookmarks?type=group';
      } else {
        await dispatch(createBookmarkForFree({ post_id: post.id })).unwrap();
        url = '/feed/bookmarks';
      }

      handleModalClose();
      setToastMessage({
        duration: 15000,
        content: (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{Config.TOAST_MESSAGE_TYPE.BOOKMARK_COMPLETE}</span>{' '}
            <button onClick={() => navigate(url)}>확인하러가기</button>
          </div>
        ),
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        handleModalClose();
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      } else {
        //console.log('create error:', feedError.message);
      }
    }
  };

  // 북마크 해제
  const deleteBookmark = async (post: FeedItemType) => {
    try {
      if (!post.post_bookmark || post.post_bookmark?.length == 0) return;

      // 북마크 해제
      if (post.group_id) {
        await dispatch(
          deleteBookmarkForGroup({
            id: post.post_bookmark[0].id,
            group_id: post.group_id,
            group_post_id: post.post_bookmark[0].group_post_id,
          }),
        ).unwrap();
      } else {
        await dispatch(deleteBookmarkForFree({ id: post.post_bookmark[0].id })).unwrap();
      }

      handleModalClose();
      setToastMessage({
        duration: 2000,
        content: Config.TOAST_MESSAGE_TYPE.BOOKMARK_REMOVE,
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        handleModalClose();
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      } else {
        //console.log('delete error:', feedError.message);
      }
    }
  };

  // 게시글 삭제
  const deletePost = async (post: FeedItemType) => {
    try {
      if (post.group_id) {
        await dispatch(deleteMyPostForGroup({ group_id: post.group_id, id: post.id })).unwrap();
      } else {
        await dispatch(deleteMyPostForFree({ id: post.id })).unwrap();
      }

      handleModalClose();
      setToastMessage({
        duration: 2000,
        content: Config.TOAST_MESSAGE_TYPE.POST_DELETE,
        type: 'message',
      });
    } catch (error) {
      const feedError = error as FeedErrorType;

      if (feedError?.status === 422) {
        handleModalClose();
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.NOT_AUTHORITY,
          type: 'message',
        });
      } else {
        //console.log('delete error:', feedError.message);
      }
    }
  };

  // 북마크 추가 및 해제 핸들러
  const handleBookmark = (post: FeedItemType) => {
    if (post.post_bookmark?.length > 0) {
      dispatch(
        ActionModal.openModal({
          modalType: 'confirm_bookmark_comment',
          action: () => {
            deleteBookmark(post);
          },
        }),
      );
    } else {
      createBookmark(post);
    }
  };

  // 게시글 삭제 핸들러
  const handlePostDelete = (post: FeedItemType) => {
    dispatch(
      ActionModal.openModal({
        modalType: 'confirm_delete_post',
        action: () => {
          deletePost(post);
        },
      }),
    );
  };

  // 공유하기
  const handleSharePost = (post: FeedItemType) => {
    dispatch(ActionModal.openModal({ modalType: 'bottomSheet_share', data: post, isOpen: true }));
  };

  // 로그아웃
  const logout = async () => {
    try {
      const response = await network().sso().signout();
      if (response.data?.result == 'ok') {
        await persistor.purge();
        rmLocStore('token');
        rmLocStore('refresh_token');

        if (window.webkit) {
          window.webkit.messageHandlers.logout.postMessage('');
        } else if (window.Android) {
          window.Android.logout();
        } else {
          window.webkit.messageHandlers.logout.postMessage('');
        }
        if (import.meta.env.PROD) {
          return;
        } else {
          navigate('/signin');
        }
      }
    } catch (error) {
      //console.log('logout error:', error);
    }
  };

  // 개인정보설정
  const changeUserInfo = async () => {
    try {
      const response = await network().sso().getJWTForMember();

      if (response.data?.JWT) {
        onView(`${import.meta.env.VITE_SSO_DOMAIN}/integrated-member?resToken=${response.data.JWT}`);
      }
    } catch (error) {
      //console.log('error:', error);
    }
  };

  return (
    <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
      <div
        id="render-modal"
        className={cn(
          Styles['popup-container'],
          Styles['bottom-sheet-popup'],
          Styles['report-pop'],
          Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
        )}
      >
        <div className={Styles['popup-header']}>
          <button className={Styles.close} onClick={handleModalClose} />
        </div>
        <div className={Styles['popup-content']}>
          <div className={cn(CommonStyles['button-list'], Styles['button-list'])}>
            {/* 설정 */}
            {modalType === 'bottomSheet_feed_setting' && (
              <>
                <button onClick={changeUserInfo}>개인정보 설정</button>
                <button
                  onClick={() => {
                    if (import.meta.env.MODE === 'localdev') {
                      navigate(`/feed/brokerinfo`);
                    } else {
                      onView(`${import.meta.env.VITE_APP_DOMAIN}/feed/brokerinfo`);
                    }
                  }}
                >
                  중개사 정보
                </button>
                <button
                  onClick={() => {
                    //handleModalClose();
                    onView('aipartnernet://net.aipartner/open?name=PushSetting');
                  }}
                >
                  푸시 알림 설정
                </button>
                {appInfo && <button>앱 버전 {appInfo}</button>}
                <button onClick={logout}>로그아웃</button>
              </>
            )}
            {/* 더보기 */}
            {modalType === 'bottomSheet_feed_more' && (
              <>
                <button
                  onClick={() => {
                    const boardType = data?.post?.group_id
                      ? 'group'
                      : data?.post?.kind && Config.BOARD_TYPE[String(data?.post?.kind)]
                      ? Config.BOARD_TYPE[String(data?.post?.kind)]
                      : '';

                    if (!boardType) return;
                    if (import.meta.env.MODE === 'localdev') {
                      navigate(`/writing?mode=edit&type=${boardType}&postId=${data?.post?.id}`);
                      handleModalClose();
                    } else {
                      onView(
                        `${import.meta.env.VITE_APP_DOMAIN}/writing?mode=edit&type=${boardType}&postId=${data?.post
                          ?.id}`,
                      );
                    }
                  }}
                >
                  수정하기
                </button>
                {/* <button onClick={() => handleSharePost(data?.post)}>공유하기</button> */}
                <button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.currentTarget.disabled = true;
                    handleBookmark(data?.post);
                  }}
                >
                  북마크{data?.post?.post_bookmark.length > 0 ? ' 해제' : ''}
                </button>
                <button
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.currentTarget.disabled = true;
                    handlePostDelete(data?.post);
                  }}
                >
                  삭제하기
                </button>
              </>
            )}
          </div>
        </div>
        <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'], CommonStyles['mt0'])}>
          <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
            취소
          </button>
        </div>
      </div>
      {isToastOpen && <Toast />}
    </div>
  );
};

export default FeedSheet;
