import { RootState, useAppDispatch } from 'redux/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userSelector } from 'redux/slices/user';

import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Styles from 'styles/header.module.css';

const BrokerInvite = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setToastMessage } = useToast();

  const { me } = useSelector(userSelector);
  const { isOpen = false } = useSelector((state: RootState) => state.toast);

  //  주소복사
  const handleClickCopy = () => {
    window.navigator.clipboard.writeText(location.origin + '/broker').then(() => {
      setToastMessage({
        duration: 2000,
        content: '링크가 복사되었습니다.\n 원하는 곳에 ctrl+v를 해보세요.',
        type: 'message',
      });
    });
  };

  /**
  //전화번호
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  //전화번호 onchange
  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhoneNumber(e.target.value);
    }
  };
  //전화번호 번호형식
  useEffect(() => {
    if (phoneNumber && phoneNumber.length >= 11) {
      const _phoneNumber = phoneNumber.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      setPhoneNumber(_phoneNumber);
      setIsKakaotalk(true);
    }else {
      setIsKakaotalk(false);
    }
  }, [phoneNumber]);
  */

  const [isKakaotalk, setIsKakaotalk] = useState<boolean>(false);
  const handleInviteSend = () => {
    const kakaoBody = {
      title: '대표님 초대하고\n중개사 인증 받으세요\n\n',
      description: `대표님 안녕하세요. 이실장넷입니다.\n대표님 소속공인중개사 ${me.name}님께서 이실장넷\n가입에 초대하였습니다!\n이실장넷은 중개사 인증을 받은 회원님들은 다양한\n중개사와 매물 공유가 가능합니다. 단, 대표님께서 가입을 완료해야 소속공인중개사분께서 중개사인증을 완료할 수 있습니다.\n\n 소속 중개사님들과 함께 이실장넷을 이용해 보세요!\n\n https://www.aipartner.net`,
      imageUrl: `${location.origin}/images/kakao_invite.png`,
      link: {
        mobileWebUrl: location.origin + '/broker/ceo',
        webUrl: location.origin + '/broker/ceo',
      },
    };

    if (window.webkit) {
      window.webkit.messageHandlers.shared.postMessage(kakaoBody);
      setToastMessage({
        duration: 2000,
        content: '초대장 보내기를 완료하였습니다.',
        type: 'message',
      });
    } else if (window.Android) {
      window.Android.shared(JSON.stringify(kakaoBody));
      setToastMessage({
        duration: 2000,
        content: '초대장 보내기를 완료하였습니다.',
        type: 'message',
      });
    }
  };

  //헤더 뒤로가기 예외
  const historyBack = () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'broker_confirm',
        isOpen: true,
        data: {
          txt: '대표님 초대하기를 나중에 하시겠습니까?',
          actionNavi: () => {
            if (window.webkit) {
              window.webkit.messageHandlers.moveTab.postMessage(JSON.stringify({ tabIndex: 0, parameter: '' }));
              window.webkit.messageHandlers.close.postMessage();
            } else if (window.Android) {
              window.Android.moveTab(JSON.stringify({ tabIndex: 0, parameter: '' }));
              window.Android.close();
            }
          },
        },
      }),
    );
  };

  return (
    <>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={historyBack}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>
        <div className={Styles.title}>중개사 인증</div>
        <div className={cn(Styles.right, CommonStyles.right)}></div>
      </header>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={cn(CommonStyles['content-div'], CommonStyles.invite)}>
            <p className={CommonStyles['page-tit']}>대표님께 가입 초대장 보내기</p>
            <div className={CommonStyles['invite-img']}>
              <img src="../images/kakao_invite.png" alt="" />
            </div>
            <div className={CommonStyles['input-wrap']}>
              {/* <div className={cn(CommonStyles['d-flex'], ResetStyles['d-flex'])}>
                <div className={cn(CommonStyles['input-area'], CommonStyles.mt0)}>
                  <input type="text" value={location.origin + '/broker/ceo'} readOnly disabled />
                </div>
                <button className={cn(CommonStyles.btn, CommonStyles.line)} onClick={handleClickCopy}>
                  복사
                </button>
              </div> */}

              {/* <div className={cn(CommonStyles['d-flex'], ResetStyles['d-flex'])}>
                <div className={cn(CommonStyles['input-area'], CommonStyles.mt0)}>
                  <input type="tel" placeholder="휴대폰번호를 입력하세요." value={phoneNumber || ''} onChange={(e) => handlePhoneNumber(e)}/>
                  <input type="tel" placeholder="휴대폰번호를 입력하세요." value={phoneNumber || ''} onChange={(e) => handlePhoneNumber(e)}/>
                </div>
                <button className={cn(CommonStyles.btn, CommonStyles.line,  (!isKakaotalk ? CommonStyles.disabled: ''))}>보내기</button>
              </div> */}

              {/* <div className={cn(CommonStyles['d-flex'], ResetStyles['d-flex'])}>
                <div className={cn(CommonStyles['input-area'], CommonStyles.mt0, CommonStyles.error)}>
                  <input type="text" value="Ooops" readOnly placeholder="휴대폰번호를 입력하세요." />
                  <p className={CommonStyles['guide-txt']}>핸드폰번호가 유효하지 않습니다.</p>
                </div>
                <button className={cn(CommonStyles.btn, CommonStyles.line, CommonStyles.disabled)}>보내기</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles.fixed)}>
        <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.kakao)} onClick={handleInviteSend}>
          <i></i>카카오톡으로 보내기
        </button>
      </div>
      {isOpen && <Toast />}
    </>
  );
};

export default BrokerInvite;
