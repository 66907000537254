import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionUser from 'redux/slices/user';
import { moveMain, onClose } from 'utils';
import { isCheckWebview } from 'utils/common';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

type textAlignType = 'left' | 'center' | 'right';

interface Props {
  type?: string;
  title?: string;
  txt?: string;
  align?: textAlignType;
  fetchBlockUser?: () => void | Promise<void>;
  deleteComment?: () => void | Promise<void>;
  setIsConfirmOpen?: (isConfirmOpen: boolean) => void;
}

/**
 * @description common confirm
 * @Class
 * @category Components
 * @subcategory common modal
 * @component
 * @returns {JSX.Element}
 */
const CommonConfirm = (props: Props) => {
  const { type, txt, title, align = 'center', fetchBlockUser, deleteComment, setIsConfirmOpen } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const subViewControl = useSubView();
  // const isSubView = useAppSelector((state: RootState) => state.global.isSubView);
  const historyPath = useAppSelector((state: RootState) => state.user.historyPath);
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const isVerified = useAppSelector((state: RootState) => state.user.brokerVerified);
  const { modalType, action, data, isOpen } = useAppSelector((state: RootState) => state.modal);

  const [open, setOpen] = useState<boolean>(isOpen || false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    if (modalType === 'find_share_register_temp') {
      moveMain(4, '1');
    }
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false, isConfirm: true }));
  };

  const handlePropModalClose = () => {
    setIsConfirmOpen!(false);
  };

  /**
   * @description action with confirm
   */
  const handleConfirm = () => {
    action?.();
    handleModalClose();
  };

  /**
   * @description 글쓰기에서 나갈때
   */
  const handleNavigateBack = () => {
    handleModalClose();
    dispatch(ActionUser.resetHistoryPath());
    if (import.meta.env.MODE === 'localdev') {
      navigate(-1);
    } else if (!window.history.state || window.history.state.idx === 0) {
      onClose();
    } else {
      navigate(-1);
    }
  };

  /**
   * @description 공유매물 매물종류 변경시 initStep
   * @returns {void}
   */
  const handleChangeCoagencyType = (): void => {
    dispatch(ActionCoagency.initStep('share'));
    return;
  };

  /**
   * @description 공유매물 소재지 변경시 initLocation
   * @returns {void}
   */
  const handleAddrLocationChange = (): void => {
    dispatch(ActionCoagency.initLocation('share'));
    return;
  };

  /**
   * @description 상세 간편등록 이후 아니오시에 내피드로 이동
   * @returns {void}
   */
  const moveToFeed = (): void => {
    if (isCheckWebview()) {
      const moveIndex = data?.moveIndex ? data.moveIndex : 4;
      moveMain(moveIndex, '0');
      onClose();
    } else {
      navigate('/feed');
    }
    dispatch(ActionCoagency.initStep('share'));
    console.log('내피드로 이동');
  };

  /**
   * @description 글쓰기로 이동
   * @returns {void}
   */
  const moveToWrite = (): void => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/newpost-type');
    } else {
      moveMain(2, '');
      onClose();
    }
  };

  /**
   * @description 홈으로 이동
   * @returns {void}
   */
  const moveToHome = (): void => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/mygroup'); //홈으로 이동시킬것
    } else {
      moveMain(0, '0');
      onClose();
    }
  };

  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
          <div className={Styles['popup-content']}>
            {title && (
              <p className={Styles.tit} style={{ textAlign: align }}>
                {title}
              </p>
            )}
            <p className={Styles.txt} style={{ whiteSpace: 'pre-line', textAlign: align }}>
              {txt || data?.txt}
            </p>
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            {(modalType === 'confirm_delete_post' ||
              modalType === 'confirm_delete_comment' ||
              modalType === 'confirm_bookmark_comment' ||
              modalType === 'confirm_block_user' ||
              modalType === 'confirm_custom' ||
              modalType === 'confirm_looking_for_delete_check') && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니오
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                  네
                </button>
              </>
            )}
            {type === 'confirm_block_user' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handlePropModalClose}
                >
                  아니오
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={fetchBlockUser}>
                  네
                </button>
              </>
            )}
            {type === 'confirm_delete_comment' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handlePropModalClose}
                >
                  아니오
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={deleteComment}>
                  네
                </button>
              </>
            )}
            {modalType === 'confirm_join_group' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  취소
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                  확인
                </button>
              </>
            )}
            {modalType === 'confirm_cancel_writing_post' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  취소하기
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleNavigateBack}>
                  나가기
                </button>
              </>
            )}
            {modalType === 'coagency_confirm_easy_registration' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={() => moveToFeed()}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={() =>
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          currStep: 11,
                        },
                      }),
                    )
                  }
                >
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_confirm_success_registration' && (
              <>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
                  확인
                </button>
              </>
            )}
            {modalType === 'coagency_confirm_change_coagency_type' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={handleChangeCoagencyType}
                >
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_confirm_modify_looking_for' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={() => {
                    action?.();
                  }}
                >
                  네
                </button>
              </>
            )}
            {modalType === 'find_share_register_temp' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={() => {
                    action?.();
                  }}
                >
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_confirm_change_location' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={handleAddrLocationChange}
                >
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_registration_cancel' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  // onClick={() => (isVerified ? navigate('/newpost-type') : navigate('/newpostselect'))}
                  onClick={() => (action?.() ? action?.() : moveToWrite())}
                >
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_writing_close' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={() => (action?.() ? action?.() : moveToHome())}
                >
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_edit_cancel' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_looking_edit_cancel' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_detail_registration_cancel' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={() => moveToFeed()}>
                  네
                </button>
              </>
            )}
            {modalType === 'findshare_cancel_write' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={() => moveToFeed()}>
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_confirm_delete_when_edit' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                  네
                </button>
              </>
            )}
            {modalType === 'coagency_offer_edit_confirm' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  아니요
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                  네
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonConfirm;
