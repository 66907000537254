import { IMixedKeyValue } from '<modal>';
import network from 'utils/network';

/**
 * @description 매물 유형 조회
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getSeekTypeList = async (path: string) => {
  const res = await network().group().getSeekTypeList(path);
  return res;
};

/**
 * @description 관계 조회
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getSeekRelationList = async (path: string) => {
  const res = await network().findShare().getSeekRelationList(path);
  return res;
};

/**
 * @description 구합니다 매물 저장(선택항목)
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putPostSeek = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().findShare().putPostSeek(path, payload);
  return res;
};

/**
 * @description  구합니다 저장(필수,선택)
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postSaveSeek = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().findShare().postSaveSeek(path, payload);
  return res;
};

/**
 * @description 구합니다 수정
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const putModifySeek = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().findShare().putModifySeek(path, payload);
  return res;
};

/**
 * @description 구합니다 거래 완료
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putCompleteSeek = async (path: string) => {
  const res = await network().findShare().putCompleteSeek(path);
  return res;
};

/**
 * @description 공유매물 수정 사유 목록 조회
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const getImproveList = async (path: string) => {
  const res = await network().coagency().getImproveList(path);
  return res;
};

/**
 * @description 공유매물 수정 요청
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postImprove = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().coagency().postImprove(path, payload);
  return res;
};

/**
 * @description 공유매물 북마크
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postShareBookmark = async (path: string) => {
  const res = await network().group().postShareBookmark(path);
  return res;
};

/**
 * @description 공유매물 북마크 해제
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @param {any} payload data
 * @returns {Promise<void>}
 */
export const postShareUnBookmark = async (path: string, payload: IMixedKeyValue) => {
  const res = await network().group().postShareUnBookmark(path, payload);
  return res;
};

/**
 * @description 공유매물 수정 요청 확인
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deletePostImpove = async (path: string) => {
  const res = await network().coagency().deletePostImprove(path);
  return res;
};

/**
 * @description 공유매물 공유 중지
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const postStopSharePost = async (path: string) => {
  const res = await network().coagency().postStopSharePost(path);
  return res;
};

/**
 * @description 공유매물 거래 완료 처리
 * @Class
 * @category Api
 * @subcategory 공유매물
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const putCompleteCoagency = async (path: string) => {
  const res = await network().coagency().putCompleteCoagency(path);
  return res;
};

/**
 * @description 구합니다 삭제
 * @Class
 * @category Api
 * @subcategory 구합니다
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deleteSeek = async (path: string) => {
  const res = await network().findShare().deleteSeek(path);
  return res;
};
