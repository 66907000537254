import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as APIS from 'api/group';
import * as CAPIS from 'api/common';
import { IMixedKeyValue } from '<modal>';
import { convertArea, moveMain, numberWithCommas, onClose } from 'utils';
import moment from 'moment';
import useToast from 'hook/useToast';
import { cloneDeep } from 'lodash';
import Toast from 'components/common/Toast/Toast';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { useInView } from 'react-intersection-observer';
import { openModal } from 'redux/slices/modal';
import * as config from 'configs/configs';
import * as ActionUser from 'redux/slices/user';

/**
 * @description 구합니다 상세
 * @Class
 * @category Pages
 * @subcategory 구합니다 게시판
 * @component
 * @returns {JSX.Element}
 */
const FindShareDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const hasReturnNav = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.returnNav;
  const backUrl: string | number = config.PATHURLLIST.find((k) => pathname.includes(k.url))?.backUrl || -1;
  const pathName = new URL(window.location.href).pathname;

  const queryParams = new URLSearchParams(location.search);
  const queryGroupId = queryParams?.get('group_id') || '';
  const queryPush = queryParams?.get('push') || 'false';

  const showComment = queryParams.get('show');
  const closeComment = queryParams.get('close');

  const params = useParams();
  const { id = '' } = params;

  // 상세정보
  const { ref: infoRef, inView: infoView } = useInView({ threshold: 0.5 });
  // 비공개 정보
  const { ref: ref, inView } = useInView({ threshold: 0.6 });
  // 비공개 정보
  const { ref: shareRef, inView: shareInview } = useInView({ threshold: 0.6 });
  // 구합니다 상세 데이터
  const [data, setData] = useState<IMixedKeyValue>({});
  const [tab, setTab] = useState<string>('info');
  // 중복클릭 방지
  const clickRef = useRef<boolean>(false);
  // toast message state
  const toastState = useAppSelector((state: RootState) => state.toast);

  const { setToastMessage } = useToast();

  useEffect(() => {
    const f = async () => {
      fetchDetailData();
    };

    f();
  }, []);

  useEffect(() => {
    const el = document.getElementById('detail');

    const handleScroll = () => {
      const elements = document.querySelectorAll('.tab-menu-section');

      if (el) {
        // 스크롤 위치
        const scrollPosition = el.scrollTop;

        const isNearBottom = el.scrollHeight - el.clientHeight - scrollPosition < 100;

        // 가장 가까운 section 태그를 저장할 변수 초기화
        let closestSection = null;
        let closestDistance = Infinity;
        let prevId: any = '';

        if (!isNearBottom) {
          elements.forEach(function (element) {
            // 요소의 위치
            const elementPosition = element.getBoundingClientRect().top + scrollPosition;

            // 스크롤 위치와 요소의 위치의 거리 계산
            const distance = Math.abs(scrollPosition - elementPosition + 100);

            // 이전에 저장된 가장 가까운 section 태그와 거리를 비교하여 더 가까우면 업데이트
            if (distance < closestDistance && distance <= 200) {
              closestDistance = distance;
              closestSection = element.closest('section');
              if (closestSection?.id) {
                if (prevId !== closestSection?.id) {
                  prevId = closestSection.id;
                  setTab(closestSection.id);
                }
              }
            }
          });
        } else {
          elements.forEach(function (element: any) {
            const elementPosition = element.offsetTop + element.clientHeight;
            const distance = Math.abs(el.scrollHeight - elementPosition);
            if (distance < closestDistance && distance <= 30) {
              closestDistance = distance;
              closestSection = element.closest('section');
              if (closestSection?.id) {
                if (prevId !== closestSection?.id) {
                  prevId = closestSection.id;
                  setTab(closestSection.id);
                }
              }
            }
          });
        }
      }
    };

    el?.addEventListener('scroll', handleScroll);

    //window.addEventListener('scroll', handleScroll);

    return () => {
      el?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (showComment === 'true' && closeComment !== 'true') {
      handleComment(data);
    }
  }, [showComment, closeComment, data]);

  /**
   * @description 구합니다 상세 조회
   * @returns {void}
   */
  const fetchDetailData = async () => {
    if (id === '') return;
    try {
      const res = await APIS.getFindShareDetail(`/seeks/${id}`);

      if (res) {
        setData({
          ...res,
        });
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  /**
   * @description 구합니다 상세 데이터
   * @returns {void}
   */
  const {
    my_seek = false,
    created_at = '',
    types = '',
    price_info = {},
    bookmark_id = null,
    comments_count = 0,
    deal_type_name = '',
    areas = '',
    title = '',
    content = '',
    area_max = '',
    area_min = '',
    status = '',
    status_deal = '',
    addr_info = [],
    address_detail = '',
    id: detailId = null,
    company_name = '',
    ceo = '',
    corp_number = '',
    contact = '',
    editor_name = '',
    type_info = '',
    customer_name = '',
    phone_num = '',
    hidden_memo = '',
    status_share = 'N',
    tel_display = '',
    shares = [],
    addr = '',
    deal_type = '',
  } = data;

  /**
   * @description 구합니다 탭 이동
   * @param {string} selected 선택된 탭
   * @returns {void}
   */
  const handleClickTab = (selected: string) => {
    const el = document.getElementById(selected);
    const detailEl = document.getElementById('detail');
    if (el) {
      el.scrollIntoView();
      setTimeout(() => {
        const val = selected === 'info' ? 100 : selected === 'my-info' ? 80 : 50;
        detailEl?.scrollBy(0, -val);
      }, 50);
    }
  };

  /**
   * @description 배열로된 주소 값에 콤마 추가
   */
  const parseAddr = addr_info.length > 0 ? addr_info.map((item: any) => item.addr).join(', ') : '';

  /**
   * @description 수정페이지로 이동
   * @returns {void}
   */
  const movePageModify = () => {
    navigate(`/coagency-lookingfor-register/modify/${detailId}`);
  };

  /**
   * @description 북마크 추가/해제
   * @param {number} id 게시글 id
   * @param {number} bookmark_id 게시글 북마크 id
   * @returns {void}
   */
  const handleClickAddBookmark = async (id?: number, bookmark_id?: number) => {
    if (id === null || id === undefined) return;
    if (clickRef.current) return;
    // 북마크 등록
    try {
      clickRef.current = true;
      if (bookmark_id === null) {
        const res = await APIS.postFindShareBookmark(`/seeks/${id}/bookmark`);

        if (res.id) {
          const copyState = cloneDeep(data);
          copyState['bookmark_id'] = res.id;
          setData(copyState);

          setToastMessage({
            duration: 2000,
            content: '북마크가 완료되었습니다.',
            type: 'message',
          });
        }
      } else {
        // 북마크 해제
        const payload = {
          ids: [bookmark_id],
        };
        const res = await APIS.postFindShareUnBookmark(`/seeks/bookmark`, payload);
        if (res.length === 0) {
          const copyState = cloneDeep(data);
          copyState['bookmark_id'] = null;
          setData(copyState);

          setToastMessage({
            duration: 2000,
            content: '북마크가 삭제되었습니다.',
            type: 'message',
          });
        }
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 구합니다 매물 거래 완료 modal open
   * @returns {void}
   */
  const handleClickCompleteOpenModal = async () => {
    dispatch(
      openModal({
        modalType: 'coagency_confirm_modify_looking_for',
        isOpen: true,
        action: () => {
          handleClickComplete();
        },
      }),
    );
  };

  /**
   * @description 구합니다 매물 거래 완료 처리
   * @returns {void}
   */
  const handleClickComplete = async () => {
    try {
      if (clickRef.current) return;
      clickRef.current = true;

      const res = await CAPIS.putCompleteSeek(`/seeks/${detailId}/complete`);

      if (res.status_del === 'complete') {
        moveMain(3, '');
      }
    } catch (err) {
      console.log(err);
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 배열로된 주소 값에 콤마 추가
   */
  const parseAddrInfo = addr_info?.map((item: any) => item.addr)?.join(',') || [];

  /**
   * @description 문자열 parsing
   */
  const paresText = () => {
    let text = '';

    if (price_info.length > 0) {
      // 매매, 전세
      if (deal_type === 'S' || deal_type === 'L') {
        if (price_info.length > 0) {
          text = `${numberWithCommas(price_info?.[0]?.min || 0)} ~ ${numberWithCommas(price_info?.[0]?.max || 0)} 만원`;
        }
      }

      // 월세, 단기 임대
      if (deal_type === 'M' || deal_type === 'T') {
        if (price_info.length > 0) {
          // 월세
          const monthVal = price_info.filter((item: any) => item.name === '월세');
          const rentalVal = price_info.filter((item: any) => item.name === '보증금');
          if (monthVal.length > 0) {
            text += `${numberWithCommas(monthVal?.[0]?.min || 0)} ~ ${numberWithCommas(monthVal?.[0]?.max || 0)} ${
              rentalVal.length === 0 ? '만원' : '/ '
            }`;
          }

          if (rentalVal.length > 0) {
            text += `${numberWithCommas(rentalVal?.[0]?.min || 0)} ~ ${numberWithCommas(
              rentalVal?.[0]?.max || 0,
            )} 만원`;
          }
        }
      }
    }

    return text;
  };

  /**
   * @description 코멘트 sheet
   * @param {any} post 게시글 정보
   * @returns {void}
   */
  const handleComment = async (post: IMixedKeyValue) => {
    dispatch(
      openModal({
        modalType: 'show_comment_list',
        data: {
          ...post,
          isType: 'looking-for',
        },
        isOpen: true,
      }),
    );
  };

  /**
   * @description 뒤로가기
   * @returns {void}
   */
  const handleClickBack = () => {
    const isBrokerInfo = pathName === '/feed/brokerinfo' ? true : false;

    // group id가 있는 경우, 푸시를 통해서 들어온 경우
    if (queryGroupId !== '' && queryPush === 'true') {
      navigate(`/mygroup/group/${queryGroupId}`, {
        replace: true,
      });
    }

    // group id가 없지만 푸시를 통해서 들어왔을 경우
    if (queryGroupId === '' && queryPush === 'true') {
      moveMain(3, '');
    }

    // group id도 없고 푸시를 통해서 들어온 경우가 아닌 경우 기존 처럼 동작
    if (queryGroupId === '' && queryPush === 'false') {
      if (import.meta.env.MODE === 'localdev') {
        if (hasReturnNav) {
          navigate(hasReturnNav);
        } else {
          navigate(backUrl as number);
        }
      } else {
        if (document.referrer.includes(import.meta.env.VITE_WEB_DOMAIN) && window.history.state.idx === 0) {
          navigate(backUrl as number);
        } else {
          if (!window.history.state || window.history.state.idx === 0 || isBrokerInfo) {
            onClose();
          } else {
            if (hasReturnNav) {
              navigate(hasReturnNav);
            } else {
              navigate(backUrl as number);
            }
          }
        }
      }
    }
    dispatch(ActionUser.resetHistoryPath());
  };

  return (
    <>
      <header>
        <div className={CommonStyles['left']}>
          <button onClick={handleClickBack}>
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['title']}>{my_seek ? '내 구합니다 상세' : '구합니다 상세'}</div>
        <div className={CommonStyles['right']}>
          {/* <button className={CommonStyles.close}>
            <i className={CommonStyles['icon-x-bk']}></i>
          </button> */}
        </div>
      </header>
      <div id="detail" className={cn(CommonStyles['container'], my_seek && CommonStyles['hasBottom'])}>
        <div id="share-detail" className={cn(CommonStyles['content'], CommonStyles['share-dtl'])}>
          <div className={cn(CommonStyles['sale-card'], CommonStyles['bg-blue'])}>
            <div className={CommonStyles['type']} style={{ display: 'flex', alignItems: 'center' }}>
              {type_info && (
                <>
                  {type_info.map((item: { name: string; code: 'string' }) => {
                    return <p>[{item.name}]&nbsp;</p>;
                  })}
                </>
              )}
            </div>
            {types && <div className={CommonStyles['type']}>[{types}]</div>}
            <div className={CommonStyles['price']}>
              <b className={CommonStyles['co-bl']}>{deal_type_name}</b>
              {parseAddr !== '' && parseAddr}
            </div>
            <div className={CommonStyles['function-div']}>
              <button onClick={() => handleComment(data)}>
                <i className={CommonStyles['icon-comment']}></i>
                {comments_count! >= 100 ? `+99` : comments_count}
              </button>
              <button
                className={cn(CommonStyles['bookmark'], bookmark_id !== null && CommonStyles['on'])}
                onClick={() => handleClickAddBookmark(detailId, bookmark_id)}
              >
                <i className={CommonStyles['icon-bookmark']}></i>
              </button>
            </div>
          </div>
          <div
            id="tab-menu"
            className={cn(CommonStyles['tab-menu'], CommonStyles['round-tab'], !my_seek && CommonStyles['flex1'])}
            style={{
              transition: 'background-color 0.3s ease',
            }}
          >
            <button
              className={cn(CommonStyles['tab'], tab === 'info' && CommonStyles['on'])}
              onClick={() => handleClickTab('info')}
            >
              상세정보
            </button>
            {!my_seek && (
              <button
                className={cn(CommonStyles['tab'], tab === 'company-info' && CommonStyles['on'])}
                onClick={() => handleClickTab('company-info')}
              >
                중개업소 정보
              </button>
            )}
            {my_seek && (
              <button
                className={cn(CommonStyles['tab'], tab === 'my-info' && CommonStyles['on'])}
                onClick={() => handleClickTab('my-info')}
              >
                비공개정보
              </button>
            )}
            {my_seek && (
              <button
                className={cn(CommonStyles['tab'], tab === 'detail-share' && CommonStyles['on'])}
                onClick={() => handleClickTab('detail-share')}
              >
                공유정보
              </button>
            )}
          </div>
          <div className={CommonStyles['section-div']}>
            <section className={cn('tab-menu-section', CommonStyles['tab-menu-section'])} id="info" ref={infoRef}>
              <p className={CommonStyles['tit']}>매물 상세정보</p>
              <table className={CommonStyles['gry-table']}>
                <colgroup>
                  <col width="115" />
                  <col width="" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>상세조건</th>
                    <td>{content}</td>
                  </tr>
                  <tr>
                    <th>소재지</th>
                    <td>{parseAddrInfo}</td>
                  </tr>
                  <tr>
                    <th>거래/매물종류</th>
                    <td>
                      <div className={CommonStyles['divide-box']}>
                        {deal_type_name && <p>{deal_type_name}</p>}
                        {type_info && (
                          <>
                            {type_info.map((item: { name: string; code: 'string' }) => {
                              return <p>{item.name}</p>;
                            })}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>매물가격</th>
                    <td>{price_info?.length > 0 && paresText()}</td>
                  </tr>
                  <tr>
                    <th>면적</th>
                    <td>
                      {+area_min || ''}㎡ - {+area_max || ''}㎡ ({convertArea(+area_min || 0, 'square')}평 -{' '}
                      {convertArea(+area_max || 0, 'square')}평)
                    </td>
                  </tr>
                  <tr>
                    <th>최초 등록일</th>
                    <td>{created_at && moment(created_at).format('YYYY.MM.DD')}</td>
                  </tr>
                </tbody>
              </table>
            </section>

            {my_seek && (
              <section className={cn('tab-menu-section', CommonStyles['tab-menu-section'])} id="my-info" ref={ref}>
                <p className={CommonStyles['tit']}>비공개 정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>고객명</th>
                      <td>{customer_name}</td>
                    </tr>
                    <tr>
                      <th>고객연락처</th>
                      <td>{phone_num}</td>
                    </tr>
                    <tr>
                      <th>중개사 메모</th>
                      <td>{hidden_memo}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {my_seek && (
              <section
                className={cn('tab-menu-section', CommonStyles['tab-menu-section'])}
                id="detail-share"
                ref={shareRef}
              >
                <p className={CommonStyles['tit']}>공유정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>공개여부</th>
                      <td>{status_share === 'Y' ? '공유함' : '공유하지 않음'}</td>
                    </tr>
                    <tr>
                      <th>공유범위</th>
                      <td>
                        내공유
                        <br />
                        {shares.map((item: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              {item?.['share_type'] &&
                                `-${item?.name ? item.name : '전체공유'}(${moment(item.created_at).format(
                                  'YYYY.MM.DD',
                                )})`}
                              <br />
                              {index + 1 === shares.length && <br />}
                            </React.Fragment>
                          );
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>연락처노출</th>
                      <td>
                        <p>
                          {tel_display === 'A'
                            ? '모두노출'
                            : tel_display === 'R'
                            ? '전화번호'
                            : tel_display === 'C'
                            ? '휴대폰번호'
                            : ''}
                        </p>

                        <p>
                          {tel_display === 'A'
                            ? '(중개업소 전화번호 + 휴대폰번호)'
                            : tel_display === 'R'
                            ? '(중개업소 전화번호)'
                            : tel_display === 'C'
                            ? '(휴대폰번호)'
                            : ''}{' '}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
            {!my_seek && (
              <section className={cn('tab-menu-section', CommonStyles['tab-menu-section'])} id="company-info">
                <p className={CommonStyles['tit']}>중개업소 정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="115" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>중개업소명</th>
                      <td>{company_name}</td>
                    </tr>
                    <tr>
                      <th>대표자명</th>
                      <td>{ceo}</td>
                    </tr>
                    <tr>
                      <th>담당자명</th>
                      <td>{editor_name}</td>
                    </tr>
                    <tr>
                      <th>연락처</th>
                      <td>{contact}</td>
                    </tr>
                    <tr>
                      <th>중개정보 소재지</th>
                      <td>{address_detail}</td>
                    </tr>
                    <tr>
                      <th>개설등록번호</th>
                      <td>{corp_number}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )}
          </div>
        </div>
      </div>
      {my_seek && (
        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <button className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])} onClick={movePageModify}>
            수정
          </button>
          <button
            className={cn(
              CommonStyles['btn'],
              CommonStyles['lg'],
              status_deal === 'complete' && CommonStyles['disabled'],
            )}
            disabled={status_deal === 'complete'}
            onClick={handleClickCompleteOpenModal}
          >
            거래완료
          </button>
        </div>
      )}
      {toastState.isOpen && <Toast />}
    </>
  );
};

export default FindShareDetail;
