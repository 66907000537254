import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import network from 'utils/network';
import { IisCompany } from '<broker>';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';

const BrokerCompany = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //넘어온값 확인
  const location = useLocation();
  const { state } = location;
  const [param, setParam] = useState(state);
  useEffect(() => {
    if (!param?.broker_agree) {
      navigate('/broker');
    }
  }, []);

  //조회버튼 클릭 가능여부
  const [isSearch, setIsSearch] = useState<boolean>(false);

  //auto focus
  const inputRegNumberRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    setTimeout(() => {
      if (inputRegNumberRef.current) {
        inputRegNumberRef.current.focus();
      }
    }, 100);
  }, []);

  //사업자등록번호
  const [registration_number, setRegistrationNumber] = useState<string>('');
  //사업자등록번호 onchange
  const handleRegisterNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const regex = /^[0-9\b -]{0,12}$/;
    if (regex.test(e.target.value)) {
      setRegistrationNumber(e.target.value);
    }
  };
  //사업자등록번호 번호형식
  useEffect(() => {
    if (registration_number == '') {
      inputRegNumberRef.current?.focus();
    }

    if (registration_number && registration_number.length === 10) {
      const _registration_number = registration_number.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
      setRegistrationNumber(_registration_number);
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }

    if (registration_number && registration_number.length === 12) {
      const _registration_number = registration_number.replace(/-/g, '').replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
      setRegistrationNumber(_registration_number);
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [registration_number]);

  //조회시작
  const handleSearchConfirm = async () => {
    if (isSearch === true) {
      dispatch(onLoad());
      setIsSearch(false);
      const req: IisCompany = {
        registration_number: registration_number.replace(/-/g, ''),
      };
      await network()
        .broker()
        .getIsCompany(req)
        .then((res) => {
          dispatch(offLoad());
          //조회불가
          if (res.data.isLive == 'empty') {
            dispatch(
              ActionModal.openModal({
                modalType: 'broker_alert',
                isOpen: true,
                data: { txt: '사업자 등록번호가 올바르지 않아요.\n정보를 다시 확인해 주세요.' },
              }),
            );
            setIsSearch(true);
          }
          //휴폐업
          if (res.data.isLive == 'close' || res.data.isLive == 'down') {
            dispatch(
              ActionModal.openModal({
                modalType: 'broker_alert',
                isOpen: true,
                data: { txt: '휴·폐업자로 확인되어 인증이 불가해요.\n정보를 다시 확인해 주세요.' },
              }),
            );
          }
          //정상
          if (res.data.isLive == 'company') {
            navigate('/broker/jurirno', {
              state: {
                broker_agree: param?.broker_agree,
                registration_number: registration_number,
              },
            });
          }
        })
        //조회 에러
        .catch((err) => {
          dispatch(offLoad());
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: err.response.data.message },
            }),
          );
          setIsSearch(true);
        });
    }
  };
  return (
    <>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={CommonStyles['content-div']}>
            <p className={CommonStyles['page-tit']}>사업자 정보를 조회해주세요.</p>
            <div className={cn(CommonStyles['input-area'], CommonStyles['clear-has'])}>
              <input
                type="tel"
                ref={inputRegNumberRef}
                name="registration_number"
                id="registration_number"
                value={registration_number || ''}
                placeholder="사업자등록번호"
                onChange={(e) => handleRegisterNumber(e)}
              />
              {registration_number.length > 0 && (
                <button className={CommonStyles.delete} onClick={() => setRegistrationNumber('')}></button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles['fixed'])}>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], !isSearch ? CommonStyles.disabled : '')}
          onClick={handleSearchConfirm}
        >
          조회
        </button>
      </div>
    </>
  );
};

export default BrokerCompany;
