import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as APIS from 'api/group';
import { IMixedKeyValue } from '<modal>';
import cn from 'classnames';
import Button from 'components/common/Button/Button';
import CommonStyles from 'styles/common.module.css';
import useScroll from 'hook/useScroll';
import GradeBadge from 'components/common/Badge/GradeBadge';
import styled from 'styled-components';
import ListLoader from 'components/common/Loader/ListLoader';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { offLoad, onLoad } from 'redux/slices/loading';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';

const PAGE_SIZE = 10;

/**
 * @description 모임 - 회원 승인
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const MemberApprovalList = () => {
  const params = useParams();
  const { id = '' } = params;
  const dispatch = useAppDispatch();
  const lastPage = useRef<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<IMixedKeyValue>({
    page: 1,
    key: new Date().getTime(),
  });
  const [list, setList] = useState<IMixedKeyValue[]>([]);
  const { setToastMessage } = useToast();
  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);

  // 새로고침 중복 방지
  const isRefreshRef = useRef<boolean>(false);

  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );
    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  useEffect(() => {
    const f = async () => {
      fetchList();
    };
    if (id) {
      if (isRefreshRef.current) return;
      f();
    }
  }, [page]);

  /**
   * @description 모임 승인 목록 조회 api
   * @param {boolean} isRefresh 새로고침 여부(새로고침을 통해서 api를 조회한건지 분기 처리)
   * @returns {Promise<void>}
   */
  const fetchList = async (isRefresh = false) => {
    try {
      setLoading(true);
      const res = await APIS.getApprovalList(
        `/groups/${id}/users/apply?page=${isRefresh ? 1 : page.page}&page_size=${PAGE_SIZE}`,
      );
      const { data = [], links } = res;
      const { next = null } = links;

      if (next === null) {
        lastPage.current = true;
      }

      if (data.length > 0) {
        setList((prev) => [...prev, ...data]);
      } else {
        setList([]);
      }
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 무한 스크롤 observe
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);

    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && list.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  /**
   * @description 모임 가입 승인
   * @param {number} userId 모임 유저 id
   * @returns {Promise<void>}
   */
  const handleClickApproval = async (userId: number) => {
    if (!userId) return;
    try {
      dispatch(onLoad());
      const res = await APIS.putApprovalJoinGroup(`/groups/${id}/users/${userId}/apply`);

      if (res.data.message === 'OK') {
        setList([]);
        setPage({
          page: 1,
          key: new Date().getTime(),
        });
        setToastMessage({
          duration: 2000,
          content: '승인이 완료되었습니다.',
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      dispatch(offLoad());
    }
  };

  /**
   * @description 모임 가입 승인 반려
   * @param {number} userId 모임 유저 id
   * @returns {Promise<void>}
   */
  const handleClickReject = async (userId: number) => {
    if (!userId) return;
    try {
      dispatch(onLoad());
      const res = await APIS.putRejectGroup(`/groups/${id}/users/${userId}/reject`);

      if (res.data.message === 'OK') {
        setList([]);
        setPage({
          page: 1,
          key: new Date().getTime(),
        });
        setToastMessage({
          duration: 2000,
          content: '승인이 반려되었습니다.',
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      dispatch(offLoad());
    }
  };

  /**
   * @description 새로고침
   * @returns {Promise<void>}
   */
  const refreshFn = () => {
    setList([]);
    setPage({
      page: 1,
      key: new Date().getTime(),
    });

    fetchList(true);
    lastPage.current = false;
    isRefreshRef.current = true;
  };

  return (
    <div className={cn(CommonStyles.content, CommonStyles['group-member'])} style={{ height: '100%' }}>
      <div className={CommonStyles['member-list']}>
        {list.map((item, index: number) => {
          return (
            <React.Fragment key={index}>
              <div className={CommonStyles.list}>
                <div className={CommonStyles['member-information']}>
                  <div className={CommonStyles['clickable']}>
                    <div className={CommonStyles.profile}>
                      <div
                        className={CommonStyles['img-div']}
                        style={{ backgroundImage: `url(${item?.profile_photo_path})` }}
                      ></div>
                      {item?.grade && item?.verify?.broker_code && <GradeBadge grade={item.grade} />}
                    </div>
                    <div className={CommonStyles['mem-info']}>
                      <p className={CommonStyles.name}>{item?.nickname || ''}</p>
                      <div className={CommonStyles['divide-box']}>
                        {item?.company?.name && (
                          <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {item.company.name}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {item?.pivot?.['join_answer'] && (
                  <div className={cn(CommonStyles['gray-box'], CommonStyles['ml0'])}>
                    {item?.pivot?.['join_answer']}
                  </div>
                )}
                <div className={CommonStyles['btn-wrap']}>
                  <Button type="line" onClick={() => handleClickApproval(item.id)}>
                    승인
                  </Button>
                  <Button type="line" onClick={() => handleClickReject(item.id)}>
                    반려
                  </Button>
                </div>
                {index + 1 === list.length && !lastPage.current && <Target ref={ref} />}
              </div>
              {index + 1 === list.length && loading && <ListLoader />}
            </React.Fragment>
          );
        })}
      </div>
      {isOpen && <Toast />}
      {list.length === 0 && (
        <div
          className={CommonStyles['no-data']}
          style={{ height: '100%', textAlign: 'center', whiteSpace: 'pre-line', flexDirection: 'column' }}
        >
          <span>가입 승인을 요청한</span>
          <span>회원이 없습니다.</span>
        </div>
      )}
    </div>
  );
};

export default MemberApprovalList;

const Target = styled.div`
  height: 1px;
`;
