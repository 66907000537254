import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { PROFILE_NICKNAME_MESSAGE, BUCKET_NAME } from 'configs/configs';
import * as APIS from 'api/group';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import { offLoad, onLoad } from 'redux/slices/loading';
import { IMixedKeyValue } from '<modal>';
import AWS from 'aws-sdk';

// s3 설정
AWS.config.update({
  region: import.meta.env.VITE_S3_BUCKET_REGION,
  accessKeyId: import.meta.env.VITE_S3_BUCKET_ACCESSKEY,
  secretAccessKey: import.meta.env.VITE_S3_BUCKET_SECRETKEY,
});

const s3 = new AWS.S3();

interface PhotosInterface {
  url?: string;
  org_name?: string;
}

/**
 * @description 모임 설정 - 모임 이미지 설정
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const ModifyGroupImage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { group = {} } = useAppSelector((state: RootState) => state.group);
  const user = useAppSelector((state: RootState) => state.user);
  const { id = '' } = params;
  const [value, setValue] = useState<string>('');
  const [fileInfo, setFileInfo] = useState<IMixedKeyValue>({});
  const [imgPath, setImgPath] = useState<string>('');
  const [temp, setTemp] = useState<{ name: string; filePath: string | null | undefined }>({
    name: '',
    filePath: '',
  });
  const { name = '', photos = [] } = group as { name?: string; photos?: PhotosInterface[] };

  useEffect(() => {
    if (group) {
      if (name) {
        setValue(name);
      }

      if (photos?.length > 0) {
        if (photos[0]?.url) {
          setImgPath(photos[0].url);
        }
      }

      if (name && photos?.length > 0) {
        setTemp({ name: name, filePath: photos[0]?.url });
      }
    }
  }, [group]);

  /**
   * @description 모임 default 이미지 선택 sheet open
   * @returns {Promise<void>}
   */
  const handleClickImage = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'fullSheet_default_image_select',
        action: (data: IMixedKeyValue) => {
          if (data?.cdn_url) {
            setImgPath(data.cdn_url);
            setFileInfo({ ...data });
          }
        },
      }),
    );
  };

  /**
   * @description 모임 custom 이미지 선택 api
   * @returns {Promise<void>}
   */
  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      dispatch(onLoad());
      const { files } = e.target;

      if (files?.length != 1) return;

      const file = files[0];
      const fileSizeLimit = 20 * 1024 * 1024;

      if (file.size > fileSizeLimit) {
        dispatch(openModal({ modalType: 'alert_image_limit' }));
        return;
      }

      const orgName = file.name;
      const currTime = new Date().getTime();
      const ext = file.name.split('.').pop();
      const filename = `${user?.me?.id}_${currTime}.${ext}`;
      const newFile = new File([file], filename, {
        type: file.type,
        lastModified: file.lastModified,
      });

      // 이미지 s3 upload
      const params: AWS.S3.PutObjectRequest = {
        Bucket: `${BUCKET_NAME}/temp/photo`,
        Key: newFile.name,
        Body: newFile,
        ACL: 'public-read', // Set ACL (Access Control List) for the uploaded image
        ContentType: newFile.type,
      };
      const upload = new AWS.S3.ManagedUpload({
        params,
        service: s3,
      });

      const result = await upload.promise();
      const relativePath = new URL(result.Location).pathname;
      const photoPath = result.Location.replace(relativePath ?? '', `/${result.Key}`);
      // 화면 섬네일 표시 이미지
      const thumbPath = `${import.meta.env.VITE_CDN_URL}${relativePath}`;
      setFileInfo({
        cdn_url: photoPath,
        org_name: orgName,
      });
      setImgPath(thumbPath);
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  /**
   * @description 모임 이미지 선택 옵션 sheet open
   * @returns {Promise<void>}
   */
  const handleClickImagePopup = () => {
    dispatch(
      openModal({
        modalType: 'groupBotSheet_image_select',
        action: () => handleClickImage(),
      }),
    );
  };

  /**
   * @description 모임정보 수정하기 api
   * @returns {Promise<void>}
   */
  const modifyGroupImageAndName = useCallback(async () => {
    if (id === '') return;
    try {
      dispatch(onLoad());
      const payload = {
        name: value,
        photos: [
          Object.keys(fileInfo)?.length > 0
            ? {
                file_type: 'photo',
                org_name: fileInfo.org_name,
                url: fileInfo.cdn_url,
              }
            : {
                file_type: 'photo',
                org_name: photos[0].org_name,
                url: photos[0].url,
              },
        ],
      };
      if (id) {
        await APIS.putModifyImageAndName(`groups/${id}/info`, payload);
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      handleClickBack();
      dispatch(offLoad());
    }
  }, [value, imgPath, group]);

  /**
   * @description 뒤로가기
   * @returns {Promise<void>}
   */
  const handleClickBack = () => {
    navigate(-1);
  };

  /**
   * @description 버튼 enable disable
   */
  const isBtn = (imgPath !== temp.filePath || value !== temp.name) && value?.length > 0 ? false : true;

  return (
    <div className={cn(CommonStyles.container, CommonStyles.hasBottom)}>
      <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
        <div className={CommonStyles['setting-form']}>
          <Button className={CommonStyles['img-box']} onClick={handleClickImagePopup}>
            {imgPath && <img src={imgPath} />}
            {!imgPath && <span className={CommonStyles['guide']}>모임 대표 사진 설정</span>}
          </Button>
          <input
            type="file"
            id="group_image_select"
            key={Date.now()} // Change the key when the component re-renders
            style={{ display: 'none' }}
            accept="image/*"
            onChange={handleImageChange}
          />
          <Input
            maxLength={50}
            placeholder="모임이름을 입력해주세요."
            value={value}
            clear={true}
            handleClickClear={() => setValue('')}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <Button size="large" type="gry" onClick={handleClickBack}>
            취소
          </Button>
          {/*확인 버튼 validation check */}
          <Button size="large" disabled={isBtn} onClick={modifyGroupImageAndName}>
            확인
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModifyGroupImage;
