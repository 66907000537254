import { useAppDispatch, useAppSelector } from 'redux/store';
// import useOutsideClick from 'components/common/hooks/useOutSideClick';
import type { RootState } from 'redux/store';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Button from 'components/common/Button/Button';
import { closeModal } from 'redux/slices/modal';
import { GROUP_DEFAULT_COVERS } from 'configs/configs';
import { IMixedKeyValue } from '<modal>';

/**
 * @description full sheet
 * @Class
 * @category Components
 * @subcategory common sheet
 * @component
 * @returns {JSX.Element}
 */
const FullSheet = () => {
  const dispatch = useAppDispatch();
  const { modalType, isOpen, data, action } = useAppSelector((state: RootState) => state.modal);
  const groupData = useAppSelector((state: RootState) => state.group);

  const { id = '' } = groupData.group;

  const handleClickBack = () => {
    dispatch(
      closeModal({
        isOpen: false,
        modalType: modalType,
      }),
    );
  };

  const handleClickImage = (data: IMixedKeyValue) => {
    if (id) {
      if (data) {
        action?.(data);
      }
      handleClickBack();
    } else {
      handleClickBack();
    }
  };

  const images = GROUP_DEFAULT_COVERS;
  return (
    <div style={{ height: '100vh' }}>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={() => handleClickBack()}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>

        <div className={Styles.title} style={{ fontWeight: 700, color: '#111' }}>
          모임 이름 및 사진 설정
        </div>
        <div className={cn(Styles.right, CommonStyles.right)}>
          <button className={cn(Styles['text-btn'], Styles.disabled, CommonStyles.disabled)}></button>
        </div>
      </header>
      <div className={CommonStyles.container}>
        <div id="fullsheet_select_photo" className={cn(CommonStyles.content, CommonStyles['select-photo'])}>
          {images.map((item: IMixedKeyValue) => {
            return (
              <Button key={item.org_name} className="none" onClick={() => handleClickImage(item)}>
                <img src={item.cdn_url} alt={item.org_name} />
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FullSheet;
