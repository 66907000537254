import CommonStyles from 'styles/common.module.css';

/**
 * @description 리스트 loader
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @returns {JSX.Element}
 */
const ListLoader = () => {
  return (
    <div className={CommonStyles['loading-dots']}>
      <span className={CommonStyles.dot}></span>
      <span className={CommonStyles.dot}></span>
      <span className={CommonStyles.dot}></span>
    </div>
  );
};

export default ListLoader;
