import { ReactNode, CSSProperties, MutableRefObject } from 'react';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';

type ButtonType = 'default' | 'org' | 'gry' | 'line' | 'red' | 'bk' | 'txt';
type ButtonRadiusType = 'normal' | 'round';
type ButtonSizeType = 'small' | 'middle' | 'large';
type ButtonIconType = 'search' | 'trash';

interface ButtonInterface {
  ref?: MutableRefObject<HTMLButtonElement | null>;
  children?: ReactNode; // 버튼명
  className?: string; // 버튼 className
  mergeClass?: boolean; // 기존 class와 새로 className 머지 여부
  onClick?: (...args: any[]) => void; // 버튼 클릭
  name?: string; // 버튼 name
  id?: string; // 버튼 id
  type?: ButtonType; // 버튼 type ?
  shape?: ButtonRadiusType; // 버튼 shape 속성 ? normal | round
  size?: ButtonSizeType; // 버튼 size 속성 ? small | middle | large
  icon?: ButtonIconType; // 버튼 아이콘
  style?: CSSProperties; // 버튼 style
  disabled?: boolean; // 버튼 disabled 유무 ? true | false
}

const ButtonTypeClassName = {
  default: '',
  org: 'org-txt',
  gry: 'gry',
  line: 'line',
  red: 'red',
  bk: 'bk',
  txt: 'txt',
};

const ButtonSizeClassName = {
  small: 'sm',
  middle: '',
  large: 'lg',
};

const ButtonShapeClassName = {
  normal: '',
  round: 'round',
};

/**
 * @description button
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {any} props.ref button ref
 * @param {ReactNode} props.children 버튼 children
 * @param {string} props.className 버튼 className
 * @param {boolean} props.mergeClass 기존 class와 새로 className 머지 여부
 * @param {void} props.onClick 버튼 클릭
 * @param {string} props.name 버튼 name
 * @param {string} props.id 버튼 id
 * @param {ButtonType} props.type 버튼 type ?
 * @param {ButtonRadiusType} props.shape 버튼 shape 속성 ? normal | round
 * @param {ButtonSizeType} props.size 버튼 size 속성 ? small | middle | large
 * @param {ButtonIconType} props.icon 버튼 아이콘
 * @param {CSSProperties} props.style 버튼 style
 * @param {boolean} props.disabled 버튼 disabled 유무 ? true | false
 * @returns {JSX.Element}
 */
const Button: React.FC<ButtonInterface> = ({
  children = '',
  style = {},
  id = '',
  name = '',
  type = 'default',
  shape = 'normal',
  size = 'small',
  icon = '',
  onClick,
  disabled = false,
  className = '',
  mergeClass = false,
}) => {
  // 버튼 props에 따른 버튼 style 설정을 해주는 함수
  const getStyle = () => {
    let payload = `${CommonStyles.btn}`;
    if (size !== 'middle') {
      payload += ` ${CommonStyles?.[ButtonSizeClassName?.[size]] || ''}`;
    }
    if (type !== 'default') {
      payload += ` ${CommonStyles?.[ButtonTypeClassName?.[type]] || ''}`;
    }
    if (shape !== 'normal') {
      payload += ` ${CommonStyles?.[ButtonShapeClassName?.[shape]] || ''}`;
    }

    if (disabled === true) {
      payload += ` ${CommonStyles?.disabled || ''}`;
    }

    return payload;
  };

  const getIconStyle = () => {
    let payload = `${CommonStyles[`icon-${icon}-wh`]}`;
    if (type === 'org') {
      payload = `${CommonStyles[`icon-${icon}-org`]}`;
    }
    if (type === 'bk' || type === 'line') {
      payload = `${CommonStyles[`icon-${icon}-bk`]}`;
    }
    if (type === 'txt') {
      payload = `${CommonStyles[`icon-${icon}-red`]}`;
    }
    return payload;
  };

  let defaultProps = {};

  if (id) {
    defaultProps = {
      ...defaultProps,
      id: id,
    };
  }

  if (name) {
    defaultProps = {
      ...defaultProps,
      name: name,
    };
  }

  return (
    <button
      className={
        className ? (mergeClass ? cn(getStyle(), className) : className === 'none' ? '' : className) : cn(getStyle())
      }
      style={style}
      {...defaultProps}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <i className={cn(getIconStyle())} />}
      {children}
    </button>
  );
};

export default Button;
