import { REType } from '../configs/configs';

/**
 * @description 코드를 받아서 법정동 코드 형태로 변환
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} code
 */
export const convertAreaFullCode = (code: string) => {
  return code.padEnd(10, '0');
};

/**
 * @description 법정동 코드에서 시 코드 추출
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} areaCd
 */
export const getSidoCode = (areaCd: string) => {
  return areaCd.substring(0, 2);
};

/**
 * @description 법정동 코드에서 시군구 코드 추출
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} areaCd
 */
export const getSigunguCode = (areaCd: string) => {
  return areaCd.substring(2, 5);
};

/**
 * @description 법정동 코드에서 읍면동 코드 추출
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} areaCd
 */
export const getDongCode = (areaCd: string) => {
  return areaCd.substring(5, 8);
};

/**
 * @description 법정동 코드에서 리 코드 추출
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} areaCd
 */
export const getLiCode = (areaCd: string) => {
  return areaCd.substring(8, 10);
};

/**
 * @description 리 코드를 가지고 있는지 확인, '00' 으로 끝나지 않으면 리 코드를 가지고 있는 것
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} areaCd
 */
export const hasLi = (areaCd: string | undefined): boolean => {
  return getLiCode(areaCd || '') !== '00';
};

/**
 * @description 공동주택인지 확인
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} offeringsGbn
 */
export const isComplex = (offeringsGbn: string | undefined) => {
  if (!offeringsGbn) return false;
  return ['AP', 'JS', 'OP', 'AB', 'JB', 'OB', 'RC'].includes(offeringsGbn);
};

/**
 * @description main_cd
 * @Class
 * @category utils helpers
 * @subcategory helpers
 * @param {string} offeringsGbn
 */
export const getMainCd = (offeringsGbn: string | undefined): string => {
  const _mainCD = REType.map((item) => item.offer_types)
    .flat()
    .find((item) => item.sub_cd === offeringsGbn)?.main_cd;

  return _mainCD || '';
};
