import { useDispatch, useSelector } from 'react-redux';
import { Toast, openToast, closeToast } from '../redux/slices/toast';
import { RootState, useAppSelector } from 'redux/store';
import { useEffect, useRef } from 'react';

const getRandomID = () => String(new Date().getTime());

/**
 * @description toast 메시지 관련 hook
 * @Class
 * @category hooks
 * @subcategory common
 * @todo toast 팝업은 총 3가지 일반 toast message, close button 이 존재하는 toast, 특정 애션 버튼이 존재하는 toast message
 * @todo message, message_left, message_right # default center
 * @todo close
 */
const useToast = () => {
  const dispatch = useDispatch();
  // 기존에 걸려있는 setTimeout 를 ref에 저장(기존에 intervalId가 있다면 초기화 목적)
  const timerId = useRef<any>(null);
  const toastMessage = useSelector((state: RootState) => state.toast);
  const { isOpen } = useAppSelector((state: RootState) => state.modal);

  // duration 시간이 지나면 기존 toast 삭제 or 기존 토스트 메세지 삭제
  const removeToast = () => {
    dispatch(closeToast());
  };

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    };
  }, []);

  // 팝업 오픈시 기존 토스트 메세지 삭제
  useEffect(() => {
    if (!isOpen) return;

    if (toastMessage.isOpen) removeToast();
  }, [isOpen]);

  // toast set 함수
  const setToastMessage = (toast: Toast) => {
    // toastMessage가 존재 할경우
    if (toastMessage.toastId) {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
      // 기존 toast Message 제거
      dispatch(closeToast());
      // 새 toast Message 생성
      setTimeout(() => {
        dispatch(
          openToast({
            ...toastMessage,
            toastId: getRandomID(),
            isOpen: true,
            content: toast.content,
            type: toast?.type || 'message',
          }),
        );
      }, 300);
      // 일정 시간이 지나면 toast Message 제거
      if (toast.type === 'message' || toast.type === 'message_left' || toast.type === 'message_right') {
        setTimeout(() => removeToast(), Number(1000 + (toast.duration! + 1000 ?? 1000)));
      }
    } else {
      // 새 toast Message 생성
      dispatch(
        openToast({
          ...toastMessage,
          toastId: getRandomID(),
          isOpen: true,
          content: toast.content,
          type: toast?.type || 'message',
        }),
      );

      if (toast.type === 'message' || toast.type === 'message_left' || toast.type === 'message_right') {
        setTimeout(() => removeToast(), Number(1000 + (toast.duration! + 1000 ?? 1000)));
      }
    }
  };

  return { setToastMessage };
};

export default useToast;
