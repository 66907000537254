import { PURGE } from 'redux-persist';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { IUser, IMyinfo } from '../../@types';
import { RootState } from 'redux/store';
import { updatePost } from './board';

interface IIUser {
  token?: string;
  is_possible_report?: boolean | null;
  currentUser: IUser;
  me: IMyinfo;
  brokerVerified: boolean | null;
  profile_edit: {
    save: boolean;
    nickname: string;
    photo: string;
  };
  historyPath: {
    to: string;
    from: string;
  };
}

const initialState: IIUser = {
  token: '',
  is_possible_report: null,
  currentUser: {
    is_possible_report: null,
    createdAt: null,
    updatedAt: null,
    broker_verified_at: null,
    area_info: null,
    birthday: null,
    broker_public_at: null,
    company: null,
    company_id: null,
    email: null,
    gender: null,
    grade: null,
    id: null,
    loginType: null,
    login_id: null,
    n_member_code: null,
    name: null,
    nickname: null,
    phone: null,
    phone_public_at: null,
    point_level: null,
    profile_photo_path: null,
    verify: null,
  },
  me: {
    area_info: null,
    birthday: null,
    borker_verified: false,
    broker_public_at: null,
    company: null,
    company_id: null,
    email: null,
    gender: null,
    grade: null,
    group_post_comment_count: null,
    group_post_count: null,
    id: null,
    loginType: null,
    login_id: null,
    n_member_code: null,
    name: null,
    nickname: null,
    offering_complete_count: null,
    offering_count: null,
    phone: null,
    phone_public_at: null,
    post_comment_count: null,
    post_count: null,
    total_comment_count: null,
    total_like_count: null,
    total_post_count: null,
    verify: null,
    verify_temp: null,
    point_level: null,
    profile_photo_path: null,
    totalBroker: null,
  },
  brokerVerified: null,
  profile_edit: {
    save: false,
    nickname: '',
    photo: '',
  },
  historyPath: {
    to: '',
    from: '',
  },
};

/**
 * @description user
 * @Class
 * @category redux
 * @subcategory slice
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPossibleReport: (state, action) => {
      state.is_possible_report = action.payload.is_possible_report;
    },
    setUser: (state, action) => {
      state.token = action.payload.token;
      state.is_possible_report = action.payload.is_possible_report;
      state.currentUser = action.payload.user;
      state.currentUser.is_possible_report = action.payload.is_possible_report;
    },
    setMe: (state, action) => {
      state.me = action.payload;
    },
    setVerified: (state, action) => {
      state.brokerVerified = action.payload;
    },
    setBrokerPublic: (state, action) => {
      state.me.broker_public_at = action.payload;
    },
    // 프로필 수정사항 저장
    setProfileChange: (state, action) => {
      // 프로필 저장 여부
      state.profile_edit.save = action.payload.save;
      // 프로필 닉네임
      state.profile_edit.nickname = action.payload.nickname;
      // 프로필 이미지
      state.profile_edit.photo = action.payload.photo;
    },
    setHistoryPath: (state, action) => {
      state.historyPath = action.payload;
    },
    resetHistoryPath(state) {
      state.historyPath = initialState.historyPath;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePost, (state, action) => {
      state.is_possible_report = action.payload.is_possible_report;
    });
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  setPossibleReport,
  setUser,
  setMe,
  setVerified,
  setBrokerPublic,
  setProfileChange,
  setHistoryPath,
  resetHistoryPath,
} = userSlice.actions;

const currentUserSelector = (state: RootState) => state.user.currentUser;
const meSelector = (state: RootState) => state.user.me;
const brokerVerifiedSelector = (state: RootState) => state.user.brokerVerified;

export const userSelector = createSelector(
  currentUserSelector,
  meSelector,
  brokerVerifiedSelector,
  (currentUser, me, brokerVerified) => ({
    currentUser,
    me,
    brokerVerified,
  }),
);
export default userSlice;
