import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FeedReqType,
  FeedResType,
  FeedErrorType,
  FeedDeleteCommentReqType,
  FeedDeleteCommentResType,
  FeedDeleteBookmarkReqType,
  FeedDeleteBookmarkResType,
  FeedBookmarkReqType,
  FeedBookmarkResType,
  FeedGroupBookmarkResType,
  FeedDeletePostReqType,
  FeedDeletePostResType,
  FeedPostActionReqType,
  FeedPostActionResType,
  FeedCommentActionReqType,
  FeedCommentActionResType,
  FeedPostReqType,
  FeedPostResType,
  FeedOfferSearchReqType,
  FeedOfferSearchResType,
  FeedSeekSearchReqType,
  FeedSeekSearchResType,
} from '../@types/feed';
import network from 'utils/network';
import { API_URL } from 'configs/configs';
import axios from 'axios';

export { resetFeed } from 'redux/slices/feed';

const getFeedError = (error: unknown) => {
  const result: FeedErrorType = {
    status: null,
    message: '',
  };

  if (axios.isAxiosError(error)) {
    result.status = error.response?.status ? error.response?.status : null;
    result.message = error.response?.data?.message ? error.response?.data?.message : '';
  } else if (error instanceof Error) {
    result.message = error.message;
  } else {
    result.message = '오류가 발생하였습니다.';
  }

  return result;
};

/**
 * @description 내가 쓴 모임 게시글 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedReqType} req
 * @returns {Promise<void>}
 */
export const getMyPostsForGroup = createAsyncThunk<FeedResType, FeedReqType>(
  '/feed/group/posts',
  async (req: FeedReqType, thunkAPI) => {
    try {
      return await network().group().getMyPosts(req);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 내가 쓴 모임 게시판 게시글 삭제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedDeletePostReqType} req
 * @returns {Promise<void>}
 */
export const deleteMyPostForGroup = createAsyncThunk<FeedDeletePostResType, FeedDeletePostReqType>(
  '/feed/group/posts/delete',
  async (req: FeedDeletePostReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.id) throw new Error('요청 데이터가 올바르지 않습니다.');

      return await network().groupPost().deleteGroupPost(req.group_id, req.id);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 내가 쓴 전체 게시판 게시글 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedReqType} req
 * @returns {Promise<void>}
 */
export const getMyPostsForFree = createAsyncThunk<FeedResType, FeedReqType>(
  '/feed/free/posts',
  async (req: FeedReqType, thunkAPI) => {
    try {
      return await network().post().getMyPosts(req);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 내가 쓴 전체 게시판 게시글 삭제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedDeletePostReqType} req
 * @returns {Promise<void>}
 */
export const deleteMyPostForFree = createAsyncThunk<FeedDeletePostResType, FeedDeletePostReqType>(
  '/feed/free/posts/delete',
  async (req: FeedDeletePostReqType, thunkAPI) => {
    try {
      if (!req.id) throw new Error('요청 데이터가 올바르지 않습니다.');

      const path = API_URL.DELETE_BOARD_POST.replace(/:postId/, String(req.id));
      return await network().post().deleteBoardPost(path);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 내가 쓴 모임 댓글 목록 갸져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedReqType} req
 * @returns {Promise<void>}
 */
export const getMyCommentsForGroup = createAsyncThunk<FeedResType, FeedReqType>(
  '/feed/group/comments',
  async (req: FeedReqType, thunkAPI) => {
    try {
      return await network().group().getMyComments(req);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description  모임 댓글 삭제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedDeleteCommentReqType} req
 * @returns {Promise<void>}
 */
export const deleteCommentForGroup = createAsyncThunk<FeedDeleteCommentResType, FeedDeleteCommentReqType>(
  '/feed/group/comments/delete',
  async (req: FeedDeleteCommentReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.group_post_id || !req.id) throw new Error('요청 데이터가 올바르지 않습니다.');

      return await network().group().deleteGroupComment(req.group_id, req.group_post_id, req.id);
    } catch (error: unknown) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 내가 쓴 전체 게시판 댓글 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedReqType} req
 * @returns {Promise<void>}
 */
export const getMyCommentsForFree = createAsyncThunk<FeedResType, FeedReqType>(
  '/feed/free/comments',
  async (req: FeedReqType, thunkAPI) => {
    try {
      return await network().post().getMyComments(req);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전체 게시판 댓글 삭제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedDeleteCommentReqType} req
 * @returns {Promise<void>}
 */
export const deleteCommentForFree = createAsyncThunk<FeedDeleteCommentResType, FeedDeleteCommentReqType>(
  '/feed/free/comments/delete',
  async (req: FeedDeleteCommentReqType, thunkAPI) => {
    try {
      return await network().post().deleteComment(req.id);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 모임 게시물 북마크 목록 갸져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedReqType} req
 * @returns {Promise<void>}
 */
export const getBookmarksForGroup = createAsyncThunk<FeedResType, FeedReqType>(
  '/feed/group/bookmarks',
  async (req: FeedReqType, thunkAPI) => {
    try {
      return await network().bookmark().getBookmarksForGroup(req);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 모임 게시물 북마크 등록
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedBookmarkReqType} req
 * @returns {Promise<void>}
 */
export const createBookmarkForGroup = createAsyncThunk<FeedGroupBookmarkResType, FeedBookmarkReqType>(
  '/feed/group/bookmarks/create',
  async (req: FeedBookmarkReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.post_id) throw new Error('요청 데이터가 올바르지 않습니다.');

      return await network().groupPost().putGroupPostBookmark(req.group_id, req.post_id);
    } catch (error: unknown) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 모임 게시물 북마크 해제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedDeleteBookmarkReqType} req
 * @returns {Promise<void>}
 */
export const deleteBookmarkForGroup = createAsyncThunk<FeedDeleteBookmarkResType, FeedDeleteBookmarkReqType>(
  '/feed/group/bookmarks/delete',
  async (req: FeedDeleteBookmarkReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.group_post_id || !req.id) throw new Error('요청 데이터가 올바르지 않습니다.');

      return await network().bookmark().deleteBookmarkForGroup(req.group_id, req.group_post_id, req.id);
    } catch (error: unknown) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전체 게시판 북마크 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedReqType} req
 * @returns {Promise<void>}
 */
export const getBookmarksForFree = createAsyncThunk<FeedResType, FeedReqType>(
  '/feed/free/bookmarks',
  async (req: FeedReqType, thunkAPI) => {
    try {
      return await network().bookmark().getBookmarksForFree(req);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전체 게시판 북마크 등록
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedBookmarkReqType} req
 * @returns {Promise<void>}
 */
export const createBookmarkForFree = createAsyncThunk<FeedBookmarkResType, FeedBookmarkReqType>(
  '/feed/free/bookmarks/create',
  async (req: FeedBookmarkReqType, thunkAPI) => {
    try {
      const path = API_URL.PUT_BOOKMARK_FREE_POST.replace(/:postId/, String(req.post_id));
      return await network().post().postBookmarkBoard(path);
    } catch (error: unknown) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전체 게시판 북마크 해제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedDeleteBookmarkReqType} req
 * @returns {Promise<void>}
 */
export const deleteBookmarkForFree = createAsyncThunk<FeedDeleteBookmarkResType, FeedDeleteBookmarkReqType>(
  '/feed/free/bookmarks/delete',
  async (req: FeedDeleteBookmarkReqType, thunkAPI) => {
    try {
      return await network().bookmark().deleteBookmarkForFree(req.id);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 모임 게시판 게시물 공감처리(좋아요, 싫어요)
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedPostActionReqType} req
 * @returns {Promise<void>}
 */
export const actionPostForGroup = createAsyncThunk<FeedPostActionResType, FeedPostActionReqType>(
  '/feed/group/post/action',
  async (req: FeedPostActionReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.id) throw new Error('요청 데이터가 올바르지 않습니다.');

      return await network().groupPost().postGroupPostAction(req.group_id, req.id, String(req.action));
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전체 게시판 게시물 공감처리(좋아요, 싫어요)
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedPostActionReqType} req
 * @returns {Promise<void>}
 */
export const actionPostForFree = createAsyncThunk<FeedPostActionResType, FeedPostActionReqType>(
  '/feed/free/post/action',
  async (req: FeedPostActionReqType, thunkAPI) => {
    try {
      return await network().post().postPostAction(req.id, Number(req.action));
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 모임 게시판 댓글 공감처리(좋아요, 싫어요)
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedCommentActionReqType} req
 * @returns {Promise<void>}
 */
export const actionCommentForGroup = createAsyncThunk<FeedCommentActionResType, FeedCommentActionReqType>(
  '/feed/group/comment/action',
  async (req: FeedCommentActionReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.group_post_id || !req.id || !req.action) {
        throw new Error('요청 데이터가 올바르지 않습니다');
      }

      return await network().groupPost().postGroupCommentAction(req.group_id, req.group_post_id, req.id, req.action);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전체 게시판 댓글 공감처리(좋아요, 싫어요)
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedCommentActionReqType} req
 * @returns {Promise<void>}
 */
export const actionCommentForFree = createAsyncThunk<FeedCommentActionResType, FeedCommentActionReqType>(
  '/feed/free/comment/action',
  async (req: FeedCommentActionReqType, thunkAPI) => {
    try {
      const path = API_URL.POST_COMMENT_ACTION.replace(/:commentId/, String(req.id));
      return await network()
        .post()
        .postBoardPostCommentLike(path, JSON.stringify({ kind: req.action }));
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 모임 게시판 게시글 상세정보 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedPostReqType} req
 * @returns {Promise<void>}
 */
export const getPostInfoForGroup = createAsyncThunk<FeedPostResType, FeedPostReqType>(
  '/feed/group/post/info',
  async (req: FeedPostReqType, thunkAPI) => {
    try {
      if (!req.group_id || !req.id) throw new Error('요청 데이터가 올바르지 않습니다');

      const replacements: { [key: string]: string } = {
        ':groupId': String(req.group_id),
        ':groupPostId': String(req.id),
      };

      const path = API_URL.GET_GROUP_POST_DETAIL.replace(/:groupId|:groupPostId/g, (matched) => replacements[matched]);
      return await network().groupPost().getGroupPostDetail(path);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 전게 게시판 게시글 상세정보 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @param {FeedPostReqType} req
 * @returns {Promise<void>}
 */
export const getPostInfoForFree = createAsyncThunk<FeedPostResType, FeedPostReqType>(
  '/feed/free/post/info',
  async (req: FeedPostReqType, thunkAPI) => {
    try {
      const path = API_URL.GET_POST_DETAIL.replace(/:postId/, String(req.id));
      return await network().post().getPostDetail(path);
    } catch (error) {
      //console.log('api error:', error);

      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 공유매물 북마크 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @returns {Promise<void>}
 */
export const getBookmarksForOfferings = createAsyncThunk<FeedOfferSearchResType, FeedOfferSearchReqType>(
  '/feed/offers/bookmarks',
  async (req: FeedOfferSearchReqType, thunkAPI) => {
    try {
      return await network().bookmark().getBookmarksForOfferings(req);
    } catch (error) {
      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 구합니다 북마크 목록 가져오기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @returns {Promise<void>}
 */
export const getBookmarksForSeeks = createAsyncThunk<FeedSeekSearchResType, FeedSeekSearchReqType>(
  '/feed/seeks/bookmarks',
  async (req: FeedSeekSearchReqType, thunkAPI) => {
    try {
      return await network().bookmark().getBookmarksForSeeks(req);
    } catch (error) {
      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 공유매물 북마크 해제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @returns {Promise<void>}
 */
export const deleteBookmarkForOffering = createAsyncThunk<number[], number>(
  '/feed/offers/delete',
  async (id: number, thunkAPI) => {
    try {
      return await network().bookmark().deleteBookmarkForOffering(id);
    } catch (error) {
      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);

/**
 * @description 구합니다 북마크 해제하기
 * @Class
 * @category Api
 * @subcategory 내피드
 * @returns {Promise<void>}
 */
export const deleteBookmarkForSeek = createAsyncThunk<number[], number>(
  '/feed/seeks/delete',
  async (id: number, thunkAPI) => {
    try {
      return await network().bookmark().deleteBookmarkForSeek(id);
    } catch (error) {
      const result: FeedErrorType = getFeedError(error);
      return thunkAPI.rejectWithValue(result);
    }
  },
);
