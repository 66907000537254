import { useNavigate } from 'react-router-dom';
import { FeedItemType } from '../../@types/feed';
import { useAppDispatch, useAppSelector, RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import useToast from 'hook/useToast';
import cn from 'classnames';
// configs
import * as Config from 'configs/configs';
// utils
import { IMixedKeyValue } from '<modal>';

import CommonStyles from 'styles/common.module.css';

import { onView } from 'utils';

// 이미지 타입
interface AttachedPhotoType {
  id: number;
  org_name: string;
  url: string;
  [key: string]: number | string | null;
}

// 첨부파일 타입
interface AttachedFileType {
  id: number;
  org_name: string;
  url: string;
  [key: string]: number | string | null;
}

/**
 * 게시글 첨부 영역 (공유 출처, 이미지, 첨부파일, 지도정보, 이모티콘)
 * Props:
 *   - post: 게시글 object
 */

/**
 * @description 게시글 첨부 영역 (공유 출처, 이미지, 첨부파일, 지도정보, 이모티콘) F.C
 * @Class
 * @category Pages
 * @subcategory 전체게시판
 * @component
 * @returns {JSX.Element}
 */
const PostAttach = ({
  post,
  attachType = 'post',
  isDetail = false,
  main = false,
}: {
  post: FeedItemType;
  attachType?: string | undefined;
  isDetail?: boolean;
  main?: boolean | undefined; // 메인 view 여부, true: 메인, false: 서브
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);
  const { setToastMessage } = useToast();
  // console.log('post==>', post);

  /**
   * @description 모임 페이지로 이동
   * @returns {void}
   */
  const moveGroupPage = () => {
    if (isDetail) return;

    const { post_share = [] } = post;
    let deleted = false;

    if (post_share.length > 0) {
      const {
        group_id = '',
        share_post_id = '',
        share_group_post_id,
        share_group_post_deleted_at,
        share_post_deleted_at,
      } = post_share?.[0] || [];

      if (group_id) {
        if (share_group_post_deleted_at) {
          deleted = true;
        } else {
          const link = `/group-postdetail/${group_id}/${share_group_post_id}`;

          if (main) {
            onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
          } else {
            navigate(link);
          }
        }
      } else if (share_post_id) {
        if (share_post_deleted_at) {
          deleted = true;
        } else {
          const link = `/postdetail/${share_post_id}`;

          if (main) {
            navigate(link);
          } else {
            navigate(link);
          }
        }
      }

      if (deleted) {
        setToastMessage({
          duration: 2000,
          content: Config.TOAST_MESSAGE_TYPE.ORIGIN_POST_DELETE,
          type: 'message',
        });
      }
    }
  };

  /**
   * @description 파일 저장 sheet open
   * @returns {void}
   */
  const handleClickSaveFile = (file: IMixedKeyValue) => {
    if (!file) return;
    dispatch(
      ActionModal.openModal({
        isOpen: true,
        modalType: 'bottomSheet_save_file',
        data: file,
      }),
    );
  };

  // console.log('post in PostAttach', post);

  return (
    <div className={CommonStyles['attached-div']}>
      {/* 공유 출처 */}
      {post?.post_share?.length > 0 && (
        <div className={CommonStyles['share-box']} onClick={() => moveGroupPage()}>
          <div className={CommonStyles.summary}>
            <div className={cn(CommonStyles['tit'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
              {post.post_share[0].share_title ?? post.post_share[0].share_content}
            </div>
            {post.post_share[0].group_name ? (
              <div className={cn(CommonStyles['tit'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                [모임] {post.post_share[0].group_name}
              </div>
            ) : (
              <div className={CommonStyles.txt}>[전체게시판] {post.post_share[0].share_post_cate}</div>
            )}
          </div>
        </div>
      )}

      {/* 이미지 */}
      {post?.photos?.length !== 0 && (
        <div className={CommonStyles['img-box']}>
          {post?.photos?.map((photo: AttachedPhotoType, index: number) => (
            <div key={index} className={CommonStyles['img-item']}>
              <img
                src={photo.url}
                alt={photo.org_name}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'show_image_full',
                      data: { url: photo.url, filename: photo.org_name },
                      isOpen: true,
                    }),
                  )
                }
              />
            </div>
          ))}
        </div>
      )}

      {/* 첨부파일 */}
      {attachType !== 'detail' && post?.files?.length !== 0 && (
        <div className={CommonStyles['file-box']}>
          <i className={CommonStyles['icon-file']}></i>
          <div className={CommonStyles.summary}>
            <div className={CommonStyles.tit}>첨부파일 총{post.files.length}개</div>
            <div className={CommonStyles.txt}>
              {post.files.map((file: AttachedFileType, index: number) => (
                <span
                  key={index}
                  className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}
                  onClick={() => {
                    const link = post?.group_id
                      ? `/group-postdetail/${post.group_id}/${post.id}`
                      : `/postdetail/${post.id}`;

                    if (main) {
                      if (import.meta.env.MODE === 'localdev') {
                        navigate(link);
                      } else {
                        onView(`${import.meta.env.VITE_APP_DOMAIN}${link}`);
                      }
                    } else {
                      navigate(link);
                    }
                  }}
                >
                  {file.org_name}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}

      {attachType === 'detail' && post?.files.length > 0 && (
        <>
          {post?.files?.map((file: IMixedKeyValue) => (
            <button key={file.url} className={CommonStyles['file-box']}>
              <i className={CommonStyles['icon-file']} />
              <div className={CommonStyles.summary}>
                <div className={CommonStyles.txt}>
                  <div style={{ width: '100%' }} onClick={() => handleClickSaveFile(file)}>
                    <span className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                      {file.org_name.length <= 40 ? `${file.org_name}` : `${file.org_name?.slice(0, 37)}...`}
                    </span>
                  </div>
                </div>
              </div>
            </button>
          ))}
        </>
      )}

      {/* 지도 */}
      {Config.SERVICE_USE_SETTING.WRITING_MAP === 'enabled' && post?.latitude && post?.longitude && (
        <div className={CommonStyles['map-box']}>
          <div className={CommonStyles.map}></div>
          <div className={CommonStyles.summary}>
            <div className={cn(CommonStyles['tit'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
              코엑스
            </div>
            <div className={cn(CommonStyles['txt'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
              서울특별시 강남구 역삼동 영동대로 513
            </div>
          </div>
        </div>
      )}

      {/* 이모티콘 */}
      {post?.emoticon && (
        <div className={CommonStyles['emoji-box']}>
          <img src={`/images/${post?.emoticon}`} alt="이모지 아이콘" />
        </div>
      )}
    </div>
  );
};

export default PostAttach;
