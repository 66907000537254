import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * @description 페이지 이동시 상단으로 이동 관련 hook
 * @Class
 * @category hooks
 * @subcategory common
 */
const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const el = document.getElementById('container');
    el?.scrollTo(0, 0);
  }, [pathname]);
};

export default useScrollToTop;
