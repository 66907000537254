import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cn from 'classnames';
import useIsMounted from 'hook/useIsMounted';
import useQueryState from 'hook/useQueryState';
import network from 'utils/network';
import { history } from 'router/index';
import { moveMain, onClose } from 'utils';
// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionModal from 'redux/slices/modal';

// components
import CoagencyRegStep from './CoagencyRegStep';
import CoagencyRegAddress from './CoagencyRegAddress';
import { initAddr } from './initState';

// configs
import * as Config from 'configs/configs';
// styles
import Styles from 'styles/header.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

/**
 * @description 공유매물등록 공통 step switching component
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @returns {JSX.Element}
 */
const CoagencyRegister = (): JSX.Element => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const totalStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.totalStep);
  const currStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.currStep);
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const mode = useAppSelector((state: RootState) => state.coagency.shareRegStep.mode);
  const currAddrStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.currAddrStep);
  const REMainType = useAppSelector(
    (state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_main_gbn,
  );
  const RESubType = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_gbn);

  const queryParams = new URLSearchParams(location.search);
  const [regStep, setRegStep] = useQueryState<string | null>(queryParams.get('currStep'), 'currStep');
  const [reTypeList, setReTypeList] = useState([]);
  const [aptGroup, setAptGroup] = useState(false);

  const [stateAddr, setStateAddr] = useState(initAddr);

  useEffect(() => {
    if (isMounted() && REMainType) {
      const _aptgroup = REMainType === 'A' || REMainType === 'B';
      setAptGroup(_aptgroup);
    }
  }, [REMainType]);

  /**
   * @description hardware backey history 동작 step4 이상
   */
  useEffect(() => {
    const listenBackEvent = () => {
      if (currStep > 1) {
        navigate(`/coagency-register?currAddrStep=${currAddrStep}&currStep=${currStep}`);
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_registration_cancel',
            action: () => handleClose(),
            isOpen: true,
          }),
        );
      }
    };

    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return () => unlisten();
  }, [currStep, currAddrStep, history]);

  /**
   * @description currStep을 query string시키고, currStep이 3일 경우 currAddrStep삭제
   */
  useEffect(() => {
    setRegStep(String(currStep));
  }, [currStep]);

  /**
   * @description 매물 타입 및 리스트 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (currStep === 1) {
        dispatch(ActionCoagency.initStep('share'));
        await getRETypeList();
      }
    };
    getData();
    return () => abortController.abort();
  }, [currStep]);

  /**
   * @description 매물 리스트 가져오기 api call
   * @returns {Promise<void>}
   */
  const getRETypeList = useCallback(async (): Promise<void> => {
    try {
      const res = await network().coagency().getRETypeList(Config.API_URL.GET_RE_TYPE_ALL);
      const { data, status } = res;
      if (status === 200) {
        setReTypeList(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  /**
   * @description render step function
   * @returns {JSX.Element}
   */
  const renderStep = (): JSX.Element => {
    switch (currStep) {
      case 1:
        return <CoagencyRegStep title="매물 종류를 선택하세요." reTypeList={reTypeList} />;
      case 2:
        return (
          <CoagencyRegAddress title="매물의 소재지를 작성해주세요." stateAddr={stateAddr} setStateAddr={setStateAddr} />
        );
      case 3:
        return <CoagencyRegAddress title="단지를 선택해주세요." stateAddr={stateAddr} setStateAddr={setStateAddr} />;
      case 4:
        return <CoagencyRegStep title="동/호를 작성해주세요." stateAddr={stateAddr} setStateAddr={setStateAddr} />;
      case 5:
        return REMainType === 'B' ? (
          <CoagencyRegStep title="분양권 구분을 선택해주세요." />
        ) : (
          <CoagencyRegStep title="상세주소를 입력해주세요." stateAddr={stateAddr} setStateAddr={setStateAddr} />
        );
      case 6:
        return <CoagencyRegStep title="거래 유형을 선택해주세요." />;
      case 7:
        return <CoagencyRegStep title="" />;
      case 8:
        return <CoagencyRegStep title="입주 가능일을 선택해주세요." />;
      case 9:
        return <CoagencyRegStep title="입력하신 정보가 맞는지 확인해주세요!" />;
      case 10:
        return <CoagencyRegStep title={`거의 다왔어요!\n매물을 공유할 곳을 선택해주세요.`} />;
      case 11:
        return <CoagencyRegStep title="none" />;
      default:
        return <CoagencyRegStep title="매물 종류를 선택하세요." reTypeList={reTypeList} />;
    }
  };

  /**
   * @description register의 handle back key 와 CoagencyRegAddress component history동작 일치시킬것
   * @returns {void}
   */
  const handleBackKey = (): void => {
    // 1. 현재step이 1일경우 back key동작시 선택화면으로 이동함.
    if (currStep === 1) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: 1,
            dir: 'left',
          },
        }),
      );
      return navigate(`/coagency-register-select`);
    }
    if (currStep === 2 && currAddrStep === 1) {
      setStateAddr(initAddr);
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 1,
            dir: 'left',
          },
        }),
      );
    }
    // 2. 현재step이 2일경우 back key동작시
    if (currStep === 2 && currAddrStep !== 1) {
      if (currAddrStep === 4) {
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              currAddrStep: currAddrStep - 1,
              dir: 'left',
              selectedAddr: {
                ...shareRegStep.selectedAddr,
                eupMyeonDongAddr: aptGroup ? {} : shareRegStep.selectedAddr.eupMyeonDongAddr,
                liAddr: {},
              },
            },
          }),
        );
      } else if (currAddrStep === 3) {
        console.warn('currAddrStep3', currAddrStep);
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              currAddrStep: currAddrStep - 1,
              dir: 'left',
              selectedAddr: {
                ...shareRegStep.selectedAddr,
                eupMyeonDongAddr: {},
              },
            },
          }),
        );
      } else if (currAddrStep === 2) {
        console.warn('currAddrStep2', currAddrStep);
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              currAddrStep: currAddrStep - 1,
              dir: 'left',
              selectedAddr: {
                ...shareRegStep.selectedAddr,
                guGunAddr: {},
              },
            },
          }),
        );
      } else {
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              currAddrStep: currAddrStep - 1,
              dir: 'left',
            },
          }),
        );
      }
      return navigate(`/coagency-register?currAddrStep=${currAddrStep - 1}&currStep=${currStep}`);
    }

    //3. 공동주택(A, B)이고 3step(단지선택) 에서 back key이동시  단지 초기화
    if (aptGroup && currStep === 3 && currAddrStep === 4) {
      console.log('currStep3, currAddrStep4', currStep, currAddrStep);
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 1,
            currAddrStep: currAddrStep - 1, // currAddrStep은 step3으로 이동
            dir: 'left',
            selectedAddr: {
              ...shareRegStep.selectedAddr,
              complexAddr: {},
            },
          },
        }),
      );
      return navigate(`/coagency-register?currAddrStep=${currAddrStep - 1}&currStep=${currStep - 1}`);
    }

    //4. 공동주택의 경우 동/호 선택단계인 step4에서 back key동작시 단지선택화면으로 이동
    if (currStep === 4 && currAddrStep === 4) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 1, // step3 단지선택된 화면
            currAddrStep: currAddrStep, // currAddrStep은 유지
            dir: 'left',
            selectedAddr: {
              ...shareRegStep.selectedAddr,
              dongAddr: {},
            },
          },
        }),
      );
      return navigate(`/coagency-register?currAddrStep=${currAddrStep}&currStep=${currStep - 1}`);
    }

    //5.1 재개발일 경우에는 step5(상세주소)에서 step을 3(단지선택)으로 이동
    if (REMainType === 'A' && RESubType === 'RB' && currStep === 5) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 2, // step3 단지선택
            dir: 'left',
          },
        }),
      );
      return navigate(`/coagency-register?currStep=${currStep - 2}`);
    }

    //5.2 비공동주택일 경우에는, step5(상세주소)에서 step을 2(읍면동)으로 이동
    if (!aptGroup && currStep === 5) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 3, // step2 리, 또는 읍면동선택
            currAddrStep: !shareRegStep.selectedAddr.liAddr.cd ? 3 : 4,
            dir: 'left',
            selectedAddr: {
              ...shareRegStep.selectedAddr,
            },
          },
        }),
      );
      return navigate(`/coagency-register?currAddrStep=4&currStep=${currStep - 3}`);
    }

    //6. 재개발이 아닐경우에는 step6(거래유형)에서 step을 4(동/호 작성)으로 이동
    if (REMainType === 'A' && RESubType !== 'RB' && currStep === 6) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 2, // step을 4(동/호 작성)
            dir: 'left',
          },
        }),
      );
      return navigate(`/coagency-register?currStep=${currStep - 2}`);
    } else {
      // 기본 back key이동시 step - 1
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: currStep - 1,
            dir: 'left',
          },
        }),
      );
      return navigate(`/coagency-register?currStep=${currStep - 1}`);
    }
  };

  const handleCloseConfirm = () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_writing_close',
        action: () => handleClose(),
        isOpen: true,
      }),
    );
  };

  const handleClose = () => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/newpost-type');
    } else {
      moveMain(2, '');
      onClose();
    }
  };

  console.group();
  console.log('reTypeList==>', reTypeList);
  console.log('aptGroup==>', aptGroup);
  console.groupEnd();

  const isIphone = /iPhone/i.test(navigator.userAgent);

  return (
    <>
      <header style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '50px', zIndex: 9999 }}>
        <div className={cn(Styles.left, ResetStyles.left)} style={mode === 'edit' ? { visibility: 'hidden' } : {}}>
          <button type="button" onClick={handleBackKey}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>
        <div className={Styles.title}>공유매물 등록</div>
        <div className={cn(Styles.right, CommonStyles.right)}>
          <button onClick={handleCloseConfirm}>
            <i className={CommonStyles['icon-x-bk']} />
          </button>
        </div>
      </header>
      <div
        className={cn(CommonStyles.container, currStep >= 7 && CommonStyles.hasBottom)}
        // className={cn(CommonStyles.container)}
        style={
          currStep === 2 || currStep === 3
            ? { overflow: 'hidden', position: 'sticky' }
            : { overflow: 'auto', position: 'relative', height: 'calc(100vh - 3.325rem)' }
        }
      >
        <div
          id="content"
          className={cn(
            CommonStyles.content,
            CommonStyles['share-write'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
          style={currStep >= 9 ? { paddingTop: '50px', marginBottom: 100 } : { paddingTop: '50px' }}
        >
          {currStep <= 8 && (
            <div className={CommonStyles['progress-wrap']} id="progressBar">
              <div className={CommonStyles['progress-bar']}>
                <p className={CommonStyles['present-state']} style={{ width: `${(currStep / totalStep) * 100}%` }}>
                  <span className={CommonStyles.a11y_hidden}>매물등록 단계</span>
                </p>
              </div>
            </div>
          )}
          {renderStep()}
        </div>
        <div id="make-scrollable" />
      </div>
    </>
  );
};

export default CoagencyRegister;
