import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeSubView, openSubView } from 'redux/slices/global';
import { RootState, useAppSelector } from 'redux/store';
import { onClose, onView } from 'utils';

/**
 * @description subview 체크 관련 hook
 * @Class
 * @category hooks
 * @subcategory common
 */
const useSubView = () => {
  const dispatch = useDispatch();
  const subview = useAppSelector((state: RootState) => state.global);
  const navigate = useNavigate();
  const openView = (url: string) => {
    if (!url) return;
    if (subview.isSubView) {
      navigate(url);
    } else {
      onView(url);
      dispatch(openSubView(url));
    }
  };

  const closeView = () => {
    onClose();
    dispatch(closeSubView());
  };

  return { openView, closeView };
};

export default useSubView;
