/* eslint-disable no-prototype-builtins */
import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';
import { ICommentItem, ICommentsLists } from '<comments>';
import _ from 'lodash';
import { IMixedKeyValue } from '<modal>';
import moment from 'moment';

interface ICommentsSlice extends ICommentsLists {
  boardKind: number | null;
  postId: number | null;
  groupId: number | null;
  commentLists: ICommentItem[];
  comments_list_count?: number;
  newCommentId?: number | null;
  newChildrenId?: number | null;
  isFetch: boolean;
  isAddComment: boolean;
  parentId: number | null;
  isAddChildren: boolean;
  isLikeTarget: number | null;
  isCommentLike: boolean;
  deletedId: number | null;
  deletedChildId: number | null;
  updatedId: number | null;
  isRemoveComment: boolean;
}

export const initialComment: ICommentsSlice = {
  boardKind: null,
  postId: null,
  groupId: null,
  commentLists: [],
  comments_list_count: 0,
  newCommentId: null,
  newChildrenId: null,
  isFetch: false,
  isAddComment: false,
  parentId: null,
  isAddChildren: false,
  isLikeTarget: null,
  isCommentLike: false,
  deletedId: null,
  deletedChildId: null,
  updatedId: null,
  isRemoveComment: false,
};

/**
 * @description 공통 댓글 comment
 * @Class
 * @category redux
 * @subcategory slice
 */
const commentsSlice = createSlice({
  name: 'comments',
  initialState: initialComment,
  reducers: {
    loadComments(state, action) {
      const comments = action.payload.comments;
      console.log('comments in slice', comments);
      const totalCount =
        comments?.length +
        comments.map((item: IMixedKeyValue) => (item.children.length ? item.children : [])).flat()?.length;
      console.log('comments totalCount==>', totalCount);
      state.boardKind = action.payload.boardKind;
      state.postId = action.payload.postId;
      state.groupId = action.payload.groupId;
      state.commentLists = comments;
      state.comments_list_count = totalCount ?? 0;
      state.parentId = action.payload.parentId;
      state.isFetch = action.payload.isFetch;
      state.isAddComment = action.payload.isAddComment;
      state.isAddChildren = action.payload.isAddChildren;
      state.isLikeTarget = action.payload.isLikeTarget;
      state.isCommentLike = action.payload.isCommentLike;
      state.isRemoveComment = action.payload.isRemoveComment;
      state.deletedId = null;
      state.deletedChildId = null;
      state.updatedId = null;
    },
    addComment(state, action) {
      const _newComment = action.payload.comments;
      state.commentLists = state.commentLists?.concat(_newComment);
      state.newCommentId = action.payload.comments.id;
      state.isFetch = false;
      state.comments_list_count = _newComment?.comment_count;
      state.isAddComment = action.payload.isAddComment;
      state.deletedId = null;
      state.deletedChildId = null;
    },
    deleteComment(state, action) {
      const _deletedComment = action.payload.comments;
      const _deletedId = action.payload.deletedId;
      state.deletedId = _deletedId;
      state.isFetch = false;
      state.comments_list_count = _deletedComment.comment_count;
      state.commentLists = state.commentLists.filter((comment) => comment.id !== _deletedId);
      state.isRemoveComment = action.payload.isRemoveComment;
    },
    updateComment(state, action) {
      const _updatedComment = action.payload.comments;
      const _updatedId = action.payload.updatedId;
      const _targetIndex = state.commentLists.findIndex((comment) => comment.id === _updatedId);
      console.log('_targetIndex', _targetIndex);
      console.log('_updatedComment', _updatedComment);
      state.updatedId = _updatedId;
      state.isFetch = false;
      state.deletedId = null;
      state.deletedChildId = null;
      if (_updatedComment.content) {
        state.commentLists[_targetIndex].content = _updatedComment.content;
      }
      if (_updatedComment.emoticon) {
        state.commentLists[_targetIndex].emoticon = _updatedComment.emoticon;
      }
      if (_updatedComment.photos) {
        state.commentLists[_targetIndex].photos = _updatedComment.photos;
      }
      if (_updatedComment.photos) {
        state.commentLists[_targetIndex].updated_at = _updatedComment.updated_at;
      }
      if (_updatedComment.comment_report) {
        state.commentLists[_targetIndex].comment_report = _updatedComment.comment_report;
      }
      if (_updatedComment.is_possible_report) {
        state.commentLists[_targetIndex].is_possible_report = _updatedComment.is_possible_report;
      }
    },
    setCommentLike(state, action) {
      const _updatedLike = action.payload.commentsLike;
      const _isLikeTarget = action.payload.isLikeTarget;
      const _targetIndex = state.commentLists.findIndex((comment) => comment.id === _isLikeTarget);
      state.commentLists[_targetIndex].like_count = _updatedLike.like_count;
      state.commentLists[_targetIndex].dislike_count = _updatedLike.dislike_count;
      state.commentLists[_targetIndex].comment_action = _updatedLike.comment_action;
      state.isFetch = false;
      console.log('_targetIndex', _targetIndex);
    },
    addChildren(state, action) {
      const _newChildren = action.payload.comments;
      if (_newChildren.parent_id) {
        const commentWithChildIndex = state.commentLists.findIndex((comment) => comment.id === _newChildren.parent_id);
        const commentAllReplies = state.commentLists[commentWithChildIndex].children;
        const childToIndex = commentAllReplies?.findIndex((reply) => reply.id === _newChildren.parent_id);
        state.commentLists[commentWithChildIndex]?.children?.splice(childToIndex! + 1, 0, _newChildren);
        state.commentLists[commentWithChildIndex]?.children?.sort((a, b) => {
          return moment(a.created_at).diff(b.created_at);
        });
      }
      state.isFetch = false;
      state.parentId = action.payload.parentId;
      state.newChildrenId = _newChildren.id;
      state.comments_list_count = _newChildren.comment_count;
      state.isAddChildren = action.payload.isAddChildren;
      state.deletedId = null;
      state.deletedChildId = null;
    },
    deleteChildren(state, action) {
      console.log('action payload', action.payload);
      const _deletedChildren = action.payload.comments;
      const _deletedChildId = action.payload.deletedChildId;
      state.newCommentId = null;
      state.newChildrenId = null;
      state.parentId = action.payload.parentId;
      state.deletedChildId = _deletedChildId;
      state.isFetch = false;
      state.isAddComment = false;
      state.isAddChildren = false;
      state.comments_list_count = _deletedChildren.comment_count;
      state.isRemoveComment = action.payload.isRemoveComment;
      if (action.payload.parentId) {
        const commentWithChildIndex = state.commentLists.findIndex((comment) => comment.id === action.payload.parentId);
        const commentAllReplies = state.commentLists[commentWithChildIndex].children;
        state.commentLists[commentWithChildIndex].children = commentAllReplies?.filter(
          (child) => child.id !== _deletedChildId,
        );
      }
    },
    updateChildren(state, action) {
      const _updatedChildren = action.payload.comments;
      const _updatedId = action.payload.updatedId;
      const _parentId = action.payload.parentId;
      if (_parentId) {
        const commentWithChildIndex = state.commentLists.findIndex((comment) => comment.id === _parentId);
        const commentAllReplies = state.commentLists[commentWithChildIndex].children;
        const childToIndex = commentAllReplies?.findIndex((child) => child.id === _updatedId);
        const targetChild = commentAllReplies && commentAllReplies[childToIndex!];
        if (_updatedChildren.content) targetChild.content = _updatedChildren.content;
        if (_updatedChildren.emoticon) targetChild.emoticon = _updatedChildren.emoticon;
        if (_updatedChildren.photos) targetChild.photos = _updatedChildren.photos;
        if (_updatedChildren.updated_at) targetChild.updated_at = _updatedChildren.updated_at;
        if (_updatedChildren.comment_report) targetChild.comment_report = _updatedChildren.comment_report;
        if (_updatedChildren.is_possible_report) targetChild.is_possible_report = _updatedChildren.is_possible_report;
      }
      state.isFetch = false;
      state.deletedId = null;
      state.deletedChildId = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialComment);
  },
});

export const {
  loadComments,
  addComment,
  deleteComment,
  updateComment,
  setCommentLike,
  addChildren,
  deleteChildren,
  updateChildren,
} = commentsSlice.actions;
export default commentsSlice;
