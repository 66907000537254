import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * @description query string을 사용하는 custom state hook
 * @Class
 * @category hooks
 * @subcategory common
 * @param {function} initialState 초기값
 * @param {string} paramName parameter 이름
 * @param {function|undefined} serialize serialize query
 */
function useQueryState<S>(
  initialState: S | (() => S),
  paramName: string,
  serialize: ((val: S) => string) | undefined = undefined,
): [S, React.Dispatch<React.SetStateAction<S>>] {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const queryParams = useMemo(() => qs.parse(search), [search]);

  const [stateValue, setState] = useState<S>(initialState);

  useEffect(() => {
    const serializedValue = serialize ? serialize(stateValue) : stateValue !== null ? String(stateValue) : null;

    if (queryParams[paramName] !== serializedValue) {
      const updatedQueryParams = {
        ...queryParams,
      };

      if (serializedValue !== null && typeof serializedValue !== 'undefined') {
        updatedQueryParams[paramName] = serializedValue;
      } else {
        delete updatedQueryParams[paramName];
      }

      const newURL = qs.stringifyUrl({
        url: pathname,
        query: updatedQueryParams,
      });

      navigate(newURL, { replace: true });
    }
  }, [stateValue, navigate, paramName, pathname, queryParams, serialize]);

  return [stateValue, setState];
}

export default useQueryState;
