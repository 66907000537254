import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import cn from 'classnames';
import { useImageSize, getImageSize } from 'react-image-size';

import Styles from 'styles/modal.module.css';
import ResetStyles from 'styles/reset.module.css';

interface Props {
  type?: string;
  title?: string;
  txt?: string;
}

/**
 * @description image sheet
 * @Class
 * @category Components
 * @subcategory common sheet
 * @component
 * @returns {JSX.Element}
 */
const ImageFull = (_props: Props) => {
  // const { title, txt } = props;
  const dispatch = useAppDispatch();
  const { modalType, isOpen, data, isMultiple, multiModalType, multiData } = useAppSelector(
    (state: RootState) => state.modal,
  );
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  useEffect(() => {
    const f = async () => {
      await fetchImageSize();
    };
    f();
  }, []);

  const fetchImageSize = async () => {
    try {
      const dimensions = await getImageSize(data?.url);
      setIsVertical(dimensions.height > dimensions.width);
    } catch (error) {
      console.error(error);
    }
  };

  const handleModalClose = () => {
    setOpen!(false);

    if (isMultiple) {
      dispatch(ActionModal.closeMultiModal({ multiModalType: multiModalType, isMultiple: false }));
    } else {
      dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    }
  };

  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show, Styles['img-full-pop'], !open && ResetStyles['d-none'])}>
        <button className={Styles.close} onClick={handleModalClose} />
        <div className={Styles['img-wrap']}>
          {isMultiple ? (
            <>
              {multiData?.url && (
                <img
                  src={multiData.url}
                  alt={multiData?.filename || 'image full'}
                  className={Styles.img}
                  // style={isVertical ? { top: 76, position: 'absolute' } : { objectFit: 'cover' }}
                />
              )}
            </>
          ) : (
            <>
              {data?.url && (
                <img
                  src={data.url}
                  alt={data?.filename || 'image full'}
                  className={Styles.img}
                  // style={isVertical ? { top: 76, position: 'absolute' } : { objectFit: 'cover' }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageFull;
