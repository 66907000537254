import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Button from 'components/common/Button/Button';
import Icon from 'components/common/Icon/IconComponent';
import styled from 'styled-components';
import useScroll from 'hook/useScroll';
import useSubView from 'hook/useSubView';
import * as APIS from 'api/group';
import { moveMain, numberWithCommas, onClose } from 'utils';
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modal';
import * as ActionUser from 'redux/slices/user';
import { IMixedKeyValue } from '<modal>';
import PostAttach from 'pages/allboard/PostAttach';
import { setGroupInfo } from 'redux/slices/group';
import { RootState, useAppSelector } from 'redux/store';
import ListLoader from 'components/common/Loader/ListLoader';
import PostContent from 'pages/allboard/PostContent';
import { timeDisplay } from 'utils/common';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';
import Toast from 'components/common/Toast/Toast';
import { cloneDeep } from 'lodash';
import { motion } from 'framer-motion';
import { offLoad, onLoad } from 'redux/slices/loading';
import { openSubView } from 'redux/slices/global';
import moment from 'moment';
import useToast from 'hook/useToast';
import GroupShareList from './GroupShareList';
import GroupFindShareList from './GroupFindShareList';
import network from 'utils/network';
import { history } from 'router';
import session from 'redux-persist/lib/storage/session';

const PAGE_SIZE = 5;

/**
 * @description 모임 상세
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const Group = () => {
  // 중복 클릭 방지
  const clickRef = useRef<boolean>(false);
  // 새로고침 여부 판단
  const isRefreshRef = useRef<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const subViewControl = useSubView();

  const isSubView = useAppSelector((state: RootState) => state.global.isSubView);
  const { id: paramId = '', code = '' } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const queryId = queryParams.get('id');
  const queryCode = queryParams.get('code');
  const id = paramId === null || paramId === undefined || paramId === '' ? queryId : paramId;
  // 유저 state
  const userData = useAppSelector((state: RootState) => state.user);
  // 모임 state
  const groupData = useAppSelector((state: RootState) => state.group);
  // toast message state
  const toastState = useAppSelector((state: RootState) => state.toast);
  // modal state
  const modal = useAppSelector((state: RootState) => state.modal);
  const { setToastMessage } = useToast();

  // 0 : 게시글, 1 : 공유매물, 2 : 구합니다.
  const [tab, setTab] = useState<number>(0);

  // 모임정보
  const [info, setInfo] = useState<IMixedKeyValue>({
    name: '',
    imgSrc: '',
    usersCount: '',
    posts_count: '',
    join_condition: {
      gender: 'ALL', // 이 부분 All or F or M
      broker_type: 'ALL', // 이 부분 null안됨.  All or BROKER
    },
    join_question: '',
    join_question_use: false,
    open: {
      key: '',
      value: '',
    },
    type: 0,
  });

  // 가입 했을 때 리스트 조회 분기 처리
  const isJoinRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      (modal.modalType === 'success_bookmark_complete' ||
        modal.modalType === 'success_bookmark_remove' ||
        modal.modalType === 'success_delete' ||
        modal.modalType === 'success_report' ||
        modal.modalType === 'success_report_remove' ||
        modal.modalType === 'success_notice_complete' ||
        modal.modalType === 'success_block_user' ||
        modal.modalType === 'success_unblock_user') &&
      modal.isConfirm
    ) {
      refreshFn(true);
    }
  }, [modal]);

  useEffect(() => {
    dispatch(
      openSubView({
        url: location.pathname,
      }),
    );
  }, []);

  // refresh 함수 설정 및 초기화
  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );

    network()
      .group()
      .groupPageView({
        group_id: Number(id),
        url: location.pathname,
        platform: 'mo',
      });

    return () => {
      setTab(0);
      dispatch(initRefreshFn());
    };
  }, []);

  // 그룹의 내정보
  const [myInfo, setMyInfo] = useState<IMixedKeyValue | null | undefined>(null);
  // 선택된 게시글 옵션 ( 0:전체글 / 1: 모임장글 )
  const [selected, setSelected] = useState<number>(0);
  // 페이지 넘버
  const [page, setPage] = useState(1);
  // 마지막 페이지 체크
  const lastPage = useRef<boolean>(false);
  // api중복으로 날라가는걸 방지하기 위한 state
  const [loading, setLoading] = useState(false);
  // 게시글 목록
  const [list, setList] = useState<any[]>([]);
  // 공지 목록
  const [noticeList, setNoticeList] = useState<IMixedKeyValue[]>([]);
  // 스크롤 값
  const [scrollHeight, setScrollHeight] = useState(0);

  // 초대 코드 값 체크
  const [isCode, setIsCode] = useState<boolean>(false);

  // 모임설정 config 조회
  useEffect(() => {
    const f = async () => {
      await fetchGroupConfig();
    };
    if (id) {
      f();
    }
  }, [id]);

  // 공개모임 여부 체크
  const isPublic = info?.['open_info']?.value === 'PUBLIC' ? true : false;

  // 모임 게시글 조회
  useEffect(() => {
    const f = async () => {
      await fetchList();
    };
    if ((myInfo?.id || isPublic) && id) {
      if (isRefreshRef.current) return;
      f();
    }
  }, [myInfo, page, selected, info, tab]);

  // 모임 공지사항 조회
  useEffect(() => {
    const f = async () => {
      await fetchNoticeList();
    };
    if ((myInfo?.id || isPublic) && id) {
      f();
    }
  }, [myInfo, info, tab]);

  // 스크롤 초기화
  useEffect(() => {
    if (list.length > 0) {
      setList([]);
    }
    setScrollHeight(0);
  }, [selected, tab]);

  /**
   * @description 모임 상세 정보 가져오기
   * @returns {Promise<void>}
   */
  const currentUrl = window.location.href;
  let res = '';
  const fetchGroupConfig = async () => {
    if (id === '') return;
    try {
      const urlSuffix = currentUrl.includes('/mygroup/group/') ? '/info' : '/basicinfo';
      res = await APIS.getGroupBasicInfo(`/groups/${id}${urlSuffix}`);
      const { data }: any = res;

      if (data) {
        const { myinfo = [], group = {}, other = {}, permissoin = {} } = data;
        const {
          users_count = 0,
          name = '',
          photos,
          join_condition,
          open_info,
          join_question_use = false,
          join_question = '',
          join_apply_use = false,
          posts_count = 0,
          type = 0,
        } = data.group;
        const myData = myinfo.length > 0 ? myinfo[0] : {};
        dispatch(
          setGroupInfo({
            myInfo: { ...myData },
            group: { ...group },
            other: { ...other },
            permissoin: { ...permissoin },
          }),
        );
        setInfo({
          name: name,
          usersCount: users_count,
          imgSrc: photos[0].url,
          posts_count: posts_count,
          /*
          join_condition : 가입조건
            ALL: 모두 가입 허용
            BROKER: 중개사만 가입 허용          
          */
          join_apply_use: join_apply_use,
          join_question: join_question,
          join_question_use: join_question_use,
          join_condition: {
            gender: join_condition.gender,
            broker_type: join_condition.broker_type,
          },
          open_info: {
            label: open_info.label,
            value: open_info.value,
          },
          type: type,
        });

        if (myData) {
          //alert(JSON.stringify(myData));
          setMyInfo({
            ...myData,
          });
        }
      }
    } catch (err: any) {
      if (err?.response?.data?.error === 'Data not found') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '존재하지 않는 모임입니다.',
            },
            isOpen: true,
            action: () => {
              moveMain(0, '');
              onClose();
            },
          }),
        );
      }
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 1000);
    }
  };

  /**
   * @description 모임 게시글 조회
   * @returns {Promise<void>}
   */
  const fetchList = async (isRefresh: boolean = false): Promise<void> => {
    if (id === '') return;
    if (tab !== 0) return;
    try {
      setLoading(true);
      let queryString = `/groups/${id}/posts/board/list?page_size=${PAGE_SIZE}&page=${
        isRefresh || isRefreshRef.current ? 1 : page
      }`;

      if (selected === 1) {
        queryString += '&admin=true';
      }

      const res = await APIS.getGroupBoardList(queryString);
      const { data = [], links } = res;
      const { next = null } = links;

      // 마지막 페이지 체크
      if (next === null) {
        lastPage.current = true;
      }
      if (data) {
        if (isRefresh) {
          setList([...data]);
        } else {
          setList((prev) => [...prev, ...data]);
        }
      }
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      isJoinRef.current = false;
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 모임 공지사항 조회
   * @returns {Promise<void>}
   */
  const fetchNoticeList = async (): Promise<void> => {
    if (id === '') return;
    if (tab !== 0) return;
    try {
      const queryString = `/groups/${id}/posts/notice`;

      const res = await APIS.getGroupBoardList(queryString);
      const { data = [] } = res;

      if (data) {
        setNoticeList([...data]);
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  /**
   * @description 모임 회원초대 코드 생성 페이지
   * @returns {Promise<void>}
   */
  const movePageInviteMember = () => {
    navigate(`/invitegroupmember/${id}`);
  };

  /**
   * @description 모임 가입 여부 체크
   * @returns {data}
   */
  const checkJoinStatus = () => {
    let data = {
      isJoin: false,
      isApproved: false,
    };
    const { group } = groupData || {};
    const { users } = group || {};
    const findItem = users?.find((item: IMixedKeyValue) => item.id === currentUser?.id);

    if (findItem?.id) {
      data = {
        isJoin: true,
        isApproved: findItem?.approved_at ? true : false,
      };
    }

    return data;
  };

  /**
   * @description 모임 가입하기
   * @returns {Promise<void>}
   */
  const handleClickRegisterGroup = async (type?: string) => {
    // user안에 있는데
    const check = await checkJoinStatus();

    if (check.isJoin && !check.isApproved) {
      dispatch(
        openModal({
          isOpen: true,
          modalType: type === 're' ? 'alert_join_re_group' : 'alert_join_group',
          action: () => fetchGroupConfig(),
        }),
      );
      return;
    }

    // 공개모임
    if (info?.join_condition?.broker_type === 'ALL') {
      // 가입 질문이 있는 경우
      if (info?.open_info?.value === 'NAMEONLY' && info.join_question_use && info.join_apply_use) {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'confirm_join_question',
            data: { quesText: info?.join_question || '', groupId: id, userId: currentUser.id },
            action: () => fetchGroupConfig(),
          }),
        );
      } else {
        // 가입 질문이 없는 경우
        if (currentUser.id) {
          fetchJoinGroup(currentUser.id);
        }
      }
    }
    // 중개사만
    if (info?.join_condition?.broker_type === 'BROKER') {
      const { verify = null } = currentUser;
      // 중개사인 경우
      if (verify !== null) {
        // 가입질문이 있는 경우
        if (info?.open_info?.value === 'NAMEONLY' && info.join_question_use && info.join_apply_use) {
          // 질문 입력
          dispatch(
            openModal({
              isOpen: true,
              modalType: 'confirm_join_question',
              data: { quesText: info?.join_question || '', groupId: id, userId: currentUser.id },
            }),
          );
        } else {
          // 가입 질문이 없는 경우
          // 신청
          if (currentUser.id) {
            fetchJoinGroup(currentUser.id);
          }
        }
        // 중개사가 아닌경우
      } else {
        dispatch(
          openModal({
            isOpen: true,
            modalType: 'alert_reject_join_group',
          }),
        );
      }
    }
  };

  /**
   * @description 모임 가입하기 신청하기 api
   * @returns {Promise<void>}
   */
  const fetchJoinGroup = async (userId?: number) => {
    if (clickRef.current) return;
    try {
      clickRef.current = true;
      dispatch(onLoad());
      const payload = {
        join_answer: '',
      };
      const res = await APIS.postJoinGroup(`/groups/${id}/users/${userId}`, payload);
      if (res?.join_apply_use === true) {
        setTimeout(() => {
          dispatch(
            openModal({
              isOpen: true,
              modalType: 'alert_join_group',
              action: () => fetchGroupConfig(),
            }),
          );
        }, 300);
      }

      if (res?.join_apply_use === false) {
        isJoinRef.current = true;
        isRefreshRef.current = true;
        lastPage.current = false;
        setList([]);
        setPage(1);
        setScrollHeight(0);
        fetchGroupConfig();
        fetchList();
        setToastMessage({
          duration: 2000,
          content: '모임에 가입되었습니다.',
          type: 'message',
        });
      }
    } catch (err) {
      console.log(err);
      clickRef.current = false;
    } finally {
      setTimeout(() => {
        clickRef.current = false;
        dispatch(offLoad());
        fetchGroupConfig();
      }, 300);
    }
  };

  /**
   * @description 모임글 작성하러 가기
   */
  const handleClickWrite = () => {
    if (id && info?.name) {
      if (info?.type === 1702) {
        dispatch(
          ActionUser.setHistoryPath({
            from: location.pathname,
            to: `/newpost-type`,
          }),
        );
        navigate({ pathname: `/newpost-type`, search: `?groupId=${id}&groupName=${info?.name}` });
      } else {
        dispatch(
          ActionUser.setHistoryPath({
            from: location.pathname,
            to: `/writing`,
          }),
        );
        navigate({
          pathname: '/writing',
          search: `?mode=write&type=group&groupId=${id}&name=${info?.name}`,
        });
      }
    } else {
      return;
    }
  };

  /**
   * @description 모임 설정 페이지 이동
   */
  const movePageSetting = () => {
    navigate(`/groupsetting/${id}`);
    dispatch(ActionUser.setHistoryPath({ from: location.pathname, to: `/groupsetting/${id}` }));
  };

  /**
   * @description 모임 회원 목록 페이지 이동
   */
  const movePageGroupMemberList = () => {
    navigate(`/groupmembersearch/${id}`);
    dispatch(ActionUser.setHistoryPath({ from: location.pathname, to: `/groupmembersearch/${id}` }));
  };

  /**
   * @description 모임 공지 목록 페이지 이동
   */
  const moveNoticeList = async () => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    const isCheck = await isPublicCheck();

    if (isCheck) return;
    navigate(`/groupnoticelist/${id}`);
    dispatch(ActionUser.setHistoryPath({ from: location.pathname, to: `/groupnoticelist/${id}` }));
  };

  /**
   * @description 뒤로가기
   */
  const movePageBack = () => {
    isSubView && subViewControl.closeView();
  };

  /**
   * @description 일반 게시글 1 / 모임장 게시글 1
   */
  const handleClickSelectedOption = (option: number) => {
    if (selected === option) {
      return;
    }

    setList([]);
    setScrollHeight(0);
    setPage(1);
    lastPage.current = false;
    setSelected(option);
  };

  /**
   * @description 모임 게시글 메뉴 sheet
   * @param {any} post 게시글 정보
   */
  const handleMoreBotSheet = async (post: IMixedKeyValue) => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    const isCheck = await isPublicCheck();

    if (isCheck) return;
    if (post?.user?.id === currentUser?.id) {
      dispatch(openModal({ modalType: 'bottomSheet_menuMyPost', data: post, isOpen: true, infoMe: currentUser?.id }));
    } else {
      dispatch(openModal({ modalType: 'bottomSheet_menu', data: post, isOpen: true, infoMe: currentUser?.id }));
    }
  };

  /**
   * @description 모임 게시글 코멘트 sheet
   * @param {any} post 게시글 정보
   */
  const handleComment = async (post: IMixedKeyValue) => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    const isCheck = await isPublicCheck();

    if (isCheck) return;
    // dispatch(openModal({ modalType: 'show_comment_list', data: post, isOpen: true }));
    if (!id && !post?.id) return;
    if (post?.post_report?.length > 0) return;
    navigate(`/group-postdetail/${id}/${post.id}?show=true`);
    dispatch(
      ActionUser.setHistoryPath({ from: location.pathname, to: `/group-postdetail/${id}/${post?.id}?show=true` }),
    );
  };

  /**
   * @description 새로고침
   * @param {boolean} isCall 모임 정보를 재조회 할지 여부
   */
  const refreshFn = (isCall: boolean = false) => {
    if (!isCall) {
      fetchGroupConfig();
    }
    setScrollHeight(0);
    fetchList(true);
    setPage(1);
    lastPage.current = false;
    isRefreshRef.current = true;
    fetchNoticeList();
    setList([]);
  };

  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);
    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && list.length > 0) {
      setPage((prev) => prev + 1);
    }
  });

  /**
   * @description 모임 게시글 좋아요
   * @param {number} postId 게시글 id
   * @returns {void}
   */
  const handleClickLikePost = async (postId: number) => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    const isCheck = await isPublicCheck();

    if (isCheck) return;
    if (!postId) return;
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await APIS.postLikeGroupPost(`/groups/${id}/posts/${postId}/like`);
      if (res?.data) {
        const copyList = cloneDeep(list);
        const findIndex = copyList?.findIndex((item) => item.id === postId);
        if (findIndex !== -1) {
          copyList[findIndex] = {
            ...copyList[findIndex],
            ...res.data,
          };

          setList(copyList);
        }
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 모임 게시글 좋아요 해제
   * @param {number} postId 게시글 id
   * @returns {void}
   */
  const handleClickdisLikePost = async (postId: number) => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    const isCheck = await isPublicCheck();

    if (isCheck) return;
    if (!postId) return;
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await APIS.postdisLikeGroupPost(`/groups/${id}/posts/${postId}/hate`);
      const copyList = cloneDeep(list);
      const findIndex = copyList?.findIndex((item) => item.id === postId);
      if (findIndex !== -1) {
        copyList[findIndex] = {
          ...copyList[findIndex],
          ...res.data,
        };

        setList(copyList);
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 모임 게시글 상세 페이지 이동
   * @param {number} postId 게시글 id
   * @param {boolean} isReport 게시글 신고여부
   */
  const movePostDetail = async (postId: number, isReport?: boolean) => {
    // 공개 모임인데 가입을 하지 않았을 경우 체크
    const isCheck = await isPublicCheck();

    if (isCheck) return;
    if (!id && !postId) return;
    if (isReport) return;
    navigate(`/group-postdetail/${id}/${postId}`);
    dispatch(ActionUser.setHistoryPath({ from: location.pathname, to: `/group-postdetail/${id}/${postId}` }));
  };

  /**
   * @description 공개 모임 가입 여부 체크 modal
   */
  const isPublicCheck = () => {
    let check = false;
    if (!myInfo && info.open_info.value === 'PUBLIC') {
      dispatch(
        openModal({
          isOpen: true,
          modalType: 'confirm_join_group',
          action: () => handleClickRegisterGroup(),
        }),
      );
      check = true;
    }

    return check;
  };

  // 코멘트 추가
  const updateCommentCount = (postId: number, count: number) => {
    if (postId && count) {
      const copyList = cloneDeep(list);
      const findIndex = copyList?.find((item) => item.id === postId) || -1;
      if (findIndex !== -1) {
        copyList[findIndex] = {
          ...copyList[findIndex],
          comments_list_count: count,
        };

        setList([...copyList]);
      }
    }
  };

  // 비공개 모임에 초대코드를 받고 들어온 경우 체크
  useEffect(() => {
    if (code) {
      const f = async () => {
        const res = await APIS.getInviteCode(`/invite/${code || queryCode}`);
        if (id === res?.id + '') {
          setIsCode(true);
        }
      };

      f();
    }
  }, [code, isCode]);

  // 패널티 회원 모달
  useEffect(() => {
    if (myInfo?.is_penalty === true) {
      // 패널티 회원인 경우

      const { histories = [] } = myInfo;
      const findIndex = histories.findIndex((item: any) => item.value.includes('PENALTY'));

      if (findIndex !== -1) {
        const { start_at = '', end_at = '' } = histories[findIndex];

        dispatch(
          openModal({
            isOpen: true,
            modalType: 'alert_penalty_error',
            data: {
              txt: `${moment(start_at).format('YYYY년 M월 D일')} ~ ${moment(end_at).format('YYYY년 M월 D일')}\n
            회원님은 활동정지 상태입니다. 모임장에게 문의하세요! `,
            },
            action: () => {
              onClose();
            },
          }),
        );
      }
    }
  }, [myInfo]);

  const { currentUser } = userData;

  //중개사만 가입할 수 있는 모임인데 중개사가 아닌 경우, 경고창 띄우고 전페이지 이동
  useEffect(() => {
    if (info.join_condition.broker_type === 'BROKER' && currentUser?.verify === null) {
      dispatch(
        openModal({
          modalType: 'alert_common',
          data: {
            txt: '모임장이 지정한 조건에 맞지 않아 가입할 수 없습니다.',
          },
          isOpen: true,
          action: () => {
            movePageBack();
          },
        }),
      );
    }
  }, [info, currentUser, navigate]);

  /**
   * @description 모임 가입 여부 체크
   */
  const joinStatus = checkJoinStatus();

  /**
   * @description 초대 코드로 모임에 들어왔는지 여부 체크
   */
  const isInvite = isCode ? true : false;

  /**
   * @description 모임 게시판 탭
   * @param {number} index 탭 index
   */
  const handleClickTab = (index: number) => {
    if (tab === index) return;
    setTab(index);
  };

  return (
    <div style={{ overflow: 'hidden', height: '100vh' }}>
      <motion.header className={cn(CommonStyles['group-header'], scrollHeight > 10 && CommonStyles.scrolled)}>
        <div className={cn(CommonStyles['background'])}>
          {info.imgSrc && <img src={info.imgSrc} alt="모임 썸네일" />}
        </div>
        <div className={cn(CommonStyles['left'])}>
          <button onClick={movePageBack}>
            <Icon type="icon-back-wh" />
          </button>
        </div>
        <div className={cn(CommonStyles['title'], CommonStyles['limit-line1'])}>{info.name}</div>
        <div className={cn(CommonStyles['right'])}>
          {myInfo?.id && (
            <button onClick={movePageSetting}>
              <Icon type="icon-setting-wh" />
            </button>
          )}
          {myInfo?.id && (
            <button onClick={handleClickWrite}>
              <Icon type="icon-write-wh" />
            </button>
          )}
          {((Object.keys(myInfo || {}).length === 0 && info.name !== '' && info?.open_info?.value !== 'PRIVATE') ||
            (Object.keys(myInfo || {}).length === 0 && isInvite && info?.open_info?.value === 'PRIVATE')) &&
            scrollHeight > 10 && (
              <Button
                className={cn(CommonStyles['dgry'])}
                size="small"
                mergeClass={true}
                onClick={
                  joinStatus.isJoin && !joinStatus.isApproved
                    ? () => handleClickRegisterGroup('re')
                    : () => handleClickRegisterGroup()
                }
              >
                {joinStatus.isJoin && !joinStatus.isApproved ? '가입 신청 완료' : '모임 가입하기'}
              </Button>
            )}
        </div>
        <div className={cn(CommonStyles['bottom'])}>
          <div className={cn(CommonStyles['group-title'])}>{info.name}</div>
          <div className={cn(CommonStyles['group-info'])}>
            <div className={cn(CommonStyles['divide-box'])}>
              {info?.['open_info']?.label && <p>{info?.['open_info']?.label}</p>}
              {info?.['join_condition']?.broker_type === 'BROKER' && <p>중개사만</p>}
              {info?.usersCount !== '' && <p>회원 {numberWithCommas(info?.usersCount || 0)}명</p>}
            </div>
            <div className={cn(CommonStyles['btn-wrap'])}>
              {myInfo?.id && (
                <Button
                  className={cn(CommonStyles['dgry'])}
                  size="small"
                  mergeClass={true}
                  onClick={movePageGroupMemberList}
                >
                  회원
                </Button>
              )}
              {myInfo?.id && myInfo?.value === 'OWNER' && (
                <Button
                  className={cn(CommonStyles['dgry'])}
                  size="small"
                  mergeClass={true}
                  onClick={movePageInviteMember}
                >
                  회원 초대하기
                </Button>
              )}
              {((Object.keys(myInfo || {}).length === 0 && info.name !== '' && info?.open_info?.value !== 'PRIVATE') ||
                (Object.keys(myInfo || {}).length === 0 && isInvite && info?.open_info?.value === 'PRIVATE')) && (
                <Button
                  className={cn(CommonStyles['dgry'])}
                  size="small"
                  mergeClass={true}
                  onClick={
                    joinStatus.isJoin && !joinStatus.isApproved
                      ? () => handleClickRegisterGroup('re')
                      : () => handleClickRegisterGroup()
                  }
                >
                  {joinStatus.isJoin && !joinStatus.isApproved ? '가입 신청 완료' : '모임 가입하기'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </motion.header>
      {info?.type === 1702 && (
        <div className={cn(CommonStyles['tab-menu'], CommonStyles['sub-tab'])}>
          <button
            className={cn(CommonStyles['tab'], tab === 0 && CommonStyles['on'])}
            onClick={() => handleClickTab(0)}
          >
            게시글
          </button>
          <button
            className={cn(CommonStyles['tab'], tab === 1 && CommonStyles['on'])}
            onClick={() => handleClickTab(1)}
          >
            공유매물
          </button>
          <button
            className={cn(CommonStyles['tab'], tab === 2 && CommonStyles['on'])}
            onClick={() => handleClickTab(2)}
          >
            구합니다
          </button>
        </div>
      )}
      {(myInfo?.id || isPublic) && noticeList.length > 0 && tab === 0 && (
        <div className={cn(CommonStyles['group-notice'])} onClick={moveNoticeList}>
          <p className={cn(CommonStyles['txt'], CommonStyles['limit-line1'])} style={{ width: '100%' }}>
            <span className={cn(CommonStyles['co-bl'])}>{noticeList?.[0]?.notice === 3 ? `[중요공지]` : ''}</span>{' '}
            {noticeList[0]?.title ? noticeList[0]?.title : noticeList[0]?.content || ''}
          </p>
          <Icon type={'icon-arrow,icon-right'} />
        </div>
      )}
      {Object.keys(myInfo || {}).length > 0 || isPublic ? (
        <div
          className={cn(
            CommonStyles.container,
            CommonStyles.pageGroup,
            // 공지사항 필요한 경우 추가 작업 필요
            // 공지사항이 있을 경우에만 밑에 클래스 추가
            // CommonStyles.hasNotice,
            // 탭 구분 필요한 경우 추가 작업 필요
            // 탭 구분이 필요한 경우 (공동중개, 구합니다, 공유매물 3개탭 구성 될경우에 추가 필요
            // CommonStyles.hasTab,
            scrollHeight > 10 && CommonStyles.scrolled,
          )}
          style={{ overflow: 'hidden' }}
        >
          <div className={cn(CommonStyles.content, CommonStyles['group-home'])}>
            {(myInfo?.id || isPublic) && tab === 0 && (
              <div className={cn(CommonStyles['sort-divide'])}>
                <button className={cn(selected === 0 && CommonStyles.on)} onClick={() => handleClickSelectedOption(0)}>
                  전체글
                </button>
                <button className={cn(selected === 1 && CommonStyles.on)} onClick={() => handleClickSelectedOption(1)}>
                  모임장글
                </button>
              </div>
            )}
            {info?.posts_count !== '' && info?.posts_count === 0 && tab === 0 && (
              <div className={CommonStyles['no-data']} style={{ height: 'calc(100% - 52px)', textAlign: 'center' }}>
                <p>
                  아직 작성된 글이 없어요!
                  <br />첫 게시글을 작성해주세요
                </p>
              </div>
            )}
            {tab === 1 && (
              <GroupShareList groupId={id || ''} scrollHeight={scrollHeight} setScrollHeight={setScrollHeight} />
            )}
            {tab === 2 && (
              <GroupFindShareList groupId={id || ''} scrollHeight={scrollHeight} setScrollHeight={setScrollHeight} />
            )}

            {tab === 0 && (
              <div
                id="container_wrap"
                onScroll={() => {
                  if (list.length === 0) return;
                  if (!info) {
                    return;
                  }
                  const element = document.getElementById('container_wrap');
                  if (element) {
                    setScrollHeight(element.scrollTop);
                  }
                }}
                style={{ height: list.length > 0 ? '100%' : '0%', overflowY: 'auto' }}
              >
                {list.map((post, index: number) => {
                  const nowDate = new Date();
                  return (
                    <React.Fragment key={index}>
                      <div className={CommonStyles['post-card']}>
                        <div className={CommonStyles['member-information']}>
                          <div className={CommonStyles.clickable}>
                            <div className={CommonStyles.profile}>
                              <div
                                className={CommonStyles['img-div']}
                                style={{
                                  width: 48,
                                  height: 48,
                                  backgroundImage: post?.user?.profile_photo_path
                                    ? `url(${post?.user?.profile_photo_path}`
                                    : `url(../images/icon_profile.svg)`,
                                  backgroundRepeat: 'no-repeat',
                                  backgroundPosition: 'center center',
                                  backgroundSize: 'cover',
                                }}
                              />
                              {post?.user?.verify?.broker_code && (
                                <span className={CommonStyles.grade} aria-label={post?.user?.grade}></span>
                              )}
                            </div>
                            <div className={CommonStyles['mem-info']}>
                              <p className={CommonStyles.name}>{post?.user?.nickname}</p>
                              <div className={CommonStyles['divide-box']}>
                                {post?.user?.company && (
                                  <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                                    {post?.user?.company?.name}
                                  </p>
                                )}
                                <p>{timeDisplay(nowDate, post?.created_at)}</p>
                              </div>
                            </div>
                          </div>
                          <button className={CommonStyles.tool} onClick={() => handleMoreBotSheet(post)} />
                        </div>
                        {post?.user_block || post?.post_report?.length > 0 ? (
                          <div className={CommonStyles.detail}>
                            <div className={CommonStyles['blind-box']}>
                              {post?.user_block ? '차단한 회원의 글입니다.' : '신고된 게시글입니다.'}
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className={CommonStyles.detail}>
                              {post?.title && (
                                <div
                                  className={cn(
                                    CommonStyles.title,
                                    CommonStyles['limit-line'],
                                    CommonStyles['limit-line1'],
                                  )}
                                  onClick={() => movePostDetail(post.id, post?.post_report?.length > 0)}
                                >
                                  {post?.title}
                                </div>
                              )}
                            </div>
                            {post && (
                              <div className={CommonStyles.detail}>
                                <PostContent id={post.id} text={post?.content} post={post} maxLines={3} />
                              </div>
                            )}
                            {post && <PostAttach attachType="post" post={post} />}
                          </>
                        )}
                        {!post?.user_block && !post?.post_report?.length && (
                          <div className={CommonStyles['post-function']}>
                            <button
                              type="button"
                              onClick={() => handleClickLikePost(post.id)}
                              className={cn(CommonStyles.like, post?.post_action?.[0]?.kind === 1 && CommonStyles.on)}
                            >
                              <i className={CommonStyles['icon-like']}></i>
                              {post?.liked_count}
                            </button>
                            <button
                              type="button"
                              onClick={() => handleClickdisLikePost(post.id)}
                              className={cn(
                                CommonStyles.dislike,
                                post?.post_action?.[0]?.kind === 2 && CommonStyles.on,
                              )}
                            >
                              <i className={CommonStyles['icon-dislike']}></i>
                              {post?.hated_count}
                            </button>
                            <button type="button" className={CommonStyles.comment} onClick={() => handleComment(post)}>
                              <i className={CommonStyles['icon-comment']}></i>
                              {post?.comments_list_count}
                            </button>
                          </div>
                        )}
                        {index - 1 === list.length - 2 && <Target ref={ref} />}
                      </div>
                      {index - 1 === list.length - 2 && loading && <ListLoader />}
                    </React.Fragment>
                  );
                })}
                {/* 스크롤 무한 로딩 필요 */}
                {scrollHeight > 0 && list.length >= 2 && <div style={{ height: '200px', width: '100%' }} />}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={cn(CommonStyles.container, CommonStyles.pageGroup, CommonStyles['group-home'])}
          style={{ height: '100vh' }}
        >
          <div className={CommonStyles['no-data']} style={{ height: '70.5%' }}>
            {/* 가입신청도 없고, 비공개 모임, 초대를 받지 않았을 경우 */}
            {!joinStatus.isApproved &&
              info?.open_info?.value === 'PRIVATE' &&
              !isInvite &&
              Object.keys(myInfo || {}).length == 0 && (
                <p style={{ textAlign: 'center' }}>
                  <>
                    회원만 볼 수 있습니다.
                    <br />
                    모임에 가입해보세요!
                  </>
                </p>
              )}
            {/* 가입신청도 없고, 비공개 모임, 초대를 받았을 경우 */}
            {!joinStatus.isApproved &&
              (info?.open_info?.value === 'PRIVATE' || info?.open_info?.value === 'NAMEONLY') &&
              isInvite &&
              Object.keys(myInfo || {}).length == 0 && (
                <p style={{ textAlign: 'center' }}>
                  <>
                    {`${'`'}${info.name}${'`'}에`}
                    <br />
                    초대받으셨네요. 반갑습니다!
                  </>
                </p>
              )}
            {joinStatus.isJoin && !joinStatus.isApproved && (
              <p style={{ textAlign: 'center' }}>
                {/* 가입신청도 한 경우 */}
                가입 신청이 완료되었습니다. 모임장이
                <br />
                승인하면 모임에 가입됩니다.
              </p>
            )}
            {!joinStatus.isJoin && Object.keys(myInfo || {}).length == 0 && info?.open_info?.value !== 'PRIVATE' && (
              <>
                {/* 아직 가입 신청을 하지 않은 경우 */}
                회원만 볼 수 있습니다.
                <br />
                모임에 가입해보세요!
              </>
            )}
          </div>
        </div>
      )}
      {toastState.isOpen && <Toast />}
    </div>
  );
};

export default Group;

const Target = styled.div`
  height: 1px;
`;
