import { useAppDispatch } from 'redux/store';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionUser from 'redux/slices/user';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

const BrokerSync = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;
  const [brokerInfoState, setBrokerInfoState] = useState(state);

  useEffect(() => {
    if (!brokerInfoState) {
      navigate('/broker');
    }
  }, [brokerInfoState]);

  const brokerVerify = async () => {
    dispatch(onLoad());
    const params = {
      registration_number: brokerInfoState?.biz_reg_no,
      jurirno: brokerInfoState?.open_reg_no,
      brkrNm: brokerInfoState?.name,
    };
    const body = JSON.stringify(params);

    await network()
      .broker()
      .PostAipartnerVerify(body)
      .then(async (res) => {
        dispatch(offLoad());
        if (res && res.data?.message === 'OK') {
          const resMe = await network().sso().me();
          dispatch(ActionUser.setMe(resMe.data));
          if (resMe.data.verify_temp) {
            navigate('/broker/temporary', { state: { locationHistory: 'sync' } });
          } else {
            navigate('/broker/complete');
          }
        } else {
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: '중개사 인증이 완료되지 않았습니다. 다시 시도해주세요.' },
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(offLoad());
        if (err && err.response?.data) {
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: err.response.data.message },
            }),
          );
        }
      });
  };

  return (
    <>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={cn(CommonStyles['content-div'], CommonStyles['complete'])}>
            <div className={ResetStyles['ta-c']}>
              <i className={CommonStyles['img-aipartner']}></i>
              <strong className={CommonStyles['big-txt']}>{brokerInfoState?.name || '-'}님,</strong>
              <p className={CommonStyles['page-txt']}>이실장에서 인증한 중개사 정보입니다.</p>
            </div>
            <div className={CommonStyles['round-table']}>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>상호명</p>
                <p className={CommonStyles.col}>{brokerInfoState?.agency_name || '-'}</p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>소재지</p>
                <p className={CommonStyles.col}>
                  {brokerInfoState?.road_addr + ' ' + brokerInfoState?.road_detail_addr}
                </p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>전화번호</p>
                <p className={CommonStyles.col}>
                  {brokerInfoState?.boss_tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') || '-'}
                </p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>등록번호</p>
                <p className={CommonStyles.col}>{brokerInfoState?.open_reg_no || '-'}</p>
              </div>
              <div className={CommonStyles.row}>
                <p className={cn(CommonStyles.col, CommonStyles.bg)}>대표자명</p>
                <p className={CommonStyles.col}>{brokerInfoState?.boss_name || '-'}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles['fixed'])}>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
          onClick={() =>
            dispatch(
              ActionModal.openModal({
                modalType: 'broker_alert_sync_cancel',
                isOpen: true,
                data: {
                  actionNavi: () => {
                    if (window.webkit) {
                      window.webkit.messageHandlers.moveTab.postMessage(JSON.stringify({ tabIndex: 0, parameter: '' }));
                      window.webkit.messageHandlers.close.postMessage();
                    } else if (window.Android) {
                      window.Android.moveTab(JSON.stringify({ tabIndex: 0, parameter: '' }));
                      window.Android.close();
                    }
                  },
                },
              }),
            )
          }
        >
          취소하기
        </button>
        <button className={cn(CommonStyles['btn'], CommonStyles['lg'])} onClick={() => brokerVerify()}>
          정보 연동하기
        </button>
      </div>
    </>
  );
};

export default BrokerSync;
