import network from 'utils/network';

/**
 * @description 유저 차단하기
 * @Class
 * @category Api
 * @subcategory 유저
 * @param {string} path url
 * @param {any} payload: { block_user_id } 차단할 유저 id
 * @returns {Promise<void>}
 */
export const postBlockUser = async (path: string, payload: { block_user_id: number }) => {
  const res = await network().user().postBlockUser(path, payload);
  return res;
};

/**
 * @description 유저 차단하기 해제
 * @Class
 * @category Api
 * @subcategory 유저
 * @param {string} path url
 * @returns {Promise<void>}
 */
export const deleteBlockUser = async (path: string) => {
  const res = await network().user().deleteBlockUser(path);
  return res;
};
