import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { debounce } from 'lodash';
import AWS from 'aws-sdk';
import { PROFILE_NICKNAME_MESSAGE, BUCKET_NAME } from 'configs/configs';
import { useAppDispatch, useAppSelector, RootState } from 'redux/store';
import { setMe, setProfileChange } from 'redux/slices/user';
import network from 'utils/network';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import { openModal } from 'redux/slices/modal';

/**
 * @description 내피드 프로필 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @returns {JSX.Element}
 */
const Profile = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  // 닉네임 input 요소 ref
  const inputRef = useRef<HTMLInputElement>(null);
  // 섬네일 input file 요소 ref
  const imageRef = useRef<HTMLInputElement>(null);
  //const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  // 닉네임
  const [nickname, setNickname] = useState<string>('');
  // 프로필 이미지 (노출)
  const [thumb, setThumb] = useState<string>('');
  // 프로필 이미지 (설정)
  const [photo, setPhoto] = useState<string>('');
  // 닉네임 유효여부
  const [validNickname, setValidNickname] = useState<boolean>(true);
  // 메세지
  const [message, setMessage] = useState<string>('');
  // 닉네임 유효성 검증 요청 목록
  const checkList = useRef<boolean[]>([]);

  /**
   * @description s3 설정
   */
  AWS.config.update({
    region: import.meta.env.VITE_S3_BUCKET_REGION,
    accessKeyId: import.meta.env.VITE_S3_BUCKET_ACCESSKEY,
    secretAccessKey: import.meta.env.VITE_S3_BUCKET_SECRETKEY,
  });

  const s3 = new AWS.S3();

  /**
   * @description 내정보 불러오기
   * @returns {void}
   */
  const getMe = async () => {
    try {
      const response = await network().sso().me();

      dispatch(setMe(response.data));
    } catch (error) {
      //console.log('getMe(error):', error);
    }
  };

  /**
   * @description 닉네임 규칙 검증(10자 이내 한글, 영문, 숫자 혼합)
   * @param {string} name 검증할 닉네임
   * @returns {void}
   */
  const checkNickname = (name: string) => {
    const pattern = /^[ㄱ-ㅎ가-힣가-힣A-Za-z0-9]*$/;
    if (!name) {
      setMessage(PROFILE_NICKNAME_MESSAGE.EMPTY_NICKNAME);
      return false;
    } else if (name.length > 10 || !pattern.test(name)) {
      setMessage(PROFILE_NICKNAME_MESSAGE.NOT_VALID_NICKNAME);
      return false;
    }

    return true;
  };

  /**
   * @description 닉네임 유효성 검증
   * @param {string} name 검증할 닉네임
   * @returns {void}
   */
  const isNickname = async (name: string) => {
    setNickname(name);
    checkList.current.push(true);

    // 닉네임 규칙 검사
    if (!checkNickname(name)) {
      setValidNickname(false);
      return;
    }

    // 현재 입력된 닉네임과 동일할 경우
    if (user?.me?.nickname && name == user?.me?.nickname) {
      setMessage('');
      setValidNickname(true);
      return;
    }

    try {
      const checkIndex = checkList.current.length;
      const response = await network().sso().isNickname({ nickname: name });

      // 검증요청 결과가 마지막 검증요청 보다 늦게 실행되었을 때
      if (checkIndex < checkList.current.length) return;

      if (response.data?.message == 'OK') {
        setMessage(PROFILE_NICKNAME_MESSAGE.USABLE_NICKNAME);
        setValidNickname(true);
      } else {
        setMessage(response.data?.message);
        setValidNickname(false);
      }
    } catch (error) {
      //console.log('isnickname error:', error);
    }
  };

  /**
   * @description 닉네임 input change 핸들러
   * @returns {void}
   */
  const changeInput = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    isNickname(newValue);
  }, 300);

  /**
   * @description  프로필 이미지 수정 버튼 클릭 처리
   * @returns {void}
   */
  const handlePhotoChange = () => {
    imageRef.current?.click();
  };

  /**
   * @description  이미지 파일 선택
   * @returns {void}
   */
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const { files } = event.target;

      if (files?.length != 1) return;

      const file = files[0];
      const fileSizeLimit = 20 * 1024 * 1024;

      if (file.size > fileSizeLimit) {
        dispatch(openModal({ modalType: 'alert_image_limit' }));
        return;
      }

      const orgName = file.name;
      const ext = file.name.split('.').pop();
      // 파일 이름 변경
      const currTime = new Date().getTime();
      const filename = `${user?.me?.id}_${currTime}.${ext}`;
      const newFile = new File([file], filename, {
        type: file.type,
        lastModified: file.lastModified,
      });

      // 이미지 s3 upload
      const params: AWS.S3.PutObjectRequest = {
        Bucket: `${BUCKET_NAME}/temp/photo`,
        Key: newFile.name,
        Body: newFile,
        ACL: 'public-read', // Set ACL (Access Control List) for the uploaded image
        ContentType: newFile.type,
        // custum header 설정
        Metadata: {
          orgname: encodeURIComponent(orgName), // 원본파일명
        },
      };

      const upload = new AWS.S3.ManagedUpload({
        params,
        service: s3,
      });

      const result = await upload.promise();
      //console.log('upload result:', result);
      /* custum header 받아오기
      const headParams = {
        Bucket: `${BUCKET_NAME}/temp/photo`,
        Key: newFile.name,
      };

      const response = await s3.headObject(headParams).promise();
      console.log('orgName:', decodeURIComponent(response.Metadata?.orgname ?? ''));
      */
      const relativePath = new URL(result.Location).pathname;
      // 프로필 이미지(api 전송 시 사용)
      const photoPath = result.Location.replace(relativePath ?? '', `/${result.Key}`);
      // 화면 섬네일 표시 이미지
      const thumbPath = `${import.meta.env.VITE_CDN_URL}${relativePath}`;

      setThumb(thumbPath);
      setPhoto(photoPath);
    } catch (error) {
      //console.log('error:', error);
    }
  };

  // 내정보 가져오기
  useEffect(() => {
    getMe();
  }, []);

  // 초기화
  useEffect(() => {
    if (!user?.me) return;

    // 닉네임
    setNickname(user?.me?.nickname ?? '');
    if (inputRef.current) inputRef.current.value = nickname;

    // 프로필 이미지
    const photoPath = user?.me?.profile_photo_path ?? '';
    setThumb(photoPath);
    setPhoto(photoPath);

    // 메세지 초기화
    setMessage('');
    // profile 변경여부
    dispatch(setProfileChange({ save: false, nickname: nickname, photo: photo }));
  }, [user?.me]);

  // 프로필 수정정보 설정 및 '완료' 버튼 활성상태 변경
  useEffect(() => {
    // 프로필 저장 가능여부(변경사항이 있을 경우 true)
    const save =
      (validNickname && nickname && user?.me?.nickname != nickname) || (photo && user?.me?.profile_photo_path != photo)
        ? true
        : false;

    // 프로필 수정정보 설정
    dispatch(setProfileChange({ save: save, nickname: nickname, photo: photo }));
  }, [nickname, photo, validNickname]);

  return (
    <div className={cn(CommonStyles['content'], CommonStyles['my-profile'])}>
      <div className={cn(CommonStyles['member-profile'], CommonStyles['vertical'], CommonStyles['modify'])}>
        <input type="file" accept="image/*" style={{ display: 'none' }} ref={imageRef} onChange={handleFileChange} />
        {/* 프로필 이미지 수정 버튼 */}
        <button className={CommonStyles.profile} onClick={handlePhotoChange}>
          <div
            className={CommonStyles['img-div']}
            style={{
              backgroundImage: thumb ? `url(${thumb})` : undefined,
            }}
          ></div>
          <span className={CommonStyles['setting-btn']}>
            <i className={CommonStyles['icon-modify']}></i>
          </span>
        </button>
        <p className={CommonStyles['mem-id']}>{user?.me?.login_id}</p>
      </div>
      {/* 닉네임 입력창 */}
      <div
        className={cn(
          CommonStyles['input-area'],
          CommonStyles['has-byte'],
          nickname && CommonStyles['clear-has'],
          !validNickname && CommonStyles['error'],
        )}
      >
        <input
          type="text"
          placeholder="닉네임을 입력해주세요."
          ref={inputRef}
          size={10}
          maxLength={10}
          onChange={changeInput}
        />
        <span className={CommonStyles.byte}>{nickname?.length ?? 0}/10</span>
        {nickname && (
          <button
            className={CommonStyles.delete}
            onClick={() => {
              if (inputRef.current) inputRef.current.value = '';
              isNickname('');
            }}
          ></button>
        )}
        {message && <p className={CommonStyles['guide-txt']}>{message}</p>}
      </div>
    </div>
  );
};

export default Profile;
