import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import styled from 'styled-components';
import * as APIS from 'api/group';
import { IMixedKeyValue } from '<modal>';
import useScroll from 'hook/useScroll';
import PostAttach from 'pages/allboard/PostAttach';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import PostContent from 'pages/allboard/PostContent';
import { timeDisplay } from 'utils/common';
import ListLoader from 'components/common/Loader/ListLoader';
import { cloneDeep } from 'lodash';
import { initRefreshFn, setRefreshFn } from 'redux/slices/refresh';
import Toast from 'components/common/Toast/Toast';
import { moveMain, onClose } from 'utils';

const PAGE_SIZE = 10;

/**
 * @description 모임 - 공지사항 목록
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupNoticeList = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { id = '' } = params;
  const navigate = useNavigate();
  const userData = useAppSelector((state: RootState) => state.user);
  const modalData = useAppSelector((state: RootState) => state.modal);
  const toastData = useAppSelector((state: RootState) => state.toast);
  const { isOpen: toastOpen = false } = toastData;

  const { modalType = '', isConfirm = false } = modalData;
  // 중복 클릭 방지
  const clickRef = useRef<boolean>(false);
  // 페이지 넘버
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);
  // 마지막 페이지 체크
  const lastPage = useRef<boolean>(true);
  // api중복으로 날라가는걸 방지하기 위한 state
  const [loading, setLoading] = useState(false);
  // 공지 목록
  const [noticeList, setNoticeList] = useState<IMixedKeyValue[]>([]);

  // 새로고침 중복 방지
  const isRefreshRef = useRef<boolean>(false);

  useEffect(() => {
    const f = async () => {
      await fetchNoticeList();
    };
    if (id) {
      if (isRefreshRef.current) return;
      f();
    }
  }, [page]);

  // refresh 기능 추가
  useEffect(() => {
    dispatch(
      setRefreshFn({
        refreshFn: () => {
          refreshFn();
        },
      }),
    );

    return () => {
      dispatch(initRefreshFn());
    };
  }, []);

  useEffect(() => {
    if ((modalType === 'success_notice_un_complete' || modalType === 'success_notice_complete') && isConfirm) {
      refreshFn();
    }
  }, [modalType]);

  /**
   * @description 모임 공지사항 조회 api
   * @returns {Promise<void>}
   */
  const fetchNoticeList = async (isRefresh = false): Promise<void> => {
    if (id === '') return;
    try {
      setLoading(true);
      const queryString = `/groups/${id}/posts/notice/list?&page_size=${PAGE_SIZE}&page=${isRefresh ? 1 : page}`;

      const res = await APIS.getGroupBoardList(queryString);
      const { data = [], links, meta } = res;
      const { total: totalCnt = 0 } = meta;
      const { next = null } = links;

      // 마지막 페이지 체크
      if (next === null) {
        lastPage.current = true;
      }

      setTotal(totalCnt);

      if (data) {
        if (isRefresh) {
          setNoticeList([...data]);
        } else {
          setNoticeList((prev) => [...prev, ...data]);
        }
      }
    } catch (err: any) {
      lastPage.current = true;
      if (err?.response?.data?.error === 'Data not found') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '존재하지 않는 모임입니다.',
            },
            isOpen: true,
            action: () => {
              moveMain(0, '');
              onClose();
            },
          }),
        );
      }
      if (err?.response?.data?.message === '권한이 없습니다.') {
        dispatch(
          openModal({
            modalType: 'alert_common',
            data: {
              txt: '권한이 없어 해당 게시글 조회가 불가능합니다.\n모임에 가입 후 이용해주세요! ',
            },
            isOpen: true,
            action: () => {
              navigate(`/mygroup/group?id=${id}`);
            },
          }),
        );
      }
    } finally {
      isRefreshRef.current = false;
      setLoading(false);
    }
  };

  /**
   * @description 새로고침
   * @returns {Promise<void>}
   */
  const refreshFn = () => {
    isRefreshRef.current = true;
    fetchNoticeList(true);
    setPage(1);
  };

  /**
   * @description 모임 공지사항 좋아요 or 해제
   * @param {number} postId 게시글 id
   * @returns {void}
   */
  const handleClickLikePost = async (postId: number) => {
    if (!postId) return;
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await APIS.postLikeGroupPost(`/groups/${id}/posts/${postId}/like`);
      if (res?.data) {
        const copyList = cloneDeep(noticeList);
        const findIndex = copyList?.findIndex((item) => item.id === postId);
        console.log(findIndex, 'findIndex');
        if (findIndex !== -1) {
          copyList[findIndex] = {
            ...copyList[findIndex],
            ...res.data,
          };

          setNoticeList(copyList);
        }
      }
      console.log(res, 'dislike');
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 모임 공지사항 싫어요 or 해제
   * @param {number} postId 게시글 id
   * @returns {void}
   */
  const handleClickdisLikePost = async (postId: number) => {
    if (!postId) return;
    if (clickRef.current) return;
    clickRef.current = true;
    try {
      const res = await APIS.postdisLikeGroupPost(`/groups/${id}/posts/${postId}/hate`);
      const copyList = cloneDeep(noticeList);
      const findIndex = copyList?.findIndex((item) => item.id === postId);
      if (findIndex !== -1) {
        copyList[findIndex] = {
          ...copyList[findIndex],
          ...res.data,
        };

        setNoticeList(copyList);
      }
    } catch (err: any) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  /**
   * @description 무한 스크롤 observe
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);

    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && noticeList.length > 0) {
      setPage((prev) => prev + 1);
    }
  });

  const { currentUser } = userData;

  /**
   * @description 모임 공지사항 게시글 메뉴 sheet open
   * @param {any} post 게시글 정보
   * @returns {void}
   */
  const handleMoreBotSheet = (post: IMixedKeyValue): void => {
    // console.log('handleMoreBotSheet===>');
    if (post?.user?.id === currentUser?.id) {
      dispatch(openModal({ modalType: 'bottomSheet_menuMyPost', data: post, isOpen: true }));
    } else {
      dispatch(openModal({ modalType: 'bottomSheet_menu', data: post, isOpen: true }));
    }
  };

  /**
   * @description 모임 공지사항 코멘트 sheet open
   * @param {any} post 게시글 정보
   * @returns {void}
   */
  const handleComment = (post: IMixedKeyValue): void => {
    dispatch(openModal({ modalType: 'show_comment_list', data: post, isOpen: true }));
  };

  /**
   * @description 모임 공지사항 게시글 상세 페이지 이동
   * @param {number} postId 게시글 id
   * @returns {void}
   */
  const movePostDetail = (postId: number) => {
    if (!id && !postId) return;
    navigate(`/group-postdetail/${id}/${postId}`);
  };

  return (
    <div className={cn(CommonStyles.content, CommonStyles['notice-page'])}>
      <div className={CommonStyles['sub-title']}>
        공지시항
        <span className={CommonStyles['co-dorg']}>{total}</span>
      </div>
      {noticeList.map((post, index: number) => {
        const nowDate = new Date();
        let brokerInfo = '';
        if (post.user.verify?.is_ceo == 1) {
          brokerInfo = '대표공인중개사';
        } else if (post.user.verify?.broker_code == 2) {
          brokerInfo = '소속공인중개사';
        } else if (post.user.verify?.broker_code == 4) {
          brokerInfo = '중개보조원';
        }

        // 공유 출처, 이미지, 첨부파일, 지도정보, 이모티콘 존재 여부
        const isAttached =
          post.post_share?.length > 0 ||
          post.photos.length !== 0 ||
          post.files.length !== 0 ||
          (post.latitude !== null && post.longitude !== null) ||
          post.emoticon !== null;

        return (
          <React.Fragment key={index}>
            <div className={CommonStyles['post-card']}>
              <div className={CommonStyles['member-information']}>
                <button className={CommonStyles.clickable}>
                  <div className={CommonStyles.profile}>
                    <div
                      className={CommonStyles['img-div']}
                      style={{
                        width: 48,
                        height: 48,
                        backgroundImage: post?.user?.profile_photo_path
                          ? `url(${post?.user?.profile_photo_path}`
                          : `url(../images/icon_profile.svg)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                      }}
                    />
                    {post?.user?.verify?.broker_code && (
                      <span className={CommonStyles.grade} aria-label={post?.user?.grade}></span>
                    )}
                  </div>
                  <div className={CommonStyles['mem-info']}>
                    <p className={CommonStyles.name}>{post?.user?.nickname}</p>
                    <div className={CommonStyles['divide-box']}>
                      {post?.user?.company && (
                        <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {post?.user?.company?.name}
                        </p>
                      )}
                      <p>{timeDisplay(nowDate, post?.created_at)}</p>
                    </div>
                  </div>
                </button>
                <button className={CommonStyles.tool} onClick={() => handleMoreBotSheet(post)} />
              </div>
              {post?.post_report?.length > 0 ? (
                <div className={CommonStyles.detail}>
                  <div className={CommonStyles['blind-box']}>신고된 게시글입니다.</div>
                </div>
              ) : (
                <button className={CommonStyles.detail} onClick={() => movePostDetail(post.id)}>
                  {post.notice === 3 && (
                    <p className={cn(CommonStyles['em-sm-txt'], CommonStyles['co-bl'])}>{'[중요공지]'}</p>
                  )}
                  {post?.title && (
                    <p className={cn(CommonStyles.title, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                      {post?.title}
                    </p>
                  )}
                  {post && <PostContent id={post.id} text={post?.content} post={post} maxLines={3} />}
                  {post && <PostAttach post={post} />}
                </button>
              )}
              {!post?.post_report?.length && (
                <div className={CommonStyles['post-function']}>
                  <button
                    type="button"
                    onClick={() => handleClickLikePost(post.id)}
                    className={cn(CommonStyles.like, post?.post_action?.[0]?.kind === 1 && CommonStyles.on)}
                  >
                    <i className={CommonStyles['icon-like']}></i>
                    {post?.liked_count}
                  </button>
                  <button
                    type="button"
                    onClick={() => handleClickdisLikePost(post.id)}
                    className={cn(CommonStyles.dislike, post?.post_action?.[0]?.kind === 2 && CommonStyles.on)}
                  >
                    <i className={CommonStyles['icon-dislike']}></i>
                    {post?.hated_count}
                  </button>
                  <button type="button" className={CommonStyles.comment} onClick={() => handleComment(post)}>
                    <i className={CommonStyles['icon-comment']}></i>
                    {post?.comments_list_count}
                  </button>
                </div>
              )}
              {index + 1 === noticeList.length && <Target ref={ref} />}
            </div>
            {index + 1 === noticeList.length && loading && <ListLoader />}
          </React.Fragment>
        );
      })}
      {toastOpen && <Toast />}
    </div>
  );
};

export default GroupNoticeList;

const Target = styled.div`
  height: 1px;
`;
