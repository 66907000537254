import { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import moment from 'moment';
import useScrollToTop from 'hook/useScrollToTop';
import _ from 'lodash';
// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { batch } from 'react-redux';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionModal from 'redux/slices/modal';

import CommonSummary from './CommonSummary';

// configs
import * as Config from 'configs/configs';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
// type and init data
import { IMixedKeyValue } from '<modal>';
import { initAddr } from './initState';
// utils
import { squareToPyeong, rmComma } from 'utils/common';
import { formatNumber } from 'utils';
import network from 'utils/network';
import { useLocation } from 'react-router-dom';

interface Props {
  title?: string;
  reTypeList?: IMixedKeyValue[];
  stateAddr?: IMixedKeyValue;
  setStateAddr?: (stateAddr: IMixedKeyValue) => void;
}

/**
 * @description 공유매물등록 step component
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @param {object} props
 * @param {string} props.title
 * @param {array} props.reTypeList
 * @returns {JSX.Element}
 */
const CoagencyRegStep = (props: Props): JSX.Element => {
  const { title, reTypeList, stateAddr, setStateAddr } = props;
  useScrollToTop();
  const dispatch = useAppDispatch();
  const { modalType, isOpen } = useAppSelector((state: RootState) => state.modal);
  const currStep = useAppSelector((state: RootState) => state.coagency.shareRegStep.currStep);
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const shares = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.shares);
  const selectedAddr = useAppSelector((state: RootState) => state.coagency.shareRegStep.selectedAddr);
  const REMainType = useAppSelector(
    (state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_main_gbn,
  );
  const RESubType = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_gbn);
  const OfferGbn = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offer_gbn);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');

  const [tit, setTit] = useState('');

  const initDongHoState = {
    ho: '',
    hideDong: false,
    dongAddrState: {},
  };
  // 동호 state
  const [stateDongHo, setStateDongHo] = useState(initDongHoState);
  const { ho, hideDong } = stateDongHo;

  const initDetailAddr = {
    addrGbn: 'N',
    streetStart: '',
    streetEnd: '',
    detailUse: 'Y',
    detailAddr: '',
  };
  // 상세주소 state
  const [stateDetailAddr, setDetailAddr] = useState(initDetailAddr);
  const { addrGbn, streetStart, streetEnd, detailUse, detailAddr } = stateDetailAddr;

  // 거래유형 가격state
  const [statePrice, setStatePrice] = useState({
    sellPrc: '',
    leasePrc: '',
    depositPrc: '',
    monthlyPrc: '',
    salePrc: '',
    premiumPrc: '',
    optionPrc: '',
  });
  const { sellPrc, leasePrc, depositPrc, monthlyPrc, salePrc, premiumPrc, optionPrc } = statePrice;

  // 입주가능일
  const [stateMoveIn, setStateMoveIn] = useState({
    moveGbn: 'A',
    isMoveChg: 'N',
  });
  const { moveGbn, isMoveChg } = stateMoveIn;

  const dongRef = useRef<HTMLButtonElement>(null);
  const hoRef = useRef<HTMLInputElement>(null);
  const streetStartRef = useRef<HTMLInputElement>(null);
  const streetEndRef = useRef<HTMLInputElement>(null);
  const detailAddrRef = useRef<HTMLInputElement>(null);

  const sellPrcRef = useRef<HTMLInputElement>(null);
  const leasePrcRef = useRef<HTMLInputElement>(null);
  const depositPrcRef = useRef<HTMLInputElement>(null);
  const monthlyPrcRef = useRef<HTMLInputElement>(null);
  const salePrcRef = useRef<HTMLInputElement>(null);
  const premiumPrcRef = useRef<HTMLInputElement>(null);
  const optionPrcRef = useRef<HTMLInputElement>(null);

  const _sellPrcRef = useRef<HTMLInputElement>(null);
  const _leasePrcRef = useRef<HTMLInputElement>(null);
  const _depositPrcRef = useRef<HTMLInputElement>(null);
  const _monthlyPrcRef = useRef<HTMLInputElement>(null);
  const _salePrcRef = useRef<HTMLInputElement>(null);
  const _premiumPrcRef = useRef<HTMLInputElement>(null);
  const _optionPrcRef = useRef<HTMLInputElement>(null);

  const lotAreaReDevRef = useRef<HTMLInputElement>(null);
  const supAreaRef = useRef<HTMLInputElement>(null);
  const conAreaRef = useRef<HTMLInputElement>(null);
  const excAreaRef = useRef<HTMLInputElement>(null);
  const lotAreaRef = useRef<HTMLInputElement>(null);
  const totAreaRef = useRef<HTMLInputElement>(null);
  const bulAreaRef = useRef<HTMLInputElement>(null);

  // 취소시
  const [isCancelEdit, setIsCancelEdit] = useState(false);

  // 거래유형 local state
  const [offerGbnState, setOfferGbnState] = useState<string>('');
  // 분양 local state
  const [parcelGbnState, setParcelGbnState] = useState<string>('A');

  // 내그룹목록
  const [mygroupList, setMyGroupList] = useState([]);

  // 고객관계리스트
  const [relationList, setRelationList] = useState([]);

  const [isTermsAgree, setIsTermsAgree] = useState(false);

  const detailRegParams = {
    sqr_cd: shareRegStep.selectedAddr.square?.sqr_cd || null,
    sup_sqr:
      RESubType === 'RB' && shareRegStep?.coagencyOptRegData?.area.lotArea
        ? shareRegStep?.coagencyOptRegData?.area.lotArea
        : RESubType === 'RB' && !shareRegStep?.coagencyOptRegData?.area.lotArea
        ? null
        : RESubType === 'OP' || RESubType === 'OB'
        ? shareRegStep.selectedAddr.square?.sup_sqr
        : shareRegStep.selectedAddr.square?.sup_sqr
        ? shareRegStep.selectedAddr.square?.sup_sqr
        : null,
    con_sqr:
      RESubType === 'OP' || RESubType === 'OB'
        ? null
        : shareRegStep.selectedAddr.square?.con_sqr
        ? shareRegStep.selectedAddr.square?.con_sqr
        : null,
    exc_sqr:
      RESubType === 'RB'
        ? null
        : shareRegStep.selectedAddr.square?.exc_sqr
        ? shareRegStep.selectedAddr.square?.exc_sqr
        : null,
    area: {
      SUP: shareRegStep?.coagencyOptRegData?.area.supArea,
      CON: shareRegStep?.coagencyOptRegData?.area.conArea,
      EXC: shareRegStep?.coagencyOptRegData?.area.excArea,
      LOT: shareRegStep?.coagencyOptRegData?.area.lotArea,
      TOT: shareRegStep?.coagencyOptRegData?.area.totArea,
      BUL: shareRegStep?.coagencyOptRegData?.area.bulArea,
    },
    floor: shareRegStep?.coagencyOptRegData?.floor,
    room_cnt: shareRegStep?.coagencyOptRegData?.room_cnt || null,
    bath_cnt: shareRegStep?.coagencyOptRegData?.bath_cnt || null,
    memo: shareRegStep?.coagencyOptRegData?.memo || null,
    detail_memo: shareRegStep?.coagencyOptRegData?.detail_memo || null,
    customer_name: shareRegStep?.coagencyOptRegData?.customer_name || null,
    owner_type: shareRegStep?.coagencyOptRegData?.owner_type?.code || null,
    phone_num: shareRegStep?.coagencyOptRegData?.phone_num,
    hidden_memo: shareRegStep?.coagencyOptRegData?.hidden_memo || null,
  };

  // btn disabled
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  console.log('stateAddr in CoagencyRegStep', stateAddr);

  const handleInputClick = (event: any) => {
    const targetElement: HTMLElement = event.target;
    // if (targetElement && !/Android/i.test(navigator.userAgent)) {
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      window.scrollBy({ top: -50, behavior: 'smooth' });
    }
  };

  /**
   * @description title
   *
   */
  useEffect(() => {
    if (title) {
      if (title === 'none') {
        setTit('');
      } else {
        setTit(title);
      }
    }
    if (!title && OfferGbn) {
      if (OfferGbn === 'S') {
        setTit('매매가를 입력해주세요.');
      } else if (OfferGbn === 'L') {
        setTit('전세가를 입력해주세요.');
      } else if (OfferGbn === 'M') {
        setTit('월세가를 입력해주세요.');
      } else if (OfferGbn === 'T') {
        setTit('단기임대가를 입력해주세요.');
      } else {
        return;
      }
    }
  }, [title]);

  /**
   * @description 수정으로 currStep으로 돌아왔을때 redux 데이터를 적용
   */
  useEffect(() => {
    if (shareRegStep.currStep === 4) {
      if (shareRegStep.editStep === 'location') {
        setStateDongHo(initDongHoState); // 초기화
      } else {
        setStateDongHo({
          ho: shareRegStep?.coagencyRegData?.ho_nm,
          hideDong: shareRegStep?.coagencyRegData?.dong_hide === 'Y' ? true : false,
          dongAddrState: shareRegStep?.selectedAddr?.dongAddr,
        });
      }
    } else if (shareRegStep.currStep === 5) {
      if (shareRegStep.editStep === 'location') {
        setDetailAddr(initDetailAddr); // 초기화
      } else {
        setDetailAddr({
          addrGbn: shareRegStep?.coagencyRegData?.addr_gbn,
          streetStart: shareRegStep?.coagencyRegData?.addr?.street_1 || '',
          streetEnd: shareRegStep?.coagencyRegData?.addr?.street_2 || '',
          detailUse: shareRegStep?.coagencyRegData?.addr?.detail_use,
          detailAddr: shareRegStep?.coagencyRegData?.addr?.detail,
        });
      }
    } else if (shareRegStep.currStep === 6) {
      setOfferGbnState(shareRegStep?.coagencyRegData?.offer_gbn);
    } else if (shareRegStep.currStep === 7) {
      setStatePrice({
        sellPrc: shareRegStep?.coagencyRegData?.sell_prc
          ? Number(shareRegStep?.coagencyRegData?.sell_prc)?.toLocaleString()
          : '',
        leasePrc: shareRegStep?.coagencyRegData?.lease_prc
          ? Number(shareRegStep?.coagencyRegData?.lease_prc)?.toLocaleString()
          : '',
        depositPrc: shareRegStep?.coagencyRegData?.deposit_prc
          ? Number(shareRegStep?.coagencyRegData?.deposit_prc)?.toLocaleString()
          : '',
        monthlyPrc: shareRegStep?.coagencyRegData?.monthly_prc
          ? Number(shareRegStep?.coagencyRegData?.monthly_prc)?.toLocaleString()
          : '',
        salePrc: shareRegStep?.coagencyRegData?.parcel_prc
          ? Number(shareRegStep?.coagencyRegData?.parcel_prc)?.toLocaleString()
          : '',
        premiumPrc: shareRegStep?.coagencyRegData?.premium_prc
          ? Number(shareRegStep?.coagencyRegData?.premium_prc)?.toLocaleString()
          : '',
        optionPrc: shareRegStep?.coagencyRegData?.parcel_option_prc
          ? Number(shareRegStep?.coagencyRegData?.parcel_option_prc)?.toLocaleString()
          : '',
      });
    } else if (shareRegStep.currStep === 8) {
      setStateMoveIn({
        moveGbn: shareRegStep?.coagencyRegData.move_gbn,
        isMoveChg: shareRegStep?.coagencyRegData.is_move_chg,
      });
    }
    setIsCancelEdit(false);
  }, [shareRegStep, isCancelEdit]);

  useEffect(() => {
    if (shareRegStep.currStep === 9 && modalType === 'coagency_price') {
      setStatePrice({
        sellPrc: shareRegStep?.coagencyRegData?.sell_prc
          ? Number(shareRegStep?.coagencyRegData?.sell_prc)?.toLocaleString()
          : '',
        leasePrc: shareRegStep?.coagencyRegData?.lease_prc
          ? Number(shareRegStep?.coagencyRegData?.lease_prc)?.toLocaleString()
          : '',
        depositPrc: shareRegStep?.coagencyRegData?.deposit_prc
          ? Number(shareRegStep?.coagencyRegData?.deposit_prc)?.toLocaleString()
          : '',
        monthlyPrc: shareRegStep?.coagencyRegData?.monthly_prc
          ? Number(shareRegStep?.coagencyRegData?.monthly_prc)?.toLocaleString()
          : '',
        salePrc: shareRegStep?.coagencyRegData?.parcel_prc
          ? Number(shareRegStep?.coagencyRegData?.parcel_prc)?.toLocaleString()
          : '',
        premiumPrc: shareRegStep?.coagencyRegData?.premium_prc
          ? Number(shareRegStep?.coagencyRegData?.premium_prc)?.toLocaleString()
          : '',
        optionPrc: shareRegStep?.coagencyRegData?.parcel_option_prc
          ? Number(shareRegStep?.coagencyRegData?.parcel_option_prc)?.toLocaleString()
          : '',
      });
      if (modalType === 'coagency_price') dispatch(ActionModal.openModal({ modalType: '', isOpen: false }));
    }
  }, [shareRegStep, modalType]);

  useEffect(() => {
    if (shareRegStep.currStep === 9 && modalType === 'coagency_bottom_price_input_register') {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              sell_prc: Number(String(statePrice?.sellPrc).replace(/,/g, '')) || '',
              lease_prc: Number(String(statePrice?.leasePrc).replace(/,/g, '')) || '',
              deposit_prc: Number(String(statePrice?.depositPrc).replace(/,/g, '')) || '',
              monthly_prc: Number(String(statePrice?.monthlyPrc).replace(/,/g, '')) || '',
              parcel_prc: Number(String(statePrice?.salePrc).replace(/,/g, '')) || '',
              premium_prc: Number(String(statePrice?.premiumPrc).replace(/,/g, '')) || '',
              parcel_option_prc: Number(String(statePrice?.optionPrc).replace(/,/g, '')) || '',
            },
          },
        }),
      );
    }
  }, [statePrice, modalType]);

  useEffect(() => {
    if (currStep >= 4) {
      const el = document.getElementById('content');
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    const abortController = new AbortController();
    const getData = async () => {
      if (currStep === 10) {
        await getMyGroupList();
        await getRelationList();
      }
    };
    getData();
    return () => abortController.abort();
  }, [currStep, groupId]);

  /**
   * @description phoneNum - 생성 side effect
   */
  useEffect(() => {
    const phoneNum = shareRegStep.coagencyOptRegData.phone_num;
    if (phoneNum?.length === 11) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              phone_num: phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
            },
          },
        }),
      );
    }
    if (phoneNum?.length === 13) {
      const _newPhoneNum = phoneNum.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              phone_num: _newPhoneNum,
            },
          },
        }),
      );
    }
  }, [shareRegStep.coagencyOptRegData.phone_num]);

  /**
   * @description 내 공유매물 등록할때 수정시 B.S에서 거래유형수정이후 가격입력부분 연속동작하도록 수정
   */
  useEffect(() => {
    if (shareRegStep.coagencyRegData?.isPriceChange) {
      setTimeout(() => {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_bottom_price_input_register',
            data: shareRegStep.coagencyRegData,
            isOpen: true,
          }),
        );
      }, 500);
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              isPriceChange: false,
            },
          },
        }),
      );
    }
  }, [shareRegStep.coagencyRegData?.isPriceChange]);

  /**
   * @description 내 모임목록 가져오기
   * @returns {Promise<void>}
   */
  const getMyGroupList = async (): Promise<void> => {
    try {
      const resAddr = await network().coagency().getCommonList(Config.API_URL.GET_OFFERS_MYGROUP);
      const { data, status } = resAddr;
      if (status === 200) {
        let _data = data;
        const defaultShare = {
          id: 99999,
          group_id: 99999,
          name: '전체 공유 (공동중개 게시판)',
          code: 'P',
        };
        _data = [defaultShare, ..._data];

        const _shares = !groupId
          ? _.uniqBy([defaultShare], 'id')
          : [
              {
                code: '',
                group_id: undefined,
                ...data.find((item: IMixedKeyValue) => item.id === Number(groupId)),
              },
            ];

        setMyGroupList(_data);
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              coagencyRegData: {
                ...shareRegStep.coagencyRegData,
                shares: _shares,
              },
            },
          }),
        );
      }
    } catch (err) {
      console.error('err', err);
      if (err) {
        setMyGroupList([]);
      }
    }
  };

  /**
   * @description 소유자 관계목록
   * @returns {Promise<void>}
   */
  const getRelationList = async (): Promise<void> => {
    try {
      const resAddr = await network().coagency().getCommonList(Config.API_URL.GET_OWNER_TYPE);
      const { data, status } = resAddr;
      if (status === 200) {
        let _data = data.map((item: IMixedKeyValue) => ({
          name: item.name,
          code: item.code,
        }));
        _data = [{ code: '', name: '선택' }, ..._data];
        setRelationList(_data);
      }
    } catch (err) {
      console.error('err', err);
      if (err) {
        setRelationList([]);
      }
    }
  };

  /**
   * @description 층수 입력시 숫자(1이상) or null 입력되도록 처리
   * @param {string} value
   * @param {string} type
   */
  const handleFloorChange = (value: string, type: string, max?: number): void => {
    if (max && Number(value) > max) {
      return;
    }
    const _floor = type === 'apply' && Number(value) > -99 ? Number(value) : Number(value) > 0 ? Number(value) : null;
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          coagencyOptRegData: {
            ...shareRegStep?.coagencyOptRegData,
            floor: {
              ...shareRegStep?.coagencyOptRegData?.floor,
              [type]: _floor || null,
            },
          },
        },
      }),
    );
  };

  /**
   * @description 메모입력 handler
   * @param {object} e React.syntheticEvent
   * @returns {void}
   */
  const handleMemo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'memo' && value?.length > 40) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '40자 이내로 작성해주세요.' },
          isOpen: true,
        }),
      );
      return;
    }
    if (name !== 'memo' && value?.length > 1000) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '1000자 이내로 작성해주세요.' },
          isOpen: true,
        }),
      );
      return;
    }
    if (name !== 'memo') {
      value = value?.replaceAll('<br>', '\r\n');
    }
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          coagencyOptRegData: {
            ...shareRegStep.coagencyOptRegData,
            [name]: value,
          },
        },
      }),
    );
  };

  /**
   * @description 전화번호 숫자입력 정규표현식 validataion
   * @param {object} e React.SyntheticEvent
   * @returns {void}
   */
  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const regex = /^[0-9\b -]{0,13}$/;
    const { value } = e.target;
    if (regex.test(e.target.value)) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              phone_num: value,
            },
          },
        }),
      );
    }
  };

  /***********************currStep 1 매물구분리스트 *****************************/
  /**
   * @description currStep 1 ==>  render 메물구분리스트
   */
  const renderTypeList = reTypeList?.map((item: IMixedKeyValue) => {
    return (
      <div key={item.main_cd} className={CommonStyles.division}>
        <p className={CommonStyles['sub-title']}>{item.main_nm}</p>
        <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'])}>
          {item?.offer_types?.map((k: IMixedKeyValue) => (
            <button
              key={k?.sub_cd}
              type="button"
              className={cn(CommonStyles.btn, CommonStyles.line)}
              onClick={() => {
                dispatch(
                  ActionCoagency.setStep({
                    shareRegStep: {
                      ...shareRegStep,
                      currStep: shareRegStep.currStep + 1,
                      dir: 'right',
                      mode: 'write',
                      coagencyRegData: {
                        ...shareRegStep.coagencyRegData,
                        offerings_main_gbn: item.main_cd,
                        offerings_gbn: k.sub_cd,
                      },
                      selectedAddr: initAddr,
                    },
                  }),
                );
              }}
            >
              {k?.sub_nm}
            </button>
          ))}
        </div>
      </div>
    );
  });

  /***********************currStep 4 동/호 *****************************/
  /**
   * @description 동선택 모달 open
   * @returns {void}
   */
  const handleSelectDong = (): void => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_select_dong_select',
        data: selectedAddr?.complexAddr?.dong,
        isOpen: true,
      }),
    );
  };

  /**
   * @description 동/호정보 입력 및 동/호 미노출 선택
   * @returns {void}
   */
  const handleSetDongHo = (): void => {
    if (!selectedAddr?.dongAddr?.dong_cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '동을 선택해주세요.' },
          isOpen: true,
        }),
      );
      dongRef.current?.focus();
      return;
    }
    if (!ho) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '호를 입력해주세요.' },
          isOpen: true,
        }),
      );
      hoRef.current?.focus();
      return;
    }

    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: REMainType === 'A' && RESubType !== 'RB' ? shareRegStep.currStep + 2 : shareRegStep.currStep + 1,
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            dong_cd: selectedAddr?.dongAddr?.dong_cd,
            dong_hide: hideDong ? 'Y' : 'N',
            ho_nm: ho,
          },
        },
      }),
    );
  };

  /**
   * @description value change
   * @param {object} e React.SyntheticEvent
   * @returns {Promise<void>}
   */
  const handleValueChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    let { value } = e.target;
    const onlyNumber = /^[0-9]*$/;

    if ((name === 'streetStart' || name === 'streetEnd') && onlyNumber.test(value)) {
      value = e.target.value.slice(0, 5);
      setDetailAddr({
        ...stateDetailAddr,
        [name]: value,
      });
    } else if (name === 'detailAddr') {
      value = e.target.value.slice(0, 100);
      setDetailAddr({
        ...stateDetailAddr,
        [name]: value,
      });
    } else if (name === 'ho' && onlyNumber.test(value)) {
      value = e.target.value.slice(0, 10);
      setStateDongHo({
        ...stateDongHo,
        [name]: value,
      });
    } else if (name === 'customer_name' || name === 'phone_num' || name === 'hidden_memo') {
      value = e.target.value.slice(0, 10);
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              [name]: value,
              owner_type: name === 'customer_name' && !value ? {} : shareRegStep?.coagencyOptRegData?.owner_type,
            },
          },
        }),
      );
      if ((name === 'customer_name' || name === 'phone_num') && !value) {
        setIsTermsAgree(false);
      }
    }
  };

  /**
   * @description 동/호 수정 취소
   * @returns {void}
   */
  const handleDonghoEditCancel = (): void => {
    // 여기는 validation처리함
    if (!selectedAddr?.dongAddr?.dong_cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '동을 선택해주세요.' },
          isOpen: true,
        }),
      );
      dongRef.current?.focus();
      return;
    }
    if (!ho) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '호를 입력해주세요.' },
          isOpen: true,
        }),
      );
      hoRef.current?.focus();
      return;
    }
    // 취소시 기존 state 데이터 복원
    setStateDongHo({
      ho: shareRegStep.coagencyRegData.ho_nm,
      hideDong: shareRegStep.coagencyRegData.dong_hide === 'Y' ? true : false,
      dongAddrState: shareRegStep.selectedAddr.dongAddr,
    });
    // 취소시 기존 redux데이터를 그대로 유지하고 summary단계로 이동
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          selectedAddr: {
            ...shareRegStep.selectedAddr,
          },
        },
      }),
    );
  };

  /**
   * @description 동/호 수정 완료
   * @returns {void}
   */
  const handleEditDongHoComplete = (): void => {
    console.log('edit complete');
    if (!selectedAddr?.dongAddr?.dong_cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '동을 선택해주세요.' },
          isOpen: true,
        }),
      );
      dongRef.current?.focus();
      return;
    }
    if (!ho) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '호를 입력해주세요.' },
          isOpen: true,
        }),
      );
      hoRef.current?.focus();
      return;
    }
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          selectedAddr: {
            ...selectedAddr,
            sidoAddr: shareRegStep?.editStep === 'location' ? stateAddr?.sidoAddr : shareRegStep.selectedAddr.sidoAddr,
            guGunAddr:
              shareRegStep?.editStep === 'location' ? stateAddr?.guGunAddr : shareRegStep.selectedAddr.guGunAddr,
            eupMyeonDongAddr:
              shareRegStep?.editStep === 'location'
                ? stateAddr?.eupMyeonDongAddr
                : shareRegStep.selectedAddr.eupMyeonDongAddr,
            liAddr: shareRegStep?.editStep === 'location' ? stateAddr?.liAddr : shareRegStep.selectedAddr.liAddr,
            complexAddr:
              shareRegStep?.editStep === 'location' ? stateAddr?.complexAddr : shareRegStep.selectedAddr.complexAddr,
          },
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            dong_cd: selectedAddr?.dongAddr?.dong_cd,
            dong_hide: hideDong ? 'Y' : 'N',
            ho_nm: ho,
          },
        },
      }),
    );
  };

  /**
   * @description currStep4 동/호
   */
  const renderDongHo = (
    <div className={CommonStyles.division}>
      <div className={CommonStyles['insert-item']}>
        <p className={CommonStyles['sub-title']}>동/호 정보</p>
        <div className={cn(CommonStyles['d-flex'], ResetStyles['d-flex'], CommonStyles.half, ResetStyles.half)}>
          <button ref={dongRef} type="button" className={CommonStyles.dropdown} onClick={handleSelectDong}>
            {selectedAddr?.dongAddr?.dong_cd ? (
              <span>{`${selectedAddr?.dongAddr?.dong_name} 동`}</span>
            ) : (
              <>
                <span>선택</span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </>
            )}
          </button>
          <div className={CommonStyles['input-area']}>
            <input
              onClick={handleInputClick}
              type="tel"
              placeholder="(비공개)"
              name="ho"
              value={ho || ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
              }}
              onChange={(e) => handleValueChange(e)}
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={10}
            />
            <span className={CommonStyles.unit}>호</span>
          </div>
        </div>
        <div className={CommonStyles['check-box']}>
          <input
            ref={hoRef}
            type="checkbox"
            id="chk1"
            checked={hideDong}
            onChange={(e) => setStateDongHo({ ...stateDongHo, hideDong: e.target.checked })}
          />
          <label htmlFor="chk1" className={CommonStyles['label-info']}>
            동 비노출
          </label>
        </div>
      </div>
      {shareRegStep.mode === 'write' ? (
        <div
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          style={
            import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
          }
        >
          <button type="button" className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleSetDongHo}>
            확인
          </button>
        </div>
      ) : (
        <div
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          style={
            import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
          }
        >
          <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleDonghoEditCancel}>
            수정 취소
          </button>
          <button
            className={cn(
              CommonStyles.btn,
              CommonStyles.lg,
              (!shareRegStep?.selectedAddr.dongAddr?.dong_cd || !ho) && CommonStyles?.disabled,
            )}
            onClick={handleEditDongHoComplete}
            disabled={!shareRegStep?.selectedAddr?.dongAddr?.dong_cd || !ho}
          >
            수정 완료
          </button>
        </div>
      )}
    </div>
  );

  /***********************currStep 5 상세주소 *****************************/
  /**
   * @description 상세정보입력 및 상세주소 없음 선택
   * @returns {void}
   */
  const handleSetDetailAddr = (): void => {
    if (!streetStart) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '본번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      streetStartRef.current?.focus();
      return;
    }
    if (!streetEnd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '부번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      streetEndRef.current?.focus();
      return;
    }
    if (detailUse === 'Y' && !detailAddr) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '상세주소를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      detailAddrRef.current?.focus();
      return;
    }

    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: shareRegStep.currStep + 1,
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            addr_gbn: addrGbn,
            addr: {
              street_1: streetStart,
              street_2: streetEnd,
              detail_use: detailUse,
              detail: detailAddr,
            },
          },
        },
      }),
    );
  };

  /**
   * @description 수정 취소
   * @returns {void}
   */
  const handleDetailAddrEditCancel = (): void => {
    console.log('detail addr edit cancel');
    if (!streetStart) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '본번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      streetStartRef.current?.focus();
      return;
    }
    if (!streetEnd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '부번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      streetEndRef.current?.focus();
      return;
    }
    if (detailUse === 'Y' && !detailAddr) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '상세주소를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      detailAddrRef.current?.focus();
      return;
    }
    setIsCancelEdit(true);
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
        },
      }),
    );
  };

  const handleDetailAddrEditComplete = () => {
    if (!streetStart) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '본번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      streetStartRef.current?.focus();
      return;
    }
    if (!streetEnd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '부번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      streetEndRef.current?.focus();
      return;
    }
    if (detailUse === 'Y' && !detailAddr) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '상세주소를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      detailAddrRef.current?.focus();
      return;
    }
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            addr_gbn: addrGbn,
            addr: {
              street_1: streetStart,
              street_2: streetEnd,
              detail_use: detailUse,
              detail: detailAddr,
            },
          },
        },
      }),
    );
  };

  /**
   * @description currStep 5 상세주소
   */
  const renderDetailAddr = (
    <>
      <div className={cn(CommonStyles['insert-item'], CommonStyles['insert-address'])}>
        <div className={CommonStyles['btn-wrap']}>
          <button
            type="button"
            className={cn(CommonStyles.btn, addrGbn !== 'N' && CommonStyles.line)}
            onClick={() => setDetailAddr({ ...stateDetailAddr, addrGbn: 'N' })}
          >
            일반
          </button>
          <button
            type="button"
            className={cn(CommonStyles.btn, addrGbn !== 'S' && CommonStyles.line)}
            onClick={() => setDetailAddr({ ...stateDetailAddr, addrGbn: 'S' })}
          >
            산
          </button>
        </div>
        <div className={CommonStyles['input-div']}>
          <div className={CommonStyles['input-area']}>
            <input
              onClick={handleInputClick}
              ref={streetStartRef}
              type="tel"
              placeholder="(비공개)"
              name="streetStart"
              value={streetStart || ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
              }}
              onChange={(e) => handleValueChange(e)}
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={5}
            />
          </div>
          <span>-</span>
          <div className={CommonStyles['input-area']}>
            <input
              onClick={handleInputClick}
              ref={streetEndRef}
              type="tel"
              placeholder="(비공개)"
              name="streetEnd"
              value={streetEnd || ''}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
              }}
              onChange={(e) => handleValueChange(e)}
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={5}
            />
            <span className={CommonStyles.unit}>번지</span>
          </div>
        </div>
        <div className={CommonStyles['input-area']}>
          <input
            onClick={handleInputClick}
            ref={detailAddrRef}
            type="text"
            placeholder="상세주소 (비공개)"
            name="detailAddr"
            value={detailAddr || ''}
            onChange={(e) => handleValueChange(e)}
            disabled={detailUse === 'N'}
            maxLength={100}
          />
        </div>
        <div className={CommonStyles['check-box']}>
          <input
            type="checkbox"
            id="chk1"
            name="detailUse"
            value={detailUse}
            checked={detailUse === 'N'}
            onChange={(e) =>
              setDetailAddr({
                ...stateDetailAddr,
                detailUse: e.target.checked ? 'N' : 'Y',
                detailAddr: e.target.checked ? '' : detailAddr,
              })
            }
          />
          <label htmlFor="chk1" className={CommonStyles['label-info']}>
            상세주소 없음
          </label>
        </div>
      </div>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        // style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        {shareRegStep.mode === 'write' ? (
          <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleSetDetailAddr}>
            확인
          </button>
        ) : (
          <>
            <button
              className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
              onClick={handleDetailAddrEditCancel}
            >
              수정 취소
            </button>
            <button
              className={cn(
                CommonStyles.btn,
                CommonStyles.lg,
                !shareRegStep?.coagencyRegData?.addr?.street_1 &&
                  !shareRegStep?.coagencyRegData?.addr?.street_2 &&
                  shareRegStep?.coagencyRegData?.addr?.detail_use === 'Y' &&
                  !shareRegStep?.coagencyRegData?.addr?.detail &&
                  CommonStyles?.disabled,
              )}
              onClick={handleDetailAddrEditComplete}
              disabled={
                !shareRegStep?.coagencyRegData?.addr?.street_1 &&
                !shareRegStep?.coagencyRegData?.addr?.street_2 &&
                shareRegStep?.coagencyRegData?.addr?.detail_use === 'Y' &&
                !shareRegStep?.coagencyRegData?.addr?.detail
              }
            >
              수정 완료
            </button>
          </>
        )}
      </div>
    </>
  );

  /***********************currStep 5 분양권구분 *****************************/

  /**
   * @description 분양권 구분 handler
   * @param {string} type 'A' or 'B'
   */
  const handleForSaleRight = (type: string) => {
    if (shareRegStep.mode === 'write') {
      setParcelGbnState(type);
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: shareRegStep.currStep + 1,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              parcel_gbn: type,
            },
          },
        }),
      );
    } else {
      setParcelGbnState(type);
    }
  };

  /**
   * @description 분양권 종류 수정 취소
   * @returns {void}
   */
  const handleParcelEditCancel = () => {
    console.log('edit parcel cancel');
    setIsCancelEdit(true);
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            parcel_gbn: shareRegStep.coagencyRegData?.parcel_gbn,
          },
        },
      }),
    );
  };

  /**
   * @description 분양권 종류 수정 완료
   * @returns {void}
   */
  const handleParcelEditComplete = () => {
    console.log('edit parcel complete');
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            parcel_gbn: parcelGbnState,
          },
        },
      }),
    );
  };

  /**
   * @description 분양권 구분
   */
  const renderForSaleRight = (
    <>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles.full)}>
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg, parcelGbnState !== 'A' && CommonStyles.line)}
          onClick={() => handleForSaleRight('A')}
        >
          일반분양
        </button>
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg, parcelGbnState !== 'B' && CommonStyles.line)}
          onClick={() => handleForSaleRight('B')}
        >
          조합원분양
        </button>
      </div>
      {shareRegStep.mode === 'edit' && (
        <div
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          style={
            import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
          }
        >
          <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleParcelEditCancel}>
            수정 취소
          </button>
          <button
            className={cn(CommonStyles.btn, CommonStyles.lg, !OfferGbn && CommonStyles.disabled)}
            onClick={handleParcelEditComplete}
            disabled={!OfferGbn}
          >
            수정 완료
          </button>
        </div>
      )}
    </>
  );

  /***********************currStep 6 거래유형 *****************************/
  /**
   * @description 거래유형 선택
   * @param {string } val S, M, L, T
   * @returns {void}
   */
  const handlesetOfferGbnState = (val: string): void => {
    if (shareRegStep.mode === 'write') {
      setOfferGbnState(val);
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: shareRegStep.currStep + 1,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              offer_gbn: val,
            },
          },
        }),
      );
    } else {
      setOfferGbnState(val);
    }
  };

  /**
   * @description 거래유형 수정 취소
   * @returns {void}
   */
  const handleOfferEditCancel = (): void => {
    console.log('edit cancel offer_gbn1');
    setIsCancelEdit(true);
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            offer_gbn: shareRegStep.coagencyRegData?.offer_gbn,
          },
        },
      }),
    );
  };

  /**
   * @description 거래유형변경시 confirm
   * @returns {void}
   */
  const handleOfferEditConfirm = () => {
    if (offerGbnState !== shareRegStep.coagencyRegData.offer_gbn) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_offer_edit_confirm',
          action: () => handleOfferEditComplete(),
          isOpen: true,
        }),
      );
      return;
    }
    handleOfferEditComplete();
  };

  /**
   * @description 거래유형 다음
   * @returns {void}
   */
  const handleOfferEditComplete = (): void => {
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: currStep + 1, // 다음 가격입력으로 이동함.
          editStep: '',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            offer_gbn: offerGbnState,
            sell_prc: null, // 가격초기화
            lease_prc: null,
            deposit_prc: null,
            monthly_prc: null,

            parcel_prc: null,
            premium_prc: null,
            parcel_option_prc: null,
          },
        },
      }),
    );
  };

  /**
   * @description currStep 6 거래유형
   */
  const renderSaleType = (
    <>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles.full)}>
        {REMainType === 'D'
          ? Config.COAGENCY_REG_OFFER_GBN.filter((p) => p.value !== 'S').map((item) => {
              return (
                <button
                  key={item.id}
                  type="button"
                  className={cn(CommonStyles.btn, CommonStyles.lg, offerGbnState !== item.value && CommonStyles.line)}
                  onClick={() => handlesetOfferGbnState(item.value)}
                >
                  {item.label}
                </button>
              );
            })
          : RESubType !== 'RB'
          ? Config.COAGENCY_REG_OFFER_GBN.map((item) => {
              return (
                <button
                  key={item.id}
                  type="button"
                  className={cn(CommonStyles.btn, CommonStyles.lg, offerGbnState !== item.value && CommonStyles.line)}
                  onClick={() => handlesetOfferGbnState(item.value)}
                >
                  {item.label}
                </button>
              );
            })
          : Config.COAGENCY_REG_OFFER_GBN.filter((p) => p.value === 'S').map((item) => {
              return (
                <button
                  key={item.id}
                  type="button"
                  className={cn(CommonStyles.btn, CommonStyles.lg, offerGbnState !== item.value && CommonStyles.line)}
                  onClick={() => handlesetOfferGbnState(item.value)}
                >
                  {item.label}
                </button>
              );
            })}
      </div>
      {shareRegStep.mode === 'edit' && (
        <div
          className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
          // style={
          //   import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
          // }
        >
          <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleOfferEditCancel}>
            수정 취소
          </button>
          <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleOfferEditConfirm}>
            수정 완료
          </button>
        </div>
      )}
    </>
  );

  /**
   * @description 가격저장 후 next step
   * @returns {void}
   */
  const handlePriceSave = (): void => {
    if (REMainType !== 'B' && OfferGbn === 'S' && (!sellPrc || Number(sellPrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '매매가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return sellPrcRef.current?.focus();
    }

    if (REMainType !== 'B' && OfferGbn === 'L' && (!leasePrc || Number(leasePrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '전세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return leasePrcRef.current?.focus();
    }

    if (REMainType !== 'B' && (OfferGbn === 'M' || OfferGbn === 'T') && (depositPrc === '' || depositPrc === null)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '보증금을 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return depositPrcRef.current?.focus();
    }

    if (REMainType !== 'B' && (OfferGbn === 'M' || OfferGbn === 'T') && (!monthlyPrc || Number(monthlyPrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '월세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return monthlyPrcRef.current?.focus();
    }

    if (REMainType === 'B' && OfferGbn === 'S' && (!salePrc || Number(salePrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '분양가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return salePrcRef.current?.focus();
    }

    if (REMainType === 'B') {
      if (OfferGbn === 'S' && (premiumPrc === '' || optionPrc === '')) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '분양옵션가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return optionPrcRef.current?.focus();
      }

      if (OfferGbn === 'L' && !leasePrc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '전세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return leasePrcRef.current?.focus();
      }

      if ((OfferGbn === 'M' || OfferGbn === 'T') && (depositPrc === '' || depositPrc === null)) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '보증금을 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return depositPrcRef.current?.focus();
      }

      if ((OfferGbn === 'M' || OfferGbn === 'T') && !monthlyPrc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '월세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return monthlyPrcRef.current?.focus();
      }
    }
    batch(() => {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_price',
          isOpen: false,
        }),
      );
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: shareRegStep.currStep + 1,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              sell_prc: sellPrc ? Number(String(sellPrc).replace(/,/g, '')) : null,
              lease_prc: leasePrc ? Number(String(leasePrc).replace(/,/g, '')) : null,
              deposit_prc: depositPrc ? Number(String(depositPrc).replace(/,/g, '')) : null,
              monthly_prc: monthlyPrc ? Number(String(monthlyPrc).replace(/,/g, '')) : null,

              parcel_prc: salePrc ? Number(String(salePrc).replace(/,/g, '')) : null,
              premium_prc: premiumPrc ? Number(String(premiumPrc).replace(/,/g, '')) : null,
              parcel_option_prc: optionPrc ? Number(String(optionPrc).replace(/,/g, '')) : null,
            },
          },
        }),
      );
    });
  };

  /***********************currStep 7 거래유형에 따른 가격 입력 *****************************/

  /**
   * @description 숫자 입력시 천단위마다 comma처리하여 표시
   * @param {string} value
   * @param {string} type
   * @param {string} zero 0값 허용(allow) / 불허(deny)
   * @param {string} negative 음수값 허용(allow) / 불허(deny)
   * @returns {void}
   */
  const handleNumberChange = (
    value: string,
    type: string,
    inputType?: string,
    zero?: 'allow' | 'deny',
    negative?: 'allow' | 'deny',
  ): void => {
    let _value = value;
    const _zero = zero ?? 'deny';
    const _negative = negative ?? 'deny';

    _value = rmComma(value);
    if (_value === '0' && _zero === 'deny') {
      value = '';
    } else if (_value === '-' && _negative === 'deny') {
      value = '';
    } else if (_value !== '' && _value !== '-') {
      value = Number(_value).toLocaleString('ko-KR');
    }
    if (
      type === 'supArea' ||
      type === 'conArea' ||
      type === 'excArea' ||
      type === 'lotArea' ||
      type === 'totArea' ||
      type === 'bulArea'
    ) {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              area: {
                ...shareRegStep.coagencyOptRegData.area,
                [type]: value,
              },
            },
          },
        }),
      );
    } else if (type === 'room_cnt' || type === 'bath_cnt') {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              [type]: value,
            },
          },
        }),
      );
    } else if (inputType === 'direct') {
      setStatePrice({
        ...statePrice,
        [type]: value,
      });
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              sell_prc: type === 'sellPrc' ? value : null,
              lease_prc: type === 'leasePrc' ? value : null,
              deposit_prc: type === 'depositPrc' ? value : null,
              monthly_prc: type === 'monthlyPrc' ? value : null,
              parcel_prc: type === 'salePrc' ? value : null,
              premium_prc: type === 'premiumPrc' ? value : null,
              parcel_option_prc: type === 'optionPrc' ? value : null,
            },
          },
        }),
      );
    } else {
      setStatePrice({
        ...statePrice,
        [type]: value,
      });
    }
  };

  /**
   * @description 가격 수정취소
   * @returns {void}
   */
  const handlePriceEditCancel = (): void => {
    console.log('가격수정 취소 기존 값 복원');
    setIsCancelEdit(true);
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
        },
      }),
    );
  };

  /**
   * @description 가격 수정 완료
   * @returns {void}
   */
  const handlePriceEditComplete = (): void => {
    console.log('가격수정 완료');

    if (REMainType !== 'B' && OfferGbn === 'S' && (!sellPrc || Number(sellPrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '매매가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return sellPrcRef.current?.focus();
    }

    if (REMainType !== 'B' && OfferGbn === 'L' && (!leasePrc || Number(leasePrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '전세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return leasePrcRef.current?.focus();
    }

    if (REMainType !== 'B' && (OfferGbn === 'M' || OfferGbn === 'T') && (depositPrc === '' || depositPrc === null)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '보증금을 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return depositPrcRef.current?.focus();
    }

    if (REMainType !== 'B' && (OfferGbn === 'M' || OfferGbn === 'T') && (!monthlyPrc || Number(monthlyPrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '월세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return monthlyPrcRef.current?.focus();
    }

    if (REMainType === 'B' && OfferGbn === 'S' && (!salePrc || Number(salePrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '분양가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return salePrcRef.current?.focus();
    }

    if (REMainType === 'B') {
      if (OfferGbn === 'S' && (premiumPrc === '' || optionPrc === '')) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '분양옵션가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return optionPrcRef.current?.focus();
      }

      if (OfferGbn === 'L' && !leasePrc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '전세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return leasePrcRef.current?.focus();
      }

      if ((OfferGbn === 'M' || OfferGbn === 'T') && (depositPrc === '' || depositPrc === null)) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '보증금을 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return depositPrcRef.current?.focus();
      }

      if ((OfferGbn === 'M' || OfferGbn === 'T') && !monthlyPrc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '월세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return monthlyPrcRef.current?.focus();
      }
    }

    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            sell_prc: sellPrc ? Number(String(sellPrc).replace(/,/g, '')) : null,
            lease_prc: leasePrc ? Number(String(leasePrc).replace(/,/g, '')) : null,
            deposit_prc: depositPrc ? Number(String(depositPrc).replace(/,/g, '')) : null,
            monthly_prc: monthlyPrc ? Number(String(monthlyPrc).replace(/,/g, '')) : null,

            parcel_prc: salePrc ? Number(String(salePrc).replace(/,/g, '')) : null,
            premium_prc: premiumPrc ? Number(String(premiumPrc).replace(/,/g, '')) : null,
            parcel_option_prc: optionPrc ? Number(String(optionPrc).replace(/,/g, '')) : null,
          },
        },
      }),
    );
  };

  /**
   * @description 거래유형에 따른 가격 입력
   */
  const renderPriceInput = (
    <>
      <section className={CommonStyles.p0}>
        {/* 매매가 */}
        {OfferGbn === 'S' && REMainType !== 'B' && (
          <div className={CommonStyles['insert-item']}>
            <div className={CommonStyles['input-area']}>
              <input
                onClick={handleInputClick}
                ref={sellPrcRef}
                type="tel"
                name="sellPrc"
                value={sellPrc || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                onChange={(e) => handleNumberChange(e.target.value, 'sellPrc')}
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={10}
              />
              <span className={CommonStyles.unit}>만원</span>
            </div>
            <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
              [금액:
              <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                {/* {sellPrc ? numTohan(Number(String(sellPrc).replace(/,/g, '')) * 10000) : '0'} */}
                {sellPrc ? formatNumber(Number(String(sellPrc).replace(/,/g, ''))) : '0'}
              </b>
              원]
            </div>
          </div>
        )}

        {/* 전세가 */}
        {OfferGbn === 'L' && (
          <div className={CommonStyles['insert-item']}>
            <div className={CommonStyles['input-area']}>
              <input
                onClick={handleInputClick}
                ref={leasePrcRef}
                type="tel"
                name="leasePrc"
                value={leasePrc || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                onChange={(e) => handleNumberChange(e.target.value, 'leasePrc')}
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={10}
              />
              <span className={CommonStyles.unit}>만원</span>
            </div>
            <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
              [금액:
              <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                {leasePrc ? formatNumber(Number(String(leasePrc).replace(/,/g, ''))) : '0'}
              </b>
              원]
            </div>
          </div>
        )}

        {/* 월세가 / 단기임대가 */}
        {(OfferGbn === 'M' || OfferGbn === 'T') && (
          <>
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>보증금</p>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  ref={depositPrcRef}
                  type="tel"
                  name="depositPrc"
                  value={depositPrc || ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'depositPrc')}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={10}
                />
                <span className={CommonStyles.unit}>만원</span>
              </div>
              <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                  {depositPrc ? formatNumber(Number(String(depositPrc).replace(/,/g, ''))) : '0'}
                </b>
                원]
              </div>
            </div>
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>월세</p>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  ref={monthlyPrcRef}
                  type="tel"
                  value={monthlyPrc || ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'monthlyPrc')}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={10}
                />
                <span className={CommonStyles.unit}>만원</span>
              </div>
              <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                  {monthlyPrc ? formatNumber(Number(String(monthlyPrc).replace(/,/g, ''))) : '0'}
                </b>
                원]
              </div>
            </div>
          </>
        )}

        {/* 분양시 매매가 */}
        {OfferGbn === 'S' && REMainType === 'B' && (
          <>
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>분양가</p>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  ref={salePrcRef}
                  type="tel"
                  name="salePrc"
                  value={salePrc || ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'salePrc')}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={10}
                />
                <span className={CommonStyles.unit}>만원</span>
              </div>
              <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                  {salePrc ? formatNumber(Number(String(salePrc).replace(/,/g, ''))) : '0'}
                </b>
                원]
              </div>
            </div>
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>프리미엄분양가</p>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  ref={premiumPrcRef}
                  type="tel"
                  name="premiumPrc"
                  value={premiumPrc || ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'premiumPrc')}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={10}
                />
                <span className={CommonStyles.unit}>만원</span>
              </div>
              <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                  {premiumPrc ? formatNumber(Number(String(premiumPrc).replace(/,/g, ''))) : '0'}
                </b>
                원]
              </div>
            </div>
            <div className={CommonStyles['insert-item']} style={{ marginBottom: 100 }}>
              <p className={CommonStyles['sub-title']}>분양옵션가</p>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  ref={optionPrcRef}
                  type="tel"
                  name="optionPrc"
                  value={optionPrc || ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'optionPrc')}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={10}
                />
                <span className={CommonStyles.unit}>만원</span>
              </div>
              <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                  {optionPrc ? formatNumber(Number(String(optionPrc).replace(/,/g, ''))) : '0'}
                </b>
                원]
              </div>
            </div>
          </>
        )}
      </section>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        {shareRegStep.mode === 'write' && (
          <button type="button" className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handlePriceSave}>
            확인
          </button>
        )}
        {shareRegStep.mode === 'edit' && (
          <div
            className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
            style={
              import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
            }
          >
            <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handlePriceEditCancel}>
              수정 취소
            </button>
            <button
              className={cn(
                CommonStyles.btn,
                CommonStyles.lg,
                (OfferGbn === 'S' && REMainType !== 'B' && !sellPrc) ||
                  (OfferGbn === 'L' && !leasePrc) ||
                  ((OfferGbn === 'M' || OfferGbn === 'T') && (!depositPrc || !monthlyPrc)) ||
                  (OfferGbn === 'S' && REMainType === 'B' && (!salePrc || !premiumPrc || !optionPrc))
                  ? CommonStyles.disabled
                  : '',
              )}
              onClick={handlePriceEditComplete}
              disabled={
                (OfferGbn === 'S' && REMainType !== 'B' && !sellPrc) ||
                (OfferGbn === 'L' && !leasePrc) ||
                ((OfferGbn === 'M' || OfferGbn === 'T') && (!depositPrc || !monthlyPrc)) ||
                (OfferGbn === 'S' && REMainType === 'B' && (!salePrc || !premiumPrc || !optionPrc))
              }
            >
              수정 완료
            </button>
          </div>
        )}
      </div>
    </>
  );

  /***********************currStep 8 입주가능일 *****************************/
  const handleMoveInDay = () => {
    console.warn('handleMoveInDay');
    if (shareRegStep.mode === 'write') {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            currStep: shareRegStep.currStep + 1,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              move_gbn: moveGbn,
              is_move_chg: isMoveChg,
            },
          },
        }),
      );
    }
  };

  /**
   * @description 입주가능일 수정취소
   * @returns {void}
   */
  const handleMoveInEditCancel = (): void => {
    console.log('입주가능일 수정 취소');
    setIsCancelEdit(true);
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
        },
      }),
    );
  };

  /**
   * @description 입주가능일 수정 완료
   * @returns {void}
   */
  const handleMoveInEditComplete = (): void => {
    console.log('입주가능일 수정 완료');
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: 9,
          editStep: '',
          mode: 'write',
          coagencyRegData: {
            ...shareRegStep.coagencyRegData,
            move_gbn: moveGbn,
            is_move_chg: isMoveChg,
          },
        },
      }),
    );
  };

  /**
   * @description 입주가능일 선택
   */
  const renderMoveinDate = (
    <>
      <div className={cn(CommonStyles['button-list'], CommonStyles.p0)}>
        <button className={CommonStyles['radio-box']}>
          <input
            type="radio"
            id="moveGbn_A"
            name="moveGbn"
            value="A"
            checked={moveGbn === 'A'}
            onChange={(e) =>
              setStateMoveIn({
                ...stateMoveIn,
                moveGbn: e.target.value,
              })
            }
          />
          <label htmlFor="moveGbn_A" className={CommonStyles['label-info']}>
            즉시입주
          </label>
        </button>
        <button className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])} aria-expanded={true}>
          <input
            type="radio"
            id="moveGbn_B"
            name="moveGbn"
            value="S"
            checked={['S', 'B', 'C', 'D'].includes(moveGbn)}
            onChange={(e) =>
              setStateMoveIn({
                ...stateMoveIn,
                moveGbn: e.target.value,
              })
            }
          />
          <label htmlFor="moveGbn_B" className={CommonStyles['label-info']}>
            입주일지정
          </label>
        </button>

        {['S', 'B', 'C', 'D'].includes(moveGbn) && (
          <button
            className={CommonStyles['calendar-btn']}
            aria-label="option-calendar"
            onClick={() =>
              dispatch(
                ActionModal.openModal({
                  modalType: 'coagency_bottom_movein_calendar',
                  isOpen: true,
                }),
              )
            }
          >
            {['B', 'C', 'D'].includes(shareRegStep.coagencyRegData.move_gbn)
              ? `${moment(shareRegStep.coagencyRegData.move_ymd).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                  (item) => item.cd === shareRegStep.coagencyRegData.move_gbn,
                )?.name}`
              : moment(shareRegStep.coagencyRegData.move_ymd).format('YYYY-MM-DD')}
          </button>
        )}

        <button
          className={cn(CommonStyles['check-box'], CommonStyles.revert, CommonStyles['border-top'], CommonStyles.mt24)}
        >
          <input
            type="checkbox"
            id="moveGbn_checkbox"
            name="moveGbn_checkbox"
            checked={isMoveChg === 'Y'}
            onChange={(e) =>
              setStateMoveIn({
                ...stateMoveIn,
                isMoveChg: e.target.checked ? 'Y' : 'N',
              })
            }
          />
          <label htmlFor="moveGbn_checkbox" className={CommonStyles['label-info']}>
            입주가능일 협의
          </label>
        </button>
      </div>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        {shareRegStep.mode === 'write' && (
          <button type="button" className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleMoveInDay}>
            확인
          </button>
        )}
        {shareRegStep.mode === 'edit' && (
          <div
            className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
            style={
              import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}
            }
          >
            <button
              className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
              onClick={handleMoveInEditCancel}
            >
              수정 취소
            </button>
            <button
              className={cn(CommonStyles.btn, CommonStyles.lg, !OfferGbn && CommonStyles.disabled)}
              onClick={handleMoveInEditComplete}
              disabled={!OfferGbn}
            >
              수정 완료
            </button>
          </div>
        )}
      </div>
    </>
  );

  /***********************currStep 9 입력정보체크 *****************************/
  const handleShareSettings = () => {
    console.log('공유범위 설정으로 이동전 validation');
    // 1. 소재지 validation 추가
    if (!shareRegStep?.selectedAddr.eupMyeonDongAddr.cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '소재지를 선택해 주세요.' },
          isOpen: true,
        }),
      );
      return;
    }
    // 2. 단지 정보 validation 추가
    if ((REMainType === 'A' || REMainType === 'B') && !shareRegStep?.selectedAddr.complexAddr.complex_cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '단지를 선택해 주세요.' },
          isOpen: true,
        }),
      );
      return;
    }

    // 3. 동 호 정보 validation 추가
    if (
      ((REMainType === 'A' && RESubType !== 'RB') || REMainType === 'B') &&
      !shareRegStep?.selectedAddr.complexAddr.complex_cd
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '동/호를 선택해 주세요.' },
          isOpen: true,
        }),
      );
      return;
    }

    // 4.가격
    if (
      REMainType !== 'B' &&
      OfferGbn === 'S' &&
      (!shareRegStep?.coagencyRegData?.sell_prc || Number(shareRegStep?.coagencyRegData?.sell_prc) === 0)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '매매가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return _sellPrcRef.current?.focus();
    }

    if (
      REMainType !== 'B' &&
      OfferGbn === 'L' &&
      (!shareRegStep?.coagencyRegData?.lease_prc || Number(shareRegStep?.coagencyRegData?.lease_prc) === 0)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '전세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return _leasePrcRef.current?.focus();
    }

    if (
      REMainType !== 'B' &&
      (OfferGbn === 'M' || OfferGbn === 'T') &&
      (shareRegStep?.coagencyRegData?.deposit_prc === '' || shareRegStep?.coagencyRegData?.deposit_prc === null)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '보증금을 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return _depositPrcRef.current?.focus();
    }

    if (
      REMainType !== 'B' &&
      (OfferGbn === 'M' || OfferGbn === 'T') &&
      (!shareRegStep?.coagencyRegData?.monthly_prc || Number(shareRegStep?.coagencyRegData?.monthly_prc) === 0)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '월세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return _monthlyPrcRef.current?.focus();
    }

    if (
      REMainType === 'B' &&
      OfferGbn === 'S' &&
      (!shareRegStep?.coagencyRegData?.parcel_prc || Number(shareRegStep?.coagencyRegData?.parcel_prc) === 0)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '분양가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      return _salePrcRef.current?.focus();
    }

    if (REMainType === 'B') {
      if (
        OfferGbn === 'S' &&
        (shareRegStep?.coagencyRegData?.premium_prc === '' || shareRegStep?.coagencyRegData?.parcel_option_prc === '')
      ) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '분양옵션가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return _optionPrcRef.current?.focus();
      }

      if (OfferGbn === 'L' && !shareRegStep?.coagencyRegData?.lease_prc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '전세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return _leasePrcRef.current?.focus();
      }

      if (
        (OfferGbn === 'M' || OfferGbn === 'T') &&
        (shareRegStep?.coagencyRegData?.deposit_prc === '' || shareRegStep?.coagencyRegData?.deposit_prc === null)
      ) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '보증금을 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return depositPrcRef.current?.focus();
      }

      if ((OfferGbn === 'M' || OfferGbn === 'T') && !shareRegStep?.coagencyRegData?.monthly_prc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '월세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        return _monthlyPrcRef.current?.focus();
      }
    }
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          currStep: shareRegStep.currStep + 1,
        },
      }),
    );
  };

  const renderCheckValue = (
    <>
      <div className={CommonStyles['section-div']}>
        <section className={CommonStyles['pt0']}>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물종류</p>
            <button
              className={CommonStyles['input-area']}
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'coagency_confirm_change_coagency_type',
                    isOpen: true,
                  }),
                )
              }
            >
              <input
                onClick={handleInputClick}
                className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                type="text"
                value={
                  Config.REType.find(
                    (item) => item.main_cd === shareRegStep.coagencyRegData.offerings_main_gbn,
                  )?.offer_types?.find((k) => k.sub_cd === shareRegStep.coagencyRegData.offerings_gbn)?.sub_nm
                }
                readOnly
              />
            </button>
          </div>
        </section>

        <section>
          {/* <!-- 공동 --> */}
          {(REMainType === 'A' || REMainType === 'B') && (
            <>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>소재지</p>
                <button
                  className={CommonStyles['input-area']}
                  onClick={() =>
                    dispatch(
                      ActionModal.openModal({
                        modalType: 'coagency_confirm_change_location',
                        isOpen: true,
                      }),
                    )
                  }
                >
                  <input
                    onClick={handleInputClick}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    type="text"
                    value={
                      shareRegStep?.selectedAddr?.sidoAddr?.name
                        ? `${shareRegStep?.selectedAddr?.sidoAddr?.name} ${
                            shareRegStep?.selectedAddr?.guGunAddr?.name ?? ''
                          } ${shareRegStep?.selectedAddr?.eupMyeonDongAddr?.name ?? ''} ${
                            shareRegStep?.selectedAddr?.liAddr?.name ?? ''
                          }`
                        : ''
                    }
                    readOnly
                  />
                </button>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>단지정보</p>
                <button
                  className={CommonStyles['input-area']}
                  onClick={() => dispatch(ActionCoagency.initComplex('share'))}
                >
                  <input
                    onClick={handleInputClick}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    type="text"
                    value={shareRegStep?.selectedAddr?.complexAddr?.complex_nm || ''}
                    readOnly
                  />
                </button>
              </div>
              {RESubType !== 'RB' && (
                <div className={CommonStyles['insert-item']}>
                  <p className={CommonStyles['sub-title']}>동/호 정보</p>
                  <div
                    className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}
                    // onClick={() => dispatch(ActionCoagency.initDongHo('share'))}
                    // ux이동(등록화면에서 바로처리하도록수정)
                  >
                    <button type="button" className={CommonStyles.dropdown} onClick={handleSelectDong}>
                      <span>
                        {selectedAddr?.dongAddr?.dong_name ? `${selectedAddr?.dongAddr?.dong_name} 동` : '선택'}
                      </span>
                      <i className={CommonStyles['icon-arrow-down-gr']}></i>
                    </button>
                    <button className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                        type="tel"
                        value={shareRegStep?.coagencyRegData?.ho_nm || '-'}
                        placeholder="(비공개)"
                        onChange={(e) =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyRegData: {
                                  ...shareRegStep.coagencyRegData,
                                  ho_nm: e.target.value,
                                },
                              },
                            }),
                          )
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={10}
                        // readOnly
                      />
                      <span className={CommonStyles.unit}>호</span>
                    </button>
                  </div>
                  <div className={CommonStyles['check-box']}>
                    <input
                      type="checkbox"
                      id="chk1"
                      checked={shareRegStep.coagencyRegData.dong_hide === 'Y'}
                      onChange={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                dong_hide: e.target.checked ? 'Y' : 'N',
                              },
                            },
                          }),
                        )
                      }
                    />
                    <label htmlFor="chk1" className={CommonStyles['label-info']}>
                      동 비노출
                    </label>
                  </div>
                </div>
              )}

              {RESubType === 'RB' && (
                <div
                  className={cn(CommonStyles['insert-item'], CommonStyles['insert-address'])}
                  // onClick={() => dispatch(ActionCoagency.initDetailAddr('share'))}
                >
                  <p className={CommonStyles['sub-title']}>상세주소</p>
                  <div className={CommonStyles['btn-wrap']}>
                    <button
                      type="button"
                      className={cn(
                        CommonStyles.btn,
                        CommonStyles.lg,
                        shareRegStep?.coagencyRegData?.addr_gbn !== 'N' && CommonStyles.line,
                      )}
                      onClick={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                addr_gbn: 'N',
                              },
                            },
                          }),
                        )
                      }
                    >
                      일반
                    </button>
                    <button
                      type="button"
                      className={cn(
                        CommonStyles.btn,
                        CommonStyles.lg,
                        shareRegStep?.coagencyRegData?.addr_gbn !== 'S' && CommonStyles.line,
                      )}
                      onClick={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                addr_gbn: 'S',
                              },
                            },
                          }),
                        )
                      }
                    >
                      산
                    </button>
                  </div>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="te1"
                        value={shareRegStep?.coagencyRegData?.addr?.street_1 || ''}
                        placeholder="(비공개)"
                        onChange={(e) =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyRegData: {
                                  ...shareRegStep.coagencyRegData,
                                  addr: {
                                    ...shareRegStep.coagencyRegData.addr,
                                    street_1: e.target.value,
                                  },
                                },
                              },
                            }),
                          )
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={5}
                      />
                    </div>
                    <span>-</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="te1"
                        value={shareRegStep?.coagencyRegData?.addr?.street_2 || ''}
                        placeholder="(비공개)"
                        onChange={(e) =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyRegData: {
                                  ...shareRegStep.coagencyRegData,
                                  addr: {
                                    ...shareRegStep.coagencyRegData.addr,
                                    street_2: e.target.value,
                                  },
                                },
                              },
                            }),
                          )
                        }
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={5}
                      />
                      <span className={CommonStyles.unit}>번지</span>
                    </div>
                  </div>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="text"
                      value={shareRegStep?.coagencyRegData?.addr?.detail || ''}
                      placeholder="상세주소 (비공개)"
                      disabled={shareRegStep?.coagencyRegData?.addr?.detail_use === 'N'}
                      onChange={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                addr: {
                                  ...shareRegStep.coagencyRegData.addr,
                                  detail: e.target.value,
                                },
                              },
                            },
                          }),
                        )
                      }
                    />
                  </div>
                  <div className={CommonStyles['check-box']}>
                    <input
                      type="checkbox"
                      id="chk2"
                      checked={shareRegStep?.coagencyRegData?.addr?.detail_use === 'N'}
                      onChange={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                addr: {
                                  ...shareRegStep.coagencyRegData.addr,
                                  detail_use: e.target.checked ? 'N' : 'Y',
                                },
                              },
                            },
                          }),
                        )
                      }
                    />
                    <label htmlFor="chk2" className={CommonStyles['label-info']}>
                      상세주소 없음
                    </label>
                  </div>
                </div>
              )}
            </>
          )}
          {/* <!-- // 공동 --> */}

          {/* <!-- 비공동 --> */}
          {REMainType === 'A' || REMainType === 'B' || (
            <>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>소재지</p>
                <button
                  className={CommonStyles['input-area']}
                  onClick={() =>
                    dispatch(
                      ActionModal.openModal({
                        modalType: 'coagency_confirm_change_location',
                        isOpen: true,
                      }),
                    )
                  }
                >
                  <input
                    onClick={handleInputClick}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    type="text"
                    value={
                      shareRegStep?.selectedAddr?.sidoAddr?.name
                        ? `${shareRegStep?.selectedAddr?.sidoAddr?.name ?? ''} ${
                            shareRegStep?.selectedAddr?.guGunAddr?.name ?? ''
                          } ${shareRegStep?.selectedAddr?.eupMyeonDongAddr?.name ?? ''} ${
                            shareRegStep?.selectedAddr?.liAddr?.name ?? ''
                          }`
                        : ''
                    }
                    readOnly
                  />
                </button>
              </div>
              <div
                className={cn(CommonStyles['insert-item'], CommonStyles['insert-address'])}
                // onClick={() => dispatch(ActionCoagency.initDetailAddr('share'))}
              >
                <p className={CommonStyles['sub-title']}>상세주소</p>
                <div className={CommonStyles['btn-wrap']}>
                  <button
                    type="button"
                    className={cn(
                      CommonStyles.btn,
                      CommonStyles.lg,
                      shareRegStep?.coagencyRegData?.addr_gbn !== 'N' && CommonStyles.line,
                    )}
                    onClick={(e) =>
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              addr_gbn: 'N',
                            },
                          },
                        }),
                      )
                    }
                  >
                    일반
                  </button>
                  <button
                    type="button"
                    className={cn(
                      CommonStyles.btn,
                      CommonStyles.lg,
                      shareRegStep?.coagencyRegData?.addr_gbn !== 'S' && CommonStyles.line,
                    )}
                    onClick={(e) =>
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              addr_gbn: 'S',
                            },
                          },
                        }),
                      )
                    }
                  >
                    산
                  </button>
                </div>
                <div className={CommonStyles['input-div']}>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="te1"
                      value={shareRegStep?.coagencyRegData?.addr?.street_1 || ''}
                      placeholder="(비공개)"
                      onChange={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                addr: {
                                  ...shareRegStep.coagencyRegData.addr,
                                  street_1: e.target.value,
                                },
                              },
                            },
                          }),
                        )
                      }
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={5}
                    />
                  </div>
                  <span>-</span>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      value={shareRegStep?.coagencyRegData?.addr?.street_2 || ''}
                      placeholder="(비공개)"
                      onChange={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyRegData: {
                                ...shareRegStep.coagencyRegData,
                                addr: {
                                  ...shareRegStep.coagencyRegData.addr,
                                  street_2: e.target.value,
                                },
                              },
                            },
                          }),
                        )
                      }
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={5}
                    />
                    <span className={CommonStyles.unit}>번지</span>
                  </div>
                </div>
                <div className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    type="text"
                    value={shareRegStep?.coagencyRegData?.addr?.detail || ''}
                    placeholder="상세주소 (비공개)"
                    disabled={shareRegStep?.coagencyRegData?.addr?.detail_use === 'N'}
                    onChange={(e) =>
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              addr: {
                                ...shareRegStep.coagencyRegData.addr,
                                detail: e.target.value,
                              },
                            },
                          },
                        }),
                      )
                    }
                  />
                </div>
                <div className={CommonStyles['check-box']}>
                  <input
                    type="checkbox"
                    id="chk2"
                    checked={shareRegStep?.coagencyRegData?.addr?.detail_use === 'N'}
                    onChange={(e) =>
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              addr: {
                                ...shareRegStep.coagencyRegData.addr,
                                detail_use: e.target.checked ? 'N' : 'Y',
                              },
                            },
                          },
                        }),
                      )
                    }
                  />
                  <label htmlFor="chk2" className={CommonStyles['label-info']}>
                    상세주소 없음
                  </label>
                </div>
              </div>
            </>
          )}
          {/* <!-- // 비공동 --> */}
        </section>
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>거래유형</p>
            <div style={{ display: 'flex' }}>
              {REMainType === 'B' && (
                <button
                  type="button"
                  className={CommonStyles.btn}
                  // onClick={() => dispatch(ActionCoagency.initParcel('share'))}
                  onClick={() =>
                    dispatch(
                      ActionModal.openModal({
                        modalType: 'coagency_bottom_sale_type_select_register',
                        data: shareRegStep?.coagencyRegData,
                        isOpen: true,
                      }),
                    )
                  }
                  style={{ marginRight: '0.5rem' }}
                >
                  {shareRegStep?.coagencyRegData?.parcel_gbn === 'A' ? '일반분양' : '조합원분양'}
                </button>
              )}
              <button
                type="button"
                className={CommonStyles.btn}
                // onClick={() => dispatch(ActionCoagency.initOffer('share'))}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_bottom_sale_type_select_register',
                      data: shareRegStep?.coagencyRegData,
                      isOpen: true,
                    }),
                  )
                }
              >
                {Config.COAGENCY_REG_OFFER_GBN.find((item) => item.value === OfferGbn)?.label}
              </button>
            </div>
          </div>

          {(REMainType === 'B' && OfferGbn === 'S') || (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>
                {OfferGbn === 'S'
                  ? '매매가'
                  : OfferGbn === 'L'
                  ? '전세가'
                  : OfferGbn === 'M' || OfferGbn === 'T'
                  ? '보증금'
                  : ''}
              </p>
              <button
                className={CommonStyles['input-area']}
                // onClick={() => dispatch(ActionCoagency.initPrice('share'))}
                // onClick={() => {
                //   dispatch(
                //     ActionModal.openModal({
                //       modalType: 'coagency_bottom_price_input_register',
                //       data: shareRegStep.coagencyRegData,
                //       isOpen: true,
                //     }),
                //   );
                // }}
              >
                {OfferGbn === 'S' && REMainType !== 'B' && (
                  <input
                    onClick={handleInputClick}
                    ref={_sellPrcRef}
                    type="text"
                    name="sellPrc"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    value={sellPrc || ''}
                    onChange={(e) => handleNumberChange(e.target.value, 'sellPrc', 'direct')}
                    // value={
                    //   shareRegStep?.coagencyRegData?.sell_prc
                    //     ? Number(shareRegStep?.coagencyRegData?.sell_prc)?.toLocaleString()
                    //     : ''
                    // }
                    // onChange={(e) => handleNumberChange(e.target.value, 'sellPrc', 'direct')}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={10}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    // readOnly
                  />
                )}
                {OfferGbn === 'L' && (
                  <input
                    onClick={handleInputClick}
                    ref={_leasePrcRef}
                    type="text"
                    name="leasePrc"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    value={leasePrc || ''}
                    onChange={(e) => handleNumberChange(e.target.value, 'leasePrc', 'direct')}
                    // value={
                    //   shareRegStep?.coagencyRegData?.lease_prc
                    //     ? Number(shareRegStep?.coagencyRegData?.lease_prc)?.toLocaleString()
                    //     : ''
                    // }
                    // onChange={(e) => handleNumberChange(e.target.value, 'leasePrc')}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={10}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    // readOnly
                  />
                )}
                {(OfferGbn === 'M' || OfferGbn === 'T') && (
                  <>
                    <input
                      onClick={handleInputClick}
                      ref={_depositPrcRef}
                      type="text"
                      name="depositPrc"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      value={depositPrc || ''}
                      onChange={(e) => handleNumberChange(e.target.value, 'depositPrc', 'direct')}
                      // value={
                      //   shareRegStep?.coagencyRegData?.deposit_prc
                      //     ? Number(shareRegStep?.coagencyRegData?.deposit_prc)?.toLocaleString()
                      //     : ''
                      // }
                      // onChange={(e) => handleNumberChange(e.target.value, 'depositPrc')}
                      inputMode="numeric"
                      pattern="[0-9]*"
                      maxLength={10}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      // readOnly
                    />
                  </>
                )}
                <span className={CommonStyles.unit}>만원</span>
              </button>
              <p className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                {OfferGbn === 'S' && (
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {shareRegStep?.coagencyRegData?.sell_prc
                      ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.sell_prc).replace(/,/g, '')))
                      : '0'}
                  </b>
                )}
                {OfferGbn === 'L' && (
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {shareRegStep?.coagencyRegData?.lease_prc
                      ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.lease_prc).replace(/,/g, '')))
                      : '0'}
                  </b>
                )}
                {(OfferGbn === 'M' || OfferGbn === 'T') && (
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {shareRegStep?.coagencyRegData?.deposit_prc
                      ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.deposit_prc).replace(/,/g, '')))
                      : '0'}
                  </b>
                )}
                원]
              </p>
            </div>
          )}
          {(OfferGbn === 'M' || OfferGbn === 'T') && (
            <div
              className={CommonStyles['insert-item']}
              // onClick={() => dispatch(ActionCoagency.initPrice('share'))}
            >
              <p className={CommonStyles['sub-title']}>월세</p>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  ref={_monthlyPrcRef}
                  type="text"
                  name="monthlyPrc"
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  value={monthlyPrc || ''}
                  onChange={(e) => handleNumberChange(e.target.value, 'monthlyPrc', 'direct')}
                  // value={
                  //   shareRegStep?.coagencyRegData?.monthly_prc
                  //     ? Number(shareRegStep?.coagencyRegData?.monthly_prc)?.toLocaleString()
                  //     : ''
                  // }
                  // onChange={(e) => handleNumberChange(e.target.value, 'monthlyPrc')}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={10}
                  // readOnly
                />
                <span className={CommonStyles.unit}>만원</span>
              </div>
              <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                [금액:
                <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                  {shareRegStep?.coagencyRegData?.monthly_prc
                    ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.monthly_prc).replace(/,/g, '')))
                    : '0'}
                </b>
                원]
              </div>
            </div>
          )}
          {/* <!-- 분양 케이스 --> */}
          {OfferGbn === 'S' && REMainType === 'B' && (
            <>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>분양가</p>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    ref={_salePrcRef}
                    type="text"
                    name="salePrc"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    value={salePrc || ''}
                    onChange={(e) => handleNumberChange(e.target.value, 'salePrc', 'direct')}
                    // value={
                    //   shareRegStep?.coagencyRegData?.parcel_prc
                    //     ? Number(shareRegStep?.coagencyRegData?.parcel_prc)?.toLocaleString()
                    //     : ''
                    // }
                    // onChange={(e) => handleNumberChange(e.target.value, 'salePrc')}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={10}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    // readOnly
                  />
                  <span className={CommonStyles.unit}>만원</span>
                </button>
                <p className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                  [금액:
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {shareRegStep?.coagencyRegData?.parcel_prc
                      ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.parcel_prc).replace(/,/g, '')))
                      : '0'}
                  </b>
                  원]
                </p>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>프리미엄 분양가</p>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    ref={_premiumPrcRef}
                    type="text"
                    name="premiumPrc"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    value={premiumPrc || ''}
                    onChange={(e) => handleNumberChange(e.target.value, 'premiumPrc', 'direct')}
                    // value={
                    //   shareRegStep?.coagencyRegData?.premium_prc
                    //     ? Number(shareRegStep?.coagencyRegData?.premium_prc)?.toLocaleString()
                    //     : ''
                    // }
                    // onChange={(e) => handleNumberChange(e.target.value, 'premiumPrc')}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={10}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    // readOnly
                  />
                  <span className={CommonStyles.unit}>만원</span>
                </button>
                <p className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                  [금액:
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {shareRegStep?.coagencyRegData?.premium_prc
                      ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.premium_prc).replace(/,/g, '')))
                      : '0'}
                  </b>
                  원]
                </p>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>분양옵션가</p>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    ref={_optionPrcRef}
                    type="text"
                    name="optionPrc"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    value={optionPrc || ''}
                    onChange={(e) => handleNumberChange(e.target.value, 'optionPrc', 'direct')}
                    // value={
                    //   shareRegStep?.coagencyRegData?.parcel_option_prc
                    //     ? Number(shareRegStep?.coagencyRegData?.parcel_option_prc)?.toLocaleString()
                    //     : ''
                    // }
                    // onChange={(e) => handleNumberChange(e.target.value, 'optionPrc')}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={10}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    // readOnly
                  />
                  <span className={CommonStyles.unit}>만원</span>
                </button>
                <p className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                  [금액:
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {shareRegStep?.coagencyRegData?.parcel_option_prc
                      ? formatNumber(Number(String(shareRegStep?.coagencyRegData?.parcel_option_prc).replace(/,/g, '')))
                      : '0'}
                  </b>
                  원]
                </p>
              </div>
            </>
          )}
        </section>
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>입주가능일</p>
            <button
              className={cn(CommonStyles.dropdown, CommonStyles.on)}
              // onClick={() => dispatch(ActionCoagency.initMoveDay('share'))}
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'coagency_bottom_movein_select_register',
                    data: shareRegStep?.coagencyRegData,
                    isOpen: true,
                  }),
                )
              }
            >
              {shareRegStep?.coagencyRegData?.move_gbn === 'A' ? (
                <span>즉시입주</span>
              ) : shareRegStep?.coagencyRegData?.move_gbn === 'S' ? (
                <span>{moment(shareRegStep.coagencyRegData.move_ymd).format('YYYY-MM-DD')}</span>
              ) : ['B', 'C', 'D'].includes(shareRegStep?.coagencyRegData?.move_gbn) ? (
                <span>{`${moment(shareRegStep?.coagencyRegData?.move_ymd).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                  (item) => item.cd === shareRegStep?.coagencyRegData?.move_gbn,
                )?.name}`}</span>
              ) : (
                <span />
              )}
              {shareRegStep?.coagencyRegData?.is_move_chg === 'Y' && <div>입주가능일협의</div>}
              <i className={CommonStyles['icon-arrow-down-gr']}></i>
            </button>
          </div>
        </section>
      </div>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleShareSettings}>
          마지막으로 공유 범위 설정하러 가기
        </button>
      </div>
    </>
  );

  /***********************currStep 10, 11 공통 간략보기 *****************************/

  /***********************currStep 10 간략보기 및 노출기간, 연락처, 공유선택, *****************************/
  /**
   * @description 매물 간편 등록
   * @returns {Promise<void>}
   */
  const handleEasySubmit = async (type: string): Promise<void> => {
    console.log('easy submit start==>');
    submitBtnRef.current ? (submitBtnRef.current.disabled = true) : null;
    // 공유여부
    if (shareRegStep?.coagencyRegData?.status_share === 'Y' && !shares?.length) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '공유범위를 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }
    // 연락처 노출 선택여부
    if (shareRegStep?.coagencyRegData?.status_share === 'Y' && !shareRegStep?.coagencyRegData?.tel_display) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '연락처노출을 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    // 지도 노출 선택여부
    if (!(REMainType === 'A' || REMainType === 'B') && !shareRegStep?.coagencyRegData?.status_share_map) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '지도노출을 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (
      type === 'all' &&
      (shareRegStep?.coagencyOptRegData?.customer_name || shareRegStep?.coagencyOptRegData?.phone_num) &&
      !isTermsAgree
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '개인정보 수집에 동의해 주세요' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    console.log('간편 등록 start', type);
    const params = {
      offerings_gbn: RESubType,
      area_cd: shareRegStep?.coagencyRegData?.area_cd || null,
      complex_cd: shareRegStep?.selectedAddr?.complexAddr?.complex_cd
        ? shareRegStep?.selectedAddr?.complexAddr?.complex_cd
        : null,
      dong_cd: shareRegStep?.selectedAddr?.dongAddr.dong_cd || null,
      ho_nm: shareRegStep?.coagencyRegData.ho_nm || null,

      addr_gbn: shareRegStep?.coagencyRegData.addr_gbn || null,
      addr: shareRegStep?.coagencyRegData.addr,
      offer_gbn: shareRegStep?.coagencyRegData?.offer_gbn,
      sell_prc: shareRegStep?.coagencyRegData?.sell_prc
        ? Number(String(shareRegStep?.coagencyRegData?.sell_prc).replace(/,/g, ''))
        : null,
      lease_prc: shareRegStep?.coagencyRegData?.lease_prc
        ? Number(String(shareRegStep?.coagencyRegData?.lease_prc).replace(/,/g, ''))
        : null,
      deposit_prc: shareRegStep?.coagencyRegData?.deposit_prc
        ? Number(String(shareRegStep?.coagencyRegData?.deposit_prc).replace(/,/g, ''))
        : null,
      monthly_prc: shareRegStep?.coagencyRegData?.monthly_prc
        ? Number(String(shareRegStep?.coagencyRegData?.monthly_prc).replace(/,/g, ''))
        : null,

      parcel_gbn: shareRegStep?.coagencyRegData?.parcel_gbn || null,
      parcel_prc: shareRegStep?.coagencyRegData?.parcel_prc
        ? Number(String(shareRegStep?.coagencyRegData?.parcel_prc).replace(/,/g, ''))
        : null,
      premium_prc: shareRegStep?.coagencyRegData?.premium_prc
        ? Number(String(shareRegStep?.coagencyRegData?.premium_prc).replace(/,/g, ''))
        : null,
      parcel_option_prc: shareRegStep?.coagencyRegData?.parcel_option_prc
        ? Number(String(shareRegStep?.coagencyRegData?.parcel_option_prc).replace(/,/g, ''))
        : null,

      move_gbn: shareRegStep?.coagencyRegData?.move_gbn || null,
      is_move_chg: shareRegStep?.coagencyRegData?.is_move_chg || null,
      move_ymd: moment(shareRegStep?.coagencyRegData?.move_ymd).format('YYYYMMDD') || null, // 입주가능일 직접입력 날짜
      status_deal: shareRegStep?.coagencyRegData?.status_deal, // 상태, normal : 정상, hold : 보류, complete : 거래완료
      status_share: shareRegStep?.coagencyRegData?.status_share, // 공유여부, Y: 공유, N: 공유안함
      shares: shares?.map((k: IMixedKeyValue) => {
        return {
          type: k.group_id === 99999 ? 'P' : 'G',
          group_id: k.group_id === 99999 ? null : k.group_id || k.id,
        };
      }),
      tel_display: shareRegStep?.coagencyRegData?.tel_display, // 연락처 노출, A : 모두, R : 전화번호, C : 휴대폰번호
      status_share_map: shareRegStep?.coagencyRegData?.status_share_map, // 지도노출여부 (offerings_gbn 이 'RB" 일 경우 필수), Y: 지도노출, N: 지도노출안함
      show_days: 30, //  shareRegStep?.coagencyRegData?.show_days, {/* TODO 노출기간 수정 - 6. mo : 공유매물 > 작성   */}
      ad_offerings_seq: shareRegStep?.coagencyRegData?.ad_offerings_seq,
      offerings_seq: shareRegStep?.coagencyRegData?.offerings_seq,
      offering_sync: shareRegStep?.coagencyRegData?.offering_sync,
    };

    console.log('detailRegParams in handleEasySubmit', detailRegParams);
    console.log('squre===>', shareRegStep?.selectedAddr.square);
    let _params;
    const combiParams = { ...params, ...detailRegParams };
    if (REMainType === 'A' && RESubType === 'RB') {
      _params = _.omit(combiParams, [
        'dong_cd',
        'sqr_cd',
        'ho_nm',
        'parcel_gbn',
        'parcel_prc',
        'premium_prc',
        'parcel_option_prc',
        'area',
      ]);
    } else if (REMainType === 'A' && RESubType !== 'RB') {
      _params = _.omit(combiParams, [
        'addr_gbn',
        'addr',
        'parcel_gbn',
        'parcel_prc',
        'premium_prc',
        'parcel_option_prc',
        'area',
      ]);
    } else if (REMainType === 'B') {
      _params = _.omit(combiParams, ['addr_gbn', 'addr', 'area']);
    } else if (REMainType !== 'A' && REMainType !== 'B') {
      _params = _.omit(combiParams, [
        'dong_cd',
        'sqr_cd',
        'ho_nm',
        'parcel_gbn',
        'parcel_prc',
        'premium_prc',
        'parcel_option_prc',
        'sup_sqr',
        'con_sqr',
        'exc_sqr',
      ]);
    }
    let body;
    if (type === 'easy') {
      body = JSON.stringify(_params);
    } else {
      body = JSON.stringify(_.assign(combiParams));
    }
    try {
      const res = await network()
        .coagency()
        .postSubmit(
          REMainType === 'A' || REMainType === 'B' ? Config.API_URL.POST_APARTMENT : Config.API_URL.POST_NONAPARTMENT,
          body,
        );
      const { data, status } = res;
      if (status === 200 || status === 201) {
        if (
          REMainType === 'A' || REMainType === 'B'
            ? Object.keys(res.data.data).length > 0
            : Object.keys(res.data).length > 0
        ) {
          if (type == 'easy') {
            batch(() => {
              dispatch(
                ActionCoagency.setStep({
                  shareRegStep: {
                    ...shareRegStep,
                    coagencyRegData: {
                      ...shareRegStep.coagencyRegData,
                      regId: REMainType === 'A' || REMainType === 'B' ? data.data.id : data.id,
                    },
                  },
                }),
              );
              dispatch(
                ActionModal.openModal({
                  modalType: 'coagency_confirm_easy_registration',
                  isOpen: true,
                }),
              );
            });
          } else {
            dispatch(
              ActionModal.openModal({
                modalType: 'coagency_alert_success_registration',
                isOpen: true,
              }),
            );
          }
        }
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
        // console.log('data===>', data);
      }
    } catch (err: any) {
      console.error('err', err);
      if (err && err?.data.message) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: err?.data.message },
            isOpen: true,
          }),
        );
      }
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
    }
  };

  const renderSharePoint = (
    <>
      <CommonSummary type={'write'} />
      <div className={CommonStyles['section-div']}>
        {/* TODO 노출기간 수정 - 6. mo : 공유매물 > 작성   */}
        {/* <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물 노출 기간을 선택해주세요.</p>
            <div className={cn(CommonStyles['button-list'], CommonStyles.p0)}>
              <button className={CommonStyles['radio-box']}>
                <input
                  type="radio"
                  id="radio1"
                  name="radio_show_days"
                  value={15}
                  checked={shareRegStep.coagencyRegData.show_days === 15}
                  onChange={(e) =>
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            show_days: Number(e.target.value),
                          },
                        },
                      }),
                    )
                  }
                />
                <label htmlFor="radio1" className={CommonStyles['label-info']}>
                  15일
                </label>
              </button>
              <button
                className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                aria-expanded="false"
              >
                <input
                  type="radio"
                  id="radio2"
                  name="radio_show_days"
                  value={30}
                  checked={shareRegStep.coagencyRegData.show_days === 30}
                  onChange={(e) =>
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            show_days: Number(e.target.value),
                          },
                        },
                      }),
                    )
                  }
                />
                <label htmlFor="radio2" className={CommonStyles['label-info']}>
                  30일
                </label>
              </button>
            </div>
          </div>
        </section> */}
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>연락처 노출을 선택해주세요.</p>
            <div className={cn(CommonStyles['button-list'], CommonStyles.p0)}>
              <button className={CommonStyles['radio-box']}>
                <input
                  type="radio"
                  id="radio11"
                  name="radio_tel_display"
                  value="A"
                  checked={shareRegStep.coagencyRegData.tel_display === 'A'}
                  onChange={(e) =>
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            tel_display: e.target.value,
                          },
                        },
                      }),
                    )
                  }
                />
                <label htmlFor="radio11" className={CommonStyles['label-info']}>
                  모두노출(중개업소 전화번호+휴대폰번호)
                </label>
              </button>
              <button
                className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                aria-expanded="false"
              >
                <input
                  type="radio"
                  id="radio12"
                  name="radio_tel_display"
                  value="R"
                  checked={shareRegStep.coagencyRegData.tel_display === 'R'}
                  onChange={(e) =>
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            tel_display: e.target.value,
                          },
                        },
                      }),
                    )
                  }
                />
                <label htmlFor="radio12" className={CommonStyles['label-info']}>
                  중개업소 전화번호만 노출
                </label>
              </button>
              <button className={CommonStyles['radio-box']}>
                <input
                  type="radio"
                  id="radio13"
                  name="radio_tel_display"
                  value="C"
                  checked={shareRegStep.coagencyRegData.tel_display === 'C'}
                  onChange={(e) =>
                    dispatch(
                      ActionCoagency.setStep({
                        shareRegStep: {
                          ...shareRegStep,
                          coagencyRegData: {
                            ...shareRegStep.coagencyRegData,
                            tel_display: e.target.value,
                          },
                        },
                      }),
                    )
                  }
                />
                <label htmlFor="radio13" className={CommonStyles['label-info']}>
                  휴대폰번호만 노출
                </label>
              </button>
            </div>
          </div>
        </section>
        {REMainType === 'A' || REMainType === 'B' || (
          <section>
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>지도 노출을 선택해주세요.</p>
              <div className={cn(CommonStyles['button-list'], CommonStyles.p0)}>
                <button className={CommonStyles['radio-box']}>
                  <input
                    type="radio"
                    id="mapDisplay1"
                    name="mapDisplay"
                    value="Y"
                    checked={shareRegStep.coagencyRegData.status_share_map === 'Y'}
                    onChange={(e) =>
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              status_share_map: e.target.value,
                            },
                          },
                        }),
                      )
                    }
                  />
                  <label htmlFor="mapDisplay1" className={CommonStyles['label-info']}>
                    노출함
                  </label>
                </button>
                <button className={CommonStyles['radio-box']}>
                  <input
                    type="radio"
                    id="mapDisplay2"
                    name="mapDisplay"
                    value="N"
                    checked={shareRegStep.coagencyRegData.status_share_map === 'N'}
                    onChange={(e) =>
                      dispatch(
                        ActionCoagency.setStep({
                          shareRegStep: {
                            ...shareRegStep,
                            coagencyRegData: {
                              ...shareRegStep.coagencyRegData,
                              status_share_map: e.target.value,
                            },
                          },
                        }),
                      )
                    }
                  />
                  <label htmlFor="mapDisplay2" className={CommonStyles['label-info']}>
                    노출안 함
                  </label>
                </button>
              </div>
            </div>
          </section>
        )}

        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>공유정보</p>
            <div className={CommonStyles['btn-wrap']}>
              <button
                type="button"
                className={cn(CommonStyles.btn, shareRegStep.coagencyRegData.status_share !== 'N' && CommonStyles.line)}
                onClick={() =>
                  dispatch(
                    ActionCoagency.setStep({
                      shareRegStep: {
                        ...shareRegStep,
                        coagencyRegData: {
                          ...shareRegStep.coagencyRegData,
                          status_share: 'N',
                        },
                      },
                    }),
                  )
                }
              >
                공유안함
              </button>
              <button
                type="button"
                className={cn(CommonStyles.btn, shareRegStep.coagencyRegData.status_share !== 'Y' && CommonStyles.line)}
                onClick={() => {
                  const el = document.getElementById('groupList');
                  el?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
                  dispatch(
                    ActionCoagency.setStep({
                      shareRegStep: {
                        ...shareRegStep,
                        coagencyRegData: {
                          ...shareRegStep.coagencyRegData,
                          status_share: 'Y',
                        },
                      },
                    }),
                  );
                }}
              >
                공유함
              </button>
            </div>
            <p className={CommonStyles['sub-txt share-info']}>
              *공동중개를 위한 매물공유를 원하시면 공유함을 선택해주세요. 아래 게시판은 복수선택이 가능합니다.
            </p>
            <div className={cn(CommonStyles['button-list'], CommonStyles.p0)} id="groupList">
              {mygroupList.length > 0 &&
                shareRegStep.coagencyRegData.status_share === 'Y' &&
                mygroupList.map((item: IMixedKeyValue) => {
                  return (
                    <button key={item.id} className={CommonStyles['check-box']}>
                      <input
                        type="checkbox"
                        id={`share_${item.id}`}
                        checked={shares.map((k: IMixedKeyValue) => k.id).includes(item.id)}
                        value={item.id}
                        onChange={() => {
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyRegData: {
                                  ...shareRegStep.coagencyRegData,
                                  shares: [...shares].some((j: IMixedKeyValue) => j.id === item.id)
                                    ? [...shares].filter((t: IMixedKeyValue) => t.id !== item.id)
                                    : [
                                        ...shares,
                                        {
                                          id: item.id,
                                          name: item.name,
                                          group_id: item.group_id,
                                          code: item.code || '',
                                        },
                                      ],
                                },
                              },
                            }),
                          );
                        }}
                        // disabled={item.id === 99999}
                      />
                      <label htmlFor={`share_${item.id}`} className={CommonStyles['label-info']}>
                        {item.name}
                      </label>
                    </button>
                  );
                })}
            </div>
          </div>
        </section>
      </div>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        // style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        {/* <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.white)}
          onClick={() =>
            dispatch(
              ActionModal.openModal({
                modalType: 'coagency_registration_cancel',
                isOpen: true,
              }),
            )
          }
        >
          취소
        </button> */}
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
          onClick={() =>
            dispatch(
              ActionCoagency.setStep({
                shareRegStep: {
                  ...shareRegStep,
                  currStep: shareRegStep.currStep + 1,
                },
              }),
            )
          }
        >
          상세 작성
        </button>
        <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg)}
          onClick={() => handleEasySubmit('easy')}
        >
          간편 등록
        </button>
      </div>
    </>
  );

  /***********************currStep 11 간략보기 및 상세작성 *****************************/
  const handleDetailSubmit = async () => {
    console.log('detail submit start==>');
    submitBtnRef.current ? (submitBtnRef.current.disabled = true) : null;
    if (
      (shareRegStep?.coagencyOptRegData?.customer_name || shareRegStep?.coagencyOptRegData?.phone_num) &&
      !isTermsAgree
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '개인정보 수집에 동의해 주세요' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }
    console.log('상세 등록하기 start');
    console.log('detailRegParams in handleDetailSubmit', detailRegParams);
    console.log('squre in handleDetailSubmit===>', shareRegStep?.selectedAddr.square);

    let _params;
    if (REMainType === 'A' && RESubType === 'RB') {
      _params = _.omit(detailRegParams, ['sqr_cd', 'area']);
    } else if (REMainType === 'A' && RESubType !== 'RB') {
      _params = _.omit(detailRegParams, ['area']);
    } else if (REMainType === 'B') {
      _params = _.omit(detailRegParams, ['area']);
    } else if (REMainType !== 'A' && REMainType !== 'B') {
      _params = _.omit(detailRegParams, ['sqr_cd', 'sup_sqr', 'con_sqr', 'exc_sqr']);
    }
    console.log('detailRegParams in handleDetailSubmit', _params);
    const body = JSON.stringify(_params);
    const regId = shareRegStep?.coagencyRegData?.regId;
    try {
      const res = await network().coagency().putSubmit(`/offers/optional/${regId}`, body);
      const { data, status } = res;
      console.log('data in detail reg===>', data);
      if (status === 200 || status === 201) {
        if (Object.keys(data).length > 0) {
          dispatch(
            ActionModal.openModal({
              modalType: 'coagency_alert_success_registration',
              isOpen: true,
            }),
          );
        }
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      }
    } catch (err: any) {
      console.error('err', err);
      if (err && err?.data.message) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: err?.data.message },
            isOpen: true,
          }),
        );
      }
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
    }
  };

  const renderDetailWriting = (
    <>
      <CommonSummary type={'write'} />
      <div className={CommonStyles['section-div']}>
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles.title}>매물 상세 정보 (선택입력)</p>
            <p className={CommonStyles['sub-txt']}>
              *상세 정보 미작성시에도 공유하기가 가능하며, 수정하기에서 추가 작성이 가능합니다.
            </p>
          </div>
          {/**공동주택 면적정보 선택과 입력 */}
          {(REMainType === 'A' && RESubType !== 'RB') || REMainType === 'B' ? (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>면적을 선택해주세요.</p>
              <button
                className={CommonStyles.dropdown}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_select_area_select',
                      data: shareRegStep?.selectedAddr?.complexAddr?.square,
                      isOpen: true,
                    }),
                  )
                }
              >
                <span>
                  {shareRegStep?.selectedAddr?.square?.sqr_cd ? shareRegStep?.selectedAddr?.square.disSqr : '선택'}
                </span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
            </div>
          ) : (
            REMainType === 'A' &&
            RESubType === 'RB' && (
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>면적을 입력해주세요.</p>
                <p className={CommonStyles['sub-title']}>대지면적</p>
                <div className={CommonStyles['input-div']}>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      ref={lotAreaReDevRef}
                      type="number"
                      id="lotArea"
                      name="lotArea"
                      value={shareRegStep?.coagencyOptRegData?.area?.lotArea || ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) =>
                        dispatch(
                          ActionCoagency.setStep({
                            shareRegStep: {
                              ...shareRegStep,
                              coagencyOptRegData: {
                                ...shareRegStep.coagencyOptRegData,
                                area: {
                                  ...shareRegStep.coagencyOptRegData.area,
                                  lotArea: Number(e.target.value),
                                },
                              },
                            },
                          }),
                        )
                      }
                      maxLength={6}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    />
                    <span className={CommonStyles.unit}>㎡</span>
                  </div>
                  <span>=</span>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="number"
                      id="lotArPyeong_AP"
                      name="lotArPyeong_AP"
                      value={
                        shareRegStep?.coagencyOptRegData?.area?.lotArea
                          ? squareToPyeong(Number(shareRegStep?.coagencyOptRegData?.area?.lotArea), 'register')
                          : ''
                      }
                      readOnly
                    />
                    <span className={CommonStyles.unit}>평</span>
                  </div>
                </div>
              </div>
            )
          )}

          {/**비공동주택 C, D type은 면적확인하기 존재*/}
          {REMainType === 'A' || REMainType === 'B' || (
            <div className={CommonStyles['insert-item']}>
              {(REMainType === 'C' || REMainType === 'D') && (
                <p className={CommonStyles['sub-title']}>
                  면적을 입력해주세요.
                  <button
                    className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles['ml-auto'])}
                    onClick={() =>
                      dispatch(
                        ActionModal.openModal({
                          modalType: 'coagency_bottom_building_info',
                          data: {
                            areaCd: shareRegStep?.coagencyRegData?.area_cd,
                            houseNo: `${shareRegStep?.coagencyRegData?.addr?.street_1}-${shareRegStep?.coagencyRegData?.addr?.street_2}`,
                          },
                          isOpen: true,
                        }),
                      )
                    }
                  >
                    면적 확인하기
                  </button>
                </p>
              )}
              {((RESubType === 'DD' || RESubType === 'OR' || RESubType === 'SJ') && OfferGbn !== 'S') ||
                RESubType === 'VL' ||
                RESubType === 'SM' ||
                RESubType === 'SP' ||
                RESubType === 'JC' || (
                  <>
                    <p className={CommonStyles['sub-title']}>대지면적</p>
                    <div className={CommonStyles['input-div']}>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          ref={lotAreaRef}
                          type="number"
                          id="lotArea"
                          name="lotArea"
                          value={shareRegStep?.coagencyOptRegData?.area?.lotArea || ''}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          onChange={(e) =>
                            dispatch(
                              ActionCoagency.setStep({
                                shareRegStep: {
                                  ...shareRegStep,
                                  coagencyOptRegData: {
                                    ...shareRegStep.coagencyOptRegData,
                                    area: {
                                      ...shareRegStep.coagencyOptRegData.area,
                                      lotArea: Number(e.target.value),
                                    },
                                  },
                                },
                              }),
                            )
                          }
                          maxLength={6}
                          className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                        />
                        <span className={CommonStyles.unit}>㎡</span>
                      </div>
                      <span>=</span>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          type="number"
                          id="lotArPyeong"
                          name="lotArPyeong"
                          value={
                            shareRegStep?.coagencyOptRegData?.area?.lotArea
                              ? squareToPyeong(Number(shareRegStep?.coagencyOptRegData?.area?.lotArea), 'register')
                              : ''
                          }
                          readOnly
                        />
                        <span className={CommonStyles.unit}>평</span>
                      </div>
                    </div>
                    {/** 토지/임야는 연면적 제외 */}
                    {RESubType === 'TJ' || (
                      <>
                        <p className={CommonStyles['sub-title']}>연면적</p>
                        <div className={CommonStyles['input-div']}>
                          <div className={CommonStyles['input-area']}>
                            <input
                              onClick={handleInputClick}
                              ref={totAreaRef}
                              type="number"
                              id="totArea"
                              name="totArea"
                              value={shareRegStep?.coagencyOptRegData?.area?.totArea || ''}
                              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                              }}
                              onChange={(e) =>
                                dispatch(
                                  ActionCoagency.setStep({
                                    shareRegStep: {
                                      ...shareRegStep,
                                      coagencyOptRegData: {
                                        ...shareRegStep.coagencyOptRegData,
                                        area: {
                                          ...shareRegStep.coagencyOptRegData.area,
                                          totArea: Number(e.target.value),
                                        },
                                      },
                                    },
                                  }),
                                )
                              }
                              maxLength={6}
                            />
                            <span className={CommonStyles.unit}>㎡</span>
                          </div>
                          <span>=</span>
                          <div className={CommonStyles['input-area']}>
                            <input
                              onClick={handleInputClick}
                              type="number"
                              id="totArPyeong_nonPublic"
                              name="totArPyeong_nonPublic"
                              value={
                                shareRegStep?.coagencyOptRegData?.area?.totArea
                                  ? squareToPyeong(Number(shareRegStep?.coagencyOptRegData?.area?.totArea), 'register')
                                  : ''
                              }
                              readOnly
                            />
                            <span className={CommonStyles.unit}>평</span>
                          </div>
                        </div>
                      </>
                    )}
                    {/** 공장/창고 전거래유형, 단독다가구 매매시, 상가주택 매매시 , 토지/임야, F빌딩 전거래유형 건축면적제외 */}
                    {RESubType === 'GJ' ||
                      RESubType === 'TJ' ||
                      REMainType === 'F' ||
                      ((RESubType === 'DD' || RESubType === 'SJ') && OfferGbn === 'S') || (
                        <>
                          <p className={CommonStyles['sub-title']}>건축면적</p>
                          <div className={CommonStyles['input-div']}>
                            <div className={CommonStyles['input-area']}>
                              <input
                                onClick={handleInputClick}
                                ref={bulAreaRef}
                                type="number"
                                id="bulArea"
                                name="bulArea"
                                value={shareRegStep?.coagencyOptRegData?.area?.bulArea || ''}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e.target.value.length > e.target.maxLength)
                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                }}
                                onChange={(e) =>
                                  dispatch(
                                    ActionCoagency.setStep({
                                      shareRegStep: {
                                        ...shareRegStep,
                                        coagencyOptRegData: {
                                          ...shareRegStep.coagencyOptRegData,
                                          area: {
                                            ...shareRegStep.coagencyOptRegData.area,
                                            bulArea: Number(e.target.value),
                                          },
                                        },
                                      },
                                    }),
                                  )
                                }
                                maxLength={6}
                              />
                              <span className={CommonStyles.unit}>㎡</span>
                            </div>
                            <span>=</span>
                            <div className={CommonStyles['input-area']}>
                              <input
                                onClick={handleInputClick}
                                type="number"
                                id="bulArPyeong_nonPublic"
                                name="bulArPyeong_nonPublic"
                                value={
                                  shareRegStep?.coagencyOptRegData?.area?.bulArea
                                    ? squareToPyeong(
                                        Number(shareRegStep?.coagencyOptRegData?.area?.bulArea),
                                        'register',
                                      )
                                    : ''
                                }
                                readOnly
                              />
                              <span className={CommonStyles.unit}>평</span>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              {/** 계약면적 ==> 상가주택 전,월세 단기임대, 사무실, 상가점포  */}
              {((RESubType === 'SJ' && OfferGbn !== 'S') || RESubType === 'SM' || RESubType === 'SP') && (
                <>
                  <p className={CommonStyles['sub-title']}>계약면적</p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        ref={conAreaRef}
                        type="number"
                        id="conArea"
                        name="conArea"
                        value={shareRegStep?.coagencyOptRegData?.area?.conArea || ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onChange={(e) =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyOptRegData: {
                                  ...shareRegStep.coagencyOptRegData,
                                  area: {
                                    ...shareRegStep.coagencyOptRegData.area,
                                    conArea: Number(e.target.value),
                                  },
                                },
                              },
                            }),
                          )
                        }
                        maxLength={9}
                      />
                      <span className={CommonStyles.unit}>㎡</span>
                    </div>
                    <span>=</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="number"
                        id="conArea_pyeong"
                        name="conArea_pyeong"
                        value={
                          shareRegStep?.coagencyOptRegData?.area?.conArea
                            ? squareToPyeong(Number(shareRegStep?.coagencyOptRegData?.area?.conArea), 'register')
                            : ''
                        }
                        readOnly
                      />
                      <span className={CommonStyles.unit}>평</span>
                    </div>
                  </div>
                </>
              )}
              {/** 공급면적 ==> 단독/다가구, 빌라/연림 전.월세, 단기임대  원룸, 지식산업센터 */}
              {((RESubType === 'DD' && OfferGbn !== 'S') ||
                RESubType === 'VL' ||
                RESubType === 'OR' ||
                RESubType === 'JC') && (
                <>
                  <p className={CommonStyles['sub-title']}>공급면적</p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        ref={supAreaRef}
                        type="number"
                        id="supArea"
                        name="supArea"
                        value={shareRegStep?.coagencyOptRegData?.area?.supArea || ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onChange={(e) =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyOptRegData: {
                                  ...shareRegStep.coagencyOptRegData,
                                  area: {
                                    ...shareRegStep.coagencyOptRegData.area,
                                    supArea: Number(e.target.value),
                                  },
                                },
                              },
                            }),
                          )
                        }
                        maxLength={6}
                      />
                      <span className={CommonStyles.unit}>㎡</span>
                    </div>
                    <span>=</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="number"
                        id="supArea_pyeong"
                        name="supArea_pyeong"
                        value={
                          shareRegStep?.coagencyOptRegData?.area?.supArea
                            ? squareToPyeong(Number(shareRegStep.coagencyOptRegData.area.supArea), 'register')
                            : ''
                        }
                        readOnly
                      />
                      <span className={CommonStyles.unit}>평</span>
                    </div>
                  </div>
                </>
              )}
              {/** 전용면적 ==> 한옥, 사무실, 상가점포, 지식산업센터 전거래유형,
               * 전원/농가, 빌라/연립, 단독/다가구, 상가주택, 원룸 ==> 전,월세, 단기임대  */}
              {(RESubType === 'SM' ||
                RESubType === 'SP' ||
                RESubType === 'JC' ||
                RESubType === 'VL' ||
                ((RESubType === 'JW' ||
                  RESubType === 'HO' ||
                  RESubType === 'DD' ||
                  RESubType === 'SJ' ||
                  RESubType === 'OR') &&
                  OfferGbn !== 'S')) && (
                <>
                  <p className={CommonStyles['sub-title']}>전용면적</p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        ref={excAreaRef}
                        type="text"
                        id="excArea"
                        name="excArea"
                        value={shareRegStep?.coagencyOptRegData?.area?.excArea || ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onChange={(e) =>
                          dispatch(
                            ActionCoagency.setStep({
                              shareRegStep: {
                                ...shareRegStep,
                                coagencyOptRegData: {
                                  ...shareRegStep.coagencyOptRegData,
                                  area: {
                                    ...shareRegStep.coagencyOptRegData.area,
                                    excArea: Number(e.target.value),
                                  },
                                },
                              },
                            }),
                          )
                        }
                        maxLength={6}
                      />
                      <span className={CommonStyles.unit}>㎡</span>
                    </div>
                    <span>=</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="number"
                        id="excArea_pyeong"
                        name="excArea_pyeong"
                        value={
                          shareRegStep?.coagencyOptRegData?.area?.excArea
                            ? squareToPyeong(Number(shareRegStep?.coagencyOptRegData?.area?.excArea), 'register')
                            : ''
                        }
                        readOnly
                      />
                      <span className={CommonStyles.unit}>평</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/**층*/}
          {RESubType === 'TJ' || (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>층을 입력해주세요.</p>
              {RESubType === 'RB' ||
              RESubType === 'OR' ||
              RESubType === 'SM' ||
              RESubType === 'SP' ||
              RESubType === 'JC' ||
              RESubType === 'VL' ||
              (RESubType === 'DD' && OfferGbn !== 'S') ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="apply"
                      name="apply"
                      value={shareRegStep?.coagencyOptRegData?.floor.apply || ''}
                      placeholder="해당층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) =>
                        handleFloorChange(e.target.value, 'apply', shareRegStep?.coagencyOptRegData?.floor?.total || 0)
                      }
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="totFloor"
                      name="totFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.total || ''}
                      placeholder="총층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'total')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : RESubType === 'DD' && OfferGbn === 'S' ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="underFloor"
                      name="underFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.under || ''}
                      placeholder="지하층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) =>
                        handleFloorChange(e.target.value, 'under', shareRegStep?.coagencyOptRegData?.floor?.total || 0)
                      }
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="totFloor"
                      name="totFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.total || ''}
                      placeholder="총층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'total')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : REMainType === 'F' ||
                RESubType === 'HO' ||
                RESubType === 'GJ' ||
                (RESubType === 'SJ' && OfferGbn === 'S') ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  {/** 한옥, 공장/창고 전체,  상가주택 매매 */}
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="underFloor"
                      name="underFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.under || ''}
                      placeholder="지하층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'under')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="groundFloor"
                      name="groundFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.ground || ''}
                      placeholder="지상층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'ground')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : RESubType === 'SJ' && OfferGbn !== 'S' ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="apply"
                      name="apply"
                      value={shareRegStep?.coagencyOptRegData?.floor.apply || ''}
                      placeholder="해당층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'apply')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="groundFloor"
                      name="groundFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.ground || ''}
                      placeholder="지상층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'ground')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : RESubType === 'JW' ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="totFloor"
                      name="totFloor"
                      value={shareRegStep?.coagencyOptRegData?.floor?.total || ''}
                      placeholder="총층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorChange(e.target.value, 'total')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="apply"
                      name="apply"
                      value={shareRegStep?.coagencyOptRegData?.floor.apply || ''}
                      placeholder="해당층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) =>
                        handleFloorChange(
                          e.target.value,
                          'apply',
                          Number(shareRegStep?.selectedAddr?.dongAddr?.max_floor),
                        )
                      }
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      min={0}
                      max={Number(shareRegStep?.selectedAddr?.dongAddr?.max_floor)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <span className={CommonStyles.unit}>
                      [{shareRegStep?.coagencyOptRegData?.floor?.apply || '0'}/
                      {shareRegStep?.selectedAddr?.dongAddr?.max_floor || 0}층]
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}

          {/** 방수/욕실수 학인 ==> 재개발, E유형, F유형 제외 */}
          {RESubType === 'RB' || REMainType === 'E' || RESubType === 'F' || (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>방수와 욕실수를 작성해주세요.</p>
              <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    type="tel"
                    id="room_cnt"
                    name="room_cnt"
                    value={shareRegStep?.coagencyOptRegData?.room_cnt || ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    onChange={(e) => handleNumberChange(e.target.value, 'room_cnt')}
                    placeholder="방수"
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={3}
                  />
                  <span className={CommonStyles.unit}>개</span>
                </button>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    type="tel"
                    id="bath_cnt"
                    name="bath_cnt"
                    value={shareRegStep?.coagencyOptRegData?.bath_cnt || ''}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    onChange={(e) => handleNumberChange(e.target.value, 'bath_cnt')}
                    placeholder="욕실수"
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={3}
                  />
                  <span className={CommonStyles.unit}>개</span>
                </button>
              </div>
            </div>
          )}
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물특징을 작성해주세요.</p>
            <button className={cn(CommonStyles['textarea-div'], CommonStyles.type1)}>
              <textarea
                onClick={handleInputClick}
                id="memo"
                name="memo"
                value={shareRegStep.coagencyOptRegData.memo || ''}
                onChange={(e) => handleMemo(e)}
                placeholder="공동중개 리스트에 노출되는 문구입니다. 40자 이내로 작성해주세요."
              />
              <p className={CommonStyles.byte}>
                <b>{shareRegStep.coagencyOptRegData?.memo?.length ?? 0}</b>/40
              </p>
            </button>
          </div>

          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물설명을 작성해주세요.</p>
            <button className={cn(CommonStyles['textarea-div'], CommonStyles.type2)}>
              <textarea
                onClick={handleInputClick}
                id="detail_memo"
                name="detail_memo"
                value={shareRegStep.coagencyOptRegData?.detail_memo || ''}
                onChange={(e) => handleMemo(e)}
                placeholder="상세 페이지에 노출되는 문구입니다. 1000자 이내로 작성해주세요."
              />
              <p className={CommonStyles.byte}>
                <b>{shareRegStep.coagencyOptRegData?.detail_memo?.length ?? 0}</b>/1000
              </p>
            </button>
          </div>
        </section>
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles.title}>고객 정보를 입력해주세요.</p>
            <p className={CommonStyles['sub-txt']}>
              *비공개 정보로 나만 보는 매물 관리 정보가 필요하신 경우 등록하세요. 타인에게 노출되지 않습니다.
            </p>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>고객 정보를 입력해주세요.</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
              <button className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  type="text"
                  id="customer_name"
                  name="customer_name"
                  value={shareRegStep?.coagencyOptRegData?.customer_name || ''}
                  onChange={(e) => handleValueChange(e)}
                  placeholder="고객명(비공개)"
                  className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                  maxLength={10}
                />
              </button>
              <button
                className={CommonStyles.dropdown}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_select_customer_relation',
                      multiModalType: '', // 멀티모달은 초기화
                      data: relationList,
                      isOpen: true,
                    }),
                  )
                }
              >
                <span>
                  {shareRegStep?.coagencyOptRegData?.owner_type?.code
                    ? shareRegStep?.coagencyOptRegData?.owner_type?.name
                    : '선택'}
                </span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
            </div>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>고객 연락처</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.full)}>
              <input
                onClick={handleInputClick}
                type="tel"
                id="phone_num"
                name="phone_num"
                value={shareRegStep?.coagencyOptRegData?.phone_num || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                onChange={(e) => {
                  handlePhoneNumber(e);
                }}
                placeholder="고객연락처(비공개)"
                style={{ width: '100%' }}
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={13}
              />
            </div>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>중개사 메모</p>
            <button className={cn(CommonStyles['textarea-div'], CommonStyles.type2)}>
              <textarea
                onClick={handleInputClick}
                id="hidden_memo"
                name="hidden_memo"
                placeholder="중개사 메모(비공개)"
                value={shareRegStep?.coagencyOptRegData?.hidden_memo || ''}
                onChange={(e) => handleMemo(e)}
                maxLength={1000}
              />
              <p className={CommonStyles.byte}>
                <b>{shareRegStep.coagencyOptRegData?.hidden_memo?.length ?? 0}</b>/1000
              </p>
            </button>
          </div>
          {(shareRegStep.coagencyOptRegData?.customer_name || shareRegStep.coagencyOptRegData?.phone_num) && (
            <div className={cn(CommonStyles['check-box'], CommonStyles['agree-chk'])}>
              <input
                type="checkbox"
                id="agree"
                checked={isTermsAgree}
                onChange={() =>
                  dispatch(
                    ActionModal.openModal({
                      isOpen: true,
                      modalType: 'coagency_bottom_privacy_info',
                      data: {
                        isReg: true,
                      },
                      action: setIsTermsAgree(true),
                    }),
                  )
                }
              />
              <label htmlFor="agree" className={CommonStyles['label-info']}>
                (선택) 상기 개인정보의 수집, 이용에 대해 고객으로부터 동의를 받았습니다.
              </label>
            </div>
          )}
        </section>
      </div>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        // style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        {/* <button
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
          onClick={() =>
            dispatch(
              ActionModal.openModal({
                modalType: 'coagency_detail_registration_cancel',
                isOpen: true,
              }),
            )
          }
        >
          취소
        </button> */}
        <button
          ref={submitBtnRef}
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg)}
          onClick={(e) => (shareRegStep.coagencyRegData.regId ? handleDetailSubmit() : handleEasySubmit('all'))}
        >
          등록하기
        </button>
      </div>
    </>
  );

  console.group();
  console.log('stateDetailAddr', stateDetailAddr);
  console.log('statePrice', statePrice);
  console.log('stateMoveIn', stateMoveIn);
  console.log('mygroupList', mygroupList);
  console.log('relationList', relationList);
  // console.log('title', title);
  console.log('shareRegStep', shareRegStep);
  console.log('squre outer===>', shareRegStep?.selectedAddr);
  console.groupEnd();

  return (
    <>
      <p
        className={cn(CommonStyles['main-title'], currStep >= 9 && CommonStyles.pt0)}
        style={currStep === 11 ? { display: 'none' } : {}}
      >
        {tit}
      </p>
      {shareRegStep.currStep === 1 && renderTypeList}
      {shareRegStep.currStep === 4 && renderDongHo}
      {shareRegStep.currStep === 5 && REMainType === 'B' && renderForSaleRight}
      {shareRegStep.currStep === 5 && REMainType !== 'B' && renderDetailAddr}
      {shareRegStep.currStep === 6 && renderSaleType}
      {shareRegStep.currStep === 7 && renderPriceInput}
      {shareRegStep.currStep === 8 && renderMoveinDate}
      {shareRegStep.currStep === 9 && renderCheckValue}
      {shareRegStep.currStep === 10 && renderSharePoint}
      {shareRegStep.currStep === 11 && renderDetailWriting}
    </>
  );
};

export default CoagencyRegStep;
