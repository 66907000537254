import { useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userSelector } from 'redux/slices/user';

import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Styles from 'styles/header.module.css';

const BrokerTemporary = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { me } = useSelector(userSelector);
  const [agree, setAgree] = useState<boolean>(true);
  const { state } = location;
  const [param, setParam] = useState(state);

  const handleAgreeConfirm = () => {
    if (agree === true) {
      navigate('/broker/invite', { state: { invite_agree: 'Y' } });
    }
  };

  //헤더 뒤로가기 예외
  const historyBack = () => {
    if (param?.locationHistory == 'sync') {
      navigate(-1);
    } else {
      dispatch(
        ActionModal.openModal({
          modalType: 'broker_confirm',
          isOpen: true,
          data: {
            txt: '대표님 초대하기를 나중에 하시겠습니까?',
            actionNavi: () => {
              if (window.webkit) {
                window.webkit.messageHandlers.moveTab.postMessage(JSON.stringify({ tabIndex: 0, parameter: '' }));
                window.webkit.messageHandlers.close.postMessage();
              } else if (window.Android) {
                window.Android.moveTab(JSON.stringify({ tabIndex: 0, parameter: '' }));
                window.Android.close();
              }
            },
          },
        }),
      );
    }
  };

  return (
    <>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={historyBack}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>
        <div className={Styles.title}>중개사 인증</div>
        <div className={cn(Styles.right, CommonStyles.right)}></div>
      </header>
      <div className={cn(CommonStyles.container)}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={cn(CommonStyles['content-div'], CommonStyles.invite)}>
            <div
              className={cn(ResetStyles['ta-c'], ResetStyles['d-flex'], CommonStyles['ta-c'], CommonStyles['d-flex'])}
            >
              <div className={CommonStyles['txt-wrap']}>
                <strong className={CommonStyles['big-txt']}>{me.name}님,</strong>
                <p className={CommonStyles['page-txt']}>
                  소속되어있는 사업장의
                  <br />
                  <b className={cn(ResetStyles['fw-b'], CommonStyles['co-dorg'])}>
                    대표님이 가입을 완료해야 사용할 수 있습니다.
                  </b>
                </p>
              </div>
              <div className={CommonStyles['msg-wrap']}>
                <i className={CommonStyles['icon-invite']}></i>
                <p className={CommonStyles['page-txt']}>
                  대표님께 가입 초대장 알림을 보내서
                  <br />
                  가입을 요청 해보세요
                </p>
              </div>
              {/* <div className={CommonStyles['check-box-div']}>
                <div className={CommonStyles['check-box']}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    id="chk1"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                  />
                  <label htmlFor="chk1" className={CommonStyles['label-info']}>
                    <span className={CommonStyles['co-red']}>[필수]</span>
                  </label>
                  &nbsp;
                  <span
                    className={cn(CommonStyles['label-info'], CommonStyles['text-underline'])}
                    onClick={() => dispatch(ActionModal.openModal({ modalType: 'broker_bottom_invite', isOpen: true }))}
                  >
                    개인정보 수집·이용 동의
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles.fixed, CommonStyles['content-certify-btn-wrap'])}>
        <button
          className={cn(CommonStyles.btn, CommonStyles.lg, !agree ? CommonStyles.disabled : '')}
          onClick={handleAgreeConfirm}
        >
          대표님께 가입 초대장 보내기
        </button>
        <p className={CommonStyles['info-txt']}>이실장넷 내정보에서도 가입 초대장 보내기를 할 수 있어요.</p>
      </div>
    </>
  );
};

export default BrokerTemporary;
