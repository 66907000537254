import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false,
};

/**
 * @description login
 * @Class
 * @category redux
 * @subcategory slice
 */
const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLogin = action.payload;
    },
    logout: (state, action) => {
      state.isLogin = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { login, logout } = loginSlice.actions;

export default loginSlice;
