/* eslint-disable no-dupe-else-if */
import { useState, useEffect, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { batch } from 'react-redux';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionDetail from 'redux/slices/coagencydetail';
import * as ActionLoader from 'redux/slices/loading';
import cn from 'classnames';
import qs from 'qs';
import _ from 'lodash';
import ScrollLoader from 'components/common/Loader/ScrollLoader';

// components
import MoveInDatePicker from 'components/common/DatePicker/MoveInDatePicker';
import moment from 'moment';
import { IMixedKeyValue } from '<modal>';
import { TClickDate } from 'components/common/DatePicker/MoveInDatePicker';
// styles
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

// configs
import * as Config from 'configs/configs';
// utils
import { rmComma, getOfferGbnCode } from 'utils/common';
import { formatNumber } from 'utils';

import network from 'utils/network';
type TbuildingType = {
  dongNm: string;
  gbnCodeNm: string;
  mngPk: string;
  sitePos: string;
};

type TFloorType = {
  buildingPk: string;
  floorNm: string;
  hoNm: string;
  useNm: string;
  etcNm: string;
  sqr: string;
};

type TAreaType = {
  buildSqr: string | null;
  buildingNm: string | null;
  buildingPk: string | null;
  code: string | null;
  dongNm: string | null;
  excSqr: string | null;
  gbnCodeNm: string | null;
  mngPk: string | null;
  sitePos: string | null;
  siteSqr: string | null;
  supSqr: string | null;
  useSqr: string | null;
};

interface Props {
  type?: string;
  title?: string;
}

/**
 * @description 공동중개 common sheet
 * @Class
 * @category Components
 * @subcategory coagency sheet
 * @component
 * @returns {JSX.Element}
 */
const CoagencyBotSheet = (_props: Props) => {
  const dispatch = useAppDispatch();
  const { title } = _props;
  const { isCommentLoading } = useAppSelector((state: RootState) => state.loading);

  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const detailGlobal = useAppSelector((state: RootState) => state.coagencydetail);
  const regState = useAppSelector((state: RootState) => state.coagencydetail.regState);

  const { multiModalType, modalType, data, isOpen, action } = useAppSelector((state: RootState) => state.modal);
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const [selectedMoveDate, setSelectedMoveDate] = useState<TClickDate | null>(null);
  const [monthlyTerm, setMonthlyTerm] = useState('');

  // 면적
  const [buildingArea, setBuildingArea] = useState({
    supArea: '',
    conArea: '',
    excArea: '',
  });

  // 건축물 대장
  const [isStep, setIsStep] = useState('step01');
  const initBuilding = {
    dongNm: '',
    gbnCodeNm: '',
    mngPk: '',
    sitePos: '',
  };
  const initFloor = {
    buildingPk: '',
    floorNm: '',
    hoNm: '',
    useNm: '',
    etcNm: '',
    sqr: '',
  };
  const initAreaInfo = {
    buildSqr: '',
    buildingNm: '',
    buildingPk: '',
    code: '',
    dongNm: '',
    excSqr: '',
    gbnCodeNm: '',
    mngPk: '',
    sitePos: '',
    siteSqr: '',
    supSqr: '',
    useSqr: '',
  };

  const [buildingInfo, setBuildingInfo] = useState<TbuildingType[]>([]);
  const [floorInfo, setFloorInfo] = useState<TFloorType[]>([]);

  const [areaInfo, setAreaInfo] = useState<TAreaType>(initAreaInfo);
  const [floorType, setFloorType] = useState('partial');

  //** 내 공유매물 수정 */
  const [complexList, setComplexList] = useState([]);
  const [state, setState] = useState<IMixedKeyValue>({
    complexAddr: {},
    dongAddr: {},
    ho: '',
    dongHide: false,
    addr_gbn: '',
    addr: {
      street_1: '',
      street_2: '',
      detail_use: '',
      detail: '',
    },
    offer_gbn: '',
    deal_type_name: '',
    tit: '',
    sellPrc: '',
    leasePrc: '',
    depositPrc: '',
    monthlyPrc: '',
    salePrc: '',
    premiumPrc: '',
    optionPrc: '',
    is_move_chg: '',
    move_gbn: '',
    move_ymd: '',
    tel_display: '',
    status_share: 'Y',
    shares: [],
  });
  const {
    ho,
    dongHide,
    offer_gbn,
    deal_type_name,
    sellPrc,
    leasePrc,
    depositPrc,
    monthlyPrc,
    salePrc,
    premiumPrc,
    optionPrc,
    is_move_chg,
    move_gbn,
    move_ymd,
    tel_display,
    status_share,
    shares,
  } = state;

  const sellPrcRef = useRef<HTMLInputElement>(null);
  const leasePrcRef = useRef<HTMLInputElement>(null);
  const depositPrcRef = useRef<HTMLInputElement>(null);
  const monthlyPrcRef = useRef<HTMLInputElement>(null);
  const salePrcRef = useRef<HTMLInputElement>(null);
  const premiumPrcRef = useRef<HTMLInputElement>(null);
  const optionPrcRef = useRef<HTMLInputElement>(null);

  // 내 모임리스트
  const [mygroupList, setMyGroupList] = useState([]);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  /**
   * @description 건축물 대장 정보 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const f = async () => {
      if (modalType === 'coagency_bottom_building_info' && data?.areaCd) {
        setIsStep('step01');
        await getBuildingInfo(data!);
      }
    };
    f();
    return () => abortController.abort();
  }, [modalType, data]);

  useEffect(() => {
    const f = async () => {
      if (shareRegStep?.targetBuilding?.building?.mngPk) {
        setIsStep('step01');
        await getFloorInfo();
      }
    };
    f();
  }, [shareRegStep.targetBuilding.building]);

  useEffect(() => {
    if (areaInfo.code === '0000') {
      setBuildingArea({
        ...buildingArea,
        supArea: areaInfo?.supSqr || '',
        excArea: areaInfo?.useSqr || '',
      });
    }
  }, [areaInfo]);

  /**
   * @description 내 공유매물 수정시 default의 단지정보일 경우에는 동/호 bottomsheet를 열때, complexAddr을 select함
   */
  useEffect(() => {
    if (
      (modalType === 'coagency_bottom_complex_select' || modalType === 'coagency_bottom_dong_ho_select') &&
      complexList?.length
    ) {
      const _c = complexList?.find((item: IMixedKeyValue) => item.complex_cd === data?.complexAddr?.complex_cd);
      dispatch(ActionDetail.setComplex({ complexAddr: _c }));
      setState({
        ...state,
        complexAddr: _c,
      });
    }
  }, [modalType, complexList]);

  /**
   * @description detailGlobal.selectedAddr.dongAddr change side effect
   */
  useEffect(() => {
    if (modalType === 'coagency_bottom_dong_ho_select' && detailGlobal?.selectedAddr?.dongAddr?.dong_cd) {
      setState({
        ...state,
        complexAddr: detailGlobal.selectedAddr.complexAddr!,
        dongAddr: detailGlobal.selectedAddr.dongAddr,
      });
    }
  }, [modalType, detailGlobal?.selectedAddr]);

  /**
   * @description detailGlobal.moveInDate change side effect
   */
  useEffect(() => {
    if (multiModalType === 'coagency_bottom_movein_calendar_twoFloor' && detailGlobal?.moveInDate?.move_ymd) {
      setState({
        ...state,
        ...detailGlobal.moveInDate,
      });
    }
  }, [multiModalType, detailGlobal.moveInDate]);

  /**
   * @description 내 공유매물 수정시 data 정보가져오기 state에 적용
   * @description 내 공유매물 수정시 거래유형 local state
   */
  useEffect(() => {
    if (modalType === 'coagency_bottom_dong_ho_select' && data) {
      setState({
        ...state,
        dongAddr: data?.dongAddr,
        ho: data?.hoAddr,
        dongHide: data?.dong_hide === 'Y' ? true : false,
      });
    }
    if (modalType === 'coagency_bottom_sale_type_select' && data) {
      setState({
        ...state,
        offer_gbn: data?.offerGbn,
      });
    }

    if (modalType === 'coagency_bottom_sale_type_select_register' && data) {
      setState({
        ...state,
        offer_gbn: data?.offer_gbn,
      });
    }

    if (modalType === 'coagency_bottom_price_input' && data) {
      setState({
        ...state,
        tit: `${Config.COAGENCY_REG_OFFER_GBN.find((item: IMixedKeyValue) => item.value === data?.offerGbn)
          ?.label}가를 작성해주세요.`,
        offer_gbn: data?.offerGbn,
        sellPrc:
          data?.offerGbn === 'S'
            ? data?.price_info[0]?.value && Number(data?.price_info[0]?.value).toLocaleString()
            : '', // 매매가
        leasePrc:
          data?.offerGbn === 'L'
            ? data?.price_info[0]?.value && Number(data?.price_info[0]?.value).toLocaleString()
            : '', // 전세가
        depositPrc:
          data?.offerGbn === 'M' || data?.offerGbn === 'T'
            ? data?.price_info[0]?.value && Number(data?.price_info[0]?.value).toLocaleString()
            : '', // 보증금
        monthlyPrc:
          data?.offerGbn === 'M' || data?.offerGbn === 'T'
            ? data?.price_info[1]?.value && Number(data?.price_info[1]?.value).toLocaleString()
            : '', // 월세
        salePrc:
          ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
            data?.offerGbn === 'S' &&
            data?.price_info[0]?.value &&
            Number(data?.price_info[0]?.value).toLocaleString()) ||
          '', // 분양권 - 분양가
        premiumPrc:
          ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
            data?.offerGbn === 'S' &&
            data?.price_info[1]?.value &&
            Number(data?.price_info[1]?.value).toLocaleString()) ||
          '', // 분양권 - 프리미엄분양가
        optionPrc:
          ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
            data?.offerGbn === 'S' &&
            data?.price_info[2]?.value &&
            Number(data?.price_info[2]?.value).toLocaleString()) ||
          '', // 분양권 - 분양옵션가
      });
    }

    if (modalType === 'coagency_bottom_price_input_register' && data) {
      setState({
        ...state,
        tit: `${Config.COAGENCY_REG_OFFER_GBN.find((item: IMixedKeyValue) => item.value === data?.offer_gbn)
          ?.label}가를 작성해주세요.`,
        offer_gbn: data?.offer_gbn,
        sellPrc: data?.offer_gbn === 'S' ? data?.sell_prc && Number(data?.sell_prc).toLocaleString() : '', // 매매가
        leasePrc: data?.offer_gbn === 'L' ? data?.lease_prc && Number(data?.lease_prc).toLocaleString() : '', // 전세가
        depositPrc:
          data?.offer_gbn === 'M' || data?.offer_gbn === 'T'
            ? data?.deposit_prc && Number(data?.deposit_prc).toLocaleString()
            : '', // 보증금
        monthlyPrc:
          data?.offer_gbn === 'M' || data?.offer_gbn === 'T'
            ? data?.monthly_prc && Number(data?.monthly_prc).toLocaleString()
            : '', // 월세
        salePrc:
          ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
            data?.offer_gbn === 'S' &&
            data?.parcel_prc &&
            Number(data?.parcel_prc).toLocaleString()) ||
          '', // 분양권 - 분양가
        premiumPrc:
          ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
            data?.offer_gbn === 'S' &&
            data?.premium_prc &&
            Number(data?.premium_prc).toLocaleString()) ||
          '', // 분양권 - 프리미엄분양가
        optionPrc:
          ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
            data?.offer_gbn === 'S' &&
            data?.parcel_option_prc &&
            Number(data?.parcel_option_prc).toLocaleString()) ||
          '', // 분양권 - 분양옵션가
      });
    }

    if (modalType === 'coagency_bottom_movein_select' && data) {
      setState({
        ...state,
        move_gbn: data?.moveGbn,
        is_move_chg: data?.isMoveChg,
        move_ymd: data?.moveYmd,
      });
    }

    if (modalType === 'coagency_bottom_movein_select_register' && data) {
      setState({
        ...state,
        move_gbn: data?.move_gbn,
        is_move_chg: data?.is_move_chg,
        move_ymd: data?.move_ymd,
      });
    }

    if (modalType === 'coagency_bottom_tel_display' && data) {
      setState({
        ...state,
        tel_display: data?.telDisplay || '',
      });
    }
    if (modalType === 'coagency_bottom_share_input' && data?.id) {
      setState({
        ...state,
        status_share: data?.status_share,
        shares: data?.shares || [],
      });
    }
  }, [modalType, data]);

  /**
   * @description 단지 리스트 가져오기 side effect
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      dispatch(ActionLoader.onCommentLoad());
      if (data?.sidoAddr?.cd && data?.guGunAddr?.cd && data?.eupMyeonDongAddr?.cd) {
        const complexParams = complexParamFunc(data?.eupMyeonDongAddr.cd);
        await getComplexList(complexParams);
      } else if (data?.sidoAddr?.cd && data?.guGunAddr?.cd && data?.eupMyeonDongAddr?.cd && data?.liAddr?.cd) {
        const complexParams = complexParamFunc(data?.liAddr.cd);
        await getComplexList(complexParams);
      }
    };
    getData();
    return () => abortController.abort();
  }, [data]);

  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (modalType === 'coagency_bottom_share_input') {
        await getMyGroupList();
      }
    };
    getData();
    return () => abortController.abort();
  }, [modalType, data]);

  /**
   * @description 단지정보 호출시 params
   * @param {any} p
   * @returns {object} params
   */
  const complexParamFunc = useCallback(
    (p: string): object => {
      const params = {
        area_cd: p,
        complex_gbn:
          data?.offerings_gbn === 'AB'
            ? 'AP'
            : data?.offerings_gbn === 'JB'
            ? 'JS'
            : data?.offerings_gbn === 'OB'
            ? 'OP'
            : data?.offerings_gbn,
        is_rebuild: data?.offerings_gbn === 'RC' ? 'Y' : '',
        is_parcel: getOfferGbnCode(data?.offerings_gbn) === 'B' ? 'Y' : '',
      };
      return params;
    },
    [data],
  );

  /**
   * @description 공동주택 단지정보 api call
   * @returns {Promise<void>}
   */
  const getComplexList = async (params: IMixedKeyValue): Promise<void> => {
    const joinPath = `${Config.API_URL.GET_OFFERS_COMPLEXES}?${qs.stringify(params)}`;
    try {
      const resComplex = await network().coagency().getCommonList(joinPath);
      const { data, status } = resComplex;
      if (status === 200) {
        setComplexList(data?.data);
        dispatch(ActionLoader.offCommentLoad());
      }
    } catch (err) {
      console.error('err', err);
      setComplexList([]);
      dispatch(ActionLoader.offCommentLoad());
    }
  };

  /**
   * @description 내 모임목록 가져오기
   * @returns {Promise<void>}
   */
  const getMyGroupList = async (): Promise<void> => {
    try {
      const res = await network().coagency().getCommonList(Config.API_URL.GET_OFFERS_MYGROUP);
      const { data, status } = res;
      if (status === 200) {
        console.log('getMyGroupList data==>', data);
        let _data = res.data;
        const defaultShare = {
          id: 99999,
          group_id: 99999,
          name: '전체 공유 (공동중개 게시판)',
          code: 'P',
        };
        _data = [defaultShare, ..._data];
        const _shares = _.uniqBy([defaultShare], 'id');
        setMyGroupList(_data);
      }
    } catch (err) {
      console.error(err);
      setMyGroupList([]);
    }
  };

  /**************************건축물대장 api********************/
  /**
   * @description 빌딩정보 가져오기
   * @param {object} d {areaCd: null, houseNo: '' }
   * @param {number} d.areaCd
   * @param {string} d.houseNo
   * @returns {Promise<void>}
   */
  const getBuildingInfo = async (d: IMixedKeyValue): Promise<void> => {
    try {
      const resAddr = await network()
        .coagency()
        .postBuilding(Config.API_URL.POST_OFFERS_BUILDINGINFO, JSON.stringify(d));
      const { data, status } = resAddr;
      if (status === 200) {
        console.log('data==>', data);
        setBuildingInfo(data.data.data);
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  /**
   * @description 비공동주택 층정보확인
   * @returns {Promise<void>}
   */
  const getFloorInfo = async (): Promise<void> => {
    const params = {
      areaCd: data?.areaCd,
      houseNo: data?.houseNo,
      type: 'floor',
      mngPk: shareRegStep?.targetBuilding?.building?.mngPk,
    };
    try {
      const resAddr = await network()
        .coagency()
        .postBuilding(Config.API_URL.POST_OFFERS_FLOORINFO, JSON.stringify(params));
      const { data, status } = resAddr;
      if (status === 200) {
        console.log('data==>', data);
        setFloorInfo(data.data.data);
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  /**
   * @description 비공동주택 C, D 면적정보확인
   * @returns {Promise<void>}
   */
  const getAreaInfo = async (): Promise<void> => {
    const params = {
      type: 'floor',
      mngPk: shareRegStep?.targetBuilding?.building.mngPk, // '41480-42704',
      buildingPk: shareRegStep?.targetBuilding?.floor?.buildingPk, // '41480-91300',
      floorNm: floorType === 'all' ? 'all' : shareRegStep?.targetBuilding?.floor?.floorNm, // '지1층',
      hoNm: 'B01', // 정말 아무값을 넣어도 될까요? // 확인필요합니다.
      useNm: shareRegStep?.targetBuilding?.floor?.useNm,
      etcNm: shareRegStep?.targetBuilding?.floor?.etcNm,
      sqr: shareRegStep?.targetBuilding?.floor?.sqr, // '59.31',
    };
    try {
      const resAddr = await network()
        .coagency()
        .postBuilding(Config.API_URL.POST_OFFERS_AREAINFO, JSON.stringify(params));
      const { data, status } = resAddr;
      if (status === 200) {
        console.log('data==>', data);
        const _data = data?.data;
        setAreaInfo(_data);
        dispatch(ActionCoagency.setTargetArea({ a: _data }));
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  /**************************건축물대장 api end********************/

  /**
   * @description modal close
   * @returns {void}
   */
  const handleModalClose = (): void => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    }, 200);
  };

  const handleNextStep = async () => {
    setIsStep('step02');
    await getAreaInfo();
  };

  /**
   * @description 면적 적용하기
   * @returns {void}
   */
  const handleApplyArea = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    dispatch(
      ActionCoagency.setArea({
        a: {
          supArea: Number(buildingArea?.supArea) || null, // 변경된 localState값 대체
          conArea: Number(buildingArea?.conArea) || null, // 변경된 localState값 대체
          excArea: Number(buildingArea?.excArea) || null, // 변경된 localState값 대체
          lotArea: Number(areaInfo?.siteSqr) || null,
          totArea: Number(areaInfo?.excSqr) || null, // 총면적 = 연면적( areaInfo?.excSqr)
          bulArea: Number(areaInfo?.buildSqr) || null,
        },
      }),
    );
    // console.log('면적적용하기 완료');
    handleModalClose();
  };

  /*************단지정보 수정변경 bottomsheet******************/
  /**
   * @description 단지정보를 localState complexAddr에 수정 적용하기
   * @param {object} c complex object
   * @returns {void}
   */
  const handleComplexChange = (c: IMixedKeyValue): void => {
    setState({
      ...state,
      complexAddr: c,
    });
  };

  /**
   * @description 단지정보 regState redux에 저장
   * @returns {void}
   */
  const handleModifyComplex = (): void => {
    dispatch(
      ActionDetail.setRegState({
        ...regState,
        complexAddr: state?.complexAddr,
      }),
    );
    handleModalClose();
  };
  /*************단지정보 수정변경 bottomsheet end******************/

  /*************동/호 정보 수정변경 bottomsheet******************/
  /**
   * @description 동/호 정보 redux에 저장
   * @returns {void}
   */
  const handleModifyDongHo = (): void => {
    dispatch(
      ActionDetail.setRegState({
        ...regState,
        dongAddr: state.dongAddr,
        hoAddr: ho,
        dong_hide: state.dongHide ? 'Y' : 'N',
      }),
    );
    handleModalClose();
  };

  /*************상세주소 수정변경 bottomsheet******************/
  const handleModifyDetailAddr = () => {
    console.log('상세주소 수정변경');
    dispatch(
      ActionDetail.setRegState({
        ...regState,
        addrGbn: state?.addr_gbn,
        streetStart: state?.addr.street_1,
        streetEnd: state?.addr.street_2,
        detailUse: state?.addr.detail_use,
        detailAddr: state?.addr.detail,
      }),
    );
    handleModalClose();
  };

  /*************거래유형 수정변경 bottomsheet******************/
  /**
   * @description 거래유형 수정값(offer_gbn, price_info)를 redux에 저장
   * @returns {void}
   */
  const handleModifyOffer = (type: string): void => {
    if (data?.isModify) {
      const saleType =
        state.offer_gbn === 'S'
          ? '매매'
          : state.offer_gbn === 'L'
          ? '전세'
          : state.offer_gbn === 'M'
          ? '월세'
          : state.offer_gbn === 'T'
          ? '단기임대'
          : '';
      action?.(state.offer_gbn, saleType);
    } else {
      const _newPriceInfo: IMixedKeyValue =
        offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
          ? [
              { name: '분양가', value: salePrc ?? '' },
              { name: '프리미엄분양가', value: premiumPrc ?? '' },
              { name: '분양옵션가', value: optionPrc ?? '' },
            ]
          : offer_gbn === 'M' || offer_gbn === 'T'
          ? [
              { name: '보증금', value: depositPrc ?? '' },
              { name: '월세', value: monthlyPrc ?? '' },
            ]
          : offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) !== 'B'
          ? [{ name: '매매가', value: sellPrc ?? '' }]
          : offer_gbn === 'L'
          ? [{ name: '전세가', value: leasePrc ?? '' }]
          : [{ name: '', value: '' }];
      if (type === 'edit') {
        dispatch(
          ActionDetail.setRegState({
            ...regState,
            offerGbn: state?.offer_gbn,
            price_info: _newPriceInfo,
            isPriceChange: true,
          }),
        );
      } else if (type === 'register') {
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              coagencyRegData: {
                ...shareRegStep.coagencyRegData,
                offer_gbn: state?.offer_gbn,
                price_info: _newPriceInfo,
                isPriceChange: true,
              },
            },
          }),
        );
      }
    }
    handleModalClose();
  };

  /**
   * @description 숫자 입력시 천단위마다 comma처리하여 표시
   * @param {string} value
   * @param {string} type
   * @param {string} zero 0값 허용(allow) / 불허(deny)
   * @param {string} negative 음수값 허용(allow) / 불허(deny)
   * @returns {void}
   */
  const handleNumberChange = (
    value: string,
    type: string,
    zero?: 'allow' | 'deny',
    negative?: 'allow' | 'deny',
  ): void => {
    let _value = value;
    const _zero = zero ?? 'deny';
    const _negative = negative ?? 'deny';

    _value = rmComma(value);
    if (_value === '0' && _zero === 'deny') {
      value = '';
    } else if (_value === '-' && _negative === 'deny') {
      value = '';
    } else if (_value !== '' && _value !== '-') {
      value = Number(_value).toLocaleString('ko-KR');
    }
    setState({
      ...state,
      [type]: value,
    });
  };

  /*************거래유형별 가격 수정변경 bottomsheet******************/
  const handleModifyOfferPrice = (type: string) => {
    // 가격수정
    const _newPriceInfo: IMixedKeyValue =
      offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
        ? [
            { name: '분양가', value: salePrc ? Number(String(salePrc).replace(/,/g, '')) : null },
            {
              name: '프리미엄분양가',
              value: premiumPrc ? Number(String(premiumPrc).replace(/,/g, '')) : null,
            },
            {
              name: '분양옵션가',
              value: optionPrc ? Number(String(optionPrc).replace(/,/g, '')) : null,
            },
          ]
        : offer_gbn === 'M' || offer_gbn === 'T'
        ? [
            {
              name: '보증금',
              value: depositPrc ? Number(String(depositPrc).replace(/,/g, '')) : null,
            },
            {
              name: '월세',
              value: monthlyPrc ? Number(String(monthlyPrc).replace(/,/g, '')) : null,
            },
          ]
        : offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) !== 'B'
        ? [{ name: '매매가', value: sellPrc ? Number(String(sellPrc).replace(/,/g, '')) : null }]
        : offer_gbn === 'L'
        ? [{ name: '전세가', value: leasePrc ? Number(String(leasePrc).replace(/,/g, '')) : null }]
        : [{ name: '', value: null }];
    if (type === 'edit') {
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          price_info: _newPriceInfo,
          salePrc:
            offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
              ? Number(String(salePrc).replace(/,/g, ''))
              : null,
          premiumPrc:
            offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
              ? Number(String(premiumPrc).replace(/,/g, ''))
              : null,
          optionPrc:
            offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
              ? Number(String(premiumPrc).replace(/,/g, ''))
              : null,
          sellPrc:
            offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) !== 'B'
              ? Number(String(sellPrc).replace(/,/g, ''))
              : null,
          leasePrc: offer_gbn === 'L' ? Number(String(leasePrc).replace(/,/g, '')) : null,
          depositPrc: offer_gbn === 'M' || offer_gbn === 'T' ? Number(String(depositPrc).replace(/,/g, '')) : null,
          monthlyPrc: offer_gbn === 'M' || offer_gbn === 'T' ? Number(String(monthlyPrc).replace(/,/g, '')) : null,
        }),
      );
    } else {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              price_info: _newPriceInfo,
              parcel_prc:
                offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
                  ? Number(String(salePrc).replace(/,/g, ''))
                  : null,
              premium_prc:
                offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
                  ? Number(String(premiumPrc).replace(/,/g, ''))
                  : null,
              parcel_option_prc:
                offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B'
                  ? Number(String(premiumPrc).replace(/,/g, ''))
                  : null,
              sell_prc:
                offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) !== 'B'
                  ? Number(String(sellPrc).replace(/,/g, ''))
                  : null,
              lease_prc: offer_gbn === 'L' ? Number(String(leasePrc).replace(/,/g, '')) : null,
              deposit_prc: offer_gbn === 'M' || offer_gbn === 'T' ? Number(String(depositPrc).replace(/,/g, '')) : null,
              monthly_prc: offer_gbn === 'M' || offer_gbn === 'T' ? Number(String(monthlyPrc).replace(/,/g, '')) : null,
            },
          },
        }),
      );
    }
    if (modalType === 'coagency_bottom_price_input_register') {
      dispatch(ActionModal.openModal({ modalType: 'coagency_price', isOpen: false }));
    }
    // console.log('price_info array change Ok');
    handleModalClose();
  };

  /*************입주가능일 수정변경 bottomsheet******************/
  /**
   * @description 내 공유매물 수정에서 입주가능일 선택 수정
   * @returns {void}
   */
  const handleModifyMoveInDay = (type: string): void => {
    if (type === 'edit') {
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          moveGbn: state?.move_gbn,
          isMoveChg: state?.is_move_chg,
          moveYmd: state?.move_ymd,
        }),
      );
    }

    if (type === 'register') {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            coagencyRegData: {
              ...shareRegStep.coagencyRegData,
              move_gbn: state?.move_gbn,
              is_move_chg: state?.is_move_chg,
              move_ymd: state?.move_ymd,
            },
          },
        }),
      );
    }
    console.log('입주가능일 수정완료');
    handleModalClose();
  };

  /*************연락처 노출 수정변경 bottomsheet******************/
  /**
   * @description 연락처 노출 수정변경
   * @returns {void}
   */
  const handleModifyTelDisplay = (): void => {
    if (data?.isModify) {
      action?.(state.tel_display);
    } else {
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          telDisplay: state?.tel_display,
        }),
      );
    }
    console.log('연락처 노출 수정완료');
    handleModalClose();
  };

  /*************공유정보 수정 bottomsheet******************/
  /**
   * @description 공유정보 수정변경
   * @returns {void}
   */
  const handleModifyShares = (): void => {
    dispatch(
      ActionDetail.setRegState({
        ...regState,
        status_share: state?.status_share,
        shares: state?.shares,
      }),
    );
    console.log('공유정보 수정완료');
    handleModalClose();
  };

  console.group();
  // console.log('isStep===>', isStep);
  // console.log('buildingInfo===>', buildingInfo);
  // console.log('floorInfo===>', floorInfo);
  // console.log('floorType===>', floorType);
  // console.log('areaInfo===>', areaInfo);
  // console.log('buildingArea===>', buildingArea);
  console.log('data===>', data);
  console.log('complexList===>', complexList);
  console.log('multiModalType==>', multiModalType);
  console.log('state', state);
  console.groupEnd();

  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div
          id="render-modal"
          className={cn(
            Styles['popup-container'],
            Styles['bottom-sheet-popup'],
            CommonStyles['bottom-sheet-popup'],
            modalType === 'coagency_bottom_privacy_info' && Styles['personal-info-pop'],
            modalType === 'coagency_bottom_privacy_info' && CommonStyles['personal-info-pop'],
            modalType === 'coagency_bottom_complex_select' && CommonStyles['area-select-pop'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
            modalType === 'coagency_bottom_price_input' || modalType === 'coagency_bottom_price_input_register'
              ? offer_gbn === 'M' && open
                ? Styles['price-input-pop']
                : ''
              : '',
          )}
          style={{
            maxHeight:
              modalType === 'coagency_bottom_price_input' || modalType === 'coagency_bottom_price_input_register'
                ? offer_gbn === 'M' && open
                  ? 'calc(135vh - 6.75rem)'
                  : 'calc(100vh - 6.75rem)'
                : 'calc(100vh - 6.75rem)',
          }}
        >
          <div className={Styles['popup-header']}>
            <p className={Styles['pop-tit']}>
              {modalType === 'coagency_bottom_price_input' || modalType === 'coagency_bottom_price_input_register'
                ? state?.tit
                : title}
            </p>
            <button className={Styles.close} onClick={handleModalClose} />
          </div>
          <div
            className={cn(
              Styles['popup-content'],
              CommonStyles['popup-content'],
              (modalType === 'coagency_bottom_building_info' ||
                modalType === 'coagency_bottom_building_area' ||
                modalType === 'coagency_bottom_customer_info_input' ||
                modalType === 'coagency_bottom_custom_textarea' ||
                modalType === 'coagency_bottom_room_bath_input' ||
                modalType === 'coagency_bottom_floor_input' ||
                modalType === 'coagency_bottom_price_input' ||
                modalType === 'coagency_bottom_price_input_register' ||
                modalType === 'coagency_bottom_area_select' ||
                modalType === 'coagency_bottom_dong_ho_select' ||
                modalType === 'coagency_bottom_complex_select') &&
                CommonStyles['share-write'],
            )}
          >
            {modalType === 'coagency_bottom_privacy_info' && (
              <>
                <div className={cn(Styles['round-table'], CommonStyles['round-table'])}>
                  <div className={cn(Styles.row, CommonStyles.row)}>
                    <p className={cn(CommonStyles.col, CommonStyles.bg)}>수집·이용 목적</p>
                    <p className={CommonStyles.col}>공유매물/구합니다 등록 시 고객정보 입력</p>
                  </div>
                  <div className={CommonStyles.row}>
                    <p className={cn(CommonStyles.col, CommonStyles.bg)}>수집·이용 항목</p>
                    <p className={CommonStyles.col}>고객정보(고객명, 연락처, 소유자와의 관계)</p>
                  </div>
                  <div className={CommonStyles.row}>
                    <p className={cn(CommonStyles.col, CommonStyles.bg)}>보유 및 이용기간</p>
                    <p className={CommonStyles.col}>
                      <b>회원탈퇴 또는 매물 삭제 시</b>
                    </p>
                  </div>
                </div>
                <p className={cn(Styles.txt, CommonStyles.txt)} style={{ textAlign: 'left' }}>
                  귀하는 위 동의를 거부할 권리가 있으며, 위 동의를 거부하더라도 회원서비스를 이용하실 수 있습니다. 다만,
                  동의 거부 시 상기 목적에 명시된 서비스를 제공 받으실 수 없습니다
                </p>
              </>
            )}

            {modalType === 'coagency_bottom_building_info' && (
              <>
                <section>
                  <div className={CommonStyles['insert-item']} style={{ textAlign: 'left' }}>
                    <div className={cn(Styles.txt, CommonStyles.txt)}>
                      국토부에서 제공되는 건축물대장 공공 API를
                      <br />
                      네이버 부동산 검증실패를 줄이기 위한 이실장의 면적 정보 제공 서비스입니다.
                    </div>
                  </div>
                  <div
                    className={cn(
                      Styles['tab-menu'],
                      Styles['main-tab'],
                      CommonStyles['tab-menu'],
                      CommonStyles['main-tab'],
                    )}
                  >
                    <button
                      type="button"
                      className={cn(CommonStyles.tab, isStep === 'step01' && CommonStyles.on)}
                      onClick={() => setIsStep('step01')}
                    >
                      1.건축물대장 조회
                    </button>
                    <button type="button" className={cn(CommonStyles.tab, isStep === 'step02' && CommonStyles.on)}>
                      2.면적확인
                    </button>
                  </div>
                  {isStep === 'step01' && (
                    <>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>건물선택</p>
                        <button type="button" className={cn(CommonStyles.dropdown, CommonStyles.on)}>
                          <span
                            className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                ActionModal.openMultiModal({
                                  multiModalType: 'coagency_select_building_select',
                                  multiData: { data: buildingInfo },
                                  isMultiple: true,
                                }),
                              );
                            }}
                          >
                            {shareRegStep?.targetBuilding?.building?.mngPk
                              ? `[${shareRegStep?.targetBuilding?.building?.gbnCodeNm}] ${shareRegStep?.targetBuilding?.building?.sitePos}`
                              : '선택'}
                          </span>
                          <i className={CommonStyles['icon-arrow-down-gr']}></i>
                        </button>
                      </div>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>상세주소</p>
                        <button type="button" className={cn(CommonStyles.dropdown, CommonStyles.on)}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(
                                ActionModal.openMultiModal({
                                  multiModalType: 'coagency_select_detail_addr_select',
                                  multiData: { data: floorInfo },
                                  isMultiple: true,
                                }),
                              );
                            }}
                          >
                            {shareRegStep?.targetBuilding?.floor?.buildingPk
                              ? shareRegStep?.targetBuilding?.floor?.floorNm
                              : '선택'}
                          </span>
                          <i className={CommonStyles['icon-arrow-down-gr']}></i>
                        </button>
                        <div className={cn(Styles['button-list'], CommonStyles['button-list'])}>
                          <button type="button" className={CommonStyles['radio-box']}>
                            <input
                              type="radio"
                              id="radio30"
                              name="radio30"
                              value="all"
                              checked={floorType === 'all'}
                              onChange={(e) => setFloorType(e.target.value)}
                            />
                            <label htmlFor="radio30" className={CommonStyles['label-info']}>
                              층전체
                            </label>
                          </button>
                          <button type="button" className={CommonStyles['radio-box']}>
                            <input
                              type="radio"
                              id="radio31"
                              name="radio30"
                              value="partial"
                              checked={floorType === 'partial'}
                              onChange={(e) => setFloorType(e.target.value)}
                            />
                            <label htmlFor="radio31" className={CommonStyles['label-info']}>
                              층일부
                            </label>
                          </button>
                        </div>
                        <p className={cn(CommonStyles['sm-txt'], Styles['sm-txt'])} style={{ textAlign: 'left' }}>
                          *건물 통매매 (집합건축물), 묶음매물 등 일부 케이스는 조회가 불가할 수 있습니다.
                        </p>
                      </div>
                    </>
                  )}
                  {isStep === 'step02' && (
                    <>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>조회 주소 확인</p>
                        <button className={cn(CommonStyles.dropdown, CommonStyles.on)}>
                          <span className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {/* [집합] 서울특별시 광진구 능동 248-37번지 */}
                            {shareRegStep?.targetBuilding?.building?.mngPk
                              ? `[${shareRegStep?.targetBuilding?.building?.gbnCodeNm}] ${shareRegStep?.targetBuilding?.building?.sitePos}`
                              : ''}
                          </span>
                          <i className={CommonStyles['icon-arrow-down-gr']}></i>
                        </button>
                      </div>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>건축물대장 면적</p>
                        <table className={CommonStyles['gry-table']}>
                          <colgroup>
                            <col width="110" />
                            <col width="" />
                          </colgroup>
                          <tbody>
                            <tr>
                              <th>연면적</th>
                              <td style={{ textAlign: 'left' }}>
                                {areaInfo?.excSqr || '-'}
                                {areaInfo?.excSqr && <span className={CommonStyles.unit}> ㎡</span>}
                              </td>
                            </tr>
                            <tr>
                              <th>대지면적</th>
                              <td style={{ textAlign: 'left' }}>
                                {areaInfo?.siteSqr || '-'}
                                {areaInfo?.siteSqr && <span className={CommonStyles.unit}> ㎡</span>}
                              </td>
                            </tr>
                            <tr>
                              <th>건축면적</th>
                              <td style={{ textAlign: 'left' }}>
                                {areaInfo?.buildSqr || '-'}
                                {areaInfo?.buildSqr && <span className={CommonStyles.unit}> ㎡</span>}
                              </td>
                            </tr>
                            <tr>
                              <th>공급면적</th>
                              <td>
                                <div className={CommonStyles['input-area']}>
                                  <input
                                    type="text"
                                    id="brp_supSqr"
                                    value={buildingArea.supArea || ''}
                                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      if (e.target.value.length > e.target.maxLength)
                                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                                    }}
                                    onChange={(e) =>
                                      setBuildingArea({
                                        ...buildingArea,
                                        supArea: e.target.value,
                                      })
                                    }
                                    maxLength={6}
                                  />
                                  <span className={CommonStyles.unit}>㎡</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>전용면적</th>
                              <td>
                                <div className={CommonStyles['insert-item']}>
                                  <div className={CommonStyles['input-area']}>
                                    <input
                                      type="text"
                                      id="brp_useSqr"
                                      value={buildingArea.excArea || ''}
                                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.value.length > e.target.maxLength)
                                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                                      }}
                                      onChange={(e) =>
                                        setBuildingArea({
                                          ...buildingArea,
                                          excArea: e.target.value,
                                        })
                                      }
                                      maxLength={6}
                                    />
                                    <span className={CommonStyles.unit}>㎡</span>
                                  </div>
                                  <p className={cn(Styles['sm-txt'], CommonStyles['sm-txt'], CommonStyles['co-dorg'])}>
                                    (전용면적 50㎡ 이하만 원룸 등록 가능)
                                  </p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className={cn(CommonStyles['insert-item'], CommonStyles['gray-box'])}>
                        <p className={cn(Styles['sm-txt'], CommonStyles['sm-txt'], ResetStyles['fw-b'])}>주의사항</p>
                        <p className={cn(Styles['sm-txt'], CommonStyles['sm-txt'])} style={{ textAlign: 'left' }}>
                          건축물대장 상에서 확인되는 ‘주거용도’의 면적정보가 제공됩니다. <br />
                          서비스 반영 시기에 따라 신축/증축 등의 정보가 확인되지 않을 수 있습니다. 해당 경우에는
                          건축물대장을 직접 확인하시기 바랍니다.
                        </p>
                      </div>
                    </>
                  )}
                </section>
              </>
            )}

            {modalType === 'coagency_bottom_customer_info_input' && (
              <section>
                <div className={cn(CommonStyles['insert-item'], CommonStyles['insert-item'])}>
                  <p className={CommonStyles['sub-title']}>고객명</p>
                  <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                    <div className={CommonStyles['input-area']}>
                      <input type="text" placeholder="고객명(비공개)" value={'1'} />
                    </div>
                    <button type="button" className={CommonStyles.dropdown}>
                      <span>선택</span>
                      <i className={CommonStyles['icon-arrow-down-gr']}></i>
                    </button>
                  </div>
                </div>
                <div className={cn(CommonStyles['insert-item'], CommonStyles['insert-item'])}>
                  <p className={CommonStyles['sub-title']}>고객 연락처</p>
                  <div className={CommonStyles['input-area']}>
                    <input type="text" placeholder="고객연락처(비공개)" value={'2'} />
                  </div>
                </div>
              </section>
            )}

            {modalType === 'coagency_bottom_custom_textarea' && (
              <>
                <section>
                  <div className={cn(CommonStyles['insert-item'], CommonStyles['insert-item'])}>
                    {/* <!-- 매물특징 --> */}
                    <div className={cn(CommonStyles['textarea-div'], Styles['textarea-div'])}>
                      <textarea placeholder="공동중개 리스트에 노출되는 문구입니다. 40자 이내로 작성해주세요." />
                      <p className={CommonStyles.byte}>
                        <b>0</b>/40
                      </p>
                    </div>

                    {/* <!-- 매물특징 --> */}
                    <div className={cn(CommonStyles['textarea-div'], Styles['textarea-div'])}>
                      <textarea placeholder="상세 페이지에 노출되는 문구입니다. 1000자 이내로 작성해주세요." />
                      <p className={CommonStyles.byte}>
                        <b>0</b>/1000
                      </p>
                    </div>

                    {/* <!-- 중개사 메모 --> */}
                    <div className={cn(CommonStyles['textarea-div'], Styles['textarea-div'])}>
                      <textarea placeholder="중개사 메모(비공개)" />
                      <p className={CommonStyles.byte}>
                        <b>0</b>/1000
                      </p>
                    </div>
                  </div>
                </section>
              </>
            )}

            {modalType === 'coagency_bottom_movein_calendar' && (
              <>
                {selectedMoveDate?.fullDate ? (
                  <p className={Styles.tit}>
                    {['B', 'C', 'D'].includes(monthlyTerm)
                      ? `${moment(selectedMoveDate?.fullDate).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                          (item) => item.cd === monthlyTerm,
                        )?.name}`
                      : moment(selectedMoveDate?.fullDate).format('YYYY-MM-DD')}
                  </p>
                ) : (
                  <p className={Styles.txt} style={{ textAlign: 'left' }}>
                    아래 캘린더에서 날짜를 선택해주세요.
                  </p>
                )}
                <MoveInDatePicker
                  selectedMoveDate={selectedMoveDate}
                  setSelectedMoveDate={setSelectedMoveDate}
                  monthlyTerm={monthlyTerm}
                  setMonthlyTerm={setMonthlyTerm}
                  handleModalClose={handleModalClose}
                />
              </>
            )}

            {/*** 내 공유매물 수정 ==> 단지정보 수정하기*/}
            {modalType === 'coagency_bottom_complex_select' && (
              <>
                <div className={CommonStyles['select-step1']}>
                  <button type="button">{data?.sidoAddr?.name}</button>
                  <i className={CommonStyles['icon-arrow-down-gr']}></i>
                  <button type="button">{data?.guGunAddr?.name}</button>
                  <i className={CommonStyles['icon-arrow-down-gr']}></i>
                  <button type="button">{data?.eupMyeonDongAddr?.name}</button>
                  {data?.liAddr?.cd && (
                    <>
                      <i className={CommonStyles['icon-arrow-down-gr']}></i>
                      <button type="button">{data?.liAddr.name}</button>
                    </>
                  )}
                </div>
                {isCommentLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      height: 'calc(100vh - 15.5rem)',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ScrollLoader />
                  </div>
                ) : (
                  <div className={CommonStyles['scroll-area']}>
                    <div className={cn(CommonStyles['select-step2'], CommonStyles.full)}>
                      {complexList?.map((item: IMixedKeyValue) => {
                        return (
                          <button
                            key={item.complex_cd}
                            type="button"
                            className={cn(
                              CommonStyles.btn,
                              item.complex_cd !== state?.complexAddr?.complex_cd && CommonStyles.line,
                            )}
                            onClick={() => handleComplexChange(item)}
                          >
                            {item.complex_nm}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}

            {/*** 내 공유매물 수정 ==> 동/호 정보수정하기*/}
            {modalType === 'coagency_bottom_dong_ho_select' && (
              <>
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>단지정보</p>
                    <div className={CommonStyles['input-area']}>
                      <input type="text" value={data?.complexAddr?.complex_nm || ''} readOnly />
                    </div>
                  </div>
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']}>동/호 정보</p>
                    <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                      <button
                        type="button"
                        className={cn(CommonStyles.dropdown, CommonStyles.on)}
                        onClick={() =>
                          dispatch(
                            ActionModal.openMultiModal({
                              multiModalType: 'coagency_select_dong_select',
                              multiData: state?.complexAddr?.dong, // localState의 complexAddr정보를 multiData로 수정
                              isMultiple: true,
                            }),
                          )
                        }
                      >
                        <span>{`${state?.dongAddr?.dong_name} 동`}</span>
                        <i className={CommonStyles['icon-arrow-down-gr']}></i>
                      </button>
                      <div className={CommonStyles['input-area']}>
                        <input
                          type="tel"
                          id="ho"
                          name="ho"
                          value={ho || ''}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          onChange={(e) =>
                            setState({
                              ...state,
                              ho: e.target.value,
                            })
                          }
                          inputMode="numeric"
                          pattern="[0-9]*"
                          maxLength={10}
                        />
                        <span className={CommonStyles.unit}>호</span>
                      </div>
                    </div>
                    <div className={CommonStyles['check-box']} style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id="chk_dong"
                        checked={dongHide || false}
                        onChange={(e) => {
                          setState({
                            ...state,
                            dongHide: e.target.checked,
                          });
                        }}
                      />
                      <label htmlFor="chk_dong" className={CommonStyles['label-info']}>
                        동 비노출
                      </label>
                    </div>
                  </div>
                </section>
              </>
            )}

            {/** 내 공유매물 수정 거래유형 선택 */}
            {(modalType === 'coagency_bottom_sale_type_select' ||
              modalType === 'coagency_bottom_sale_type_select_register') && (
              <>
                <div className={cn(CommonStyles['btn-wrap'], CommonStyles.full)}>
                  {Config.COAGENCY_REG_OFFER_GBN.map((item: IMixedKeyValue) => {
                    return (
                      <button
                        key={item.id}
                        type="button"
                        className={cn(CommonStyles.btn, CommonStyles.lg, item.value !== offer_gbn && CommonStyles.line)}
                        onClick={() =>
                          setState({
                            ...state,
                            offer_gbn: item.value,
                            deal_type_name: item.label,
                          })
                        }
                      >
                        {item.label}
                      </button>
                    );
                  })}
                </div>
              </>
            )}

            {/** 내 공유매물 수정 거래유형별 가격 선택 */}
            {(modalType === 'coagency_bottom_price_input' || modalType === 'coagency_bottom_price_input_register') && (
              <>
                <section>
                  {/* 매매가 */}
                  {offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) !== 'B' && (
                    <div className={CommonStyles['insert-item']}>
                      <div className={CommonStyles['input-area']}>
                        <input
                          ref={sellPrcRef}
                          type="tel"
                          name="sellPrc"
                          value={sellPrc ? sellPrc : ''}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          onChange={(e) => handleNumberChange(e.target.value, 'sellPrc')}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          maxLength={10}
                        />
                        <span className={CommonStyles.unit}>만원</span>
                      </div>
                      <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                        [금액:
                        <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                          {sellPrc ? formatNumber(Number(String(sellPrc).replace(/,/g, ''))) : '0'}
                        </b>
                        원]
                      </div>
                    </div>
                  )}

                  {/* 전세가 */}
                  {offer_gbn === 'L' && (
                    <div className={CommonStyles['insert-item']}>
                      <div className={CommonStyles['input-area']}>
                        <input
                          ref={leasePrcRef}
                          type="tel"
                          name="leasePrc"
                          value={leasePrc ? leasePrc : ''}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          onChange={(e) => handleNumberChange(e.target.value, 'leasePrc')}
                          inputMode="numeric"
                          pattern="[0-9]*"
                          maxLength={10}
                        />
                        <span className={CommonStyles.unit}>만원</span>
                      </div>
                      <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                        [금액:
                        <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                          {leasePrc ? formatNumber(Number(String(leasePrc).replace(/,/g, ''))) : '0'}
                        </b>
                        원]
                      </div>
                    </div>
                  )}

                  {/* 월세가 / 단기임대가 */}
                  {(offer_gbn === 'M' || offer_gbn === 'T') && (
                    <>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>보증금</p>
                        <div className={CommonStyles['input-area']}>
                          <input
                            ref={depositPrcRef}
                            type="tel"
                            name="depositPrc"
                            value={depositPrc || ''}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            onChange={(e) => handleNumberChange(e.target.value, 'depositPrc')}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={10}
                          />
                          <span className={CommonStyles.unit}>만원</span>
                        </div>
                        <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                          [금액:
                          <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                            {depositPrc ? formatNumber(Number(String(depositPrc).replace(/,/g, ''))) : '0'}
                          </b>
                          원]
                        </div>
                      </div>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>월세</p>
                        <div className={CommonStyles['input-area']}>
                          <input
                            ref={monthlyPrcRef}
                            type="tel"
                            value={monthlyPrc || ''}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            onChange={(e) => handleNumberChange(e.target.value, 'monthlyPrc')}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={10}
                          />
                          <span className={CommonStyles.unit}>만원</span>
                        </div>
                        <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                          [금액:
                          <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                            {monthlyPrc ? formatNumber(Number(String(monthlyPrc).replace(/,/g, ''))) : '0'}
                          </b>
                          원]
                        </div>
                      </div>
                    </>
                  )}

                  {/* 분양시 매매가 */}
                  {offer_gbn === 'S' && getOfferGbnCode(data?.offerings_gbn) === 'B' && (
                    <>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>분양가</p>
                        <div className={CommonStyles['input-area']}>
                          <input
                            ref={salePrcRef}
                            type="tel"
                            name="salePrc"
                            value={salePrc || ''}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            onChange={(e) => handleNumberChange(e.target.value, 'salePrc')}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={10}
                          />
                          <span className={CommonStyles.unit}>만원</span>
                        </div>
                        <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                          [금액:
                          <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                            {salePrc ? formatNumber(Number(String(salePrc).replace(/,/g, ''))) : '0'}
                          </b>
                          원]
                        </div>
                      </div>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>프리미엄분양가</p>
                        <div className={CommonStyles['input-area']}>
                          <input
                            ref={premiumPrcRef}
                            type="tel"
                            name="premiumPrc"
                            value={premiumPrc || ''}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            onChange={(e) => handleNumberChange(e.target.value, 'premiumPrc')}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={10}
                          />
                          <span className={CommonStyles.unit}>만원</span>
                        </div>
                        <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                          [금액:
                          <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                            {premiumPrc ? formatNumber(Number(String(premiumPrc).replace(/,/g, ''))) : '0'}
                          </b>
                          원]
                        </div>
                      </div>
                      <div className={CommonStyles['insert-item']}>
                        <p className={CommonStyles['sub-title']}>분양옵션가</p>
                        <div className={CommonStyles['input-area']}>
                          <input
                            ref={optionPrcRef}
                            type="tel"
                            name="optionPrc"
                            value={optionPrc || ''}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.value.length > e.target.maxLength)
                                e.target.value = e.target.value.slice(0, e.target.maxLength);
                            }}
                            onChange={(e) => handleNumberChange(e.target.value, 'optionPrc')}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            maxLength={10}
                          />
                          <span className={CommonStyles.unit}>만원</span>
                        </div>
                        <div className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                          [금액:
                          <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                            {optionPrc ? formatNumber(Number(String(optionPrc).replace(/,/g, ''))) : '0'}
                          </b>
                          원]
                        </div>
                      </div>
                    </>
                  )}
                </section>
              </>
            )}

            {/*** 내 공유매물 수정 ==> 입주가능일 수정하기*/}
            {(modalType === 'coagency_bottom_movein_select' ||
              modalType === 'coagency_bottom_movein_select_register') && (
              <>
                <div className={cn(CommonStyles['button-list'])}>
                  <button className={CommonStyles['radio-box']}>
                    <input
                      type="radio"
                      id="moveGbn_A"
                      name="moveGbn"
                      value="A"
                      checked={move_gbn === 'A'}
                      onChange={(e) =>
                        setState({
                          ...state,
                          move_gbn: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="moveGbn_A" className={CommonStyles['label-info']}>
                      즉시입주
                    </label>
                  </button>
                  <button
                    className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                    aria-expanded={true}
                  >
                    <input
                      type="radio"
                      id="moveGbn_B"
                      name="moveGbn"
                      value="S"
                      checked={['S', 'B', 'C', 'D'].includes(move_gbn)}
                      onChange={(e) =>
                        setState({
                          ...state,
                          move_gbn: e.target.value,
                        })
                      }
                    />
                    <label htmlFor="moveGbn_B" className={CommonStyles['label-info']}>
                      입주일지정
                    </label>
                  </button>

                  {['S', 'B', 'C', 'D'].includes(move_gbn) && (
                    <button
                      className={CommonStyles['calendar-btn']}
                      aria-label="option-calendar"
                      style={{ padding: '0.88rem 3.5rem 0.88rem 1rem' }}
                      onClick={() =>
                        dispatch(
                          ActionModal.openMultiModal({
                            multiModalType: 'coagency_bottom_movein_calendar_twoFloor',
                            multiData: state, // localState 값을 전송
                            isMultiple: true,
                          }),
                        )
                      }
                    >
                      {['B', 'C', 'D'].includes(state.move_gbn)
                        ? `${moment(move_ymd).format('YYYY-MM-DD')} ${Config?.monthTerm?.find(
                            (item) => item.cd === move_gbn,
                          )?.name}`
                        : moment(move_ymd).format('YYYY-MM-DD')}
                    </button>
                  )}

                  <button
                    className={cn(
                      CommonStyles['check-box'],
                      CommonStyles.revert,
                      CommonStyles['border-top'],
                      CommonStyles.mt24,
                    )}
                  >
                    {/* <input
                      type="radio"
                      id="moveGbn_C"
                      name="moveGbn"
                      value={is_move_chg || ''}
                      checked={move_gbn === 'A' && is_move_chg === 'Y'}
                      onChange={(e) =>
                        setState({
                          ...state,
                          move_gbn: 'A',
                          is_move_chg: e.target.checked ? 'Y' : 'N',
                        })
                      }
                    /> */}
                    <input
                      type="checkbox"
                      id="moveGbn_checkbox"
                      name="moveGbn_checkbox"
                      checked={state.is_move_chg === 'Y'}
                      onChange={(e) =>
                        setState({
                          ...state,
                          is_move_chg: e.target.checked ? 'Y' : 'N',
                        })
                      }
                    />
                    <label htmlFor="moveGbn_checkbox" className={CommonStyles['label-info']}>
                      입주가능일 협의
                    </label>
                  </button>
                </div>
              </>
            )}

            {/*** 내 공유매물 수정 ==> 연락처 노출 수정하기*/}
            {modalType === 'coagency_bottom_tel_display' && (
              <div className={CommonStyles['button-list']}>
                <button className={CommonStyles['radio-box']}>
                  <input
                    type="radio"
                    id="telDisplayGbn_A"
                    name="telDisplayGbn"
                    value="A"
                    checked={tel_display === 'A'}
                    onChange={(e) =>
                      setState({
                        ...state,
                        tel_display: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="telDisplayGbn_A" className={CommonStyles['label-info']}>
                    모두노출(중개업소 전화번호+휴대폰번호)
                  </label>
                </button>
                <button
                  className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                  aria-expanded="false"
                >
                  <input
                    type="radio"
                    id="telDisplayGbn_R"
                    name="telDisplayGbn"
                    value="R"
                    checked={tel_display === 'R'}
                    onChange={(e) =>
                      setState({
                        ...state,
                        tel_display: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="telDisplayGbn_R" className={CommonStyles['label-info']}>
                    중개업소 전화번호만 노출
                  </label>
                </button>
                <button className={CommonStyles['radio-box']}>
                  <input
                    type="radio"
                    id="telDisplayGbn_C"
                    name="telDisplayGbn"
                    value="C"
                    checked={tel_display === 'C'}
                    onChange={(e) =>
                      setState({
                        ...state,
                        tel_display: e.target.value,
                      })
                    }
                  />
                  <label htmlFor="telDisplayGbn_C" className={CommonStyles['label-info']}>
                    휴대폰번호만 노출
                  </label>
                </button>
              </div>
            )}

            {/*** 내 공유매물 수정 ==> 공유정보 수정하기*/}
            {modalType === 'coagency_bottom_share_input' && (
              <>
                <div className={CommonStyles['btn-wrap']}>
                  <button
                    type="button"
                    className={cn(CommonStyles.btn, state?.status_share !== 'N' && CommonStyles.line)}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({
                        ...state,
                        status_share: 'N',
                        shares: data?.isModify ? [...state.shares] : [],
                      });
                    }}
                  >
                    공유안함
                  </button>
                  <button
                    type="button"
                    className={cn(CommonStyles.btn, state?.status_share !== 'Y' && CommonStyles.line)}
                    onClick={(e) => {
                      e.preventDefault();
                      setState({
                        ...state,
                        status_share: 'Y',
                        shares: regState.shares,
                      });
                    }}
                  >
                    공유함
                  </button>
                </div>
                {state?.status_share === 'Y' && (
                  <>
                    <p className={CommonStyles['sub-txt']}>
                      *공동중개를 위한 매물공유를 원하시면 공유함을 선택해주세요. 아래 게시판은 복수선택이 가능합니다.
                    </p>
                    <div className={CommonStyles['button-list']}>
                      {mygroupList?.map((item: IMixedKeyValue) => {
                        return (
                          <button key={item.id} type="button" className={CommonStyles['check-box']}>
                            <input
                              type="checkbox"
                              id={`share_${item.id}`}
                              checked={shares?.map((k: IMixedKeyValue) => k.id).includes(item.id)}
                              value={item.id}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  shares: [...shares].some((j) => j.id === item.id)
                                    ? [...shares].filter((t) => t.id !== item.id)
                                    : [
                                        ...shares,
                                        {
                                          id: item.id,
                                          name: item.name,
                                          group_id: item.group_id,
                                          code: item.code || '',
                                        },
                                      ],
                                });
                              }}
                            />
                            <label htmlFor={`share_${item.id}`} className={CommonStyles['label-info']}>
                              {item.name}
                            </label>
                          </button>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}

            {/*** 내 공유매물 수정 ==> 면적정보 수정하기*/}
            {modalType === 'coagency_bottom_area_select' && (
              <>
                <section>
                  <div className={CommonStyles['insert-item']}>
                    <button type="button" className={CommonStyles.dropdown}>
                      <span>면적</span>
                      <i className={CommonStyles['icon-arrow-down-gr']}></i>
                    </button>
                  </div>
                </section>
              </>
            )}

            {/*** 내 공유매물 수정 ==> 방/욕실수 수정하기*/}
            {modalType === 'coagency_bottom_room_bath_input' && (
              <section>
                <div className={CommonStyles['insert-item']}>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input type="text" placeholder="방수" value="방수" />
                      <span className={CommonStyles.unit}>개</span>
                    </div>
                    <div className={CommonStyles['input-area']}>
                      <input type="text" placeholder="욕실수" value="욕실수" />
                      <span className={CommonStyles.unit}>개</span>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {/*** 내 공유매물 수정 ==> 층정보 수정하기*/}
            {modalType === 'coagency_bottom_floor_input' && (
              <section>
                <div className={CommonStyles['insert-item']}>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input type="text" placeholder="해당층" value="해당층" />
                    </div>
                    <div className={CommonStyles['input-area']}>
                      <input type="text" placeholder="총층" value="총층" />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>

          <div
            className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}
            style={isIos ? { paddingBottom: '100px' } : {}}
          >
            {modalType === 'coagency_bottom_privacy_info' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => {
                  if (data?.isModify || data?.isEdit || data?.isReg) {
                    action?.();
                  }
                  handleModalClose();
                }}
              >
                동의하기
              </button>
            )}
            {modalType === 'coagency_bottom_building_info' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={() => {
                    if (isStep === 'step01') {
                      setBuildingInfo([]);
                      batch(() => {
                        dispatch(ActionCoagency.setTargetBuilding(initBuilding));
                        dispatch(ActionCoagency.setTargetFloor(initFloor));
                        dispatch(ActionCoagency.setTargetArea(initAreaInfo));
                      });
                      setFloorType('partial');
                      handleModalClose();
                    } else {
                      setIsStep('step01');
                    }
                  }}
                >
                  취소
                </button>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                  onClick={(e) => (isStep === 'step01' ? handleNextStep() : handleApplyArea(e))}
                >
                  적용하기
                </button>
              </>
            )}
            {modalType === 'coagency_bottom_building_area' && (
              <>
                <button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  취소
                </button>
                <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}>적용하기</button>
              </>
            )}
            {modalType === 'coagency_bottom_customer_info_input' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}>수정하기</button>
            )}
            {modalType === 'coagency_bottom_custom_textarea' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
                완료
              </button>
            )}
            {modalType === 'coagency_bottom_room_bath_input' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}>수정하기</button>
            )}
            {modalType === 'coagency_bottom_floor_input' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}>수정하기</button>
            )}
            {modalType === 'coagency_bottom_area_select' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}>확인</button>
            )}
            {/** 단지정보 수정하기 */}
            {modalType === 'coagency_bottom_complex_select' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModifyComplex}>
                수정하기
              </button>
            )}
            {/** 동/호 수정하기 */}
            {modalType === 'coagency_bottom_dong_ho_select' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModifyDongHo}>
                수정하기
              </button>
            )}
            {/** 거래유형 수정하기 */}
            {modalType === 'coagency_bottom_sale_type_select' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => handleModifyOffer('edit')}
              >
                수정하기
              </button>
            )}
            {modalType === 'coagency_bottom_sale_type_select_register' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => handleModifyOffer('register')}
              >
                수정하기
              </button>
            )}
            {/** 거래유형별 가격 수정하기 */}
            {modalType === 'coagency_bottom_price_input' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => handleModifyOfferPrice('edit')}
              >
                수정하기
              </button>
            )}
            {modalType === 'coagency_bottom_price_input_register' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => handleModifyOfferPrice('register')}
              >
                수정하기
              </button>
            )}
            {/** 입주가능일 수정하기 */}
            {modalType === 'coagency_bottom_movein_select' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => handleModifyMoveInDay('edit')}
              >
                수정하기
              </button>
            )}
            {modalType === 'coagency_bottom_movein_select_register' && (
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                onClick={() => handleModifyMoveInDay('register')}
              >
                수정하기
              </button>
            )}
            {/** 연락처 노출 수정하기 */}
            {modalType === 'coagency_bottom_tel_display' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModifyTelDisplay}>
                수정하기
              </button>
            )}
            {/** 공유정보 수정하기 */}
            {modalType === 'coagency_bottom_share_input' && (
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModifyShares}>
                수정하기
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoagencyBotSheet;
