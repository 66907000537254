import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

interface Props {
  type?: string;
  title?: string;
  txt?: string;
}

/**
 * @description common alert
 * @Class
 * @category Components
 * @subcategory common modal
 * @component
 * @returns {JSX.Element}
 */
const CommonAlert = (props: Props) => {
  const { type, title, txt } = props;

  const dispatch = useAppDispatch();
  const { modalType, data, isOpen, action } = useAppSelector((state: RootState) => state.modal);

  const [open, setOpen] = useState<boolean>(isOpen || false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show, !open && ResetStyles['d-none'])}>
        <div className={cn(Styles['popup-container'], Styles['modal-popup'], Styles['app-dowonload-pop'])}>
          <div className={Styles['popup-content']}>
            <div className={CommonStyles['img-box']}>
              <img
                className={CommonStyles['app-pop-bg']}
                src="/images/app_bg.svg"
                alt="당신의 부동산 여정을 가치 있게 만드는 곳 이실장넷 앱으로 보기"
              />
              <img className={CommonStyles.logo} src="/images/logo.svg" alt="이실장넷 로고" />
            </div>
            <p className={Styles.txt}>당신의 부동산 여정을 가치 있게 만드는 곳 이실장넷</p>
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}>앱으로 보기</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonAlert;
