import { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as APIS from 'api/group';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Textarea from 'components/common/Textarea/Textarea';
import Button from 'components/common/Button/Button';
import { offLoad, onLoad } from 'redux/slices/loading';
import moment from 'moment';
import CheckBox from 'components/common/Button/Checkbox';
import { useNavigate } from 'react-router-dom';
import { onClose } from 'utils';
import useToast from 'hook/useToast';

interface Props {
  type?: string;
  title?: string;
  txt?: string;
}

/**
 * @description 모임 confirm
 * @Class
 * @category Components
 * @subcategory common modal
 * @component
 * @returns {JSX.Element}
 */
const GroupConfirm = (props: Props) => {
  const { title, txt } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { modalType, isOpen, data, action } = useAppSelector((state: RootState) => state.modal);
  const groupData = useAppSelector((state: RootState) => state.group);
  const { group } = groupData;
  const { join_question = '', id = '' } = group;
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [open, setOpen] = useState<boolean>(isOpen || false);
  // 비공개 여부 체크
  const [checked, setChecked] = useState<boolean>(false);
  // 모임 탈퇴 여부 체크
  const [leaveChecked, setLeaveChecked] = useState<boolean>(false);
  // 모임 삭제 여부 체크
  const [isDelete, setIsDelete] = useState<boolean>(false);
  // 모임 삭제 여부 체크
  // 가입 질문 설정
  const [text, setText] = useState<string>('');
  const [tempText, setTempText] = useState<string>('');
  // 가입질문 작성
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (join_question) {
      setText(join_question);
      setTempText(join_question);
    }
  }, [group]);

  const handleModalClose = () => {
    // 페널티 해제
    // if (modalType === 'confirm_group_clear_penalty') {
    //   fetchDisabledPenalty();
    // }
    setOpen!(false);
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  const { setToastMessage } = useToast();

  // 페널티 해제
  const fetchDisabledPenalty = async () => {
    if (id) {
      if (data?.groupId && data?.userId) {
        try {
          dispatch(onLoad());
          const res = await APIS.putDisabledPenalty(`/groups/${data.groupId}/users/${data.userId}/penalty/clear`);
          if (res.data.message === 'OK') {
            action?.();
          }
        } catch (err) {
          console.log(err);
        } finally {
          setTimeout(() => {
            dispatch(offLoad());
          }, 300);
        }
      }
    }
  };

  // 페널티 적용
  const fetchEnablePenalty = async () => {
    try {
      dispatch(onLoad());
      const payload = {
        start_at: moment(data?.startDate?.fullDate).format('YYYY-MM-DD'),
        end_at: moment(data?.endDate?.fullDate).format('YYYY-MM-DD'),
      };
      const res = await APIS.postEnabledPenalty(`/groups/${id}/users/${data?.userId}/penalty`, payload);
      if (res.data.message === 'OK') {
        action?.();
      }
    } catch (err) {
      console.log(err, 'ere');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 모임장 위임
  const fetchTransferGroupLeader = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.putTransferGroupLeader(`/groups/${data?.groupId}/users/${data?.userId}/owner`);
      if (res.data.message === 'OK') {
        setTimeout(() => {
          navigate(`/mygroup/group/${id}`);
        }, 300);
      } else {
        setToastMessage({
          duration: 2000,
          content: res.message,
          type: 'message',
        });
      }
    } catch (err: any) {
      dispatch(
        ActionModal.openModal({
          isOpen: true,
          data: { txt: err?.response?.data?.message || 'Error' },
          modalType: 'alert_owner_error',
        }),
      );
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 모임탈퇴
  const fetchLeaveGroup = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.putLeaveGroup(`/groups/${data?.groupId}/users/${data?.userId}/withdrawal`);
      if (res.data.message === 'OK') {
        setTimeout(() => {
          navigate(`/mygroup/group/${id}`);
        }, 300);
      }
    } catch (err) {
      console.log(err, 'ere');
    } finally {
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  };

  // 모임삭제
  const fetchDeleteGroup = async () => {
    try {
      dispatch(onLoad());
      const res = await APIS.deleteGroup(`/groups/${data?.groupId}`);
      if (res.data.message === 'OK') {
        setTimeout(() => {
          onClose();
        }, 300);
      }
    } catch (err) {
      console.log(err, 'ere');
    } finally {
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  // 모임 가입 신청
  const fetchJoinGroup = async () => {
    try {
      const payload = {
        join_answer: value,
      };
      const res = await APIS.postJoinGroup(`/groups/${data?.groupId}/users/${data?.userId}`, payload);
      if (res.join_apply_use === true) {
        setTimeout(() => {
          dispatch(
            ActionModal.openModal({
              isOpen: true,
              modalType: 'alert_join_group',
              action: () => action?.(),
            }),
          );
        }, 300);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfirm = () => {
    //  모임 공개여부 설정
    if (modalType === 'confirm_group_change_type') {
      action?.();
    }
    // 모임 가입 조건 설정
    if (modalType === 'confirm_group_join_question') {
      modifyGroupApproval();
    }
    if (modalType === 'confirm_group_clear_penalty') {
      fetchDisabledPenalty();
    }
    // 페널티 기간 설정
    if (modalType === 'confirm_group_set_penalty') {
      if (data?.startDate && data?.endDate && data?.userId) {
        fetchEnablePenalty();
      }
    }
    // 모임장 위임
    if (modalType === 'confirm_group_transfer_leader') {
      if (data?.groupId && data?.userId) {
        fetchTransferGroupLeader();
      }
    }
    // 모임 탈퇴
    if (modalType === 'confirm_group_quit_group') {
      if (data?.groupId && data?.userId) {
        fetchLeaveGroup();
      }
    }
    // 모임삭제
    if (modalType === 'confirm_group_delete_group') {
      if (data?.groupId) {
        fetchDeleteGroup();
      }
    }

    // 가입신청
    if (modalType === 'confirm_join_question') {
      if (data?.groupId && data?.userId) {
        fetchJoinGroup();
      }
    }
    handleModalClose();
  };

  const handleClickShowCalendar = () => {
    dispatch(
      ActionModal.openModal({
        isOpen: true,
        modalType: 'groupBotSheet_group_penalty_period',
        data: { ...data },
        action: action,
      }),
    );
  };

  // modalIndex의 confirm종류별로 확인하시면 됩니다.

  // 확인 버튼 활성화 여부
  const isBtn =
    (modalType === 'confirm_group_join_question' && tempText === text && text.length <= 0) ||
    (modalType === 'confirm_group_change_type' && !checked) ||
    (modalType === 'confirm_join_question' && value.length <= 0)
      ? true
      : false;

  // 모임 탈퇴 버튼 활성화여부
  const isLeaveBtn = modalType === 'confirm_group_quit_group' && !leaveChecked ? true : false;

  // 모임 삭제 버튼 활성화 여부
  const isDeleteBtn = modalType === 'confirm_group_delete_group' && !isDelete ? true : false;

  const modifyGroupApproval = async () => {
    if (id === '') return;
    try {
      dispatch(onLoad());
      const payload = {
        join_apply_use: data?.join_apply_use, //신청 사용 여부
        join_question_use: data?.join_question_use, //질문 사용여부
        join_question: text, //질문 내용
      };
      await APIS.puthModifyApprovalManagement(`/groups/${id}/apply`, payload);
    } catch (err) {
      console.log(err, 'err');
    } finally {
      navigate(-1);
      dispatch(offLoad());
    }
  };

  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show, !open && ResetStyles['d-none'])}>
        <div
          className={cn(
            Styles['popup-container'],
            Styles['modal-popup'],
            modalType === 'confirm_group_change_type' && Styles['change-type-pop'],
            (modalType === 'confirm_group_join_question' || modalType === 'confirm_join_question') &&
              Styles['join-question-pop'],
            modalType === 'confirm_group_set_penalty' && Styles['set-penalty-pop'],
            modalType === 'confirm_group_quit_group' && Styles['quit-group-pop'],
            modalType === 'confirm_group_delete_group' && Styles['delete-group-pop'],
          )}
        >
          <div className={Styles['popup-content']}>
            <p className={Styles.tit}>{title}</p>
            <p className={Styles.txt} style={{ whiteSpace: 'pre-line' }}>
              {txt}
            </p>
            {modalType === 'confirm_group_change_type' && (
              <>
                <div className={cn(CommonStyles['dot-indent'], Styles['dot-indent'])}>
                  <p>비공개 모임의 변경은 신청 후 승인이 필요합니다.</p>
                  <p>승인 과정에서 모임 운영 원칙에 위배되는 글, 댓글 등의 콘텐츠가 삭제될 수 있습니다.</p>
                  <p>운영 원칙에 위배되는 모임은 승인이 거절됩니다. </p>
                </div>
                <div className={cn(CommonStyles['check-box'], Styles['check-box'])}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    name="type"
                    id="agree"
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <label htmlFor="agree" className={cn(CommonStyles['label-info'], Styles['label-info'])}>
                    위의 내용에 동의합니다.
                  </label>
                </div>
              </>
            )}
            {modalType === 'confirm_join_question' && (
              <>
                <p className={Styles['tit']}>가입 질문에 답해주세요.</p>
                <p className={Styles['txt']} style={{ wordBreak: 'break-all' }}>
                  {data?.quesText || ''}
                </p>
                <Textarea
                  className={cn(Styles['join-question-pop'], Styles['textarea-div'])}
                  value={value}
                  placeholder="답변을 작성해주세요."
                  maxLength={200}
                  ref={textareaRef}
                  onChange={(e) => setValue(e.target.value)}
                  show={false}
                />
              </>
            )}
            {modalType === 'confirm_group_join_question' && (
              <Textarea
                className={cn(Styles['join-question-pop'], Styles['textarea-div'])}
                value={text}
                placeholder="질문을 작성해주세요. (최대 200자)"
                maxLength={200}
                onChange={(e) => setText(e.target.value)}
                show={false}
              />
            )}

            {modalType === 'confirm_group_set_penalty' && (
              <>
                <p className={Styles.tit}>[{data?.nickname}] 회원에게 페널티를 부과하겠습니까?</p>
                <p className={Styles.txt}>
                  페널티가 부과되는 회원에게는 알림이 제공됩니다. 이후, 모임 접속은 가능하나 글작성 / 댓글 / 공감표현 등
                  활동이 제한됩니다.
                </p>
                <div className={Styles.period}>
                  <p className={Styles.tit}>페널티 적용 기간</p>
                  {/** 설정 전 */}
                  {data?.startDate?.fullDate && data?.endDate?.fullDate ? (
                    <Button
                      className={cn(Styles['calendar-btn'], CommonStyles['calendar-btn'])}
                      mergeClass={false}
                      onClick={handleClickShowCalendar}
                    >
                      {`${moment(data.startDate.fullDate).format('YYYY년 M월 D일')} ~ ${moment(
                        data.endDate.fullDate,
                      ).format('YYYY년 M월 D일')}`}
                      {/* 2023년 8월 18일 ~ 2023년 8월 31일 */}
                    </Button>
                  ) : (
                    <Button
                      className={cn(Styles['calendar-btn'], CommonStyles['calendar-btn'], CommonStyles['co-gry'])}
                      mergeClass={false}
                      onClick={handleClickShowCalendar}
                    >
                      시작일 ~ 종료일
                    </Button>
                  )}
                </div>
              </>
            )}

            {modalType === 'confirm_group_clear_penalty' && (
              <>
                <p className={Styles.tit}>[{data?.nickname}] 회원의 페널티를 해제하시겠습니까?</p>
                <p className={Styles.txt}>해제된 회원은 바로 회원 활동이 가능합니다.</p>
              </>
            )}

            {modalType === 'confirm_group_clear_leader' && (
              <>
                <p className={Styles.tit}>
                  [{data?.nickname}] 회원을 공동모임장에서
                  <br />
                  해임하시겠습니까?
                </p>
                <p className={Styles.txt}>
                  공동모임장에서 해임시 일반회원으로 변경되며,
                  <br />
                  공동모임장의 권한이 사라집니다.
                </p>
              </>
            )}
            {modalType === 'confirm_group_transfer_leader' && (
              <>
                <p className={Styles.tit} style={{ textAlign: 'left' }}>
                  [{data?.nickname}] 회원에게 모임장을 위임하시겠습니까?
                </p>
                <p className={Styles.txt} style={{ textAlign: 'left' }}>
                  위임하면 모임장으로 변경됩니다.
                  <br />
                  모임장 위임 시, 기존 모임장은 일반 회원으로 권한이
                  <br />
                  변경됩니다.
                </p>
              </>
            )}
            {modalType === 'confirm_group_quit_group' && (
              <>
                <p className={Styles.tit}>모임 탈퇴 전에 꼭 확인해주세요!</p>
                <div className={cn(CommonStyles['dot-indent'], Styles['dot-indent'])}>
                  <p>
                    모임을 탈퇴하면, 이 모임에 등록한 글, 사진, 댓글 등을 수정/삭제할 수 없습니다. 필요하면 탈퇴 전에
                    수정하거나 삭제해주세요.
                  </p>
                </div>
                <div className={cn(CommonStyles['check-box'], Styles['check-box'])}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    name="type"
                    id="quitAgree"
                    checked={leaveChecked}
                    onChange={(e) => {
                      setLeaveChecked(e.target.checked);
                    }}
                  />
                  <label htmlFor="quitAgree" className={cn(CommonStyles['label-info'], Styles['label-info'])}>
                    모임 탈퇴 안내를 모두 확인하였으며, 탈퇴에 동의합니다.
                  </label>
                </div>
              </>
            )}
            {modalType === 'confirm_group_delete_group' && (
              <>
                <p className={Styles.tit}>모임을 삭제하시겠습니까?</p>
                <div className={cn(CommonStyles['dot-indent'], Styles['dot-indent'])}>
                  <p>
                    모임을 삭제하면, 이 모임에 등록한 글, 사진, 댓글 등 모든 정보가 삭제되며, 삭제후에는 복구가
                    불가능합니다.
                  </p>
                </div>
                <div className={cn(CommonStyles['check-box'], Styles['check-box'])}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    name="type"
                    id="deleteAgree"
                    checked={isDelete}
                    onChange={(e) => {
                      setIsDelete(e.target.checked);
                    }}
                  />
                  <label htmlFor="deleteAgree" className={cn(CommonStyles['label-info'], Styles['label-info'])}>
                    모임 삭제 안내를 모두 확인하였으며, 삭제하겠습니다.
                  </label>
                </div>
              </>
            )}
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            {(modalType === 'confirm_group_change_type' ||
              modalType === 'confirm_group_join_question' ||
              modalType === 'confirm_group_set_penalty' ||
              modalType === 'confirm_group_clear_leader' ||
              modalType === 'confirm_group_transfer_leader' ||
              modalType === 'confirm_join_question') && (
              <>
                <Button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  취소
                </Button>
                <Button
                  className={cn(Styles.btn)}
                  mergeClass={true}
                  size="large"
                  disabled={isBtn}
                  onClick={handleConfirm}
                >
                  확인
                </Button>
              </>
            )}
            {modalType === 'confirm_group_clear_penalty' && (
              <>
                <Button size="large" type="gry" className={cn(Styles.btn)} mergeClass={true} onClick={handleModalClose}>
                  취소
                </Button>
                <Button size="large" type="red" className={cn(Styles.btn)} mergeClass={true} onClick={handleConfirm}>
                  확인
                </Button>
              </>
            )}
            {modalType === 'confirm_group_quit_group' && (
              <>
                <Button
                  className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                  onClick={handleModalClose}
                >
                  취소
                </Button>
                <Button
                  size="large"
                  mergeClass={true}
                  className={cn(Styles.btn)}
                  onClick={handleConfirm}
                  disabled={isLeaveBtn}
                >
                  탈퇴하기
                </Button>
              </>
            )}

            {modalType === 'confirm_group_delete_group' && (
              <>
                <Button type="gry" size="large" className={cn(Styles.btn)} onClick={handleModalClose} mergeClass={true}>
                  취소
                </Button>
                <Button
                  size="large"
                  mergeClass={true}
                  className={cn(Styles.btn)}
                  onClick={handleConfirm}
                  disabled={isDeleteBtn}
                >
                  삭제하기
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupConfirm;
