import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as Form from '../../@types/form';
import cn from 'classnames';
import type { TOffers } from '@types';
import LegacyItem from 'components/common/coagency/LegacyItem';
import { getOfferGbnStr, getOfferGbnCode } from 'utils/common';
import * as ActionModal from 'redux/slices/modal';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/slices/user';
import * as ActionCoagency from 'redux/slices/coagency';
import _ from 'lodash';
import { initImportState } from 'redux/slices/coagency';
import * as Config from 'configs/configs';
import qs from 'qs';

// styles
import CommonStyles from 'styles/common.module.css';

import network from 'utils/network';
import { useAppDispatch } from 'redux/store';
import { offLoad, onLoad } from 'redux/slices/loading';

/**
 * @description 공유매물 매물불러오기 리스트
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @returns {JSX.Element}
 */
const CoagencyRegisterLegacyList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [importOffers, setImportOffers] = useState<Array<TOffers.TImportOffers>>([]);
  const [selectOffers, setSelectOffers] = useState<Array<number>>([]);

  const [importScList, setImportScList] = useState<Array<TOffers.TImportOffersScList>>([]);
  const [selectScList, setSelectScList] = useState<TOffers.TImportOffersScList | null>(null);

  const { me } = useSelector(userSelector);

  const { watch, setValue, reset, handleSubmit } = useForm<Form.TOfferImportRequest>({
    defaultValues: {
      mode: 'adMst',
      scOfferingsGbn: 'AP',
    },
  });
  const { mode } = watch();

  //select offer
  const changeOffers = (_offeringsSeq: number) => {
    if (selectOffers.includes(_offeringsSeq)) {
      setSelectOffers([...selectOffers.filter((selectOffer) => selectOffer != _offeringsSeq)]);
    } else {
      setSelectOffers([...selectOffers, _offeringsSeq]);
    }
  };

  //get data
  const getData = async (formData?: Form.TOfferImportRequest) => {
    dispatch(onLoad());
    const { data } = await network().coagency().import(mode, formData);
    setImportOffers(data.list);
    setImportScList(
      data.scList.map((scData: TOffers.TImportOffersScList) => {
        scData.offeringsGbnStr = '[' + getOfferGbnStr(scData.offeringsGbn) + ']';
        scData.label = [scData.acDongNm, scData.cmComplexNm].join(' ').trim();
        return scData;
      }),
    );
    setSelectOffers([]);
    dispatch(offLoad());
  };

  //매물장, 매물광고 변경시
  useEffect(() => {
    setSelectScList(null);
    getData();
  }, [mode]);

  //단지 선택시 목록 가져오기
  useEffect(() => {
    if (selectScList) {
      setValue('scAreaCd', selectScList?.areaCd);
      setValue('scComplexCd', selectScList?.complexCd.toString());
      setValue('scOfferingsGbn', selectScList?.offeringsGbn);

      handleSubmit(async (formData: Form.TOfferImportRequest) => {
        dispatch(onLoad());
        const { data } = await network().coagency().import(mode, formData);
        setSelectOffers([]);
        setImportOffers(data.list);
        dispatch(offLoad());
      })();
    }
  }, [selectScList]);

  //단지 목록 모달
  const modalScList = () => {
    if (importScList.length > 0) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_import_sc_list',
          isOpen: true,
          data: {
            importScList: importScList,
          },
          action: (_selectScList: TOffers.TImportOffersScList) => setSelectScList(_selectScList),
        }),
      );
    }
  };

  //1개 복사하기
  const handleCopy = async () => {
    if (selectOffers.length < 1) {
      dispatch(
        ActionModal.openModal({
          modalType: 'alert_common',
          isOpen: true,
          data: { txt: '선택된 매물이 없습니다.' },
        }),
      );
      return;
    }
    dispatch(onLoad());

    //데이터 변환
    const offeringsSeq = selectOffers[0];
    const _getOne = _.find(importOffers, {
      offeringsSeq: offeringsSeq,
    });
    const importMode = (_getOne as unknown as TOffers.TImportOffers)?.targetSourceName;
    const { data } = await network().coagency().convert(mode, offeringsSeq);
    if (importMode === 'adMst') {
      data.adOfferingsSeq = data.offeringsSeq;
      data.offeringsSeq = null;
    }
    if (importMode === 'mst') {
      data.adOfferingsSeq = null;
    }
    const importState = { ...initImportState, ...(_getOne as object), ...data };

    //시도변환
    const [siCode, guCode, dongCode, liCode] = [
      importState.areaCd.slice(0, 2),
      importState.areaCd.slice(0, 5),
      importState.areaCd.slice(0, 8),
      importState.areaCd.slice(0, 10),
    ];

    //메인 타입 (공동, 비공동)
    const mainCdRes = await network().coagency().getRETypeList(Config.API_URL.GET_RE_TYPE_ALL);
    const mainCdData = mainCdRes.data;
    const offeringsMainGbn = _.find(mainCdData, {
      offer_types: [{ sub_cd: importState.offeringsGbn }],
    });

    //단지정보 조회
    const complex_params = {
      area_cd: importState.areaCd,
      complex_gbn:
        importState.offeringsGbn === 'AB'
          ? 'AP'
          : importState.offeringsGbn === 'JB'
          ? 'JS'
          : importState.offeringsGbn === 'OB'
          ? 'OP'
          : importState.offeringsGbn,
      is_rebuild: importState.offeringsGbn === 'RC' ? 'Y' : '',
      is_parcel: getOfferGbnCode(importState.offeringsGbn) === 'B' ? 'Y' : '',
    };
    const joinPath = `${Config.API_URL.GET_OFFERS_COMPLEXES}?${qs.stringify(complex_params)}`;
    const resComplexs = await network().coagency().getCommonList(joinPath);
    const complexAddr = resComplexs.data.data.filter(
      (resComplex: any) => resComplex.complex_cd == importState.complexCd,
    );
    const dongAddr = complexAddr[0]?.dong.filter((dong: any) => dong.dong_cd == importState.dongCd);
    const square = complexAddr[0]?.square.filter((square: any) => square.sqr_cd == importState.sqrCd);
    let disSqr = '';
    if (square && square[0]) {
      const squareT = square[0];
      disSqr = !squareT.sqr_cd
        ? '선택'
        : importState.offeringsGbn === 'OP' || importState.offeringsGbn === 'OB'
        ? `${Math.floor(squareT.con_sqr)}${squareT.square_type ?? ''} ( 계약: ${Number(squareT.con_sqr).toFixed(
            2,
          )}/ 전용: ${Number(squareT.exc_sqr).toFixed(2)} )`
        : `${Math.floor(squareT.sup_sqr)}${squareT.square_type ?? ''} ( 공급: ${Number(squareT.sup_sqr).toFixed(
            2,
          )}/ 전용: ${Number(squareT.exc_sqr).toFixed(2)} )`;
    }
    dispatch(
      ActionCoagency.setStep({
        coagencyType: 'share',
        shareRegStep: {
          totalStep: 11,
          currStep: 10,
          currAddrStep: data.acLiNm ? 4 : 3,
          selectedAddr: {
            sidoAddr: {
              addr_li_code: `${siCode}00000000`,
              name: importState.acMetroNm,
              code: `${siCode}00000000`,
              cd: siCode,
            },
            guGunAddr: {
              addr_li_code: `${guCode}00000`,
              name: importState.acGuNm,
              code: `${guCode}00000`,
              cd: guCode,
            },
            eupMyeonDongAddr: {
              addr_li_code: `${dongCode}00`,
              name: importState.acDongNm,
              code: `${dongCode}00`,
              cd: dongCode,
            },
            liAddr: {
              addr_li_code: liCode,
              name: importState.acLiNm,
              code: liCode,
              cd: liCode,
            },
            complexAddr: complexAddr && complexAddr[0] ? complexAddr[0] : {},
            dongAddr: dongAddr && dongAddr[0] ? dongAddr[0] : {},
            square: square && square[0] ? { ...square[0], disSqr } : {},
          },
          dir: 'right',
          mode: 'write',
          coagencyRegData: {
            regId: null,
            offerings_main_gbn: offeringsMainGbn.main_cd, //디비정보
            offerings_gbn: importState.offeringsGbn,
            area_cd: importState.areaCd,
            complex_cd: importState.complexCd,
            dong_cd: importState.dongCd,
            dong_hide: 'N',
            ho_nm: importState.hoNm,
            addr_gbn: importState.addrGbn,
            addr: {
              street_1: importState.houseNo ? importState.houseNo.split('-')[0] : null,
              street_2: importState.houseNo ? importState.houseNo.split('-')[1] : null,
              detail_use: importState.detailAddr !== null ? 'Y' : 'N',
              detail: importState.detailAddr ?? '',
            },
            offer_gbn: importState.offerGbn,
            sell_prc: importState.sellPrc ? importState.sellPrc : 0,
            lease_prc: importState.leasePrc ? importState.leasePrc : 0,
            deposit_prc: importState.depositPrc ? importState.depositPrc : 0,
            monthly_prc: importState.monthlyPrc ? importState.monthlyPrc : 0,

            parcel_gbn: importState.parcelGbn,
            parcel_prc:
              ['AB', 'JB', 'OB'].includes(importState.offeringsGbn) && importState.offerGbn === 'S'
                ? importState.parcelPrc
                : 0,
            premium_prc:
              ['AB', 'JB', 'OB'].includes(importState.offeringsGbn) && importState.offerGbn === 'S'
                ? importState.premiumPrc
                : 0,
            parcel_option_prc:
              ['AB', 'JB', 'OB'].includes(importState.offeringsGbn) && importState.offerGbn === 'S'
                ? importState.parcelOptionPrc
                : 0,
            move_gbn: importState.moveGbn || 'A',
            is_move_chg: importState.isMoveChg || 'N',
            move_ymd: importState.moveYmd
              ? `${importState.moveYmd.slice(0, 4)}-${importState.moveYmd.slice(4, 6)}-${importState.moveYmd.slice(
                  6,
                  8,
                )} 00:00:00`
              : `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`,
            status_deal: 'normal',
            status_share: 'Y',
            shares: [],
            tel_display: importState.telDisplayGbn,
            status_share_map: 'N',
            show_days: 15,
            ad_offerings_seq:
              importState.adOfferingsSeq && importState.adOfferingsSeq !== 0 ? importState.adOfferingsSeq : null,
            offerings_seq: importState.offeringsSeq && importState.offeringsSeq !== 0 ? importState.offeringsSeq : null,
            offering_sync:
              (importState.adOfferingsSeq && importState.adOfferingsSeq !== 0) ||
              (importState.offeringsSeq && importState.offeringsSeq !== 0)
                ? 'S'
                : null,
          },
          coagencyOptRegData: {
            area: {
              supArea: Number(String(importState.supSqr)?.slice(0, 6)),
              conArea: Number(String(importState.supSqr)?.slice(0, 6)),
              excArea: Number(String(importState.excSqr)?.slice(0, 6)),
              lotArea: Number(String(importState.supSqr)?.slice(0, 6)),
              totArea:
                Number(String(importState.etcSqr)?.slice(0, 6)) || Number(String(importState.excSqr)?.slice(0, 6)),
              bulArea: Number(String(importState.buildSqr)?.slice(0, 6)),
            },
            floor: {
              apply: null,
              under: importState.floor,
              ground: importState.totFloor,
              total: importState.totFloor,
            },
            room_cnt: importState.roomCnt,
            bath_cnt: importState.bathCnt,
            memo: importState.memo,
            detail_memo: importState.detailMemo,
            customer_name: importState.customerNm,
            owner_type: {
              code: '',
              name: '',
            },
            phone_num: importState.customerTel1 ? importState.customerTel1.replace('-', '') : null,
            hidden_memo: importState.hiddenMemo,
            isTermsAgree: importState.hiddenMemo ? true : false,
          },
          targetBuilding: {
            building: {
              dongNm: '',
              gbnCodeNm: '',
              mngPk: '',
              sitePos: '',
            },
            floor: {
              buildingPk: '',
              floorNm: '',
              hoNm: '',
              useNm: '',
              etcNm: '',
              sqr: '',
            },
            area: {
              buildSqr: '',
              buildingNm: '',
              buildingPk: '',
              code: '',
              dongNm: '',
              excSqr: '',
              gbnCodeNm: '',
              mngPk: '',
              sitePos: '',
              siteSqr: '',
              supSqr: '',
              useSqr: '',
            },
          },
        },
        lookForRegStep: {},
      }),
    );
    dispatch(offLoad());
    navigate(`/coagency-register?currStep=10`);
  };

  //바로 등록
  const handleRegister = () => {
    if (selectOffers.length < 1) {
      dispatch(
        ActionModal.openModal({
          modalType: 'alert_common',
          isOpen: true,
          data: { txt: '선택된 매물이 없습니다.' },
        }),
      );
      return;
    }
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_import_input',
        isOpen: true,
        data: {
          selectedOffers: importOffers.filter((importOffers) => {
            if (importOffers.offeringsSeq) {
              return selectOffers.includes(importOffers.offeringsSeq);
            }
          }),
        },
      }),
    );
  };

  //전체매물 불러오기 모달.
  const hadndleAllImport = async () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_import_all_confirm',
        isOpen: true,
      }),
    );
  };

  return (
    <>
      <div className={cn(CommonStyles.container, CommonStyles.hasBottom)}>
        <div className={cn(CommonStyles.content, CommonStyles.board, CommonStyles['share-copy'])}>
          <div className={cn(CommonStyles['tab-menu'], CommonStyles['round-tab'])}>
            <button
              className={cn(CommonStyles.tab, mode == 'mst' && CommonStyles.on)}
              onClick={() => {
                setValue('mode', 'mst');
              }}
            >
              매물장
            </button>
            <button
              className={cn(CommonStyles.tab, mode == 'adMst' && CommonStyles.on)}
              onClick={() => {
                setValue('mode', 'adMst');
              }}
            >
              매물광고
            </button>
            {me?.offering_sync != 'Y' && (
              <button className={cn(CommonStyles.tab)} onClick={hadndleAllImport}>
                전체매물 불러오기
              </button>
            )}
          </div>
          <div className={CommonStyles['top-area']}>
            <button className={cn(CommonStyles.dropdown, CommonStyles.on)} onClick={modalScList}>
              <span className={CommonStyles['limit-line1']}>
                {selectScList?.offeringsGbnStr} {selectScList?.label || importScList[0]?.label || '전체'}
              </span>
              <i className={CommonStyles['icon-arrow-down-gr']}></i>
            </button>
          </div>
          <div className={CommonStyles['sale-card-div']}>
            {importOffers.map((item: TOffers.TImportOffers, index: number) => (
              <LegacyItem
                key={item.offeringsSeq}
                index={index}
                item={item}
                selectOffers={selectOffers}
                changeOffers={changeOffers}
              />
            ))}
          </div>
        </div>
      </div>
      {selectOffers.length > 1 ? (
        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleRegister}>
            총 {selectOffers.length}개 바로 등록하기
          </button>
        </div>
      ) : (
        <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
          <button className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleCopy}>
            복사하기
          </button>
          <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={handleRegister}>
            바로등록
          </button>
        </div>
      )}
    </>
  );
};

export default CoagencyRegisterLegacyList;
