import type { IMyinfo } from '../../@types';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { GRADE_LIST } from 'configs/configs';

// props 타입
interface GradeInfoPropsType {
  me: IMyinfo; // 내 정보
  show: boolean; // 팝업 노출여부
  onShow: (show: boolean) => void; // show 설정 callback 함수
}

/**
 * @description 내피드 유저 등급 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @param {IMyinfo} props.me 내 정보
 * @param {boolean} props.show 팝업 노출여부
 * @param {void} props.onShow show 설정 callback 함수
 * @returns {JSX.Element}
 */
const GradeInfo = ({ me, show = false, onShow }: GradeInfoPropsType) => {
  // 유저 등급 단계
  const gageNumbers = Array.from({ length: 6 });
  // 유저 등급
  const index = GRADE_LIST.findIndex((item) => item == me.grade);
  const markingNumber = index !== -1 ? index + 1 : 0;
  const cell_per = 100 / 6;
  const grade_per = markingNumber > 0 ? (markingNumber - 1) * cell_per + cell_per / 2 : 0;

  return (
    <div className={cn(CommonStyles['popup-dimd'], show && CommonStyles['show'])}>
      <div
        className={cn(CommonStyles['popup-container'], CommonStyles['bottom-sheet-popup'], CommonStyles['grade-pop'])}
      >
        <div className={CommonStyles['popup-header']}>
          <p className={CommonStyles['pop-tit']}></p>
          <button className={CommonStyles.close} onClick={() => onShow(false)}></button>
        </div>
        <div className={CommonStyles['popup-content']}>
          <div className={CommonStyles['grade-information']}>
            <div className={CommonStyles.grade} aria-label={me.grade ? me.grade : ''}></div>
            <p className={CommonStyles.title}>
              {me?.nickname}님의 등급은 <b className={CommonStyles['co-dorg']}>{me.grade}</b> 입니다.
            </p>
            {/* 등급 gage */}
            <div className={CommonStyles['grade-gage']}>
              <span
                className={cn(CommonStyles['grade-tooltip'], CommonStyles['bottom-center'])}
                style={{ left: grade_per ? `${grade_per}%` : `0` }}
              >
                {me.point_level}
              </span>
              <div className={CommonStyles['gage-bar']}>
                {gageNumbers.map((_, index) => (
                  <div
                    key={index}
                    className={cn(CommonStyles.section, markingNumber >= index + 1 && CommonStyles.on)}
                  ></div>
                ))}
              </div>
            </div>
            {/* 등급 상세내역 */}
            <div className={cn(CommonStyles['gray-box'], CommonStyles['active-state'])}>
              <div className={CommonStyles.detail}>
                <span className={CommonStyles.tit}>글쓰기</span>
                <span className={CommonStyles.count}>{me.total_post_count}</span>
              </div>
              <div className={CommonStyles.detail}>
                <span className={CommonStyles.tit}>댓글</span>
                <span className={CommonStyles.count}>{me.total_comment_count}</span>
              </div>
              <div className={CommonStyles.detail}>
                <span className={CommonStyles.tit}>공유매물</span>
                <span className={CommonStyles.count}>{me.offering_count}</span>
              </div>
              <div className={CommonStyles.detail}>
                <span className={CommonStyles.tit}>거래완료</span>
                <span className={CommonStyles.count}>{me.offering_complete_count}</span>
              </div>
              <div className={CommonStyles.detail}>
                <span className={CommonStyles.tit}>받은공감</span>
                <span className={CommonStyles.count}>{me.total_like_count}</span>
              </div>
            </div>
          </div>
          <div className={CommonStyles['contour-line']}></div>
          <div className={CommonStyles['grade-detail-info']}>
            <p className={CommonStyles.tit}>등급 상세안내</p>
            <div className={CommonStyles.line}>
              <span className={CommonStyles.grade} aria-label="새싹"></span>
              <span>이실장넷 회원 가입 완료 회원 </span>
            </div>
            <div className={CommonStyles.line}>
              <span className={CommonStyles.grade} aria-label="열매"></span>
              <span>중개사인증을 완료한 회원</span>
            </div>
            <div className={CommonStyles.line}>
              <span className={CommonStyles.grade} aria-label="초수"></span>
              <span>1-1000점을 획득한 회원</span>
            </div>
            <div className={CommonStyles.line}>
              <span className={CommonStyles.grade} aria-label="중수"></span>
              <span>1001-5000점을 획득한 회원</span>
            </div>
            <div className={CommonStyles.line}>
              <span className={CommonStyles.grade} aria-label="고수"></span>
              <span>5001-10000점을 획득한 회원</span>
            </div>
            <div className={CommonStyles.line}>
              <span className={CommonStyles.grade} aria-label="영웅"></span>
              <span>10001-20000점을 획득한 회원</span>
            </div>
          </div>
          <div className={CommonStyles['score-info']}>
            <div className={CommonStyles.line}>
              <p className={ResetStyles['fw-b']}>활동점수란</p>
              <span>이실장넷에서 커뮤니티 활동을 통해 부여받을 수 있는 점수입니다.</span>
            </div>
            <div className={CommonStyles.line}>
              공동중개 매물공유/구합니다 작성 시 1점
              <br />
              공동중개 매물공유/구합니다 작성 후, 공유 시 5점
              <br />
              공동중개 댓글 작성 시 3점
              <br />
              내매물 거래 완료 버튼 클릭 시 1점
            </div>
            <div className={CommonStyles.line}>
              등급은 실시간으로 반영됩니다. 게시글 작성 후 본인에 의해 삭제 시 획득한 포인트는 차감됩니다.
            </div>
            <div className={CommonStyles.line}>
              부당한 방법으로 활동점수를 획득한 고객등급은 이실장넷 운영진에 의해 재조정될 수 있습니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradeInfo;
