import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import network from 'utils/network';
import { persistor } from 'main';
import { rmLocStore } from 'utils/common';

/**
 * @description SSO logout bridge
 * @Class
 * @category Bridge
 * @subcategory sso login
 */
const LogoutBridge = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const resToken = queryParams.get('resToken') || '';

  const onLogout = async (resToken: string) => {
    try {
      const response = await network().sso().signoutJwt({ token: resToken });
      if (response.data?.result != 'ok') throw new Error('로그아웃 오류(fail)');

      await persistor.purge();
      rmLocStore('token');
      rmLocStore('refresh_token');

      if (window.webkit) {
        window.webkit.messageHandlers.logout.postMessage('');
      } else if (window.Android) {
        window.Android.logout();
      } else {
        window.webkit.messageHandlers.logout.postMessage('');
      }
    } catch (error: unknown) {
      const errorObj = {
        message: '',
      };

      if (axios.isAxiosError(error)) {
        errorObj.message = error.response?.data?.message ?? '';
      } else if (error instanceof Error) {
        errorObj.message = error.message ?? '';
      } else {
        errorObj.message = '로그아웃 오류';
      }

      console.log('logout:', errorObj);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!resToken) return;

    onLogout(resToken);
  }, [resToken]);

  return <></>;
};

export default LogoutBridge;
