import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import cn from 'classnames';
import useSubView from 'hook/useSubView';
// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionGroup from 'redux/slices/group';
import * as ActionsBoard from 'redux/slices/board';

// styles
import CommonStyles from 'styles/common.module.css';

// utils
import network from 'utils/network';
import { IMixedKeyValue } from '<modal>';

// configs
import * as Config from 'configs/configs';
import { onView } from 'utils';

const GroupSelect = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subViewControl = useSubView();
  const MygroupList = useAppSelector((state: RootState) => state.group.mygroup);
  const [isLoading, setIsLoading] = useState(false);
  const [mygroupList, setMyGroupList] = useState(MygroupList || []);
  const [totalCount, setTotalCount] = useState(0);
  const params = useParams();
  const { sharedId } = params;

  const shareBoardDate = useAppSelector((state: RootState) => state.board.shareBoardData);
  const boardType =
    shareBoardDate?.cate?.key === 1500 ? 'free-board' : shareBoardDate?.cate?.key === 1501 ? 'issue' : 'group';

  console.log('sharedId==>', sharedId);

  /**
   * @description 내그룹목록 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const f = async () => {
      setIsLoading(true);
      await getMyGroup();
    };
    f();
    return () => abortController.abort();
  }, []);

  /**
   * @description 내 그룹목록의 redux데이터를 local state에 매핑
   */
  useEffect(() => {
    if (MygroupList?.length > 0) setMyGroupList(MygroupList);
  }, [MygroupList]);

  /**
   * @description 내그룹 목록 api call
   * @returns {Promise<void>}
   */
  const getMyGroup = async (): Promise<void> => {
    try {
      const res = await network().group().getMyGroup();
      console.log('res in NewPostSelect==>', res);
      const { list, total_count } = res.data;
      setMyGroupList(list);
      setTotalCount(total_count);
      dispatch(ActionGroup.setMyGroup(list));
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  /**
   * @description 내 그룹목록 리스트
   */
  const groupList = mygroupList.map((group: IMixedKeyValue) => {
    return (
      <button
        key={group?.id}
        className={CommonStyles['line-box']}
        onClick={() => {
          // dispatch(ActionsBoard.getShareData(shareBoardDate));
          if (sharedId) {
            // open view로 열였을 경우
            navigate(
              `/writing?mode=share&shareOrigin=allboard&type=group&groupId=${group?.id}&name=${group?.name}&sharedId=${sharedId}`,
            );
          } else {
            navigate(
              `/writing?mode=share&shareOrigin=${boardType}&type=group&groupId=${group?.id}&name=${group?.name}`,
            );
          }
        }}
      >
        <span className={CommonStyles['img-box']}>
          <img src={group?.photos[0]?.url} alt={group?.photos[0]?.url ? '그룹 프로필 이미지' : ''} />
        </span>
        <span className={CommonStyles['group-tit']}>{group?.name}</span>
      </button>
    );
  });

  /**
   * @description 내 그룹목록이 없을 경우(가입한 목록이 하나도 없을경우)
   */
  const noItem = (
    <div className={CommonStyles['no-data']}>
      <p>
        아직 가입한 내모임이 없으시네요!
        <br />
        다양한 모임에 가입하여
        <br />
        게시글 공유 활동을 시작해보세요!
      </p>
      <button
        type="button"
        className={cn(CommonStyles.btn, CommonStyles.lg)}
        onClick={() => onView('aipartnernet://net.aipartner/open?name=CategoryGroup')}
      >
        주제별 모임 찾기
      </button>
    </div>
  );

  return (
    <div
      className={cn(
        CommonStyles.content,
        CommonStyles['select-board'],
        Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
      )}
    >
      <div className={CommonStyles['line-button-list']}>
        <p className={CommonStyles.tit}>내가 가입한 모임</p>
        <>{mygroupList?.length > 0 ? groupList : noItem}</>
      </div>
    </div>
  );
};
export default GroupSelect;
