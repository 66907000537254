import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import ScrollLoader from '../Loader/ScrollLoader';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { offRefresh, onRefresh } from 'redux/slices/refresh';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { closeModal } from 'redux/slices/modal';

interface RefreshInterface {
  children: JSX.Element; // children *
}
/**
 * @description RefreshLayout
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {ReactNode} children children
 * @returns {JSX.Element}
 */
const RefreshLayout = ({ children }: RefreshInterface) => {
  const location = useLocation();
  const { pathname } = location;
  const refresh = useAppSelector((state: RootState) => state.refresh);
  const { load = false, isFunction = false, refreshFn } = refresh;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (load) {
      dispatch(offRefresh());
    }
    if (pathname) {
      dispatch(closeModal({ isOpen: false, modalType: '' }));
    }
  }, [pathname]);

  /**
   * @description refresh 함수
   * @todo 추후 리팩토링 필요
   * @todo infinite scroll 관련 api를 호출할 때 중복데이터 또는 페이지 넘버에 대한 이슈가 존재
   * @todo loading time은 함수 실행시간 / 날려야 할 함수가 여러개 일 때 어떻게 처리할것인가 보통은 Promise  all 로 묶어서 처리할듯...
   * @returns {Promise<void>}
   */
  const onRefreshAsyncFunction = async (): Promise<void> => {
    dispatch(onRefresh());
    return new Promise((res) => {
      setTimeout(() => {
        res(refreshFn());
        dispatch(offRefresh());
      }, 1000);
    });
  };

  return (
    <PullToRefresh
      isPullable={load && !isFunction ? false : true}
      resistance={1}
      pullDownThreshold={65}
      maxPullDownDistance={80}
      canFetchMore={false}
      onRefresh={onRefreshAsyncFunction}
      pullingContent={''}
      refreshingContent={<ScrollLoader />}
    >
      {children}
    </PullToRefresh>
  );
};

export default RefreshLayout;
