import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { batch } from 'react-redux';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionsComment from 'redux/slices/comment';
import * as ActionLoader from 'redux/slices/loading';
import cn from 'classnames';
import useQueryState from 'hook/useQueryState';
import ScrollLoader from 'components/common/Loader/ScrollLoader';

// components
import CommentList from 'components/common/Comments/CommentList';
import CommentForm from 'components/common/Comments/CommentForm';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

// configs
import * as Config from 'configs/configs';
import { initialComment } from 'redux/slices/comment';
import { TisEdit } from '<comments>';

interface Props {
  type?: string;
  title?: string;
}

/**
 * @description comment bottom sheet
 * @Class
 * @category Components
 * @subcategory common sheet
 * @component
 * @returns {JSX.Element}
 */
const CommentBotSheet = (_props: Props) => {
  const dispatch = useAppDispatch();
  const { isCommentLoading } = useAppSelector((state: RootState) => state.loading);
  const { modalType, data, isOpen, action } = useAppSelector((state: RootState) => state.modal);
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const searchParams = new URLSearchParams(location.search);
  const [closeComment, setCloseComment] = useQueryState<string | null>(searchParams.get('close'), 'close');
  const [inputComment, setInputComment] = useState('');

  // console.log(data, 'data');

  const initialCommentType = {
    focusDepth: '1depthComment',
    parentId: '',
    mentionId: '',
    mentionNickname: '',
    isOn: false,
  };
  const [commentType, setCommentType] = useState(initialCommentType);
  const [isEdit, setIsEdit] = useState<TisEdit>({
    editTarget: '1depth',
    parentCommentId: null,
    editCommentId: null,
    isEditOn: false,
  });

  useEffect(() => {
    setOpen(isOpen!);
    setCommentType(initialCommentType);
    if (isOpen) setCloseComment('false');
  }, [isOpen]);

  useEffect(() => {
    if (commentType.mentionNickname) {
      const _inputComment = `@${commentType.mentionNickname}`;
      setInputComment(_inputComment);
    }
  }, [commentType]);
  /**
   * @description modal close
   * @returns {void}
   */
  const handleModalClose = (): void => {
    setOpen!(false);
    if (action) {
      console.log(data, 'data');
      action?.(data?.id, data?.comments_list_count);
    }
    setTimeout(() => {
      batch(() => {
        dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
        dispatch(
          ActionsComment.loadComments({
            comments: [],
            parentId: null,
            isFetch: false,
            isAddComment: false,
            isAddChildren: false,
            isLikeTarget: null,
            isCommentLike: false,
          }),
        );
        dispatch(ActionLoader.offCommentLoad());
      });
    }, 200);
    if (closeComment !== 'true') setCloseComment('true');
  };

  // console.log('data in CommentBotSheet==>', data, Object.keys(data!).length);
  console.log('inputComment in CommentBotSheet', inputComment);
  console.log('commentType===>', commentType);
  console.log('isEdit', isEdit);

  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkVisibility = () => {
      const div_bottom = document.getElementById('div_bottom');
      if (div_bottom) {
        const rect = div_bottom.getBoundingClientRect();
        setIsVisible(rect.top >= 0 && rect.bottom <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', checkVisibility);
    window.addEventListener('resize', checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener('scroll', checkVisibility);
      window.removeEventListener('resize', checkVisibility);
    };
  }, []);

  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div
          id="render-modal"
          className={cn(
            Styles['popup-container'],
            Styles['bottom-sheet-popup'],
            Styles['comment-pop'],
            // Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
          style={{
            paddingBottom:
              CommentList.length < 1 ? (isIos ? (!isVisible ? '80px' : '20px') : '0px') : !isVisible ? '80px' : '20px',
          }}
        >
          <div className={Styles['popup-header']}>
            <p className={Styles['pop-tit']}>댓글</p>
            {(data?.isType === 'coagency' || data?.isType === 'looking-for') && (
              <div className={CommonStyles['lock-item']} />
            )}
            <button className={Styles.close} onClick={handleModalClose} />
          </div>
          {open && (
            <div
              className={Styles['popup-content']}
              style={{ maxHeight: isIos ? (!isVisible ? 'calc(100vh - 15rem)' : '') : '' }}
            >
              <CommentList
                data={data!}
                commentType={commentType}
                setCommentType={setCommentType}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
              />
            </div>
          )}
          <CommentForm
            id="render-comment"
            value={inputComment}
            onChange={setInputComment}
            maxLength={1000}
            placeholder="댓글을 남겨주세요."
            sheetOpen={open}
            data={data}
            commentType={commentType}
            setCommentType={setCommentType}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </div>
        <div id="div_bottom" style={{ height: '10px', background: 'white', marginTop: '-81px', zIndex: 1000 }}></div>
      </div>
    </>
  );
};

export default CommentBotSheet;
