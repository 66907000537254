/**
 * @description 앱 설치 체크 ==> 미사용
 * @Class
 * @category Pages
 * @subcategory unused
 * @component
 * @returns {JSX.Element}
 */
const AppCheck = () => {
  return <>앱 설치가 안 되어있습니다.</>;
};

export default AppCheck;
