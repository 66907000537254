import { IMixedKeyValue } from '<modal>';
import cn from 'classnames';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/group';
import * as EAPIS from 'api/common';
import { useAppDispatch } from 'redux/store';
import { openModal, openMultiModal } from 'redux/slices/modal';
import { convertArea, formatNumber, moveMain, numberWithCommas, onClose } from 'utils';
import { cloneDeep } from 'lodash';
import network from 'utils/network';
// configs
import * as Config from 'configs/configs';

/**
 * @description 구합니다 수정 화면 F.C
 * @Class
 * @category Pages
 * @subcategory 구합니다 게시판
 * @component
 * @returns {JSX.Element}
 */
const EditFindShare = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id = '' } = params;

  // 고객관계리스트
  const [relationList, setRelationList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<IMixedKeyValue>({
    min: '',
    m_min: '',
    max: '',
    m_max: '',
    area_min: '',
    area_max: '',
  });
  const [temp, setTemp] = useState<IMixedKeyValue>({
    min: '',
    m_min: '',
    max: '',
    m_max: '',
    area_min: '',
    area_max: '',
  });
  const [areaType, setAreaType] = useState<string>('meter');
  const [isTermsAgree, setAgree] = useState<boolean>(false);
  const inputRefs = useRef<any>(null);
  const [force, setForce] = useState(new Date().getTime());

  useEffect(() => {
    if (id) {
      fetchInfo();
      getRelationList();
    }
  }, [id]);

  // useEffect(() => {
  //   const handleTouchMove = (e: any) => {
  //     e.preventDefault();
  //   };

  //   const inputs = document.querySelectorAll('input, textarea');

  //   const disableTouchScroll = () => {
  //     document.addEventListener('touchmove', handleTouchMove, { passive: false });
  //   };

  //   const enableTouchScroll = () => {
  //     document.removeEventListener('touchmove', handleTouchMove);
  //   };

  //   inputs.forEach((input) => {
  //     input.addEventListener('focus', disableTouchScroll);
  //     input.addEventListener('blur', enableTouchScroll);
  //   });

  //   return () => {
  //     inputs.forEach((input) => {
  //       input.removeEventListener('focus', disableTouchScroll);
  //       input.removeEventListener('blur', enableTouchScroll);
  //     });
  //     document.removeEventListener('touchmove', handleTouchMove);
  //   };
  // }, []);

  /**
   * @description 소유자 관계목록
   * @returns {Promise<void>}
   */
  const getRelationList = async (): Promise<void> => {
    try {
      const resAddr = await network().coagency().getCommonList(Config.API_URL.GET_OWNER_TYPE);
      const { data, status } = resAddr;
      if (status === 200) {
        let _data = data.map((item: IMixedKeyValue) => ({
          name: item.name,
          code: item.code,
        }));
        _data = [{ code: '', name: '선택' }, ..._data];
        setRelationList(_data);
      }
    } catch (err) {
      console.error('err', err);
      if (err) {
        setRelationList([]);
      }
    }
  };

  /**
   * @description  구합니다 상세 조회
   * @returns {void}
   */
  const fetchInfo = async () => {
    try {
      const res = await APIS.getFindShareDetail(`/seeks/${id}`);

      if (res) {
        let payload = { ...res };

        if (res.customer_name === null) {
          payload = {
            ...res,
            customer_name: '',
          };
        }

        if (res.phone_num === null) {
          payload = {
            ...res,
            phone_num: '',
          };
        }

        if (res.relation_name === null) {
          payload = {
            ...res,
            relation_name: '',
          };
        }

        if (res.relation_type === null) {
          payload = {
            ...res,
            relation_type: '',
          };
        }

        if (res.hidden_memo === null) {
          payload = {
            ...res,
            hidden_memo: '',
          };
        }

        if (payload['price_info']?.length > 0) {
          payload['price_info'].forEach((item: any) => {
            if (item.name === '보증금') {
              const min = item.min === null ? '' : item.min;
              const max = item.max === null ? '' : item.max;
              payload = {
                ...payload,
                min: numberWithCommas(min + ''),
                max: numberWithCommas(max + ''),
              };

              setTimeout(() => {
                if (inputRefs?.current?.['min']) {
                  inputRefs.current['min'].value = numberWithCommas(min + '');
                }
                if (inputRefs?.current?.['max']) {
                  inputRefs.current['max'].value = numberWithCommas(max + '');
                }
              }, 200);
            }

            if (item.name === '월세' || item.name === '매매가' || item.name === '전세가') {
              console.log('item', item);
              const min = item.min === null ? '' : item.min;
              const max = item.max === null ? '' : item.max;
              payload = {
                ...payload,
                m_min: numberWithCommas(min + ''),
                m_max: numberWithCommas(max + ''),
              };

              setTimeout(() => {
                if (inputRefs?.current?.['m_min']) {
                  inputRefs.current['m_min'].value = numberWithCommas(min + '');
                }
                if (inputRefs?.current?.['m_max']) {
                  inputRefs.current['m_max'].value = numberWithCommas(max + '');
                }
              }, 200);
            }
          });
        }

        if (payload?.['area_min'] || payload?.['area_max']) {
          if (payload?.['area_min']) {
            payload = {
              ...payload,
              area_min: +payload['area_min'],
            };
            if (inputRefs?.current?.['area_min']) {
              inputRefs.current['area_min'].value = +payload['area_min'] || '';
            }
          }

          if (payload?.['area_max']) {
            payload = {
              ...payload,
              area_max: +payload['area_max'],
            };
            if (inputRefs?.current?.['area_max']) {
              inputRefs.current['area_max'].value = +payload['area_max'] || '';
            }
          }
        }

        setInfo({ ...payload });
        setTemp({ ...payload });
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const clickRef = useRef<boolean>(false);

  /**
   * @description  구합니다 상세 수정
   * @returns {void}
   */
  const handleClickSave = async () => {
    const copyTemp = cloneDeep(temp);
    try {
      setLoading(true);

      let payload: any = {
        title: copyTemp.title,
        content: copyTemp.content,
        deal_type: copyTemp.deal_type,
        area_min: copyTemp.area_min,
        area_max: copyTemp.area_max,
        customer_name: copyTemp.customer_name,
        relation_type: copyTemp.relation_type,
        phone_num: copyTemp.phone_num,
        hidden_memo: copyTemp.hidden_memo,
        status_share: copyTemp.status_share,
        tel_display: copyTemp.tel_display,
        shares: copyTemp.shares,
        show_days: 30, // copyTemp.show_days, // {/* TODO 노출기간 수정 - 10. mo : 구합니다 > 상세 > 수정   */}
        areas: copyTemp.addr_info,
      };

      // 면적이 평형으로 돼 있을 경우 제곱미터로 변환해서 저장
      if (payload.area_min !== null || payload.area_min !== '') {
        if (areaType === 'square') {
          payload = {
            ...payload,
            area_min: convertArea(+payload.area_min, 'meter'),
          };
        }
      }

      // 면적이 평형으로 돼 있을 경우 제곱미터로 변환해서 저장
      if (payload.area_max !== null || payload.area_max !== '') {
        if (areaType === 'square') {
          payload = {
            ...payload,
            area_max: convertArea(+payload.area_max, 'meter'),
          };
        }
      }

      // 매매
      if (copyTemp?.deal_type === 'S') {
        payload = {
          ...payload,
          sell_prc_min: +copyTemp?.m_min?.replace(/,/g, '') || null,
          sell_prc_max: +copyTemp?.m_max?.replace(/,/g, '') || null,
        };
      }

      // 전세
      if (temp?.deal_type === 'L') {
        payload = {
          ...payload,
          lease_prc_min: +copyTemp?.m_min?.replace(/,/g, '') || null,
          lease_prc_max: +copyTemp?.m_max?.replace(/,/g, '') || null,
        };
      }

      // 월세가,단기임대
      if (temp?.deal_type === 'M' || temp?.deal_type === 'T') {
        payload = {
          ...payload,
          deposit_prc_min: +copyTemp?.min?.replace(/,/g, '') || null,
          deposit_prc_max: +copyTemp?.max?.replace(/,/g, '') || null,
          monthly_prc_min: +copyTemp?.m_min?.replace(/,/g, '') || null,
          monthly_prc_max: +copyTemp?.m_max?.replace(/,/g, '') || null,
        };
      }

      const parseaAddrInfo =
        payload['areas']?.map((item: any) => {
          if (item?.addr_li_cd !== null && item?.addr_li_cd !== undefined) {
            return item.addr_li_cd;
          } else if (item?.addr_dong_cd !== null && item?.addr_dong_cd !== undefined) {
            return item.addr_dong_cd;
          } else if (item?.addr_gu_cd !== null && item?.addr_gu_cd !== undefined) {
            return item.addr_gu_cd;
          } else if (item?.addr_si_cd !== null && item?.addr_si_cd !== undefined) {
            return item.addr_si_cd;
          } else {
            return item.area_cd;
          }
        }) || [];

      const set = new Set(parseaAddrInfo);
      const uniqueArr = Array.from(set);

      if (payload.shares.length > 0) {
        const parseList = payload.shares.map((item: any) => {
          return {
            type: item.share_type,
            group_id: item?.id || null,
          };
        });
        payload = {
          ...payload,
          shares: [...parseList],
        };
      }

      if (parseaAddrInfo.length > 0) {
        payload = {
          ...payload,
          areas: [...uniqueArr],
        };
      }

      const res = await EAPIS.putModifySeek(`/seeks/${id}`, payload);

      if (res) {
        dispatch(
          openModal({
            modalType: 'alert_looking_for_modify_success',
            isOpen: true,
            action: () => {
              navigate(`/findsharedetail/${id}`, {
                replace: true,
              });
            },
          }),
        );
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      setLoading(false);
    }
  };

  const onChangeValue = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>, max = 1000) => {
    const { name, value } = e.target;

    if (value.length >= max) return;
    if (name) {
      setTemp({
        ...temp,
        [name]: value,
      });
    }
  };

  /**
   * @description  구합니다 약관 선택
   * @returns {void}
   */
  const handleClickTerms = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'coagency_bottom_privacy_info',
        data: {
          isModify: true,
        },
        action: () => {
          setAgree(true);
        },
      }),
    );
  };

  // const onChaneNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
  //   const { value, name } = e.target;
  //   const inputValue = value;
  //   const isNumberValue = inputValue.replace(/\D/g, '');
  //   const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  //   if (name) {
  //     if (inputValue.length > 10) return;
  //     setTemp((prevInfo) => {
  //       const newInfo = { ...prevInfo, [name]: name === 'area_min' || name === 'area_max' ? inputValue : commaValue };
  //       if (name === 'min' && parseInt(isNumberValue) > parseInt(temp.max?.replace(/,/g, ''))) {
  //         newInfo.max = commaValue;
  //       } else if (name === 'max' && parseInt(isNumberValue) < parseInt(temp.min?.replace(/,/g, ''))) {
  //         newInfo.min = commaValue;
  //       } else if (name === 'm_min' && parseInt(isNumberValue) > parseInt(temp.m_max?.replace(/,/g, ''))) {
  //         newInfo.m_max = commaValue;
  //       } else if (name === 'm_max' && parseInt(isNumberValue) < parseInt(temp.m_min?.replace(/,/g, ''))) {
  //         newInfo.m_min = commaValue;
  //       } else if (
  //         name === 'area_min' &&
  //         +inputValue > +temp.area_max &&
  //         temp.area_max !== null &&
  //         temp.area_max !== ''
  //       ) {
  //         newInfo.area_max = +inputValue;
  //       } else if (
  //         name === 'area_max' &&
  //         +inputValue < +temp.area_min &&
  //         temp.area_min !== null &&
  //         temp.area_min !== ''
  //       ) {
  //         newInfo.area_min = +inputValue;
  //       }

  //       return newInfo;
  //     });
  //   }
  // };

  /**
   * @description  구합니다 관계 선택
   * @returns {void}
   */
  const handleClickOpenRelationModal = () => {
    dispatch(
      openModal({
        modalType: 'findshare_modal_relation_type',
        isOpen: true,
        action: (val: IMixedKeyValue) => {
          if (Object.keys(val).length > 0) {
            setTemp({
              ...temp,
              relation_type: val.code,
              relation_name: val.name,
            });
          }
        },
      }),
    );
  };

  /**
   * @description  구합니다 매물 종류 sheet
   * @returns {void}
   */
  const handleClickType = () => {
    dispatch(
      openMultiModal({
        multiModalType: 'share_property_type_looking_for',
        multiData: {
          type_info: [...temp.type_info],
          type:
            temp.type_info.length > 0 && temp.type_info[0].code.includes('A')
              ? 'residential'
              : temp.type_info.length > 0 && temp.type_info[0].code.includes('B')
              ? 'commercial'
              : '',
        },
        multiAction: (typeList: any) => {
          setTemp({
            ...temp,
            type_info: typeList?.length > 0 ? [...typeList] : [],
          });
        },
        isMultiple: true,
      }),
    );
  };

  /**
   * @description 구합니다 매물 종류 sheet
   * @returns {void}
   */
  const handleClickSaleType = () => {
    dispatch(
      openModal({
        modalType: 'coagency_bottom_sale_type_select',
        data: {
          deal_type: temp.deal_type,
          isModify: true,
        },
        action: (type: string, name: string) => {
          setTemp({
            ...temp,
            deal_type: type,
            deal_type_name: name,
          });
        },
        isOpen: true,
      }),
    );
  };

  /**
   * @description 노출일 변경
   * @param {number} val 노출일 값
   * @returns {void}
   */
  const handleChangeExpireDate = (val: number) => {
    const copyState = cloneDeep(temp);
    copyState['show_days'] = val;

    setTemp(copyState);
  };

  /**
   * @description 매물 공유 옵션
   * @param {string} type 공유 옵션 값
   * @returns {void}
   */
  const handleClickShareType = (type: string) => {
    if (temp.status_share === type) return;
    const copyState = cloneDeep(temp);

    copyState['status_share'] = type;

    if (type === 'Y') {
      dispatch(
        openModal({
          modalType: 'coagency_bottom_share_input',
          data: {
            shares: temp.shares,
            isModify: true,
          },
          action: (list: any, status: any) => {
            setTemp({
              ...temp,
              status_share: status,
              shares: [...list],
            });
          },
          isOpen: true,
        }),
      );
    }
    setTemp(copyState);
  };

  /**
   * @description 소재지 삭제
   * @param {number} index 선택된 소재지 index
   * @returns {void}
   */
  const handleClickDelete = (index: number) => {
    const copyState = cloneDeep(temp);

    copyState.addr_info = copyState.addr_info.filter((_: any, itemIndex: number) => itemIndex !== index);

    setTemp(copyState);
  };

  /**
   * @description 소재지 선택 sheet
   * @param {any} areaInfo 소재지 정보
   * @param {number} index 선택된 소재지 index
   * @returns {void}
   */
  const handleClickAreaModify = (areaInfo: IMixedKeyValue, index: number) => {
    dispatch(
      openModal({
        modalType: 'share_property_type_looking_for_area',
        data: {
          ...areaInfo,
          isModify: true,
          isArea: true,
          areaIndex: index,
        },
        action: (data: IMixedKeyValue) => {
          const copyTemp = cloneDeep(temp);
          copyTemp['addr_info'][index] = {
            ...data,
          };

          setTemp(copyTemp);
        },
        isOpen: true,
      }),
    );
  };

  /**
   * @description 면적 선택
   * @param {string} type 선택된 면적 type
   * @returns {void}
   */
  const handleClickAreaType = (type: string) => {
    const copyTemp = cloneDeep(temp);

    if (areaType === 'meter') {
      setAreaType('square');
    } else {
      setAreaType('meter');
    }

    if (copyTemp.area_min && copyTemp.area_max) {
      copyTemp.area_min = convertArea(+copyTemp['area_min'], type);
      copyTemp.area_max = convertArea(+copyTemp['area_max'], type);
      setTemp(copyTemp);
    } else if (copyTemp.area_min) {
      copyTemp.area_min = convertArea(+copyTemp['area_min'], type);
      setTemp(copyTemp);
    } else if (copyTemp.area_max) {
      copyTemp.area_max = convertArea(+copyTemp['area_max'], type);
      setTemp(copyTemp);
    }
  };
  /**
   * @description 구합니다 삭제
   * @returns {void}
   */
  const handleClickDeleteSeek = () => {
    dispatch(
      openModal({
        modalType: 'confirm_looking_for_delete_check',
        isOpen: true,
        action: async () => {
          if (clickRef.current) return;
          clickRef.current = true;
          try {
            const res = await EAPIS.deleteSeek(`/seeks/${id}`);

            if (res.length === 0) {
              dispatch(
                openModal({
                  modalType: 'alert_looking_for_delete_success',
                  isOpen: true,
                  action: () => {
                    moveMain(4, '1');
                  },
                }),
              );
            }
          } catch (err) {
            console.log(err, 'err');
          } finally {
            clickRef.current = false;
          }
        },
      }),
    );
  };

  /**
   * @description 소재지 추가
   * @returns {void}
   */
  const handleClickAdd = () => {
    const copyTemp = cloneDeep(temp);

    const lastIndex = copyTemp['addr_info'].length;

    if (copyTemp['addr_info'].length >= 5) {
      dispatch(
        openModal({
          isOpen: true,
          modalType: 'alert_custom',
          data: {
            txt: '소재지는 최대 5개까지 추가 가능합니다.',
          },
        }),
      );
      return;
    }

    if (copyTemp['addr_info'][lastIndex - 1].addr_si === null) return;

    copyTemp['addr_info'].push({
      addr: null,
      addr_dong: null,
      addr_dong_code: null,
      addr_gu: null,
      addr_gu_code: null,
      addr_li: null,
      addr_li_code: null,
      addr_si: null,
      addr_si_code: null,
      area_cd: null,
      area_name: null,
    });

    setTemp(copyTemp);

    setTimeout(() => {
      const el = document.getElementById(`addrArea_${temp['addr_info'].length}`);
      if (el) {
        el.click();
      }
    }, 100);
  };
  /**
   * @description 취소 클릭
   * @returns {void}
   */
  const handleClickCancel = () => {
    dispatch(
      openModal({
        modalType: 'coagency_looking_edit_cancel',
        isOpen: true,
        action: () => {
          navigate(`/findsharedetail/${id}`, {
            replace: true,
          });
        },
      }),
    );
  };

  /**
   * @description 매물 min max 관련 input ref를 추가하는 함수
   * @param {string} key obj데이터 키값
   * @param {any} ref element 데이터
   */
  const addInputRef = (key: string, ref: any) => {
    if (key) {
      inputRefs.current = {
        ...inputRefs.current,
        [key]: ref,
      };
    }
  };

  /**
   * @description 수정하기 버튼 상태 enable disable
   */
  const isBtn = useMemo(() => {
    // 변경된 값이 있는지 validation 체크
    let check = true;
    const keys1 = Object.keys(info);
    for (const key of keys1) {
      // 두개의 객체 값을 비교
      if (info[key] !== temp[key]) {
        check = false;
        break;
      }
    }

    if ((temp.title === '' && temp.content === '') || (temp.title === null && temp.content === null)) {
      check = true;
    }

    if (
      (temp.customer_name !== info.customer_name ||
        info.phone_num !== temp.phone_num ||
        info.hidden_memo !== temp.hidden_memo) &&
      !isTermsAgree
    ) {
      check = true;
    }
    return check;
  }, [info, temp, isTermsAgree]);

  const isTerms = useMemo(() => {
    let check = true;
    if (
      temp.customer_name?.trim() !== '' ||
      temp.phone_num?.trim() !== '' ||
      temp.hidden_memo?.trim() !== '' ||
      temp.relation_type?.trim() !== '' ||
      temp.relation_name?.trim() !== ''
    ) {
      check = false;
    }
    return check;
  }, [info, temp]);

  useEffect(() => {
    if (isTerms === true && isTermsAgree === true) {
      setAgree(false);
    }
  }, [temp, isTerms, isTermsAgree]);

  useEffect(() => {
    if (info?.customer_name) {
      if (
        info.customer_name?.trim() !== null ||
        info.phone_num?.trim() !== '' ||
        info.hidden_memo?.trim() !== null ||
        info.relation_type?.trim() !== null ||
        info.relation_name?.trim() !== null
      ) {
        setAgree(true);
      }
    }
  }, [info]);

  const handleChangeAreaValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const len = `${value}`.length;
    const maxLen = `${value}`.includes('.') ? 7 : 6;

    if (len > maxLen) return;

    // if(len === 0)

    setTemp({
      ...temp,
      [name]: value,
    });
  };

  const handleInputClick = (event: any) => {
    const targetElement: HTMLElement = event.target;
    if (targetElement && !/Android/i.test(navigator.userAgent)) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <>
      <header>
        <div className={CommonStyles['left']}>
          <button
            onClick={() => {
              handleClickCancel();
            }}
          >
            <i className={CommonStyles['icon-back']}></i>
          </button>
        </div>
        <div className={CommonStyles['title']}>구합니다 상세 수정</div>
        <div className={CommonStyles['right']}></div>
      </header>
      <div className={cn(CommonStyles['container'], CommonStyles['hasBottom'])}>
        <div className={cn(CommonStyles['content'], CommonStyles['share-write'])}>
          <div className={CommonStyles['section-div']}>
            <section>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>구하는 매물 제목</p>
                <button className={cn(CommonStyles['input-area'], CommonStyles['has-byte'])}>
                  <input
                    onClick={handleInputClick}
                    name="title"
                    className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['line'])}
                    type="text"
                    value={temp?.title || ''}
                    maxLength={40}
                    onChange={(e: any) => {
                      onChangeValue(e, 40);
                    }}
                  />
                  <span className={CommonStyles['byte']}>{temp?.title?.length || 0}/40</span>
                </button>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>구하는 매물 상세 조건</p>
                <button className={cn(CommonStyles['textarea-div'], CommonStyles['type2'])}>
                  <textarea
                    onClick={handleInputClick}
                    name="content"
                    placeholder="구하는 매물의 상세조건을 입력해주세요."
                    maxLength={1000}
                    value={temp?.content || ''}
                    onChange={(e: any) => {
                      onChangeValue(e, 1000);
                    }}
                  />
                  <p className={CommonStyles['byte']}>
                    <b>{temp?.content?.length || 0}</b>/1000
                  </p>
                </button>
              </div>
            </section>
            <section>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>소재지</p>
                {temp?.addr_info?.map((item: any, index: number) => {
                  const name = `${item?.addr_si || ''} ${item?.addr_gu || ''} ${item?.addr_dong || ''} ${
                    item?.addr_li || ''
                  }
                    `;
                  return (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <button
                        id={`addrArea_${index}`}
                        key={index}
                        className={CommonStyles['input-area']}
                        onClick={() => handleClickAreaModify(item, index)}
                      >
                        <input
                          onClick={handleInputClick}
                          className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['line'])}
                          type="text"
                          value={name}
                          readOnly
                        />
                      </button>
                      {temp?.addr_info?.length >= 2 && (
                        <div
                          style={{
                            height: '100%',
                            position: 'absolute',
                            right: -7,
                            top: -12,
                            opacity: 0.7,
                          }}
                        >
                          <button
                            className={cn(CommonStyles['clear-has'], CommonStyles.delete)}
                            style={{ width: '16px', height: '16px', borderRadius: '50%' }}
                            onClick={() => handleClickDelete(index)}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
                <button
                  className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'], CommonStyles['mt12'])}
                  onClick={handleClickAdd}
                >
                  +
                </button>
              </div>
              {/* TODO 노출기간 수정 - 10. mo : 구합니다 > 상세 > 수정   */}
              {/* <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>매물 노출 기간을 선택해주세요.</p>
                <div className={cn(CommonStyles['button-list'], CommonStyles['p0'])}>
                  <button className={CommonStyles['radio-box']}>
                    <input
                      type="radio"
                      id="radio15"
                      name="radio15"
                      value="15"
                      checked={temp?.show_days === 15}
                      onChange={() => handleChangeExpireDate(15)}
                    />
                    <label htmlFor="radio15" className={CommonStyles['label-info']}>
                      15일
                    </label>
                  </button>
                  <button className={CommonStyles['radio-box']}>
                    <input
                      type="radio"
                      id="radio30"
                      name="radio30"
                      value="30"
                      checked={temp?.show_days === 30}
                      onChange={() => handleChangeExpireDate(30)}
                    />
                    <label htmlFor="radio30" className={CommonStyles['label-info']}>
                      30일
                    </label>
                  </button>
                </div>
              </div> */}

              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>연락처 노출 선택</p>
                <button
                  className={CommonStyles['input-area']}
                  onClick={() =>
                    dispatch(
                      openModal({
                        modalType: 'coagency_bottom_tel_display',
                        data: {
                          telDisplay: temp.tel_display,
                          isModify: true,
                        },
                        isOpen: true,
                        action: (val: string) => {
                          setTemp({
                            ...temp,
                            tel_display: val,
                          });
                        },
                      }),
                    )
                  }
                >
                  <input
                    onClick={handleInputClick}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    type="text"
                    value={
                      temp?.tel_display === 'A'
                        ? '모두 노출 (중개업소 전화번호+휴대폰번호)'
                        : temp?.tel_display === 'R'
                        ? '중개업소 전화번호만 노출'
                        : '휴대폰번호만 노출'
                    }
                    readOnly
                  />
                </button>
              </div>

              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>공유정보</p>
                <div className={CommonStyles['btn-wrap']}>
                  <button
                    className={cn(CommonStyles['btn'], temp.status_share === 'Y' && CommonStyles['line'])}
                    onClick={() => handleClickShareType('N')}
                  >
                    공유안함
                  </button>
                  <button
                    className={cn(CommonStyles['btn'], temp.status_share === 'N' && CommonStyles['line'])}
                    onClick={() => handleClickShareType('Y')}
                  >
                    공유함
                  </button>
                </div>
                {temp?.status_share === 'Y' && temp?.shares.length > 0 && (
                  <div
                    className={cn(CommonStyles['gray-box'], CommonStyles['dot-indent'])}
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalType: 'coagency_bottom_share_input',
                          data: {
                            shares: temp.shares,
                            isModify: true,
                          },
                          action: (list: any, status: any) => {
                            setTemp({
                              ...temp,
                              status_share: status,
                              shares: [...list],
                            });
                          },
                          isOpen: true,
                        }),
                      )
                    }
                  >
                    {temp?.shares.map((item: IMixedKeyValue, index: number) => {
                      return <p key={index}>{item.share_type === 'P' ? '전체 게시판(공동중개)' : item.name}</p>;
                    })}
                  </div>
                )}
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>매물 종류</p>
                <button
                  className={CommonStyles['dropdown']}
                  style={{ color: temp?.deal_type_name ? '#000' : '#aaa' }}
                  onClick={handleClickType}
                >
                  <span>
                    {temp?.type_info?.length > 0
                      ? temp.type_info.map((item: any, index: number) => {
                          return `${item.name}${temp.type_info.length !== index + 1 ? ',' : ''}`;
                        })
                      : '선택'}
                  </span>
                  <i className={CommonStyles['icon-arrow-down-gr']}></i>
                </button>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>거래유형</p>
                <div onClick={handleClickSaleType}>
                  <button className={CommonStyles['btn']}>{temp?.deal_type_name}</button>
                </div>
              </div>
              <div className={CommonStyles['insert-form']}>
                <p className={CommonStyles['sub-title']} style={{ marginLeft: '0rem' }}>
                  {(temp?.['3']?.trade_type === 'S' || temp?.deal_type === 'S') && '매매가'}
                  {(temp?.['3']?.trade_type === 'L' || temp?.deal_type === 'L') && '전세가'}
                  {(temp?.['3']?.trade_type === 'M' || temp?.deal_type === 'M') && '월세가'}
                  {(temp?.['3']?.trade_type === 'T' || temp?.deal_type === 'T') && '단기임대가'}
                </p>
                {(temp?.['3']?.trade_type === 'M' ||
                  temp?.['3']?.trade_type === 'T' ||
                  temp?.deal_type === 'M' ||
                  temp?.deal_type === 'T') && (
                  <div className={CommonStyles['insert-item']}>
                    <p className={CommonStyles['sub-title']} style={{ marginLeft: '0.5rem', marginTop: '0.5rem' }}>
                      {(temp?.['3']?.trade_type === 'M' ||
                        temp?.deal_type === 'M' ||
                        temp?.['3']?.trade_type === 'T' ||
                        temp?.deal_type === 'T') &&
                        '보증금'}
                    </p>
                    <div className={CommonStyles['input-div']}>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          type="tel"
                          name="min"
                          maxLength={8}
                          onFocus={(e) => {
                            const { value } = e.target;
                            const inputValue = value;
                            const isNumberValue = inputValue.replace(/\D/g, '');

                            if (inputRefs?.current?.['min']?.value?.includes(',')) {
                              inputRefs.current['min'].value = isNumberValue;
                            }
                          }}
                          onBlur={() => {
                            const inputValue = inputRefs.current['min'].value;
                            const isNumberValue = inputValue.replace(/\D/g, '');
                            const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            if (inputRefs?.current?.['min'].value) {
                              if (
                                parseInt(inputRefs?.current['max']?.value?.replace(/,/g, '')) > 0 &&
                                parseInt(inputRefs?.current['min']?.value?.replace(/,/g, '')) >
                                  parseInt(inputRefs?.current['max']?.value?.replace(/,/g, ''))
                              ) {
                                inputRefs.current['max'].value = null;
                                inputRefs.current['min'].value = commaValue;
                                setTemp({ ...temp, ['min']: commaValue, ['max']: null });
                              } else {
                                inputRefs.current['min'].value = commaValue;
                                setTemp({ ...temp, ['min']: commaValue });
                              }
                            } else {
                              setForce(new Date().getTime());
                            }
                          }}
                          ref={(ref) => addInputRef('min', ref)}
                        />
                        <span className={CommonStyles['unit']}>만원</span>
                      </div>
                      <span>~</span>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          type="tel"
                          name="max"
                          maxLength={8}
                          onFocus={(e) => {
                            const { value } = e.target;
                            const inputValue = value;
                            const isNumberValue = inputValue.replace(/\D/g, '');

                            if (inputRefs?.current?.['max']?.value?.includes(',')) {
                              inputRefs.current['max'].value = isNumberValue;
                            }
                          }}
                          onBlur={() => {
                            const inputValue = inputRefs.current['max'].value;
                            const isNumberValue = inputValue.replace(/\D/g, '');
                            const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            if (inputRefs?.current?.['max'].value) {
                              if (
                                parseInt(inputRefs?.current['max']?.value?.replace(/,/g, '')) > 0 &&
                                parseInt(inputRefs?.current['min']?.value?.replace(/,/g, '')) >
                                  parseInt(inputRefs?.current['max']?.value?.replace(/,/g, ''))
                              ) {
                                inputRefs.current['max'].value = null;
                                setTemp({ ...temp, ['max']: null });
                              } else {
                                inputRefs.current['max'].value = commaValue;
                                setTemp({ ...temp, ['max']: commaValue });
                              }
                            } else {
                              setForce(new Date().getTime());
                            }
                          }}
                          ref={(ref) => addInputRef('max', ref)}
                        />
                        <span className={CommonStyles['unit']}>만원</span>
                      </div>
                    </div>
                    <div className={cn(CommonStyles['w100p'], CommonStyles['info-txt'])}>
                      [금액:
                      <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                        {inputRefs?.current?.['min']?.value
                          ? formatNumber(inputRefs?.current?.['min']?.value?.replace(/,/g, ''))
                          : formatNumber(temp?.['min']?.replace(/,/g, '') || 0)}
                      </b>
                      원 ~{' '}
                      <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                        {inputRefs?.current?.['max']?.value
                          ? formatNumber(inputRefs?.current?.['max']?.value?.replace(/,/g, ''))
                          : formatNumber(temp?.['max']?.replace(/,/g, '') || 0)}
                      </b>
                      원]
                    </div>
                  </div>
                )}
                {/* 공통 min ~ max 입력란 */}
                <div className={CommonStyles['insert-item']}>
                  <p className={CommonStyles['sub-title']} style={{ marginLeft: '0.5rem' }}>
                    {(temp?.['3']?.trade_type === 'M' ||
                      temp?.['3']?.trade_type === 'T' ||
                      temp?.deal_type === 'M' ||
                      temp?.deal_type === 'T') &&
                      '월세'}
                  </p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="tel"
                        name="m_min"
                        maxLength={8}
                        onFocus={(e) => {
                          const { value } = e.target;
                          const inputValue = value;
                          const isNumberValue = inputValue.replace(/\D/g, '');

                          if (inputRefs?.current?.['m_min']?.value?.includes(',')) {
                            inputRefs.current['m_min'].value = isNumberValue;
                          }
                        }}
                        onBlur={() => {
                          const inputValue = inputRefs.current['m_min'].value;
                          const isNumberValue = inputValue.replace(/\D/g, '');
                          const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          if (inputRefs?.current?.['m_min'].value) {
                            if (
                              parseInt(inputRefs?.current['m_max']?.value?.replace(/,/g, '')) > 0 &&
                              parseInt(inputRefs?.current['m_min']?.value?.replace(/,/g, '')) >
                                parseInt(inputRefs?.current['m_max']?.value?.replace(/,/g, ''))
                            ) {
                              inputRefs.current['m_max'].value = null;
                              inputRefs.current['m_min'].value = commaValue;
                              setTemp({ ...temp, ['m_min']: commaValue, ['m_max']: null });
                            } else {
                              inputRefs.current['m_min'].value = commaValue;
                              setTemp({ ...temp, ['m_min']: commaValue });
                            }
                          } else {
                            setForce(new Date().getTime());
                          }
                        }}
                        ref={(ref) => addInputRef('m_min', ref)}
                      />
                      <span className={CommonStyles['unit']}>만원</span>
                    </div>
                    <span>~</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="tel"
                        name="m_max"
                        maxLength={8}
                        onFocus={(e) => {
                          const { value } = e.target;
                          const inputValue = value;
                          const isNumberValue = inputValue.replace(/\D/g, '');

                          if (inputRefs?.current?.['m_max']?.value?.includes(',')) {
                            inputRefs.current['m_max'].value = isNumberValue;
                          }
                        }}
                        onBlur={() => {
                          const inputValue = inputRefs.current['m_max'].value + '';
                          const isNumberValue = inputValue.replace(/\D/g, '');
                          const commaValue = isNumberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          if (inputRefs?.current?.['m_max'].value) {
                            if (
                              parseInt(inputRefs?.current['m_min']?.value?.replace(/,/g, '')) > 0 &&
                              parseInt(inputRefs?.current['m_min']?.value?.replace(/,/g, '')) >
                                parseInt(inputRefs?.current['m_max']?.value?.replace(/,/g, ''))
                            ) {
                              inputRefs.current['m_max'].value = null;
                              setTemp({ ...temp, ['m_max']: null });
                            } else {
                              inputRefs.current['m_max'].value = commaValue;
                              setTemp({ ...temp, ['m_max']: commaValue });
                            }
                          } else {
                            setForce(new Date().getTime());
                          }
                        }}
                        ref={(ref) => addInputRef('m_max', ref)}
                      />
                      <span className={CommonStyles['unit']}>만원</span>
                    </div>
                  </div>
                  <div className={cn(CommonStyles['w100p'], CommonStyles['info-txt'])}>
                    [금액:
                    <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                      {inputRefs?.current?.['m_min']?.value
                        ? formatNumber(inputRefs?.current?.['m_min']?.value?.replace(/,/g, ''))
                        : formatNumber(temp?.['m_min']?.replace(/,/g, '') || 0)}
                    </b>
                    원 ~{' '}
                    <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>
                      {inputRefs?.current?.['m_max']?.value
                        ? formatNumber(inputRefs?.current?.['m_max']?.value?.replace(/,/g, ''))
                        : formatNumber(temp?.['m_max']?.replace(/,/g, '') || 0)}
                    </b>
                    원]
                  </div>
                </div>
              </div>
              <div className={CommonStyles['sub-title']}>면적을 작성해주세요.</div>
              <div className={CommonStyles['insert-item']}>
                <div className={CommonStyles['input-div']}>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      name="area_min"
                      type="number"
                      maxLength={6}
                      value={temp.area_min}
                      onChange={handleChangeAreaValue}
                      onBlur={() => {
                        const inputValue = temp['area_min'] + '';
                        const isNumberValue = inputValue.replace(/\D/g, '');
                        if (temp['area_min']) {
                          if (
                            parseInt(temp['area_max']?.replace(/,/g, '')) > 0 &&
                            parseInt(temp['area_min']?.replace(/,/g, '')) >
                              parseInt(temp['area_max']?.replace(/,/g, ''))
                          ) {
                            setTemp({ ...temp, ['area_max']: null, ['area_min']: isNumberValue });
                          } else {
                            setTemp({ ...temp, ['area_min']: isNumberValue });
                          }
                        }
                      }}
                      ref={(ref) => addInputRef('area_min', ref)}
                    />
                    <span className={CommonStyles['unit']}>{areaType === 'meter' ? '㎡' : '평'}</span>
                  </div>
                  <span>~</span>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      name="area_max"
                      type="number"
                      maxLength={6}
                      value={temp?.area_max === null ? '' : temp?.area_max}
                      onChange={handleChangeAreaValue}
                      onBlur={() => {
                        const inputValue = temp['area_max'] + '';
                        const isNumberValue = inputValue.replace(/\D/g, '');
                        if (temp['area_max']) {
                          if (
                            parseInt(temp['area_max']?.replace(/,/g, '')) > 0 &&
                            parseInt(temp['area_min']?.replace(/,/g, '')) >
                              parseInt(temp['area_max']?.replace(/,/g, ''))
                          ) {
                            setTemp({ ...temp, ['area_max']: null });
                          } else {
                            setTemp({ ...temp, ['area_max']: isNumberValue });
                          }
                        }
                      }}
                      ref={(ref) => addInputRef('area_max', ref)}
                    />
                    <span className={CommonStyles['unit']}>{areaType === 'meter' ? '㎡' : '평'}</span>
                  </div>
                </div>
                <div className={cn(CommonStyles['ml-auto'], CommonStyles['btn-div'])}>
                  <button
                    className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['gry'])}
                    onClick={() => handleClickAreaType(areaType === 'meter' ? 'square' : 'meter')}
                  >
                    <i className={CommonStyles['icon-change']}></i>
                    {areaType === 'meter' ? '평형으로 입력' : '제곱미터로 입력'}
                  </button>
                </div>
              </div>
            </section>
            <section style={{ paddingBottom: isTerms ? '0.5rem' : '0' }}>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['title']}>비공개 정보 입력</p>
                <p className={CommonStyles['sub-txt']}>
                  *나만 보는 매물 관리 정보가 필요하신 경우 등록하세요. 매물 공유시에 타인에게 노출되지 않습니다.
                </p>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>고객명</p>
                <div className={cn(CommonStyles['d-flex'], CommonStyles['half'])} style={{ display: 'flex' }}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      name="customer_name"
                      className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['line'])}
                      type="text"
                      placeholder="고객명(비공개)"
                      value={temp?.customer_name || ''}
                      onChange={onChangeValue}
                    />
                  </button>
                  <button
                    className={CommonStyles['dropdown']}
                    onClick={() =>
                      dispatch(
                        openModal({
                          modalType: 'coagency_select_customer_relation_fn',
                          data: relationList,
                          multiModalType: '', // 멀티모달은 초기화
                          action: (val: IMixedKeyValue) => {
                            if (Object.keys(val).length > 0) {
                              if (val?.name === '선택') {
                                setTemp({
                                  ...temp,
                                  relation_type: '',
                                  relation_name: '',
                                });
                              } else {
                                setTemp({
                                  ...temp,
                                  relation_type: val.code,
                                  relation_name: val.name,
                                });
                              }
                            }
                          },
                          isOpen: true,
                        }),
                      )
                    }
                  >
                    <span style={{ color: temp?.relation_name ? '#000' : '#aaa' }}>
                      {temp?.relation_name ? temp.relation_name : '선택'}
                    </span>
                    <i className={CommonStyles['icon-arrow-down-gr']}></i>
                  </button>
                </div>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>고객 연락처</p>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    name="phone_num"
                    className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['line'])}
                    type="text"
                    placeholder="고객연락처(비공개)"
                    value={temp?.phone_num || ''}
                    onChange={onChangeValue}
                  />
                </button>
              </div>
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>중개사 메모</p>
                <button className={cn(CommonStyles['textarea-div'], CommonStyles['type2'])}>
                  <textarea
                    onClick={handleInputClick}
                    name="hidden_memo"
                    placeholder="중개사 메모(비공개)"
                    value={temp?.hidden_memo || ''}
                    onChange={onChangeValue}
                    maxLength={1000}
                  />
                  <p className={CommonStyles['byte']}>
                    <b>{temp?.hidden_memo?.length || 0}</b>/1000
                  </p>
                </button>
              </div>
              {!isTerms && (
                <div
                  className={cn(CommonStyles['check-box'], CommonStyles['agree-chk'])}
                  onClick={() => {
                    if (isTermsAgree) {
                      setAgree(false);
                    } else {
                      handleClickTerms();
                    }
                  }}
                >
                  <input
                    onClick={handleInputClick}
                    type="checkbox"
                    defaultChecked={isTermsAgree}
                    checked={isTermsAgree}
                    readOnly
                  />
                  <label className={CommonStyles['label-info']}>
                    (선택) 상기 개인정보의 수집, 이용에 대해 고객으로부터 동의를 받았습니다.
                  </label>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])} style={{ background: '#fff' }}>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['gry'])}
          onClick={handleClickCancel}
        >
          취소
        </button>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], CommonStyles['bk'])}
          onClick={handleClickDeleteSeek}
        >
          삭제
        </button>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], isBtn && CommonStyles['disabled'])}
          disabled={isBtn}
          onClick={handleClickSave}
        >
          수정완료
        </button>
      </div>
      {loading && (
        <div className={CommonStyles['loading-container']}>
          <div className={CommonStyles['loading-spinner']} />
        </div>
      )}
    </>
  );
};

export default EditFindShare;
