import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/group';
import { moveMain, onClose } from 'utils';
import { RootState, useAppSelector } from 'redux/store';
import { useDispatch } from 'react-redux';
import { openModal } from 'redux/slices/modal';

/**
 * @description 모임 초대 분기 화면
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupInvite = () => {
  // 그룹 코드를 받고 초대된 사람들 화면 이동
  // 여기서 초대 코드 검증
  const navigate = useNavigate();
  const { isSubView = false } = useAppSelector((state: RootState) => state.global);
  const params = useParams();
  const { code = '' } = params;
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  // 모임 초대 코드를 받고 온사람을 분기 처리해서 각 모임으로 이동
  useEffect(() => {
    if (code) {
      const fetchCode = async () => {
        try {
          const res = await APIS.getConfirmInviteCode(`/invite/${code}`);
          const { id } = res;

          if (id && code) {
            if (isSubView) {
              navigate(`/mygroup/group/${id}/${code}`);
            } else {
              navigate(`/mygroup/group/${id}/${code}`);
              //onView(`/mygroup/group/${id}/${code}`);
            }
          }
        } catch (err) {
          setLoading(false);
          dispatch(
            openModal({
              modalType: 'alert_common',
              data: {
                txt: '존재하지 않는 모임입니다.',
              },
              isOpen: true,
              action: () => {
                moveMain(0, '');
                onClose();
              },
            }),
          );
        }
      };
      const f = async () => {
        fetchCode();
      };

      f();
    }
  }, []);

  return (
    <div className={CommonStyles.container}>
      {isLoading && (
        <div className={CommonStyles['loading-container']}>
          <div className={CommonStyles['loading-spinner']} />
        </div>
      )}
    </div>
  );
};

export default GroupInvite;
