import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import GradeBadge from 'components/common/Badge/GradeBadge';
import { useEffect, useState } from 'react';
import { initInfo } from 'redux/slices/groupmember';

const INFO_TAB = [
  {
    title: '글쓰기',
    label: 'total_post_count',
  },
  {
    title: '댓글',
    label: 'total_post_count',
  },
  {
    title: '공유매물',
    label: 'offering_count',
  },
  {
    title: '거래완료',
    label: 'offering_complete_count',
  },
  {
    title: '받은공감',
    label: 'total_like_count',
  },
];

const GRADE = ['새싹', '열매', '초수', '중수', '고수', '영웅'];
const GAUAGE_POSITION = [1, 3, 5, 7, 9];

/**
 * @description 모임 - 회원 상세
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupMemberDetail = () => {
  const dispatch = useDispatch();
  const memberInfo = useSelector((state: RootState) => state.groupmember);
  const [member, setMember] = useState<any>({});
  const [position, setPosition] = useState<string>('0px');

  useEffect(() => {
    if (member?.grade) {
      const findIndex = GRADE.findIndex((item) => item === member.grade);
      if (findIndex > -1) {
        const el = document.getElementById(`gage-bar-${findIndex}`);
        const po = GAUAGE_POSITION[findIndex];
        if (el) {
          const width = el.offsetWidth;
          setPosition((width / 2) * po + 'px');
        }
      }
    }
    return () => {
      // unmount시 데이터 초기화
      dispatch(initInfo);
    };
  }, [member]);

  useEffect(() => {
    if (memberInfo) {
      setMember(memberInfo);
    }
  }, [memberInfo]);

  return (
    <div className={CommonStyles.container}>
      <div className={cn(CommonStyles.content, CommonStyles['group-member'])}>
        <div className={cn(CommonStyles['member-profile'], CommonStyles.vertical)}>
          <div className={CommonStyles.profile}>
            <div
              className={CommonStyles['img-div']}
              style={{ backgroundImage: `url(${member.profile_photo_path ? member.profile_photo_path : ''})` }}
            ></div>
            <GradeBadge grade={member.grade!} />
          </div>
          <div className={CommonStyles['mem-info']}>
            <p className={CommonStyles.name}>{member?.nickname || '-'}</p>
            {/* <p className={CommonStyles['mem-id']}>확인필요</p> */}
            <div className={CommonStyles['divide-box']}>
              {/* <p>방배동</p> */}
              {member?.company?.name && (
                <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>{member.company.name}</p>
              )}
            </div>
            <p className={CommonStyles['grade-txt']}>
              회원님의 현재 등급은 <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>{member.grade}</b>{' '}
              입니다.
            </p>
          </div>
        </div>
        <div className={CommonStyles['grade-information']}>
          <div className={CommonStyles['grade-gage']}>
            <span
              className={cn(CommonStyles['grade-tooltip'], CommonStyles['bottom-center'])}
              style={{ left: position }}
            >
              {member.point_level}
            </span>
            <div className={CommonStyles['gage-bar']}>
              {Array.from({ length: 6 }).map((_, index) => {
                const level = GRADE.findIndex((item) => item === member.grade);
                const check = index <= level ? true : false;
                return (
                  <div
                    id={`gage-bar-${index}`}
                    key={index}
                    className={check ? cn(CommonStyles.section, CommonStyles.on) : CommonStyles.section}
                  />
                );
              })}
            </div>
          </div>
          <div className={cn(CommonStyles['gray-box'], CommonStyles['active-state'])}>
            {INFO_TAB.map((item: { title: string; label: string }, index: number) => {
              return (
                <div key={index} className={CommonStyles.detail}>
                  <span className={CommonStyles.tit}>{item.title}</span>
                  <span className={CommonStyles.count}>{member?.[item.label] || 0}</span>
                </div>
              );
            })}
          </div>
        </div>
        {member?.company && (
          <>
            <div className={CommonStyles['contour-line']}></div>
            <div className={CommonStyles['agency-info']}>
              <p className={CommonStyles['sub-title']}>{member?.nickname || ''} 공인중개사 정보</p>
              <div className={CommonStyles.line}>
                <p className={CommonStyles.tit}>중개업소</p>
                <p className={CommonStyles.txt}>{member?.company?.name || '-'}</p>
              </div>
              {member?.verify?.broker_name && (
                <div className={CommonStyles.line}>
                  <p className={CommonStyles.tit}>자격</p>
                  <p className={CommonStyles.txt}>{member?.verify?.broker_name || '-'}</p>
                </div>
              )}
              {member?.company?.address_detail && (
                <div className={CommonStyles.line}>
                  <p className={CommonStyles.tit}>소재지</p>
                  <p className={CommonStyles.txt}>{member?.company?.address_detail}</p>
                </div>
              )}
              {member?.company?.contact && (
                <div className={CommonStyles.line}>
                  <p className={CommonStyles.tit}>연락처</p>
                  <div className={CommonStyles.txt}>
                    <p>{member?.company?.contact}</p>
                    {member?.phone && <p>{member?.phone || '-'}</p>}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GroupMemberDetail;
