import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  load: false,
  isFunction: false,
  refreshFn: () => {},
};

/**
 * @description refresh
 * @Class
 * @category redux
 * @subcategory slice
 */
const refreshSlice = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    onRefresh: (state) => {
      state.load = true;
    },
    offRefresh: (state) => {
      state.load = false;
    },
    setRefreshFn: (state, action) => {
      state.isFunction = true;
      state.refreshFn = action.payload.refreshFn;
    },
    initRefreshFn: (state) => {
      state.refreshFn = () => {};
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { onRefresh, offRefresh, setRefreshFn, initRefreshFn } = refreshSlice.actions;

export default refreshSlice;
