import { createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { PURGE } from 'redux-persist';

/*
    message : 일반 toast message
    close   : close버튼이 존재하는 toast message
    action  : 특정 액션 버튼이 존재하는 toast message
*/
type ToastMessageType = 'message' | 'close' | 'action' | 'message_left' | 'message_right';

// toast 팝업은 총 3가지 일반 toast message, close button 이 존재하는 toast, 특정 애션 버튼이 존재하는 toast message
export interface Toast {
  toastId?: string; // toast id
  content: string | ReactNode; // toast content *required
  duration?: number; // toast duration 유지시간
  type?: ToastMessageType; // toast message type
  action?: boolean; // toast action 여부 default false ex) 만약에 true면 특정 액션이 존재 close 버튼 or action 버튼
  isOpen?: boolean; // toast open 여부 default false
}

const initialState = {
  toastId: '',
  content: '',
  duration: 2000, // toast 유지시간 default 2초
  type: 'message',
  action: false,
  isOpen: false,
};

/**
 * @description toast
 * @Class
 * @category redux
 * @subcategory slice
 */
const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (state, action) => {
      state.toastId = action.payload.toastId;
      state.content = action.payload.content;
      state.duration = action.payload.duration;
      state.isOpen = action.payload.isOpen;
      state.type = action.payload.type;
    },
    closeToast: (state) => {
      state.content = '';
      state.isOpen = false;
      state.type = 'message';
    },
  },

  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { openToast, closeToast } = toastSlice.actions;

export default toastSlice;
