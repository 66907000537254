// styles
import { TOffers } from '@types';
import { getOfferGbnStr, getStatusGbnStr } from 'utils/common';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';

const LegacyItem = ({
  index,
  item,
  selectOffers,
  changeOffers,
}: {
  index: number;
  item: TOffers.TImportOffers;
  selectOffers: Array<number>;
  changeOffers: (_offeringsSeq: number) => void;
}) => {
  const {
    targetSourceNameText,
    targetSourceName,
    offeringsGbn,
    offeringsSeq,
    imgList,
    addrStr,
    memo,
    price,
    supSqrStr,
    offerGbnStr,
    ntStatusGbn,
    ntDisplayDate,
    ntExpireDate,
    existImport,
  } = item;
  return (
    <a
      className={cn(
        CommonStyles['sale-card'],
        CommonStyles['bg-gry'],
        selectOffers.includes(offeringsSeq || 0) && CommonStyles.on,
      )}
      onClick={() => {
        changeOffers(offeringsSeq || 0);
      }}
    >
      <div className={CommonStyles['state-summary']}>
        <div className={CommonStyles['divide-box']}>
          <p className={cn(CommonStyles['co-dorg'], CommonStyles['has-icon'])}>
            <i className={CommonStyles['icon-A']}></i>매물번호 {offeringsSeq}
          </p>
        </div>
        {item.existImport && <i className={cn(CommonStyles.legacy, CommonStyles['icon-legacy'])}></i>}
      </div>
      <div className={CommonStyles.type}>[{getOfferGbnStr(offeringsGbn)}]</div>
      <div className={CommonStyles.address}>{addrStr}</div>
      <div className={CommonStyles.price}>
        <b className={CommonStyles['co-bl']}>{offerGbnStr}</b>
        {price}만원
      </div>
      <div className={cn(CommonStyles.description, CommonStyles['limit-line1'])}>{memo}</div>
      {targetSourceName === 'adMst' && (
        <div className={CommonStyles.description}>
          광고검증상태 : {getStatusGbnStr(ntStatusGbn) || '-'}
          {ntStatusGbn === '50' && `(${ntDisplayDate} ~ ${ntExpireDate})`}
        </div>
      )}
    </a>
  );
};
export default LegacyItem;
