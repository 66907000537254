import { isIOS, isSafari, isAndroid } from 'react-device-detect';
import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';

/**
 * @description 앱다운로드 화면
 * @Class
 * @category Pages
 * @subcategory 앱다운로드
 * @component
 * @returns {JSX.Element}
 */
const AppDownload = () => {
  return (
    <div id="wrap">
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles['full-page'], CommonStyles['app-download'])}>
          <div className={CommonStyles['img-box']}>
            <img
              className={CommonStyles['app-pop-bg']}
              src="/images/app_bg.svg"
              alt="당신의 부동산 여정을 가치 있게 만드는 곳 이실장넷 앱으로 보기"
            />
            <img className={CommonStyles.logo} src="/images/logo.svg" alt="이실장넷 로고" />
          </div>
          <p className={CommonStyles.txt}>당신의 부동산 여정을 가치 있게 만드는 곳 이실장넷</p>
          <a
            href={
              isAndroid
                ? 'https://play.google.com/store/apps/details?id=net.aipartner&pcampaignid'
                : isIOS || isSafari
                ? 'https://apps.apple.com/kr/app/id6475016320'
                : 'https://www.aipartner.net'
            }
            className={cn(CommonStyles.btn, CommonStyles.org, CommonStyles.lg)}
          >
            앱 다운로드
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
