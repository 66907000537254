import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import CommonStyles from 'styles/common.module.css';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={cn(CommonStyles.content, CommonStyles['error-page'])}>
      <i className={CommonStyles['icon-error']}></i>
      <div className={CommonStyles['txt-box']}>
        <p className={CommonStyles.tit}>요청하신 페이지를 찾을 수 없어요.</p>
        <p className={CommonStyles.txt}>서비스 이용에 불편을 드려 죄송합니다.</p>
      </div>
      <button className={cn(CommonStyles.btn, CommonStyles.line)} onClick={() => navigate(-1)}>
        이전으로 돌아가기
      </button>
    </div>
  );
};
export default NotFound;
