import { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { IArea } from '<broker>';
import network from 'utils/network';

interface Props {
  type?: string;
  title?: string;
}

/**
 * @description 중개사 sheet
 * @Class
 * @category Components
 * @subcategory broker sheet
 * @component
 * @returns {JSX.Element}
 */
const BrokerBottomSheet = (props: Props) => {
  // const { title } = props;

  const dispatch = useAppDispatch();
  const { modalType, isOpen, data } = useAppSelector((state: RootState) => state.modal);

  const [open, setOpen] = useState<boolean>(isOpen || false);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  //체크박스 동의 비동의
  const [agree, setAgree] = useState<boolean>(false);

  //모달 닫기
  const handleModalClose = () => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    }, 200);
  };

  //동의화면 confirm
  const handleAgreeConfirm = () => {
    if (agree === true) {
      data?.actionNavi();
    }
    handleModalClose();
  };

  //중개사인증 개설등록번호 등록 요청
  const [sidoList, setSidoList] = useState<IArea[]>([]);
  const [gugunList, setGugunList] = useState<IArea[]>([]);
  const [sido, setSido] = useState<IArea>();
  const [gugun, setGugun] = useState<IArea>();
  //시도버튼 클릭
  const handleSelectSido = (_sido: IArea) => {
    setSido(_sido);
  };
  //구군버튼 클릭
  const handleSelectGugun = (_gugun: IArea) => {
    setGugun(_gugun);
  };

  // 시/도 목록
  async function postCompanyArea() {
    dispatch(onLoad());
    network()
      .broker()
      .postCompanyArea()
      .then((res) => {
        const _sido = { code: '', cd: '', name: '시/도', addr_li_code: '' };
        const _gugun = { code: '', cd: '', name: '시/군/구', addr_li_code: '' };
        setSido(data?.sido);
        setGugun(data?.gugun);
        setSidoList([]);
        setGugunList([]);
        data?.handleSelectArea(_sido, _gugun);

        dispatch(offLoad());
        setSidoList(
          res.sort((a: IArea, b: IArea): number => {
            return parseInt(a.code) - parseInt(b.code);
          }),
        );
      });
  }

  // 구/군 목록
  async function postCompanyAreaSido(sido: IArea) {
    dispatch(onLoad());
    network()
      .broker()
      .postCompanyArea({
        si_code: sido.cd,
      })
      .then((res) => {
        dispatch(offLoad());
        setGugunList(
          res.sort((a: IArea, b: IArea): number => {
            return parseInt(a.code) - parseInt(b.code);
          }),
        );
      });
  }

  //최초 시/도 가져오기
  useEffect(() => {
    if (modalType === 'broker_bottom_area') {
      postCompanyArea();
    }
  }, []);

  //시도 데이터가 넘어왔을 경우
  useEffect(() => {
    if (modalType === 'broker_bottom_area') {
      if (data?.sido) {
        setSido(data?.sido);
        postCompanyAreaSido(data?.sido);
      }
    }
  }, [data]);

  //시도선택시 구군 가져오기
  useEffect(() => {
    if (modalType === 'broker_bottom_area') {
      if (sido) {
        postCompanyAreaSido(sido);
      }
    }
  }, [sido]);

  //구군선택시
  useEffect(() => {
    if (modalType === 'broker_bottom_area') {
      if (sido && gugun) {
        data?.handleSelectArea(sido, gugun);
        handleModalClose();
      }
    }
  }, [gugun]);

  const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // IOS 홈버튼 유무 확인
  const hasHomeBtn = () => {
    const ratio = window.devicePixelRatio || 1;
    const screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };

    const homeButtonResolutions = [
      { width: 750, height: 1334 },
      { width: 1242, height: 2208 },
      { width: 640, height: 1136 },
    ];

    return homeButtonResolutions.some(
      (res) =>
        (res.width === screen.width && res.height === screen.height) ||
        (res.width === screen.height && res.height === screen.width),
    );
  };

  return (
    <>
      {modalType === 'broker_bottom_agree' && (
        <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
          <div
            className={cn(Styles['popup-container'], Styles['bottom-sheet-popup'], CommonStyles['personal-info-pop'])}
          >
            <div className={Styles['popup-header']}>
              <p className={Styles['pop-tit']}>개인정보 수집 · 이용 동의</p>
              <button className={Styles.close} onClick={handleModalClose} />
            </div>
            <div className={Styles['popup-content']}>
              <div className={CommonStyles['round-table']}>
                <div className={CommonStyles['row']}>
                  <p className={cn(CommonStyles['col'], CommonStyles['bg'])}>수집·이용 목적</p>
                  <p className={CommonStyles['col']}>중개사 인증</p>
                </div>
                <div className={CommonStyles['row']}>
                  <p className={cn(CommonStyles['col'], CommonStyles['bg'])}>수집·이용 항목</p>
                  <p className={CommonStyles['col']}>
                    중개업소정보
                    <br />
                    (상호명, 주소, 대표자명, 개설등록번호, 사업자등록번호, 중개업소 대표 전화번호)
                  </p>
                </div>
                <div className={CommonStyles['row']}>
                  <p className={cn(CommonStyles['col'], CommonStyles['bg'])}>보유 및 이용기간</p>
                  <p className={CommonStyles['col']}>
                    <b>탈퇴일로부터 1년</b>
                  </p>
                </div>
              </div>
              <p className={CommonStyles['txt']}>
                귀하는 위 동의를 거부할 권리가 있으며, 위 동의를 거부하더라도 회원서비스를 이용하실 수 있습니다. 다만,
                동의 거부 시 상기 목적에 명시된 서비스를 제공 받으실 수 없습니다
              </p>
              <div className={CommonStyles['check-box-div']}>
                <div className={CommonStyles['check-box']}>
                  <input
                    type="checkbox"
                    id="broker_agree_check"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                    style={{ width: '0px', height: '0px' }}
                  />
                  <label htmlFor="broker_agree_check" className={CommonStyles['label-info']}>
                    <span className={CommonStyles['co-red']}>[필수]</span> 개인정보 수집·이용 동의
                  </label>
                </div>
              </div>
            </div>
            {/* <div id="div_bottom" style={{ height: '1px', background: 'red', zIndex: 1000 }}></div> */}
            <div
              className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}
              style={{ paddingBottom: isIos ? (hasHomeBtn() ? '20px' : '80px') : '0px' }}
            >
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg, !agree ? CommonStyles.disabled : '')}
                style={{ zIndex: 100 }}
                onClick={handleAgreeConfirm}
              >
                동의하기
              </button>
            </div>
            {/* <div id="btn_bottom" style={{ height: '1px', background: 'red', zIndex: 1000, marginTop: '-22px' }}></div> */}
          </div>
        </div>
      )}

      {modalType === 'broker_bottom_area' && (
        <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
          <div className={cn(Styles['popup-container'], Styles['bottom-sheet-popup'], CommonStyles['area-select-pop'])}>
            <div className={Styles['popup-header']}>
              <p className={Styles['pop-tit']}>지역 선택</p>
              <button className={Styles.close} onClick={handleModalClose} />
            </div>
            <div className={cn(Styles['popup-content'], CommonStyles['popup-content'])}>
              <div className={CommonStyles['select-step1']}>
                <button type="button" className={sido?.name && CommonStyles.seleted} onClick={postCompanyArea}>
                  {sido?.name || '시/도'}
                </button>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
                <button type="button" className={gugun?.name && CommonStyles.seleted}>
                  {gugun?.name || '시/군/구'}
                </button>
              </div>
              <div className={CommonStyles['scroll-area']}>
                {sidoList.length > 0 && gugunList.length < 1 && (
                  <div className={CommonStyles['select-step2']}>
                    {sidoList.map((area: IArea) => (
                      <button
                        key={area.code}
                        type="button"
                        className={cn(CommonStyles.btn, area.code !== sido?.code ? CommonStyles.line : '')}
                        onClick={() => handleSelectSido(area)}
                      >
                        {area.name}
                      </button>
                    ))}
                  </div>
                )}
                {sidoList.length > 0 && gugunList.length > 0 && (
                  <div className={CommonStyles['select-step2']}>
                    {gugunList.map((area: IArea) => (
                      <button
                        key={area.code}
                        type="button"
                        className={cn(CommonStyles.btn, area.code !== gugun?.code ? CommonStyles.line : '')}
                        onClick={() => handleSelectGugun(area)}
                      >
                        {area.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {modalType === 'broker_bottom_invite' && (
        <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
          <div
            className={cn(Styles['popup-container'], Styles['bottom-sheet-popup'], CommonStyles['personal-info-pop'])}
          >
            <div className={Styles['popup-header']}>
              <p className={Styles['pop-tit']}>개인정보 수집 · 이용 동의</p>
              <button className={Styles.close} onClick={handleModalClose} />
            </div>
            <div className={cn(Styles['popup-content'], CommonStyles['popup-content'])}>
              <div className={CommonStyles['round-table']}>
                <div className={CommonStyles['row']}>
                  <p className={cn(CommonStyles['col'], CommonStyles['bg'])}>수집·이용 목적</p>
                  <p className={CommonStyles['col']}>대표자에게 이실장넷 회원가입 및 중개사인증 요청</p>
                </div>
                <div className={CommonStyles['row']}>
                  <p className={cn(CommonStyles['col'], CommonStyles['bg'])}>수집·이용 항목</p>
                  <p className={CommonStyles['col']}>대표자 휴대폰번호</p>
                </div>
                <div className={CommonStyles['row']}>
                  <p className={cn(CommonStyles['col'], CommonStyles['bg'])}>보유 및 이용기간</p>
                  <p className={CommonStyles['col']}>
                    <b>요청일로부터 6개월</b>
                  </p>
                </div>
              </div>
              <p className={CommonStyles['txt']}>
                귀하는 위 동의를 거부할 권리가 있으며, 위 동의를 거부하더라도 회원서비스를 이용하실 수 있습니다. 다만,
                동의 거부 시 상기 목적에 명시된 서비스를 제공 받으실 수 없습니다
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleModalClose}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BrokerBottomSheet;
