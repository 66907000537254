import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import {
  fetchPostCommentsData,
  deletePostComment,
  deleteReportPostComment,
  setBlockUser,
  unBlockUser,
} from 'api/comment';
import CommentContent from './CommentContent';

import Panel from 'components/common/Panel/Panel';
import BottomSheet from '../modal/popup/BottomSheet';
import Toast from 'components/common/Toast/Toast';

import Modal from 'react-modal';
import CommonConfirm from '../modal/popup/CommonConfirm';

import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Styles from 'styles/modal.module.css';
import { IMixedKeyValue } from '<modal>';
// utils
import { timeDisplay } from 'utils/common';
import { ICommentType, TisViewChildren, TisEdit } from '<comments>';

interface ChildListProps {
  comment?: IMixedKeyValue;
  commentType?: ICommentType;
  setCommentType?: (commentType: ICommentType) => void;
  isUnFold?: TisViewChildren[];
  isEdit?: TisEdit;
  childrenData?: IMixedKeyValue;
  setIsEdit?: (isEdit: TisEdit) => void;
}

/**
 * @description 댓글 child list F.C
 * @Class
 * @category Components
 * @subcategory 댓글
 * @param {ChildListProps} props
 * @param {object} props.comment
 * @param {object} props.commentType
 * @param {function} props.setCommentType
 * @param {object} props.isUnFold
 * @param {any[]} props.isEdit
 * @param {object} props.childrenData
 * @param {function} props.setIsEdit
 * @returns {JSX.Element}
 */
const ChildList = ({
  comment,
  commentType,
  setCommentType,
  isUnFold,
  isEdit,
  childrenData,
  setIsEdit,
}: ChildListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const toastState = useAppSelector((state: RootState) => state.toast);

  const infoMe = useAppSelector((state: RootState) => state.user.me);
  const comments = useAppSelector((state: RootState) => state.comment);
  const currentKindId = useAppSelector((state: RootState) => state.board.currentKindId);
  const nowDate = new Date();
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [targetDepth, setTargetDepth] = useState('2depth');
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  useEffect(() => {
    const _isOpen = isUnFold?.find((k) => k.referId === comment?.parent_id)?.isOpen;
    setIsOpen(_isOpen!);
    setTargetDepth('2depth');
  }, [isUnFold, comment]);

  /**
   * @description children reply
   * @returns {void}
   */
  const handleChildrenReply = () => {
    setCommentType!({
      ...commentType,
      focusDepth: '2depthReply',
      parentId: comment?.parent_id || '',
      mentionId: comment?.user?.id,
      mentionNickname: comment?.user?.nickname,
      isOn: true,
    } as ICommentType);
  };

  /**
   * @description comment tool
   * @returns {void}
   */
  const handleToolsTwoDepth = () => {
    if (infoMe.id === comment?.user_id) {
      dispatch(
        ActionModal.openMultiModal({
          multiModalType: 'comment_tool_me',
          multiData: comment,
          action: () => handleEditComment(comment?.id),
          multiAction: () => handleDeleteComment(comment?.id),
          isMultiple: true,
        }),
      );
      console.log('comment 작성자가 나의 경우 tooltip bottomsheet');
    } else {
      dispatch(
        ActionModal.openMultiModal({
          multiModalType: 'comment_tool_other',
          multiData: comment,
          action: async () =>
            comment?.comment_report?.length > 0
              ? await handlRemoveReport(
                  comment?.parent_id,
                  comment?.comment_report[0].comment_id,
                  comment?.comment_report[0].id,
                  '2depth',
                )
              : handleReport(), // 여기에 신고, 신고해제 api영역
          multiAction: () => (comment?.user_block?.block_user_id ? fetchUnBlockUser() : handleBlock()),
          isMultiple: true,
        }),
      );
      console.log('comment 작성자가 내가 아닌경우 tooltip bottomsheet');
    }
  };

  /**
   * @description Comment edit
   * @returns {number} comment id
   * @returns {void}
   */
  const handleEditComment = (id: number) => {
    setIsEdit!({
      ...isEdit,
      editTarget: '2depth',
      parentCommentId: comment?.parent_id,
      editCommentId: id,
      isEditOn: true,
    } as TisEdit);
  };

  /**
   * @description Comment delete
   * @returns {void}
   */
  const handleDeleteComment = (id: number) => {
    console.log('delete child 2depth==>');
    dispatch(deletePostComment('2depth', comment?.id, comment?.parent_id));
  };

  /**
   * @description 신고하기
   * @returns {void}
   */
  const handleReport = (): void => {
    setIsSheetOpen(true);
  };

  /**
   * @description 신고해제
   * @returns {void}
   */
  const handlRemoveReport = async (
    parentId: string,
    commentId: number,
    reportId: number,
    depthType: string,
  ): Promise<void> => {
    await dispatch(deleteReportPostComment(parentId, commentId, reportId, depthType));
    await dispatch(fetchPostCommentsData(currentKindId, comments.postId!, comments.groupId!));
  };

  /**
   * @description 2depth comment 사용자 차단하기
   * @returns {void}
   */
  const handleBlock = () => {
    setIsConfirmOpen(true);
  };

  /**
   * @description 2depth comment 사용자 차단하기 api call
   * @returns {Promise<void>}
   */
  const fetchBlockUser = async (): Promise<void> => {
    const params = {
      block_user_id: comment?.user_id,
    };
    await dispatch(setBlockUser(comment?.parent_id, comment?.id, params, targetDepth));
    await dispatch(fetchPostCommentsData(currentKindId, comments.postId!, comments.groupId!));
    setIsConfirmOpen(false);
  };

  /**
   * @description 2depth comment 사용자 차단하기 해제
   * @returns {Promise<void>}
   */
  const fetchUnBlockUser = async (): Promise<void> => {
    await dispatch(unBlockUser(comment?.parent_id, comment?.id, comment?.user_id, targetDepth));
    await dispatch(fetchPostCommentsData(currentKindId, comments.postId!, comments.groupId!));
    setIsConfirmOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div key={`item_child_${comment?.id}`} className={CommonStyles['reply-box']} id={`item_child_${comment?.id}`}>
          <div className={cn(CommonStyles['comment-item'], CommonStyles.reply)}>
            <div className={CommonStyles['member-information']}>
              <button className={CommonStyles.clickable}>
                <div className={CommonStyles.profile}>
                  <div
                    className={CommonStyles['img-div']}
                    style={{
                      width: 32,
                      height: 32,
                      backgroundImage: comment?.user?.profile_photo_path
                        ? `url(${comment?.user?.profile_photo_path}`
                        : `url(../images/icon_profile.svg)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                    }}
                  />
                  {comment?.user?.verify?.broker_code && (
                    <span className={CommonStyles.grade} aria-label={comment?.user?.grade}></span>
                  )}
                </div>
                <div className={CommonStyles['mem-info']}>
                  <p className={CommonStyles.name}>{comment?.user?.nickname}</p>
                  <div className={CommonStyles['divide-box']}>
                    {comment?.user?.company && (
                      <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                        {comment?.user?.company?.name}
                      </p>
                    )}
                    {(childrenData?.isType === 'coagency' || childrenData?.isType === 'looking-for') && (
                      <p>{comment?.user?.phone}</p>
                    )}
                    <p>{timeDisplay(nowDate, comment?.created_at)}</p>
                  </div>
                </div>
              </button>
              <button type="button" className={CommonStyles.tool} onClick={handleToolsTwoDepth} />
            </div>
            <div
              className={CommonStyles.content}
              key={`item_child_content_${comment?.id}`}
              id={`item_child_content_${comment?.id}`}
              style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}
            >
              {comment?.user_block?.block_user_id && (
                <div className={CommonStyles['blind-box']} style={{ margin: '5px 0' }}>
                  {'차단된 게시글입니다.'}
                </div>
              )}
              {comment?.comment_report?.length > 0 && (
                <div className={CommonStyles['blind-box']} style={{ margin: '5px 0' }}>
                  {'신고된 게시글입니다.'}
                </div>
              )}
              {comment?.comment_report?.length === 0 && !comment?.user_block && (
                <>
                  <div className={CommonStyles.text}>
                    <CommentContent
                      key={comment?.id}
                      id={comment?.id}
                      childKey={comment?.id}
                      comment={comment}
                      maxLines={3}
                      onExpended={() => {
                        setShow(true);
                      }}
                    />
                  </div>
                  {(comment?.photos?.length > 0 || comment?.emoticon) && (
                    <div className={CommonStyles['attached-div']}>
                      {comment?.photos?.length > 0 && (
                        <div className={CommonStyles['img-box']}>
                          <button className={CommonStyles['img-item']}>
                            <img src={comment?.photos[0].url} alt={comment?.photos[0].org_name} />
                          </button>
                        </div>
                      )}
                      {comment?.emoticon && (
                        <div className={CommonStyles['emoji-box']}>
                          <img src={`/images/${comment.emoticon}`} alt="이모지" />
                        </div>
                      )}
                    </div>
                  )}
                  {!comment?.user_block && !comment?.comment_report?.length && (
                    <div className={CommonStyles['post-function']}>
                      <button className={CommonStyles.comment} onClick={() => handleChildrenReply()}>
                        <i className={CommonStyles['icon-comment']}></i>답글 달기
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {isSheetOpen && (
            <Panel open={isSheetOpen!} onDismiss={() => setIsSheetOpen(!isSheetOpen)} modalType={'bottomSheet_report'}>
              <BottomSheet
                type="bottomSheet_report"
                title="신고하기"
                targetData={comment}
                targetDepth="2depth"
                setIsSheetOpen={setIsSheetOpen}
              />
            </Panel>
          )}
          {isConfirmOpen && (
            <Modal
              isOpen={isConfirmOpen!}
              onRequestClose={() => setIsConfirmOpen(!open)}
              style={{
                overlay: {
                  position: 'fixed',
                  zIndex: 999999999,
                  inset: 0,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
              }}
              className={Styles.modal}
              ariaHideApp={false}
            >
              <CommonConfirm
                type="confirm_block_user"
                title="차단하시겠어요?"
                align="left"
                txt={`이 회원이 쓴 글과 댓글에 대해 알림이 오지 않고,\n작성한 내용도 보이지 않게 됩니다.`}
                fetchBlockUser={fetchBlockUser}
                setIsConfirmOpen={setIsConfirmOpen}
              />
            </Modal>
          )}
          {toastState.isOpen && <Toast />}
        </div>
      )}
    </>
  );
};

export default ChildList;
