import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState } from 'redux/store';
import { setMe, setBrokerPublic } from 'redux/slices/user';
import { openModal } from 'redux/slices/modal';
import network from 'utils/network';
import { IUser } from '../../@types/user';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import axios from 'axios';

/**
 * @description 내피드 중개사 정보 F.C
 * @Class
 * @category Pages
 * @subcategory 내피드
 * @component
 * @returns {JSX.Element}
 */
const BrokerInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const me = useAppSelector((state: RootState) => state.user.me);
  // 인증타입, 1: 중개사 미인증, 2: 중개사 인증 (대표), 3: 대표 외
  const [verifyType, setVerifyType] = useState<number | null>(null);
  // 중개사 인증일시
  const [verifyDate, setVerifyDate] = useState<string | null>(null);
  // 중개사 직원 목록
  const [brokerList, setBrokerList] = useState<IUser[] | []>([]);

  /**
   * @description 내정보 조회
   * @returns {void}
   */
  const getMe = async () => {
    try {
      const response = await network().sso().me();
      dispatch(setMe(response.data));
    } catch (error) {
      //console.log('getMe(error):', error);
    }
  };

  /**
   * @description 중개사 직원목록(대표 제외) 조회
   * @returns {void}
   */
  const getBrokerList = async () => {
    try {
      const response = await network().broker().getBrokerList();
      setBrokerList(response.data[0].filter((user: IUser) => user.id !== me.id));
    } catch (error) {
      //console.log('getMe(error):', error);
    }
  };

  /**
   * @description 중개사 정보 공개 토글
   * @returns {void}
   */
  const changeBrokerPublicState = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const response = await network().sso().brokerpublic();
      if (response.data?.broker_public_at !== undefined) {
        dispatch(setBrokerPublic(response.data?.broker_public_at));
      }
    } catch (error) {
      //console.log('toggle broker public error:', error);
    }
  };

  /**
   * @description 중개사 퇴사 handle
   * @returns {void}
   */
  const handleBrokerLeave = async () => {
    try {
      // 퇴사 가능여부 확인
      const response = await network().broker().getBrokerVerifyCheck();

      if (response.data?.leave?.possible === true) {
        // 퇴사 가능한 경우
        const action = () => {
          brokerLeave();
        };

        if (response.data?.leave?.message) {
          dispatch(
            openModal({ modalType: 'confirm_custom', data: { txt: response.data?.leave?.message }, action: action }),
          );
        } else {
          brokerLeave();
        }
      } else if (response.data?.leave?.possible === false) {
        // 퇴사 불가능한 경우
        if (response.data?.leave?.message) {
          dispatch(openModal({ modalType: 'alert_custom', data: { txt: response.data?.leave?.message } }));
        }
      }
    } catch (error) {
      //console.log('verify check:', error);
    }
  };

  /**
   * @description 중개사 해고 handle
   * @param {number} id 중개사 id
   * @returns {void}
   */
  const handleBrokerFire = async (id: number) => {
    try {
      // 해고 가능여부 확인
      const response = await network().broker().getBrokerFireCheck(id);

      if (response.data?.fire?.possible === true) {
        // 해고 가능한 경우
        const action = () => {
          brokerFire(id);
        };

        if (response.data?.fire?.message) {
          dispatch(
            openModal({ modalType: 'confirm_custom', data: { txt: response.data?.fire?.message }, action: action }),
          );
        } else {
          brokerFire(id);
        }
      } else if (response.data?.fire?.possible === false) {
        // 퇴사 불가능한 경우
        if (response.data?.fire?.message) {
          dispatch(openModal({ modalType: 'alert_custom', data: { txt: response.data?.fire?.message } }));
        }
      }
    } catch (error) {
      //console.log('fire check:', error);
    }
  };

  /**
   * @description 중개사 퇴사
   * @returns {void}
   */
  const brokerLeave = async () => {
    try {
      await network().broker().deleteBrokerLeave();
      getMe();
    } catch (error) {
      let message = '오류가 발생하였습니다.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message ? error.response?.data?.message : '';
      } else if (error instanceof Error) {
        message = error.message;
      }

      dispatch(openModal({ modalType: 'alert_custom', data: { txt: message } }));
    }
  };

  /**
   * @description 중개사 해고
   * @param {number} id 중개사 id
   * @returns {void}
   */
  const brokerFire = async (id: number) => {
    try {
      const response = await network().broker().deleteBrokerFire(id);
      setBrokerList(response.data[0].filter((user: IUser) => user.id !== me.id));
    } catch (error) {
      let message = '오류가 발생하였습니다.';
      if (axios.isAxiosError(error)) {
        message = error.response?.data?.message ? error.response?.data?.message : '';
      } else if (error instanceof Error) {
        message = error.message;
      }

      dispatch(openModal({ modalType: 'alert_custom', data: { txt: message } }));
    }
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    // 인증타입
    const type =
      me.verify === null && me.verify_temp === null ? 1 : me.verify !== null && me.verify?.is_ceo === 1 ? 2 : 3;
    setVerifyType(type);

    // 중개사 인증 완료일
    if (me.verify?.created_at) {
      const date = new Date(me.verify.created_at);
      const year = date.getFullYear().toString().slice(2);
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const datestr = `${year}.${month}.${day}`;
      setVerifyDate(datestr);
    } else {
      setVerifyDate(null);
    }

    // 직원목록(대표 권한)
    if (type == 2) {
      getBrokerList();
    }
  }, [me.verify, me.verify_temp]);

  return (
    <div className={cn(CommonStyles['content'], CommonStyles['agency-cert'])}>
      {/* 중개사 미인증 */}
      {verifyType && verifyType === 1 && (
        <>
          <div className={CommonStyles['no-data']}>
            <i className={CommonStyles['icon-phone-agency']}></i>
            <p className={cn(CommonStyles['lg-txt'], ResetStyles['ta-c'])}>
              중개사 정보가 없네요.
              <br />
              <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>중개사 인증을 해보세요!</b>
            </p>
          </div>
          <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
            <button className={cn(CommonStyles.btn, CommonStyles.lg)} onClick={() => navigate('/broker')}>
              중개사 인증하기
            </button>
          </div>
        </>
      )}

      {/* 중개사 인증 */}
      {verifyType && verifyType !== 1 && (
        <>
          <div className={CommonStyles['line-box']}>
            <div className={CommonStyles.top}>
              <p className={CommonStyles.tit}>{me.company?.name}</p>
              <button className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles.line)} onClick={handleBrokerLeave}>
                삭제
              </button>
            </div>
            <div className={CommonStyles.information}>
              <p className={CommonStyles.tit}>
                {me.verify?.is_ceo
                  ? '대표'
                  : me.verify?.broker_code == 2
                  ? '소속공인중개사'
                  : me.verify?.broker_code == 4
                  ? '중개보조원'
                  : ''}
                : {me.verify?.is_ceo ? me.company?.ceo_name : me.name}
              </p>
              <p>{me.company?.address_detail}</p>
              <p>전화번호 : {me.company?.contact}</p>
              <p>개설번호 : {me.company?.corp_number}</p>
              <p style={!me.verify ? { color: '#FF5959' } : {}}>
                ({me.verify ? `중개사인증 완료 ${verifyDate}` : `중개사인증 미완료 대표님 가입 필요`} )
              </p>
            </div>
            <div className={cn(CommonStyles['gray-box'], CommonStyles['toggle-list'])}>
              <label htmlFor="openInfo">중개사 정보 공개</label>
              <div className={cn(CommonStyles['toggle-box'], CommonStyles['round-toggle'])}>
                <input
                  type="checkbox"
                  style={{ width: '0px', height: '0px' }}
                  id="openInfo"
                  onChange={changeBrokerPublicState}
                  checked={me.broker_public_at ? true : false}
                />
                <label htmlFor="openInfo" className={CommonStyles['label-info']}></label>
              </div>
            </div>
          </div>

          {/* 대표 */}
          {verifyType === 2 && (
            <div className={CommonStyles['agency-memeber']}>
              <p className={CommonStyles.tit}>소속공인중개사 {brokerList.length}명</p>
              <table>
                <caption>소속공인중개사 리스트</caption>
                <colgroup>
                  <col width="40%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>구분(직위)</th>
                    <th>이름</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {brokerList.map((user: IUser, index) => {
                    let info = '';

                    if (user.verify?.broker_code == 2) {
                      info = '소속공인중개사';
                    } else if (user.verify?.broker_code == 4) {
                      info = '중개보조원';
                    }
                    return (
                      <tr key={index}>
                        <td>{info}</td>
                        <td>{user.name}</td>
                        <td>
                          <button
                            className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles.line)}
                            onClick={() => {
                              if (user.id) handleBrokerFire(user.id);
                            }}
                          >
                            퇴사
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {/* 대표 외 */}
          {verifyType === 3 && (
            <div className={cn(CommonStyles['agency-memeber'], CommonStyles['gray-box'])}>
              <div className={CommonStyles.txt}>
                소속공인중개사 또는 중개보조원의 경우엔
                <br />
                소속되어있는 대표님께서 이실장넷 중개사인증을
                <br />
                완료해야 중개사인증 완료가 가능합니다.
              </div>
              <button
                className={cn(CommonStyles.btn, me.verify !== null && CommonStyles.disabled)}
                onClick={() => {
                  navigate('/broker/invite');
                }}
              >
                대표님께 가입 초대장 보내기
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BrokerInfo;
