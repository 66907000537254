/* eslint-disable no-unsafe-optional-chaining */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { history } from 'router/index';

// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionGroup from 'redux/slices/group';

import { isCheckWebview } from 'utils/common';

// styles
import CommonStyles from 'styles/common.module.css';

// utils
import network from 'utils/network';
import { IMixedKeyValue } from '<modal>';

// configs
import * as Config from 'configs/configs';
import { onView, onClose } from 'utils';

/**
 * @description 새 게시물 component
 * @Class
 * @category Pages
 * @subcategory 글쓰기
 * @component
 * @returns {JSX.Element}
 */
const NewPostSelect = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isVerified = useAppSelector((state: RootState) => state.user.brokerVerified);
  const historyPath = useAppSelector((state: RootState) => state.user.historyPath);
  const MygroupList = useAppSelector((state: RootState) => state.group.mygroup);
  const [isLoading, setIsLoading] = useState(false);
  const [mygroupList, setMyGroupList] = useState(MygroupList || []);
  const [totalCount, setTotalCount] = useState(0);

  /**
   * @description hardware backey history 동작
   */
  useEffect(() => {
    const listenBackEvent = () => {
      if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
        navigate(`/newpost-type`);
      } else if (isVerified) {
        onClose();
      }
    };

    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlisten;
  }, [history]);

  /**
   * @description 내그룹목록 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const f = async () => {
      setIsLoading(true);
      await getMyGroup();
    };
    f();
    return () => abortController.abort();
  }, []);

  /**
   * @description 내 그룹목록의 redux데이터를 local state에 매핑
   */
  useEffect(() => {
    if (MygroupList?.length) {
      setMyGroupList(MygroupList);
    }
  }, [MygroupList]);

  /**
   * @description 내그룹 목록 api call
   * @returns {Promise<void>}
   */
  const getMyGroup = async (): Promise<void> => {
    try {
      const res = await network().group().getMyGroup();
      const { list, total_count } = res.data;
      setMyGroupList(list);
      setTotalCount(total_count);
      dispatch(ActionGroup.setMyGroup(list));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  /**
   * @description 내 그룹목록 리스트
   */
  const groupList =
    mygroupList.length > 0 &&
    mygroupList?.map((group: IMixedKeyValue, index: number) => {
      return (
        <button
          key={index}
          className={CommonStyles['line-box']}
          onClick={() => {
            if (isVerified) {
              navigate({
                pathname: '/writing',
                search: `?mode=write&type=group&groupId=${group?.id}&name=${group?.name}`,
              });
            } else {
              onView(
                `${
                  import.meta.env.VITE_APP_DOMAIN
                }/writing?mode=write&type=group&groupId=${group?.id}&name=${group?.name}`,
              );
            }
          }}
        >
          <span className={CommonStyles['img-box']}>
            <img
              src={group?.photos.length > 0 && group?.photos[0]?.url}
              alt={group?.photos[0]?.url ? '그룹 프로필 이미지' : ''}
            />
          </span>
          <span className={CommonStyles['group-tit']}>{group?.name}</span>
        </button>
      );
    });

  /**
   * @description 내 그룹목록이 없을 경우(가입한 목록이 하나도 없을경우)
   */
  const noItem = (
    <div className={CommonStyles['no-data']}>
      <p>
        아직 가입한 내모임이 없으시네요!
        <br />
        다양한 모임에 가입하여
        <br />
        게시글 공유 활동을 시작해보세요!
      </p>
      <button
        type="button"
        className={cn(CommonStyles.btn, CommonStyles.lg)}
        onClick={() => onView('aipartnernet://net.aipartner/open?name=CategoryGroup')}
      >
        주제별 모임 찾기
      </button>
    </div>
  );

  // console.log('mygroupList==>', mygroupList);
  // console.log('totalCount==>', totalCount);
  // console.log('historyPath==>', historyPath);

  return (
    <>
      <div
        className={cn(
          CommonStyles.content,
          CommonStyles['select-board'],
          Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
        )}
      >
        <div className={CommonStyles['button-list']}>
          <p className={CommonStyles.tit}>전체 게시판</p>
          <button
            onClick={() => {
              if (isVerified) {
                navigate({
                  pathname: '/writing',
                  search: '?mode=write&type=free-board',
                });
              } else {
                onView(`${import.meta.env.VITE_APP_DOMAIN}/writing?mode=write&type=free-board`);
              }
            }}
          >
            자유 게시판<i className={cn(CommonStyles['icon-arrow-down-gr'], CommonStyles['icon-right'])}></i>
          </button>
          <button
            onClick={() => {
              if (isVerified) {
                navigate({
                  pathname: '/writing',
                  search: '?mode=write&type=issue',
                });
              } else {
                onView(`${import.meta.env.VITE_APP_DOMAIN}/writing?mode=write&type=issue`);
              }
            }}
          >
            부동산 이슈<i className={cn(CommonStyles['icon-arrow-down-gr'], CommonStyles['icon-right'])}></i>
          </button>
        </div>
        <div className={CommonStyles['contour-line']} />
        <div className={CommonStyles['line-button-list']}>
          <p className={CommonStyles.tit}>내가 가입한 모임</p>
          <>{mygroupList?.length > 0 ? groupList : noItem}</>
        </div>
      </div>
    </>
  );
};

export default NewPostSelect;
