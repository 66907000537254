import { RootState, useAppSelector } from 'redux/store';
import CommonStyles from 'styles/common.module.css';

/**
 * @description default loader
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @returns {JSX.Element}
 */
const Loader = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.loading);
  return isLoading ? (
    <div className={CommonStyles['loading-container']}>
      <div className={CommonStyles['loading-spinner']} />
    </div>
  ) : null;
};

export default Loader;
