import React, { TextareaHTMLAttributes } from 'react';
import CommonStyles from 'styles/common.module.css';
import cn from 'classnames';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface TextareaInterface extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  maxLength?: number;
  show?: boolean; // maxLength 값 보이기/숨기기 default true
}

/**
 * @description textarea
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {string} props.value textarea value
 * @param {string} props.placeholder textarea placeholder
 * @param {string} props.className textarea className
 * @param {void} props.onChange textarea onchange 함수
 * @param {boolean} props.disabled textarea enable disabled 여부
 * @param {number} props.maxLength textarea maxLength
 * @param {boolean} props.show textarea maxLength 값 show 여부
 * @returns {JSX.Element}
 */
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaInterface>(
  (
    {
      value = '',
      placeholder = '',
      onChange = () => {},
      disabled = false,
      maxLength = '',
      className = '',
      show = true,
    },
    ref,
  ) => {
    let defaultProps = {};
    if (maxLength) {
      defaultProps = {
        ...defaultProps,
        maxLength: maxLength,
      };
    }
    return (
      <ParentDiv className={className ? className : cn(CommonStyles['textarea-div'])}>
        <StyledTextarea
          ref={ref}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...defaultProps}
          disabled={disabled}
        />
        {maxLength && show && (
          <p className={cn(CommonStyles['byte'])}>
            <b>{value.length}</b>/{maxLength}
          </p>
        )}
      </ParentDiv>
    );
  },
);

export default Textarea;

const ParentDiv = styled.div`
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;

  &:focus-within {
    border-color: #ff951a;
  }
`;

const StyledTextarea = styled.textarea`
  border: none;
  outline: none;
`;
