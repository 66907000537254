import { useState, useMemo } from 'react';
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
// redux
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';

// configs
import * as Config from 'configs/configs';
// utils
import { getOfferGroup } from 'utils/common';
import { formatNumber } from 'utils';
// styles
import CommonStyles from 'styles/common.module.css';
// type
import { IMixedKeyValue } from '<modal>';

interface TProps {
  type?: string;
  regState?: IMixedKeyValue;
}

/**
 * @description 공유매물등록 상단 요약 component
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @param {TProps} props
 * @param {string} props.type
 * @param {object} props.regState
 * @returns {JSX.Element}
 */
const CommonSummary = (props: TProps) => {
  const { type, regState } = props;
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const REMainType = useAppSelector(
    (state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_main_gbn,
  );
  const RESubType = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_gbn);
  const OfferGbn = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offer_gbn);

  // 간략히 보기
  const [show, setShow] = useState<boolean>(true);

  /**
   * @description 가격
   */
  const getFirstPrice = useMemo(() => {
    const price =
      regState?.offerings_main_gbn === 'B' && regState?.offerings_gbn === 'S'
        ? ``
        : `${regState?.price_info[0]?.value?.toLocaleString()}`;
    return price;
  }, [regState]);

  // console.log('type, regState===>', type, regState);

  return (
    <>
      <button
        className={cn(CommonStyles.btn, CommonStyles.underline, CommonStyles['view-info-btn'])}
        aria-pressed={show}
        onClick={() => setShow(true)}
      >
        내매물 간략하게 보기
      </button>
      <div className={cn(CommonStyles['gray-box'], CommonStyles.summary)} aria-hidden="false">
        <div className={CommonStyles.item}>
          <div className={CommonStyles.tit}>매물종류</div>
          {type === 'detail-edit' && regState?.id && (
            <div className={CommonStyles.txt}>
              {Config.REType?.find((item) => item.main_cd === regState?.offerings_main_gbn)?.offer_types.find(
                (k) => k.sub_cd === regState?.offerings_gbn,
              )?.sub_nm || ''}
            </div>
          )}
          {type === 'write' && (
            <div className={CommonStyles.txt}>
              {
                Config.REType.find(
                  (item) => item.main_cd === shareRegStep?.coagencyRegData?.offerings_main_gbn,
                )?.offer_types?.find((k) => k.sub_cd === shareRegStep?.coagencyRegData?.offerings_gbn)?.sub_nm
              }
            </div>
          )}
        </div>
        <div className={CommonStyles.item}>
          <div className={CommonStyles.tit}>소재지</div>
          {type === 'detail-edit' && regState?.id && <div className={CommonStyles.txt}>{regState?.addr}</div>}
          {type === 'write' && (
            <div className={CommonStyles.txt}>
              {`${shareRegStep?.selectedAddr?.sidoAddr?.name} ${shareRegStep?.selectedAddr?.guGunAddr
                ?.name} ${shareRegStep?.selectedAddr?.eupMyeonDongAddr?.name} ${
                shareRegStep?.selectedAddr?.liAddr?.name || ''
              }`}
            </div>
          )}
        </div>
        {type === 'detail-edit' &&
          regState?.id &&
          getOfferGroup(regState?.offerings_gbn) === 'apartment_group' &&
          regState?.offerings_gbn !== 'RB' && (
            <div className={CommonStyles.item}>
              <div className={CommonStyles.tit}>단지정보</div>
              <div className={CommonStyles.txt}>{regState?.complexAddr?.complex_nm || '-'}</div>
            </div>
          )}
        {((type === 'write' && REMainType === 'A' && RESubType !== 'RB') || REMainType === 'B') && (
          <div className={CommonStyles.item}>
            <div className={CommonStyles.tit}>단지정보</div>
            <div className={CommonStyles.txt}>{shareRegStep?.selectedAddr?.complexAddr?.complex_nm || ''}</div>
          </div>
        )}

        {/** 거래유형 */}
        <div className={CommonStyles.item}>
          <div className={CommonStyles.tit}>거래유형</div>
          {type === 'detail-edit' && regState?.id && (
            <div className={CommonStyles.txt}>
              {Config.COAGENCY_REG_OFFER_GBN?.find((item) => item.value === regState?.offerGbn)?.label}
            </div>
          )}
          {type === 'write' && (
            <div className={CommonStyles.txt}>
              {Config.COAGENCY_REG_OFFER_GBN?.find((item) => item.value === OfferGbn)?.label}
            </div>
          )}
        </div>

        {type === 'detail-edit' && regState?.id && (
          <>
            {(regState?.offerings_main_gbn === 'B' && regState?.offerGbn === 'S') || (
              <div className={CommonStyles.item}>
                <div className={CommonStyles.tit}>
                  {regState?.offerGbn === 'S'
                    ? '매매가'
                    : regState?.offerGbn === 'L'
                    ? '전세가'
                    : regState?.offerGbn === 'M' || regState?.offerGbn === 'T'
                    ? '보증금'
                    : ''}
                </div>
                {`${getFirstPrice} 만원(${formatNumber(Number(String(getFirstPrice).replace(/,/g, '')))} 원)`}
              </div>
            )}
            {(regState?.offerGbn === 'M' || regState?.offerGbn === 'T') && (
              <div className={CommonStyles.item}>
                <div className={CommonStyles.tit}>월세</div>
                <div className={CommonStyles.txt}>
                  {regState?.price_info.length > 0
                    ? `${Number(regState?.price_info[1]?.value)?.toLocaleString()} 만원 (${formatNumber(
                        Number(String(regState?.price_info[1]?.value?.toLocaleString()).replace(/,/g, '')),
                      )} 원)`
                    : '0'}
                </div>
              </div>
            )}
          </>
        )}
        {type === 'write' && (
          <>
            <div className={CommonStyles.item}>
              <div className={CommonStyles.tit}>
                {OfferGbn === 'S' && REMainType !== 'B'
                  ? '매매가'
                  : OfferGbn === 'L'
                  ? '전세가'
                  : OfferGbn === 'M' || OfferGbn === 'T'
                  ? '보증금'
                  : ''}
              </div>
              {OfferGbn === 'S' && REMainType !== 'B' && (
                <div className={CommonStyles.txt}>
                  {shareRegStep?.coagencyRegData?.sell_prc
                    ? `${Number(shareRegStep?.coagencyRegData?.sell_prc)?.toLocaleString()} 만원 (${formatNumber(
                        Number(String(shareRegStep?.coagencyRegData?.sell_prc).replace(/,/g, '')),
                      )} 원)`
                    : '0'}
                </div>
              )}
              {OfferGbn === 'L' && (
                <div className={CommonStyles.txt}>
                  {shareRegStep?.coagencyRegData?.lease_prc
                    ? `${Number(shareRegStep?.coagencyRegData?.lease_prc)?.toLocaleString()} 만원 (${formatNumber(
                        Number(String(shareRegStep?.coagencyRegData?.lease_prc).replace(/,/g, '')),
                      )} 원)`
                    : '0'}
                </div>
              )}
              {(OfferGbn === 'M' || OfferGbn === 'T') && (
                <div className={CommonStyles.txt}>
                  {shareRegStep?.coagencyRegData?.deposit_prc
                    ? `${Number(shareRegStep?.coagencyRegData?.deposit_prc)?.toLocaleString()} 만원 (${formatNumber(
                        Number(String(shareRegStep?.coagencyRegData?.deposit_prc).replace(/,/g, '')),
                      )} 원)`
                    : '0'}
                </div>
              )}
            </div>
            {(OfferGbn === 'M' || OfferGbn === 'T') && (
              <div className={CommonStyles.item}>
                <div className={CommonStyles.tit}>월세</div>
                <div className={CommonStyles.txt}>
                  {shareRegStep?.coagencyRegData?.monthly_prc
                    ? `${Number(shareRegStep?.coagencyRegData?.monthly_prc)?.toLocaleString()} 만원 (${formatNumber(
                        Number(String(shareRegStep?.coagencyRegData?.monthly_prc).replace(/,/g, '')),
                      )} 원)`
                    : '0'}
                </div>
              </div>
            )}
          </>
        )}

        {/** 거래유형 분양*/}
        {type === 'detail-edit' &&
          regState?.offerGbn === 'S' &&
          regState?.offerings_main_gbn === 'B' &&
          regState?.price_info?.map((item: IMixedKeyValue) => {
            return (
              <div key={item.name} className={CommonStyles.item}>
                <div className={CommonStyles.tit}>{item.name}</div>
                <div className={CommonStyles.txt}>
                  {`${Number(item.value)?.toLocaleString()} 만원 (${formatNumber(
                    Number(String(item.value).replace(/,/g, '')),
                  )} 원)`}
                </div>
              </div>
            );
          })}
        {type === 'write' && OfferGbn === 'S' && REMainType === 'B' && (
          <>
            <div className={CommonStyles.item}>
              <div className={CommonStyles.tit}>분양가</div>
              <div className={CommonStyles.txt}>
                {shareRegStep?.coagencyRegData?.parcel_prc
                  ? `${Number(shareRegStep?.coagencyRegData?.parcel_prc)?.toLocaleString()} 만원 (${formatNumber(
                      Number(String(shareRegStep?.coagencyRegData?.parcel_prc).replace(/,/g, '')),
                    )} 원)`
                  : '0'}
              </div>
            </div>
            <div className={CommonStyles.item}>
              <div className={CommonStyles.tit}>프리미엄 분양가</div>
              <div className={CommonStyles.txt}>
                {shareRegStep?.coagencyRegData?.premium_prc
                  ? `${Number(shareRegStep?.coagencyRegData?.premium_prc)?.toLocaleString()} 만원 (${formatNumber(
                      Number(String(shareRegStep?.coagencyRegData?.premium_prc).replace(/,/g, '')),
                    )} 원)`
                  : '0'}
              </div>
            </div>
            <div className={CommonStyles.item}>
              <div className={CommonStyles.tit}>분양옵션가</div>
              <div className={CommonStyles.txt}>
                {shareRegStep?.coagencyRegData?.parcel_option_prc
                  ? `${Number(shareRegStep?.coagencyRegData?.parcel_option_prc)?.toLocaleString()} 만원 (${formatNumber(
                      Number(String(shareRegStep?.coagencyRegData?.parcel_option_prc).replace(/,/g, '')),
                    )} 원)`
                  : '0'}
              </div>
            </div>
          </>
        )}
        <div className={CommonStyles.item}>
          <div className={CommonStyles.tit}>입주가능일</div>
          {type === 'detail-edit' && regState?.id && (
            <div className={CommonStyles.txt}>
              {regState?.isMoveChg === 'Y' && <span>입주가능일협의</span>}
              {regState?.isMoveChg === 'N' && regState?.moveGbn === 'A' ? (
                <span>즉시입주</span>
              ) : regState?.isMoveChg === 'N' && regState?.moveGbn === 'S' ? (
                <span>{moment(regState?.moveYmd).format('YYYY-MM-DD')}</span>
              ) : regState?.isMoveChg === 'N' && ['B', 'C', 'D'].includes(regState?.moveGbn) ? (
                <span>{`${moment(regState?.moveYmd).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                  (item) => item.cd === regState?.moveGbn,
                )?.name}`}</span>
              ) : (
                <span />
              )}
            </div>
          )}
          {type === 'write' && (
            <div className={CommonStyles.txt}>
              {shareRegStep.coagencyRegData.is_move_chg === 'Y' && <span>입주가능일협의</span>}
              {shareRegStep.coagencyRegData.is_move_chg === 'N' && shareRegStep.coagencyRegData.move_gbn === 'A' ? (
                <span>즉시입주</span>
              ) : shareRegStep.coagencyRegData.is_move_chg === 'N' && shareRegStep.coagencyRegData.move_gbn === 'S' ? (
                <span>{moment(shareRegStep.coagencyRegData.move_ymd).format('YYYY-MM-DD')}</span>
              ) : shareRegStep.coagencyRegData.is_move_chg === 'N' &&
                ['B', 'C', 'D'].includes(shareRegStep.coagencyRegData.move_gbn) ? (
                <span>{`${moment(shareRegStep.coagencyRegData.move_ymd).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                  (item) => item.cd === shareRegStep.coagencyRegData.move_gbn,
                )?.name}`}</span>
              ) : (
                <span />
              )}
            </div>
          )}
        </div>
        <button
          type="button"
          className={cn(
            CommonStyles.btn,
            CommonStyles.sm,
            CommonStyles.line,
            CommonStyles['ml-auto'],
            CommonStyles['hide-info-btn'],
          )}
          aria-pressed={!show}
          onClick={() => setShow(false)}
        >
          접기
        </button>
      </div>
    </>
  );
};

export default CommonSummary;
