/* eslint-disable no-dupe-else-if */
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';

import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import cn from 'classnames';

// components
import MoveInDatePicker from 'components/common/DatePicker/MoveInDatePicker';
import moment from 'moment';
import { TClickDate } from 'components/common/DatePicker/MoveInDatePicker';
// styles
import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

// configs
import * as Config from 'configs/configs';
// utils
import { formatNumber } from 'utils';
import network from 'utils/network';
type TbuildingType = {
  dongNm: string;
  gbnCodeNm: string;
  mngPk: string;
  sitePos: string;
};

type TFloorType = {
  buildingPk: string;
  floorNm: string;
  hoNm: string;
  useNm: string;
  etcNm: string;
  sqr: string;
};

type TAreaType = {
  buildSqr: string | null;
  buildingNm: string | null;
  buildingPk: string | null;
  code: string | null;
  dongNm: string | null;
  excSqr: string | null;
  gbnCodeNm: string | null;
  mngPk: string | null;
  sitePos: string | null;
  siteSqr: string | null;
  supSqr: string | null;
  useSqr: string | null;
};

interface Props {
  type?: string;
  title?: string;
}

/**
 * @description 공동중개 날짜 sheet
 * @Class
 * @category Components
 * @subcategory coagency sheet
 * @component
 * @returns {JSX.Element}
 */
const CoagencyBotSheet = (_props: Props) => {
  const dispatch = useAppDispatch();
  const { title } = _props;
  const { isCommentLoading } = useAppSelector((state: RootState) => state.loading);

  const detailGlobal = useAppSelector((state: RootState) => state.coagencydetail);
  const regState = useAppSelector((state: RootState) => state.coagencydetail.regState);

  const { multiModalType, modalType, data, multiData, isOpen, action } = useAppSelector(
    (state: RootState) => state.modal,
  );
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const [selectedMoveDate, setSelectedMoveDate] = useState<TClickDate | null>(null);
  const [monthlyTerm, setMonthlyTerm] = useState('');

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  useEffect(() => {
    if (multiData?.move_gbn) {
      setMonthlyTerm(multiData?.move_gbn);
    }
  }, [multiData]);

  /**
   * @description multi modal close
   * @returns {void}
   */
  const handleMultiModalClose = (): void => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeMultiModal({ multiModalType: multiModalType, isOpen: false }));
    }, 200);
  };

  console.group();
  // console.log('data===>', data);
  // console.log('multiModalType==>', multiModalType);
  // console.log('multiData==>', multiData);
  // console.log('selectedMoveDate==>', selectedMoveDate);
  // console.log('monthlyTerm in CoagencyBotDatePicker==>', monthlyTerm);
  console.groupEnd();

  return (
    <>
      <div className={cn(Styles.show, !open && ResetStyles['d-none'])}>
        <div
          id="render-modal"
          className={cn(
            Styles['popup-container'],
            Styles['bottom-sheet-popup'],
            CommonStyles['bottom-sheet-popup'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
        >
          <div className={Styles['popup-header']}>
            <p className={Styles['pop-tit']}>{title}</p>
            <button className={Styles.close} onClick={handleMultiModalClose} />
          </div>
          <div className={cn(Styles['popup-content'], CommonStyles['popup-content'], CommonStyles['share-write'])}>
            {multiModalType === 'coagency_bottom_movein_calendar_twoFloor' && (
              <>
                {selectedMoveDate?.fullDate ? (
                  <p className={Styles.tit}>
                    {['B', 'C', 'D'].includes(monthlyTerm)
                      ? `${moment(selectedMoveDate?.fullDate).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                          (item) => item.cd === monthlyTerm,
                        )?.name}`
                      : moment(selectedMoveDate?.fullDate).format('YYYY-MM-DD')}
                  </p>
                ) : (
                  <p className={Styles.txt} style={{ textAlign: 'left' }}>
                    아래 캘린더에서 날짜를 선택해주세요.
                  </p>
                )}
                <MoveInDatePicker
                  clType="modify"
                  selectedMoveDate={selectedMoveDate}
                  setSelectedMoveDate={setSelectedMoveDate}
                  monthlyTerm={monthlyTerm}
                  setMonthlyTerm={setMonthlyTerm}
                  handleModalClose={handleMultiModalClose}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoagencyBotSheet;
