import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import _ from 'lodash';
import qs from 'qs';
import { moveMain, onClose } from 'utils';
import { isCheckWebview } from 'utils/common';
// redux
import { useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import { batch, useDispatch } from 'react-redux';
import * as ActionModal from 'redux/slices/modal';
import * as ActionDetail from 'redux/slices/coagencydetail';

import CommonSummary from './CommonSummary';
import Toast from 'components/common/Toast/Toast';
import useToast from 'hook/useToast';

// configs
import * as Config from 'configs/configs';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
// type and init data
import { IMixedKeyValue } from '<modal>';
// utils
import { squareToPyeong, rmComma } from 'utils/common';
import { formatNumber } from 'utils';
import network from 'utils/network';
import { convertAreaFullCode, hasLi } from 'helpers/offering-helper';

/**
 * @description 공유매물 수정
 * @Class
 * @category Pages
 * @subcategory 공유매물
 * @component
 * @returns {JSX.Element}
 */
const CoagencyEdit = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { offerId } = params;

  const historyPath = useAppSelector((state: RootState) => state.user.historyPath);
  const toastState = useAppSelector((state: RootState) => state.toast);
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const detailGlobal = useAppSelector((state: RootState) => state.coagencydetail);
  const regState = useAppSelector((state: RootState) => state.coagencydetail.regState);

  const { setToastMessage } = useToast();
  const [isREType, setIsREType] = useState('');
  const [isRESubType, setIsRESubType] = useState('');
  const [isTermsAgree, setIsTermsAgree] = useState(false);
  const [complexList, setComplexList] = useState([]);

  // 고객관계리스트
  const [relationList, setRelationList] = useState([]);

  // 면적
  const lotAreaReDevRef = useRef<HTMLInputElement>(null);
  const supAreaRef = useRef<HTMLInputElement>(null);
  const conAreaRef = useRef<HTMLInputElement>(null);
  const excAreaRef = useRef<HTMLInputElement>(null);
  const lotAreaRef = useRef<HTMLInputElement>(null);
  const totAreaRef = useRef<HTMLInputElement>(null);
  const bulAreaRef = useRef<HTMLInputElement>(null);

  // 필수
  const complexRef = useRef<HTMLInputElement>(null);
  const dongRef = useRef<HTMLButtonElement>(null);
  const hoRef = useRef<HTMLInputElement>(null);
  const priceRef = useRef<HTMLInputElement>(null);

  const streetStartRef = useRef<HTMLInputElement>(null);
  const detailAddrRef = useRef<HTMLInputElement>(null);

  const isTermsAgreeRef = useRef<HTMLInputElement>(null);
  // btn disabled
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  /**
   * @description 상세정보 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (offerId) {
        await getPostDetail();
      }
    };
    getData();
    return () => abortController.abort();
  }, [offerId]);

  /**
   * @description phoneNum - 생성 side effect
   */
  useEffect(() => {
    const phoneNum = regState.phoneNum;
    if (phoneNum?.length === 11) {
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          phoneNum: phoneNum.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
        }),
      );
    }
    if (phoneNum?.length === 13) {
      const _newPhoneNum = phoneNum.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          phoneNum: _newPhoneNum,
        }),
      );
    }
  }, [regState.phoneNum]);

  /******************************단지 및 동저보 가져오기**************************** */
  /**
   * @description 단지 리스트 가져오기 side effect
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (regState?.sidoAddr?.cd && regState?.guGunAddr?.cd && regState?.eupMyeonDongAddr?.cd) {
        const complexParams = complexParamFunc(regState?.eupMyeonDongAddr.cd);
        await getComplexList(complexParams);
      }
      if (regState?.sidoAddr?.cd && regState?.guGunAddr?.cd && regState?.eupMyeonDongAddr?.cd && regState?.liAddr?.cd) {
        const complexParams = complexParamFunc(regState?.liAddr.cd);
        await getComplexList(complexParams);
      }
      await getRelationList();
    };
    getData();
    return () => abortController.abort();
  }, [regState]);

  /**
   * @description 가져온 단지리스트 중 헤당되는 단지 및 동정보 가져오기
   */
  useEffect(() => {
    if (complexList?.length && regState?.complexAddr?.complex_cd && regState?.dongAddr?.dong_cd) {
      const _c: IMixedKeyValue = complexList.find(
        (item: IMixedKeyValue) => item.complex_cd === regState?.complexAddr?.complex_cd,
      )!;
      console.log('_c==>', _c);
      const _d = _c ? _c?.dong.find((item: IMixedKeyValue) => item.dong_cd === regState?.dongAddr?.dong_cd) : {};
      batch(() => {
        dispatch(ActionDetail.setComplex({ complexAddr: _c }));
        dispatch(ActionDetail.setDong({ dongAddr: _d }));
      });
    }
  }, [regState, complexList]);

  /**
   * @description 내 공유매물 수정시 B.S에서 거래유형수정이후 가격입력부분 연속동작하도록 수정
   */
  useEffect(() => {
    if (regState?.isPriceChange) {
      setTimeout(() => {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_bottom_price_input',
            data: regState,
            isOpen: true,
          }),
        );
      }, 500);
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          isPriceChange: false,
        }),
      );
    }
  }, [regState]);
  /**
   * @description 단지정보 호출시 params
   * @param {any} p
   * @returns {object} params
   */
  const complexParamFunc = useCallback(
    (p: string): object => {
      const params = {
        area_cd: p,
        complex_gbn:
          regState?.offerings_gbn === 'AB'
            ? 'AP'
            : regState?.offerings_gbn === 'JB'
            ? 'JS'
            : regState?.offerings_gbn === 'OB'
            ? 'OP'
            : regState?.offerings_gbn,
        is_rebuild: regState?.offerings_gbn === 'RC' ? 'Y' : '',
        is_parcel: regState?.offerings_main_gbn === 'B' ? 'Y' : '',
      };
      return params;
    },
    [regState],
  );

  /**
   * @description 공동주택 단지정보 api call
   * @returns {Promise<void>}
   */
  const getComplexList = async (params: IMixedKeyValue): Promise<void> => {
    const joinPath = `${Config.API_URL?.GET_OFFERS_COMPLEXES}?${qs.stringify(params)}`;
    try {
      const resComplex = await network().coagency().getCommonList(joinPath);
      const { data, status } = resComplex;
      if (status === 200) {
        setComplexList(data?.data);
      }
    } catch (err) {
      console.error('err', err);
      setComplexList([]);
    }
  };
  /******************************단지 및 동저보 가져오기 end**************************** */

  /**
   * @description 소유자 관계목록
   * @returns {Promise<void>}
   */
  const getRelationList = async (): Promise<void> => {
    try {
      const resAddr = await network().coagency().getCommonList(Config.API_URL.GET_OWNER_TYPE);
      const { data, status } = resAddr;
      if (status === 200) {
        let _data = data.map((item: IMixedKeyValue) => ({
          name: item.name,
          code: item.code,
        }));
        _data = [{ code: '', name: '선택' }, ..._data];
        setRelationList(_data);
      }
    } catch (err) {
      console.error('err', err);
      if (err) {
        setRelationList([]);
      }
    }
  };

  /**
   * @description 상세정보 가져오기
   * @returns {Promise<void>}
   */
  const getPostDetail = async (): Promise<void> => {
    try {
      const res = await network().coagency().getCommonList(`/offers/${offerId}`);
      const { data, status } = res;
      if (status === 200) {
        console.log('getPostDetail data===>', data);
        const _complexAddr = {
          complex_cd: data?.complex_cd,
          complex_nm: data?.complex_nm,
        };

        const _isREType = Config.REType.map((k) => k.offer_types)
          .flat()
          .find((j) => j.sub_cd === data.offerings_gbn)!.main_cd;

        const _square = data.area_info.map((item: IMixedKeyValue) =>
          _.assign(item, {
            name:
              item.name === '공'
                ? 'sup_sqr'
                : item.name === '계'
                ? 'con_sqr'
                : item.name === '전'
                ? 'exc_sqr'
                : item.name === '대' || item.name === '대지'
                ? 'lot_sqr'
                : item.name === '연'
                ? 'tot_sqr'
                : item.name === '건' || item.name === '건축'
                ? 'bul_sqr'
                : '',
          }),
        );
        const _squareSelect = _.chain(_square).keyBy('name').mapValues('value').value();
        console.log('_squareSelect ==>', _squareSelect);

        const _newSquareSelect = [_squareSelect].map((item: IMixedKeyValue) => {
          const disSqr =
            data.offerings_gbn === 'OP' || data.offerings_gbn === 'OB'
              ? `${Math.floor(item.con_sqr)}(계약: ${Number(item.con_sqr).toFixed(2)}/ 전용: ${Number(
                  item.exc_sqr,
                ).toFixed(2)} )`
              : `${Math.floor(item.sup_sqr)}( 공급: ${Number(item.sup_sqr).toFixed(2)}/ 전용: ${Number(
                  item.exc_sqr,
                ).toFixed(2)} )`;
          return _.assign(item, { sqr_cd: data.sqr_cd, dis_sqr: disSqr });
        });

        console.log('_newSquareSelect', _newSquareSelect);
        /** 면적정보 compose end */
        /** 층정보 compose start */
        const _floor = data?.floor_info?.map((item: IMixedKeyValue) =>
          _.assign(item, {
            name:
              item.name === '해당층'
                ? 'applyFloor'
                : item.name === '지상층'
                ? 'groundFloor'
                : item.name === '지하층'
                ? 'underFloor'
                : item.name === '총층'
                ? 'totFloor'
                : '',
          }),
        );
        const _floorInfo = _.chain(_floor).keyBy('name').mapValues('value').value();
        console.log('_floorInfo ==>', _floorInfo);
        /** 층 정보 end */
        /** 공유정보 start */
        const _shares = data.shares.map((k: IMixedKeyValue) => {
          return _.assign(k, {
            id: k.share_type === 'P' ? 99999 : k.id,
            group_id: k.share_type === 'P' ? 99999 : k.group_id,
            name: k.share_type === 'P' ? '전체 공유 (공동중개 게시판)' : k.name,
            code: k.share_type,
          });
        });
        const _regState = {
          id: data?.id,
          areaCd: data?.area_cd,
          houseNo: data?.house_no,
          // 모바일 추가
          offerings_main_gbn: _isREType,
          offerings_gbn: data?.offerings_gbn,
          addr: data?.addr,
          // 모바일 추가
          sidoAddr: {
            code:
              data?.addr_si_code === '36' // 세종특별자치시
                ? convertAreaFullCode(`${data?.addr_si_code}11`)
                : convertAreaFullCode(data?.addr_si_code) || '',
            cd: data?.addr_si_code || '',
            name: data?.addr_si || '',
          },
          guGunAddr: {
            code: data?.addr_si_code && data?.addr_gu_code ? convertAreaFullCode(data?.addr_gu_code) : '',
            cd: data?.addr_gu_code || '',
            name: data?.addr_gu || '',
          },
          eupMyeonDongAddr: {
            code:
              data?.addr_si_code && data?.addr_gu_code && data?.addr_dong_code
                ? convertAreaFullCode(data?.addr_dong_code)
                : '',
            cd: data?.addr_dong_code || '',
            name: data?.addr_dong || '',
          },
          liAddr: {
            code:
              data?.addr_si_code && data?.addr_gu_code && data?.addr_dong_code && hasLi(data?.area_cd)
                ? `${data?.addr_li_code}`
                : '',
            cd: data?.addr_li_code || '',
            name: data?.addr_li || '',
          },
          redevelopAddr: {
            complex_cd: data?.complex_cd || '',
            complex_nm: data?.complex_nm || '',
          },
          complexAddr: {
            complex_cd: data?.complex_cd || '',
            complex_nm: data?.complex_nm || '',
          },
          dongAddr: {
            dong_name: data?.dong_name || '',
            dong_cd: data?.dong_cd || '',
          },
          hoAddr: data?.ho_name ? data?.ho_name : data?.ho_nm,
          dong_hide: data?.dong_hide || 'N', // 추가
          addrGbn: data?.addr_gbn,
          forSaleRight: data?.parcel_gbn || 'A', // 분양권 (A: 일반분양권 , B: 조합원분양권)
          streetStart: data?.house_no ? data?.house_no.split('-')[0] : '',
          streetEnd: data?.house_no ? data?.house_no.split('-')[1] : '',
          detailUse: data?.detail_addr ? 'Y' : 'N', // 상세주소 사용 'Y',  없음 'N'
          detailAddr: data?.detail_addr,
          offerGbn: data?.offer_gbn,
          price_info: data?.price_info, // 추가
          sellPrc: data?.offer_gbn === 'S' ? data?.price_info[0]?.value.toLocaleString() : null, // 매매가
          leasePrc: data?.offer_gbn === 'L' ? data?.price_info[0]?.value.toLocaleString() : null, // 전세가
          depositPrc:
            data?.offer_gbn === 'M' || data?.offer_gbn === 'T' ? data?.price_info[0]?.value.toLocaleString() : null, // 보증금
          monthlyPrc:
            data?.offer_gbn === 'M' || data?.offer_gbn === 'T' ? data?.price_info[1]?.value.toLocaleString() : null, // 월세
          salePrc:
            ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
              data?.offer_gbn === 'S' &&
              data?.price_info[0]?.value.toLocaleString()) ||
            null, // 분양권 - 분양가
          premiumPrc:
            ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
              data?.offer_gbn === 'S' &&
              data?.price_info[1]?.value.toLocaleString()) ||
            null, // 분양권 - 프리미엄가
          optionPrc:
            ((data?.offerings_gbn === 'AB' || data?.offerings_gbn === 'JB' || data?.offerings_gbn === 'OB') &&
              data?.offer_gbn === 'S' &&
              data?.price_info[2]?.value.toLocaleString()) ||
            null, // 분양권 - 분양옵션가
          squareSelect: _newSquareSelect[0], // 면적선택 (공동주택의 경우)
          supArea: _newSquareSelect[0]?.sup_sqr || null, // 공급면적
          conArea: _newSquareSelect[0]?.con_sqr || null, // 계약면적
          excArea: _newSquareSelect[0]?.exc_sqr || null, // 전용면적
          lotArea: _newSquareSelect[0]?.lot_sqr || null, // 대지면적
          totArea: _newSquareSelect[0]?.tot_sqr || null, // 연면적
          bulArea: _newSquareSelect[0]?.bul_sqr || null, // 건축면적
          applyFloor: _floorInfo.applyFloor || null, // 해당층
          underFloor: _floorInfo.underFloor || null, // 지하층
          groundFloor: _floorInfo.groundFloor || null, // 지상층
          totFloor: _floorInfo.totFloor || null, // 총층
          roomCnt: data?.room_cnt || null,
          bathCnt: data?.bath_cnt || null,
          moveGbn: data?.move_gbn, // 입주가능일 타입 - A:즉시입주,  B:초순,  C:중순,  D:하순, S: 날짜 지정
          isMoveChg: data?.is_move_chg || 'N', // 입주가능일 협의여부
          moveYmd: moment(data?.move_ymd).format('YYYYMMDD') || new Date(),
          memo: data?.memo, // 매물특징
          detailMemo: data?.detail_memo, // 매물설명
          statusDeal: data?.status_deal, // 상태, normal : 정상, hold : 보류, complete : 거래완료
          customerName: data?.customer_name, // 비공개정보 고객명
          ownerType: {
            name: data?.owner_type_name || '',
            code: data?.owner_type || '',
          }, // 비공개정보 관계  01 ~ 99
          phoneNum: data?.phone_num,
          hiddenMemo: data?.hidden_memo,
          status_share: data?.status_share, // 공유여부, Y: 공유, N: 공유안함  // 공유함 default
          shares: _shares, // 모임 id, type이 'G' 경우 설정
          telDisplay: data?.tel_display, // 연락처 노출, A : 모두, R : 전화번호, C : 휴대폰번호
          statusShareMap: data?.status_share_map, // 지도노출여부, Y: 지도노출, N: 지도노출안함
          show_days: 30, // data?.show_days || 30, // {/* TODO 노출기간 수정 - 7. mo : 공유매물 > 상세 > 수정    */}
          isPriceChange: false,
        };
        batch(() => {
          dispatch(ActionDetail.setComplex({ complexAddr: _complexAddr }));
          dispatch(ActionDetail.setRegState(_regState));
        });
        setIsREType(_isREType);
        setIsRESubType(data.offerings_gbn);
      }
      console.log('res==>', res);
    } catch (err) {
      console.error('err', err);
    }
  };

  /**
   * @description 매물종류와 소재지 수정불가 alert
   * @returns {void}
   */
  const handleNoEdit = (): void => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_common_alert',
        data: { txt: '매물종류와 소재지는 수정할 수 없습니다.' },
        isOpen: true,
      }),
    );
  };

  /**
   * @description 방수 욕실수 숫자입력
   * @param {string} value
   * @param {string} type
   * @param {string} zero 0값 허용(allow) / 불허(deny)
   * @param {string} negative 음수값 허용(allow) / 불허(deny)
   * @returns {void}
   */
  const handleNumberChange = (
    value: string,
    type: string,
    zero?: 'allow' | 'deny',
    negative?: 'allow' | 'deny',
  ): void => {
    let _value = value;
    const _zero = zero ?? 'deny';
    const _negative = negative ?? 'deny';

    _value = rmComma(value);
    if (_value === '0' && _zero === 'deny') {
      value = '';
    } else if (_value === '-' && _negative === 'deny') {
      value = '';
    } else if (_value !== '' && _value !== '-') {
      value = Number(_value).toLocaleString('ko-KR');
    }

    dispatch(
      ActionDetail.setRegState({
        ...regState,
        [type]: value,
      }),
    );
  };

  /**
   * @description 층수 입력시 숫자(1이상) or null 입력되도록 처리
   * @param {string} value
   * @param {string} type
   */
  const handleFloorEditChange = (value: string, type: string, max?: number): void => {
    if (max && Number(value) > max) {
      return;
    }
    const _floor =
      type === 'applyFloor' && Number(value) > -99 ? Number(value) : Number(value) > 0 ? Number(value) : null;
    dispatch(
      ActionDetail.setRegState({
        ...regState,
        [type]: Number(_floor) || null,
      }),
    );
  };

  /**
   * @description 메모 및 input 입력 handler
   * @param {object} e React.syntheticEvent
   * @returns {void}
   */
  const handleMemo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { name } = e.target;
    let { value } = e.target;
    if (name === 'customerName' && value.length > 10) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '10자 이내로 작성해주세요.' },
          isOpen: true,
        }),
      );
      return;
    }

    if (name === 'memo' && value.length > 40) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '40자 이내로 작성해주세요.' },
          isOpen: true,
        }),
      );
      return;
    }
    if (name !== 'customerName' && name !== 'memo' && value.length > 1000) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '1000자 이내로 작성해주세요.' },
          isOpen: true,
        }),
      );
      return;
    }
    if (name !== 'customerName' && name !== 'memo') {
      value = value.replaceAll('<br>', '\r\n');
    }

    if (name === 'customerName' && !value) {
      setIsTermsAgree(false);
    }
    dispatch(
      ActionDetail.setRegState({
        ...regState,
        [name]: value,
        ownerType: name === 'customerName' && !value ? {} : regState.ownerType,
      }),
    );
  };

  /**
   * @description 전화번호 숫자입력 정규표현식 validataion
   * @param {object} e React.SyntheticEvent
   * @returns {void}
   */
  const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const regex = /^[0-9\b -]{0,13}$/;
    const { value } = e.target;
    if (!value) {
      setIsTermsAgree(false);
    }
    if (regex.test(e.target.value)) {
      dispatch(
        ActionDetail.setRegState({
          ...regState,
          phoneNum: value,
        }),
      );
    }
  };

  /**
   * @description 취소시 confirm이후 이전화면으로 이동
   * @returns {void}
   */
  const handleReset = (): void => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_edit_cancel',
        action: () => moveToBefore(),
        isOpen: true,
      }),
    );
  };

  /**
   * @description 내 공유매물삭제 confirm
   * @returns {void}
   */
  const handleDeleteConfirm = (e: React.MouseEvent<HTMLButtonElement>): void => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_confirm_delete_when_edit',
        action: () => handleCoagencyDelete(e),
        isOpen: true,
      }),
    );
  };

  /**
   * @description 내 공유매물삭제 action
   * @returns {Promise<void>}
   */
  const handleCoagencyDelete = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    submitBtnRef.current ? (submitBtnRef.current.disabled = true) : null;
    try {
      const res = await network()
        .coagency()
        .deleteCoagency(
          isREType === 'A' || isREType === 'B' ? `/offers/apartment/${offerId}` : `/offers/nonapartment/${offerId}`,
        );
      const { data, status } = res;
      if (status === 200) {
        console.log('내 공유매물 삭제하기 완료 data', data);
        // if (import.meta.env.PROD) {
        moveMain(4, '0');
        onClose();
        // } else {
        //   navigate('/feed');
        // }
      }
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
    } catch (err: any) {
      console.error('err', err);
      if (err && err?.data.message) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: err?.data.message },
            isOpen: true,
          }),
        );
      }
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
    }
  };

  /**
   * @description 수정완료 이후 이전으로 이동
   * @returns {void}
   */
  const moveToBefore = (): void => {
    if (historyPath.from.includes('sharedetail')) {
      navigate(-1); // 상세에서 이동한 수정페이지 경우
    } else if (isCheckWebview()) {
      // moveMain(4, '0');
      onClose(); // 여기서는 onClose()만 수행!
    }
  };

  /**
   * @description 공동 비공동 매물 수정 api
   * @returns {Promise<void>}
   */
  const handleModifyComplete = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    submitBtnRef.current ? (submitBtnRef.current.disabled = true) : null;
    console.log('매물수정하기 start');
    if ((isREType === 'A' || isREType === 'B') && !regState?.complexAddr.complex_cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '단지를 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return complexRef.current?.focus();
    }

    if (((isREType === 'A' && isRESubType !== 'RB') || isREType === 'B') && !regState?.dongAddr?.dong_cd) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '동을 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return dongRef.current?.focus();
    }

    if (((isREType === 'A' && isRESubType !== 'RB') || isREType === 'B') && !regState?.hoAddr) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '호를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return hoRef.current?.focus();
    }

    // 상세주소 필수 ( 재개발(RB) 부분도 필수)
    if (
      ((isREType === 'A' && isRESubType === 'RB') ||
        isREType === 'C' ||
        isREType === 'D' ||
        isREType === 'E' ||
        isREType === 'F') &&
      (!regState?.streetStart || Number(regState?.streetStart) === 0)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '본번지를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return streetStartRef.current?.focus();
    }

    if (
      ((isREType === 'A' && isRESubType === 'RB') ||
        isREType === 'C' ||
        isREType === 'D' ||
        isREType === 'E' ||
        isREType === 'F') &&
      regState?.detailUse === 'Y' &&
      !regState?.detailAddr
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '상세주소를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return detailAddrRef.current?.focus();
    }

    if (isREType !== 'B' && regState?.offerGbn === 'S' && (!regState?.sellPrc || Number(regState?.sellPrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '매매가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (isREType !== 'B' && regState?.offerGbn === 'L' && (!regState?.leasePrc || Number(regState?.leasePrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '전세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (
      isREType !== 'B' &&
      (regState?.offerGbn === 'M' || regState?.offerGbn === 'T') &&
      (regState?.depositPrc === '' || regState?.depositPrc === null)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '보증금을 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (
      isREType !== 'B' &&
      (regState?.offerGbn === 'M' || regState?.offerGbn === 'T') &&
      (!regState?.monthlyPrc || Number(regState?.monthlyPrc) === 0)
    ) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '월세가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    // 분양시 체크
    if (isREType === 'B' && !regState?.forSaleRight) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '분양권 구분을 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (isREType === 'B' && regState?.offerGbn === 'S' && (!regState?.salePrc || Number(regState?.salePrc) === 0)) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '분양가를 입력해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (isREType === 'B') {
      if (regState?.offerGbn === 'S' && regState?.premiumPrc === '') {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '프리미엄가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
        return;
      }
      if (regState?.offerGbn === 'S' && regState?.optionPrc === '') {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '분양옵션가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
        return;
      }

      if (regState?.offerGbn === 'L' && !regState?.leasePrc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '전세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
        return;
      }

      if (
        (regState?.offerGbn === 'M' || regState?.offerGbn === 'T') &&
        (regState?.depositPrc === '' || regState?.depositPrc === null)
      ) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '보증금을 입력해 주세요.' },
            isOpen: true,
          }),
        );
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
        return;
      }

      if ((regState?.offerGbn === 'M' || regState?.offerGbn === 'T') && !regState?.monthlyPrc) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: '월세가를 입력해 주세요.' },
            isOpen: true,
          }),
        );
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
        return;
      }
    }

    if (!regState?.show_days) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '매물 노출 기간을 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    // 공유여부
    if (regState?.status_share === 'Y' && !regState?.shares.length) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '공유범위를 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    if (regState?.status_share === 'Y' && !regState?.telDisplay) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '연락처노출을 선택해 주세요.' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return;
    }

    // 고객정보입력시 관계선택부분 필수체크

    if ((regState.customerName! || regState.phoneNum!) && !isTermsAgree) {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_common_alert',
          data: { txt: '개인정보 수집에 동의해 주세요' },
          isOpen: true,
        }),
      );
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      return isTermsAgreeRef.current?.focus();
    }

    const params = {
      dong_cd: regState?.dongAddr.dong_cd, // 공동주택의 동 cd
      sqr_cd: regState?.squareSelect.sqr_cd, // 공동주택의 면적선택 후 sar_cd
      ho_nm: regState?.hoAddr, // 공동주택의 호 입력정보

      addr_gbn: regState?.addrGbn, // 주소 - 종류(일반:N, 산:S)
      addr: {
        street_1: regState?.streetStart || null, // 주소 - 시작 지번
        street_2: regState?.streetEnd || null, // 주소 - 종료 지번
        detail_use: regState?.detailUse, // 상세주소 사용여부, Y: 사용, N:사용안함
        detail: regState?.detailAddr || null, // 상세주소, 상세주소 사용여부가 'Y'일 경우 입력
      },
      offer_gbn: regState?.offerGbn, // 거래유형 (S: 매매, L: 전세, M: 월세, T: 단기임대
      sell_prc: regState?.sellPrc ? Number(String(regState?.sellPrc).replace(/,/g, '')) : null,
      lease_prc: regState?.leasePrc ? Number(String(regState?.leasePrc).replace(/,/g, '')) : null,
      deposit_prc: regState?.depositPrc ? Number(String(regState?.depositPrc).replace(/,/g, '')) : null,
      monthly_prc: regState?.monthlyPrc ? Number(String(regState?.monthlyPrc).replace(/,/g, '')) : null,

      // 분양권일때만 (AB,JB,OB) 매매일때만 START
      parcel_gbn: regState?.forSaleRight, // 분양구분 (A: 일반분양, B: 조합원분)
      parcel_prc: regState?.salePrc ? Number(String(regState?.salePrc).replace(/,/g, '')) : null, // 분양가
      premium_prc: regState?.premiumPrc ? Number(String(regState?.premiumPrc).replace(/,/g, '')) : null, // 분양 프리미엄가
      parcel_option_prc: regState?.optionPrc ? Number(String(regState?.optionPrc).replace(/,/g, '')) : null, // 분양 옵션가
      sup_sqr:
        regState.offerings_gbn === 'RB' && regState?.lotArea
          ? regState?.lotArea
          : regState.offerings_gbn === 'RB' && !regState?.lotArea
          ? null
          : regState.offerings_gbn === 'OP' || regState.offerings_gbn === 'OB'
          ? regState?.squareSelect.con_sqr
          : regState?.squareSelect.con_sqr
          ? regState?.squareSelect.sup_sqr
          : null,
      con_sqr:
        regState.offerings_gbn === 'OP' || regState.offerings_gbn === 'OB'
          ? null
          : regState?.squareSelect.con_sqr
          ? regState?.squareSelect.con_sqr
          : null,
      exc_sqr:
        regState.offerings_gbn === 'RB' ? null : regState?.squareSelect.exc_sqr ? regState?.squareSelect.exc_sqr : null,

      area: {
        SUP: regState?.supArea,
        CON: regState?.conArea,
        EXC: regState?.excArea,
        LOT: regState?.lotArea,
        TOT: regState?.totArea,
        BUL: regState?.bulArea,
      },
      floor: {
        apply: regState?.applyFloor,
        under: regState?.underFloor,
        ground: regState?.groundFloor,
        total: regState?.totFloor,
      },
      // 공동주택 재개발(RB) 아닐때와 비공동주택 에는 room_cnt, bath_cnt
      room_cnt: regState?.roomCnt, // 방수, 입력 안하는 매물유형이 있음 -면적, 층 입력정보 api(/offers/type/info) 참고
      bath_cnt: regState?.bathCnt, // 욕실수, 입력 안하는 매물유형이 있음 - 면적, 층 입력정보 api(/offers/type/info) 참고
      move_gbn: regState?.moveGbn, // 입주가능일 타입 - A:즉시입주,  B:초순,  C:중순,  D:하순, S : 날짜 지정
      is_move_chg: regState?.isMoveChg, // 입주가능일 협의 가능 여부
      move_ymd: moment(regState.moveYmd).format('YYYYMMDD'), // 입주가능일 직접입력 날짜
      memo: regState?.memo, // 매물특징
      detail_memo: regState?.detailMemo, // 매물설명
      status_deal: regState?.statusDeal, // 상태, normal : 정상, hold : 보류, complete : 거래완료
      customer_name: regState?.customerName, // 비공개정보 - 고객명
      owner_type: regState?.ownerType?.code, // 비공개정보 - 관계
      phone_num: regState?.phoneNum, // 비공개정보 - 연락처 ( validation 추가할것 !)
      hidden_memo: regState?.hiddenMemo, // 비공개정보 - 중개사 메모
      status_share: regState?.status_share, // 공유여부, Y: 공유, N: 공유안함
      shares: regState?.shares?.map((k: IMixedKeyValue) => {
        return {
          type: k.group_id === 99999 ? 'P' : 'G',
          group_id: k.group_id === 99999 ? null : k.group_id || k.id,
        };
      }),
      tel_display: regState?.telDisplay, // 연락처 노출, A : 모두, R : 전화번호, C : 휴대폰번호
      status_share_map: (isREType === 'A' && isRESubType !== 'RB') || isREType === 'B' ? 'N' : regState?.statusShareMap, // 지도노출여부, Y: 지도노출, N: 지도노출안함
      show_days: regState?.show_days,
    };
    let _params;
    if (isREType === 'A' && isRESubType === 'RB') {
      _params = _.omit(params, [
        'dong_cd',
        'sqr_cd',
        'ho_nm',
        'parcel_gbn',
        'parcel_prc',
        'premium_prc',
        'parcel_option_prc',
        'area',
      ]);
    } else if (isREType === 'A' && isRESubType !== 'RB') {
      _params = _.omit(params, [
        'addr_gbn',
        'addr',
        'parcel_gbn',
        'parcel_prc',
        'premium_prc',
        'parcel_option_prc',
        'area',
      ]);
    } else if (isREType === 'B') {
      _params = _.omit(params, ['addr_gbn', 'addr', 'area']);
    } else if (isREType !== 'A' && isREType !== 'B') {
      _params = _.omit(params, [
        'dong_cd',
        'sqr_cd',
        'ho_nm',
        'parcel_gbn',
        'parcel_prc',
        'premium_prc',
        'parcel_option_prc',
        'sup_sqr',
        'con_sqr',
        'exc_sqr',
      ]);
    }
    console.log('_params', _params);
    const body = JSON.stringify(_params);
    try {
      const res = await network()
        .coagency()
        .putSubmit(
          isREType === 'A' || isREType === 'B'
            ? `/offers/apartment/${regState?.id}`
            : `/offers/nonapartment/${regState?.id}`,
          body,
        );
      const { data, status } = res;
      if (status === 200) {
        console.log('data===>', data);
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_alert_success_edit',
            action: () => moveToBefore(),
            isOpen: true,
          }),
        );
        submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
      }
    } catch (err: any) {
      console.error('err', err);
      if (err && err?.data.message) {
        dispatch(
          ActionModal.openModal({
            modalType: 'coagency_common_alert',
            data: { txt: err?.data.message },
            isOpen: true,
          }),
        );
      }
      submitBtnRef.current ? (submitBtnRef.current.disabled = false) : null;
    }
  };

  console.group();
  console.log('params===>', params);
  console.log('complexList===>', complexList);
  console.log('regState in CoagencyEdit==>', regState);
  console.groupEnd();

  const handleInputClick = (event: any) => {
    const targetElement: HTMLElement = event.target;
    if (targetElement && !/Android/i.test(navigator.userAgent)) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 400);
    }
  };

  const isIphone = /iPhone/i.test(navigator.userAgent);

  return (
    <div className={cn(CommonStyles.content, CommonStyles['share-write'])}>
      <CommonSummary type={'detail-edit'} regState={regState} />
      <div
        className={CommonStyles['section-div']}
        style={
          import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency'
            ? { marginBottom: 100 }
            : { marginBottom: 50 }
        }
      >
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-txt']}>*보류,거래완료 변경시 [공유안함]으로 자동 설정됩니다.</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
              <button
                className={cn(CommonStyles.btn, regState.statusDeal !== 'normal' && CommonStyles.line)}
                onClick={(e) =>
                  dispatch(
                    ActionDetail.setRegState({
                      ...regState,
                      statusDeal: 'normal',
                      status_share: 'Y',
                      telDisplay: localStorage.getItem('telDisPlay') || 'A',
                    }),
                  )
                }
              >
                정상
              </button>
              <button
                className={cn(CommonStyles.btn, regState.statusDeal !== 'hold' && CommonStyles.line)}
                onClick={(e) =>
                  dispatch(
                    ActionDetail.setRegState({
                      ...regState,
                      statusDeal: 'hold',
                      status_share: 'N',
                      telDisplay: localStorage.getItem('telDisPlay') || 'A',
                    }),
                  )
                }
              >
                보류
              </button>
              <button
                className={cn(CommonStyles.btn, regState.statusDeal !== 'complete' && CommonStyles.line)}
                onClick={(e) =>
                  dispatch(
                    ActionDetail.setRegState({
                      ...regState,
                      statusDeal: 'complete',
                      status_share: 'N',
                      telDisplay: localStorage.getItem('telDisPlay') || 'A',
                    }),
                  )
                }
              >
                거래완료
              </button>
              <button
                className={cn(CommonStyles.btn, regState.statusDeal !== 'delete' && CommonStyles.line)}
                onClick={(e) => handleDeleteConfirm(e)}
              >
                삭제
              </button>
            </div>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물종류</p>
            <div className={CommonStyles['input-area']} onClick={handleNoEdit}>
              <input
                onClick={handleInputClick}
                type="text"
                value={
                  Config.REType?.find((item) => item.main_cd === isREType)?.offer_types.find(
                    (k) => k.sub_cd === isRESubType,
                  )?.sub_nm || ''
                }
                readOnly
              />
            </div>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>소재지</p>
            <div className={CommonStyles['input-area']} onClick={handleNoEdit}>
              <input onClick={handleInputClick} type="text" value={regState?.addr || ''} readOnly />
            </div>
          </div>

          {/** 단지정보 ==> 공동주택일 경우 */}
          {(isREType === 'A' || isREType === 'B') && (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>단지정보</p>
              <button
                className={CommonStyles['input-area']}
                onClick={() => {
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_common_alert',
                      data: { txt: '공동주택의 단지정보는 수정할 수 없습니다.' },
                      isOpen: true,
                    }),
                  );
                }}
                // onClick={() =>
                //   dispatch(
                //     ActionModal.openModal({
                //       modalType: 'coagency_bottom_complex_select',
                //       data: regState,
                //       isOpen: true,
                //     }),
                //   )
                // }
              >
                <input
                  onClick={handleInputClick}
                  ref={complexRef}
                  // className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                  type="text"
                  value={regState?.complexAddr?.complex_nm || ''}
                  readOnly
                />
              </button>
            </div>
          )}
        </section>

        <section>
          {/** 동/호 정보 */}
          {((isREType === 'A' && isRESubType !== 'RB') || isREType === 'B') && (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>동/호 정보</p>
              <div
                className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_bottom_dong_ho_select',
                      data: regState,
                      isOpen: true,
                    }),
                  )
                }
              >
                <button type="button" ref={dongRef} className={CommonStyles.dropdown}>
                  <span>{regState.dongAddr?.dong_name ? `${regState.dongAddr?.dong_name} 동` : '선택'}</span>
                  <i className={CommonStyles['icon-arrow-down-gr']}></i>
                </button>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    ref={hoRef}
                    type="text"
                    value={regState?.hoAddr || ''}
                    placeholder="(비공개)"
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    readOnly
                  />
                  <span className={CommonStyles.unit}>호</span>
                </button>
              </div>
              <div className={CommonStyles['check-box']}>
                <input
                  onClick={handleInputClick}
                  type="checkbox"
                  id="chk1"
                  name="chk1"
                  checked={regState?.dong_hide === 'Y' || false}
                  onChange={(e) =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        dong_hide: e.target.checked ? 'Y' : 'N',
                      }),
                    )
                  }
                />
                <label htmlFor="chk1" className={CommonStyles['label-info']}>
                  동 비노출
                </label>
              </div>
            </div>
          )}

          {(isREType === 'A' && isRESubType !== 'RB') || isREType === 'B' || (
            <div className={cn(CommonStyles['insert-item'], CommonStyles['insert-address'])}>
              <p className={CommonStyles['sub-title']}>상세주소</p>
              <div className={CommonStyles['btn-wrap']}>
                <button
                  className={cn(CommonStyles.btn, CommonStyles.lg, regState.addrGbn !== 'N' && CommonStyles.line)}
                  onClick={() =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        addrGbn: 'N',
                      }),
                    )
                  }
                >
                  일반
                </button>
                <button
                  className={cn(CommonStyles.btn, CommonStyles.lg, regState.addrGbn !== 'S' && CommonStyles.line)}
                  onClick={() =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        addrGbn: 'S',
                      }),
                    )
                  }
                >
                  산
                </button>
              </div>
              <div className={CommonStyles['input-div']}>
                <div className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    ref={streetStartRef}
                    type="tel"
                    name="streetStart"
                    value={regState?.streetStart || ''}
                    placeholder="(비공개)"
                    onChange={(e) =>
                      dispatch(
                        ActionDetail.setRegState({
                          ...regState,
                          streetStart: e.target.value,
                        }),
                      )
                    }
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={5}
                  />
                </div>
                <span>-</span>
                <div className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    type="text"
                    name="streetEnd"
                    value={regState?.streetEnd || ''}
                    placeholder="(비공개)"
                    onChange={(e) =>
                      dispatch(
                        ActionDetail.setRegState({
                          ...regState,
                          streetEnd: e.target.value,
                        }),
                      )
                    }
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={5}
                  />
                  <span className={CommonStyles.unit}>번지</span>
                </div>
              </div>
              <div className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  type="text"
                  value={regState?.detailAddr || ''}
                  onChange={(e) =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        detailAddr: e.target.value,
                      }),
                    )
                  }
                  placeholder="상세주소 (비공개)"
                  disabled={regState.detailUse === 'N'}
                />
              </div>
              <div className={CommonStyles['check-box']}>
                <input
                  type="checkbox"
                  id="detail_use_chk2"
                  checked={regState.detailUse === 'N'}
                  onChange={(e) =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        detailUse: e.target.checked ? 'N' : 'Y',
                        detailAddr: '',
                      }),
                    )
                  }
                />
                <label htmlFor="detail_use_chk2" className={CommonStyles['label-info']}>
                  상세주소 없음
                </label>
              </div>
            </div>
          )}

          {/** 거래유형 */}
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>거래유형</p>
            <div
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'coagency_bottom_sale_type_select',
                    data: regState,
                    isOpen: true,
                  }),
                )
              }
            >
              <button className={CommonStyles.btn}>
                {Config.COAGENCY_REG_OFFER_GBN.find((item: IMixedKeyValue) => item.value === regState.offerGbn)?.label}
              </button>
            </div>
          </div>
          {regState?.price_info?.map((item: IMixedKeyValue, i: number) => {
            let prcKey = '';
            if (item.name === '보증금') prcKey = 'depositPrc';
            if (item.name === '월세') prcKey = 'monthlyPrc';
            if (item.name === '매매가') prcKey = 'sellPrc';
            if (item.name === '전세가') prcKey = 'leasePrc';
            if (item.name === '분양가') prcKey = 'salePrc';
            if (item.name === '프리미엄') prcKey = 'premiumPrc';
            if (item.name === '분양옵션가') prcKey = 'optionPrc';
            const prcValue =
              regState?.[
                prcKey as 'depositPrc' | 'monthlyPrc' | 'sellPrc' | 'leasePrc' | 'salePrc' | 'premiumPrc' | 'optionPrc'
              ];
            return (
              <div className={CommonStyles['insert-item']} key={item.name}>
                <p className={CommonStyles['sub-title']}>{item.name}</p>
                <button className={CommonStyles['input-area']}>
                  <input
                    onClick={handleInputClick}
                    type="tel"
                    value={prcValue ?? ''}
                    className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    onChange={(e) => handleNumberChange(e.target.value, prcKey)}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    maxLength={10}
                  />
                  <span className={CommonStyles.unit}>만원</span>
                </button>
                <p className={cn(CommonStyles.w100p, CommonStyles['info-txt'])}>
                  [금액:
                  <b className={cn(CommonStyles['co-dorg'], ResetStyles['fw-b'])}>
                    {formatNumber(Number(String(prcValue).replace(/,/g, ''))) || '0'}
                  </b>
                  원]
                </p>
              </div>
            );
          })}

          {/** 입주가능일 */}
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>입주가능일</p>
            <button
              className={cn(CommonStyles.dropdown, CommonStyles.on)}
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'coagency_bottom_movein_select',
                    data: regState,
                    isOpen: true,
                  }),
                )
              }
            >
              {regState?.moveGbn === 'A' ? (
                <span>즉시입주</span>
              ) : regState?.moveGbn === 'S' ? (
                <span>{moment(regState?.moveYmd).format('YYYY-MM-DD')}</span>
              ) : ['B', 'C', 'D'].includes(regState?.moveGbn) ? (
                <span>{`${moment(regState?.moveYmd).format('YYYY-MM-DD')} ${Config.monthTerm.find(
                  (item) => item.cd === regState?.moveGbn,
                )?.name}`}</span>
              ) : (
                <span />
              )}
              {regState?.isMoveChg === 'Y' && <span>입주가능일협의</span>}
              <i className={CommonStyles['icon-arrow-down-gr']}></i>
            </button>
          </div>

          {/* TODO 노출기간 수정 - 7. mo : 공유매물 > 상세 > 수정 */}
          {/** 매물 노출 기간 */}
          {/* <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물 노출 기간을 선택해주세요.</p>
            <div className={cn(CommonStyles['button-list'], CommonStyles.p0)}>
              <button className={CommonStyles['radio-box']}>
                <input
                  type="radio"
                  id="show_days_15"
                  name="radio_show_days"
                  value={15}
                  checked={regState.show_days === 15}
                  onChange={(e) =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        show_days: Number(e.target.value),
                      }),
                    )
                  }
                />
                <label htmlFor="show_days_15" className={CommonStyles['label-info']}>
                  15일
                </label>
              </button>
              <button
                className={cn(CommonStyles['radio-box'], CommonStyles['has-option-calendar'])}
                aria-expanded="false"
              >
                <input
                  type="radio"
                  id="show_days_30"
                  name="radio_show_days"
                  value={30}
                  checked={regState.show_days === 30}
                  onChange={(e) =>
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        show_days: Number(e.target.value),
                      }),
                    )
                  }
                />
                <label htmlFor="show_days_30" className={CommonStyles['label-info']}>
                  30일
                </label>
              </button>
            </div>
          </div> */}

          {/** 연락처 노출 */}
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>연락처 노출 선택</p>
            <button
              className={CommonStyles['input-area']}
              onClick={() =>
                dispatch(
                  ActionModal.openModal({
                    modalType: 'coagency_bottom_tel_display',
                    data: regState,
                    isOpen: true,
                  }),
                )
              }
            >
              <input
                onClick={handleInputClick}
                className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                type="text"
                value={
                  regState?.telDisplay === 'A'
                    ? '모두 노출 (중개업소 전화번호+휴대폰번호)'
                    : regState?.telDisplay === 'R'
                    ? '중개업소 전화번호만 노출'
                    : '휴대폰번호만 노출'
                }
                readOnly
              />
            </button>
          </div>

          {/** 공유정보 */}
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>공유정보</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
              <button
                type="button"
                className={cn(CommonStyles.btn, regState.status_share !== 'N' && CommonStyles.line)}
                onClick={() =>
                  dispatch(
                    ActionDetail.setRegState({
                      ...regState,
                      status_share: 'N',
                    }),
                  )
                }
              >
                공유안함
              </button>
              <button
                type="button"
                className={cn(CommonStyles.btn, regState.status_share !== 'Y' && CommonStyles.line)}
                onClick={() => {
                  if (regState.statusDeal !== 'normal') {
                    dispatch(
                      ActionModal.openModal({
                        modalType: 'coagency_common_alert',
                        data: { txt: '보류,거래완료 변경시 [공유안함]으로 자동 설정됩니다.' },
                        isOpen: true,
                      }),
                    );
                    return;
                  }
                  batch(() => {
                    dispatch(
                      ActionDetail.setRegState({
                        ...regState,
                        status_share: 'Y',
                      }),
                    );
                    dispatch(
                      ActionModal.openModal({
                        modalType: 'coagency_bottom_share_input',
                        data: {
                          ...regState,
                          status_share: 'Y',
                        },
                        isOpen: true,
                      }),
                    );
                  });
                }}
              >
                공유함
              </button>
            </div>
            {regState.statusDeal === 'normal' && regState?.status_share === 'Y' && regState?.shares.length > 0 && (
              <div
                className={cn(CommonStyles['gray-box'], CommonStyles['dot-indent'])}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_bottom_share_input',
                      data: regState,
                      isOpen: true,
                    }),
                  )
                }
              >
                {regState?.shares.map((item: IMixedKeyValue, index: number) => {
                  return <p key={index}>{item.share_type === 'P' ? '전체 게시판(공동중개)' : item.name}</p>;
                })}
              </div>
            )}
          </div>
        </section>
        <section>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles.title}>매물 상세 정보 (선택입력)</p>
            <p className={CommonStyles['sub-txt']}>
              *상세 정보 미작성시에도 공유하기가 가능하며, 수정하기에서 추가 작성이 가능합니다.
            </p>
          </div>

          {(isREType === 'A' && isRESubType !== 'RB') || isREType === 'B' ? (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>면적을 선택해주세요.</p>
              <button
                className={CommonStyles.dropdown}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_select_area_select_when_edit',
                      data: detailGlobal?.selectedAddr?.complexAddr?.square, // 기존에 가져온 면적정보
                      isOpen: true,
                    }),
                  )
                }
              >
                <span>
                  {regState.squareSelect?.dis_sqr
                    ? regState.squareSelect?.dis_sqr
                    : regState.squareSelect?.disSqr
                    ? regState.squareSelect?.disSqr
                    : '선택'}
                </span>

                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
            </div>
          ) : (
            isREType === 'A' &&
            isRESubType === 'RB' && (
              <div className={CommonStyles['insert-item']}>
                <p className={CommonStyles['sub-title']}>면적을 입력해주세요.</p>
                <p className={CommonStyles['sub-title']}>대지면적</p>
                <div className={CommonStyles['input-div']}>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      ref={lotAreaReDevRef}
                      type="number"
                      id="lotArea"
                      name="lotArea"
                      value={regState?.lotArea ? Number(regState?.lotArea).toFixed(2) : ''}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) =>
                        dispatch(
                          ActionDetail.setRegState({
                            ...regState,
                            lotArea: Number(e.target.value),
                          }),
                        )
                      }
                      maxLength={6}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                    />
                    <span className={CommonStyles.unit}>㎡</span>
                  </div>
                  <span>=</span>
                  <div className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="number"
                      id="lotArPyeong_AP"
                      name="lotArPyeong_AP"
                      value={regState?.lotArea ? squareToPyeong(Number(regState?.lotArea), 'register') : ''}
                      readOnly
                    />
                    <span className={CommonStyles.unit}>평</span>
                  </div>
                </div>
              </div>
            )
          )}

          {/**비공동주택 C, D type은 면적확인하기 존재*/}
          {isREType === 'A' || isREType === 'B' || (
            <div className={CommonStyles['insert-item']}>
              {(isREType === 'C' || isREType === 'D') && (
                <p className={CommonStyles['sub-title']}>
                  면적을 입력해주세요.
                  <button
                    className={cn(CommonStyles.btn, CommonStyles.sm, CommonStyles['ml-auto'])}
                    onClick={() =>
                      dispatch(
                        ActionModal.openModal({
                          modalType: 'coagency_bottom_building_info',
                          data: {
                            areaCd: regState?.areaCd,
                            houseNo: regState?.houseNo,
                          },
                          isOpen: true,
                        }),
                      )
                    }
                  >
                    면적 확인하기
                  </button>
                </p>
              )}
              {((isRESubType === 'DD' || isRESubType === 'OR' || isRESubType === 'SJ') && regState?.offerGbn !== 'S') ||
                isRESubType === 'VL' ||
                isRESubType === 'SM' ||
                isRESubType === 'SP' ||
                isRESubType === 'JC' || (
                  <>
                    <p className={CommonStyles['sub-title']}>대지면적</p>
                    <div className={CommonStyles['input-div']}>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          ref={lotAreaRef}
                          type="number"
                          id="lotArea"
                          name="lotArea"
                          value={regState?.lotArea ? Number(regState?.lotArea).toFixed(2) : ''}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length > e.target.maxLength)
                              e.target.value = e.target.value.slice(0, e.target.maxLength);
                          }}
                          onChange={(e) =>
                            dispatch(
                              ActionDetail.setRegState({
                                ...regState,
                                lotArea: Number(e.target.value),
                              }),
                            )
                          }
                          maxLength={6}
                          className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                        />
                        <span className={CommonStyles.unit}>㎡</span>
                      </div>
                      <span>=</span>
                      <div className={CommonStyles['input-area']}>
                        <input
                          onClick={handleInputClick}
                          type="number"
                          id="lotArPyeong"
                          name="lotArPyeong"
                          value={regState?.lotArea ? squareToPyeong(Number(regState?.lotArea), 'register') : ''}
                          readOnly
                        />
                        <span className={CommonStyles.unit}>평</span>
                      </div>
                    </div>
                    {/** 토지/임야는 연면적 제외 */}
                    {isRESubType === 'TJ' || (
                      <>
                        <p className={CommonStyles['sub-title']}>연면적</p>
                        <div className={CommonStyles['input-div']}>
                          <div className={CommonStyles['input-area']}>
                            <input
                              onClick={handleInputClick}
                              ref={totAreaRef}
                              type="number"
                              id="totArea"
                              name="totArea"
                              value={regState?.totArea ? Number(regState?.totArea).toFixed(2) : ''}
                              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value.length > e.target.maxLength)
                                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                              }}
                              onChange={(e) =>
                                dispatch(
                                  ActionDetail.setRegState({
                                    ...regState,
                                    totArea: Number(e.target.value),
                                  }),
                                )
                              }
                              maxLength={6}
                            />
                            <span className={CommonStyles.unit}>㎡</span>
                          </div>
                          <span>=</span>
                          <div className={CommonStyles['input-area']}>
                            <input
                              onClick={handleInputClick}
                              type="number"
                              id="totArPyeong_nonPublic"
                              name="totArPyeong_nonPublic"
                              value={regState?.totArea ? squareToPyeong(Number(regState?.totArea), 'register') : ''}
                              readOnly
                            />
                            <span className={CommonStyles.unit}>평</span>
                          </div>
                        </div>
                      </>
                    )}
                    {/** 공장/창고 전거래유형, 단독다가구 매매시, 상가주택 매매시 , 토지/임야, F빌딩 전거래유형 건축면적제외 */}
                    {isRESubType === 'GJ' ||
                      isRESubType === 'TJ' ||
                      isREType === 'F' ||
                      ((isRESubType === 'DD' || isRESubType === 'SJ') && regState?.offerGbn === 'S') || (
                        <>
                          <p className={CommonStyles['sub-title']}>건축면적</p>
                          <div className={CommonStyles['input-div']}>
                            <div className={CommonStyles['input-area']}>
                              <input
                                onClick={handleInputClick}
                                ref={bulAreaRef}
                                type="number"
                                id="bulArea"
                                name="bulArea"
                                value={regState?.bulArea ? Number(regState?.bulArea).toFixed(2) : ''}
                                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  if (e.target.value.length > e.target.maxLength)
                                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                                }}
                                onChange={(e) =>
                                  dispatch(
                                    ActionDetail.setRegState({
                                      ...regState,
                                      bulArea: Number(e.target.value),
                                    }),
                                  )
                                }
                                maxLength={6}
                              />
                              <span className={CommonStyles.unit}>㎡</span>
                            </div>
                            <span>=</span>
                            <div className={CommonStyles['input-area']}>
                              <input
                                onClick={handleInputClick}
                                type="number"
                                id="bulArPyeong_nonPublic"
                                name="bulArPyeong_nonPublic"
                                value={regState?.bulArea ? squareToPyeong(Number(regState?.bulArea), 'register') : ''}
                                readOnly
                              />
                              <span className={CommonStyles.unit}>평</span>
                            </div>
                          </div>
                        </>
                      )}
                  </>
                )}
              {/** 계약면적 ==> 상가주택 전,월세 단기임대, 사무실, 상가점포  */}
              {((isRESubType === 'SJ' && regState?.offerGbn !== 'S') ||
                isRESubType === 'SM' ||
                isRESubType === 'SP') && (
                <>
                  <p className={CommonStyles['sub-title']}>계약면적</p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        ref={conAreaRef}
                        type="number"
                        id="conArea"
                        name="conArea"
                        value={regState?.conArea ? Number(regState?.conArea).toFixed(2) : ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onChange={(e) =>
                          dispatch(
                            ActionDetail.setRegState({
                              ...regState,
                              conArea: Number(e.target.value),
                            }),
                          )
                        }
                        maxLength={9}
                      />
                      <span className={CommonStyles.unit}>㎡</span>
                    </div>
                    <span>=</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="number"
                        id="conArea_pyeong"
                        name="conArea_pyeong"
                        value={regState?.conArea ? squareToPyeong(Number(regState?.conArea), 'register') : ''}
                        readOnly
                      />
                      <span className={CommonStyles.unit}>평</span>
                    </div>
                  </div>
                </>
              )}
              {/** 공급면적 ==> 단독/다가구, 빌라/연림 전.월세, 단기임대  원룸, 지식산업센터 */}
              {((isRESubType === 'DD' && regState?.offerGbn !== 'S') ||
                isRESubType === 'VL' ||
                isRESubType === 'OR' ||
                isRESubType === 'JC') && (
                <>
                  <p className={CommonStyles['sub-title']}>공급면적</p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        ref={supAreaRef}
                        type="number"
                        id="supArea"
                        name="supArea"
                        value={regState?.supArea ? Number(regState?.supArea).toFixed(2) : ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onChange={(e) =>
                          dispatch(
                            ActionDetail.setRegState({
                              ...regState,
                              supArea: Number(e.target.value),
                            }),
                          )
                        }
                        maxLength={6}
                      />
                      <span className={CommonStyles.unit}>㎡</span>
                    </div>
                    <span>=</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="number"
                        id="supArea_pyeong"
                        name="supArea_pyeong"
                        value={regState?.supArea ? squareToPyeong(Number(regState.supArea), 'register') : ''}
                        readOnly
                      />
                      <span className={CommonStyles.unit}>평</span>
                    </div>
                  </div>
                </>
              )}
              {/** 전용면적 ==> 한옥, 사무실, 상가점포, 지식산업센터 전거래유형,
               * 전원/농가, 빌라/연립, 단독/다가구, 상가주택, 원룸 ==> 전,월세, 단기임대  */}
              {(isRESubType === 'SM' ||
                isRESubType === 'SP' ||
                isRESubType === 'JC' ||
                isRESubType === 'VL' ||
                ((isRESubType === 'JW' ||
                  isRESubType === 'HO' ||
                  isRESubType === 'DD' ||
                  isRESubType === 'SJ' ||
                  isRESubType === 'OR') &&
                  regState?.offerGbn !== 'S')) && (
                <>
                  <p className={CommonStyles['sub-title']}>전용면적</p>
                  <div className={CommonStyles['input-div']}>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        ref={excAreaRef}
                        type="text"
                        id="excArea"
                        name="excArea"
                        value={regState?.excArea ? Number(regState?.excArea).toFixed(2) : ''}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.value.length > e.target.maxLength)
                            e.target.value = e.target.value.slice(0, e.target.maxLength);
                        }}
                        onChange={(e) =>
                          dispatch(
                            ActionDetail.setRegState({
                              ...regState,
                              excArea: Number(e.target.value),
                            }),
                          )
                        }
                        maxLength={6}
                      />
                      <span className={CommonStyles.unit}>㎡</span>
                    </div>
                    <span>=</span>
                    <div className={CommonStyles['input-area']}>
                      <input
                        onClick={handleInputClick}
                        type="number"
                        id="excArea_pyeong"
                        name="excArea_pyeong"
                        value={regState?.excArea ? squareToPyeong(Number(regState?.excArea), 'register') : ''}
                        readOnly
                      />
                      <span className={CommonStyles.unit}>평</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/** 층 정보수정 */}
          {isRESubType === 'TJ' || (
            <div className={CommonStyles['insert-item']}>
              <p className={CommonStyles['sub-title']}>층을 입력해주세요.</p>
              {isRESubType === 'RB' ||
              isRESubType === 'OR' ||
              isRESubType === 'SM' ||
              isRESubType === 'SP' ||
              isRESubType === 'JC' ||
              isRESubType === 'VL' ||
              (isRESubType === 'DD' && regState.offerGbn !== 'S') ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="applyFloor"
                      name="applyFloor"
                      value={regState?.applyFloor || ''}
                      placeholder="해당층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'applyFloor', regState?.totFloor || 0)}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="totFloor"
                      name="totFloor"
                      value={regState?.totFloor || ''}
                      placeholder="총층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'totFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : isRESubType === 'DD' && regState.offerGbn === 'S' ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="underFloor"
                      name="underFloor"
                      value={regState?.underFloor || ''}
                      placeholder="지하층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'underFloor', regState?.totFloor || 0)}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="totFloor"
                      name="totFloor"
                      value={regState?.totFloor || ''}
                      placeholder="총층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'totFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : isREType === 'F' ||
                isRESubType === 'HO' ||
                isRESubType === 'GJ' ||
                (isRESubType === 'SJ' && regState.offerGbn === 'S') ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  {/** 한옥, 공장/창고 전체,  상가주택 매매 */}
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="underFloor"
                      name="underFloor"
                      value={regState.underFloor || ''}
                      placeholder="지하층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'underFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="groundFloor"
                      name="groundFloor"
                      value={regState?.groundFloor || ''}
                      placeholder="지상층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'groundFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : isRESubType === 'SJ' && regState.offerGbn !== 'S' ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="applyFloor"
                      name="applyFloor"
                      value={regState.applyFloor || ''}
                      placeholder="해당층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'applyFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="groundFloor"
                      name="groundFloor"
                      value={regState?.groundFloor || ''}
                      placeholder="지상층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'groundFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : isRESubType === 'JW' ? (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      onClick={handleInputClick}
                      type="tel"
                      id="totFloor"
                      name="totFloor"
                      value={regState?.totFloor || ''}
                      placeholder="총층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) => handleFloorEditChange(e.target.value, 'totFloor')}
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                </div>
              ) : (
                <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
                  <button className={CommonStyles['input-area']}>
                    <input
                      type="tel"
                      id="applyFloor"
                      name="applyFloor"
                      value={regState?.applyFloor || ''}
                      placeholder="해당층"
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(0, e.target.maxLength);
                      }}
                      onChange={(e) =>
                        handleFloorEditChange(
                          e.target.value,
                          'applyFloor',
                          detailGlobal?.selectedAddr?.dongAddr?.max_floor || 0,
                        )
                      }
                      className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                      max={detailGlobal?.selectedAddr?.dongAddr?.max_floor}
                      inputMode="tel"
                      maxLength={3}
                    />
                  </button>
                  <button className={CommonStyles['input-area']}>
                    <span className={CommonStyles.unit}>
                      [{regState?.applyFloor || '0'}/{detailGlobal?.selectedAddr?.dongAddr?.max_floor || '0'}층]
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}

          {/** 방수/욕실수 수정  ==> 재개발, E유형, F유형 제외 */}
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>방수와 욕실수를 작성해주세요.</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
              <button className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                  type="tel"
                  id="roomCnt"
                  name="roomCnt"
                  value={regState?.roomCnt ?? ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'roomCnt')}
                  placeholder="방수"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={3}
                />
                <span className={CommonStyles.unit}>개</span>
              </button>
              <button className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                  type="tel"
                  id="bathCnt"
                  name="bathCnt"
                  value={regState?.bathCnt ?? ''}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(e) => handleNumberChange(e.target.value, 'bathCnt')}
                  placeholder="욕실수"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  maxLength={3}
                />
                <span className={CommonStyles.unit}>개</span>
              </button>
            </div>
          </div>

          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물특징을 작성해주세요.</p>
            <button className={cn(CommonStyles['textarea-div'], CommonStyles.type1)}>
              <textarea
                onClick={handleInputClick}
                id="memo"
                name="memo"
                value={regState?.memo || ''}
                onChange={(e) => handleMemo(e)}
                placeholder="공동중개 리스트에 노출되는 문구입니다. 40자 이내로 작성해주세요."
                maxLength={40}
              />
              <p className={CommonStyles.byte}>
                <b>{regState?.memo?.length ?? 0}</b>/40
              </p>
            </button>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>매물설명을 작성해주세요.</p>
            <button className={cn(CommonStyles['textarea-div'], CommonStyles.type2)}>
              <textarea
                onClick={handleInputClick}
                id="detailMemo"
                name="detailMemo"
                value={regState?.detailMemo || ''}
                onChange={(e) => handleMemo(e)}
                placeholder="상세 페이지에 노출되는 문구입니다. 1000자 이내로 작성해주세요."
                maxLength={1000}
              />
              <p className={CommonStyles.byte}>
                <b>{regState?.detailMemo?.length ?? 0}</b>/1000
              </p>
            </button>
          </div>
        </section>
        <section style={{ paddingBottom: 50 }}>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles.title}>고객 정보를 입력해주세요.</p>
            <p className={CommonStyles['sub-txt']}>
              *비공개 정보로 나만 보는 매물 관리 정보가 필요하신 경우 등록하세요. 타인에게 노출되지 않습니다.
            </p>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>고객 정보를 입력해주세요.</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.half)}>
              <button className={CommonStyles['input-area']}>
                <input
                  onClick={handleInputClick}
                  className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.line)}
                  type="text"
                  id="customerName"
                  name="customerName"
                  value={regState?.customerName || ''}
                  onChange={(e) => handleMemo(e)}
                  placeholder="고객명(비공개)"
                  maxLength={10}
                />
              </button>
              <button
                className={CommonStyles.dropdown}
                onClick={() =>
                  dispatch(
                    ActionModal.openModal({
                      modalType: 'coagency_select_customer_relation_when_edit',
                      data: relationList,
                      isOpen: true,
                    }),
                  )
                }
              >
                <span>{regState?.ownerType.name || '선택'}</span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
            </div>
          </div>
          <div className={CommonStyles['insert-item']}>
            <p className={CommonStyles['sub-title']}>고객 연락처</p>
            <div className={cn(ResetStyles['d-flex'], CommonStyles['d-flex'], CommonStyles.full)}>
              <input
                onClick={handleInputClick}
                type="tel"
                id="phoneNum"
                name="phoneNum"
                value={regState?.phoneNum || ''}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                onChange={(e) => {
                  handlePhoneNumber(e);
                }}
                placeholder="고객연락처(비공개)"
                style={{ width: '100%' }}
                inputMode="numeric"
                pattern="[0-9]*"
                maxLength={13}
              />
            </div>
          </div>
          <div className={CommonStyles['insert-item']} style={{ paddingBottom: isIphone ? '80px' : '0px' }}>
            <p className={CommonStyles['sub-title']}>중개사 메모</p>
            <button className={cn(CommonStyles['textarea-div'], CommonStyles.type2)}>
              <textarea
                onClick={handleInputClick}
                id="hiddenMemo"
                name="hiddenMemo"
                value={regState?.hiddenMemo ?? ''}
                onChange={(e) => handleMemo(e)}
                placeholder="중개사 메모(비공개)"
                maxLength={1000}
              />
              <p className={CommonStyles.byte}>
                <b>{regState?.hiddenMemo?.length ?? 0}</b>/1000
              </p>
            </button>
          </div>
          {(regState?.customerName || regState?.phoneNum) && (
            <div className={cn(CommonStyles['check-box'], CommonStyles['agree-chk'])} style={{ marginBottom: 50 }}>
              <input
                ref={isTermsAgreeRef}
                type="checkbox"
                id="agree"
                name="agree"
                checked={isTermsAgree}
                onChange={() =>
                  dispatch(
                    ActionModal.openModal({
                      isOpen: true,
                      data: {
                        isEdit: true,
                      },
                      modalType: 'coagency_bottom_privacy_info',
                      action: () => setIsTermsAgree(true),
                    }),
                  )
                }
              />
              <label htmlFor="agree" className={CommonStyles['label-info']}>
                (선택) 상기 개인정보의 수집, 이용에 대해 고객으로부터 동의를 받았습니다.
              </label>
            </div>
          )}
        </section>
      </div>
      <div
        className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}
        // style={import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency' ? { paddingBottom: 50 } : {}}
      >
        <button type="button" className={cn(CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)} onClick={handleReset}>
          취소
        </button>
        <button
          ref={submitBtnRef}
          type="button"
          className={cn(CommonStyles.btn, CommonStyles.lg)}
          onClick={(e) => regState.id && handleModifyComplete(e)}
        >
          수정완료
        </button>
      </div>
      {toastState.isOpen && <Toast />}
    </div>
  );
};

export default CoagencyEdit;
