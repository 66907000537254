import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import * as ActionCoagency from 'redux/slices/coagency';
import * as ActionDetail from 'redux/slices/coagencydetail';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import { IMixedKeyValue } from '<modal>';
import { batch } from 'react-redux';

interface Props {
  type?: string;
  title?: string;
  txt?: string;
  setIsAlertOpen?: (isAlertOpen: boolean) => void;
}

/**
 * @description 공동중개 지역 선택 sheet
 * @Class
 * @category Components
 * @subcategory coagency sheet
 * @component
 * @returns {JSX.Element}
 */
const CoagencyAddrSelect = (props: Props) => {
  const { type, title, txt, setIsAlertOpen } = props;

  const dispatch = useAppDispatch();
  const { multiModalType, modalType, data, multiData, isOpen, action } = useAppSelector(
    (state: RootState) => state.modal,
  );
  const shareRegStep = useAppSelector((state: RootState) => state.coagency.shareRegStep);
  const RESubType = useAppSelector((state: RootState) => state.coagency.shareRegStep.coagencyRegData.offerings_gbn);
  const detailGlobal: IMixedKeyValue = useAppSelector((state: RootState) => state.coagencydetail);
  const regState = useAppSelector((state: RootState) => state.coagencydetail.regState);

  const [open, setOpen] = useState<boolean>(isOpen || false);

  // 면적리스트
  const [squareList, setSquareList] = useState<IMixedKeyValue>([]);
  // 관계리스트
  const [relationList, setRelationList] = useState<IMixedKeyValue>([]);

  // 빌딩주소리스트
  const [buildingInfoList, setBuildingInfoList] = useState<IMixedKeyValue>([]);
  // 해당빌딩의 층리스트
  const [floorList, setFloorList] = useState<IMixedKeyValue>([]);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  /**
   * @description 단지 면적정보 파싱 및 정렬
   */
  useEffect(() => {
    if (
      (modalType === 'coagency_select_area_select' || modalType === 'coagency_select_area_select_when_edit') &&
      data?.length
    ) {
      const _squareList = data?.slice(0);
      const sortedSquareList = _squareList?.map((item: IMixedKeyValue) => {
        const disSqr = !item.sqr_cd
          ? '선택'
          : RESubType === 'OP' || RESubType === 'OB'
          ? `${Math.floor(item.con_sqr)}${item.square_type ?? ''} ( 계약: ${Number(item.con_sqr).toFixed(
              2,
            )}/ 전용: ${Number(item.exc_sqr).toFixed(2)} )`
          : `${Math.floor(item.sup_sqr)}${item.square_type ?? ''} ( 공급: ${Number(item.sup_sqr).toFixed(
              2,
            )}/ 전용: ${Number(item.exc_sqr).toFixed(2)} )`;
        return {
          ...item,
          disSqr,
        };
      });

      sortedSquareList.sort((a: IMixedKeyValue, b: IMixedKeyValue) => Number(a.con_sqr) - Number(b.con_sqr));
      setSquareList(sortedSquareList);
    }
    if (
      (modalType === 'coagency_select_customer_relation' ||
        modalType === 'coagency_select_customer_relation_when_edit' ||
        modalType === 'coagency_select_customer_relation_fn') &&
      data?.length
    ) {
      setRelationList(data);
    }
  }, [modalType, data]);

  /**
   * @description 빌딩주소 가져오기
   */
  useEffect(() => {
    if (multiModalType === 'coagency_select_building_select' && multiData?.data?.length) {
      setBuildingInfoList(multiData?.data);
    }
    if (multiModalType === 'coagency_select_detail_addr_select' && multiData?.data?.length) {
      setFloorList(multiData?.data);
    }
  }, [multiModalType, multiData]);

  /**
   * @description modal close
   * @returns {void}
   */
  const handleModalClose = (): void => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
    }, 200);
  };

  /**
   * @description multi modal close
   * @returns {void}
   */
  const handleMultiModalClose = (): void => {
    setOpen!(false);
    setTimeout(() => {
      dispatch(ActionModal.closeMultiModal({ multiModalType: multiModalType, isOpen: false }));
    }, 200);
  };

  /**
   * @description 동 선택
   * @param {object} d
   * @returns {void}
   */
  const handleSelectDong = (d: IMixedKeyValue): void => {
    dispatch(
      ActionCoagency.setStep({
        shareRegStep: {
          ...shareRegStep,
          selectedAddr: {
            ...shareRegStep.selectedAddr,
            dongAddr: d,
          },
        },
      }),
    );
    handleModalClose();
  };

  /**
   * @description 동 선택 //==> 자동으로 방수와 욕실수를 가져옴
   * @param {object} s
   * @returns {void}
   */
  const handleSelectSquare = (s: IMixedKeyValue, type: string): void => {
    console.log('s===>', s);
    if (type === 'write') {
      dispatch(
        ActionCoagency.setStep({
          shareRegStep: {
            ...shareRegStep,
            selectedAddr: {
              ...shareRegStep.selectedAddr,
              square: s,
            },
            coagencyOptRegData: {
              ...shareRegStep.coagencyOptRegData,
              room_cnt: s.room_cnt,
              bath_cnt: s.bath_cnt,
            },
          },
        }),
      );
    }
    if (type === 'edit') {
      batch(() => {
        dispatch(
          ActionDetail.setSquare({
            square: s,
          }),
        );
        dispatch(
          ActionDetail.setRegState({
            ...regState,
            squareSelect: s, // 기존데이터 덮어씀
            roomCnt: s.room_cnt,
            bathCnt: s.bath_cnt,
          }),
        );
      });
    }
    handleModalClose();
  };

  /**
   * @description 관계 선택
   * @param {object} r
   * @returns {void}
   */
  const handleSelectRelation = (r: IMixedKeyValue, type: string): void => {
    if (modalType === 'coagency_select_customer_relation_fn') {
      action?.(r);
    } else {
      if (type === 'write') {
        dispatch(
          ActionCoagency.setStep({
            shareRegStep: {
              ...shareRegStep,
              coagencyOptRegData: {
                ...shareRegStep.coagencyOptRegData,
                owner_type: r,
              },
            },
          }),
        );
      }
      if (type === 'edit') {
        dispatch(
          ActionDetail.setRegState({
            ...regState,
            ownerType: r,
          }),
        );
      }
    }
    handleModalClose();
  };

  /**
   * @description 건축물대장 빌딩 선택
   * @param {object} b
   * @returns {void}
   */
  const handleBuildingSelection = (b: IMixedKeyValue): void => {
    dispatch(ActionCoagency.setTargetBuilding({ b }));
    handleMultiModalClose();
  };

  /**
   * @description 건축물대장 선택빌딩 주소의 선택 층
   * @param {object} f
   * @returns {void}
   */
  const handleFloorSelection = (f: IMixedKeyValue): void => {
    dispatch(ActionCoagency.setTargetFloor({ f }));
    handleMultiModalClose();
  };

  console.group();
  console.log('data==>', data);
  console.log('multiData===>', multiData);
  console.log('squareList==>', squareList);
  console.groupEnd();

  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show, !open && ResetStyles['d-none'])}>
        <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
          <div
            className={cn(
              Styles['popup-content'],
              Styles['button-list'],
              CommonStyles['popup-content'],
              CommonStyles['button-list'],
            )}
          >
            {multiModalType === 'coagency_select_detail_addr_select' && (
              <>
                {floorList?.length > 0 ? (
                  floorList?.map((item: IMixedKeyValue, index: number) => {
                    return (
                      <button
                        key={index}
                        type="button"
                        onClick={() => handleFloorSelection(item)}
                      >{`${item.floorNm}`}</button>
                    );
                  })
                ) : (
                  <button type="button">해당건물의 층 정보가 없습니다.</button>
                )}
              </>
            )}

            {multiModalType === 'coagency_select_building_select' && (
              <>
                {buildingInfoList?.length > 0 ? (
                  buildingInfoList?.map((item: IMixedKeyValue) => {
                    return (
                      <button
                        key={item.mngPk}
                        type="button"
                        onClick={() => handleBuildingSelection(item)}
                      >{`[${item.gbnCodeNm}] ${item.sitePos}`}</button>
                    );
                  })
                ) : (
                  <button type="button">해당건물의 주소가 없습니다.</button>
                )}
              </>
            )}
            {/** 매물등록시 관계정보 선택*/}
            {(modalType === 'coagency_select_customer_relation' ||
              modalType === 'coagency_select_customer_relation_fn') && (
              <>
                {relationList?.map((item: IMixedKeyValue) => {
                  return (
                    <button key={item.code} type="button" onClick={() => handleSelectRelation(item, 'write')}>
                      {item.name}
                    </button>
                  );
                })}
              </>
            )}

            {/** 매물수정시 관계정보 선택*/}
            {modalType === 'coagency_select_customer_relation_when_edit' && (
              <>
                {relationList?.map((item: IMixedKeyValue) => {
                  return (
                    <button key={item.code} type="button" onClick={() => handleSelectRelation(item, 'edit')}>
                      {item.name}
                    </button>
                  );
                })}
              </>
            )}
            {/** 매물등록시 면적정보 선택*/}
            {modalType === 'coagency_select_area_select' && (
              <>
                {squareList?.map((item: IMixedKeyValue) => {
                  return (
                    <button key={item.sqr_cd} type="button" onClick={() => handleSelectSquare(item, 'write')}>
                      {item.disSqr}
                    </button>
                  );
                })}
              </>
            )}
            {/** 내 공유매물수정시 면적정보 선택*/}
            {modalType === 'coagency_select_area_select_when_edit' && (
              <>
                {squareList?.map((item: IMixedKeyValue) => {
                  return (
                    <button key={item.sqr_cd} type="button" onClick={() => handleSelectSquare(item, 'edit')}>
                      {item.disSqr}
                    </button>
                  );
                })}
              </>
            )}
            {modalType === 'coagency_select_dong_select' && (
              <>
                {data?.length > 0
                  ? data?.map((item: IMixedKeyValue) => (
                      <button key={item.dong_cd} type="button" onClick={() => handleSelectDong(item)}>
                        {`${item.dong_name} 동`}
                      </button>
                    ))
                  : !data?.length && (
                      <>
                        <div className={Styles['popup-content']}>해당하는 행정구역이 없습니다.</div>
                        <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
                          <button
                            className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                            style={{ justifyContent: 'center' }}
                            onClick={handleModalClose}
                          >
                            확인
                          </button>
                        </div>
                      </>
                    )}
              </>
            )}

            {multiModalType === 'coagency_select_dong_select' && (
              <>
                {multiData?.length > 0
                  ? multiData?.map((item: IMixedKeyValue) => (
                      <button
                        key={item.dong_cd}
                        type="button"
                        onClick={() => {
                          dispatch(
                            ActionDetail.setDong({
                              dongAddr: item,
                            }),
                          );
                        }}
                      >
                        {`${item.dong_name} 동`}
                      </button>
                    ))
                  : !multiData?.length && (
                      <>
                        <div className={Styles['popup-content']}>해당하는 행정구역이 없습니다.</div>
                        <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
                          <button
                            className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)}
                            style={{ justifyContent: 'center' }}
                            onClick={handleModalClose}
                          >
                            확인
                          </button>
                        </div>
                      </>
                    )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoagencyAddrSelect;
