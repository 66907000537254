import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Textarea from 'components/common/Textarea/Textarea';
import Button from 'components/common/Button/Button';
import CheckBox from 'components/common/Button/Checkbox';

/**
 * @description etc confirm
 * @Class
 * @category Components
 * @subcategory common modal
 * @component
 * @returns {JSX.Element}
 */
const EtcConfirm = () => {
  const dispatch = useAppDispatch();
  const { modalType, isOpen, data, action } = useAppSelector((state: RootState) => state.modal);
  const groupData = useAppSelector((state: RootState) => state.group);
  const [open, setOpen] = useState<boolean>(isOpen || false);
  // 공지 등록
  const [checked, setChecked] = useState<boolean>(true);

  // 중요 공지 등록 toggle
  const [imToggle, setToggle] = useState<boolean>(false);
  // 중요 공지 등록 checkbox
  const [imChecked, setImChecked] = useState<boolean>(true);

  useEffect(() => {
    setOpen(isOpen!);
  }, [isOpen]);

  const handleModalClose = () => {
    setOpen!(false);
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  const handleConfirm = () => {
    // 일반 공지
    if (data?.type === 'normal') {
      action?.();
    }

    // 공지 상단 고정
    if (data?.type === 'fix_top') {
      action?.();
    }
    handleModalClose();
  };

  //
  const isBtn =
    data?.type === 'fix_top' && checked && imChecked ? false : data?.type === 'normal' && checked ? false : true;

  return (
    <>
      <div className={cn(Styles['popup-dimd'], Styles.show, !open && ResetStyles['d-none'])}>
        <div
          className={cn(
            Styles['popup-container'],
            Styles['modal-popup'],
            modalType === 'confirm_board_change_type' && Styles['set-notice-pop'],
          )}
        >
          <div className={Styles['popup-content']}>
            <div className={Styles.list}>
              <label htmlFor="setNotice">
                <p className={Styles.tit}>{data?.type !== 'normal' ? '중요 공지로 등록' : '공지로 등록'}</p>
              </label>
              {data?.type !== 'normal' && (
                <div className={cn(CommonStyles['toggle-box'], CommonStyles['round-toggle'])}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    id="setNotice"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                  <label htmlFor="setNotice" className={CommonStyles['label-info']}></label>
                </div>
              )}
              {data?.type === 'normal' && (
                <div className={cn(CommonStyles['toggle-box'], CommonStyles['round-toggle'])}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    id="setNotice"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  />
                  <label htmlFor="setNotice" className={CommonStyles['label-info']}></label>
                </div>
              )}
            </div>

            {data?.type !== 'normal' && (
              <div className={cn(Styles['hidden-item'], CommonStyles['hidden-item'], CommonStyles['gray-box'])}>
                <label htmlFor="fixTop">
                  <p className={Styles.tit}>중요 공지로 등록</p>
                  <p className={Styles.txt}>이 글은 목록 최상단에 고정됩니다.</p>
                </label>
                <div className={cn(Styles['check-box'])}>
                  <input
                    type="checkbox"
                    style={{ width: '0px', height: '0px' }}
                    name="type"
                    id="fixTop"
                    checked={imChecked}
                    disabled={checked ? false : true}
                    onChange={(e) => setImChecked(e.target.checked)}
                  />
                  <label htmlFor="fixTop" className={cn(Styles['hidden-item'], Styles['label-info'])}></label>
                </div>
              </div>
            )}
          </div>
          <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
            <Button type="gry" size="large" className={Styles['btn']} mergeClass={true} onClick={handleModalClose}>
              취소
            </Button>
            <Button size="large" className={Styles['btn']} mergeClass={true} disabled={isBtn} onClick={handleConfirm}>
              확인
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EtcConfirm;
