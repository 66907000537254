/* eslint-disable @typescript-eslint/no-explicit-any */
import useQueryState from 'hook/useQueryState';
import { ReactElement, ReactNode, useEffect } from 'react';
import Sheet from 'react-modal-sheet';
import styled from 'styled-components';

interface PanelInterface {
  open?: boolean; // panel open 여부
  onDismiss?: () => void; // panel 닫는 액션
  children?: JSX.Element | ReactNode | ReactElement; // panel content 영역
  modalType?: string;
}

/**
 * @description bottom sheet
 * @see https://www.npmjs.com/package/react-modal-sheet
 * @Class
 * @category Components
 * @subcategory 공통 컴포넌트
 * @component
 * @param {boolean} open sheet open 여부
 * @param {void} onDismiss dim 영역 클릭시 event 함수
 * @param {ReactNode} children children
 * @param {string} modalType sheet type
 * @returns {JSX.Element}
 */
const Panel: React.FC<PanelInterface> = ({ open = false, children, onDismiss = () => {}, modalType }) => {
  /**
   * @description url search param
   */
  const searchParams = new URLSearchParams(location.search);
  /**
   * @description 댓글창 분기 처리
   */
  const isShow = searchParams?.get('show') || '';
  const [showComment, setShowComment] = useQueryState<string | null>(searchParams.get('show'), 'show');

  useEffect(() => {
    const el = document.getElementsByClassName('react-modal-sheet-backdrop');
  }, []);

  return (
    <StyledSheet
      isOpen={open}
      onClose={() => {
        onDismiss();
      }}
      style={{ height: '100vh' }}
      disableScrollLocking={true}
      disableDrag={true}
      detent="content-height"
      tweenConfig={{ ease: 'easeInOut', duration: 0.3 }}
    >
      <Sheet.Container>
        <Sheet.Content>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        layoutId="modal-backdrop"
        onTap={(e) => {
          e.stopPropagation();
          if (isShow !== '' || showComment === 'true') {
            setShowComment('false');
          }
          onDismiss();
        }}
      />
    </StyledSheet>
  );
};

export default Panel;

const StyledSheet = styled(Sheet)`
  .react-modal-sheet-container {
    max-height: 100% !important;
  }
`;
