import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { history } from 'router/index';
import useIsMounted from 'hook/useIsMounted';

// redux
import { useAppDispatch } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';

// configs
import * as Config from 'configs/configs';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Styles from 'styles/header.module.css';
import { moveMain, onClose } from 'utils';

/**
 * @description 글쓰기 선택 매물공유 or 구합니다
 * @Class
 * @category Pages
 * @subcategory 글쓰기
 * @component
 * @returns {JSX.Element}
 */
const NewPostCoagencyType = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupId = queryParams.get('groupId');

  /**
   * @description hardware backey history 동작
   */
  useEffect(() => {
    const listenBackEvent = () => {
      if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
        navigate(`/newpost-type`);
      } else {
        onClose();
      }
    };

    const unlisten = history.listen(({ action }) => {
      if (action === 'POP') {
        listenBackEvent();
      }
    });

    return unlisten;
  }, [history]);

  const handleBackKey = () => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/newpost-type');
    } else {
      // android 글쓰기 moveMain 제거
      // moveMain(2, '');
      onClose();
    }
  };

  const handleCloseConfirm = () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_writing_close',
        action: () => handleClose(),
        isOpen: true,
      }),
    );
  };

  const handleClose = () => {
    if (import.meta.env.MODE === 'localdev' || import.meta.env.MODE === 'coagency') {
      navigate('/newpost-type');
    } else {
      moveMain(2, '');
      onClose();
    }
  };

  return (
    <>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={handleBackKey}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>
        <div className={Styles.title}>공유매물 등록</div>
        <div className={cn(Styles.right, CommonStyles.right)}>
          <button onClick={handleCloseConfirm}>
            <i className={CommonStyles['icon-x-bk']} />
          </button>
        </div>
      </header>
      <div
        className={cn(CommonStyles.container)}
        style={{ overflow: 'auto', position: 'relative', height: 'calc(100vh - 3.325rem)' }}
      >
        <div
          className={cn(
            CommonStyles.content,
            CommonStyles['select-board'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
        >
          <p className={cn(CommonStyles.txt, ResetStyles['fw-b'])}>
            공동중개 중 작성하고자 하는 게시글을 선택해주세요.
          </p>
          <div className={CommonStyles['type-wrap']}>
            <button
              id="coagency-coagency"
              className={CommonStyles['select-type']}
              aria-label="매물공유"
              onClick={() => {
                navigate({
                  pathname: '/coagency-register-select',
                  search: groupId ? `?groupId=${groupId}` : '',
                });
              }}
            >
              <i></i>매물공유
            </button>
            <button
              id="coagency-lookingfor"
              className={CommonStyles['select-type']}
              aria-label="구합니다"
              onClick={() => {
                navigate({
                  pathname: '/coagency-lookingfor-register',
                  search: groupId ? `?groupId=${groupId}` : '',
                });
              }}
            >
              <i></i>구합니다
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPostCoagencyType;
