import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { getUniqueArray } from 'utils/common';
import {
  getMyPostsForGroup,
  getMyPostsForFree,
  getMyCommentsForGroup,
  deleteCommentForGroup,
  getMyCommentsForFree,
  deleteCommentForFree,
  getBookmarksForGroup,
  deleteBookmarkForGroup,
  getBookmarksForFree,
  deleteBookmarkForFree,
  createBookmarkForGroup,
  createBookmarkForFree,
  deleteMyPostForGroup,
  deleteMyPostForFree,
  actionPostForGroup,
  actionPostForFree,
  actionCommentForGroup,
  actionCommentForFree,
  getPostInfoForGroup,
  getPostInfoForFree,
  getBookmarksForOfferings,
  deleteBookmarkForOffering,
  getBookmarksForSeeks,
  deleteBookmarkForSeek,
} from 'api/feed';
import {
  FeedResType,
  FeedListType,
  FeedDeleteCommentResType,
  FeedBookmarkResType,
  FeedGroupBookmarkResType,
  FeedDeletePostResType,
  FeedCommentActionResType,
  FeedPostActionResType,
  FeedPostResType,
  FeedOfferSearchResType,
  FeedSeekSearchResType,
} from '../../@types/feed';

interface FeedState {
  loading: boolean;
  group_posts: FeedListType;
  group_comments: FeedListType;
  group_bookmarks: FeedListType;
  posts: FeedListType;
  comments: FeedListType;
  bookmarks: FeedListType;
  offer_bookmarks: FeedListType;
  seek_bookmarks: FeedListType;
}

interface CustomPayloadAction<T> extends PayloadAction<T> {
  meta?: any;
}

const initialState: FeedState = {
  loading: false, // api 진행 상태
  group_posts: {
    current_page: null,
    total_page: null,
    items: [],
    loading: false,
  },
  group_comments: {
    current_page: null,
    total_page: null,
    items: [],
    loading: false,
  },
  group_bookmarks: {
    current_page: null,
    total_page: null,
    items: [],
    loading: false,
  },
  posts: {
    current_page: null,
    total_page: null,
    items: [],
    loading: false,
  },
  comments: {
    current_page: null,
    total_page: null,
    items: [],
    loading: false,
  },
  bookmarks: {
    current_page: null,
    total_page: null,
    items: [],
    loading: false,
  },
  offer_bookmarks: {
    current_page: null,
    total_page: null,
    total: 0,
    items: [],
    loading: false,
  },
  seek_bookmarks: {
    current_page: null,
    total_page: null,
    total: 0,
    items: [],
    loading: false,
  },
};

/**
 * @description 내피드
 * @Class
 * @category redux
 * @subcategory slice
 */
const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    resetFeed: (state: FeedState) => {
      state.loading = false;
      state.group_posts = { ...initialState.group_posts };
      state.group_comments = { ...initialState.group_comments };
      state.group_bookmarks = { ...initialState.group_bookmarks };
      state.posts = { ...initialState.posts };
      state.comments = { ...initialState.comments };
      state.bookmarks = { ...initialState.bookmarks };
      state.offer_bookmarks = { ...initialState.offer_bookmarks };
      state.seek_bookmarks = { ...initialState.seek_bookmarks };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyPostsForGroup.pending, (state: FeedState) => {
        state.loading = true;
        state.group_posts.loading = true;
      })
      .addCase(getMyPostsForGroup.rejected, (state: FeedState) => {
        state.loading = false;
        state.group_posts.loading = false;
      })
      .addCase(getMyPostsForGroup.fulfilled, (state: FeedState, action: PayloadAction<FeedResType>) => {
        state.group_posts.current_page = action.payload.meta?.current_page;
        state.group_posts.total_page = action.payload.meta?.last_page;

        if (action.payload.meta?.current_page == 1) {
          state.group_posts.items = action.payload.data ?? [];
        } else {
          if (action.payload.data && action.payload.data.length > 0) {
            const uniqueItems = getUniqueArray(state.group_posts.items, 'id', action.payload.data);
            if (uniqueItems.length > 0) state.group_posts.items.push(...uniqueItems);
          }
        }

        state.loading = false;
        state.group_posts.loading = false;
      })
      .addCase(deleteMyPostForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteMyPostForGroup.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        deleteMyPostForGroup.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedDeletePostResType>) => {
          const index = state.group_posts.items.findIndex((item) => item.id === action.meta.arg.id);

          if (index != -1) state.group_posts.items.splice(index, 1);

          state.loading = false;
        },
      )
      .addCase(getMyPostsForFree.pending, (state: FeedState) => {
        state.posts.loading = true;
        state.loading = true;
      })
      .addCase(getMyPostsForFree.rejected, (state: FeedState) => {
        state.posts.loading = false;
        state.loading = false;
      })
      .addCase(getMyPostsForFree.fulfilled, (state: FeedState, action: PayloadAction<FeedResType>) => {
        state.posts.current_page = action.payload.current_page;
        state.posts.total_page = action.payload.last_page;

        if (action.payload.current_page == 1) {
          state.posts.items = action.payload.data ?? [];
        } else {
          if (action.payload.data && action.payload.data.length > 0) {
            const uniqueItems = getUniqueArray(state.posts.items, 'id', action.payload.data);
            if (uniqueItems.length > 0) state.posts.items.push(...uniqueItems);
          }
        }

        state.posts.loading = false;
        state.loading = false;
      })
      .addCase(deleteMyPostForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteMyPostForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        deleteMyPostForFree.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedDeletePostResType>) => {
          const index = state.posts.items.findIndex((item) => item.id === action.meta.arg.id);

          if (index != -1) state.posts.items.splice(index, 1);

          state.loading = false;
        },
      )
      .addCase(getMyCommentsForGroup.pending, (state: FeedState) => {
        state.group_comments.loading = true;
        state.loading = true;
      })
      .addCase(getMyCommentsForGroup.rejected, (state: FeedState) => {
        state.group_comments.loading = false;
        state.loading = false;
      })
      .addCase(getMyCommentsForGroup.fulfilled, (state: FeedState, action: PayloadAction<FeedResType>) => {
        state.group_comments.current_page = action.payload.meta?.current_page;
        state.group_comments.total_page = action.payload.meta?.last_page;

        if (action.payload.meta?.current_page == 1) {
          state.group_comments.items = action.payload.data ?? [];
        } else {
          if (action.payload.data && action.payload.data.length > 0) {
            const uniqueItems = getUniqueArray(state.group_comments.items, 'id', action.payload.data);
            if (uniqueItems.length > 0) state.group_comments.items.push(...uniqueItems);
          }
        }

        state.group_comments.loading = false;
        state.loading = false;
      })
      .addCase(deleteCommentForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteCommentForGroup.rejected, (state: FeedState, action) => {
        state.loading = false;
      })
      .addCase(
        deleteCommentForGroup.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedDeleteCommentResType>) => {
          const index = state.group_comments.items.findIndex((item) => item.id === action.meta.arg.id);

          if (index !== -1) state.group_comments.items.splice(index, 1);

          state.loading = false;
        },
      )
      .addCase(getMyCommentsForFree.pending, (state: FeedState) => {
        state.comments.loading = true;
        state.loading = true;
      })
      .addCase(getMyCommentsForFree.rejected, (state: FeedState) => {
        state.comments.loading = false;
        state.loading = false;
      })
      .addCase(getMyCommentsForFree.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedResType>) => {
        state.comments.current_page = action.payload.current_page;
        state.comments.total_page = action.payload.last_page;

        if (action.payload.current_page == 1) {
          state.comments.items = action.payload.data ?? [];
        } else {
          if (action.payload.data && action.payload.data.length > 0) {
            const uniqueItems = getUniqueArray(state.comments.items, 'id', action.payload.data);
            if (uniqueItems.length > 0) state.comments.items.push(...uniqueItems);
          }
        }

        state.comments.loading = false;
        state.loading = false;
      })
      .addCase(deleteCommentForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteCommentForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        deleteCommentForFree.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedDeleteCommentResType>) => {
          const index = state.comments.items.findIndex((item) => item.id === action.meta.arg.id);

          if (index !== -1) state.comments.items.splice(index, 1);

          state.loading = false;
        },
      )
      .addCase(getBookmarksForGroup.pending, (state: FeedState) => {
        state.group_bookmarks.loading = true;
        state.loading = true;
      })
      .addCase(getBookmarksForGroup.rejected, (state: FeedState) => {
        state.group_bookmarks.loading = false;
        state.loading = false;
      })
      .addCase(getBookmarksForGroup.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedResType>) => {
        state.group_bookmarks.current_page = action.payload.meta?.current_page;
        state.group_bookmarks.total_page = action.payload.meta?.last_page;

        if (action.payload.meta?.current_page == 1) {
          state.group_bookmarks.items = action.payload.data ?? [];
        } else {
          if (action.payload.data && action.payload.data.length > 0) {
            const uniqueItems = getUniqueArray(state.group_bookmarks.items, 'id', action.payload.data);
            if (uniqueItems.length > 0) state.group_bookmarks.items.push(...uniqueItems);
          }
        }

        state.group_bookmarks.loading = false;
        state.loading = false;
      })
      .addCase(getBookmarksForFree.pending, (state: FeedState) => {
        state.bookmarks.loading = true;
        state.loading = true;
      })
      .addCase(getBookmarksForFree.rejected, (state: FeedState) => {
        state.bookmarks.loading = false;
        state.loading = false;
      })
      .addCase(getBookmarksForFree.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedResType>) => {
        state.bookmarks.current_page = action.payload.current_page;
        state.bookmarks.total_page = action.payload.last_page;

        if (action.payload.current_page == 1) {
          state.bookmarks.items = action.payload.data ?? [];
        } else {
          if (action.payload.data && action.payload.data.length > 0) {
            const uniqueItems = getUniqueArray(state.bookmarks.items, 'id', action.payload.data);
            if (uniqueItems.length > 0) state.bookmarks.items.push(...uniqueItems);
          }
        }

        state.bookmarks.loading = false;
        state.loading = false;
      })
      .addCase(createBookmarkForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(createBookmarkForGroup.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        createBookmarkForGroup.fulfilled,
        (state: FeedState, action: PayloadAction<FeedGroupBookmarkResType>) => {
          const index = state.group_posts.items.findIndex((item) => item.id === action.payload?.data?.id);
          if (index != -1 && action.payload.data.post_bookmark && action.payload.data.post_bookmark?.length > 0) {
            state.group_posts.items[index].post_bookmark.push(...action.payload.data.post_bookmark);
          }

          state.loading = false;
        },
      )
      .addCase(deleteBookmarkForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteBookmarkForGroup.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(deleteBookmarkForGroup.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedResType>) => {
        // 모임 게시글 북마크 목록에서 제거
        const bookmarkIndex = state.group_bookmarks.items.findIndex(
          (item) => item.post_bookmark[0].id === action.meta.arg.id,
        );

        if (bookmarkIndex !== -1) {
          state.group_bookmarks.items.splice(bookmarkIndex, 1);
        }

        // 모임 게시글의 북마크 정보 삭제
        const postIndex = state.group_posts.items.findIndex(
          (item) => item.post_bookmark?.length > 0 && item.post_bookmark[0].id === action.meta?.arg?.id,
        );

        if (postIndex != -1) {
          state.group_posts.items[postIndex].post_bookmark.splice(0, 1);
        }

        state.loading = false;
      })
      .addCase(createBookmarkForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(createBookmarkForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        createBookmarkForFree.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedBookmarkResType>) => {
          const index = state.posts.items.findIndex((item) => item.id === action.payload?.post_id);
          if (index != -1 && action.payload.post_bookmark && action.payload.post_bookmark?.length > 0) {
            state.posts.items[index].post_bookmark.push(...action.payload.post_bookmark);
          }

          state.loading = false;
        },
      )
      .addCase(deleteBookmarkForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteBookmarkForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(deleteBookmarkForFree.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedResType>) => {
        // 전체 게시글 북마크 목록에서 제거
        const bookmarkIndex = state.bookmarks.items.findIndex(
          (item) => item.post_bookmark[0].id === action.meta.arg.id,
        );

        if (bookmarkIndex !== -1) state.bookmarks.items.splice(bookmarkIndex, 1);

        // 전체 게시글의 북마크 정보 삭제
        const postIndex = state.posts.items.findIndex(
          (item) => item.post_bookmark?.length > 0 && item.post_bookmark[0].id === action.meta?.arg?.id,
        );

        if (postIndex != -1) {
          state.posts.items[postIndex].post_bookmark.splice(0, 1);
        }

        state.loading = false;
      })
      .addCase(actionPostForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(actionPostForGroup.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(actionPostForGroup.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedPostActionResType>) => {
        const postIndex = state.group_posts.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (postIndex != -1) {
          state.group_posts.items[postIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.group_posts.items[postIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.group_posts.items[postIndex].post_action = action.payload.data?.post_action ?? [];
        }

        const bookmarkIndex = state.group_bookmarks.items.findIndex((item) => item.id === action.meta?.arg?.id);
        if (bookmarkIndex != -1) {
          state.group_bookmarks.items[bookmarkIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.group_bookmarks.items[bookmarkIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.group_bookmarks.items[bookmarkIndex].post_action = action.payload.data?.post_action ?? [];
        }

        state.loading = false;
      })
      .addCase(actionPostForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(actionPostForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(actionPostForFree.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedPostActionResType>) => {
        const postIndex = state.posts.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (postIndex != -1) {
          state.posts.items[postIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.posts.items[postIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.posts.items[postIndex].post_action = action.payload.data?.post_action ?? [];
        }

        const bookmarkIndex = state.bookmarks.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (bookmarkIndex != -1) {
          state.bookmarks.items[bookmarkIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.bookmarks.items[bookmarkIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.bookmarks.items[bookmarkIndex].post_action = action.payload.data?.post_action ?? [];
        }

        state.loading = false;
      })
      .addCase(actionCommentForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(actionCommentForGroup.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        actionCommentForGroup.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedCommentActionResType>) => {
          const commentIndex = state.group_comments.items.findIndex((item) => item.id === action.meta?.arg?.id);

          if (commentIndex != -1) {
            state.group_comments.items[commentIndex].like_count = action.payload.data?.like_count ?? 0;
            state.group_comments.items[commentIndex].dislike_count = action.payload.data?.dislike_count ?? 0;
            state.group_comments.items[commentIndex].comment_action = action.payload.data?.comment_action ?? [];
          }

          state.loading = false;
        },
      )
      .addCase(actionCommentForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(actionCommentForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      .addCase(
        actionCommentForFree.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedCommentActionResType>) => {
          const commentIndex = state.comments.items.findIndex((item) => item.id === action.meta?.arg?.id);

          if (commentIndex != -1) {
            state.comments.items[commentIndex].like_count = action.payload.data?.like_count ?? 0;
            state.comments.items[commentIndex].dislike_count = action.payload.data?.dislike_count ?? 0;
            state.comments.items[commentIndex].comment_action = action.payload.data?.comment_action ?? [];
          }

          state.loading = false;
        },
      )
      .addCase(getPostInfoForGroup.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(getPostInfoForGroup.rejected, (state: FeedState) => {
        state.loading = false;
      })
      // 모임 게시글 공감 정보, 댓글 수 업데이트
      .addCase(getPostInfoForGroup.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedPostResType>) => {
        const postIndex = state.group_posts.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (postIndex != -1) {
          state.group_posts.items[postIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.group_posts.items[postIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.group_posts.items[postIndex].comments_list_count = action.payload.data?.comments_list_count ?? 0;
        }

        const bookmarkIndex = state.group_bookmarks.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (bookmarkIndex != -1) {
          state.group_bookmarks.items[bookmarkIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.group_bookmarks.items[bookmarkIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.group_bookmarks.items[bookmarkIndex].comments_list_count =
            action.payload.data?.comments_list_count ?? 0;
        }

        state.loading = false;
      })
      .addCase(getPostInfoForFree.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(getPostInfoForFree.rejected, (state: FeedState) => {
        state.loading = false;
      })
      // 모임 게시글 공감 정보, 댓글 수 업데이트
      .addCase(getPostInfoForFree.fulfilled, (state: FeedState, action: CustomPayloadAction<FeedPostResType>) => {
        const postIndex = state.posts.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (postIndex != -1) {
          state.posts.items[postIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.posts.items[postIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.posts.items[postIndex].comments_list_count = action.payload.data?.comments_list_count ?? 0;
        }

        const bookmarkIndex = state.bookmarks.items.findIndex((item) => item.id === action.meta?.arg?.id);

        if (bookmarkIndex != -1) {
          state.bookmarks.items[bookmarkIndex].liked_count = action.payload.data?.liked_count ?? 0;
          state.bookmarks.items[bookmarkIndex].hated_count = action.payload.data?.hated_count ?? 0;
          state.bookmarks.items[bookmarkIndex].comments_list_count = action.payload.data?.comments_list_count ?? 0;
        }

        state.loading = false;
      })
      .addCase(getBookmarksForOfferings.pending, (state: FeedState) => {
        state.offer_bookmarks.loading = true;
        state.loading = true;
      })
      .addCase(getBookmarksForOfferings.rejected, (state: FeedState) => {
        state.offer_bookmarks.loading = false;
        state.loading = false;
      })
      // 공유매물 북마크 목록
      .addCase(
        getBookmarksForOfferings.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedOfferSearchResType>) => {
          state.offer_bookmarks.current_page = action.payload.current_page;
          state.offer_bookmarks.total_page = action.payload.last_page;
          state.offer_bookmarks.total = action.payload.total;

          if (action.payload.current_page == 1) {
            state.offer_bookmarks.items = action.payload.data ?? [];
          } else {
            if (action.payload.data && action.payload.data.length > 0) {
              const uniqueItems = getUniqueArray(state.offer_bookmarks.items, 'id', action.payload.data);
              if (uniqueItems.length > 0) state.offer_bookmarks.items.push(...uniqueItems);
            }
          }

          state.offer_bookmarks.loading = false;
          state.loading = false;
        },
      )
      .addCase(getBookmarksForSeeks.pending, (state: FeedState) => {
        state.seek_bookmarks.loading = true;
        state.loading = true;
      })
      .addCase(getBookmarksForSeeks.rejected, (state: FeedState) => {
        state.seek_bookmarks.loading = false;
        state.loading = false;
      })
      // 구합니다 북마크 목록
      .addCase(
        getBookmarksForSeeks.fulfilled,
        (state: FeedState, action: CustomPayloadAction<FeedSeekSearchResType>) => {
          state.seek_bookmarks.current_page = action.payload.current_page;
          state.seek_bookmarks.total_page = action.payload.last_page;
          state.seek_bookmarks.total = action.payload.total;

          if (action.payload.current_page == 1) {
            state.seek_bookmarks.items = action.payload.data ?? [];
          } else {
            if (action.payload.data && action.payload.data.length > 0) {
              const uniqueItems = getUniqueArray(state.seek_bookmarks.items, 'id', action.payload.data);
              if (uniqueItems.length > 0) state.seek_bookmarks.items.push(...uniqueItems);
            }
          }

          state.seek_bookmarks.loading = false;
          state.loading = false;

          //console.log('구합니다(북마크) 목록:', state.seek_bookmarks);
        },
      )
      .addCase(deleteBookmarkForOffering.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteBookmarkForOffering.rejected, (state: FeedState) => {
        state.loading = false;
      })
      // 공유매물 북마크 해제
      .addCase(deleteBookmarkForOffering.fulfilled, (state: FeedState, action: CustomPayloadAction<number[]>) => {
        // 공유매물 북마크 목록에서 제거
        const bookmarkIndex = state.offer_bookmarks.items.findIndex((item) => item.bookmark_id === action.meta.arg);

        if (bookmarkIndex !== -1) state.offer_bookmarks.items.splice(bookmarkIndex, 1);

        state.loading = false;
      })
      .addCase(deleteBookmarkForSeek.pending, (state: FeedState) => {
        state.loading = true;
      })
      .addCase(deleteBookmarkForSeek.rejected, (state: FeedState) => {
        state.loading = false;
      })
      // 구합니다 북마크 해제
      .addCase(deleteBookmarkForSeek.fulfilled, (state: FeedState, action: CustomPayloadAction<number[]>) => {
        // 공유매물 북마크 목록에서 제거
        const bookmarkIndex = state.seek_bookmarks.items.findIndex((item) => item.bookmark_id === action.meta.arg);

        if (bookmarkIndex !== -1) state.seek_bookmarks.items.splice(bookmarkIndex, 1);

        state.loading = false;
      })
      .addCase(PURGE, () => initialState);
  },
});

export const { resetFeed } = feedSlice.actions;
export default feedSlice;
