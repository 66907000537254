import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import _ from 'lodash';
import {
  fetchPostCommentsData,
  setPostCommentLike,
  deletePostComment,
  deleteReportPostComment,
  setBlockUser,
  unBlockUser,
} from 'api/comment';

import Panel from 'components/common/Panel/Panel';
import BottomSheet from '../modal/popup/BottomSheet';
import Toast from 'components/common/Toast/Toast';
import useToast from 'hook/useToast';

import Modal from 'react-modal';
import CommonConfirm from '../modal/popup/CommonConfirm';

// component
import CommentContent from './CommentContent';
import ChildList from './ChildList';

import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Styles from 'styles/modal.module.css';
// utils
import { timeDisplay } from 'utils/common';
import { IMixedKeyValue } from '<modal>';
import { ICommentType, TisViewChildren, TisEdit } from '<comments>';

interface CommentProps {
  id?: string | null;
  comment?: IMixedKeyValue;
  commentType?: ICommentType;
  setCommentType?: (commentType: ICommentType) => void;
  isEdit?: TisEdit;
  setIsEdit?: (isEdit: TisEdit) => void;
}

/**
 * @description 댓글 F.C
 * @Class
 * @category Components
 * @subcategory 댓글
 * @param {CommentProps} props
 * @param {string | null} props.id
 * @param {object} props.comment
 * @param {object} props.commentType
 * @param {function} props.setCommentType
 * @param {object} props.isEdit
 * @param {function} props.setIsEdit
 * @returns {JSX.Element}
 */
const Comment = ({ id, comment, commentType, setCommentType, isEdit, setIsEdit }: CommentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const clickRef = useRef<boolean>(false);
  const { setToastMessage } = useToast();
  const toastState = useAppSelector((state: RootState) => state.toast);
  const infoMe = useAppSelector((state: RootState) => state.user.me);
  const comments = useAppSelector((state: RootState) => state.comment);
  const parentId = useAppSelector((state: RootState) => state.comment.parentId);
  const currentKindId = useAppSelector((state: RootState) => state.board.currentKindId);

  const modal = useAppSelector((state: RootState) => state.modal);
  const { data } = modal;
  const nowDate = new Date();
  const [show, setShow] = useState(false);
  const [isUnFold, setIsUnFold] = useState<TisViewChildren[]>([]);

  const [targetDepth, setTargetDepth] = useState('1depth');
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  /**
   * @description scroll into view
   */
  useEffect(() => {
    if (comments.isAddComment && comments.newCommentId) {
      const element = document.getElementById(`item_${comments.newCommentId}`);
      setTimeout(() => {
        element?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 100);
    }
    if (comments.isAddChildren && comments?.newChildrenId) {
      const el = document.getElementById(`item_child_${comments.newChildrenId}`);
      setTimeout(() => {
        el?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 100);
    }
    setTargetDepth('1depth');
  }, [comments]);

  /**
   * @description unfold 대댓글 영역
   */
  useEffect(() => {
    let _isUnFold;
    if (comments.parentId) {
      _isUnFold = comments?.commentLists?.map((item, index) =>
        _.assign(
          {},
          {
            referId: item?.id,
            isChildren: !!item?.children?.length,
            isOpen: item.id === parentId ? true : false,
          },
        ),
      );
    } else {
      _isUnFold = comments?.commentLists?.map((item, index: number) =>
        _.assign({}, { referId: item?.id, isChildren: !!item?.children?.length, isOpen: index === 0 ? true : false }),
      );
    }
    setIsUnFold(_isUnFold!);
  }, [comments]);

  /**
   * @description reply
   * @returns {void}
   */
  const handleReply = (): void => {
    setCommentType!({
      ...commentType,
      focusDepth: '1depthReply',
      parentId: comment?.id || '',
      mentionId: comment?.user?.id || '',
      mentionNickname: comment?.user?.nickname,
      isOn: true,
    } as ICommentType);
  };

  /**
   * @description 댓글 더보기, 댓글 숨기기
   * @param {number} id 참조 id
   * @returns {void}
   */
  const handleViewChildren = (id: number): void => {
    const _newIsUnFold = [...isUnFold].map((item) =>
      _.assign(item, { isOpen: item.referId === id ? !item.isOpen : item.isOpen }),
    );
    setIsUnFold(_newIsUnFold);
  };

  /**
   * @description comment tool 1depth
   * @returns {void}
   */
  const handleToolsOneDepth = (): void => {
    if (infoMe.id === comment?.user_id) {
      dispatch(
        ActionModal.openMultiModal({
          multiModalType: 'comment_tool_me',
          multiData: comment,
          action: () => handleEditComment(comment?.id),
          multiAction: async () =>
            comments?.groupId
              ? await handleDeleteComment(comment?.id, comment?.parent_id, comments?.groupId, comments?.postId)
              : await handleDeleteComment(comment?.id),
          isMultiple: true,
        }),
      );
      // console.log('comment 작성자가 나의 경우 tooltip bottomsheet');
    } else {
      dispatch(
        ActionModal.openMultiModal({
          multiModalType: 'comment_tool_other',
          multiData: comment,
          action: async () =>
            comment?.comment_report?.length > 0
              ? await handlRemoveReport(
                  '',
                  comment?.comment_report[0].comment_id,
                  comment?.comment_report[0].id,
                  targetDepth,
                )
              : handleReport(), // 여기에 신고, 신고해제 api영역
          multiAction: () => (comment?.user_block?.block_user_id ? fetchUnBlockUser() : handleBlock()), // 여기에 차단, 차단해제 api영역
          isMultiple: true,
        }),
      );
      // console.log('comment 작성자가 내가 아닌경우 tooltip bottomsheet');
    }
  };

  /**
   * @description 좋아요 싫어요 handler
   * @returns {void}
   */
  const handleLikeOrUnlike = useCallback((type: string) => {
    const params = {
      kind: type === 'like' ? 1 : 2,
    };
    dispatch(setPostCommentLike(comment?.id, params, data?.id, data?.group_id, data?.isType));
  }, []);

  /**
   * @description Comment edit
   * @returns {number} comment id
   * @returns {void}
   */
  const handleEditComment = (id: number) => {
    setIsEdit!({
      ...isEdit,
      editTarget: '1depth',
      parentCommentId: null,
      editCommentId: id,
      isEditOn: true,
    } as TisEdit);
  };

  /**
   * @description Comment delete 삭제하기
   *  @returns {Promise<void>}
   */
  const handleDeleteComment = async (
    commentId: number,
    pid?: number,
    groupId?: number,
    groupPostId?: number | null,
  ) => {
    if (data?.isType === 'coagency' || data?.isType === 'looking-for') {
      await dispatch(deletePostComment(targetDepth, commentId, pid, groupId, data.id, data?.isType));
    } else {
      await dispatch(deletePostComment(targetDepth, commentId, pid, groupId, groupPostId));
    }
  };

  /**
   * @description 신고하기
   * @returns {void}
   */
  const handleReport = (): void => {
    setIsSheetOpen(true);
  };

  /**
   * @description 신고해제
   * @returns {void}
   */
  const handlRemoveReport = async (
    parentId: string,
    commentId: number,
    reportId: number,
    depth: string,
  ): Promise<void> => {
    if (data?.isType === 'coagency' || data?.isType === 'looking-for') {
      // 코멘트 id
      const cid =
        data?.isType === 'coagency'
          ? comment?.comment_report[0]?.offering_comment_id
          : comment?.comment_report[0]?.offering_seek_comment_id;
      await dispatch(
        deleteReportPostComment('', cid, comment?.comment_report[0].id, depth, data.id, undefined, data?.isType),
      );
    } else {
      await dispatch(deleteReportPostComment('', commentId, reportId, depth));
    }
    await dispatch(fetchPostCommentsData(currentKindId, comments.postId!, comments.groupId!, data?.isType));
  };

  /**
   * @description 사용자 차단하기
   * @returns {void}
   */
  const handleBlock = (): void => {
    setIsConfirmOpen(true);
  };

  /**
   * @description 사용자 차단하기 api call
   * @returns {Promise<void>}
   */
  const fetchBlockUser = async (): Promise<void> => {
    const params = {
      block_user_id: comment?.user_id,
    };
    await dispatch(setBlockUser(null, comment?.id, params, targetDepth));
    await dispatch(fetchPostCommentsData(currentKindId, comments?.postId, comments.groupId!));
    setIsConfirmOpen(false);
  };

  /**
   * @description 사용자 차단하기 해제
   * @returns {Promise<void>}
   */
  const fetchUnBlockUser = async (): Promise<void> => {
    await dispatch(unBlockUser(null, comment?.id, comment?.user_id, targetDepth));
    await dispatch(fetchPostCommentsData(currentKindId, comments?.postId, comments.groupId!));
    setIsConfirmOpen(false);
  };

  // console.log('comment in Comment', comment);
  // console.log('comments in parent Comment==>', comments);
  // console.log('id===>', id);
  // console.log('isUnFold', isUnFold);

  return (
    <div key={`item_${id}`} id={`item_${id}`} className={CommonStyles['comment-item']}>
      <div className={CommonStyles['member-information']}>
        <div className={CommonStyles.clickable}>
          <div className={CommonStyles.profile}>
            <div
              className={CommonStyles['img-div']}
              style={{
                width: 32,
                height: 32,
                backgroundImage: comment?.user?.profile_photo_path
                  ? `url(${comment?.user?.profile_photo_path}`
                  : `url(../images/icon_profile.svg)`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
              }}
            />
            {comment?.user?.verify?.broker_code && (
              <span className={CommonStyles.grade} aria-label={comment?.user?.grade} />
            )}
          </div>
          <div className={CommonStyles['mem-info']}>
            <p className={CommonStyles.name}>{comment?.user?.nickname}</p>
            <div className={CommonStyles['divide-box']}>
              {comment?.user?.company && (
                <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                  {comment?.user?.company?.name}
                </p>
              )}
              {(data?.isType === 'coagency' || data?.isType === 'looking-for') && <p>{comment?.user?.phone}</p>}
              <p>{timeDisplay(nowDate, comment?.created_at)}</p>
            </div>
          </div>
        </div>
        <button type="button" className={CommonStyles.tool} onClick={handleToolsOneDepth} />
      </div>
      <div
        className={CommonStyles.content}
        id={`item_content_${id}`}
        style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}
      >
        <React.Fragment key={`item_blocked_reported_${id}`}>
          {comment?.user_block?.block_user_id && (
            <div key={`blocked_${id}`} className={CommonStyles['blind-box']} style={{ margin: '5px 0' }}>
              {'차단된 게시글입니다.'}
            </div>
          )}
          {comment?.comment_report?.length > 0 && (
            <div key={`reported_${id}`} className={CommonStyles['blind-box']} style={{ margin: '5px 0' }}>
              {'신고된 게시글입니다.'}
            </div>
          )}
        </React.Fragment>
        {comment?.comment_report?.length === 0 && !comment?.user_block && (
          <>
            <div className={CommonStyles.text}>
              <CommentContent
                key={`item_comment_${id}`}
                id={comment?.id}
                childKey={comment?.id}
                comment={comment}
                maxLines={3}
                show={show}
                onExpended={() => {
                  setShow(true);
                }}
              />
            </div>
            {(comment?.photos?.length > 0 || comment?.emoticon) && (
              <div className={CommonStyles['attached-div']}>
                {comment?.photos?.length > 0 && (
                  <div className={CommonStyles['img-box']}>
                    <button className={CommonStyles['img-item']}>
                      <img
                        src={comment?.photos[0]?.url}
                        alt={comment?.photos[0]?.org_name}
                        onClick={() =>
                          dispatch(
                            ActionModal.openMultiModal({
                              multiModalType: 'show_image_full',
                              multiData: { url: comment?.photos[0]?.url, filename: comment?.photos[0]?.org_name },
                              isMultiple: true,
                            }),
                          )
                        }
                      />
                    </button>
                  </div>
                )}
                {comment?.emoticon && (
                  <div className={CommonStyles['emoji-box']}>
                    <img src={`/images/${comment.emoticon}`} alt="이모지" />
                  </div>
                )}
              </div>
            )}
            {!comment?.user_block && !comment?.comment_report?.length && (
              <div className={CommonStyles['post-function']}>
                <button
                  type="button"
                  className={cn(
                    CommonStyles.like,
                    comment?.comment_action && comment?.comment_action?.[0]?.kind === 1 && CommonStyles.on,
                  )}
                  onClick={() => handleLikeOrUnlike('like')}
                >
                  <i className={CommonStyles['icon-like']}></i>
                  {comment?.like_count}
                </button>
                <button
                  type="button"
                  className={cn(
                    CommonStyles.dislike,
                    comment?.comment_action && comment?.comment_action?.[0]?.kind === 2 && CommonStyles.on,
                  )}
                  onClick={() => handleLikeOrUnlike('dislike')}
                >
                  <i className={CommonStyles['icon-dislike']}></i>
                  {comment?.dislike_count}
                </button>
                <button type="button" className={CommonStyles.comment} onClick={() => handleReply()}>
                  <i className={CommonStyles['icon-comment']}></i>답글 달기
                </button>
              </div>
            )}
          </>
        )}
        {comment?.children?.map((child: IMixedKeyValue) => {
          return (
            <ChildList
              key={child.id}
              comment={child}
              commentType={commentType}
              setCommentType={setCommentType}
              isUnFold={isUnFold}
              isEdit={isEdit}
              childrenData={data}
              setIsEdit={setIsEdit}
            />
          );
        })}
        {isUnFold.map((item) => {
          return (
            item.isChildren &&
            item.referId === comment?.id && (
              <button
                key={item.referId}
                type="button"
                className={CommonStyles['more-btn']}
                onClick={() => handleViewChildren(item.referId!)}
              >
                {item?.isOpen ? '답글 숨기기' : `댓글 ${comment?.children?.length}개 더보기`}
              </button>
            )
          );
        })}
      </div>
      {isSheetOpen && (
        <Panel open={isSheetOpen!} onDismiss={() => setIsSheetOpen(!isSheetOpen)} modalType={'bottomSheet_report'}>
          <BottomSheet
            type="bottomSheet_report"
            title="신고하기"
            targetData={comment}
            targetDepth={targetDepth}
            setIsSheetOpen={setIsSheetOpen}
          />
        </Panel>
      )}
      {isConfirmOpen && (
        <Modal
          isOpen={isConfirmOpen!}
          onRequestClose={() => setIsConfirmOpen(!open)}
          style={{
            overlay: {
              position: 'fixed',
              zIndex: 999999999,
              inset: 0,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
          className={Styles.modal}
          ariaHideApp={false}
        >
          <CommonConfirm
            type="confirm_block_user"
            title="차단하시겠어요?"
            align="left"
            txt={`이 회원이 쓴 글과 댓글에 대해 알림이 오지 않고,\n작성한 내용도 보이지 않게 됩니다.`}
            fetchBlockUser={fetchBlockUser}
            setIsConfirmOpen={setIsConfirmOpen}
          />
        </Modal>
      )}
      {toastState.isOpen && <Toast />}
    </div>
  );
};

export default Comment;
