import { PURGE } from 'redux-persist';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
};

/**
 * @description subView
 * @Class
 * @category redux
 * @subcategory slice
 */
const subViewSlice = createSlice({
  name: 'subview',
  initialState,
  reducers: {
    add: (state) => {
      state.count++;
    },
    subtract: (state) => {
      state.count--;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { add, subtract } = subViewSlice.actions;

export default subViewSlice;
