import { useState, useEffect, useCallback } from 'react';
import * as APIS from 'api/group';
import cn from 'classnames';
import CommonStyles from 'styles/common.module.css';
import Textarea from 'components/common/Textarea/Textarea';
import Icon from 'components/common/Icon/IconComponent';
import Button from 'components/common/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { openModal } from 'redux/slices/modal';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { offLoad, onLoad } from 'redux/slices/loading';

/**
 * @description 모임 설정 -  모임명,주제,소개
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupIntroduce = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = '' } = params;
  const { isOpen = false, modalType = '' } = useAppSelector((state: RootState) => state.modal);
  const groupData = useAppSelector((state: RootState) => state.group);
  const { group } = groupData;

  const [value, setValue] = useState<string>('');
  // 임시 저장 데이터 => 정보 변경 여부 체크 state
  const [tempInfo, setTempInfo] = useState<any>({
    text: '', // 소개글,
    category: '', // 모임 주제
    category_value: '', // 모임 주제
    category_key: null, // 모임 주제
    keyCode: '',
    addr: '',
    code: null,
  });
  // 모임정보
  const [info, setInfo] = useState<{
    category: string;
    category_value: string;
    category_key: number | null;
  }>({
    category: '', // 모임 주제명
    category_value: '', // 모임 주제 value 값F
    category_key: null, // 모임 주제 key 값
  });

  const [area, setArea] = useState<{ addr?: string; code?: string; keyCode?: number | null }>({
    keyCode: null,
    addr: '',
    code: '',
  });

  // 모임 정보 설정
  useEffect(() => {
    if (group) {
      const {
        kind_info = {
          label: '',
          value: '',
          key: null,
        },
        type_info = {
          key: null,
        },
        area_info = {
          addr: '',
          code: '',
        },
      } = group as {
        kind_info: {
          label: string;
          value: string;
          key: number | null;
        };
        type_info: {
          key: number | string | null;
        };

        area_info: {
          addr: string;
          code: string;
        };
      };

      const { desc } = group;
      if (kind_info) {
        setInfo({
          ...info,
          category: kind_info['label'],
          category_value: kind_info['value'],
          category_key: kind_info['key'],
        });
      }

      if (type_info?.key) {
        if (type_info.key === 1701 || type_info.key === 1702) {
          if (area_info?.code) {
            setArea({
              keyCode: type_info.key,
              addr: area_info.addr,
              code: area_info.code,
            });
          }
        }
      }

      if (desc?.length > 0) {
        setValue(desc);
      }

      // group state가 변경될 때 마다 값이 바뀌므로 분기처리(처음 한번만 셋팅)
      if (tempInfo.category === '') {
        setTempInfo({
          text: desc || '',
          category: kind_info?.['label'] || '',
          category_value: kind_info?.['value'] || '',
          category_key: kind_info?.['key'] || '',
          keyCode: type_info?.key || '',
          addr: area_info?.addr || '',
          code: area_info?.code || null,
        });
      }
    }
  }, [group]);

  useEffect(() => {
    if (!isOpen && modalType === 'groupBotSheet_group_subject_select') {
      setIsCategory(false);
    }

    if (!isOpen && modalType === 'groupBotSheet_group_area') {
      setKeyPad(false);
    }
  }, [isOpen]);

  // 지역설정
  const [keypad, setKeyPad] = useState<boolean>(false);
  // 카테고리 설정
  const [isCategory, setIsCategory] = useState<boolean>(false);

  /**
   * @description 모임 정보 설정 api
   * @returns {Promise<void>}
   */
  const modifyGroupIntroduce = useCallback(async () => {
    if (id === '') return;
    try {
      dispatch(onLoad());
      const payload = {
        desc: value, //소개 설명
        area: area?.code ? area.code : '', //법정동 코드
        kind: info.category_key, //모임종류 key 값
      };
      await APIS.putModifyIntroduceAndArea(`/groups/${id}/intro`, payload);
    } catch (err) {
      console.log(err, 'err');
    } finally {
      handleClickBack();
      setTimeout(() => {
        dispatch(offLoad());
      }, 300);
    }
  }, [info, area, value]);

  /**
   * @description 뒤로가기
   */
  const handleClickBack = useCallback(() => {
    navigate(-1);
  }, []);

  /**
   * @description 모임 카테고리 설정 sheet open
   */
  const handleClickOpenCategory = () => {
    if (id === '') return;
    if (!info.category) return;
    setIsCategory(true);
    dispatch(
      openModal({
        modalType: 'groupBotSheet_group_subject_select',
        isOpen: true,
        data: {
          category: info.category,
          category_value: info.category_value,
        },
        action: (selected: string, value: string, key: number | null) => handleClickCategory(selected, value, key),
      }),
    );
  };

  /**
   * @description 모임 지역 설정 sheet open
   */
  const handleClickOpenArea = () => {
    if (id === '') return;
    if (!info.category) return;
    setKeyPad(true);
    dispatch(
      openModal({
        modalType: 'groupBotSheet_group_area',
        isOpen: true,
        action: (addr: string, code: string) => {
          handleClickArea(addr, code);
          setKeyPad(false);
        },
        // data: {
        //   category: info.category,
        //   category_value: info.category_value,
        // },
        // action: (selected: string, value: string) => handleClickCategory(selected, value),
      }),
    );
  };

  /**
   * @description 모임 지역설정
   * @param {string} addr 설정 지역 주소
   * @param {string} code 설정 지역 코드
   */
  const handleClickArea = (addr: string, code: string) => {
    if (addr && code) {
      setArea({
        ...area,
        addr: addr,
        code: code,
      });
    }
  };

  /**
   * @description 모임 카테고리 설정
   * @param {string} selected 선택된 모임 카테고리
   * @param {string} value 모임 카테고리 value
   * @param {number} key 모임 카테고리 키값
   * @returns {void}
   */
  const handleClickCategory = (selected: string, value: string, key: number | null) => {
    if (id === '') return;
    if (selected === info.category) return;
    if (key === null) return;
    setInfo({
      category: selected,
      category_value: value,
      category_key: key,
    });
  };

  /**
   * @description 확인 버튼 상태 enable disable
   */
  const isValidation = () => {
    let isBtn = false;

    if (info.category === tempInfo.category) {
      isBtn = true;
    }

    if (value === tempInfo.text && value?.length === 0) {
      isBtn = true;
    }

    if (area.keyCode !== 1701 && area?.keyCode) {
      if (area.addr === tempInfo.addr) {
        isBtn = true;
      }
    }

    if (value !== tempInfo.text && value?.length !== 0) {
      isBtn = false;
    }

    if (info.category !== tempInfo.category) {
      isBtn = false;
    }

    if (area.keyCode === 1701 && area?.keyCode) {
      if (area.addr !== tempInfo.addr) {
        isBtn = false;
      }
    }

    return isBtn;
  };

  const isBtn = isValidation();

  return (
    <>
      <div className={cn(CommonStyles.hasBottom)}>
        <div className={cn(CommonStyles.content, CommonStyles['setting-group'])}>
          <div className={cn(CommonStyles['setting-form'])}>
            <div className={CommonStyles.section}>
              <p className={CommonStyles.tit}>모임 소개</p>
              <Textarea
                maxLength={300}
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              />
            </div>
            {area?.keyCode && (
              <div className={CommonStyles.section}>
                <p className={CommonStyles.tit}>모임 지역</p>
                <Button
                  className={cn(CommonStyles.dropdown, keypad && CommonStyles.focus, CommonStyles.on)}
                  mergeClass={false}
                  onClick={handleClickOpenArea}
                >
                  <span>{area.addr}</span>
                  <Icon type={keypad ? 'icon-arrow-down-gr,icon-top' : 'icon-arrow-down-gr'} />
                </Button>
              </div>
            )}
            <div className={CommonStyles.section}>
              <p className={CommonStyles.tit}>모임 주제</p>
              <Button
                className={cn(CommonStyles.dropdown, isCategory && CommonStyles.focus, CommonStyles.on)}
                mergeClass={false}
                onClick={handleClickOpenCategory}
              >
                <span>{info.category}</span>
                <Icon type={isCategory ? 'icon-arrow-down-gr,icon-top' : 'icon-arrow-down-gr'} />
              </Button>
            </div>
          </div>

          <div className={cn(CommonStyles['bottom-fixed'], CommonStyles['btn-wrap'])}>
            <Button size="large" type="gry" onClick={handleClickBack}>
              취소
            </Button>
            <Button size="large" disabled={isBtn} onClick={modifyGroupIntroduce}>
              확인
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupIntroduce;
