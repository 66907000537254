import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import AWS from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import useIsMounted from 'hook/useIsMounted';
import useOutsideClick from 'hook/useOutSideClick';
import useToast from 'hook/useToast';
import useQueryState from 'hook/useQueryState';
import { isIOS, isSafari } from 'react-device-detect';

// redux
import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionUser from 'redux/slices/user';
import * as ActionModal from 'redux/slices/modal';
import * as ActionLoading from 'redux/slices/loading';
// styles
import CommonStyles from 'styles/common.module.css';
import Styles from 'styles/header.module.css';
import ResetStyles from 'styles/reset.module.css';
// configs
import * as Config from 'configs/configs';
// utils
import { getFileExtentions } from 'utils/common';
import network from 'utils/network';
import { IMixedKeyValue } from '<modal>';
import { onClose, moveWritingTab } from 'utils';
// api
import * as APIS from 'api/group';

export type TWritingState = {
  title?: string;
  content?: string;
  kind?: number | null;
  emoticon?: string;
  latitude?: string;
  longitude?: string;
  addr?: string;
  photos?: IMixedKeyValue[];
  files?: IMixedKeyValue[];
};

type TUploadFile = {
  mime_type: string;
  org_name: string;
  url: string;
};

/**
 * @description 글쓰기 F.C
 * @Class
 * @category Pages
 * @subcategory 글쓰기
 * @component
 * @returns {JSX.Element}
 */
const Writing = (): JSX.Element => {
  const isMounted = useIsMounted();
  const location = useLocation();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setToastMessage } = useToast();

  const shareBoardDate = useAppSelector((state: RootState) => state.board.shareBoardData);
  const { isOpen = false } = useAppSelector((state: RootState) => state.toast);
  const searchParams = new URLSearchParams(location.search);
  const [boardType, setBoardType] = useQueryState<string | null>(searchParams.get('type'), 'type');
  // const boardType: string | null = searchParams.get('type');
  const mode = searchParams.get('mode');
  const postId = searchParams.get('postId');
  const type = searchParams.get('type');
  const groupId = searchParams.get('groupId');
  const groupName = searchParams.get('name');
  const shareOrigin = searchParams.get('shareOrigin');
  // 전체게시판글을 모임에 공유할때 전체게시판 post id
  const sharedId = searchParams.get('sharedId');

  const [writingState, setWritingState] = useState<TWritingState>({
    title: '',
    content: '',
    kind: null,
    emoticon: '',
    latitude: '',
    longitude: '',
    addr: '',
    photos: [],
    files: [],
  });
  const { title, content, kind, emoticon, latitude, longitude, addr } = writingState;
  const textTitle = document.getElementById('autoTitle') as HTMLElement;
  const textContent = document.getElementById('autoContent') as HTMLElement;

  // emoji
  const [isOpenEmoji, setIsOpenEmoji] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<TUploadFile[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<TUploadFile[]>([]);

  const titleRef = useRef<HTMLTextAreaElement>(null);
  const contentRef = useRef<HTMLTextAreaElement>(null);
  const imageInput = useRef<HTMLInputElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const attachedRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  // 삭제
  const initDelete = {
    deleteType: '',
    deleteTarget: '',
  };
  const [isDelete, setIsDelete] = useState(initDelete);
  const { deleteType, deleteTarget } = isDelete;

  // share
  const [shareData, setShareData] = useState({
    shareTitle: '',
    shareContent: '',
    shareCate: { key: null, label: '' },
  });

  const { shareTitle, shareContent } = shareData;
  const [emojiOutSideClick, setEmojiOutSideClick] = useState(false);

  // btn disable
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const s3Regex = /[^a-zA-Z0-9가-힣ㄱ-ㅎㅏ-ㅣ._-]/g;

  /**
   * @description 최초 mount시 필수입력 contentRef에 focus
   */
  useEffect(() => {
    if (isMounted()) {
      contentRef.current?.focus();
    }
  }, []);

  /**
   * @description content를 가져올때 autoResizeTextarea() 실행
   */
  useEffect(() => {
    if (contentRef.current) {
      autoResizeTextarea();
      const container = document.getElementById('container');
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }
  }, [content]);

  /**
   * @description 게시판 타입별 kind값 할당
   */
  useEffect(() => {
    const _kind = boardType === 'free-board' ? 1500 : boardType === 'issue' ? 1501 : null;
    setWritingState({
      ...writingState,
      kind: _kind,
    });
  }, [boardType]);

  /**
   * @description 수정하기 및 공유하기(edit, share)
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (mode === 'edit' && postId && type !== 'group' && !groupId) {
        await getPostDetail(Number(postId!));
      } else if (mode === 'share' && groupId && state) {
        setShareData({
          ...shareData,
          shareTitle: state?.title,
          shareContent: state?.content,
        });
      } else if (mode === 'share' && groupId && shareBoardDate && Object.keys(shareBoardDate).length > 0) {
        setShareData({
          ...shareData,
          shareTitle: shareBoardDate?.title,
          shareContent: shareBoardDate?.content,
        });
      } else if (mode === 'share' && groupId && localStorage.getItem('shareData')) {
        setShareData({
          ...shareData,
          shareTitle: localStorage.getItem('shareData') && JSON.parse(localStorage.getItem('shareData')!).title,
          shareContent: localStorage.getItem('shareData') && JSON.parse(localStorage.getItem('shareData')!).content,
        });
      } else if (mode === 'edit' && postId && type === 'group' && groupId) {
        await getGroupPostDetail(Number(postId!), Number(groupId!));
        await getPostDetail(Number(postId!));
      }
    };
    getData();
    return () => abortController.abort();
  }, [mode, postId, groupId]);

  /**
   * @description natvie에서 전체게시판 글(post)을 모임에 공유할때 sharedId에 해당하는 글을 가져오기
   */
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      if (mode === 'share' && sharedId) {
        await getPostSharedDetail(Number(sharedId!));
      }
    };
    getData();
    return () => abortController.abort();
  }, [sharedId]);

  // /**
  //  * @description 최초 mount시 필수입력 contentRef에 focus 하고 iOS 또는 safari browser에서 focus시에 scroll 방지
  //  */
  // useEffect(() => {
  //   if (isIOS || isSafari) {
  //     const handleFocus = (e: any) => {
  //       if (e.target === contentRef.current) {
  //         e.preventDefault();
  //         contentRef.current?.focus({ preventScroll: true });
  //       }
  //       if (e.target === titleRef.current) {
  //         e.preventDefault();
  //         titleRef.current?.focus({ preventScroll: true });
  //       }
  //       handleFocusScroll();
  //     };

  //     // const handleBlur = (e: any) => {
  //     //   handleBlurScroll();
  //     // };

  //     const titleTextarea = titleRef.current;
  //     const contentTextarea = contentRef.current;
  //     titleTextarea?.addEventListener('focus', handleFocus, false);
  //     contentTextarea?.addEventListener('focus', handleFocus, false);
  //     return () => {
  //       titleTextarea?.removeEventListener('focus', handleFocus, false);
  //       contentTextarea?.removeEventListener('focus', handleFocus, false);
  //     };
  //   }
  // }, [titleRef.current, contentRef.current]);

  // /**
  //  * @description handle Focus Scroll
  //  * @returns {void}
  //  */
  // const handleFocusScroll = () => {
  //   const scroll = window.scrollY;
  //   document.documentElement.style.position = 'fixed';
  //   document.body.style.top = `${-scroll}px`;
  // };

  /**
   * @description 모엠 게시글 상세 조회
   * @returns {Promise<void>}
   */
  const getGroupPostDetail = useCallback(
    async (postId: number, groupId: number) => {
      if (!postId && !groupId) return;
      try {
        const res = await APIS.getGroupPostDetail(`groups/${groupId}/posts/${postId}`);
        console.log('res==>', res);
        const _data = res.data;
        const _writingState = {
          title: _data?.title,
          content: _data?.content,
          kind: _data.cate?.key,
          emoticon: _data?.emoticon,
          latitude: _data?.latitude,
          longitude: _data?.longitude,
          addr: _data?.addr,
          photos: _data?.photos,
          files: _data?.files,
        };
        setWritingState(_writingState);
        setUploadedImages(_data.photos);
        setUploadedFiles(_data.files);
      } catch (err) {
        console.log(err, 'err');
      }
    },
    [postId, groupId],
  );

  /**
   * @description 전체게시판의 post edit mode에서 상세정보 가져오기
   * @param {number} postId {post id}
   * @returns {Promise<void>}
   */
  const getPostDetail = useCallback(
    async (postId: number) => {
      try {
        const res = await network().post().getPostDetail(`/posts/${postId}`);
        console.log('res in Writing edit mode getPostDetail==>', res);
        const _data = res.data;
        const _writingState = {
          title: _data?.title,
          content: _data?.content,
          kind: _data.cate?.key,
          emoticon: _data?.emoticon,
          latitude: _data?.latitude,
          longitude: _data?.longitude,
          addr: _data?.addr,
          photos: _data?.photos,
          files: _data?.files,
        };
        setWritingState(_writingState);
        setUploadedImages(_data.photos);
        setUploadedFiles(_data.files);
      } catch (err) {
        console.error(err);
      }
    },
    [postId],
  );

  /**
   * @description 공유하고자 하는 전체게시판의 post에 해당하는 상세정보 가져오기
   * @param {number} sharedId {post id}
   * @returns {Promise<void>}
   */
  const getPostSharedDetail = useCallback(
    async (sharedId: number) => {
      try {
        const res = await network().post().getPostDetail(`/posts/${sharedId}`);
        console.log('res in Writing shared getPostSharedDetail==>', res);
        const _data = res.data;
        setShareData({
          shareTitle: _data?.title,
          shareContent: _data?.content,
          shareCate: _data.cate,
        });
      } catch (err) {
        console.error(err);
      }
    },
    [sharedId],
  );

  /**
   * @description textarea value change for title and content
   * @param {object} e React.SyntheticEvent
   * @returns {Promise<void>}
   */
  const handleValueChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    e.stopPropagation();
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'title') {
      value = e.target.value.slice(0, 50);
      if (value.length >= 50) {
        dispatch(ActionModal.openModal({ modalType: 'alert_title_limit', isOpen: true }));
      }

      setWritingState({
        ...writingState,
        [name]: value,
      });
    } else if (name === 'content') {
      value = e.target.value.slice(0, 3000);
      if (value.length > 3000) {
        dispatch(ActionModal.openModal({ modalType: 'alert_content_limit', isOpen: true }));
      }
      setWritingState({
        ...writingState,
        [name]: value,
      });
    }
  };

  /**
   * @description outside click handler
   * @returns {void}
   */
  const handleClickDeleteOutside = (): void => {
    setIsDelete(initDelete);
    setEmojiOutSideClick(false);
  };
  const deleteRef = useOutsideClick(handleClickDeleteOutside, attachedRef);

  /**
   * @description emoji click handler
   * @returns {void}
   */
  const handleClickEmojiOutside = (): void => {
    setEmojiOutSideClick(true);
  };
  const emojiRef = useOutsideClick(handleClickEmojiOutside, attachedRef, selectRef);

  const onClickImageUpload = useCallback(() => {
    imageInput?.current?.click();
  }, [imageInput.current]);

  const onClickFileUpload = useCallback(() => {
    fileInput?.current?.click();
  }, [fileInput.current]);

  // AWS config and new s3
  AWS.config.update({
    region: import.meta.env.VITE_S3_BUCKET_REGION,
    accessKeyId: import.meta.env.VITE_S3_BUCKET_ACCESSKEY,
    secretAccessKey: import.meta.env.VITE_S3_BUCKET_SECRETKEY,
  });
  const s3 = new AWS.S3();

  /**
   * @description upload  photo image to S3
   * @param {File[]} files 파일
   * @param {string} bucketName
   * @param {string[]} keys
   * @returns {Promise<ManagedUpload.SendData[]>}
   */
  const uploadImagesToS3 = async (
    files: File[],
    bucketName: string,
    keys: string[],
  ): Promise<ManagedUpload.SendData[]> => {
    const uploadPromises: Promise<ManagedUpload.SendData>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = keys[i];

      const params: AWS.S3.PutObjectRequest = {
        Bucket: `${Config.BUCKET_NAME}/temp/photo`,
        Key: key,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
        ContentDisposition: `attachment; filename="${file?.name.replace(s3Regex, '').replace(/\s/g, '')}"`,
      };

      const upload = new AWS.S3.ManagedUpload({
        params,
        service: s3,
      });

      uploadPromises.push(upload.promise());
    }

    return Promise.all(uploadPromises);
  };

  /**
   * @description upload file image to S3
   * @param {File[]} files 파일
   * @param {string} bucketName
   * @param {string[]} keys
   * @returns {Promise<ManagedUpload.SendData[]>}
   */
  const uploadFilesToS3 = async (
    files: File[],
    bucketName: string,
    keys: string[],
  ): Promise<ManagedUpload.SendData[]> => {
    const uploadPromises: Promise<ManagedUpload.SendData>[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const key = keys[i];
      const params: AWS.S3.PutObjectRequest = {
        Bucket: `${Config.BUCKET_NAME}/temp/files`,
        Key: key,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
        ContentDisposition: `attachment; filename="${file?.name.replace(s3Regex, '').replace(/\s/g, '')}"`,
      };

      const upload = new AWS.S3.ManagedUpload({
        params,
        service: s3,
      });

      uploadPromises.push(upload.promise());
    }

    return Promise.all(uploadPromises);
  };

  /**
   * @description image change handler
   * @param {object} event React.syntheticEvent
   * @returns {Promise<void>}
   */
  const onChangeImages = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const target = event.currentTarget;
    const { files } = event.target;
    if (!files) return;
    // console.log('files', files);
    if (files?.length > 10) {
      target.value = '';
      dispatch(ActionModal.openModal({ modalType: 'alert_image_limit', isOpen: true }));
      return;
    }

    const fileSizeLimit = 20 * 1024 * 1024; // 20MB 제한 (바이트 단위)

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const saniTizedFileName = file.name.replace(s3Regex, '').replace(/\s/g, '');

      if (file.size > fileSizeLimit) {
        target.value = '';
        dispatch(ActionModal.openModal({ modalType: 'alert_image_size_limit', isOpen: true }));
        return;
      }

      const fileExt = saniTizedFileName.split('.').pop()?.toLowerCase();
      if (!fileExt || !['jpg', 'jpeg', 'png'].includes(fileExt)) {
        target.value = '';
        dispatch(ActionModal.openModal({ modalType: 'alert_image_extension_limit', isOpen: true }));
        return;
      }

      const encodedName = encodeURIComponent(saniTizedFileName);
      console.log('saniTizedFileName', saniTizedFileName);
      console.log('encodeName', encodedName);
      Object.defineProperty(file, 'name', {
        writable: true,
        value: encodedName,
      });
    }

    const fileList = Array.from(files);
    const keys = fileList?.map((file) => decodeURIComponent(file.name));
    const bucketName = 'photo_bucket_name';

    try {
      const uploadData = await uploadImagesToS3(fileList, bucketName, keys);
      uploadData?.forEach((upload, index) => {
        const fileType = 'photo';
        const orgName = decodeURIComponent(decodeURI(upload.Location.split('/').pop() || ''));
        const extensionRegex = /[^.]+$/;
        const extensionMatch = orgName.match(extensionRegex);
        let mimeType = '';

        if (extensionMatch) {
          const extension = extensionMatch[0].toLowerCase();
          if (extension === 'svg') {
            mimeType = 'image/svg+xml';
          } else if (extension === 'jpg') {
            mimeType = 'image/jpeg';
          } else {
            mimeType = `image/${extension}`;
          }
        }
        const imagePath = {
          file_type: fileType,
          mime_type: mimeType,
          org_name: orgName,
          url: upload.Location.replace(/%25/g, '%')
            .replace(/%3B/g, ';')
            .replace(/%3A/g, ':')
            .replace(/%2B/g, '+')
            .replace(/%2F/g, '/'),
        };
        console.log('imagePath===>', imagePath);
        setUploadedImages((prev: TUploadFile[]) => [...prev, imagePath]);
        target.value = '';
      });
    } catch (error) {
      console.error('Image upload failed:', error);
      target.value = '';
    }
  };

  /**
   * @description emoticon 수정
   * @param {string} src url
   * @returns {void}
   */
  const handleEmoji = (src: string): void => {
    setWritingState({
      ...writingState,
      emoticon: src,
    });
    setIsOpenEmoji(false);
    setEmojiOutSideClick(false);
  };

  /**
   * @description file change handler
   * @param {object} event React.syntheticEvent
   * @returns {Promise<void>}
   */
  const onChangeFiles = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const target = event.currentTarget;
    const { files } = event.target;
    if (!files) return;
    if (files?.length > 5) {
      target.value = '';
      dispatch(ActionModal.openModal({ modalType: 'alert_file_each_limit', isOpen: true }));
      return;
    }

    const fileSizeLimit = 200 * 1024 * 1024; // 20MB 제한 (바이트 단위)

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const saniTizedFileName = file.name.replace(s3Regex, '').replace(/\s/g, '');
      if (file.size > fileSizeLimit) {
        target.value = '';
        dispatch(ActionModal.openModal({ modalType: 'alert_file_size_limit', isOpen: true }));
        return;
      }

      const fileExt = file.name.split('.').pop()?.toLowerCase();
      console.log('fileExt===>', fileExt);
      if (!fileExt || !['pdf', 'ppt', 'pptx', 'doc', 'docx', 'hwp', 'hwpx', 'xlsx', 'xls'].includes(fileExt)) {
        target.value = '';
        dispatch(ActionModal.openModal({ modalType: 'alert_file_limit', isOpen: true }));
        return;
      }

      // const saniTizedFileName = file.name.replace(s3Regex, '').replace(/\s/g, '');
      const encodedName = encodeURIComponent(saniTizedFileName);
      Object.defineProperty(file, 'name', {
        writable: true,
        value: encodedName,
      });
    }

    const fileList = Array.from(files);
    const keys = fileList?.map((file) => decodeURIComponent(file.name));
    const bucketName = 'file_bucket_name';

    try {
      const uploadData = await uploadFilesToS3(fileList, bucketName, keys);
      uploadData?.forEach((upload, index) => {
        const fileType = 'file';
        const orgName = decodeURIComponent(decodeURI(upload.Location.split('/').pop() || ''));
        const extensionRegex = /[^.]+$/;
        const extensionMatch = orgName.match(extensionRegex);
        let mimeType = '';

        if (extensionMatch) {
          const extension = extensionMatch[0].toLowerCase();
          let _extension = extension;
          if (extension === 'hwpx') {
            _extension = 'vnd.hancom.hwpx';
          } else if (extension === 'hwp') {
            _extension = 'vnd.hancom.hwp';
          } else if (extension === 'doc' || extension === 'docx') {
            _extension = 'msword';
          } else if (extension === 'ppt' || extension === 'pptx') {
            _extension = 'vnd.ms-powerpoint';
          } else if (extension === 'xls') {
            _extension = 'vnd.ms-excel';
          }
          mimeType = `application/${_extension}`;
        }

        const filePath = {
          file_type: fileType,
          mime_type: mimeType,
          org_name: orgName,
          url: upload.Location.replace(/%25/g, '%')
            .replace(/%3B/g, ';')
            .replace(/%3A/g, ':')
            .replace(/%2B/g, '+')
            .replace(/%2F/g, '/'),
        };
        setUploadedFiles((prev) => [...prev, filePath]);
        target.value = '';
      });
    } catch (error) {
      console.error('Image upload failed:', error);
      target.value = '';
    }
  };

  /**
   * @description 선택된 이모티콘, 이미지 또는 파일 제거
   * @param {string} type 파일 타입( photo, file)
   * @param {number} idx
   * @returns {void}
   */
  const handleRemove = (type: string, idx?: string) => {
    // console.log('handle remove');
    if (type === 'emoticon') {
      setWritingState({
        ...writingState,
        emoticon: '',
      });
    }
    if (type === 'photo') {
      const _newUploadImages = uploadedImages.filter((k: IMixedKeyValue, j: number) => j !== Number(idx));
      setUploadedImages(_newUploadImages);
    }
    if (type === 'file') {
      const _newUploadFiles = uploadedFiles.filter((k: IMixedKeyValue, j: number) => j !== Number(idx));
      setUploadedFiles(_newUploadFiles);
    }
    setIsDelete(initDelete);
    setIsOpenEmoji(false);
  };

  /**
   * @description title textarea영역 enter key handler
   * @param {object} e React syntheticEvent
   * @returns {void}
   */
  const handleEnterTitle = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return;
    }
    autoResizeTextarea();
  };

  /**
   * @description 자동으로 textarea의 row가 늘어나도록 함.
   * @returns {void}
   */
  const autoResizeTextarea = (): void => {
    if (textTitle) {
      textTitle.style.height = 'auto';
      textTitle.style.height = `${textTitle.scrollHeight}px`;
    }

    if (textContent) {
      textContent.style.height = 'auto';
      textContent.style.height = 'auto';
      textContent.style.height = `${textContent.scrollHeight}px`;
    }
  };

  /**
   * @description 글쓰기 저장
   * @param {string} mode {write, edit}
   * @param {number} groupId? {groupId}
   * @returns {Promise<void>}
   */
  const handleSubmit = async (mode: string, groupId?: number): Promise<void> => {
    if (!content) {
      dispatch(ActionModal.openModal({ modalType: 'alert_dont_content', isOpen: true }));
      return;
    }
    // 사진 10개
    if (uploadedImages.length > 10) {
      dispatch(ActionModal.openModal({ modalType: 'alert_image_limit', isOpen: true }));
      return;
    }
    // 파일 5개
    if (uploadedFiles.length > 5) {
      dispatch(ActionModal.openModal({ modalType: 'alert_file_each_limit', isOpen: true }));
      return;
    }
    dispatch(ActionLoading.onLoad());

    submitBtnRef.current!.disabled = true;
    const params = {
      title, // 제목
      content, // 내용
      kind, // 게시판 구분( 자유게시판: 1500, 부동산 이슈: 1501, 그룹: null)
      latitude, // 위도
      longitude, // 경도
      addr, // 주소
      emoticon, // 이모티콘
      photos: uploadedImages.map((k) =>
        _.assign({
          file_type: 'photo',
          mime_type: k.mime_type,
          org_name: k.org_name,
          url: k.url,
        }),
      ),
      files: uploadedFiles.map((k) =>
        _.assign({
          file_type: 'file',
          mime_type: k.mime_type,
          org_name: k.org_name,
          url: k.url,
        }),
      ),
    };

    const paramsShare = {
      title, // 제목
      content, // 내용
      kind, // 게시판 구분( 자유게시판: 1500, 부동산 이슈: 1501, 그룹: null)
      latitude, // 위도
      longitude, // 경도
      addr, // 주소
      emoticon, // 이모티콘
      photos: uploadedImages?.map((k) =>
        _.assign({
          file_type: 'photo',
          mime_type: k.mime_type,
          org_name: k.org_name,
          url: k.url,
        }),
      ),
      files: uploadedFiles?.map((k) =>
        _.assign({
          file_type: 'file',
          mime_type: k.mime_type,
          org_name: k.org_name,
          url: k.url,
        }),
      ),
      share_post_id: shareOrigin !== 'group' && sharedId ? Number(sharedId) : null, // 전체게시판에서의 게시물 ID
      share_group_post_id:
        shareOrigin === 'group' && state
          ? Number(state?.id)
          : shareOrigin === 'group' && localStorage.getItem('shareData')
          ? Number(JSON.parse(localStorage.getItem('shareData')!).id)
          : null, // 모임게시판에서의 공유할 게시물  post ID
      share_title: shareTitle,
      share_content: shareContent,
    };
    const body = JSON.stringify(params);
    const bodyShare = JSON.stringify(paramsShare);
    // console.log('bodyShare===>', bodyShare);
    try {
      if (mode === 'write') {
        const res =
          boardType === 'group'
            ? await network().groupPost().postGroupPost(`/groups/${groupId}/posts`, body)
            : await network().post().postBoardPost(`/posts`, body);
        console.log('res 글쓰기 등록', res);
        const _data = res.data;
        if (res?.message === '' && _data.id) {
          console.log('success==>', _data);
          if (boardType === 'group') {
            navigate(`/mygroup/group/${groupId}`);
          } else {
            if (import.meta.env.MODE === 'localdev') {
              navigate(`/allboard?type=${boardType}`);
            } else {
              moveWritingTab(`type=${boardType}`);
              onClose();
            }
          }
          dispatch(ActionLoading.offLoad());
          setToastMessage({
            duration: 2000,
            content: '게시글이 등록되었습니다.',
            type: 'message',
          });
        }
        submitBtnRef.current!.disabled = false;
      } else if (mode === 'edit') {
        const res =
          boardType === 'group'
            ? await network().groupPost().putGroupPost(`/groups/${groupId}/posts/${postId}`, body)
            : await network().post().putBoardPost(`/posts/${postId}`, body);
        console.log('res 글쓰기 수정', res);
        const _data = res.data;
        if (res?.message === '' && _data.id) {
          // console.log('success==>', _data);
          if (boardType === 'group') {
            navigate(`/mygroup/group?id=${groupId}`);
          } else {
            if (import.meta.env.MODE === 'localdev') {
              navigate(`/allboard?type=${boardType}&postId=${postId}`);
            } else {
              moveWritingTab(`type=${boardType}`);
              onClose();
            }
          }
          dispatch(ActionLoading.offLoad());
          setToastMessage({
            duration: 2000,
            content: '게시글이 수정되었습니다.',
            type: 'message',
          });
        }
        submitBtnRef.current!.disabled = false;
      } else if (mode === 'share') {
        const res = await network().groupPost().postGroupPostShare(`/groups/${groupId}/posts/share`, bodyShare);
        console.log('res 공유하기', res);
        const _data = res.data;
        if (res?.message === '' && _data.id) {
          // console.log('success==>', _data);
          navigate(`/mygroup/group/${groupId}`);
          dispatch(ActionLoading.offLoad());
          setToastMessage({
            duration: 2000,
            content: '게시글이 공유되었습니다.',
            type: 'message',
          });
        }
        localStorage.getItem('shareData') && localStorage.removeItem('shareData');
        submitBtnRef.current!.disabled = false;
      }
    } catch (err) {
      console.error('error', err);
      dispatch(ActionLoading.offLoad());
      submitBtnRef.current!.disabled = false;
    }
  };

  /**
   * @description header back key handler
   * @returns {void}
   */
  const handleBackKey = (): void => {
    if (title || content || emoticon || uploadedImages.length || uploadedFiles.length) {
      dispatch(ActionModal.openModal({ modalType: 'confirm_cancel_writing_post', isOpen: true }));
    } else {
      if (import.meta.env.MODE === 'localdev') {
        navigate(-1);
      } else {
        if (!window.history.state || window.history.state.idx === 0) {
          onClose();
        } else {
          navigate(-1);
        }
      }
      dispatch(ActionUser.resetHistoryPath());
    }
  };

  // console.log('boardType', boardType);
  // console.log('postId==>', postId);
  // console.log('writingState===>', writingState);
  // console.log('uploadedImages==>', uploadedImages);
  // console.log('uploadedFiles==>', uploadedFiles);
  // console.log('state==>', state);
  // console.log('shareBoardDate==>', shareBoardDate);
  // console.log('isOpenEmoji', isOpenEmoji);
  // console.log('isDelete', isDelete);
  // console.log('shareData', shareData);

  const isIphone = /iPhone/i.test(navigator.userAgent);

  const [paddingBottom, setPaddingBottom] = useState(isIphone ? '344px' : '0px');

  const handleFocus = () => {
    setPaddingBottom('344px'); // 키보드가 나타날 때 예상되는 높이
  };

  const handleBlur = () => {
    setPaddingBottom('0px'); // 키보드가 사라질 때
  };

  useEffect(() => {
    if (isIphone) {
      // 모든 입력 필드에 이벤트 리스너 추가
      const inputs = document.querySelectorAll('input, textarea, [contenteditable]');
      inputs.forEach((input) => {
        input.addEventListener('focus', handleFocus);
        input.addEventListener('blur', handleBlur);
      });

      return () => {
        inputs.forEach((input) => {
          input.removeEventListener('focus', handleFocus);
          input.removeEventListener('blur', handleBlur);
        });
      };
    }
  }, [isIphone]); // isIphone이 바뀌면 다시 초기화

  return (
    <>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={handleBackKey}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>
        <div className={Styles.title}>{mode! === 'edit' ? '글수정' : mode === 'share' ? '공유하기' : '글쓰기'}</div>
        <div className={cn(Styles.right, CommonStyles.right)}>
          <button
            ref={submitBtnRef}
            className={cn(Styles['text-btn'], !content && Styles.disabled, !content && CommonStyles.disabled)}
            disabled={!content}
            onClick={() => handleSubmit(mode!, Number(groupId))}
          >
            완료
          </button>
        </div>
      </header>
      <div /* id="wrapper" */>
        <div
          className={cn(
            CommonStyles.content,
            CommonStyles['post-write'],
            CommonStyles['post-area'],
            Config.SERVICE_USE_SETTING.hasGNG === 'enabled' && CommonStyles.hasGNB,
          )}
        >
          <div className={cn(CommonStyles['divide-box'], CommonStyles['post-area'])}>
            <p>{boardType === 'free-board' || boardType === 'issue' ? '전체게시판' : '내가 가입한 모임'}</p>
            <p className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
              {boardType === 'free-board' ? '자유게시판' : boardType === 'issue' ? '부동산이슈' : groupName}
            </p>
          </div>
          <div className={CommonStyles['write-div']}>
            <div className={CommonStyles['title-form']}>
              <textarea
                id="autoTitle"
                ref={titleRef}
                name="title"
                value={title || ''}
                onChange={(e) => handleValueChange(e)}
                placeholder="제목추가(선택)"
                onKeyDown={(e) => handleEnterTitle(e)}
                onKeyUp={autoResizeTextarea}
              />
              <span className={CommonStyles.byte}>{title?.length || 0}/50</span>
            </div>
            <div>
              <div className={cn(CommonStyles['content-div'], emoticon && CommonStyles['has-emoji'])}>
                <textarea
                  id="autoContent"
                  ref={contentRef}
                  name="content"
                  value={content || ''}
                  onChange={(e) => handleValueChange(e)}
                  placeholder="새로운 소식을 남겨보세요."
                  style={{ boxSizing: 'border-box' }}
                  // onKeyDown={autoResizeTextarea}
                  // onKeyUp={autoResizeTextarea}
                />
                <div className={CommonStyles['attached-div']} ref={attachedRef}>
                  {/*모임에 컨텐츠공유 // 전체게시판 컨텐츠, 모임의 컨텐츠 */}
                  {/* {mode === 'share' && state && (
                    <button className={CommonStyles['share-box']}>
                      <div className={CommonStyles.summary}>
                        {shareTitle && (
                          <div className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>{shareTitle}</div>
                        )}
                        <div className={cn(CommonStyles.tit, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {shareContent}
                        </div>
                        {state.cate?.label ? (
                          <div className={CommonStyles.txt}>[전체게시판] {state.cate.label || '-'}</div>
                        ) : (
                          <div className={CommonStyles.txt}>[모임] {groupName || '-'}</div>
                        )}
                      </div>
                    </button>
                  )} */}
                  {/** 모임에서 컨텐츠 공유할때 */}
                  {mode === 'share' && localStorage.getItem('shareData') && (
                    <button className={CommonStyles['share-box']}>
                      <div className={CommonStyles.summary}>
                        {shareTitle && (
                          <div className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {shareTitle}
                          </div>
                        )}
                        <div className={cn(CommonStyles.tit, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {shareContent}
                        </div>
                        {JSON.parse(localStorage.getItem('shareData')!).cate?.label ? (
                          <div className={CommonStyles.txt}>
                            [전체게시판] {JSON.parse(localStorage.getItem('shareData')!).cate.label || '-'}
                          </div>
                        ) : (
                          <div className={CommonStyles.txt}>[모임] {groupName || '-'}</div>
                        )}
                      </div>
                    </button>
                  )}

                  {/**전체게시판에서 컨텐츠 공유할때 */}
                  {mode === 'share' && sharedId && shareData?.shareContent && (
                    <button className={CommonStyles['share-box']}>
                      <div className={CommonStyles.summary}>
                        {shareTitle && (
                          <div className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                            {shareTitle}
                          </div>
                        )}
                        <div className={cn(CommonStyles.tit, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          {shareContent}
                        </div>
                        <div className={CommonStyles.txt}>[전체게시판] {shareData?.shareCate?.label || '-'}</div>
                      </div>
                    </button>
                  )}

                  {/** 이미지영역 */}
                  {uploadedImages.length > 0 && (
                    <div className={CommonStyles['img-box']}>
                      {uploadedImages
                        ?.map((item: TUploadFile, index: number) => (
                          <button
                            key={index}
                            className={CommonStyles['img-item']}
                            onTouchStart={() =>
                              setIsDelete({
                                deleteType: 'photo',
                                deleteTarget: String(index),
                              })
                            }
                          >
                            <img
                              src={item.url?.replace(`${Config.S3_URL}`, `${Config.CDN_URL}`).replace(/%/g, '%25')}
                              alt={item.org_name || ''}
                            />
                          </button>
                        ))
                        .reverse()}
                    </div>
                  )}

                  {/** 파일영역 */}
                  {uploadedFiles.length > 0 && (
                    <>
                      {uploadedFiles?.map((item: IMixedKeyValue, index: number) => (
                        <button
                          key={index}
                          className={CommonStyles['file-box']}
                          onTouchStart={() =>
                            setIsDelete({
                              deleteType: 'file',
                              deleteTarget: String(index),
                            })
                          }
                        >
                          <i className={CommonStyles['icon-file']} />
                          <div className={CommonStyles.summary}>
                            <div className={CommonStyles.txt}>
                              <span className={cn(CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                                {item.org_name.length <= 40 ? `${item.org_name}` : `${item.org_name?.slice(0, 37)}...`}
                              </span>
                              {getFileExtentions(item.org_name)}
                            </div>
                          </div>
                        </button>
                      ))}
                    </>
                  )}

                  {/** 지도영역 */}
                  {Config.SERVICE_USE_SETTING.WRITING_MAP === 'enabled' && latitude && longitude && addr && (
                    <button className={CommonStyles['map-box']}>
                      <div className={CommonStyles.map} />
                      <div className={CommonStyles.summary}>
                        <div className={cn(CommonStyles.tit, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          코엑스
                        </div>
                        <div className={cn(CommonStyles.tit, CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
                          서울특별시 강남구 역삼동 영동대로 513
                        </div>
                      </div>
                    </button>
                  )}
                  {/** 이모티콘영역 */}
                  {emoticon && (
                    <button
                      className={CommonStyles['emoji-box']}
                      onTouchStart={() =>
                        setIsDelete({
                          deleteType: 'emoticon',
                          deleteTarget: writingState.emoticon!,
                        })
                      }
                    >
                      <img src={`/images/${emoticon}`} alt="이모지 아이콘" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{ paddingBottom: paddingBottom }}
              className={
                isOpenEmoji && !emojiOutSideClick
                  ? cn(CommonStyles['attached-function'], CommonStyles['d-none'])
                  : CommonStyles['attached-function']
              }
              ref={selectRef}
              //웹 테스트시에 하단 영역이 생겨서 textarea의 일부가 가려져서 주석처리
              // style={Config.SERVICE_USE_SETTING.hasGNG === 'enabled' ? { bottom: 49 } : {}}
            >
              {/* 
              <div
                ref={selectRef}
                style={
                  isOpenEmoji && !emojiOutSideClick
                    ? { display: 'none' }
                    : {
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                }
              > 
              */}
              <button type="button" onClick={() => onClickImageUpload()}>
                <i className={CommonStyles['icon-album']} />
                사진
                <input
                  ref={imageInput}
                  type="file"
                  name="image"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={onChangeImages}
                />
              </button>
              <button
                type="button"
                onClick={() => {
                  setIsOpenEmoji(true);
                  setEmojiOutSideClick(false);
                }}
              >
                <i className={CommonStyles['icon-emoji']} />
                이모티콘
              </button>
              {Config.SERVICE_USE_SETTING.WRITING_MAP === 'enabled' && (
                <button>
                  <i className={CommonStyles['icon-map']} />
                  지도
                </button>
              )}
              <button onClick={() => onClickFileUpload()}>
                <i className={CommonStyles['icon-file']} />
                파일
                <input ref={fileInput} type="file" name="image" accept="*/*" multiple hidden onChange={onChangeFiles} />
              </button>
            </div>
            <>
              {isOpenEmoji && !isDelete.deleteType && !emojiOutSideClick && (
                <div ref={emojiRef} className={CommonStyles['emoji-select']}>
                  {Config.EMOJIICON_LIST?.map((item: string) => {
                    return (
                      <button
                        key={item}
                        type="button"
                        onClick={() => handleEmoji(`emoji_${item}.svg`)}
                        className={cn(CommonStyles.emoji, CommonStyles[item])}
                      />
                    );
                  })}
                </div>
              )}
              {isDelete.deleteType && (
                <div ref={deleteRef} className={cn(CommonStyles['attached-function'], CommonStyles['attached-delete'])}>
                  <button className={CommonStyles['co-red']} onClick={() => handleRemove(deleteType, deleteTarget)}>
                    <i className={CommonStyles['icon-trash-red']} /> 삭제
                  </button>
                </div>
              )}
            </>
          </div>
          {/* </div> */}
        </div>
        <div id="make-scrollable"></div>
      </div>
    </>
  );
};
export default Writing;
