import { RootState, useAppDispatch } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import network from 'utils/network';
import { IArea } from '<broker>';
import { offLoad, onLoad } from 'redux/slices/loading';

import * as ActionModal from 'redux/slices/modal';
import useToast from 'hook/useToast';
import Toast from 'components/common/Toast/Toast';

import cn from 'classnames';
// styles
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';
import Styles from 'styles/header.module.css';

const BrokerRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setToastMessage } = useToast();
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const { isOpen = false } = useSelector((state: RootState) => state.toast);

  //지역선택 (시도)
  const handleSearchSido = async () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'broker_bottom_area',
        isOpen: true,
        data: { handleSelectArea: handleSelectArea },
      }),
    );
  };

  //지역선택 (구군)
  const handleSearchGugun = async () => {
    if (selectSidoObj) {
      dispatch(
        ActionModal.openModal({
          modalType: 'broker_bottom_area',
          isOpen: true,
          data: { sido: selectSidoObj, handleSelectArea: handleSelectArea },
        }),
      );
    }
  };

  //지역셋팅
  const [selectSidoObj, setSelectSidoObj] = useState<IArea>();
  const [selectSido, setSelectSido] = useState<string>('시/도');
  const [selectGugun, setSelectGugun] = useState<string>('시/군/구');
  const handleSelectArea = (sido: IArea, gugun: IArea) => {
    setSelectSido(sido.name);
    setSelectGugun(gugun.name);
    setSelectSidoObj(sido);
  };

  //개설등록번호
  const [jurirno, setJurirno] = useState<string>('');
  //개설등록번호 onchange
  const handleJurirNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setJurirno(e.target.value);
  };

  //개설등록번호 번호형식
  const inputJurirNumberRef = useRef<HTMLInputElement>(null);

  //등록가능여부
  useEffect(() => {
    if (selectSido && selectGugun && jurirno) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }
  }, [selectSido, selectGugun, jurirno]);

  //등록번호 요청하기
  const handleRegisterConfirm = async () => {
    if (selectSido && selectGugun && jurirno) {
      dispatch(onLoad());
      await network()
        .broker()
        .postCompanyRegister({
          jurirno: jurirno,
          addr: selectSido + ' ' + selectGugun,
        })
        .then((res) => {
          dispatch(offLoad());
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: {
                txt: '개설등록번호 등록 요청이 완료되었습니다.',
                actionNavi: () => {
                  if (window.webkit) {
                    window.webkit.messageHandlers.moveTab.postMessage(JSON.stringify({ tabIndex: 0, parameter: '' }));
                    window.webkit.messageHandlers.close.postMessage();
                  } else if (window.Android) {
                    window.Android.moveTab(JSON.stringify({ tabIndex: 0, parameter: '' }));
                    window.Android.close();
                  }
                },
              },
            }),
          );
        })
        .catch((err) => {
          dispatch(offLoad());
          dispatch(
            ActionModal.openModal({
              modalType: 'broker_alert',
              isOpen: true,
              data: { txt: err.response.data.message },
            }),
          );
        });
    }
  };

  const setJurirnoClear = () => {
    setJurirno('');
    inputJurirNumberRef.current?.focus();
  };

  //헤더 뒤로가기 예외
  const historyBack = () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'broker_confirm',
        isOpen: true,
        data: {
          txt: '개설등록번호 등록 요청을 취소하시겠습니까?',
          actionNavi: () => {
            if (window.webkit) {
              window.webkit.messageHandlers.moveTab.postMessage(JSON.stringify({ tabIndex: 0, parameter: '' }));
              window.webkit.messageHandlers.close.postMessage();
            } else if (window.Android) {
              window.Android.moveTab(JSON.stringify({ tabIndex: 0, parameter: '' }));
              window.Android.close();
            }
          },
        },
      }),
    );
  };

  return (
    <>
      <header>
        <div className={cn(Styles.left, ResetStyles.left)}>
          <button type="button" onClick={historyBack}>
            <i className={cn(CommonStyles['icon-back'], Styles['icon-back'])} />
          </button>
        </div>
        <div className={Styles.title}>중개사 인증</div>
        <div className={cn(Styles.right, CommonStyles.right)}></div>
      </header>
      <div className={CommonStyles.container}>
        <div className={cn(CommonStyles.content, CommonStyles.certify)}>
          <div className={cn(CommonStyles['content-div'], CommonStyles['search'])}>
            <p className={CommonStyles['page-tit']}>개설등록번호 등록 요청하기</p>
            <div className={CommonStyles['gray-box']}>
              <p>
                개설등록번호가 조회되지 않아서 중개사무소 검색이 어려우신가요?{' '}
                <b className={cn(ResetStyles['fw-b'], CommonStyles['co-bl'])}>개설등록번호 등록을 요청</b>하시면, 저희가
                확인하고 <b className={cn(ResetStyles['fw-b'], CommonStyles['co-bl'])}>알림톡</b>으로 안내드리겠습니다!
              </p>
            </div>
            <div className={CommonStyles['dropdown-wrap']}>
              <button
                className={cn(CommonStyles.dropdown, selectSido !== '시/도' && CommonStyles.selected)}
                onClick={handleSearchSido}
              >
                <span>{selectSido}</span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
              <button
                className={cn(CommonStyles.dropdown, selectGugun !== '시/군/구' && CommonStyles.selected)}
                onClick={handleSearchGugun}
              >
                <span>{selectGugun}</span>
                <i className={CommonStyles['icon-arrow-down-gr']}></i>
              </button>
            </div>
            <div className={cn(CommonStyles['input-area'], CommonStyles['clear-has'])}>
              <input
                type="text"
                placeholder="개설등록번호"
                ref={inputJurirNumberRef}
                value={jurirno || ''}
                onChange={(e) => handleJurirNumber(e)}
              />
              {jurirno.length > 0 && <button className={CommonStyles.delete} onClick={() => setJurirno('')}></button>}
            </div>
          </div>
        </div>
      </div>
      <div className={cn(CommonStyles['btn-wrap'], CommonStyles['fixed'])}>
        <button
          className={cn(CommonStyles['btn'], CommonStyles['lg'], !isConfirm ? CommonStyles.disabled : '')}
          onClick={handleRegisterConfirm}
        >
          요청하기
        </button>
      </div>
      {isOpen && <Toast />}
    </>
  );
};

export default BrokerRequest;
