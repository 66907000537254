import cn from 'classnames';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import CommonStyles from 'styles/common.module.css';
import * as APIS from 'api/group';
import { IMixedKeyValue } from '<modal>';
import { RootState, useAppDispatch, useAppSelector } from 'redux/store';
import { openModal } from 'redux/slices/modal';
import React from 'react';
import styled from 'styled-components';
import useScroll from 'hook/useScroll';
import ListLoader from 'components/common/Loader/ListLoader';
import moment from 'moment';
import { convertArea, numberWithCommas } from 'utils';
import { cloneDeep } from 'lodash';
import useToast from 'hook/useToast';
import { useNavigate } from 'react-router-dom';
import Input from 'components/common/Input/Input';
import ScrollLoader from 'components/common/Loader/ScrollLoader';
import { isComplex } from 'helpers/offering-helper';

interface Props {
  groupId?: string;
  setScrollHeight: any;
  scrollHeight: number;
}

const PAGE_SIZE = 5;

/**
 * @description 모임 - 공유매물 목록
 * @Class
 * @category Pages
 * @subcategory 모임
 * @component
 * @returns {JSX.Element}
 */
const GroupShareList = (props: Props) => {
  const dispatch = useAppDispatch();
  const { groupId = '', setScrollHeight, scrollHeight = 0 } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  // 새로고침 상태에 따라서 리스트 재조회
  const { load = false } = useAppSelector((state: RootState) => state.refresh);

  // 마지막 페이지 체크
  const lastPage = useRef<boolean>(false);
  // api중복으로 날라가는걸 방지하기 위한 state
  const [loading, setLoading] = useState(false);

  const [filterInfo, setFilterInfo] = useState<IMixedKeyValue>({});
  // 공유 매물 리스트
  const [shareList, setShareList] = useState<IMixedKeyValue[]>([]);
  // 페이징 정보
  const [page, setPage] = useState({
    page: 1,
    key: new Date().getTime(),
  });

  // 매물 타입
  const [typeList, setTypeList] = useState<IMixedKeyValue[]>([]);
  // 매물 유무 체크
  const [isFirst, setIsFirst] = useState<boolean>(false);
  // 매물 갯수
  const [cnt, setCnt] = useState<any>(null);
  // 검색어로 검색했는지 여부
  const [isSearch, setIsSearch] = useState<boolean>(false);
  // 처음 페이지 로드시 loading state
  const [firstLoader, setFirstLoader] = useState<boolean>(false);

  useEffect(() => {
    setFirstLoader(true);
  }, []);

  // 페이지 정보가 바뀔 때 공동중개 매물 재조회
  useEffect(() => {
    const f = async () => {
      fetchShareList();
    };

    f();
  }, [page]);

  // 새로고침 할 때 리스트 재조회
  useEffect(() => {
    if (load) {
      setShareList([]);
      setPage({
        page: 1,
        key: new Date().getTime(),
      });
    }
  }, [load]);

  // setter된 필터가 바뀔 떄 공동중개 매물 검색
  useEffect(() => {
    if (Object.keys(filterInfo).length > 0) {
      setShareList([]);
      // 설정된 필터의 정보가 바뀔 때 => 페이지 정보를 초기화 시켜서 공동중개 매물 재조회
      setPage({
        page: 1,
        key: new Date().getTime(),
      });
    }
  }, [filterInfo]);

  /**
   * @description 모임 공유매물 목록 조회 api
   * @returns {Promise<void>}
   */
  const fetchShareList = async () => {
    if (groupId === '') return;
    try {
      setLoading(true);
      const queryString = `/offers/search/${groupId}`;
      //const queryString = `/offers/search`;
      let payload: IMixedKeyValue = {
        page: page.page,
        page_size: PAGE_SIZE,
        sort: 2,
        type: 'share',
      };

      if (searchValue.length > 0) {
        payload = {
          ...payload,
          keyword: searchValue,
        };
      }
      const res = await APIS.postShareList(queryString, payload);

      const {
        data = [],
        next_page_url,
        total = 0,
      }: { data: IMixedKeyValue[]; next_page_url: any; total: any } = res || {};

      // 마지막 페이지 체크
      if (next_page_url === null) {
        lastPage.current = true;
      }

      if (data.length === 0 && !isFirst) {
        setIsFirst(true);
        setShareList([]);
      } else {
        if (data.length > 0) {
          if (isSearch) {
            setIsFirst(false);
          }

          if (!isFirst) {
            setIsFirst(true);
          }

          setShareList((prev) => [...prev, ...data]);
        } else {
          if (!isSearch) {
            setIsSearch(true);
          } else {
            setIsSearch(false);
          }
          setShareList([]);
        }
      }

      setCnt(total);
    } catch (err) {
      lastPage.current = true;
      console.log(err, 'err');
    } finally {
      if (firstLoader) {
        setFirstLoader(false);
      }
      setLoading(false);
    }
  };

  // 공유 매물 검색
  const handleClickSearch = (e: any) => {
    if (e.key === 'Enter') {
      fetchShareList();
    }
  };

  // 필터 sheet 호출
  const handleClickFilter = () => {
    dispatch(
      openModal({
        isOpen: true,
        modalType: 'share_filter_full',
        action: (payload: IMixedKeyValue) => setFilterInfo({ ...payload }),
      }),
    );
  };

  /**
   * @description 구합니다 무한 스크롤 observe
   */
  const ref = useScroll(async (entry, observer) => {
    observer.unobserve(entry.target);
    // 마지막 페이지가 아니면 그리고 데이터 조회가 아닐 떄
    if (!lastPage.current && !loading && shareList.length > 0) {
      setPage({
        page: page.page + 1,
        key: new Date().getTime(),
      });
    }
  });

  /**
   * @description 모임 공유매물 목록
   */
  const memoShareList = useMemo(() => {
    return shareList;
  }, [shareList]);

  const navigate = useNavigate();

  return (
    <div
      id="container_wrap"
      onScroll={() => {
        const element = document.getElementById('container_wrap');
        if (element) {
          setScrollHeight(element.scrollTop);
        }
      }}
      style={{ height: shareList.length >= 0 ? '100%' : '0%', overflowY: 'auto' }}
    >
      {/* <div className={CommonStyles['region-filter']}>
        <div className={CommonStyles['selected-option']}>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['sticky'])}>초기화</span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            빌라/연립
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            매매
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            10억~20억
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            화장실
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            빌라/연립
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            매매
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            10억~20억
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
          <span className={cn(CommonStyles['btn'], CommonStyles['sm'], CommonStyles['line'])}>
            화장실
            <button>
              <i className={CommonStyles['icon-close']}></i>
            </button>
          </span>
        </div>
        <div className={CommonStyles['search-box']}>
          <Input
            className={cn(CommonStyles['search-input'])}
            style={{ width: '100%' }}
            mergeClass={true}
            clear={true}
            value={searchValue}
            handleClickClear={() => setSearchValue('')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            placeholder="단지명, 매물특징으로 검색해보세요"
            onKeyDown={() => handleClickSearch()}
          />
          <button className={CommonStyles['filter-btn']} onClick={handleClickFilter}>
            <i className={CommonStyles['icon-filter']}></i>
          </button>
        </div>
      </div> */}
      {/* {memoShareList.length === 0 && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          공유매물이 없습니다.
        </div>
      )} */}
      <div className={CommonStyles['region-filter']}>
        <div className={CommonStyles['search-box']}>
          <Input
            className={cn(CommonStyles['search-input'])}
            style={{ width: '100%' }}
            mergeClass={true}
            clear={true}
            value={searchValue}
            handleClickClear={() => setSearchValue('')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            placeholder="단지명, 매물특징으로 검색해보세요"
            onKeyDown={(e: any) => handleClickSearch(e)}
          />
        </div>
      </div>
      {cnt !== null && memoShareList.length !== 0 && (
        <div className={CommonStyles['card-count-txt']}>
          총 <b className={cn(CommonStyles['co-dorg'], CommonStyles['fw-b'])}>{cnt > 1000 ? '1000+' : cnt}</b>개의
          매물이 있습니다.
        </div>
      )}
      {firstLoader && memoShareList.length === 0 && !isFirst && (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <ScrollLoader />
        </div>
      )}
      {memoShareList.length > 0 && (
        <div className={CommonStyles['sale-card-div']} style={{ marginTop: '0.75rem' }}>
          {memoShareList.map((item, index: number) => {
            return (
              <React.Fragment key={index}>
                <ShareCard shareData={item} setList={setShareList} index={index} list={shareList} />
                {index - 1 === shareList.length - 2 && <Target ref={ref} />}
                {index - 1 === shareList.length - 2 && loading && (
                  <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <ListLoader />
                  </div>
                )}
              </React.Fragment>
            );
          })}
          {scrollHeight > 0 && shareList.length >= 2 && <div style={{ height: '100px', width: '100%' }} />}
        </div>
      )}
      {isFirst && memoShareList.length === 0 && !isSearch && (
        // <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className={CommonStyles['sale-card-div']} style={{ width: '100%' }}>
          <div className={CommonStyles['no-data']}>
            <p>
              공유매물을 등록해서
              <br />
              공동중개를 진행해보세요!
            </p>
            <button
              className={cn(CommonStyles['btn'], ['lg'])}
              onClick={() => {
                navigate('/coagency-register');
              }}
            >
              등록하러 가기
            </button>
          </div>
        </div>
        // </div>
      )}
      {isSearch && memoShareList.length === 0 && (
        <div className={CommonStyles['sale-card-div']}>
          <div className={CommonStyles['no-data']}>
            <p>
              제공 및 조건에 맞는 매물을 찾지 못했어요.
              <br />
              다시 검색해보세요!
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const ShareCard = ({
  shareData = {},
  setList,
  index,
  list,
}: {
  list: IMixedKeyValue[];
  shareData: IMixedKeyValue;
  index: number;
  setList: (list: IMixedKeyValue[]) => void;
}) => {
  const {
    my_offer,
    created_at,
    offer_type_name,
    addr,
    ho_nm,
    price_info = {},
    memo,
    floor2,
    room_bath,
    comments_count = 0,
    area_info,
    edit_req = '',
    status = '',
    status_deal = '',
    bookmark_id = null,
    id = null,
    edit_req_date = '',
    updated_at = '',
    company_name = '',
    offerings_gbn,
    addr_dong,
  } = shareData;

  const navigate = useNavigate();

  const roomData = room_bath !== '-' ? room_bath.split('/') : null;

  const { setToastMessage } = useToast();

  // 중복클릭 방지
  const clickRef = useRef<boolean>(false);

  const status_color =
    status_deal === 'normal'
      ? 'state-success'
      : status_deal === 'hold'
      ? 'state-hold'
      : status_deal === 'complete'
      ? 'state-complete'
      : 'state';

  //my_offer 1 내 매물 / 0 공유매물
  //deal_name 전세 / 매매 / 월세

  // 관심매물 등록
  const handleClickAddBookmark = async (id: number, bookmark_id: number) => {
    if (id === null) return;
    if (clickRef.current) return;
    // 북마크 등록
    try {
      clickRef.current = true;
      if (bookmark_id === null) {
        const res = await APIS.postShareBookmark(`/offers/${id}/bookmark`);

        if (res.id) {
          const parseList: IMixedKeyValue[] = cloneDeep(list);
          parseList[index]['bookmark_id'] = res.id;
          setList([...parseList]);

          setToastMessage({
            duration: 2000,
            content: '관심매물로 등록되었습니다.',
            type: 'message',
          });
        }
      } else {
        // 북마크 해제
        const payload = {
          ids: [bookmark_id],
        };
        const res = await APIS.postShareUnBookmark(`/offers/bookmark`, payload);
        if (res.length === 0) {
          const parseList: IMixedKeyValue[] = cloneDeep(list);
          parseList[index]['bookmark_id'] = null;
          setList([...parseList]);

          setToastMessage({
            duration: 2000,
            content: '관심매물에서 해제되었습니다.',
            type: 'message',
          });
        }
      }
    } catch (err) {
      console.log(err, 'err');
    } finally {
      clickRef.current = false;
    }
  };

  // 구합니다 상세
  const movePageDetail = (e: any, id: number) => {
    if (id === null) return;
    if (e.target.id.includes('bookmark')) return;
    navigate(`/sharedetail/${id}`);
  };

  return (
    <div className={CommonStyles['sale-card']} style={{ cursor: 'pointer' }} onClick={(e) => movePageDetail(e, id)}>
      <div className={CommonStyles['state-summary']}>
        <div className={cn(CommonStyles['divide-box'], CommonStyles['limit-line'], CommonStyles['limit-line1'])}>
          {(my_offer === 1 || my_offer === true) && (
            <p className={cn(CommonStyles['co-dorg'], CommonStyles['has-icon'])}>
              <i className={CommonStyles['icon-mine']}></i>내 매물
            </p>
          )}
          {updated_at !== '' ? (
            <>
              <p className={CommonStyles['date']}>
                수정일 {moment(updated_at).format('YYYY.MM.DD')}{' '}
                {`(최초 작성일 ${moment(created_at).format('YYYY.MM.DD')})`}
              </p>
            </>
          ) : (
            <p className={CommonStyles['date']}>최초 작성일 {moment(created_at).format('YYYY.MM.DD')}</p>
          )}
          {my_offer === 0 && <p className={CommonStyles['date']}>{company_name}</p>}
        </div>
        {/* {status && <p className={cn(CommonStyles['state'], CommonStyles[status_color])}>{status}</p>} */}
      </div>
      {offer_type_name && <div className={CommonStyles['type']}>[{offer_type_name}]</div>}
      <div className={CommonStyles['address']}>
        {isComplex(offerings_gbn) ? addr : addr_dong} {ho_nm && `${ho_nm}호`}
      </div>
      <div className={CommonStyles['information']}>
        <p>
          {area_info?.[0]?.value !== null &&
            `[${area_info?.[0]?.name}]${convertArea(+area_info?.[0]?.value || 0, 'square')}평`}{' '}
          {area_info?.[1]?.value !== undefined &&
            `[${area_info?.[1]?.name}]${convertArea(+area_info?.[1]?.value || 0, 'square')}평`}
        </p>
        {room_bath !== '-' && <p>{`방${roomData?.[0] || '-'}욕실${roomData?.[1] || '-'}`}</p>}
        {floor2 !== '-' && <p>{floor2}층</p>}
      </div>
      <div className={CommonStyles['price']} style={{ wordBreak: 'break-all' }}>
        <b className={CommonStyles['co-bl']}>{price_info['deal_name']}</b>
        <b className={CommonStyles['co']}>
          {Object.keys(price_info['price']).length > 0 &&
            Object.keys(price_info['price']).map((el: any, index) => {
              return `${numberWithCommas(price_info['price'][el])}${
                index === 0 && Object.keys(price_info['price']).length > 1 ? '/' : ''
              }`;
            })}
          만원
        </b>
      </div>
      {edit_req_date !== '' && edit_req_date !== null && my_offer === 0 && (
        <p className={CommonStyles['warning-txt']}>
          <i className={CommonStyles['icon-warning-red']}></i>타 중개사의 수정요청을 받았습니다.
        </p>
      )}
      {memo && <div className={cn(CommonStyles['description'], CommonStyles['limit-line1'])}>{memo}</div>}
      {/* {edit_req === 1 ||
        (edit_req === '1' && (
          <p className={CommonStyles['warning-txt']}>
            <i className={CommonStyles['icon-warning-red']}></i>타 중개사의 수정요청을 받았습니11다.
          </p>
        ))} */}
      <div className={cn(CommonStyles['function-div'])}>
        <button>
          <i className={CommonStyles['icon-comment']}></i>
          {comments_count! >= 100 ? `+99` : comments_count}
        </button>
        <button
          id="bookmark"
          className={cn(CommonStyles['bookmark'], bookmark_id && CommonStyles['on'])}
          onClick={() => {
            handleClickAddBookmark(id, bookmark_id);
          }}
        >
          <i id="bookmark-icon" className={CommonStyles['icon-bookmark']}></i>
        </button>
      </div>
    </div>
  );
};

export default GroupShareList;

const Target = styled.div`
  height: 1px;
`;
