import { useAppDispatch, useAppSelector } from 'redux/store';
import type { RootState } from 'redux/store';
import * as ActionModal from 'redux/slices/modal';
import { useEffect, useState } from 'react';
import network from 'utils/network';
import { offLoad, onLoad } from 'redux/slices/loading';
import { Json } from 'aws-sdk/clients/robomaker';
import { useSelector } from 'react-redux';
import { userSelector } from 'redux/slices/user';
import * as Index from 'utils/index';
import * as ActionUser from 'redux/slices/user';

import cn from 'classnames';

import Styles from 'styles/modal.module.css';
import CommonStyles from 'styles/common.module.css';
import ResetStyles from 'styles/reset.module.css';

/**
 * @description 공동중개 modal
 * @Class
 * @category Components
 * @subcategory coagency modal
 * @component
 * @returns {JSX.Element}
 */
const LegacyAllModal = () => {
  const dispatch = useAppDispatch();
  const { modalType, action, data } = useAppSelector((state: RootState) => state.modal);
  const { me } = useSelector(userSelector);

  //닫기
  const handleModalClose = () => {
    dispatch(ActionModal.closeModal({ modalType: modalType, isOpen: false }));
  };

  //실행
  const handleConfirm = () => {
    action?.();
    handleModalClose();
  };

  //이실장 구독정보
  const [subscribes, setSubscribes] = useState<{
    code?: number;
    goods?: string;
    startDate?: string;
    endDate?: string;
  } | null>(null);

  //이실장 전체매물 가져오기 (갯수)
  const [importDataList, setImportDataList] = useState<{
    code?: number;
    message?: string;
    count?: number;
  } | null>(null);

  //전체매물 불러오기
  const getDataList = async () => {
    dispatch(onLoad());
    const { data } = await network().sso().subscribes();
    setSubscribes(data);
    //이실장 정보로 구독정보 확인 후
    if (data?.code === 0) {
      //전체매물을 불러오기
      const { data } = await network().coagency().getImportAllList();
      setImportDataList(data);
      //불러오기 실패
      if (data.code !== 0) {
        //불러올 매물이 없을때
        if (data.code === 98) {
          dispatch(
            ActionModal.openModal({
              modalType: 'alert_common',
              isOpen: true,
              data: { txt: '이미 이실장의 모든 매물을 불러왔습니다.' },
            }),
          );
        } else {
          dispatch(
            ActionModal.openModal({
              modalType: 'alert_common',
              isOpen: true,
              data: { txt: '전체매물 불러오기에 실패하였습니다.\n새로고침후 다시 시도해주세요' },
            }),
          );
        }
      }
    } else {
      dispatch(
        ActionModal.openModal({
          modalType: 'alert_common',
          isOpen: true,
          data: { txt: '이실장 멤버십 회원만\n사용할 수 있는 기능입니다.' },
        }),
      );
    }
    dispatch(offLoad());
  };

  useEffect(() => {
    if (modalType === 'coagency_import_all_confirm') {
      getDataList();
    }
  }, [modalType]);

  //이실장 전체 연동 시작
  const handleImportAll = async () => {
    dispatch(
      ActionModal.openModal({
        modalType: 'coagency_import_all_lading',
        isOpen: true,
      }),
    );
    const result = await importAllSync();

    //정보갱신
    const resMe = await network().sso().me();
    dispatch(ActionUser.setMe(resMe.data));

    // 전체매물 연동 성공
    if (result.code === 0) {
      dispatch(
        ActionModal.openModal({
          modalType: 'alert_common',
          isOpen: true,
          data: { txt: '이실장의 모든 매물을 불러왔습니다.\n공유 여부와 범위를 매물별로 설정해주세요. ' },
          action: () => {
            //완료되면 내피드로 이동
            Index.moveMain(4, '0');
            Index.onClose();
          },
        }),
      );
      //불러올 매물이 없을때
    } else if (result.code === 98) {
      dispatch(
        ActionModal.openModal({
          modalType: 'alert_common',
          isOpen: true,
          data: { txt: '이미 이실장의 모든 매물을 불러왔습니다.' },
        }),
      );
      //불러오기 실패
    } else {
      dispatch(
        ActionModal.openModal({
          modalType: 'coagency_import_all_error',
          isOpen: true,
          data: { txt: '오류가 발생하여\n이실장 매물 불러오기가 중단되었습니다.\n다시 이어서 진행하시겠습니까?' },
          action: () => importAllReConvert(),
        }),
      );
    }
  };

  // 이실장 매물 연동 재귀함수
  async function importAllSync(): Promise<any> {
    const { data } = await network().coagency().importAllConvert();
    if (data.code === 1) {
      return importAllSync();
    } else {
      return data;
    }
  }

  //이실장 전체매물 연동 다시 시작하기
  const importAllReConvert = () => {
    setTimeout(() => {
      handleImportAll();
    }, 100);
  };

  return (
    <>
      {/* 매물연동 시작하기 */}
      {modalType == 'coagency_import_all_confirm' && subscribes && importDataList && (
        <div className={Styles.show}>
          <div className={cn(Styles['popup-container'], Styles['bottom-sheet-popup'], Styles['sharing-copy-pop'])}>
            <div className={Styles['popup-header']}>
              <p className={Styles['pop-tit']}>이실장 전체 매물 불러오기</p>
              <button className={Styles.close} onClick={handleModalClose} />
            </div>
            <div className={Styles['popup-content']}>
              <div className={cn(Styles['gray-box'], CommonStyles['gray-box'])}>
                <div className={cn(CommonStyles.top, ResetStyles['ta-c'])}>
                  아래 기준으로
                  <br />
                  <b className={ResetStyles['fw-b']}>
                    이실장 매물 정보를 <span className={CommonStyles['co-dorg']}>1회</span> 불러옵니다.
                  </b>
                </div>
                <div className={Styles.bottom}>
                  <p>이실장에서 노출중인 광고매물</p>
                  <b className={cn(CommonStyles.count, ResetStyles['fw-b'], CommonStyles['co-dorg'])}>
                    {importDataList.count}
                  </b>
                </div>
              </div>
              <section>
                <p className={Styles.tit}>회원정보</p>
                <table className={CommonStyles['gry-table']}>
                  <colgroup>
                    <col width="110" />
                    <col width="" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>개설등록번호</th>
                      <td>{me.company?.corp_number}</td>
                    </tr>
                    <tr>
                      <th>중개업소명</th>
                      <td>{me.company?.name}</td>
                    </tr>
                    <tr>
                      <th>대표자명</th>
                      <td>{me.company?.ceo_name}</td>
                    </tr>
                    <tr>
                      <th>
                        이실장
                        <br />
                        멤버십 정보
                      </th>
                      <td className={ResetStyles['pre-line']}>
                        {subscribes.code === 0 && `${subscribes?.goods} 사용중`} <br />
                        {subscribes.startDate && `(${subscribes?.startDate}`}
                        {subscribes.startDate && subscribes.endDate && ` ~ ${subscribes?.endDate})`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
              <section>
                <p className={Styles.tit}>전체매물 불러오기 안내사항</p>
                <div className={Styles['txt-box']}>
                  <p>- 이실장 광고 노출중인 모든 매물을 이실장넷으로 불러옵니다. </p>
                  <p>- 이후에 이실장에서 등록되는 매물정보는 단건 불러오기를 통해 불러오시면 됩니다. </p>
                  <p>- 불러오기 후 취소는 불가능합니다. </p>
                </div>
              </section>
            </div>
            <div className={Styles['popup-button-wrap']}>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg, CommonStyles.gry)}
                onClick={handleModalClose}
              >
                취소
              </button>
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleImportAll}>
                불러오기
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 매물연동 실패 */}
      {modalType == 'coagency_import_all_error' && (
        <div className={Styles.show}>
          <div className={cn(Styles['popup-container'], Styles['modal-popup'])}>
            <div className={Styles['popup-content']}>
              <p className={Styles.txt} style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                {data?.txt}
              </p>
            </div>
            <div className={cn(Styles['popup-button-wrap'], CommonStyles['popup-button-wrap'])}>
              <button
                className={cn(Styles.btn, CommonStyles.btn, CommonStyles.gry, CommonStyles.lg)}
                onClick={handleModalClose}
              >
                취소
              </button>
              <button className={cn(Styles.btn, CommonStyles.btn, CommonStyles.lg)} onClick={handleConfirm}>
                확인
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 매물 연동 성공 */}
      {modalType == 'coagency_import_all_lading' && (
        <div className={Styles.show}>
          <div
            className={cn(
              Styles['popup-container'],
              Styles['modal-popup'],
              CommonStyles['modal-popup'],
              Styles['loading-pop'],
            )}
          >
            <div className={Styles['popup-content']}>
              <div className={CommonStyles['loading-refresh']}></div>
              <p className={Styles.tit}>
                이실장에서 매물정보를 불러오고 있어요.
                <br />
                창을 닫지 말고 잠시만 기다려 주세요
              </p>
              <p className={Styles.txt}>(최대 5~10분정도의 시간이 소요될 수 있습니다.)</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LegacyAllModal;
